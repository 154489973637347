import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectList({
  label,
  noValueString,
  listItems,
  selectedValue,
  setSelectedValue,
}) {
  if (!listItems?.length) return null;

  const classes = useStyles();

  const handleSelectChangeChange = event => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="simple-select-outlined-label">{label}</InputLabel>
        <Select
          labelId="simple-select-outlined-label"
          id="SelectListItems"
          value={selectedValue}
          onChange={handleSelectChangeChange}
          label={label}
          fullWidth
        >
          {listItems.map((item, ID) => (
            <MenuItem key={item + ID} value={ID}>
              {item || noValueString}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
