import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CropFreeIcon from "@material-ui/icons/CropFree";
import {
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  Grid,
  Paper,
  Typography,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Clear";

import QRCode from "qrcode.react";

const styles = makeStyles(theme => ({
  tryAssistantButton: {
    position: "fixed",
    transform: "rotate(-90deg)",
    zIndex: 10000,
    right: "-56px",
    top: "52vh",
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    minWidth: 400,
    padding: 40,
  },
}));

const TryAssistant = props => {
  // Props
  const { filterAppID, apiKey } = props;

  const [isQRCodeDialogOpen, setQRCodeDialogOpen] = useState(false);

  const classes = styles();

  const {
    id,
    name,
    domain,
    major_version,
    // assistantVersion
  } = filterAppID[0];

  return (
    <>
      <Button
        onClick={() => setQRCodeDialogOpen(true)}
        variant="contained"
        color="primary"
        className={`${classes.tryAssistantButton}`}
        startIcon={<CropFreeIcon />}
      >
        {" Try on Android "}
      </Button>
      <Dialog
        onClose={() => setQRCodeDialogOpen(false)}
        open={isQRCodeDialogOpen}
      >
        <div className={classes.dialogCreate}>
          <IconButton
            onClick={() => setQRCodeDialogOpen(false)}
            style={{ top: -15, float: "right" }}
            edge="end"
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle variant="h6">Try the Assistant!</DialogTitle>

          <DialogContent>
            <Grid alignContent="center" alignItems="center" container>
              <Grid xs={12} item>
                <Paper elevation={1}>
                  <br />
                  <div>
                    <QRCode
                      style={{ margin: 10, borderRadius: 15 }}
                      size={200}
                      includeMargin
                      value={`slangqr:${id}:${apiKey}:${domain}:${major_version}:${process.env.REACT_APP_STAGE}`}
                    />
                  </div>
                  <Typography variant="button" component="p">
                    {name}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {"Domain: "}&nbsp;{domain}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {"Major Version: "}&nbsp;{major_version}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {"Assistant ID: "}&nbsp;{id}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {"API Key: "}&nbsp;{apiKey}
                  </Typography>
                  <br />
                </Paper>
                <br />
              </Grid>
              <Grid xs={12} item>
                <Typography component="span">
                  Scan the QR code above in Slang CONVA Playground App &nbsp;
                  <a
                    href="https://play.google.com/store/apps/details?id=in.slanglabs.pg_app"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src="/images/playstore.png"
                      width="130"
                      alt="pub success"
                      style={{ verticalAlign: "middle" }}
                    />
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions />
        </div>
      </Dialog>
    </>
  );
};

TryAssistant.propTypes = {
  filterAppID: PropTypes.array.isRequired,
  apiKey: PropTypes.string.isRequired,
};

export default TryAssistant;
