import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel, a11yProps } from "./UserJourneysEdit/TabPanel";
import { Grid, Typography, Paper } from "@material-ui/core";

import DocHelpLink from "../../common/DocLinks";
import GlobalUIHints from "./UserJourneysEdit/UIHints";
import GlobalPromptsEdit from "./UserJourneysEdit/PromptsEdit";
import LanguageSettings from "./UserJourneysEdit/LanguageSettings";
import UICustomization from "./UserJourneysEdit/UICustomization/UICustomization";
import TutorialWrapper from "../../common/TutorialWrapper";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "left",
  },
  globalStateTab: {
    margin: `0 ${theme.spacing(10)}px`,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  promptHeading: {
    textAlign: "left",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    textAlign: "left",
    height: "100%",
  },
}));

const appStateTabsList = [
  {
    name: "Greeting",
    value: 0,
  },
  {
    name: "Clarification",
    value: 1,
  },
];

export default function AppSettingsTabs(props) {
  const {
    userJourneyID,
    userJourney,
    appStatesMetadata,
    changeUserJourneyData,
    handleEditAppData,
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState(0);

  const { onboardingStep } = useSelector(state => ({
    onboardingStep: state.user.onboardingStep,
  }));

  const languageSettingsRef = useRef(null);
  const uiCustomizationRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // selectedAppState: selectedValue,
  //   changeUserJourneyData,
  //   userJourneyID,
  //   userJourney,
  //   allHints,

  const handleChangeGlobalTab = (event, currentTab) => {
    setSelectedValue(currentTab);
  };

  const allHints = appStatesMetadata[selectedValue].userUIHints
    ? appStatesMetadata[selectedValue].userUIHints
    : appStatesMetadata[selectedValue].uiHints;

  const uiHintsProps = {
    allHints,
    userJourney,
    userJourneyID,
    selectedAppState: selectedValue,
    changeUserJourneyData,
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid xs={2} item>
          <Typography variant="h5" className={classes.promptHeading} noWrap>
            {"App Settings "} <DocHelpLink docFor={"APP_SETTINGS"} />
          </Typography>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            indicatorColor="primary"
            className={classes.tabs}
          >
            <Tab
              label={
                <TutorialWrapper step={8} anchorEl={languageSettingsRef}>
                  <span ref={languageSettingsRef}>Language Settings</span>
                </TutorialWrapper>
              }
              {...a11yProps(0)}
            />
            <Tab label="Prompts" {...a11yProps(1)} />
            <Tab label="UI Hints" {...a11yProps(2)} />
            <Tab
              label={
                <TutorialWrapper step={9} anchorEl={uiCustomizationRef}>
                  <span ref={uiCustomizationRef}>UI Customization</span>
                </TutorialWrapper>
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>
        <Grid xs={10} item>
          <Paper elevation={0} className={classes.globalStateTab}>
            <br />
            <br />
            {value > 0 && value < 3 ? (
              <Tabs
                value={selectedValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeGlobalTab}
                centered
                variant="fullWidth"
              >
                {value > 0 && value < 3
                  ? appStateTabsList.map((item, key) => (
                      <Tab key={key} value={item.value} label={item.name} />
                    ))
                  : null}
              </Tabs>
            ) : null}
          </Paper>
          <TabPanel value={value} index={0}>
            <LanguageSettings
              {...{
                handleEditAppData,
              }}
            />{" "}
          </TabPanel>

          <TabPanel value={value} index={1}>
            <GlobalPromptsEdit
              {...{
                appStatesMetadata,
                selectedAppState: selectedValue,
                userJourneyID,
                changeUserJourneyData,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <GlobalUIHints {...uiHintsProps} />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <UICustomization
              {...{
                handleEditAppData,
              }}
            />{" "}
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}
