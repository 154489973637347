import CircularProgress from "@material-ui/core/CircularProgress";

export const SlangLoader = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div style={{ width: 130, margin: "0 auto", paddingTop: "20vh" }}>
        <img
          src={"/images/logo_logout.png"}
          style={{ position: "relative", top: 107, width: 100 }}
          alt="Slang Labs"
        />
        <CircularProgress size={100} thickness={2} />
      </div>
    </div>
  );
};

export default SlangLoader;
