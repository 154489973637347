import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { testClassNames } from "../../../utils/integrationTestClassNames";

import WelcomePop from "./WelcomePop";

// const Transition = (props) =>
//    ( <Slide direction="up" {...props} timeout={{ enter: 1000, exit: 500 }} />);

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
    timeout={{ enter: 1000, exit: 500 }}
  />
));

const WelcomeDialog = props => {
  const { classes, handleDialogClose, state } = props;

  const { openWelcome } = state;

  return (
    <Dialog
      open={openWelcome}
      onClose={handleDialogClose}
      classes={{ paper: classes.firstLoad }}
      TransitionComponent={Transition}
    >
      <WelcomePop />
      <DialogActions className={classes.leftAlign}>
        <Button
          color="primary"
          onClick={handleDialogClose}
          className={`${testClassNames.HOME_WELCOME_POPUP_DONE_BUTTON}`}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeDialog;
