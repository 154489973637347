import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpwardTwoTone";
import UploadForm from "./UploadForm";

import {
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  iconButton: {
    marginRight: 10,
  },
  uploadGridSpace: {
    marginTop: 20,
  },
  closeButton: {
    float: "right",
  },
}));

const UploadDialog = props => {
  const classes = useStyles();

  const {
    assistantType,
    dataID,
    subDomainID,
    displayName,
    subDomainName,
    dataName,
    dispatchSubdomainChange,
    refAnchor,
    userDataID,
  } = props;

  const [Open, setOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = () => {
    isProcessing ? null : setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const ShowNames = () => (
    <>
      <Typography>
        {"Subdomain: "}
        {subDomainName}
      </Typography>
      <Typography>
        {"Data type: "}
        {dataName}
      </Typography>
    </>
  );
  return (
    <>
      <IconButton
        aria-label="Upload"
        title={`Update your ${displayName} for ${subDomainName} subdomain`}
        onClick={handleOpen}
        className={classes.iconButton}
        ref={refAnchor}
      >
        <ArrowUpward />
      </IconButton>

      <Dialog open={Open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Subdomain data Upload
          <IconButton
            aria-label="Upload"
            title={"close"}
            onClick={handleClose}
            disabled={isProcessing}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {" "}
          <UploadForm
            {...{
              assistantType,
              dataID,
              subDomainID,
              ShowNames,
              subDomainName,
              dataName,
              dispatchSubdomainChange,
              handleClose,
              setIsProcessing,
              classes,
              userDataID,
            }}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

UploadDialog.propTypes = {
  dataID: PropTypes.number.isRequired,
  subDomainID: PropTypes.number.isRequired,
  displayName: PropTypes.string,
  assistantType: PropTypes.string.isRequired,
  subDomainName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  dispatchSubdomainChange: PropTypes.func.isRequired,
};

export default UploadDialog;
