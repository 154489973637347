import { Component } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import PlayGroundIcon from "./PlaygroundIcon";

import Slang from "@slanglabs/slang-web-sdk";

class TriggerPlayground extends Component {
  state = {
    isProcessing: false,
    activeUtterance: null,
    processingStarted: false,
  };

  componentWillUnmount() {
    this._componentIsMounted = false;
  }

  componentDidMount() {
    this._componentIsMounted = true;

    Slang.registerUIObserver(sdkUiState => {
      if (this._componentIsMounted) {
        if (this.props.utterance === this.state.activeUtterance) {
          if (sdkUiState.isReady) {
            if (this.state.processingStarted) {
              this.setState({
                activeUtterance: null,
                processingStarted: false,
              });
            }
          }
          if (sdkUiState.isProcessing) {
            this.setState({ processingStarted: true });
          }
        }
      }
    });
  }

  handleUtteranceClick = utterance => {
    this.setState({
      activeUtterance: utterance,
    });
    Slang.startWithUtterance(utterance);
  };

  render() {
    const { classes, utterance, loaderSize, ...props } = this.props;
    const { activeUtterance } = this.state;

    return utterance ? (
      activeUtterance === utterance ? (
        <CircularProgress size={loaderSize || 15} />
      ) : (
        <IconButton
          {...props}
          className={classes.triggerButton}
          onClick={this.handleUtteranceClick.bind(this, utterance)}
          title="Test this utterance in the playground"
        >
          <PlayGroundIcon className={classes.triggerButtonIcon} />{" "}
        </IconButton>
      )
    ) : null;
  }
}

TriggerPlayground.propTypes = {
  classes: PropTypes.object.isRequired,
  utterance: PropTypes.string.isRequired,
};

export default TriggerPlayground;
