/* eslint-disable no-use-before-define */
import React from "react";
// import Chip from '@material-ui/';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SelectIntent({
  intentNames,
  selectedIntents,
  setSelectedIntents,
}) {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      id="intent-outlined"
      options={intentNames}
      getOptionLabel={option => option}
      value={selectedIntents}
      onChange={(event, newValue) => {
        setSelectedIntents(newValue);
      }}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Select intent"
          placeholder="Next?"
          fullWidth
        />
      )}
    />
  );
}
