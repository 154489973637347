import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const initialSubDomainDataType = {
  name: "",
  value: {
    type: "csv",
    replace: false,
    csvfields: [],
    csvdata: [],
  },
  description: "",
  displayName: "",
};

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: 10,
  },
}));

function AddSubDomainForm(props) {
  const { subdomains, open, subDomainID, handleSaveClose } = props;
  const classes = useStyles();

  const [selectError, setSelectError] = useState(null);
  const [csvfields, setCsvfields] = useState("");
  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    return () => {
      if (!open) {
        setDisplayName("");
        setName("");
        setDescription("");
        setSelectError(null);
      }
    };
  }, [open]);

  const handleChangeDisplayName = event => {
    const text = event.target.value;
    if (text.length < 140) setDisplayName(text);
  };
  const handleChangeName = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDisplayName(text.replace(/_/g, " "));
      const currentName = text.replace(/ /g, "_");
      const doesNameExist = subdomains.find(SD => SD.name === currentName);

      if (doesNameExist) {
        setSelectError("Sub Domain name exists");
      } else {
        setSelectError(null);
      }
      setName(currentName);
    }
  };
  const handleChangeDescription = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDescription(text);
    }
  };
  const handleChangeCsvFields = event => {
    const text = event.target.value;
    if (text.length < 340) {
      setCsvfields(text);
    }
  };
  const handleSubmitData = () => {
    const newSubDomainData = subdomains[subDomainID].data;
    initialSubDomainDataType.name = name;
    initialSubDomainDataType.displayName = displayName;
    initialSubDomainDataType.description = description;
    initialSubDomainDataType.value.csvfields = csvfields
      .split(",")
      .map(item => item.trim());
    newSubDomainData[newSubDomainData.length] = initialSubDomainDataType;
    subdomains[subDomainID].data = newSubDomainData;
    handleSaveClose(subdomains);
  };
  const isDisabled =
    name.length < 2 || displayName.length < 2 || description.length < 3;
  return (
    <>
      <form noValidate autoComplete="off">
        <Grid spacing={3} alignContent="center" alignItems="center" container>
          <Grid xs={6} item>
            <TextField
              id="standard-name"
              label="Name"
              name="name"
              value={name}
              onChange={handleChangeName}
              error={selectError !== null}
              helperText={selectError}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="standard-display-name"
              label="Display Name"
              name="displayName"
              value={displayName}
              onChange={handleChangeDisplayName}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="standard-description"
              label="Description"
              name="description"
              value={description}
              onChange={handleChangeDescription}
              fullWidth
            />
          </Grid>
          <Grid xs={12} className={classes.marginBottom} item>
            <TextField
              id="standard-csvfields"
              label="CSV fields"
              name="csvfields"
              value={csvfields}
              onChange={handleChangeCsvFields}
              helperText={"separate each field by commas"}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <Button
              disabled={selectError !== null}
              onClick={handleSubmitData}
              color="primary"
              fullWidth
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

AddSubDomainForm.propTypes = {
  handleSaveClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  subdomains: PropTypes.array.isRequired,
};

export default AddSubDomainForm;
