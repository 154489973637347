require("es6-promise").polyfill();
require("isomorphic-fetch");

// var btoa = require("btoa");
const SlangConfig = require("./config");

const queryString = require("qs");
const role = {
  currentRole: "visitor",
  setRole: function (role) {
    this.currentRole = role;
  },
  getRole: function () {
    return this.currentRole;
  },
};

// checks the role of the user and replaces applications with assistant templates or config
function checkURL(url) {
  let isTemplates = false;
  if (typeof window !== "undefined") {
    isTemplates = window.location.pathname.match("templates");
  }
  const currentRole = role.getRole();
  switch (currentRole) {
    case "Slang Admin":
      return isTemplates
        ? url.replace("/applications/", "/assistant-templates/")
        : url.replace("/applications/", "/assistant-configs/");
    case "Customer Admin":
      return url.replace("/applications/", "/assistant-configs/");
    case "visitor":
      return url;
    default:
      return url;
  }
}

function _get_uri(action, host, args = {}) {
  const actualURL = checkURL(sub_get_uri(action, host, args));
  return actualURL;
}
function sub_get_uri(action, host, args = {}) {
  let env = "stage";
  if (args.env === "prod") {
    env = "prod";
  }
  delete args.env;

  let ver = "1.0.0";
  if (args.assistant_version) {
    ver = args.assistant_version;
    args.version = args.assistant_version;
  }
  delete args.assistant_version;

  let appId = "";
  if ("appId" in args) {
    appId = args.appId;
  }
  delete args.appId;

  let pkg = "";
  if ("pkg" in args) {
    pkg = args.pkg;
  }
  delete args.pkg;

  let typeName = "";
  if ("typeName" in args) {
    typeName = args.typeName;
    delete args.typeName;
  }

  let qs = queryString.stringify(args);
  if (qs) {
    qs = "&" + qs; // env being the first param
  }
  switch (action) {
    case "create_app":
      return (
        host +
        SlangConfig.APP_CREATE_URI.replace("ENV", env).replace("VER", ver) +
        qs
      );
    case "clone_app":
      return host + SlangConfig.APP_CLONE_URI;
    case "app_strings":
      return (
        host +
        SlangConfig.APP_STRINGS_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENV", env) +
        qs
      );
    case "get_all_apps":
      return (
        host + SlangConfig.APPS_URI.replace("ENV", env).replace("VER", ver) + qs
      );
    case "get_app":
      return (
        host +
        SlangConfig.APP_GET_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENV", env) +
        qs
      );
    case "get_app_meta":
      return (
        host +
        SlangConfig.APP_METADATA_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENV", env) +
        qs
      );
    case "get_app_meta_train_status":
      return (
        host +
        SlangConfig.APP_METADATA_TRAIN_STATUS_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENV", env) +
        qs
      );
    case "get_android_code":
      return (
        host +
        SlangConfig.APP_ANDROID_CODEGEN_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("PKG_NAME", pkg)
          .replace("ENV", env) +
        qs
      );
    case "app_entity_type":
      return (
        host +
        SlangConfig.APP_ENTITY_TYPE_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENTITY_TYPE", typeName)
          .replace("ENV", env) +
        qs
      );
    case "update_app":
      return (
        host +
        SlangConfig.APP_SAVE_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENV", env) +
        qs
      );
    case "delete_app":
      return (
        host +
        SlangConfig.APP_DELETE_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENV", env) +
        qs
      );
    case "publish_app":
      return (
        host +
        SlangConfig.APP_TRAIN_URI.replace("APP_ID", appId)
          .replace("VER", ver)
          .replace("ENV", env) +
        qs
      );
    case "schema":
      return (
        host +
        SlangConfig.SLANG_SCHEMA_URI.replace("ENV", env).replace("VER", ver) +
        qs
      );
    case "std_entity_types":
      return (
        host +
        SlangConfig.STD_ENTITY_TYPES_URI.replace("ENV", env).replace(
          "VER",
          ver
        ) +
        qs
      );
    case "get_domains":
      return (
        host +
        SlangConfig.SLANG_DOMAINS_URI.replace("ENV", env).replace("VER", ver) +
        qs
      );
    case "get_templates":
      return (
        host +
        SlangConfig.SLANG_TEMPLATES_URI.replace("ENV", env).replace(
          "VER",
          ver
        ) +
        qs
      );
    default:
      break;
  }
}

function _validate_response(json) {
  if ("code" in json && (json.code < 200 || json.code > 300)) {
    const errorMsg = json.message || "Internal error";
    const trace = json.trace || "Trace: none";
    return {
      success: false,
      message: errorMsg,
      trace,
      raw_json_response: json,
    };
  } else {
    return { success: true, raw_json_response: json };
  }
}

const fetchSlangSchemaDefinition = function (host, args = {}) {
  return new Promise((resolve, reject) => {
    const uri = _get_uri("schema", host, args);
    fetch(uri, {})
      .then(response => {
        if (response.ok) return response.json();
        else throw Error(response.statusText);
      })
      .then(schema => {
        resolve(schema);
      })
      .catch(err => {
        reject("Failed to fetch Slang schema: " + err.message);
      });
  });
};

const fetchSlangStdEntityTypes = function (host, args = {}) {
  return new Promise((resolve, reject) => {
    const uri = _get_uri("std_entity_types", host, args);
    fetch(uri, {})
      .then(response => {
        if (response.ok) return response.json();
        else throw Error(response.statusText);
      })
      .then(schema => {
        resolve(schema);
      })
      .catch(err => {
        reject("Failed to fetch Standard Entity Types: " + err.message);
      });
  });
};

// Reads role and token from auth_key and secret_key params
// Sets identity header with apikey and secret combo interim.
// TODO: first class way to handle tokens and roles
function _slang_headers(orgID_token, secret_key_role, headers = null) {
  const secret_role = secret_key_role || "NONE";
  const _secret = secret_role.split("|");
  role.setRole(_secret[1] ? _secret[1] : "visitor");
  const _orgID_token = orgID_token.split("|");
  // const secret = _secret[0]
  // let base64_auth = btoa(_orgID_token[0] + ":" + secret);
  let slangHeaders = SlangConfig.HEADERS;

  if (headers) slangHeaders = headers;
  return Object.assign(slangHeaders, {
    Authorization: "Bearer " + _orgID_token[1],
    identity: localStorage.getItem("U_orgID"),
  });
}

const getSlangAppList = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const headers = _slang_headers(orgID_token, api_secret);

    const uri = _get_uri("get_all_apps", host, args);
    fetch(uri, {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to fetch Apps: " + err.message);
      });
  });
};

const getSlangApp = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("get_app", host, args), {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get app schema: " + err.message);
      });
  });
};

const getSlangAppStringTable = function (
  host,
  orgID_token,
  api_secret,
  args = {}
) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("app_strings", host, args), {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get app strings: " + err.message);
      });
  });
};

const getSlangAppMetadata = function (
  host,
  orgID_token,
  api_secret,
  args = {}
) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");
    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("get_app_meta", host, args), {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get app schema: " + err.message);
      });
  });
};

const getSlangAppMetadataTrainStatus = function (
  host,
  orgID_token,
  api_secret,
  args = {}
) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");
    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("get_app_meta_train_status", host, args), {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get app schema: " + err.message);
      });
  });
};

const createSlangApp = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) {
      reject("Not Authorized");
      return;
    }

    const payload = {};
    const name = args.createAppName;
    if (!name || !args.template_version || !args.template_id) {
      reject("Missing Parameter name");
      return;
    }
    payload.name = name;
    delete args.createAppName;

    const template_id = args.template_id;
    if (template_id) {
      payload.template_id = template_id;
      delete args.template_id;
    }
    const template_version = args.template_version;
    if (template_version) {
      payload.template_version = template_version;
      delete args.template_version;
    }
    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("create_app", host, args), {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to create Buddy: " + err.message);
      });
  });
};

const deleteSlangApp = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("delete_app", host, args), {
      method: "DELETE",
      headers,
    })
      .then(response => {
        if (response.status === 204)
          resolve(
            _validate_response({
              code: response.status,
              raw_json_response: { code: response.status },
            })
          );
        else {
          response
            .json()
            .then(json => {
              resolve(_validate_response(json));
            })
            .catch(err => {
              reject("Failed to delete schema: " + err.message);
            });
        }
      })
      .catch(err => {
        reject("Failed to delete schema: " + err.message);
      });
  });
};

const saveAppStrings = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const appStringTable = args.appStringTable;
    delete args.appStringTable;
    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("app_strings", host, args), {
      method: "PUT",
      headers,
      body: appStringTable,
    })
      .then(response => {
        if (response.status === 204)
          resolve(
            _validate_response({
              code: response.status,
              raw_json_response: { code: response.status },
            })
          );
        else {
          response
            .json()
            .then(json => {
              resolve({
                success: false,
                message: "Error saving strings",
                raw_json_response: json,
              });
            })
            .catch(err => {
              reject("Failed to save schema: " + err.message);
            });
        }
      })
      .catch(err => {
        reject("Failed to save schema: " + err.message);
      });
  });
};

const saveIntents = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const appSchema = args.appIntentSchema;
    delete args.appIntentSchema;

    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("update_app", host, args), {
      method: "PUT",
      headers,
      body: appSchema,
    })
      .then(response => {
        if (response.status === 204)
          resolve(
            _validate_response({
              code: response.status,
              raw_json_response: { code: response.status },
            })
          );
        else {
          response
            .json()
            .then(json => {
              resolve(_validate_response(json));
            })
            .catch(err => {
              reject("Failed to save schema: " + err.message);
            });
        }
      })
      .catch(err => {
        reject("Failed to save schema: " + err.message);
      });
  });
};

const publishIntents = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const headers = _slang_headers(orgID_token, api_secret);
    const uri = _get_uri("publish_app", host, args);

    fetch(uri, {
      method: "POST",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to publish app schema: " + err.message);
      });
  });
};

const getSlangDomains = function (host, orgID_token, api_secret) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const headers = _slang_headers(orgID_token, api_secret);
    const uri = _get_uri("get_domains", host);

    fetch(uri, {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to fetch Domains: " + err.message);
      });
  });
};

const getSlangTemplates = function (host, orgID_token, api_secret) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not authorized");

    const headers = _slang_headers(orgID_token, api_secret);
    const uri = _get_uri("get_templates", host);

    fetch(uri, {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to fetch Templates: " + err.message);
      });
  });
};

const getAndroidCode = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("get_android_code", host, args), {
      method: "GET",
      headers,
    })
      .then(response => {
        console.log(response.status);
        return response.text();
      })
      .then(text => {
        resolve(text);
      })
      .catch(err => {
        reject("Failed to get android code: " + err.message);
      });
  });
};

const getEntityTypeValues = function (
  host,
  orgID_token,
  api_secret,
  args = {}
) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const appId = args.appId;
    if (!appId) reject("Missing Parameter app ID");

    const typeName = args.typeName;
    if (!typeName) reject("Missing Parameter entity type name");

    const headers = _slang_headers(orgID_token, api_secret);

    fetch(_get_uri("app_entity_type", host, args), {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get entity type values: " + err.message);
      });
  });
};

const postEntityTypeValues = function (
  host,
  orgID_token,
  api_secret,
  args = {}
) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const appId = args.appId;
    if (!appId) reject("Missing Parameter app ID");

    const typeName = args.typeName;
    if (!typeName) reject("Missing Parameter entity type name");

    const contentType = args.contentType;
    delete args.contentType;

    let payload = args.dataBody;
    if (typeof payload === "object") {
      payload = JSON.stringify(payload);
    }
    delete args.dataBody;
    const headers = _slang_headers(orgID_token, api_secret, {
      "Content-Type": contentType,
    });

    fetch(_get_uri("app_entity_type", host, args), {
      method: "POST",
      headers,
      body: payload,
    })
      .then(response => {
        if (response.status === 204)
          resolve(
            _validate_response({
              code: response.status,
              raw_json_response: { code: response.status },
            })
          );
        else {
          response
            .json()
            .then(json => {
              resolve(_validate_response(json));
            })
            .catch(err => {
              reject("Failed to post entity type values: " + err.message);
            });
        }
      })
      .catch(err => {
        reject("Failed to post entity type values: " + err.message);
      });
  });
};

const putEntityTypeValues = function (
  host,
  orgID_token,
  api_secret,
  args = {}
) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const appId = args.appId;
    if (!appId) reject("Missing Parameter app ID");

    const typeName = args.typeName;
    if (!typeName) reject("Missing Parameter entity type name");

    const contentType = args.contentType;
    delete args.contentType;

    let payload = args.dataBody;
    if (typeof payload === "object") {
      payload = JSON.stringify(payload);
    }
    delete args.dataBody;
    const headers = _slang_headers(orgID_token, api_secret, {
      "Content-Type": contentType,
    });

    fetch(_get_uri("app_entity_type", host, args), {
      method: "PUT",
      headers,
      body: payload,
    })
      .then(response => {
        if (response.status === 204)
          resolve(
            _validate_response({
              code: response.status,
              raw_json_response: { code: response.status },
            })
          );
        else {
          response
            .json()
            .then(json => {
              resolve(_validate_response(json));
            })
            .catch(err => {
              reject("Failed to post entity type values: " + err.message);
            });
        }
      })
      .catch(err => {
        reject("Failed to put entity type values: " + err.message);
      });
  });
};

/*
        Get the app with the selected ID
            - string data
            - app data
        Create a new blank app with said name;    
        Store the app data in variables
        Save the new app with the previous app data
            - string data
            - app data
        Complete Action 

  */

const cloneSlangApp = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");

    const env = "stage";
    const appName = args.appName;
    const cloneID = args.cloneID;
    if (!appName) reject("Missing Parameter name");
    if (!cloneID) reject("App ID missing");

    const testStatus = function (data, deleteApp = null) {
      if (data.success) return;

      // if saving the app fails, delete the recently created clone
      if (deleteApp) {
        deleteSlangApp(host, orgID_token, api_secret, { appId: deleteApp });
      }

      throw Error(data.message);
    };
    let newAppData, newAppStrings, newAppID, newAppName;

    getSlangApp(host, orgID_token, api_secret, {
      appId: cloneID,
    })
      .then(getAppData => {
        testStatus(getAppData);
        newAppData = getAppData.raw_json_response;
        return getSlangAppStringTable(host, orgID_token, api_secret, {
          appId: cloneID,
        });
      })
      .then(getAppStrings => {
        testStatus(getAppStrings);
        newAppStrings = getAppStrings.raw_json_response;
        return createSlangApp(host, orgID_token, api_secret, {
          appName,
        });
      })
      .then(createAppData => {
        testStatus(createAppData);
        newAppID = createAppData.raw_json_response.id;
        newAppName = createAppData.raw_json_response.name;
        newAppData.id = newAppID;
        newAppData.name = newAppName;
        newAppData = JSON.stringify(newAppData);
        return saveIntents(host, orgID_token, api_secret, {
          appId: newAppID,
          env,
          appIntentSchema: newAppData,
        });
      })
      .then(saveNewAppData => {
        testStatus(saveNewAppData, newAppID);
        newAppStrings = JSON.stringify(newAppStrings);
        return saveAppStrings(host, orgID_token, api_secret, {
          appId: newAppID,
          env,
          appStringTable: newAppStrings,
        });
      })
      .then(getAppData => {
        testStatus(getAppData, newAppID);
        return getSlangApp(host, orgID_token, api_secret, {
          appId: newAppID,
        });
      })
      .then(getNewAppData => {
        testStatus(getNewAppData);
        resolve({ raw_json_response: getNewAppData });
      })
      .catch(error => {
        reject(error);
      });
  });
};

// This function is used by the console client to call the buddy clone endpoint on the TS server
const requestCloneApp = function (host, orgID_token, api_secret, args = {}) {
  return new Promise((resolve, reject) => {
    if (!orgID_token || !api_secret) reject("Not Authorized");
    const appName = args.appName;
    const cloneID = args.cloneID;

    if (!appName) reject("Missing Parameter name request");
    if (!cloneID) reject("App ID missing");

    const payload = {
      appName,
      cloneID,
    };
    const headers = _slang_headers(orgID_token, api_secret);
    fetch(_get_uri("clone_app", host, args), {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to clone Buddy: " + err.message);
      });
  });
};
module.exports = {
  fetchSlangSchemaDefinition,
  getSlangAppList,
  getSlangApp,
  getSlangAppStringTable,
  getSlangAppMetadata,
  getSlangAppMetadataTrainStatus,
  createSlangApp,
  deleteSlangApp,
  saveIntents,
  saveAppStrings,
  publishIntents,
  getSlangDomains,
  getSlangTemplates,
  fetchSlangStdEntityTypes,
  getAndroidCode,
  getEntityTypeValues,
  postEntityTypeValues,
  putEntityTypeValues,
  cloneSlangApp,
  requestCloneApp,
};
