import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";

const styles = makeStyles(theme => ({
  textField: {
    width: "90%",
  },
  fab: {
    height: 35,
    width: 35,
    marginLeft: theme.spacing(2),
    verticalAlign: "bottom",
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
}));

const InputTextField = props => {
  const { langName, chipType, cleanPhraseData } = props;
  const [addPhrase, setaddPhrase] = useState("");

  const classes = styles();

  const handleTextChange = event => {
    setaddPhrase(event.target.value);
  };
  const updateChips = () => {
    if (cleanPhraseData(chipType, addPhrase)) setaddPhrase("");
  };
  const onKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      const name = event.target.name;
      updateChips();
    }
  };

  return (
    <>
      <TextField
        name={chipType}
        label={`Hints in ${langName}`}
        placeholder="e.g. 'chrome browser' or 'chrome, firefox, netscape'"
        className={classes.textField}
        value={addPhrase}
        onChange={handleTextChange}
        onKeyPress={onKeyPress}
        margin="normal"
      />
      <Fab
        size="small"
        aria-label="Add"
        color="default"
        className={classes.fab}
        onClick={() => updateChips()}
        disabled={addPhrase.length === 0}
      >
        <Add />
      </Fab>
    </>
  );
};

InputTextField.propTypes = {
  langName: PropTypes.string.isRequired,
  chipType: PropTypes.string.isRequired,
  cleanPhraseData: PropTypes.func.isRequired,
};

export default InputTextField;
