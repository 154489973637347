import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import AppConfig from "../../../../app.config";
import { makeStyles } from "@material-ui/core";

const Langs = AppConfig.LANGUAGES;

const styles = makeStyles(theme => ({
  highlightButton: {
    marginRight: 8,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
  },
  normalButton: {
    marginRight: 8,
    borderBottom: "2px solid transparent",
    borderRadius: 0,
  },
}));

const LangButton = props => {
  const { lang, setLang } = props;

  const classes = styles();

  return Langs.map((item, key) => {
    return (
      <Button
        key={key}
        variant="text"
        color={lang === item.code ? "primary" : "default"}
        className={`${
          lang === item.code ? classes.highlightButton : classes.normalButton
        }`}
        onClick={() => setLang(item.code)}
      >
        {`${item.locale}`}
      </Button>
    );
  });
};

LangButton.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
};

export default LangButton;
