import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Chip, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { cleanParameters } from "../../../../../utils/parametersUtil";

const styles = makeStyles(theme => ({
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    paddingBottom: 0,
    textAlign: "left",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  promptContainer: {
    padding: theme.spacing(2.5),
  },
}));

const Parameters = props => {
  const { appStatesMetadata, selectedAppState } = props;

  const [parameters, setParameters] = useState([]);

  useEffect(() => {
    setParameters([]);
    appStatesMetadata[selectedAppState].parameters.forEach(item => {
      const cleanData = cleanParameters(item);
      setParameters(oldParameters => [
        ...new Set([...cleanData, ...oldParameters]),
      ]);
    });
  }, [selectedAppState]);

  const classes = styles();

  return (
    <Fade in={parameters.length > 0}>
      <div className={classes.promptContainer}>
        <Typography variant="h6" className={classes.chip} noWrap>
          Parameters
        </Typography>
        {parameters.map((item, key) => (
          <Chip key={key + item} label={item} className={classes.chip} />
        ))}
      </div>
    </Fade>
  );
};

export default Parameters;
