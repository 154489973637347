import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Save, Assignment, CloudUpload } from "@material-ui/icons";
import {
  List,
  Popover,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getSaveAndTrainTimestamp } from "../../utils/MiscUtils";

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const PublishPopover = props => {
  const { metadata, metadataTrainStatus } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastSaved, setLastSaved] = useState(null);
  const [lastStagePublished, setLastStagePublished] = useState(null);
  const [lastProdPublished, setLastProdPublished] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      const trainAndSaveTimestamps = getSaveAndTrainTimestamp(
        metadata,
        metadataTrainStatus
      );
      setLastSaved(trainAndSaveTimestamps.lastSavedTimestamp);
      setLastStagePublished(trainAndSaveTimestamps.lastStagePublishedTimestamp);
      setLastProdPublished(trainAndSaveTimestamps.lastProdPublishedTimestamp);
    }
  }, [anchorEl]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Icon
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        fontSize="small"
        color="inherit"
      >
        info
      </Icon>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List style={{ padding: "0 1rem", width: "100%", maxWidth: "360px" }}>
          <ListItem dense disableGutters>
            <ListItemIcon>
              <Save />
            </ListItemIcon>
            <ListItemText
              primary="Last Saved"
              secondary={
                lastSaved ? moment(lastSaved).fromNow().toString() : "Not Saved"
              }
            />
          </ListItem>
          <ListItem dense disableGutters>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText
              primary="Last Published on Stage"
              secondary={
                lastStagePublished
                  ? moment(lastStagePublished).fromNow().toString()
                  : "Not Published"
              }
            />
          </ListItem>
          <ListItem dense disableGutters>
            <ListItemIcon>
              <CloudUpload />
            </ListItemIcon>
            <ListItemText
              primary="Last Published on Production"
              secondary={
                lastProdPublished
                  ? moment(lastProdPublished).fromNow().toString()
                  : "Not Published"
              }
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

PublishPopover.propTypes = {
  metadata: PropTypes.object.isRequired,
  metadataTrainStatus: PropTypes.object.isRequired,
};

export default PublishPopover;
