import PropTypes from "prop-types";
import React from "react";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import DocHelpLink from "../../common/DocLinks";
import { makeStyles } from "@material-ui/core";

const newIntent = {
  name: "",
  entities: [],
  examples: [],
  actions: [],
  description: "",
  help: {
    helpDescription: {},
    utterances: [],
  },
  completion: {
    successTemplates: [
      {
        value: "prompt",
        isId: false,
        severity: 1,
      },
    ],
    failureTemplates: [],
  },
};

const styles = makeStyles(theme => ({
  textColor: {
    color: theme.palette.primary.dark,
  },
  intentButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    minWidth: 400,
    padding: 40,
  },
  intentIcon: {
    fontSize: 90,
    color: theme.palette.primary.main,
  },
  createInput: {
    color: theme.palette.primary.dark,
    width: 300,
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    width: "100%",
    height: 50,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  disabled: {},
  cancelButton: {
    width: "100%",
    height: 50,
    color: theme.palette.primary.dark,
  },
}));

const ViewNewIntent = props => {
  const { allIntentNames, handleCreateNewIntent } = props;

  const [openCreate, setOpenCreate] = React.useState(false);
  const [intentName, setIntentName] = React.useState("");
  const [intentDescription, setIntentDescription] = React.useState("");
  const [createError, setCreateError] = React.useState(false);

  const handleChangeIntentName = event => {
    const text = event.target.value;
    if (text.length < 140) {
      const currentName = text.replace(/ /g, "_");
      setIntentName(currentName);
      const doesIntentExist = allIntentNames.includes(currentName);
      setCreateError(doesIntentExist);
    }
  };

  const handleChangeIntentDescription = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setIntentDescription(text);
    }
  };

  const classes = styles();

  const handleSaveNewIntent = event => {
    event.preventDefault();
    setOpenCreate(false);

    const payload = {
      ...newIntent,
      name: intentName,
      description: intentDescription,
    };

    handleCreateNewIntent(payload);
  };

  const handleCloseDialog = () => {
    setOpenCreate(false);
  };

  const handleOpenDialog = () => {
    setOpenCreate(true);
  };

  return (
    <div>
      <Button
        onClick={handleOpenDialog}
        variant="text"
        className={classes.intentButton}
      >
        <Icon>add</Icon>
      </Button>
      <Dialog open={openCreate} onClose={handleCloseDialog} maxWidth="md">
        <div className={classes.dialogCreate}>
          <Typography variant="h5" gutterBottom className={classes.textColor}>
            {"Create New Intent "}
            <DocHelpLink docFor="INTENT" />
          </Typography>
          <DialogContent className={classes.textColor}>
            <Icon className={classes.intentIcon}>record_voice_over</Icon>
            <form onSubmit={handleSaveNewIntent}>
              <Typography
                variant="caption"
                gutterBottom
                paragraph
                className={classes.textColor}
              />
              <FormControl
                className={classes.createInput}
                error={!!createError}
                aria-describedby="create-intent-name-text"
              >
                <Input
                  autoFocus
                  id="create-intent-name"
                  className={classes.createInput}
                  color="primary"
                  placeholder="Intent Name"
                  pattern="[A-Za-z0-9.]+"
                  inputProps={{
                    "aria-label": "Intent Name",
                  }}
                  type="text"
                  onChange={handleChangeIntentName}
                  name={"createIntentName"}
                  value={intentName}
                  required
                />
                <FormHelperText id="create-intent-name-text">
                  {"unique name 3+ letters or underscore; e.g. app_click"}
                </FormHelperText>
              </FormControl>
              <br />
              <br />
              <FormControl
                className={classes.createInput}
                aria-describedby="create-intent-description-text"
              >
                <Input
                  id="create-intent-description"
                  className={classes.createInput}
                  color="primary"
                  placeholder="Intent Description"
                  pattern="[A-Za-z0-9. ]+"
                  inputProps={{
                    "aria-label": "Intent Description",
                  }}
                  type="text"
                  onChange={handleChangeIntentDescription}
                  name={"createIntentDescription"}
                  value={intentDescription}
                />
              </FormControl>

              <br />
              <br />
              <Button
                variant="text"
                classes={{
                  root: classes.eTypeDone,
                  disabled: classes.disabled,
                }}
                type="submit"
                disabled={!!createError}
              >
                {"Create new intent"}
              </Button>
              <br />
              <br />
              <Button
                variant="outlined"
                className={classes.cancelButton}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </form>
          </DialogContent>
          <DialogActions />
        </div>
      </Dialog>
    </div>
  );
};

ViewNewIntent.propTypes = {
  handleCreateNewIntent: PropTypes.func.isRequired,
};

export default ViewNewIntent;
