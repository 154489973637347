import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { Paper } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Android, Web, Apple } from "@material-ui/icons";
import {
  emptyOnboardingStatements,
  readUIConfigOnboardingStatments,
  editOnboardingStatements,
  writeOnboardingStatements,
} from "../../../../../utils/uiCustomizationUtils";
import { changeAppStringTable } from "../../../../../redux/actions/appSchema";
import ThemeCustomization from "./ThemeCustomization";
import { TabPanel } from "../TabPanel";
import OnboardingCustomization from "./OnboardingCustomization";
import { validatePromptTextLanguage } from "../../../../../utils/validate";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../../libs/analytics/slangAnalyticsAPIs";

const styles = makeStyles(theme => ({
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    textAlign: "left",
    background: theme.palette.background.default,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  promptContainer: {
    padding: theme.spacing(2.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  createInput: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  ui: {
    width: 345,
    position: "relative",
    zIndex: 5,
  },
  uiRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uiSurface: {
    position: "absolute",
    width: 325,
    height: "auto",
    bottom: "8.5rem",
    left: "0.65rem",
  },
  mobile: {
    width: 350,
    height: 642,
    zIndex: 10,
  },
  platformControl: {
    display: "flex",
    justifyContent: "flex-start",
    // margin: `0 ${theme.spacing(1)}px`,
  },
  themeButtonGroup: {
    maxWidth: 150,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  components: {
    position: "absolute",
    width: 325,
    height: "auto",
    bottom: "8.5rem",
    left: "0.65rem",
  },
  animComponent: {
    animation: "$fade 1s linear",
  },
  "@keyframes fade": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

const UICustomization = props => {
  const { handleEditAppData } = props;
  const { uiConfigs, appStringTable } = useSelector(state => ({
    uiConfigs: state.appSchema.appDATA.uiConfigs,
    appStringTable: state.appSchema.appStringTable,
  }));
  const [lang, setLang] = useState("en-IN");
  const [onboardingLangSentences, setOnboardingLangSentences] = useState(
    emptyOnboardingStatements || {}
  );
  const [brandColor, setBrandColor] = useState("#4E7F3D");
  const [surfaceStyle, setSurfaceStyle] = useState("gradient");
  const [surfaceHintStyle, setSurfaceHintStyle] = useState("static");
  const [surfaceUIMode, setSurfaceUIMode] = useState("light");
  const [selectedPlatform, setSelectedPlatform] = useState("android");
  const [value, setValue] = useState(0);

  const classes = styles();

  const handleChangeValue = val => {
    setValue(val);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const updateState = (configs = uiConfigs, stringTable = appStringTable) => {
    const selectedPlatformUIConfig = configs?.find(
      item => item.platform === selectedPlatform
    );
    if (selectedPlatformUIConfig) {
      const _uiConfig = selectedPlatformUIConfig.uiConfig;
      setOnboardingLangSentences(
        readUIConfigOnboardingStatments(
          _uiConfig,
          stringTable,
          selectedPlatform
        )
      );
      setBrandColor(_uiConfig.brandColor);
      setSurfaceStyle(_uiConfig.surfaceStyle);
      setSurfaceHintStyle(_uiConfig.surfaceHintStyle);
      setSurfaceUIMode(_uiConfig.surfaceUIMode);
    } else {
      setOnboardingLangSentences(
        readUIConfigOnboardingStatments({}, stringTable, selectedPlatform)
      );
      setBrandColor("#4E7F3D");
      setSurfaceStyle("gradient");
      setSurfaceHintStyle("static");
      setSurfaceUIMode("light");
    }
  };

  const onChangeText = e => {
    const { name, value } = e.target;
    const newOnboardingStatements = editOnboardingStatements(
      onboardingLangSentences,
      name,
      value,
      lang,
      selectedPlatform
    );
    setOnboardingLangSentences(newOnboardingStatements);
    handleSaveUIConfig(newOnboardingStatements);
  };

  const handleChangePlatform = (event, currentTab) => {
    if (currentTab !== null) {
      setSelectedPlatform(currentTab);
    }
  };

  const handleSaveUIConfig = (
    onboardingStatements = onboardingLangSentences
  ) => {
    const newData = writeOnboardingStatements(
      onboardingStatements,
      appStringTable
    );

    let newUIConfigs = [];
    if (uiConfigs) {
      newUIConfigs = uiConfigs;
    }

    const isPlatformExist = newUIConfigs?.find(
      item => item?.platform === selectedPlatform
    );

    if (isPlatformExist) {
      newUIConfigs = newUIConfigs.map(config => {
        if (config.platform === selectedPlatform) {
          return {
            platform: config.platform,
            uiConfig: {
              name: `ui_config_${config.platform}`,
              ...newData.onboardingStatements,
              brandColor,
              surfaceStyle,
              surfaceHintStyle,
              surfaceUIMode,
            },
          };
        }
        return config;
      });
    } else {
      newUIConfigs.push({
        platform: selectedPlatform,
        uiConfig: {
          name: `ui_config_${selectedPlatform}`,
          ...newData.onboardingStatements,
          brandColor,
          surfaceStyle,
          surfaceHintStyle,
          surfaceUIMode,
        },
      });
    }

    updateState(newUIConfigs, newData.stringTable);

    handleEditAppData(newUIConfigs, "uiConfigs");
    changeAppStringTable({
      appStringTable: newData.stringTable,
    });
    logEvent(SlangSeverityLevels.INFO, SlangEvents.UI_CONFIG_UPDATED, {
      selectedPlatform,
    });
  };

  useEffect(() => {
    updateState();
  }, [selectedPlatform]);

  return (
    <div>
      <div>
        <Paper className={classes.promptPaper}>
          <ToggleButtonGroup
            color="primary"
            value={selectedPlatform}
            exclusive
            onChange={handleChangePlatform}
            aria-label="text alignment"
            className={classes.platformControl}
          >
            <ToggleButton value={"android"}>
              Android &nbsp; <Android />
            </ToggleButton>
            <ToggleButton value={"web"}>
              Web &nbsp; <Web />
            </ToggleButton>
            <ToggleButton value={"ios"}>
              iOS &nbsp; <Apple />
            </ToggleButton>
          </ToggleButtonGroup>
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
              <ThemeCustomization
                {...{
                  classes,
                  surfaceUIMode,
                  setSurfaceUIMode,
                  brandColor,
                  setBrandColor,
                  surfaceHintStyle,
                  setSurfaceHintStyle,
                  surfaceStyle,
                  setSurfaceStyle,
                  handleSaveUIConfig,
                  handleChangeValue,
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <OnboardingCustomization
                {...{
                  classes,
                  onChangeText,
                  onboardingLangSentences,
                  setOnboardingLangSentences,
                  lang,
                  setLang,
                  handleSaveUIConfig,
                  handleChangeValue,
                  brandColor,
                }}
              />
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </div>
    </div>
  );
};

export default UICustomization;
