import * as types from "../types";

export const getSchemaDefinition = (payload = {}) => ({
  type: types.GET_SCHEMA_DEFINITION,
  payload,
  meta: {
    type: "APPUPLOAD",
  },
});

export const validateAppSchema = (payload = {}) => ({
  type: types.VALIDATE_APP_SCHEMA,
  payload,
  meta: {
    type: "APPUPLOAD",
  },
});

export const uploadAppSchema = (payload = {}) => ({
  type: types.UPLOAD_APP_SCHEMA,
  payload,
  meta: {
    type: "APPUPLOAD",
  },
});

export const uploadAppStrings = (payload = {}) => ({
  type: types.UPLOAD_APP_STRING_TABLE,
  payload,
  meta: {
    type: "APPUPLOAD",
  },
});

export const saveAppSchema = (payload = {}) => ({
  type: types.SAVE_APP_SCHEMA,
  payload,
  meta: {
    type: "APPUPLOAD",
  },
});

export const publishAppSchema = (payload = {}) => ({
  type: types.PUBLISH_APP_SCHEMA,
  payload,
  meta: {
    type: "APPUPLOAD",
  },
});

export const startPublish = (payload = {}) => ({
  type: types.PUBLISH_START,
  payload,
});

export const resetState = () => ({
  type: types.RESET_STATE,
});
