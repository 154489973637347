import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectList({
  label,
  noValueString,
  listItems,
  listItemsType,
  selectedValue,
  setSelectedValue,
}) {
  if (!listItems) return null;

  const classes = useStyles();

  const handleSelectChangeChange = event => {
    if (typeof event.target.value !== "undefined")
      setSelectedValue(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="simple-select-outlined-label">{label}</InputLabel>
        {listItemsType === "noCategory" ? (
          <Select
            labelId="simple-select-outlined-label"
            id="SelectListItems"
            value={selectedValue}
            onChange={handleSelectChangeChange}
            label={label}
            fullWidth
          >
            {listItems.map((item, ID) => (
              <MenuItem key={item + ID} value={ID}>
                {item || noValueString}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select
            labelId="simple-select-outlined-label"
            id="SelectListItems"
            value={selectedValue}
            onChange={handleSelectChangeChange}
            label={label}
            fullWidth
          >
            {listItems.Terminal.length > 0 ? (
              <ListSubheader>Terminal</ListSubheader>
            ) : null}
            {listItems.Terminal.length > 0
              ? listItems.Terminal.map((item, ID) => (
                  <MenuItem key={item + ID} value={ID}>
                    {item || noValueString}
                  </MenuItem>
                ))
              : null}
            {listItems["Non-Terminal"].length > 0 ? (
              <ListSubheader>Non-Terminal</ListSubheader>
            ) : null}
            {listItems["Non-Terminal"].length > 0
              ? listItems["Non-Terminal"].map((item, ID) => (
                  <MenuItem
                    key={item + ID}
                    value={listItems.Terminal.length + ID}
                  >
                    {item || noValueString}
                  </MenuItem>
                ))
              : null}
          </Select>
        )}
      </FormControl>
    </div>
  );
}
