import { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../../withTheme";
import { IntentsTableStyles } from "../styles.js";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TablePaginationActionsWrapped from "./pagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { ListItemText, TextField } from "@material-ui/core";

import DeleteConfirm from "./DeleteConfirm";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import DocHelpLink from "../../common/DocLinks";
import ViewNewIntent from "./ViewNewIntent";

let emptyRows = 1;

class IntentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      open: false,
      dialogTitle: "",
      dialogContent: "",
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: [10],
      appIntents: null,
      deleteID: null,
      changePriorityIntent: "",
      priorityError: null,
      priority: null,
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleDeleteClick = ID => {
    this.setState({
      open: true,
      dialogTitle:
        "Deleting an Intent will also delete all utterances and entites associated with it. Are you sure you want to proceed ?",
      deleteID: ID,
    });
  };

  handleEditClick = event => {
    this.setState({
      open: true,
      dialogTitle: "Edit:",
      dialogContent: event.target.id.substring(0, event.target.id.length - 1),
    });
  };

  handleChangeSearch = event => {
    this.setState({
      search: event.target.value,
    });
    this.filterApps(event.target.value);
  };

  handleSearchClick = event => {
    if (this.state.search !== "") {
      this.setState({
        open: true,
      });
    }
  };

  onKeyPress = event => {
    if (event.key === "Enter") {
      // if (this.state.search !== '') {
      //     this.setState({
      //         open: true,
      //     });
      // }
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleMouseOver = name => {
    this.setState({
      show: name,
    });
  };

  handleMouseLeave = name => {
    this.setState({
      show: "",
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  filterApps(text) {
    const currentIntents = this.props.appIntents;
    if (!currentIntents || currentIntents.length === 0) {
      return;
    }

    if (text && text.length > 0) {
      const filteredIntentList = currentIntents.filter(
        intent =>
          intent.name?.toLowerCase().substring(0, text.length) ===
          text.toLowerCase()
      );
      this.setState({
        appIntents: filteredIntentList,
      });
    } else {
      this.setState({
        appIntents: this.props.appIntents,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        appIntents: this.props.appIntents,
      });
    }
  }

  componentDidMount() {
    this.setState({
      appIntents: this.props.appIntents,
    });
  }

  handleDeleteID = () => {
    this.setState({
      open: false,
    });

    this.props.handleDeleteIntent(this.state.deleteID);
  };

  render() {
    const { classes, appName, handleCreateNewIntent, handleEditAppData } =
      this.props;
    const {
      open,
      // search,
      dialogTitle,
      show,
      rowsPerPageOptions,
      rowsPerPage,
      page,
      appIntents,
      priorityError,
      changePriorityIntent,
    } = this.state;
    emptyRows = appIntents
      ? rowsPerPage -
        Math.min(rowsPerPage, appIntents.length - page * rowsPerPage)
      : 0;

    const { handleClose, handleDeleteID } = this;

    const DeleteConfirmProps = {
      handleClose,
      handleDeleteID,
      classes,
    };
    const DeleteConfirmState = {
      open,
      dialogTitle,
    };

    const handlePriorityChange = (event, key) => {
      const priorityValue = +event.target.value;

      if (priorityValue < 1 || priorityValue > 999) {
        this.setState({
          priorityError: "priority can be only between 1 and 999",
        });
      } else {
        this.setState({
          priorityError: null,
        });
      }

      appIntents[key].priority = priorityValue;
    };

    const handlePriorityBlur = event => {
      if (!priorityError) {
        appIntents.sort((first, second) => second.priority - first.priority);
        handleEditAppData(appIntents, "intents");

        this.setState({
          changePriorityIntent: "",
        });
      }
    };

    const handleKeyPress = event => {
      if (event.key === "Enter") {
        handlePriorityBlur(event);
        event.preventDefault();
      }
    };

    return (
      <Paper className={classes.root} elevation={0}>
        <Typography
          variant="h5"
          // color="inherit"
          classes={{ h5: classes.typeFont }}
          style={{ marginLeft: 24 }}
          noWrap
        >
          {"Intents "}
          <DocHelpLink docFor="INTENT" />
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.searchHead} colSpan="3">
                <Input
                  placeholder="Search"
                  className={`${classes.searchInput} ${testClassNames.SEARCH_INTENT_TEXT}`}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  onKeyPress={this.onKeyPress}
                  onChange={this.handleChangeSearch}
                />
              </TableCell>
              <TableCell className={classes.searchHead}>
                <ViewNewIntent
                  {...{
                    handleCreateNewIntent,
                    allIntentNames: appIntents?.map(item => item.name),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.padding}>
                <Typography className={classes.tableHead}>NAME</Typography>
              </TableCell>
              <TableCell className={classes.padding}>
                <Typography className={classes.tableHead}>
                  DESCRIPTION
                </Typography>
              </TableCell>
              <TableCell className={classes.padding}>
                <Typography className={classes.tableHead}>UTTERANCE</Typography>
              </TableCell>
              <TableCell className={classes.padding}>
                <Typography className={classes.tableHead}>PRIORITY</Typography>
              </TableCell>
              <TableCell className={classes.padding}>
                <Typography variant="button" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appIntents ? (
              appIntents
                .sort((first, second) => second.priority - first.priority)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, key) => {
                  if (item) {
                    const ID = page * rowsPerPage + key;
                    if (item.name) {
                      return (
                        <TableRow
                          key={key}
                          onMouseEnter={this.handleMouseOver.bind(
                            this,
                            item.name
                          )}
                          onMouseLeave={this.handleMouseLeave}
                          hover
                        >
                          <TableCell
                            id={item.name + 1}
                            component="th"
                            scope="row"
                          >
                            <div
                              style={{ color: "inherit" }}
                              className={classes.tableBodyLink}
                              onClick={() =>
                                this.props.handlePageChange(item.name)
                              }
                            >
                              {item.name}
                            </div>
                          </TableCell>
                          <TableCell
                            id={item.name + 20}
                            component="th"
                            scope="row"
                          >
                            <div
                              style={{ color: "inherit" }}
                              className={classes.tableBodyLink}
                              onClick={() =>
                                this.props.handlePageChange(item.name)
                              }
                            >
                              {item.description ? item.description : ""}
                            </div>
                          </TableCell>
                          <TableCell id={item.name + 2}>
                            <div
                              style={{ color: "inherit" }}
                              className={classes.tableBodyLink}
                              onClick={() =>
                                this.props.handlePageChange(item.name)
                              }
                            >
                              {item.examples[0]
                                ? item.examples[0].map((item, key) => (
                                    <span key={key}>{item.text} </span>
                                  ))
                                : ""}
                            </div>
                          </TableCell>
                          <TableCell id={item.name + 2}>
                            {changePriorityIntent === item.name + 2 ? (
                              <TextField
                                id="info-priority"
                                label="priority"
                                name="priority"
                                value={item.priority}
                                onChange={event => {
                                  handlePriorityChange(
                                    event,
                                    page * rowsPerPage + key
                                  );
                                }}
                                onKeyPress={handleKeyPress}
                                onBlur={handlePriorityBlur}
                                error={priorityError !== null}
                                autoFocus
                              />
                            ) : (
                              <ListItemText
                                onDoubleClick={() => {
                                  this.setState({
                                    changePriorityIntent: item.name + 2,
                                  });
                                }}
                                className={classes.tableBodyLink}
                                tabIndex={0}
                                name="priority"
                                title="Double Click to edit."
                              >
                                {" "}
                                {item.priority}
                              </ListItemText>
                            )}
                          </TableCell>
                          <TableCell
                            id={item.name + 3}
                            className={classes.edit}
                          >
                            <span
                              style={
                                show === item.name
                                  ? { display: "inherit" }
                                  : { display: "none" }
                              }
                            >
                              <IconButton
                                id={item.name + 6}
                                className={classes.buttonEdit}
                                aria-label="Edit"
                                onClick={() =>
                                  this.props.handlePageChange(item.name)
                                }
                              >
                                <EditIcon id={item.name + 4} />
                              </IconButton>
                              <IconButton
                                id={item.name + 7}
                                className={classes.buttonDelete}
                                aria-label="Delete"
                                onClick={this.handleDeleteClick.bind(this, ID)}
                              >
                                <DeleteIcon id={item.name + 5} />
                              </IconButton>
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  }
                  emptyRows++;
                  return null;
                })
            ) : (
              <TableRow style={{ height: 48 * rowsPerPage }}>
                <TableCell colSpan={5}>
                  {" "}
                  <LinearProgress
                    color="primary"
                    variant="query"
                    className={`${testClassNames.PROGRESS_BAR}`}
                  />{" "}
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow sytle={{ textAlign: "right" }}>
              <TablePagination
                colSpan={6}
                count={appIntents ? appIntents.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={rowsPerPageOptions}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <DeleteConfirm state={DeleteConfirmState} {...DeleteConfirmProps} />
      </Paper>
    );
  }
}

IntentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired,
};

export default withTheme(withStyles(IntentsTableStyles)(IntentsTable));
