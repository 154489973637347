import * as types from "../types";

export const initialState = () => ({
  // profile: localStorage.getItem("U_profile")
  //   ? JSON.parse(localStorage.getItem("U_profile"))
  //   : null,
  email_id: null,
  profile: null,
  isProfileUpdated: null,
  isPlanUpdated: null,
  allPlans: null,
  // allPlans: localStorage.getItem("U_allPlans")
  //   ? JSON.parse(localStorage.getItem("U_allPlans"))
  //   : null,
  selectedPlan: null,
  currentPlan: null,
  userPriviledge: null,
  userIntents: null,
  userTransactions: null,
  userSessions: null,
  error: null,
  errorPlans: null,
  onboardingComplete: localStorage.getItem("SLANG_ONBOARDING_COMPLETE")
    ? JSON.parse(localStorage.getItem("SLANG_ONBOARDING_COMPLETE"))
    : null,
  onboardingStep: localStorage.getItem("SLANG_ONBOARDING_STEP")
    ? JSON.parse(localStorage.getItem("SLANG_ONBOARDING_STEP"))
    : null,
});

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.USER_CREATE:
      return {
        ...state,
        email_id: action.payload.email_id,
        error: action.payload.error,
      };

    case types.USER_GET_PROFILE:
      return {
        ...state,
        profile: action.payload.profile,
        error: action.payload.error,
      };
    case types.USER_GET_PLAN:
      return {
        ...state,
        currentPlan: action.payload.currentPlan,
        error: action.payload.error,
      };

    case types.USER_UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload.profile,
        error: action.payload.error,
        isProfileUpdated: action.payload.isProfileUpdated,
      };
    case types.USER_UPDATE_PLAN:
      return {
        ...state,
        selectedPlan: action.payload.selectedPlan,
        currentPlan: action.payload.currentPlan,
        error: action.payload.error,
        isPlanUpdated: action.payload.isPlanUpdated,
      };

    case types.USER_GET_ALL_PLANS:
      return {
        ...state,
        allPlans: action.payload.allPlans,
        // error: action.payload.error,
        errorPlans: action.payload.errorPlans,
      };

    case types.USER_SESSIONS_USAGE:
      return {
        ...state,
        userSessions: action.payload.userSessions,
        error: action.payload.error,
      };
    case types.USER_TRANSC_USAGE:
      return {
        ...state,
        userTransactions: action.payload.userTransactions,
        error: action.payload.error,
      };

    case types.USER_INTENT_USAGE:
      return {
        ...state,
        userIntents: action.payload.userIntents,
        error: action.payload.error,
      };

    case types.USER_GET_PRIVLG:
      return {
        ...state,
        userPriviledge: action.payload.userPriviledge,
        error: action.payload.error,
      };
    case types.USER_RESET_IS_PLAN_UPDATE:
      return {
        ...state,
        isPlanUpdated: false,
        error: null,
      };
    case types.USER_RESET_IS_PROFILE_UPDATE:
      return {
        ...state,
        isProfileUpdated: null,
        error: null,
      };
    case types.USER_ONBOARDING:
      return {
        ...state,
        onboardingComplete: action.payload.onboardingComplete,
        onboardingStep: action.payload.onboardingStep,
      };

    default:
      return state;
  }
};

export default reducer;
