/**
 *
 * @param {string} email
 * @returns {bool} wether the user has a slanglabs email or not
 */
const checkIfUserBelongsToSlangLabs = email => {
  const username = email.split("@")[1];
  return username && username.toUpperCase() === "slanglabs.in".toUpperCase();
};

module.exports = {
  checkIfUserBelongsToSlangLabs,
};
