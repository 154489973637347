import * as types from "../types";

export const getAPI = (payload = {}) => ({
  type: types.GET_API,
  payload,
  meta: {
    type: "APIACTION",
  },
});

export const createAPI = (payload = {}) => ({
  type: types.CREATE_API,
  payload,
  meta: {
    type: "APIACTION",
  },
});

export const resetAPI = (payload = {}) => ({
  type: types.RESET_API,
  payload,
  meta: {
    type: "APIACTION",
  },
});
export const resetErrorAPI = () => ({
  type: types.RESET_ERROR,
});
