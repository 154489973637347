import { useEffect, useState } from "react";
import Layout from "../../common/layouts/defaultLayout";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppConfig from "../../../app.config";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import { GetSetAppMetadata } from "../../../utils/auth/handleAuthState";

// rbac
import AllPermissions from "../../../utils/rbac/rbac-rules";
import Can from "../../../utils/rbac/Can";

import {
  getDomainFullList,
  createAssistantDomain,
  deleteAssistantDomain,
  getNewDomainsList,
  getSchemaDefinition,
  getAllAssistantTemplates,
  updateAssistantDomain,
} from "../../../libs/slang/asstAPIs";
import { styles } from "../styles";
import { withStyles } from "@material-ui/core/styles";
import { getSideNavShortcuts } from "../../common/routes/RouteControl";

import NewAssistatantDomainCard from "./NewAssistatantDomainCard";
import AssistantDomainCards from "./AssistantDomainCards";
import CreateAssistantDomainDialog from "./CreateAssistantDomainDialog";
import UpdateAssistantDomainDialog from "./UpdateAssistantDomainDialog";

import ConfirmDialog from "../components/ConfirmDialog";
import WelcomeDialog from "../components/WelcomeDialog";
import { getEqualOrGreater } from "../../../utils/versionUtils";

import { makeStyles } from "@material-ui/styles";
// @ts-expect-error
const useStyles = makeStyles(theme => styles());

const AssistantDomainsHome = (props: any) => {
  const [notify, setNotify] = useState({
    variant: "info",
    message: "msg",
  });
  const [domains, setDomains] = useState({ isLoading: true, domainList: [] });
  const [newCanonicalDomainsList, setNewCanonicalDomainsList] = useState([]);
  const [allTemplates, setAllTemplates] = useState<any>(null);
  const [schemaDefinition, setSchemaDefinition] = useState(null);

  const refreshDomainList = async () => {
    try {
      const data = await getDomainFullList();
      setNewCanonicalDomainsList(await getNewDomainsList());
      setSchemaDefinition(await getSchemaDefinition());
      setAllTemplates(await getAllAssistantTemplates());
      setDomains({ isLoading: false, domainList: data });
    } catch (error: any) {
      setNotify({
        variant: "error",
        message: error.toString(),
      });
      setDomains({ isLoading: false, domainList: [] });
    }
  };

  // fetch the domainList
  useEffect(() => {
    (async () => {
      const orgID = GetSetAppMetadata().organization_id;
      localStorage.setItem("U_orgID", orgID);
      await refreshDomainList();
    })();
  }, []);

  // ui stuff
  const themeType =
    localStorage.getItem("U_DARKMODE") &&
    localStorage.getItem("U_DARKMODE") === "true"
      ? "dark"
      : "light";
  const Image = domains.domainList.filter(
    (item: any) => item.name !== AppConfig.demoAppName
  ).length
    ? "basecamp"
    : "astronaut";
  const backgroundImage = `url("/images/draw_${Image}_${themeType}.png")`;

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedDomainData, setSelectedDomainData] = useState(null);

  const [confirmDeleteState, setConfirmDeleteState] = useState<{
    openDelete: boolean;
    dialogTitle?: string;
    deleteName?: string;
    deleteID?: string;
  }>({
    openDelete: false,
    dialogTitle: "",
  });

  const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false);

  const [isCreatingOrDeleting, setIsCreatingOrDeleting] = useState(false);

  const isLoading = domains.isLoading;
  const classes = useStyles();

  // rbac
  const allowCreateDomain = Can({
    perform: AllPermissions.ASSISTANT_CONFIG.CREATE,
    yes: () => true,
    no: () => null,
  });

  // card props and state
  const newDomainCardProps = {
    classes,
    handleCreateClick: (event: any) => {
      setIsCreateDialogOpen(true);
    },
  };

  const newDomainCardState = {
    isCreatingOrDeleting,
  };

  const domainCardsProps = {
    classes,
    handleDeleteClick: (domainID: any, domainName: any) => {
      setConfirmDeleteState({
        openDelete: true,
        dialogTitle:
          "This is an irreversible action. Are you sure you want to DELETE the domain " +
          domainName +
          " ?",
        deleteName: domainName,
        deleteID: domainID,
      });
    },
    handleSelect: (domainID: any) => {
      const domainData: any = domains.domainList.find(
        (item: any) => item.id === domainID
      );
      const templatesForDomain: any[] = allTemplates?.filter(
        (item: any) => item.id === domainData.template_id
      );
      const allVersions = templatesForDomain.map(item => item.version);
      const upgradableVersions = getEqualOrGreater(
        domainData.template_version,
        allVersions
      );
      setSelectedDomainData({ ...domainData, upgradableVersions });
      console.log(domainData);
    },
  };

  const domainCardsState = {
    isCreatingOrDeleting,
    domainList: domains.domainList,
  };

  const confirmDeleteProps = {
    classes,
    handleClose: () => {
      setConfirmDeleteState({ openDelete: false, dialogTitle: "" });
    },
    handleConfirmDelete: async () => {
      const deleteAppID = confirmDeleteState.deleteID;
      const deleteAppName = confirmDeleteState.deleteName;

      if (deleteAppID !== "") {
        setNotify({
          variant: "info",
          message: "Deleting Domain: " + deleteAppName,
        });
        setIsCreatingOrDeleting(true);
        setConfirmDeleteState({ openDelete: false });
        try {
          await deleteAssistantDomain({ domainID: deleteAppID });
          setNotify({
            variant: "success",
            message: "The domain has been deleted.",
          });
          await refreshDomainList();
        } catch (error: any) {
          setNotify({
            variant: "error",
            message: error.toString(),
          });
          await refreshDomainList();
        }
        setIsCreatingOrDeleting(false);
      }
    },
  };

  const welcomeDialogProps = {
    classes,
    handleDialogClose: () => {
      setIsWelcomeDialogOpen(false);
      localStorage.setItem("I_WELCOME", "DONE");
    },
  };

  const welcomeDialogState = {
    openWelcome: isWelcomeDialogOpen,
  };

  const createDomainDialogProps = {
    classes,
    handleClose: () => {
      setIsCreateDialogOpen(false);
    },
    handleCreateNewAssistantDomain: async ({
      newDomainCanonicalName,
      newDomainDisplayName,
      newDomainDescription,
      assistantTemplateData,
      assistantTemplateStringTable,
    }: {
      newDomainCanonicalName: string;
      newDomainDisplayName: string;
      newDomainDescription: string;
      assistantTemplateData: any;
      assistantTemplateStringTable: any;
    }) => {
      try {
        setIsCreateDialogOpen(false);
        setIsCreatingOrDeleting(true);
        await createAssistantDomain({
          newDomainCanonicalName,
          newDomainDisplayName,
          newDomainDescription,
          assistantTemplateData,
          assistantTemplateStringTable,
        });
        setIsCreateDialogOpen(false);
        await refreshDomainList();
        setIsCreatingOrDeleting(false);
        setNotify({
          variant: "success",
          message: "New Domain has been created",
        });
      } catch (error: any) {
        setNotify({
          variant: "error",
          message: error.toString(),
        });
        setIsCreatingOrDeleting(false);
      }
    },
  };

  const createDomainDialogState = {
    isOpen: isCreateDialogOpen,
    newCanonicalDomainsList,
    existingDomains: domains.domainList,
    schemaDefinition,
  };

  const updateDomainDialogProps = {
    classes,
    handleClose: () => {
      setSelectedDomainData(null);
    },
    handleUpdateNewAssistantDomain: async ({
      newTemplateVersion,
      domainID,
      templateID,
    }: {
      newTemplateVersion: string;
      domainID: any;
      templateID: any;
    }) => {
      try {
        setSelectedDomainData(null);
        setIsCreatingOrDeleting(true);
        await updateAssistantDomain({
          newTemplateVersion,
          domainID,
          templateID,
        });
        await refreshDomainList();
        setIsCreatingOrDeleting(false);
        setNotify({
          variant: "success",
          message: "Domain has been updated",
        });
      } catch (error) {
        console.error(error);
        setNotify({
          variant: "error",
          message: "Something went wrong. Check console.",
        });
        setIsCreatingOrDeleting(false);
      }
    },
  };

  const updateDomainDialogState = {
    isOpen: !!selectedDomainData,
    templates: allTemplates,
    domainData: selectedDomainData,
  };

  return (
    <Layout
      notify={notify}
      backgroundImage={backgroundImage}
      sideNavShortcuts={getSideNavShortcuts()}
      currentPage="Domain"
    >
      <div>
        <div style={{ height: 8, width: "100%" }}>
          {(isLoading || isCreatingOrDeleting) && (
            <LinearProgress className={`${testClassNames.PROGRESS_BAR}`} />
          )}
        </div>
        <br />
        <Grid container spacing={1}>
          {allowCreateDomain &&
            newCanonicalDomainsList &&
            newCanonicalDomainsList.length > 0 && (
              <Grid item xs={12} md={3} sm={4} lg={2} xl={1}>
                <NewAssistatantDomainCard
                  {...newDomainCardProps}
                  state={newDomainCardState}
                />
              </Grid>
            )}
          <AssistantDomainCards
            {...domainCardsProps}
            state={domainCardsState}
          />
        </Grid>
        <ConfirmDialog {...confirmDeleteProps} state={confirmDeleteState} />
        <WelcomeDialog {...welcomeDialogProps} state={welcomeDialogState} />
        <CreateAssistantDomainDialog
          {...createDomainDialogProps}
          state={createDomainDialogState}
        />
        <UpdateAssistantDomainDialog
          {...updateDomainDialogProps}
          state={updateDomainDialogState}
        />
      </div>
    </Layout>
  );
};

export default AssistantDomainsHome;
