import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import Right from "@material-ui/icons/ChevronRightSharp";
import Left from "@material-ui/icons/ChevronLeftSharp";
import axios from "axios";
import AppConfig from "../../../../app.config";
import { makeStyles } from "@material-ui/core/styles";
const { HUBSPOT_CDN } = AppConfig;

const useStyles = makeStyles(theme => ({
  configBox: {
    textAlign: "left",
  },
  errorState: {
    fontWeight: 900,
    color: "indianred",
  },
  space: {
    height: "2em",
  },
  textImportant: {
    color: theme.palette.primary.main,
    margin: `0 ${theme.spacing(0.5)}px`,
  },
  theDotsChecked: {
    cursor: "pointer",
    fontSize: "3rem",
    marginRight: 3,
    color: theme.palette.text.primary,
    textShadow: "0px 0px 3px " + theme.palette.text.primary,
    zIndex: 200,
  },
  theDots: {
    cursor: "pointer",
    fontSize: "3rem",
    marginRight: 3,
    color: theme.palette.text.hint,
    textShadow: "none",
    zIndex: 200,
  },
  slideContainer: {
    height: "250px",
    overflow: "hidden",
  },
  slide: {
    position: "relative",
    margin: " 0 auto",
    width: "100%",
    maxWidth: "450px",
    height: "250px",
    verticalAlign: "middle",
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 1s, visibility 1s",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  rightButton: { position: "relative", left: "-60px", zIndex: 100 },
  leftButton: { position: "relative", left: "60px", zIndex: 100 },
}));

const Carousel = props => {
  const { classes, imageList, timeToSlide = 3000 } = props;

  const [checked, setChecked] = React.useState(0);

  const slideIndex = imageList ? imageList.length - 1 : 0;

  const ref = React.useRef();

  const handleGotoSlide = i => {
    clearInterval(ref.current);
    if (typeof i !== "undefined") setChecked(i);
  };

  const handleRunCarousel = () => {
    setChecked(prev => (prev < slideIndex ? 1 + prev : 0));
  };

  const handleChangeRight = () => {
    clearInterval(ref.current);
    setChecked(prev => (prev < slideIndex ? 1 + prev : 0));
  };

  const handleChangeLeft = () => {
    clearInterval(ref.current);
    setChecked(prev => (prev !== 0 ? prev - 1 : slideIndex));
  };

  React.useEffect(() => {
    if (slideIndex) {
      ref.current = setInterval(handleRunCarousel, timeToSlide);
      imageList.forEach(imgName => {
        const loadImg = new Image();
        loadImg.src = HUBSPOT_CDN + imgName;
      });
    }
  }, [slideIndex]);

  React.useEffect(() => {
    return () => {
      clearInterval(ref.current);
      // cleanup
    };
  }, []);

  const carouselContent = () => {
    return imageList.map((imgName, key) => {
      const baseStyle = {
        top: `-${key * 250}px`,
        backgroundImage: `url('${HUBSPOT_CDN + imgName}')`,
      };
      return (
        <div
          key={key}
          src={HUBSPOT_CDN + imgName}
          className={classes.slide}
          style={
            checked === key
              ? {
                  ...baseStyle,
                  opacity: 1,
                  visibility: "visible",
                }
              : baseStyle
          }
          // title={imgName}
        />
      );
    });
  };
  return (
    <>
      <Grid xs={1} item>
        <IconButton
          onClick={handleChangeLeft}
          className={classes.leftButton}
          aria-label="left"
        >
          <Left />
        </IconButton>
      </Grid>
      <Grid xs={10} className={classes.slideContainer} item>
        {carouselContent()}
      </Grid>
      <Grid xs={1} item>
        <IconButton
          onClick={handleChangeRight}
          className={classes.rightButton}
          aria-label="right"
        >
          <Right />
        </IconButton>
      </Grid>
      <Grid xs={12} item>
        {imageList.map((n, key) => (
          <span
            key={key}
            className={
              checked === key ? classes.theDotsChecked : classes.theDots
            }
            onClick={() => handleGotoSlide(key)}
          >
            &middot;
          </span>
        ))}
      </Grid>
    </>
  );
};

export default function ReadyToPublish(props) {
  const {
    createAppName,
    selectedAssistant,
    SubDomains,
    UserJourneys,
    isLoading,
  } = props;
  const classes = useStyles();

  const [imageList, setImageList] = React.useState(null);

  React.useEffect(() => {
    const apiCall = async () => {
      try {
        const response = await axios.get(
          `${HUBSPOT_CDN}index.json?rndM${Math.round(
            Math.random() * 314200
          )}=${Math.round(Math.random() * 3142)}`
        );
        setImageList(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    apiCall();

    return () => {
      // cleanup
    };
  }, []);

  const carouselProps = {
    classes,
    imageList,
    timeToSlide: 4000,
  };
  return (
    <Grid alignContent="flex-end" alignItems="stretch" container>
      <Grid
        xs={9}
        alignItems="center"
        alignContent="center"
        style={{ minHeight: 250, minWidth: 450 }}
        container
        item
      >
        {isLoading && imageList ? (
          <Carousel {...carouselProps} />
        ) : (
          <img
            src={`${HUBSPOT_CDN}create_assistant_ready.png`}
            style={{ verticalAlign: "middle", margin: "0 auto" }}
            height="250px"
            alt="pub success"
          />
        )}
      </Grid>
      <Grid xs={3} className={classes.configBox} item>
        <>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {isLoading
              ? "Publishing the assistant..."
              : "Ready to publish the Assistant"}
          </Typography>
          <Typography variant="body1" color="primary" gutterBottom>
            {`${createAppName} (${selectedAssistant})`}
          </Typography>
          <Typography>Sub Domains:</Typography>
          {SubDomains &&
            Object.keys(SubDomains)
              .filter(
                L =>
                  SubDomains[L].enabled &&
                  SubDomains[L].displayName.trim() !== "Common Datasets"
              )
              .map((L, key) => (
                <Typography color="primary" variant="inherit" key={key}>
                  {`${key ? ", " : ""} ${SubDomains[L].displayName}`}
                </Typography>
              ))}
          <Typography>User Journeys:</Typography>
          {UserJourneys &&
            Object.keys(UserJourneys)
              .filter(L => UserJourneys[L].enabled)
              .map((L, key) => (
                <Typography color="primary" variant="inherit" key={key}>
                  {`${key ? ", " : ""} ${UserJourneys[L].displayName}`}
                </Typography>
              ))}
        </>
      </Grid>
    </Grid>
  );
}
