import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  publishAsstConfig,
  createSaveAsstConfig,
  getSDKVersions,
} from "../../../../libs/slang/asstAPIs";
import { resetError } from "../../../../redux/actions/asstTemplates";
import { deleteApp } from "../../../../redux/actions/appSchema";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import ChooseAssistant from "./ChooseAssistant";
import ChooseSubDomainUserJourney from "./ChooseSubDomainUserJourney";
import { CircularProgress } from "@material-ui/core";
import {
  validateAppName,
  namespace_name_regex_reverse,
} from "../../../../utils/validate";
import {
  OptionsToView,
  SelectionToData,
} from "../../../../utils/asstConfigUtils";
import ReadyToPublish from "./ReadyToPublish";
import PublishSuccess from "./PublishSuccess";
import IntegrationSDK from "./IntegrationSDK";
import TryAssistant from "./TryAssistant";

const styles = theme => ({
  root: {
    width: "100%",
  },
  topMargin: {
    marginTop: "20%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  configBox: {
    textAlign: "left",
  },
  errorState: {
    fontWeight: 900,
    color: "indianred",
  },
  space: {
    height: "2em",
  },
  textImportant: {
    color: theme.palette.primary.main,
    margin: `0 ${theme.spacing(0.5)}px`,
  },
  theDotsChecked: {
    cursor: "pointer",
    fontSize: "3rem",
    color: theme.palette.text.primary,
    textShadow: "0px 0px 3px " + theme.palette.text.primary,
  },
  theDots: {
    cursor: "pointer",
    fontSize: "3rem",
    color: theme.palette.text.hint,
    textShadow: "none",
  },
});

function getSteps() {
  return [
    "Select your app domain",
    "Choose User-Journeys",
    "Publish the Assistant",
    "Integrate/Try the assistant",
  ];
}

const FadeIn = ({ children }) => (
  <Fade in={true} timeout={{ enter: 500, exit: 1000 }}>
    <div>{children}</div>
  </Fade>
);

const CreatePublishAConfigStepper = props => {
  const { domainFullList, error } = useSelector(state => ({
    domainFullList: state.asstTemplates.templatesList,
    error: state.asstTemplates.error || state.appSchema.error,
  }));

  const dispatchRedux = useDispatch();

  const {
    handleClose,
    classes,
    apikey,
    isLoading,
    getNewAppList,
    setIsLoading,
  } = props;

  const val = useRef();
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [activeStep, setactiveStep] = useState(0);
  const [SubDomains, setSubDomains] = useState({});
  const [UserJourneys, setUserJourneys] = useState({});
  const [createAppName, setCreateAppName] = useState("");
  const [createError, setCreateError] = useState(false);
  const [submitData, setSubmitData] = useState(null);
  const [asstConfigID, setasstConfigID] = useState(null);
  const [publishSaveError, setPublishSaveError] = useState(null);
  const [sdkVersionsData, setSdkVersionsData] = useState(null);
  const [publishData, setPublishData] = useState(null);
  const [selectAfterPublish, setSelectAfterPublish] = useState(null);

  const domainListUI = domainFullList?.map(domain => {
    let addItems;
    switch (domain.display_name.toLowerCase()) {
      case "retail":
        addItems = {
          url: "/images/add_assistant_retail.png",
          title: "Retail",
        };
        break;
      case "travel":
        addItems = {
          url: "/images/add_assistant_train.png",
          title: "Travel",
        };
        break;
      default:
        addItems = {
          url: "/images/add_assistant_null.png",
          title: domain.display_name,
        };
        break;
    }
    const filterGlobal = domain.userJourneys
      .filter(UJ => UJ.name.toLowerCase() !== "global")
      .map(item => ({
        ...item,
        enabled: true,
      }));
    const subDomainsDisabled = domain.subDomains.map(item => ({
      ...item,
      enabled: false,
    }));
    return {
      ...domain,
      ...addItems,
      userJourneys: filterGlobal,
      subDomains: subDomainsDisabled,
    };
  });

  useEffect(() => {
    if (domainListUI?.length && selectedAssistant === null) {
      setSelectedAssistant(0);
      setSubDomains(OptionsToView(domainListUI[0].subDomains));
      setUserJourneys(OptionsToView(domainListUI[0].userJourneys));
    }
    return () => {};
  }, [selectedAssistant, domainListUI]);

  useEffect(() => {
    val.current = {
      activeStep,
      asstConfigID,
      domainListUI,
      selectedAssistant,
    };
  });
  useEffect(() => {
    return () => {
      const { activeStep, asstConfigID, domainListUI, selectedAssistant } =
        val.current;
      if (activeStep < 3) {
        if (
          asstConfigID &&
          domainListUI?.length &&
          selectedAssistant !== null
        ) {
          dispatchRedux(
            deleteApp({
              deleteAppID: asstConfigID,
              assistant_version:
                domainListUI[selectedAssistant].template_version,
            })
          );
        }
      } else {
        getNewAppList();
        dispatchRedux(resetError());
      }
    };
  }, [handleClose]);

  useEffect(() => {
    const apiCalls = async () => {
      setPublishSaveError(null);
      try {
        // Get the asst SDK versions from CF
        const domain = domainFullList.find(D => D.id === submitData.id);
        getSDKVersions({
          domain: domain.name,
          template_version: domain.template_version,
        }).then(data => {
          if (data) {
            setSdkVersionsData(data);
          }
        });
        const asstID = await createSaveAsstConfig(submitData);
        // Ignore if we started fetching something else
        setasstConfigID(asstID);
        setactiveStep(a => a + 1);
      } catch (error) {
        console.log(error);
        setPublishSaveError("There was an error. Please try again later!");
      } finally {
        setIsLoading(false);
      }
    };
    if (submitData !== null && isLoading && activeStep === 1) {
      apiCalls();
    }

    return () => {};
  }, [submitData, isLoading, setIsLoading, activeStep]);

  useEffect(() => {
    const apiCalls = async () => {
      setPublishSaveError(null);
      try {
        await publishAsstConfig(publishData);
        setactiveStep(a => a + 1);
      } catch (error) {
        console.log(error);
        setPublishSaveError("There was an error. Please try again later!");
      } finally {
        setIsLoading(false);
      }
    };
    if (publishData !== null && isLoading && activeStep === 2) {
      apiCalls();
    }

    return () => {};
  }, [publishData, isLoading, setIsLoading, activeStep]);

  if (!domainFullList?.length) {
    return <CircularProgress className={classes.topMargin} />;
  } else if (error) {
    return (
      <Typography color="error" variant="button">
        {" "}
        There was an error loading this data!
      </Typography>
    );
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return "";
      case 1:
        return (
          publishSaveError ||
          (isLoading ? "This might take few seconds..." : "")
        );
      case 2:
        return (
          publishSaveError ||
          (isLoading ? "This might take few minutes..." : "")
        );
      default:
        return "";
    }
  };

  const handleCreateNewApp = event => {
    event.preventDefault();
    setIsLoading(true);
    const selectedSubdomains = SelectionToData(SubDomains);
    const selectedUserJourneys = SelectionToData(UserJourneys);

    const editedAssistant = {
      ...domainListUI[selectedAssistant],
      userJourneys: selectedUserJourneys,
      subDomains: selectedSubdomains,
    };

    setSubmitData({ ...editedAssistant, name: createAppName });
  };

  const handlePublishNewApp = event => {
    event.preventDefault();
    setIsLoading(true);

    const assistantVersion = domainListUI[selectedAssistant].template_version;
    setPublishData({ asstConfigID, assistantVersion });
  };

  const handleChangeAppName = event => {
    const text = event.target.value
      .replace(namespace_name_regex_reverse, "")
      .replace(/(\.\.)+/g, ".");
    const valid = validateAppName(text.trim(), props.appList);

    setCreateAppName(text);
    setCreateError(!valid);
  };

  const onSelectedAssistantType = ID => {
    setSelectedAssistant(ID);
    setSubDomains(OptionsToView(domainListUI[ID].subDomains));
    setUserJourneys(OptionsToView(domainListUI[ID].userJourneys));
  };

  const handleChangeSD = (name, event) => {
    const enabled = event.target.checked;
    setSubDomains(SD => ({ ...SD, [name]: { ...SD[name], enabled } }));
  };
  const handleChangeUJ = (name, event) => {
    const enabled = event.target.checked;
    setUserJourneys(UJ => ({ ...UJ, [name]: { ...UJ[name], enabled } }));
  };
  const steps = getSteps();

  const handleNext = () => {
    setactiveStep(step => step + 1);
  };
  const handleTryIntegrate = selection => {
    setSelectAfterPublish(selection);
    setactiveStep(step => step + 1);
  };

  const handleBack = () => {
    setactiveStep(step => step - 1);
  };

  const isChosenSubDomain = Object.keys(SubDomains).reduce(
    (acc, cur) => acc || SubDomains[cur].enabled,
    false
  );
  const isChosenUserJourney = Object.keys(UserJourneys).reduce(
    (acc, cur) => acc || UserJourneys[cur].enabled,
    false
  );

  const ChooseSubDomainUserJourneyProps = {
    SubDomains,
    UserJourneys,
    handleChangeSD,
    handleChangeUJ,
    isLoading,
    selectedAssistant: domainListUI[selectedAssistant],
  };
  const ChooseAssistantProps = {
    domains: domainListUI,
    selectedAssistant,
    onSelectedAssistantType,
    createError,
    handleChangeAppName,
    createAppName,
    SubDomains,
    handleChangeSD,
  };

  const ReadyToPublishProps = {
    classes,
    createAppName,
    selectedAssistant:
      selectedAssistant !== null
        ? domainListUI[selectedAssistant].display_name
        : null,
    SubDomains,
    UserJourneys,
    isLoading,
  };
  const PublishSuccessProps = {
    classes,
    apikey,
    createAppName,
    selectedAssistant: ReadyToPublishProps.selectedAssistant,
    asstConfigID,
    sdkVersionsData,
    domain: selectedAssistant
      ? domainListUI[selectedAssistant].display_name.toLowerCase()
      : "fitness",
    major_version: domainListUI[selectedAssistant]?.template_version[0],
  };

  const isChosenSubDomainUserJourney = isChosenUserJourney && isChosenSubDomain;
  const checkAssistantTypeName =
    Boolean(selectedAssistant === null) || !createAppName || createError;
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, key) => (
          <Step key={label}>
            {activeStep === 4 && key + 1 === 4 ? (
              <StepLabel>
                {{ try: "Try ", integrate: "Integrate " }[selectAfterPublish]}{" "}
                the assistant
              </StepLabel>
            ) : (
              <StepLabel>{label}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
      <div
        style={{
          height: activeStep === 0 ? 450 : activeStep > 0 ? 420 : 420,
        }}
      >
        {
          {
            0: (
              <FadeIn>
                <ChooseAssistant {...ChooseAssistantProps} />
              </FadeIn>
            ),
            1: (
              <FadeIn>
                <ChooseSubDomainUserJourney
                  {...ChooseSubDomainUserJourneyProps}
                />
              </FadeIn>
            ),
            2: (
              <FadeIn>
                <ReadyToPublish {...ReadyToPublishProps} />
              </FadeIn>
            ),
            3: (
              <FadeIn>
                <PublishSuccess {...PublishSuccessProps} />
              </FadeIn>
            ),
            4: (
              <FadeIn>
                {
                  {
                    try: <TryAssistant {...PublishSuccessProps} />,
                    integrate: <IntegrationSDK {...PublishSuccessProps} />,
                  }[selectAfterPublish]
                }
              </FadeIn>
            ),
          }[activeStep]
        }
      </div>
      <div>
        {!isChosenSubDomain && activeStep === 0 ? (
          <Typography variant="body2" color="error" gutterBottom>
            Select at least 1 SubDomain
          </Typography>
        ) : createError && activeStep === 0 ? (
          <Typography variant="body2" color="error" gutterBottom>
            Invalid name or App Name already exists
          </Typography>
        ) : null}
        {!isChosenUserJourney && activeStep === 1 ? (
          <Typography variant="body2" color="error" gutterBottom>
            Select at least 1 User Journey
          </Typography>
        ) : null}
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}></Typography>
            <Button variant="contained" onClick={handleBack}>
              Back
            </Button>{" "}
            &nbsp;
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </div>
        ) : (
          <div>
            <Typography
              className={publishSaveError ? classes.errorState : classes.space}
            >
              {getStepContent(activeStep)}
            </Typography>
            <div>
              {activeStep > 0 && activeStep < 3 && (
                <Button
                  onClick={handleBack}
                  className={classes.backButton}
                  disabled={isLoading}
                >
                  {"Back"}
                </Button>
              )}
              {activeStep === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={!isChosenSubDomain || checkAssistantTypeName}
                >
                  {"Next"}
                </Button>
              )}
              {activeStep === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewApp}
                  disabled={!isChosenUserJourney || isLoading}
                >
                  {isLoading && <CircularProgress size={20} />} &nbsp;{" "}
                  {" Next "}
                </Button>
              )}
              {activeStep === 2 && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={handlePublishNewApp}
                >
                  {isLoading && <CircularProgress size={20} />} &nbsp;{" "}
                  {" Publish "}
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleTryIntegrate("integrate")}
                  >
                    {" Integrate Assistant "}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleTryIntegrate("try")}
                  >
                    {" Try Assistant "}
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CreatePublishAConfigStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  apikey: PropTypes.string.isRequired,
};

export default withStyles(styles)(CreatePublishAConfigStepper);
