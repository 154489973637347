import { Styles } from "@material-ui/core/styles/withStyles";
import theme from "./theme";

export const commonStyles: {
  root: any;
  appFrame: any;
  content: any;
  typeFont: any;
  fourOhFour: any;
  sideHeading: any;
  margin: any;
  paper: any;
} = {
  root: {
    textAlign: "center",
    overflow: "hidden",
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  appFrame: {
    height: "100vh",
    width: "100vw",
    zIndex: 1,
    overflow: "auto",
    position: "relative",
    display: "flex",
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginLeft: 90,
  },
  typeFont: {
    fontFamily: "'Work Sans', sans-serif",
  },
  fourOhFour: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginLeft: 0,
  },
  sideHeading: {
    fontFamily: "'Work Sans', sans-serif",
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "left",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(3),
  },
};
