import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { IntentsTableStyles } from "../styles.js";
import withTheme from "../../withTheme";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

import TablePaginationActionsWrapped from "./pagination";
import DeleteConfirm from "./DeleteConfirm";
import AddEditEntity from "./AddEditEntity";
import EntityPromptsChip from "./EntityPromptsChip";
import { readEntityPromptState } from "../../../utils/promptUtils";
import { colourEntity } from "../../../utils/helpers";
import { changeAppStringTable } from "../../../redux/actions/appSchema";
import { testClassNames } from "../../../utils/integrationTestClassNames.js";

const mapDispatchToProps = dispatch => ({
  changeAppStringTable: appStrings =>
    dispatch(changeAppStringTable(appStrings)),
});

let dbClick = 0;
let emptyRows = 1;

const reOrder = listItems => {
  const sortList = listItems.sort((a, b) => {
    return a.priority - b.priority;
  });

  const rePrioritize = sortList.map((item, key) => {
    return { ...item, priority: key + 1 };
  });

  return rePrioritize;
};

const reFromTo = (listItems, from, to) => {
  if (to === from) {
    return listItems;
  }
  const moveUp = to < from;
  let onFlag = false;
  let rePrioritize;

  if (moveUp) {
    rePrioritize = listItems.map((item, key) => {
      if (key === to || key === from) {
        if (key === to) {
          onFlag = true;
          return { ...listItems[from], priority: key + 1 };
        } else if (key === from) {
          onFlag = false;
          return { ...listItems[key - 1], priority: key + 1 };
        }
      } else if (onFlag) {
        return { ...listItems[key - 1], priority: key + 1 };
      }
      return { ...item, priority: key + 1 };
    });
  } else {
    rePrioritize = listItems.map((item, key) => {
      if (key === to || key === from) {
        if (key === from) {
          onFlag = true;
          return { ...listItems[key + 1], priority: key + 1 };
        } else if (key === to) {
          onFlag = false;
          return { ...listItems[from], priority: key + 1 };
        }
      } else if (onFlag) {
        return { ...listItems[key + 1], priority: key + 1 };
      }
      return { ...item, priority: key + 1 };
    });
  }

  return rePrioritize;
};

const colorName = name => {
  const Color = colourEntity(name);
  const colorStyle = {
    backgroundColor: Color.bColor,
    color: Color.fColor,
    borderRadius: 5,
    fontWeight: "bold",
    padding: 4,
    marginLeft: 2,
    marginRight: 2,
  };
  return <span style={colorStyle}>{name}</span>;
};
export class IntentHomeEntityTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: "",
      page: 0,
      rowsPerPage: 5,
      rowsPerPageOptions: [5],
      dialogTitle: "",
      dialogContent: "",
      entityData: {},
      canSubmit: false,
      data: this.props.data2.length ? reOrder(this.props.data2) : [],
      deleteID: null,
      editID: null,
      contextTrigger: true,
      lang: "en-IN",
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  handleEntityEdit = (item, ID, event) => {
    const entityData = item;
    const stringData = this.props.appStringTable;

    if (!this.state.open) {
      this.props.setStateOfEntityDialog(true);
      this.setState(state => ({
        languagePrompts: readEntityPromptState(entityData, stringData),
        stringData,
        open: false,
        contextTrigger: true,
        entityData,
        canSubmit: true,
        lang: typeof event === "string" ? event : "en-IN",
        editID: ID,
      }));
    }
  };

  dbClickEvent = (ID, updown) => {
    let newData = this.props.data2;

    if (updown === "up") {
      if (ID === 0) {
        return false;
      }

      newData = reFromTo(newData, ID, 0);
    } else {
      if (ID === newData.length - 1) {
        return false;
      }
      newData = reFromTo(newData, ID, this.props.data2.length - 1);
    }

    this.setState({
      data: newData.sort((a, b) => {
        return a.priority - b.priority;
      }),
      editEntity: "",
    });

    this.props.handleSaveEntityData(newData, null, null);

    return false;
  };

  singleClickEvent = (ID, updown) => {
    let newData = this.props.data2;

    if (updown === "up") {
      if (ID === 0) {
        return false;
      }

      newData = reFromTo(newData, ID, ID - 1);
    } else {
      if (ID === newData.length - 1) {
        return false;
      }
      newData = reFromTo(newData, ID, ID + 1);
    }

    this.setState({
      data: newData.sort((a, b) => {
        return a.priority - b.priority;
      }),
      editEntity: "",
    });

    this.props.handleSaveEntityData(newData, null, null);

    return false;
  };

  handlePriority = (item, ID, updown) => {
    let clickCount = dbClick;
    let singleClickTimer;
    clickCount++;
    dbClick++;

    if (clickCount === 1) {
      singleClickTimer = setTimeout(() => {
        if (dbClick === 1) {
          clickCount = 0;
          dbClick = 0;
          this.singleClickEvent(ID, updown);
        }
      }, 200);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      dbClick = 0;
      this.dbClickEvent(ID, updown);
    }
  };

  handleDeleteClick = ID => {
    this.setState({
      editEntity: "",
      open: true,
      dialogTitle: "Are you sure you want to delete this entity ?",
      deleteID: ID,
    });
  };

  handleOpenEntity = () => {
    this.props.setStateOfEntityDialog(true);
    this.setState({
      lang: "en-IN",
      editID: null,
      contextTrigger: true,
      entityData: {},
      isRequired: false,
    });
  };

  handleDeleteID = () => {
    const ID = this.state.deleteID;
    const newData = this.props.data2;
    const deleteEntityName = this.props.data2[ID].name;

    if (ID > -1) {
      newData.splice(ID, 1);
    }

    this.setState({
      open: false,
      deleteID: null,
      data: newData,
    });

    this.props.handleSaveEntityData(newData, deleteEntityName, null);
  };

  handleDeleteClose = () => {
    this.setState({
      open: false,
      deleteID: null,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  handleChangeSearch = event => {
    this.setState({
      search: event.target.value,
    });
    this.filterApps(event.target.value);
  };

  filterApps(text) {
    const data = this.props.data2.length ? this.props.data2 : [];
    if (!data || data.length === 0) {
      return;
    }

    if (text && text.length > 0) {
      const filteredAppList = data.map(appItem => {
        if (appItem.name.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          return appItem;
        }
        return null;
      });
      if (typeof filteredAppList !== "undefined") {
        this.setState({ data: filteredAppList });
      }
    } else {
      this.setState({ data });
    }
  }

  componentDidMount() {
    if (this.props.data2.length) {
      const maxPriority = this.props.data2.length;
      const filterList = this.props.data2.filter(
        (item, key) => item.priority === key + 1
      );
      if (maxPriority !== filterList.length) {
        const allEntities = reOrder(this.props.data2);
        this.props.handleSaveEntityData(allEntities);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data2 !== this.props.data2) {
      this.setState({
        data: this.props.data2.length ? reOrder(this.props.data2) : [],
      });
    }
  }

  render() {
    const {
      classes,
      stdTypes,
      appTypes,
      appStringTable,
      setStateOfEntityDialog,
      handleSaveEntityData,
      handleNewEntityType,
      changeAppStringTable,
      data2,
      appDATA,
    } = this.props;
    const {
      open,
      data,
      dialogTitle,
      rowsPerPageOptions,
      rowsPerPage,
      page,
      canSubmit,
      editEntity,
      lang,
      listCheck,
      entityData,
      editID,
    } = this.state;

    emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const { handleDeleteClose, handleDeleteID, handleEntityEdit } = this;

    const DeleteConfirmProps = {
      handleDeleteClose,
      handleDeleteID,
      classes,
    };
    const DeleteConfirmState = {
      open,
      dialogTitle,
    };
    const AddEditEntityProps = {
      appDATA,
      stdTypes,
      appTypes,
      allEntities: data2,
      stringData: appStringTable,
      entityData,
      setStateOfEntityDialog,
      handleSaveEntityData,
      changeAppStringTable,
      editID,
      lang,
      handleNewEntityType,
      classes,
    };
    const AddEditEntityState = {
      openEntity: this.props.entityDialogOpen,
      canSubmit,
      listCheck,
      lang,
    };
    const entityPromptsChipProps = {
      classes,
      handleEntityEdit,
      appStringTable,
    };

    return (
      <Paper className={classes.root} elevation={0}>
        <AddEditEntity state={AddEditEntityState} {...AddEditEntityProps} />
        <form>
          <Table className={classes.table} border={0}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.searchHead} colSpan="6">
                  <Input
                    placeholder="Search"
                    className={`${classes.searchInput} ${testClassNames.ENTITY_SEARCH_INPUT_FIELD}`}
                    inputProps={{
                      "aria-label": "Description",
                    }}
                    onChange={this.handleChangeSearch}
                  />
                </TableCell>
                <TableCell className={classes.searchHead}>
                  <Button
                    onClick={this.handleOpenEntity}
                    variant="text"
                    className={`${classes.intentButton} ${testClassNames.ENTITY_ADD_BUTTON}`}
                  >
                    <Icon>add</Icon>
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.padding}>
                  <Typography className={classes.tableHead}>
                    PRIORITY
                  </Typography>
                </TableCell>
                <TableCell className={classes.padding}>
                  <Typography className={classes.tableHead}>NAME</Typography>
                </TableCell>
                <TableCell className={classes.padding}>
                  <Typography className={classes.tableHead}>TYPE</Typography>
                </TableCell>
                <TableCell className={classes.padding}>
                  <Typography className={classes.tableHead}>
                    REQUIRED
                  </Typography>
                </TableCell>
                <TableCell className={classes.padding}>
                  <Typography className={classes.tableHead}>LIST</Typography>
                </TableCell>
                <TableCell className={classes.padding}>
                  <Typography className={classes.tableHead}>PROMPTS</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody className={`${testClassNames.ENTITY_TYPE_CREATED_LIST}`}>
              {data.length > 0 &&
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, key) => {
                    if (item) {
                      const ID = page * rowsPerPage + key;

                      if (item.name) {
                        if (editEntity !== item.name) {
                          return (
                            <TableRow
                              key={key}
                              id={item.name + 0}
                              className={classes.parentRow}
                              hover
                            >
                              <TableCell
                                id={item.name + 8}
                                component="th"
                                scope="row"
                                className={classes.padding}
                              >
                                <Button
                                  size="small"
                                  className={classes.arrows}
                                  onClick={this.handlePriority.bind(
                                    this,
                                    item,
                                    ID,
                                    "up"
                                  )}
                                  disabled={ID === 0}
                                  title={
                                    "Single click to move one UP, double click to move to the TOP of the list"
                                  }
                                >
                                  <Icon className={classes.iconSearch}>
                                    keyboard_arrow_up
                                  </Icon>
                                </Button>

                                <Button
                                  size="small"
                                  className={classes.arrows}
                                  onClick={this.handlePriority.bind(
                                    this,
                                    item,
                                    ID,
                                    "down"
                                  )}
                                  disabled={ID === data.length - 1}
                                  title={
                                    "Single click to move one DOWN, double click to move to the BOTTOM of the list"
                                  }
                                >
                                  <Icon className={classes.iconSearch}>
                                    keyboard_arrow_down
                                  </Icon>
                                </Button>
                              </TableCell>
                              <TableCell
                                id={item.name + 1}
                                component="th"
                                scope="row"
                                className={classes.paddingPointer}
                                onClick={this.handleEntityEdit.bind(
                                  this,
                                  item,
                                  ID
                                )}
                              >
                                {colorName(item.name)}
                              </TableCell>
                              <TableCell
                                id={item.name + 2}
                                className={classes.paddingPointer}
                                onClick={this.handleEntityEdit.bind(
                                  this,
                                  item,
                                  ID
                                )}
                              >
                                {item.type}
                              </TableCell>
                              <TableCell
                                id={item.name + 9}
                                className={classes.paddingPointer}
                                onClick={this.handleEntityEdit.bind(
                                  this,
                                  item,
                                  ID
                                )}
                              >
                                {item.required ? <Icon>check_circle</Icon> : ""}
                              </TableCell>
                              <TableCell
                                id={item.name + 9}
                                className={classes.paddingPointer}
                                onClick={this.handleEntityEdit.bind(
                                  this,
                                  item,
                                  ID
                                )}
                              >
                                {item.list ? <Icon>check_circle</Icon> : ""}
                              </TableCell>
                              <TableCell
                                id={item.name + 4}
                                className={classes.padding}
                              >
                                <EntityPromptsChip
                                  {...entityPromptsChipProps}
                                  entity={item}
                                  ID={ID}
                                />
                              </TableCell>
                              <TableCell
                                id={item.name + 3}
                                className={classes.delete}
                              >
                                <span className={"hidden"}>
                                  <span
                                    onClick={this.handleDeleteClick.bind(
                                      this,
                                      ID
                                    )}
                                  >
                                    <IconButton
                                      id={item.name + 7}
                                      className={classes.buttonDelete}
                                      aria-label="Delete"
                                    >
                                      <DeleteIcon id={item.name + 5} />
                                    </IconButton>
                                  </span>
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return null;
                        }
                      }
                      return null;
                    }
                    emptyRows++;
                    return null;
                  })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow sytle={{ textAlign: "right" }}>
                <TablePagination
                  colSpan={7}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </form>
        <DeleteConfirm state={DeleteConfirmState} {...DeleteConfirmProps} />
      </Paper>
    );
  }
}

IntentHomeEntityTable.propTypes = {
  classes: PropTypes.object.isRequired,
  appStringTable: PropTypes.object.isRequired,
  entityDialogOpen: PropTypes.bool.isRequired,
  setStateOfEntityDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    appStringTable: state.appSchema.appStringTable,
    appDATA: state.appSchema.appDATA,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(IntentsTableStyles)(IntentHomeEntityTable)));
