import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Layout from "../../common/layouts/defaultLayout";
import { Grid, LinearProgress } from "@material-ui/core";
import AppConfig from "../../../app.config";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import { GetSetAppMetadata } from "../../../utils/auth/handleAuthState";
import { getAPI } from "../../../redux/actions/apikey";

import { getAllOrganizations } from "../../../libs/auth/slang_auth";
import { styles } from "../styles";
import { makeStyles } from "@material-ui/core/styles";
import { getSideNavShortcuts } from "../../common/routes/RouteControl";

import OrganizationCards from "./OrganizationCards";
import WelcomeDialog from "../components/WelcomeDialog";

import { notifyInterface } from "../homeTypes";
import {
  OrganizationIndexInterface,
  orgalizationsListInterface,
  WelcomeDialogPropsInterface,
} from "./OrganizationsHomeTypes";

// @ts-expect-error
const useStyles = makeStyles(theme => styles());

const OrganizationsHome = (props: OrganizationIndexInterface) => {
  const [notify, setNotify] = useState<notifyInterface>({
    variant: "info",
    message: "msg",
  });
  const [organizationsList, setOrganizationsList] =
    useState<orgalizationsListInterface>([]);
  const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatchRedux = useDispatch();
  const classes = useStyles();

  const refreshOrganizations = async () => {
    try {
      const data = await getAllOrganizations();
      setOrganizationsList(data.organizations);
      localStorage.setItem(
        "U_orgName",
        data.organizations.filter(
          (org: { organization_id: string }) =>
            org.organization_id === localStorage.getItem("U_orgID")
        ).organization_name
      );
      setIsLoading(false);
    } catch (error) {
      setNotify({
        variant: "error",
        message: error.toString(),
      });
      setIsLoading(false);
    }
  };

  // fetch the org list
  useEffect(() => {
    (async () => {
      const orgID: string = GetSetAppMetadata().organization_id;
      localStorage.setItem("U_orgID", orgID);
      await refreshOrganizations();
      dispatchRedux(getAPI());
    })();
  }, []);

  // ui stuff
  const themeType: string =
    localStorage.getItem("U_DARKMODE") &&
    localStorage.getItem("U_DARKMODE") === "true"
      ? "dark"
      : "light";
  const Image = organizationsList.filter(
    (item: any) => item.name !== AppConfig.demoAppName
  ).length
    ? "basecamp"
    : "astronaut";
  const backgroundImage = `url("/images/draw_${Image}_${themeType}.png")`;

  const organizationCardsProps = {
    classes,
  };

  const organizationCardsState = {
    organizationsList,
  };

  const welcomeDialogProps: WelcomeDialogPropsInterface = {
    classes,
    handleDialogClose: () => {
      setIsWelcomeDialogOpen(false);
      localStorage.setItem("I_WELCOME", "DONE");
    },
  };

  const welcomeDialogState = {
    openWelcome: isWelcomeDialogOpen,
  };

  return (
    <Layout
      notify={notify}
      backgroundImage={backgroundImage}
      sideNavShortcuts={getSideNavShortcuts()}
      currentPage="Organizations"
    >
      <div>
        <div style={{ height: 8, width: "100%" }}>
          {isLoading && (
            <LinearProgress className={`${testClassNames.PROGRESS_BAR}`} />
          )}
        </div>
        <br />
        <Grid container spacing={1}>
          <OrganizationCards
            {...organizationCardsProps}
            state={organizationCardsState}
          />
        </Grid>
        <WelcomeDialog {...welcomeDialogProps} state={welcomeDialogState} />
      </div>
    </Layout>
  );
};

export default OrganizationsHome;
