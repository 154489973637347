import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { Clear as CloseIcon, Edit } from "@material-ui/icons";
import SelectList from "./SelectList";
// import AppStatesPromptsEdit from "./AppStatesPromptsEdit";
// Uncomment this code if you want to show the user greeting
// and user clarification style prompts
// and comment the line above
import VerticalTabs from "./VerticalTabs";
import { a11yProps, TabPanel } from "./TabPanel";
import VoiceAssistTriggerEdit from "../VoiceAssistTriggerEdit";

const styles = makeStyles(theme => ({
  iconButton: {
    marginRight: 20,
  },
  dialogHeading: {
    textTransform: "capitalize",
  },
  content: {
    padding: `0 ${theme.spacing(1)}px`,
    width: "100%",
    overflow: "hidden",
    minHeight: "650px",
  },
  closeButton: {
    float: "right",
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
  },
  addDialogContent: {
    overflow: "hidden",
  },
}));

function EditUserJourneyDialog({
  appStatesMetadata,
  userJourneyID,
  userJourneyName,
  changeUserJourneyData,
  refAnchor,
}) {
  const { userJourney } = useSelector(state => ({
    userJourney: state.appSchema.appDATA.userJourneys[userJourneyID],
  }));

  const [open, setOpen] = useState(false);
  const [allStates, setAllStates] = useState(appStatesMetadata);
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedAppState, setSelectedAppState] = useState(0);
  const [selectedCondition, setSelectedCondition] = useState(0);
  const [allAppStates, setAllAppStates] = useState([]);
  const [allConditions, setAllConditions] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const editUJRef = useRef(null);

  const updateStates = (appStates = allStates) => {
    const newAppStatesMetadata = appStates.map((item, ID) => ({
      ...item,
      idx: ID,
    }));

    setAllStates(newAppStatesMetadata);

    const appStateNames = [
      ...new Set(
        newAppStatesMetadata.map(item =>
          item.appStateName
            .replace(/_/g, " ")
            .replace(/\w/g, x => x.toUpperCase())
        )
      ),
    ];

    const conditionNames = {
      Terminal: [],
      "Non-Terminal": [],
    };

    const terminalConditionNames = [
      ...new Set(
        newAppStatesMetadata
          .filter(
            item =>
              item.appStateName
                .replace(/_/g, " ")
                .replace(/\w/g, x => x.toUpperCase()) ===
                appStateNames[selectedAppState] && item.isTerminal
          )
          .map(item => item.displayName)
      ),
    ];

    const nonTerminalConditionNames = [
      ...new Set(
        newAppStatesMetadata
          .filter(
            item =>
              item.appStateName
                .replace(/_/g, " ")
                .replace(/\w/g, x => x.toUpperCase()) ===
                appStateNames[selectedAppState] && !item.isTerminal
          )
          .map(item => item.displayName)
      ),
    ];

    conditionNames.Terminal = terminalConditionNames;
    conditionNames["Non-Terminal"] = nonTerminalConditionNames;

    setAllAppStates(appStateNames);
    setAllConditions(conditionNames);

    const _appState = newAppStatesMetadata
      .filter(
        item =>
          item.appStateName
            .replace(/_/g, " ")
            .replace(/\w/g, x => x.toUpperCase()) ===
          appStateNames[selectedAppState]
      )
      .find(item => {
        if (selectedCondition >= conditionNames.Terminal.length) {
          return (
            item.displayName ===
            conditionNames["Non-Terminal"][
              selectedCondition - conditionNames.Terminal.length
            ]
          );
        } else {
          return (
            item.displayName === conditionNames.Terminal[selectedCondition]
          );
        }
      });

    setSelectedValue(_appState ? _appState.idx : 0);
  };

  useEffect(() => {
    if (!open) {
      setSelectedValue(0);
      setSelectedAppState(0);
      setSelectedCondition(0);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const newAppStatesMetadata = appStatesMetadata.map((item, ID) => ({
        ...item,
        uid: ID,
      }));
      setAllStates(newAppStatesMetadata);
      updateStates(newAppStatesMetadata);
    }
  }, [open, selectedAppState, selectedCondition]);

  useEffect(() => {
    setSelectedCondition(0);
  }, [selectedAppState]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectedCondition = condition => {
    setSelectedCondition(condition);
  };

  const verticalAppStateProps = {
    appStateUID: allStates[selectedValue].uid,
    userJourneyID,
    userJourney,
    userJourneyName,
    appStatesMetadata,
    changeUserJourneyData,
    selectedValue,
    isTerminal: allStates[selectedValue].isTerminal,
  };

  const handleCurrentTab = (event, value) => {
    setCurrentTab(value);
  };

  const classes = styles();

  return (
    <>
      <IconButton
        aria-label="View"
        className={classes.iconButton}
        onClick={handleClickOpen}
        ref={refAnchor}
      >
        <Edit />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeading}>
          {`${userJourney.displayName}`}
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid alignContent="center" alignItems="center" spacing={0} container>
            <Grid xs={2} item></Grid>
            <Grid xs={8} item>
              <Tabs
                value={currentTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleCurrentTab}
                centered
                variant="fullWidth"
              >
                <Tab label={"Appstates and Conditions"} {...a11yProps(0)}></Tab>
                <Tab label={"Voice Assist Triggers"} {...a11yProps(1)}></Tab>
              </Tabs>
            </Grid>
            <Grid xs={2} item></Grid>
          </Grid>
          {/* </Paper> */}
          <br />
          <TabPanel value={currentTab} index={0}>
            <Grid
              alignContent="center"
              alignItems="center"
              spacing={3}
              container
            >
              <Grid xs={2} item></Grid>
              <Grid xs={4} item>
                <SelectList
                  label={"App States"}
                  noValueString={"No App State"}
                  listItems={allAppStates}
                  selectedValue={selectedAppState}
                  setSelectedValue={setSelectedAppState}
                  listItemsType={"noCategory"}
                />
              </Grid>
              <Grid xs={4} item>
                <SelectList
                  label={"Condition"}
                  noValueString={"No Condition"}
                  listItems={allConditions}
                  selectedValue={selectedCondition}
                  setSelectedValue={handleSelectedCondition}
                  listItemsType={"category"}
                />
              </Grid>
              <Grid xs={2} item></Grid>
            </Grid>
            <br />
            <VerticalTabs
              {...{
                ...verticalAppStateProps,
              }}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <VoiceAssistTriggerEdit
              {...{
                open,
                classes,
                appStatesMetadata,
                userJourneyID,
                userJourneyName,
                changeUserJourneyData,
              }}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
}

EditUserJourneyDialog.propTypes = {
  classes: PropTypes.object,
  appStatesMetadata: PropTypes.array,
  disabled: PropTypes.bool,
};

export default EditUserJourneyDialog;
