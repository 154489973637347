// @deprecated
// import Auth from "@aws-amplify/auth";
// import Amplify from "@aws-amplify/core";
import AuthConfig from "./config";
import AppConfig from "../../app.config";

const _init = function (headers) {
  return new Promise((resolve, reject) => {
    fetch(AppConfig.SLANG_HOST + AuthConfig.URI, {
      method: "GET",
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(json);
      })
      .catch(err => {
        reject("Failed to initialize:" + err.message);
      });
  });
};

export async function initialize() {
  const getCreds = await _init(AuthConfig.HEADERS);
  // Amplify.configure(getCreds.cognito);
}

export function signup(username, password, promocode) {
  return new Promise(
    (resolve, reject) => reject("deprecated")
    // Auth.signUp({
    //   username: username,
    //   password: password,
    //   attributes: {
    //     "custom:isProdUser": AppConfig.SLANG_ISPROD,
    //     "custom:promo_code": promocode
    //   }
    // })
    //   .then(newUser => {
    //     resolve(newUser);
    //   })
    //   .catch(e => {
    //     reject(e);
    //   })
  );
}

export function login(username, password) {
  return new Promise(
    (resolve, reject) => reject("deprecated")
    // Auth.signIn(username, password)
    //   .then(user => {
    //     resolve(user);
    //   })
    //   .catch(e => {
    //     reject(e);
    //   })
  );
}

export function logout() {
  return new Promise(
    (resolve, reject) => reject("deprecated")
    // Auth.currentAuthenticatedUser()
    //   .then(user => {
    //     Auth.signOut()
    //       .then(data => {
    //         resolve(data);
    //       })
    //       .catch(e => {
    //         reject(e);
    //       });
    //   })
    //   .catch(e => {
    //     reject(e);
    //   })
  );
}

export function changePassword(oldPassword, newPassword) {
  return new Promise(
    (resolve, reject) => reject("deprecated")
    // Auth.currentAuthenticatedUser()
    //   .then(user => {
    //     return Auth.changePassword(user, oldPassword, newPassword);
    //   })
    //   .then(data => {
    //     resolve(data);
    //   })
    //   .catch(e => {
    //     reject(e);
    //   })
  );
}

export function confirmSignup(username, confirmationCode) {
  return new Promise(
    (resolve, reject) => reject("deprecated")
    // Auth.confirmSignUp(username, confirmationCode)
    //   .then(data => {
    //     resolve(data);
    //   })
    //   .catch(e => {
    //     reject(e);
    //   })
  );
}

export function sendConfirmCodeForforgotPassword(username) {
  return new Promise(
    (resolve, reject) => reject("deprecated")
    // Auth.forgotPassword(username)
    //   .then(data => {
    //     resolve(data);
    //   })
    //   .catch(e => {
    //     reject(e);
    //   })
  );
}

export function resetPassword(username, confirmationCode, newPassword) {
  return new Promise(
    (resolve, reject) => reject("deprecated")
    // Auth.forgotPasswordSubmit(username, confirmationCode, newPassword)
    //   .then(data => {
    //     resolve(data);
    //   })
    //   .catch(e => {
    //     reject(e);
    //   })
  );
}

export function isUserLoggedIn() {
  return new Promise((resolve, reject) => {
    reject("deprecated");
    // wait for cognito to init
    // setTimeout(() => {
    //   Auth.currentAuthenticatedUser()
    //     .then(data => {
    //       resolve(data);
    //     })
    //     .catch(e => {
    //       reject(e);
    //     });
    // }, 500);
  });
}
