import { connect } from "react-redux";
import { signout } from "../../redux/actions/login";
import SlangLoader from "../common/slangLoader";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const mapDispatchToProps = dispatch => ({
  userLogout: user => dispatch(signout(user)),
});

export const LogOut = props => {
  const { logout } = useAuth0();

  useEffect(() => {
    logEvent(SlangSeverityLevels.INFO, SlangEvents.SIGNOUT_STARTED);
    props.userLogout({});
  }, []);

  useEffect(() => {
    if (!props.isLoggedIn) {
      logEvent(SlangSeverityLevels.INFO, SlangEvents.SIGNOUT_SUCCESS);
    }
  }, [props.isLoggedIn]);

  if (!props.isLoggedIn) {
    logout({
      returnTo: window.location.origin,
      federated: true,
    });
  }
  return <SlangLoader />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.login.isAuth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogOut);
