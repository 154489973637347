import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import AppConfig from "../../../app.config";

const Langs = AppConfig.LANGUAGES;

const LangButton = props => {
  const { classes, lang, handleLangChange } = props;
  return Langs.map((item, key) => {
    return (
      <Button
        key={key}
        variant="text"
        color={lang === item.code ? "primary" : "initial"}
        className={`${
          lang === item.code ? classes.highlightButton : classes.normalButton
        } ${item.code}`}
        onClick={handleLangChange.bind(this, item.code)}
      >
        {item.locale}
      </Button>
    );
  });
};

LangButton.propTypes = {
  classes: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  handleLangChange: PropTypes.func.isRequired,
};

export default LangButton;
