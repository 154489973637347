export const testClassNames = {
  SIGNUP_BUTTON: "signup_button",
  SIGNIN_BUTTON: "signin_button",
  LOGOUT_BUTTON: "logout_button",
  CONFIRM_BUTTON: "confirm_button",
  HOME_CREATE_NEW_BUDDY: "create_new_buddy",
  HOME_USER_PAGE: "user_page",
  HOME_WELCOME_POPUP_DONE_BUTTON: "home_welcome_popup_done_button",
  HOME_HELP_PAGE: "help_page",
  BUDDY_ANALYTICS_PAGE: "analytics_page",
  BUDDY_HOME_PAGE: "buddy_home_page",
  BUDDY_SAVE_BUTTON: "save_button",
  BUDDY_PUBLISH_UNLOCK_BUTTON: "buddy_publish_unlock_button",
  BUDDY_PUBLISH_STAGE_BUTTON: "publish_stage_button",
  BUDDY_PUBLISH_PROD_BUTTON: "publish_prod_button",
  BUDDY_NAME_EDIT_BUTTON: "buddy_name_edit_button",
  BUDDY_NAME_SAVE_BUTTON: "buddy_name_save_button",
  BUDDY_ADD_INTENT_BUTTON: "add_new_intent",
  BUDDY_ADD_ENTITY_BUTTON: "add_new_entity",
  BUDDY_CANCEL_BUTTON: "cancel_buddy_button",
  BUDDY_CREATE_BUTTON: "create_buddy_button",
  BUDDY_ID_CLIPBOARD: "buddy_id_clipboard",
  SEARCH_INTENT_TEXT: "search_intent_text",
  INTENT_CANCEL_BUTTON: "cancel_intent_button",
  INTENT_CREATE_BUTTON: "create_intent_button",
  INTENT_CREATE_DIALOGBOX: "intent_create_dialogbox",
  PLAYGROUND_APP_LINK: "playground_app_link",
  INTENT_NAME_EDIT_BUTTON: "intent_name_edit_button",
  INTENT_NAME_SAVE_BUTTON: "intent_name_save_button",
  UTTERANCE_ADD_BUTTON: "utterance_add_button",
  UTTERANCE_TABLE_BODY: "utterance_table_body",
  UTTERANCE_SEARCH_INPUT_FIELD: "utterance_search_input_field",
  ENTITY_ADD_BUTTON: "entity_add_button",
  ENTITY_SEARCH_INPUT_FIELD: "entity_search_input_field",
  ENTITY_TABLE_BODY: "entity_table_body",
  ENTITY_SAVE_BUTTON: "entity_save_button",
  ENTITY_cancel_BUTTON: "entity_cancel_button",
  INTENT_PROMPT_LIST: "intent_prompt_list",
  INTENT_PROMPT_DELETE_BUTTON: "intent_prompt_delete_button",
  APP_PROMPT_CLARIFICATION_DELETE_BUTTON:
    "app_prompt_clarification_delete_button",
  INTENT_PROMPT_ADD_BUTTON: "intent_prompt_add_button",
  INTENT_HELP_LIST: "intent_help_list",
  INTENT_HELP_DELETE_BUTTON: "intent_help_delete_button",
  INTENT_HELP_ADD_BUTTON: "intent_help_add_button",
  APP_PROMPT_ADD_BUTTON: "app_prompt_add_button",
  ENTITY_TYPE_SELECTION: "entity_type_selection",
  ENTITY_TYPE_CREATED_LIST: "entity_type_created_list",
  ENTITY_TYPE_CANCEL_BUTTON: "entity_type_cancel_button",
  ACCOUNT_REQUEST_BUTTON: "account_request_button",
  PROGRESS_BAR: "progress_bar",
  CIRCULAR_PROGRESS_BAR: "circular_progress_bar",
  PUBLISH_TO_PROD: "publish_to_prod",
  PLAYGROUND_OPEN_BUTTON: "playground_open_button",
  PLAYGROUND_ENV_BUTTON: "playground_env_button",
  PLAYGROUND_STAGE_ENV_BUTTON: "playground_stage_env_button",
  PLAYGROUND_PROD_ENV_BUTTON: "playground_prod_env_button",
  PLAYGROUND_LANG_BUTTON: "playground_lang_button",
  PLAYGROUND_EN_IN_LANG_BUTTON: "playground_en_in_lang_button",
  PLAYGROUND_Hi_IN_LANG_BUTTON: "playground_hi_in_lang_button",
  PLAYGROUND_INFERENCE_TEST_BUTTON: "playground_inference_test_button",
  PLAYGROUND_INFERENCE_TEST_RESULT: "playground_inference_test_result",
  PLAYGROUND_INFERENCE_READY_TEXT: "playground_inference_ready_text",
  USER_ACCOUNT_EDIT_INFO_BUTTON: "user_account_edit_info_button",
  USER_ACCOUNT_UPDATE_INFO_BUTTON: "user_account_update_info_button",
  ASR_HINTS_AND_STOP_WORDS_LENGTH: "asr_hints_and_stop_words_length",
};
