const catchSchemaValidationError = function (error, appSchema) {
  if (error.match("examples")) {
    const errorPath = error.split(" ", 1)[0].replace("data", "");
    let intent = appSchema;
    errorPath
      .split("/")
      .filter(Boolean)
      .forEach(item => {
        if (item !== "examples") {
          intent = intent[item];
        }
      });
    const sendError =
      "Intent: `" + intent.name + "` should have at least 1 utterance";
    return sendError;
  }
  return error;
};

module.exports = {
  catchSchemaValidationError,
};
