import React from "react";
import { Typography, Grid } from "@material-ui/core/";
import SDKVersions from "../../../common/SDKVersions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  configBox: {
    textAlign: "left",
  },
  textImportant: {
    color: theme.palette.primary.main,
    margin: `0 ${theme.spacing(0.5)}px`,
  },
}));

export default function IntegrationSDK(props) {
  const {
    apikey,
    asstConfigID,
    createAppName,
    selectedAssistant,
    sdkVersionsData,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1200);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Integrate the Assistant
      </Typography>
      <Grid alignItems="center" container>
        <Grid xs={1} item></Grid>
        <Grid xs={10} item>
          <Typography gutterBottom>
            {
              " To integrate the Assistant in your app,  refer to the documentation guide "
            }
            <a
              href="https://docs.slanglabs.in/slang/getting-started/integrating-slang-retail-assistant/code-integration-basic-steps"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.textImportant}
            >
              here
            </a>
          </Typography>
          <Typography>The SDK versions to use</Typography>
          <SDKVersions sdkVersionsData={sdkVersionsData} />
          <br />
          <Typography variant="body2">Here are the keys to use</Typography>
          <Typography variant="body2">
            {" Assistant ID: "}
            <CopyToClipboard text={asstConfigID}>
              <a
                href="#asstID"
                name="asstID"
                onClick={handleClick}
                title="click to copy"
                className={classes.textImportant}
              >
                {asstConfigID}
              </a>
            </CopyToClipboard>
          </Typography>
          <Typography variant="body2">
            API Key:
            <CopyToClipboard text={apikey}>
              <a
                href="#apikey"
                name="apikey"
                onClick={handleClick}
                title="click to copy"
                className={classes.textImportant}
              >
                {apikey}
              </a>
            </CopyToClipboard>
          </Typography>
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="subtitle2" style={{ padding: 10 }}>
          {" "}
          copied to clipboard{" "}
        </Typography>
      </Popover>
    </>
  );
}
