import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const tutorialSteps = [
  {
    label: "Welcome to Slang",
    text: "Its awesome to have you on board. Here you will be able to create and configure your Slang apps. \n Our integration docs can be viewed on the below link.",
    img: "/images/intro_1.png",
  },
  {
    label: "Welcome to Slang",
    text: "Its awesome to have you on board. Here you will be able to create and configure your Slang apps. \n Our integration docs can be viewed on the below link.",
    img: "/images/intro_2.png",
  },
  {
    label: "Welcome to Slang",
    text: "Its awesome to have you on board. Here you will be able to create and configure your Slang apps. \n Our integration docs can be viewed on the below link.",
    img: "/images/intro_4.png",
  },
];

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    marginBottom: 20,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    // height: 450,
    maxHeight: 500,
    overflow: "hidden",
    width: "100%",
  },
  text: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(4),
    marginBottom: 20,
    overflow: "hidden",
    width: "100%",
  },
});

class WelcomePop extends Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;

    const maxSteps = tutorialSteps.length;

    return (
      <div className={classes.root}>
        {/* <Paper square elevation={0} className={classes.header}>
          <Typography variant="subtitle1" >{tutorialSteps[activeStep].label}</Typography>
        </Paper> */}
        {/* <Paper square elevation={0} className={classes.text}>
          <Typography variant="subtitle1" >{tutorialSteps[activeStep].text}</Typography>
        </Paper> */}
        <img
          className={classes.img}
          src={tutorialSteps[activeStep].img}
          alt={tutorialSteps[activeStep].label}
        />
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </div>
    );
  }
}

WelcomePop.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WelcomePop);
