import * as SlangAuth from "../../libs/auth/slang_auth";
import { signIn } from "../actions/login";
import * as types from "../types";
import * as helper from "./helper";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
  updateAnalyticsProps,
} from "../../libs/analytics/slangAnalyticsAPIs";

export const loginActions = store => next => action => {
  if (!action.meta || action.meta.type !== "login") {
    return next(action);
  }

  let {
    username,
    password,
    confirmationCode,
    promocode,
    isConfirm,
    oldPassword,
    newPassword,
    identity,
  } = action.payload;

  switch (action.type) {
    case types.IS_USER_LOGGED_IN:
      // checking if the localStorage value U_identity is set.
      const U_identity = !!(
        localStorage.getItem("U_identity") && localStorage.getItem("U_username")
      );
      const payload = {
        error: null,
        isAuth: U_identity,
      };
      helper.prepareAction(action, payload, store);

      // The below code is working and blocks users who tamper with local storage
      // related to identity to get access auto login to the UI

      // SlangAuth.isUserLoggedIn()
      //   .then(result => {
      //     if (result.success) {
      //       const identity = result.data.username;
      //       const email = result.data.attributes.email;
      //       const U_identity = localStorage.getItem("U_identity");
      //       const U_username = localStorage.getItem("U_username");
      //       if (identity === U_identity && U_username === email) {
      //         const payload = {
      //           error: null,
      //           isAuth: true
      //         };
      //         return helper.prepareAction(action, payload, store);
      //       } else {
      //         // return store.dispatch(signout({}));
      //       }
      //     } else {
      //       // return store.dispatch(signout({}));
      //     }
      //     const payload = {
      //       error: null,
      //       isAuth: false
      //     };
      //     return helper.prepareAction(action, payload, store);
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     const payload = {
      //       error: error,
      //       isAuth: false
      //     };
      //     return helper.prepareAction(action, payload, store);
      //   });
      break;
    case types.SIGNIN:
      identity = identity || localStorage.getItem("U_identity");

      if (identity) {
        const payload = {
          identity,
          isAuth: true,
        };
        updateAnalyticsProps({
          user_email: username,
          user_id: payload.identity,
        });
        logEvent(SlangSeverityLevels.INFO, SlangEvents.SIGNIN_SUCCESS);
        helper.prepareAction(action, payload, store);
        return;
      } else {
        const setItem = [
          {
            key: "U_identity",
            value: identity,
          },
          {
            key: "U_username",
            value: username,
          },
        ];
        const payload = {
          username,
          password: "",
          identity,
          isAuth: true,
          error: null,
        };
        updateAnalyticsProps({
          user_email: username,
          user_id: payload.identity,
        });
        logEvent(SlangSeverityLevels.INFO, SlangEvents.SIGNIN_SUCCESS);
        helper.prepareActionLocal(action, payload, store, setItem);
        return;
      }
    case types.SIGNUP:
      SlangAuth.signup(username, password, promocode).then(result => {
        const setItem = [
          {
            key: "U_username",
            value: username,
          },
        ];
        if (result && result.success) {
          logEvent(SlangSeverityLevels.INFO, SlangEvents.SIGNUP_SUCCESS, {
            username,
            user_email: username,
            confirmation: false,
          });

          store.dispatch(
            signIn({
              username,
              password,
            })
          );
        } else {
          const payload = {
            username,
            error: result.error.message ? result.error.message : result.error,
            isConfirm: false,
            isAuth: false,
          };
          logEvent(SlangSeverityLevels.NOTICE, SlangEvents.SIGNUP_FAILED, {
            username,
            confirmation: false,
            error: payload.error,
          });
          helper.prepareAction(action, payload, store, setItem);
        }
      });

      break;
    case types.CONFIRMSIGNUP:
      SlangAuth.confirmSignup(username, confirmationCode).then(result => {
        if (result && result.success) {
          logEvent(SlangSeverityLevels.INFO, SlangEvents.CONFIRM_CODE_SUCCESS, {
            username,
          });
          logEvent(SlangSeverityLevels.INFO, SlangEvents.SIGNUP_SUCCESS, {
            username,
            user_email: username,
            confirmation: true,
          });
          store.dispatch(
            signIn({
              username,
              password,
            })
          );
        } else {
          const payload = {
            username,
            password: "",
            error: result.error.message
              ? result.error.message
              : "Error Authenticating!",
            isAuth: false,
          };
          logEvent(
            SlangSeverityLevels.WARNING,
            SlangEvents.CONFIRM_CODE_FAILURE,
            {
              username,
              error: result.error.trace,
            }
          );
          helper.prepareAction(action, payload, store);
        }
      });

      break;

    case types.SIGNOUT:
      sessionStorage.clear();
      localStorage.removeItem("U_username");
      localStorage.removeItem("S_apiKey");
      localStorage.removeItem("S_apiSecret");
      localStorage.removeItem("U_identity");
      localStorage.removeItem("U_orgID");
      const payloadSend = {
        error: null,
        isAuth: false,
      };
      helper.prepareAction(action, payloadSend, store);
      return;

    case types.FORGOT:
      if (isConfirm) {
        SlangAuth.resetPassword(username, confirmationCode, password).then(
          result => {
            if (result && result.success) {
              logEvent(
                SlangSeverityLevels.INFO,
                SlangEvents.FORGOT_PASSWORD_SUCCESS,
                {
                  username,
                  confirmation: true,
                }
              );
              store.dispatch(
                signIn({
                  username,
                  password,
                })
              );
            } else {
              let error = "";
              if (result.error != null && "message" in result.error) {
                error = result.error.message;
              } else {
                error = "Unknown error in forgot password";
              }

              const payload = {
                error,
                isConfirm: false,
              };
              logEvent(
                SlangSeverityLevels.WARNING,
                SlangEvents.FORGOT_PASSWORD_FAILURE,
                {
                  username,
                  confirmation: true,
                  error,
                }
              );
              helper.prepareAction(action, payload, store);
            }
          }
        );
      } else {
        SlangAuth.sendConfirmCodeForforgotPassword(username).then(result => {
          if (result && result.success) {
            const payload = {
              username,
              isConfirm: true,
              error: null,
            };
            helper.prepareAction(action, payload, store);
          } else {
            let error = "";
            if (result.error != null && "message" in result.error) {
              error = result.error.message;
            } else {
              error =
                "Unknown error while sending confirmation code for forgot password";
            }

            const payload = {
              error,
              isConfirm: false,
            };
            logEvent(
              SlangSeverityLevels.WARNING,
              SlangEvents.FORGOT_PASSWORD_FAILURE,
              {
                username,
                confirmation: false,
                error,
              }
            );
            helper.prepareAction(action, payload, store);
          }
        });
      }

      break;

    case types.CHANGE_PASSWORD:
      SlangAuth.changePassword(oldPassword, newPassword).then(result => {
        if (result && result.success) {
          const payload = {
            isChanged: true,
            error: false,
          };
          helper.prepareAction(action, payload, store);
        } else {
          console.log(result.error);
          let error = "";
          if (result.error != null && "message" in result.error) {
            error = result.error.message;
          } else {
            error = "Unknown error while changing password";
          }

          const payload = {
            error,
            isChanged: false,
          };
          helper.prepareAction(action, payload, store);
        }
      });

      break;

    default:
      break;
  }
};

export default loginActions;
