import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import MobileBackground from "./SlangSurface/assets/MobileBackground.png";
import { KeyboardArrowLeft, Check } from "@material-ui/icons";
import SlangButton from "./SlangSurface/SlangButton";
import { useState } from "react";
import SlangOnboardingDialog from "./SlangSurface/SlangOnboardingDialog";
import LangButton from "../LangButton";

const OnboardingCustomization = props => {
  const {
    classes,
    onChangeText,
    onboardingLangSentences,
    lang,
    setLang,
    handleSaveUIConfig,
    handleChangeValue,
    brandColor,
  } = props;

  const [value, setValue] = useState(0);

  const handleChangeFocusedUI = val => {
    setValue(val);
  };

  const langButtonProps = {
    lang,
    setLang,
  };

  const onboardingPrimaryText = onboardingLangSentences[lang]
    .onboardingPrimaryText.successTemplates.length
    ? onboardingLangSentences[lang].onboardingPrimaryText.successTemplates[0]
        .value
    : "";

  const onboardingSecondaryText = onboardingLangSentences[lang]
    .onboardingSecondaryText.successTemplates.length
    ? onboardingLangSentences[lang].onboardingSecondaryText.successTemplates[0]
        .value
    : "";

  const onboardingIntroTitle = onboardingLangSentences[lang]
    .onboardingIntroTitle.successTemplates.length
    ? onboardingLangSentences[lang].onboardingIntroTitle.successTemplates[0]
        .value
    : "";
  const onboardingIntroDescription = onboardingLangSentences[lang]
    .onboardingIntroDescription.successTemplates.length
    ? onboardingLangSentences[lang].onboardingIntroDescription
        .successTemplates[0].value
    : "";

  const uiComponents = [
    {
      name: "onboarding",
      component: (
        <SlangButton
          {...{
            onboardingPrimaryText,
            onboardingSecondaryText,
          }}
        />
      ),
    },
    {
      name: "intro",
      component: (
        <SlangOnboardingDialog
          {...{
            onboardingIntroTitle,
            onboardingIntroDescription,
            brandColor,
          }}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <div className={classes.uiRoot}>
          <div className={classes.ui}>
            <img src={MobileBackground} className={classes.mobile} />
            <div className={classes.components}>
              {uiComponents.map((item, index) =>
                value === index ? (
                  <div className={classes.animComponent}>{item.component}</div>
                ) : null
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={4}>
        <div className={classes.promptContainer}>
          <Typography variant="body">Select a Language</Typography>
          <div style={{ minWidth: 400 }}>
            <LangButton {...langButtonProps} />
          </div>
          <br />
          <Typography variant="h6" className={classes.chip} gutterBottom>
            {"Onboarding"}
          </Typography>
          <TextField
            variant="outlined"
            name="onboardingPrimaryText"
            label="Onboarding Primary Text"
            value={onboardingPrimaryText}
            onChange={onChangeText}
            fullWidth
            className={classes.createInput}
            onBlur={() => handleSaveUIConfig()}
            multiline
            size="small"
            onFocus={() => handleChangeFocusedUI(0)}
          />
          <TextField
            variant="outlined"
            name="onboardingSecondaryText"
            label="Onboarding Secondary Text"
            value={onboardingSecondaryText}
            onChange={onChangeText}
            fullWidth
            className={classes.createInput}
            onBlur={() => handleSaveUIConfig()}
            multiline
            size="small"
            onFocus={() => handleChangeFocusedUI(0)}
          />
          <br />
          <br />
          <Typography variant="h6" className={classes.chip} gutterBottom>
            {"Slang Intro"}
          </Typography>
          <TextField
            variant="outlined"
            name="onboardingIntroTitle"
            label="Onboarding Intro Title"
            value={onboardingIntroTitle}
            onChange={onChangeText}
            fullWidth
            className={classes.createInput}
            onBlur={() => handleSaveUIConfig()}
            multiline
            size="small"
            onFocus={() => handleChangeFocusedUI(1)}
          />
          <TextField
            variant="outlined"
            name="onboardingIntroDescription"
            label="Onboarding Intro Description"
            value={onboardingIntroDescription}
            onChange={onChangeText}
            fullWidth
            className={classes.createInput}
            onBlur={() => handleSaveUIConfig()}
            multiline
            size="small"
            onFocus={() => handleChangeFocusedUI(1)}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Button variant="outlined" onClick={() => handleChangeValue(0)}>
            <KeyboardArrowLeft />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default OnboardingCustomization;
