function putItem(key, value, service_name = "default", shouldPersist = true) {
  if (shouldPersist) localStorage.setItem(service_name + "_" + key, value);
  else sessionStorage.setItem(service_name + "_" + key, value);
}

function getItem(key, service_name = "default") {
  const value = sessionStorage.getItem(service_name + "_" + key);
  if (value != null) return value;

  return localStorage.getItem(service_name + "_" + key);
}

function removeItem(key, service_name = "default") {
  sessionStorage.removeItem(service_name + "_" + key);
  localStorage.removeItem(service_name + "_" + key);
}

module.exports = {
  putItem,
  getItem,
  removeItem,
};
