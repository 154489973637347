import { SvgIcon } from "@material-ui/core";

const SlangHelpIcon = props => {
  return (
    <SvgIcon viewBox="0 0 30 30" width="90px" height="90px" {...props}>
      {" "}
      <path
        fill="#ffffff"
        d="M 15.089844 3 C 10.131844 3 7.3202344 5.7778281 7.2402344 9.7988281 L 7.2402344 10 L 11.617188 10 L 11.617188 9.7988281 C 11.713187 8.0228281 12.941828 6.8769531 14.798828 6.8769531 C 16.639828 6.8769531 17.867188 7.9413594 17.867188 9.4433594 C 17.867188 10.945359 17.237297 11.720844 15.154297 12.964844 C 12.925297 14.272844 12.036094 15.726547 12.246094 18.310547 L 12.271484 19 L 16.558594 19 L 16.558594 18.34375 C 16.558594 16.79375 17.156688 16.000812 19.304688 14.757812 C 21.581688 13.416813 22.759766 11.721828 22.759766 9.2988281 C 22.759766 5.5678281 19.708844 3 15.089844 3 z M 14.507812 22 C 12.864813 22 11.839844 22.953813 11.839844 24.507812 C 11.839844 26.047813 12.865813 27 14.507812 27 C 16.149812 27 17.162109 26.047812 17.162109 24.507812 C 17.162109 22.953813 16.150813 22 14.507812 22 z"
      />
    </SvgIcon>
  );
};

export default SlangHelpIcon;
