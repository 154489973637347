import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // getApps,
  // getAppDATA,
  // getAppStringTable,
  // getAppMetaData,
  // getStdEntityTypes,
  changeAppDATA,
  // changeAppStringTable,
  // editAppList,
  // getAppSchemaDef,
  // appNameChange,
  // clearAppDATA,
} from "../../../../redux/actions/appSchema";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HelpPromptsEdit from "../../components/HelpPromptsEdit";
// import IntentHomeHelp from "../../components/IntentHomeHelp";
// import IntentHomePrompts from "../../components/IntentHomePrompts";
import IntentHomeUtteranceTable from "../../components/IntentHomeUtteranceTable";
import IntentHomeEntityTable from "../../components/IntentHomeEntityTable";
import {
  IconButton,
  Typography,
  Toolbar,
  Grid,
  Paper,
  LinearProgress,
} from "@material-ui/core";
import ArrowBackOutlined from "@material-ui/icons/ArrowBackOutlined";
import { TabPanel, a11yProps } from "../../components/TabsContainer";
import asyncComponent from "../../../common/AsyncComponent";

const JsonEditor = asyncComponent(() =>
  import("../../components/JsonEditorTemplate")
);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  paperUtter: {
    padding: theme.spacing(3),
    textAlign: "left",
    marginTop: theme.spacing(0),
  },
  paperEntity: {
    padding: theme.spacing(3),
    textAlign: "left",
    marginTop: theme.spacing(3),
  },
}));

function UtteranceEntityWrapper(props) {
  const { stdTypes } = useSelector(state => ({
    stdTypes: state.appSchema.stdTypes,
  }));
  const dispatchRedux = useDispatch();

  const { appDATA, intentID } = props;
  const intentData = appDATA?.intents[intentID];
  const classes = useStyles();

  const { name, entities, examples } = intentData;

  const [entityDialogOpen, setEntityDialogOpen] = useState(false);

  const setStateOfEntityDialog = state => {
    setEntityDialogOpen(state);
  };
  const handleSaveData = examples => {
    const newAppData = appDATA;
    const index = intentID;

    newAppData.intents[index] = {
      ...newAppData.intents[index],
      examples,
    };

    dispatchRedux(changeAppDATA({ appDATA: newAppData }));
  };

  const handleEntityNameChange = (oldName, newName) => {
    const utterances = examples;

    const newUtterances = utterances.map(utterance => {
      const newUtterance = utterance.map(item => {
        if (item.entity === oldName) {
          return { text: item.text, entity: newName, value: item.value };
        } else {
          return { text: item.text, entity: item.entity, value: item.value };
        }
      });
      return newUtterance;
    });

    handleSaveData(newUtterances);
  };

  const handleSaveEntityData = (entities, oldName = null, newName = null) => {
    const newAppData = appDATA;
    const index = intentID;
    const utterances = examples;

    if (oldName && newName) {
      const newUtterances = utterances.map(utterance => {
        const newUtterance = utterance.map(item => {
          if (item.entity === oldName) {
            return { text: item.text, entity: newName, value: item.value };
          } else {
            return { text: item.text, entity: item.entity, value: item.value };
          }
        });
        return newUtterance;
      });

      newAppData.intents[index] = {
        ...newAppData.intents[index],
        entities,
        examples: newUtterances,
      };
    } else if (oldName) {
      const newUtterances = utterances.map(utterance => {
        const newUtterance = utterance.map(item => {
          if (item.entity === oldName) {
            return { text: item.text };
          } else {
            return item;
          }
        });
        return newUtterance;
      });

      newAppData.intents[index] = {
        ...newAppData.intents[index],
        entities,
        examples: newUtterances,
      };
    } else {
      newAppData.intents[index] = {
        ...newAppData.intents[index],
        entities,
      };
    }

    dispatchRedux(changeAppDATA({ appDATA: newAppData }));
  };

  const handleNewEntityType = (
    newEntityType,
    ID = null,
    newName = null,
    oldName = null
  ) => {
    let newTypes = appDATA.types;
    const DATA = appDATA;
    if (ID !== null || ID === 0) {
      newTypes[ID] = newEntityType;
    } else {
      newTypes = [newEntityType, ...newTypes];
    }

    if (newName && oldName) {
      const newIntents = DATA.intents.map(intent => {
        const newEntities = intent.entities.map(entity => {
          if (entity.type === oldName) {
            return { ...entity, type: newName };
          } else {
            return entity;
          }
        });

        return { ...intent, entities: newEntities };
      });

      dispatchRedux(
        changeAppDATA({ appDATA: { ...DATA, intents: newIntents } })
      );
    } else if (oldName) {
      const newIntents = DATA.intents.map(intent => {
        const newIntent = intent.entities.map(entity => {
          if (entity.type === oldName) {
            return this.handleDeleteEntityData(intent, entity.name);
          } else {
            return intent;
          }
        });

        return newIntent;
      });

      dispatchRedux(
        changeAppDATA({ appDATA: { ...DATA, intents: newIntents } })
      );
    } else {
      dispatchRedux(
        changeAppDATA({
          appDATA: { ...appDATA, types: newTypes },
        })
      );
    }
  };
  return (
    <Grid container xs={12} item>
      <Grid item xs={12}>
        <Paper className={classes.paperUtter} elevation={0}>
          <Grid item sm={12}>
            <Typography variant="h5" noWrap>
              {"Utterances "}
            </Typography>
          </Grid>
          <Grid container>
            {intentData ? (
              <IntentHomeUtteranceTable
                data={examples}
                dataEntities={entities}
                handleSaveData={handleSaveData}
                intentName={name}
                setStateOfEntityDialog={setStateOfEntityDialog}
                entityDialogOpen={entityDialogOpen}
              />
            ) : (
              <Grid item sm={12} style={{ padding: "190px 0" }}>
                <LinearProgress color="primary" variant="query" />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperEntity} elevation={0}>
          <Grid item sm={12}>
            <Typography variant="h5" noWrap>
              {" Entities "}
            </Typography>
          </Grid>
          <Grid container>
            {intentData ? (
              <IntentHomeEntityTable
                appTypes={appDATA.types}
                handleEntityNameChange={handleEntityNameChange}
                handleSaveEntityData={handleSaveEntityData}
                handleNewEntityType={handleNewEntityType}
                data2={entities}
                stdTypes={stdTypes}
                intentName={name}
                setStateOfEntityDialog={setStateOfEntityDialog}
                entityDialogOpen={entityDialogOpen}
              />
            ) : (
              <Grid item sm={12} style={{ padding: "190px 0" }}>
                <LinearProgress color="primary" variant="query" />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default function IntentHomeTabs(props) {
  const { handlePageChange, intentData, appDATA, intentID } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { name } = appDATA.intents[intentID];
  const UtteranceEntityWrapperProps = { appDATA, intentID };

  const HelpPromptsEditProps = { intentID };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            variant="outlined"
            onClick={() => handlePageChange()}
          >
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="body1">{name}</Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Utterance & Entities" {...a11yProps(0)} />
            <Tab label="Help Prompts" {...a11yProps(1)} />
            <Tab label="Power Mode" {...a11yProps(2)} />
            {/* <Tab label="Intent Prompts" {...a11yProps(1)} />
            <Tab label="Intent Help" {...a11yProps(2)} /> */}
          </Tabs>
        </Toolbar>
      </AppBar>
      <TabPanel value={value} index={0}>
        <UtteranceEntityWrapper {...UtteranceEntityWrapperProps} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HelpPromptsEdit {...HelpPromptsEditProps} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <JsonEditor isTabLoaded={value === 2} />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <IntentHomePrompts
          intentData={intentData}
          handleSavePromptData={() => {}}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <IntentHomeHelp
          intentData={intentData}
          handleSavePromptData={() => {}}
        />
      </TabPanel> */}
    </div>
  );
}
