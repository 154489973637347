import { Bar } from "react-chartjs-2";

const DarkMode = localStorage.getItem("U_DARKMODE")
  ? localStorage.getItem("U_DARKMODE") === "true"
  : false;

const defaultOption = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: DarkMode ? "lightgray" : "black",
        },
      },
    ],
  },
};

function BarStackedGraph(props) {
  const { data, isStacked, height } = props;
  defaults.global.defaultFontColor = DarkMode ? "white" : "gray";
  defaults.global.defaultFontSize = 16;
  defaults.global.defaultFontStyle = "bold";
  const options = isStacked
    ? {
        ...defaultOption,
        scales: {
          xAxes: [
            {
              stacked: true,
              min: 0,
              gridLines: defaultOption.scales.xAxes[0].gridLines,
            },
          ],
          yAxes: [
            {
              stacked: true,
              min: 0,
              gridLines: defaultOption.scales.yAxes[0].gridLines,
            },
          ],
        },
      }
    : defaultOption;
  return <Bar data={data} height={height} options={options} />;
}

export default BarStackedGraph;
