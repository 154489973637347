import { useState } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";

function EditField(props) {
  const {
    userJourneyID,
    changeUserJourneyData,
    fieldName,
    userJourneysState,
    currentValue,
  } = props;

  const [editMode, seteditMode] = useState(false);
  const [value, setValue] = useState(currentValue);
  const [selectError, setSelectError] = useState(null);

  const handleBlur = () => {
    if (currentValue !== value && value.length > 2) {
      const newData = userJourneysState[userJourneyID];
      newData[fieldName] = value;
      changeUserJourneyData(newData, userJourneyID);
    } else {
      setValue(currentValue);
    }
    seteditMode(false);
    setSelectError(null);
  };

  const handleChange = event => {
    const text = event.target.value;
    if (text.length < 240) {
      if (fieldName === "name") {
        const currentName = text.replace(/ /g, "_");

        const doesNameExist = userJourneysState.find(
          (D, id) => D.name === currentName && id !== userJourneyID
        );

        if (doesNameExist) {
          setSelectError("User journey 'name' exists");
        } else {
          setSelectError(null);
        }
        setValue(currentName);
      } else {
        setValue(text);
      }
    }
    if (text.length < 3) {
      setSelectError("Should be greater than 2 chars");
    }
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && event.target.name !== "textEdit") {
      handleBlur(event);
      event.preventDefault();
    }
  };
  const handleKeyPressTypography = event => {
    if (event.key === "Enter") {
      seteditMode(true);
      event.preventDefault();
    }
  };

  return editMode ? (
    <TextField
      label={fieldName}
      name={fieldName}
      value={value}
      error={selectError !== null}
      helperText={selectError}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      fullWidth
      autoFocus
      multiline
    />
  ) : (
    <Typography
      onDoubleClick={() => {
        seteditMode(true);
      }}
      tabIndex={0}
      name={"textEdit"}
      onKeyPress={handleKeyPressTypography}
    >
      {currentValue}
    </Typography>
  );
}

EditField.propTypes = {
  userJourneysState: PropTypes.array.isRequired,
  userJourneyID: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
};

export default EditField;
