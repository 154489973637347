const AuthConfig = {};

AuthConfig.URI = "/v1/authenticate/";
AuthConfig.AUTH_BASE = "/v1/auth";
AuthConfig.ORGANIZATION = "/organization";
AuthConfig.ORGANIZATIONS = "/organizations";
AuthConfig.ORGANIZATION_ID = "/:OID";
AuthConfig.HEADERS = {
  "Content-Type": "application/json; charset=UTF-8",
  Initialized:
    "a7a3ff0d0033cabb2630e8d6eb97ce5c297bb12359803b159956023b2035fb6d",
};

// export default AuthConfig;
module.exports = AuthConfig;
