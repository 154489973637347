import { useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu,
  Drawer,
  Icon,
  MenuList,
  MenuItem,
  SvgIcon,
  Tooltip,
  ListItemIcon,
} from "@material-ui/core";
import { MenuBook, Flag } from "@material-ui/icons";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "../withTheme";
import { nav } from "./styles.js";
import { testClassNames } from "../../utils/integrationTestClassNames";
import Can from "../../utils/rbac/Can";
import AllPermissions from "../../utils/rbac/rbac-rules";
import { SideNavRoutes } from "./routes/RouteControl";
import { GetSetUserRoles } from "../../utils/auth/handleAuthState";
import { updateOnboardingStatus } from "../../redux/actions/user";

const HomeIcon = props => (
  <SvgIcon {...props}>
    <g fillRule="evenodd" strokeWidth="1.5">
      <path d="M89.305 18.824L46 1.504 28.914 8.34l43.293 17.32zM18.113 12.656L2.695 18.824 46 36.144l15.402-6.16zM1 69.004l43.738 17.492V38.355L1 20.859zM73.898 27.699v13.582l-1.797-1.078-1.8 2.52-1.802-1.083-1.8 2.52-1.801-1.078-1.797 2.523V32.02l-15.84 6.336v48.141l43.738-17.492V20.86z" />
    </g>
  </SvgIcon>
);

const DarkMode = localStorage.getItem("U_DARKMODE")
  ? localStorage.getItem("U_DARKMODE") === "true"
  : false;

const SideNav = ({
  classes,
  currentPage,
  history,
  appName,
  isDirty,
  shortcuts,
  location,
}) => {
  const { user } = useAuth0();
  const dispatchRedux = useDispatch();
  const { appList } = useSelector(state => ({
    appList: state?.appSchema?.list,
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const catchLink = event => {
    event.preventDefault();
    if (isDirty) {
      // eslint-disable-next-line
      const result = confirm(
        "Oh Jeeves, you may lose unsaved changes, continue?"
      );
      if (result) {
        history.push("/home");
      }
    } else {
      history.push("/home");
    }
  };
  const modeToggled = DarkMode ? "false" : "true";

  const toggleMode = () => {
    localStorage.setItem("U_DARKMODE", modeToggled);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  const logOutConfirm = event => {
    event.preventDefault();
    if (isDirty) {
      // eslint-disable-next-line
      const result = confirm(
        "Sure you want to logout ?\nYou will lose unsaved changes, continue ?"
      );
      if (result) {
        history.push("/logout");
      }
      return;
    }
    history.push("/logout");
  };

  const viewBuddySchema = Can({
    perform: AllPermissions.ASSISTANT_CONFIG.EDIT,
    yes: () => true,
    no: () => null,
  });

  // const viewAssistantConfigAnalytics = Can({
  //   perform: AllPermissions.ANALYTICS.VIEW,
  //   yes: () => true,
  //   no: () => null
  // })

  const checkUser = () => {
    const getRole = GetSetUserRoles();
    return getRole ? getRole[getRole.length - 1] : "visitor";
  };

  const handleHelpMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const startOnboarding = () => {
    let step = 0;
    const complete = false;
    if (appList.length > 0) {
      step = 2;
    }
    dispatchRedux(
      updateOnboardingStatus({
        onboardingComplete: complete,
        onboardingStep: step,
      })
    );
    localStorage.setItem("SLANG_ONBOARDING_STEP", JSON.stringify(step));
    localStorage.setItem("SLANG_ONBOARDING_COMPLETE", JSON.stringify(complete));
    if (history?.location?.pathname !== "/home" && step === 0) {
      history.push("/home");
    }
    if (
      step === 2 &&
      history?.location?.pathname !==
        `/home/assistants/${appList[appList.length - 1].name}`
    ) {
      history.push({
        pathname: "/home/assistants/" + appList[appList.length - 1].name,
        state: {
          nameApp: appList[appList.length - 1].name,
          appVersion: appList[appList.length - 1].major_version,
        },
      });
    }
    handleClose();
  };

  const baseRoute = SideNavRoutes();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className="logo test_home_page">
        <a href="/home" onClick={catchLink}>
          <img
            alt="Slang Labs"
            src="/images/logo.png"
            className={classNames(classes.bigAvatar)}
          />
        </a>
      </div>
      <div className={classes.centerItems}>
        <MenuList>
          {shortcuts?.map(shortcut => {
            return (
              <Link key={shortcut.link} to={shortcut.link}>
                <Tooltip title={shortcut.title} placement="right-end">
                  <MenuItem
                    className={`${
                      currentPage === shortcut.title
                        ? classes.menuItemSelected
                        : classes.menuItem
                    } ${testClassNames.BUDDY_HOME_PAGE}`}
                  >
                    <Icon
                      className={
                        currentPage === shortcut.title
                          ? classes.menuItemSelectedIcon
                          : classes.menuItem
                      }
                    >
                      {shortcut.icon}
                    </Icon>
                  </MenuItem>
                </Tooltip>
              </Link>
            );
          })}
          {appName ? (
            <span>
              {" "}
              {viewBuddySchema && (
                <Link to={baseRoute}>
                  <Tooltip title="Assistant Home" placement="right-end">
                    <MenuItem
                      className={`${
                        currentPage === "intent"
                          ? classes.menuItemSelected
                          : classes.menuItem
                      } ${testClassNames.BUDDY_HOME_PAGE}`}
                    >
                      <HomeIcon
                        viewBox="0 0 90 88"
                        className={
                          currentPage === "intent"
                            ? classNames(
                                classes.menuItemSelected,
                                classes.menuItemSVG
                              )
                            : classNames(classes.menuItem, classes.menuItemSVG)
                        }
                      />
                    </MenuItem>
                  </Tooltip>
                </Link>
              )}
            </span>
          ) : (
            <span></span>
          )}
        </MenuList>
      </div>
      <div>
        <Tooltip title="Toggle Light/Dark mode" placement="right-end">
          <MenuItem
            onClick={toggleMode}
            className={`${
              currentPage === "none"
                ? classes.menuItemSelected
                : classes.menuItem
            } ${testClassNames.HOME_USER_PAGE}`}
          >
            {DarkMode ? (
              <Icon
                className={
                  currentPage === "none"
                    ? classes.menuItemSelectedIcon
                    : classes.menuItem
                }
              >
                light_mode
              </Icon>
            ) : (
              <Icon
                className={
                  currentPage === "none"
                    ? classes.menuItemSelectedIcon
                    : classes.menuItem
                }
              >
                nightlight
              </Icon>
            )}
          </MenuItem>
        </Tooltip>
        <Link to="/home/user">
          <Tooltip title="Account" placement="right-end">
            <MenuItem
              className={`${
                currentPage === "user"
                  ? classes.menuItemSelected
                  : classes.menuItem
              } ${testClassNames.HOME_USER_PAGE}`}
            >
              <img
                alt="Slang Labs"
                src={user?.picture}
                className={classNames(classes.smallAvatar)}
              />
            </MenuItem>
          </Tooltip>
        </Link>
        {checkUser() !== "Slang Admin" ? (
          <>
            <Tooltip title="Help" placement="right-end">
              <MenuItem
                className={`${
                  currentPage === "HELP"
                    ? classes.menuItemSelected
                    : classes.menuItem
                } ${testClassNames.HOME_HELP_PAGE}`}
                onClick={event => handleHelpMenu(event)}
              >
                <Icon
                  className={
                    currentPage === "HELP"
                      ? classes.menuItemSelectedIcon
                      : classes.menuItem
                  }
                >
                  help
                </Icon>
              </MenuItem>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <a
                href="https://docs.slanglabs.in/bahubali-docs"
                rel="noopener noreferrer"
                target="_blank"
              >
                <MenuItem
                  className={`${
                    currentPage === "HELP"
                      ? classes.menuItemSelected
                      : classes.menuItem
                  } ${testClassNames.HOME_HELP_PAGE}`}
                  onClick={handleClose}
                >
                  <ListItemIcon>
                    <MenuBook fontSize="small" />
                  </ListItemIcon>
                  Developer Docs
                </MenuItem>
              </a>
              <MenuItem onClick={startOnboarding}>
                <ListItemIcon>
                  <Flag fontSize="small" />
                </ListItemIcon>
                Start Onboarding
              </MenuItem>
            </Menu>
          </>
        ) : (
          <a
            href="https://docs.slanglabs.in/bahubali-docs"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Tooltip title="Help" placement="right-end">
              <MenuItem
                className={`${
                  currentPage === "HELP"
                    ? classes.menuItemSelected
                    : classes.menuItem
                } ${testClassNames.HOME_HELP_PAGE}`}
              >
                <Icon
                  className={
                    currentPage === "HELP"
                      ? classes.menuItemSelectedIcon
                      : classes.menuItem
                  }
                >
                  help
                </Icon>
              </MenuItem>
            </Tooltip>
          </a>
        )}

        <Tooltip title="Logout" placement="right-end">
          <MenuItem
            onClick={logOutConfirm}
            className={`${classes.menuItem} ${classes.menuLogout} ${testClassNames.LOGOUT_BUTTON}`}
          >
            <Icon className={classes.menuItem}>logout</Icon>
          </MenuItem>
        </Tooltip>
      </div>
    </Drawer>
  );
};

SideNav.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    appName: state.appSchema?.appDATA?.name,
    isDirty: state.appSchema.isDirty,
  };
};

export default withRouter(connect()(withTheme(withStyles(nav)(SideNav))));
