import AppConfig from "../../../../app.config";
import PropTypes from "prop-types";
import Papa from "papaparse";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";

const FileUploader = ({
  assistantType,
  dataName,
  onFileSelectError,
  onFileSelectSuccess,
  loading,
  isUploadingFile,
  setloading,
  subDomain,
}) => {
  let totalRows = 0;
  let rows = 0;
  const chunks = [];
  let page = [];
  let headers;

  const addToChunks = () => {
    const page_number = chunks.length + 1;
    chunks.push({ page_number, data: page });
  };

  const handleError = function (results) {
    onFileSelectError({ error: "Error parsing CSV file " });
    setloading(false);
  };
  const validateHeader = headers => {
    try {
      const csvfields = subDomain.data
        ?.find(data => data.name === dataName)
        ?.value?.csvfields.map(item => item.trim().toLowerCase());

      const userDataCols = headers.map(item => item.trim().toLowerCase());

      if (csvfields) {
        return userDataCols.every(item => csvfields.includes(item));
      } else {
        return false;
      }
    } catch (error) {
      logEvent(
        SlangSeverityLevels.ERROR,
        SlangEvents.SUBDOMAIN_USER_DATA_UPLOAD_FAILURE,
        {
          subdomainName: subDomain.name,
          dataName,
          error,
        }
      );
      console.log(error);
      return false;
    }
  };
  const handleEachRow = (results, parser) => {
    totalRows++;
    rows++;
    if (totalRows === 1) {
      headers = results.data;
      rows = 0;
      if (assistantType === "config" && !validateHeader(headers)) {
        onFileSelectError({
          error: "The CSV file Uploaded is not in correct format",
        });
        parser.abort();
      }
    } else {
      page.push(results.data);
    }
    if (rows === AppConfig.PAGESIZE) {
      addToChunks();
      page = [];
      rows = 0;
    }
  };

  const onComplete = () => {
    setloading(false);
    if (rows) {
      addToChunks();
    }
    onFileSelectSuccess({ chunks, headers, totalRows });
  };

  const parseCSV = file => {
    Papa.parse(file, {
      header: false,
      worker: true,
      step: handleEachRow,
      error: handleError,
      complete: onComplete,
    });
  };

  const handleFileInput = e => {
    setloading(true);
    // handle validations
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * 1024 || file.type !== "text/csv") {
      e.target.value = null;
      onFileSelectError({
        error: "Only CSV files accepted not more than 1GB ",
      });
      setloading(false);
    } else {
      parseCSV(file);
    }
  };

  return (
    <input
      type="file"
      disabled={loading || isUploadingFile}
      accept=".csv"
      onChange={handleFileInput}
    />
  );
};

FileUploader.propTypes = {
  onFileSelectError: PropTypes.func.isRequired,
  onFileSelectSuccess: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isUploadingFile: PropTypes.bool.isRequired,
  setloading: PropTypes.func.isRequired,
};

export default FileUploader;
