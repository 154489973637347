import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  SlangCoachmarkStyle: {
    position: "absolute",
    cursor: "pointer",
    background: "#fff",
    borderRadius: 4,
    textAlign: "left",
    color: "#000",
    filter:
      "drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15))",
    zIndex: 2147482658,
    fontSize: "smaller",
    padding: 16,
    width: 300,
    bottom: "82px",
    left: "calc(-15.5rem/ 2)",
  },
  SlangCoachmarkTitle: {
    margin: "0 0 5px 0",
    fontWeight: 600,
    fontSize: "1.1em",
  },
  SlangCoachmarkDescription: {
    margin: 0,
    fontWeight: 300,
    fontSize: "1em",
    lineHeight: 1,
  },
  SlangArrow: {
    content: "",
    width: 0,
    height: 0,
    position: "absolute",
    transform: "rotate(45deg) skew(20deg, 20deg)",
    left: "47.5%",
    bottom: -5,
    "border-right": "10px solid #fff",
    "border-bottom": "10px solid #fff",
    "border-left": "10px solid transparent",
    "border-top": "10px solid transparent",
    zIndex: 2147482658,
  },
  CloseCoachmark: {
    float: "right",
    padding: 5,
    width: 5,
    height: 5,
    marignRight: 2,
    borderRadius: 20,
    fontSize: 12,
    "line-height": "0.7",
    cursor: "pointer",
    "box-shadow": "0px 0px 2px",
  },
  SlangCancelIcon: {
    position: "absolute",
    top: "5px",
    right: "2px",
    fontWeight: 600,
    height: "18px",
    width: "18px",
    cursor: "pointer",
    opacity: "0.6",
    fontSize: "1.1em",
    color: "#000",
  },
}));

const SlangCoachmark = props => {
  const { onboardingPrimaryText, onboardingSecondaryText } = props;
  const classes = styles();
  return (
    <div id="slang-coachmark" className={classes.SlangCoachmarkStyle}>
      <span className={classes.SlangCancelIcon}>&times;</span>
      <p className={classes.SlangCoachmarkTitle}>
        {onboardingPrimaryText || "You can now talk to your app!"}
      </p>
      <p className={classes.SlangCoachmarkDescription}>
        {onboardingSecondaryText || "Click here to begin..."}
      </p>
      <span className={classes.SlangArrow}></span>
    </div>
  );
};

export default SlangCoachmark;
