import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import WelcomePop from "./WelcomePop";

import { WelcomeDialogInterface } from "./AssistantConfigsHomeTypes";

// const Transition = React.forwardRef<HTMLInputElement>(function(props, ref){
//  return  <Slide
//     direction="up"
//     ref={ref}
//     {...props}
//     timeout={{ enter: 1000, exit: 500 }}
//   />
// )};

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
    />
  );
});

const WelcomeDialog = (props: WelcomeDialogInterface) => {
  const { classes, handleDialogClose, handleChangeOrgName, state } = props;

  const { openWelcome, orgName } = state;

  const WelcomePopProps = { handleDialogClose, handleChangeOrgName, orgName };

  return (
    <Dialog
      open={openWelcome}
      onClose={() => {}}
      // classes={{ paper: classes.firstLoad }}
      // TransitionComponent={Transition}
    >
      <WelcomePop {...WelcomePopProps} />
    </Dialog>
  );
};

export default WelcomeDialog;
