import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import SelectIntent from "./SelectIntent";

const styles = makeStyles(theme => ({
  iconButton: {
    marginRight: 20,
  },
  dialogHeading: {
    textTransform: "capitalize",
  },
  closeButton: {
    float: "right",
  },
  content: {
    overflow: "hidden",
  },
}));

const EditUserJourneyDialog = props => {
  const { intentNames, userJourneys } = useSelector(state => ({
    intentNames: state.appSchema.appDATA.intents.map(item => item.name),
    userJourneys: state.appSchema.appDATA.userJourneys,
  }));

  const {
    disabled,
    userJourneyID,
    userJourney,
    userJourneyName,
    changeUserJourneyData,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectError, setSelectError] = useState(null);
  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [name, setName] = useState("");
  const [selectedIntents, setSelectedIntents] = useState([]);

  const classes = styles();

  useEffect(() => {
    setDisplayName(userJourney.displayName);
    setName(userJourney.name);
    setDescription(userJourney.description);
    setSelectError(null);
    setSelectedIntents(userJourney.intents);
  }, [userJourneyID]);

  // handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDisplayName = event => {
    const text = event.target.value;
    if (text.length < 140) setDisplayName(text);
  };
  const handleChangeDescription = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDescription(text);
    }
  };

  const handleSaveUserJourney = () => {
    let updatedUJ = userJourney;
    updatedUJ = {
      ...updatedUJ,
      displayName,
      description,
      intents: selectedIntents,
    };
    const updatedUserJourneys = userJourneys;
    updatedUserJourneys[userJourneyID] = updatedUJ;
    changeUserJourneyData(updatedUserJourneys, "userJourneys");
    setOpen(false);
  };

  const isDisabled =
    name.length < 2 ||
    displayName.length < 2 ||
    description.length < 3 ||
    selectedIntents.length < 1;

  return (
    <>
      <IconButton
        aria-label="View"
        className={classes.iconButton}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <Icon color="primary">{"edit"}</Icon>
      </IconButton>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        // fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeading}>
          {`Edit: ${userJourneyName}`}
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <>
            <form noValidate autoComplete="off">
              <Grid
                spacing={3}
                alignContent="center"
                alignItems="center"
                container
              >
                <Grid xs={6} item>
                  <TextField
                    id="standard-name"
                    label="Name"
                    name="name"
                    value={name}
                    error={selectError !== null}
                    helperText={selectError}
                    autoFocus
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    id="standard-display-name"
                    label="Display Name"
                    name="displayName"
                    value={displayName}
                    onChange={handleChangeDisplayName}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} className={classes.marginBottom} item>
                  <TextField
                    id="standard-description"
                    label="Description"
                    name="description"
                    value={description}
                    onChange={handleChangeDescription}
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid xs={12} item>
                  <SelectIntent
                    {...{ intentNames, selectedIntents, setSelectedIntents }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Button
                    disabled={selectError !== null}
                    onClick={handleSaveUserJourney}
                    color="primary"
                    fullWidth
                  >
                    {"Update"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

EditUserJourneyDialog.propTypes = {
  userJourneyID: PropTypes.number.isRequired,
  userJourney: PropTypes.object.isRequired,
  userJourneyName: PropTypes.string.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
};

export default EditUserJourneyDialog;
