import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FilterNone";
import EditIcon from "@material-ui/icons/Edit";
import Can from "../../../utils/rbac/Can";
import AllPermissions from "../../../utils/rbac/rbac-rules";

const BuddyCards = props => {
  const { classes, handleDeleteClick, handleCloneClick, state } = props;

  const { apikey, appList, isCreatingOrDeleting } = state;

  return appList.map((item, key) => {
    const hover = "hover";
    const isLoading = !apikey || isCreatingOrDeleting;
    const pathName = Can({
      perform: AllPermissions.ASSISTANT_TEMPLATES.EDIT,
      yes: () => `/home/assistant_templates/${item.name}`,
      no: () => `/home/assistant_templates/${item.name}/analytics`,
    });
    return (
      <Grid
        key={item.name + key}
        item
        xs={12}
        md={3}
        sm={4}
        lg={2}
        xl={1}
        id={key + hover}
      >
        <div className={`${classes.parentCard}`}>
          <Card
            className={`${isLoading ? classes.cardDisabled : classes.card}`}
            elevation={0}
          >
            <Link
              to={
                isLoading
                  ? "/home"
                  : {
                      pathname: pathName,
                      state: { nameApp: item.name },
                    }
              }
            >
              <CardMedia
                className={isLoading ? classes.mediaDisabled : classes.media}
                image={"/images/graphic_app_green_2x.png"}
                title={item.name}
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="body2" component="h4" noWrap>
                  {item.name}
                </Typography>
              </CardContent>
            </Link>
            <span className={classes.buttonSpan}>
              <span className={isLoading ? "hide" : "hidden"}>
                <Link to={isLoading ? "/home" : "/home/" + item.name}>
                  <IconButton
                    id={key + hover + 2}
                    className={classes.buttonEdit}
                    aria-label="Edit"
                    title="Edit Buddy"
                  >
                    <EditIcon id={key + hover + 3} />
                  </IconButton>
                </Link>
                <span>
                  <IconButton
                    id={key + hover + 5}
                    className={classes.buttonClone}
                    aria-label="Clone"
                    title="Clone this Buddy"
                    onClick={
                      isLoading
                        ? null
                        : handleCloneClick.bind(this, item.id, item.name)
                    }
                  >
                    <CopyIcon id={key + hover + 5} />
                  </IconButton>
                </span>
                <span>
                  <IconButton
                    id={key + hover + 4}
                    className={classes.buttonDelete}
                    onClick={
                      isLoading
                        ? null
                        : handleDeleteClick.bind(this, item.id, item.name)
                    }
                    aria-label="Delete"
                    title="Delete Buddy"
                  >
                    <DeleteIcon id={key + hover + 5} />
                  </IconButton>
                </span>
              </span>
            </span>
          </Card>
        </div>
      </Grid>
    );
  });
};

export default BuddyCards;
