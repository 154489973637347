import { combineReducers } from "redux";

import * as login from "./reducers/login";
import * as apikey from "./reducers/apikey";
import * as user from "./reducers/user";
import * as appSchema from "./reducers/appSchema";
import * as appDataUpload from "./reducers/appDataUpload";
import * as asstDomain from "./reducers/asstDomain";
import * as asstTemplates from "./reducers/asstTemplates";
import * as asstConfig from "./reducers/asstConfig";

const toAppReducer = () => ({
  login: login.reducer,
  user: user.reducer,
  apikey: apikey.reducer,
  appSchema: appSchema.reducer,
  appDataUpload: appDataUpload.reducer,
  asstDomain: asstDomain.reducer,
  asstConfig: asstConfig.reducer,
  asstTemplates: asstTemplates.reducer,
});

const appReducer = combineReducers(toAppReducer());

export const initialState = {
  login: login.initialState(),
  user: user.initialState(),
  apikey: apikey.initialState(),
  appSchema: appSchema.initialState(),
  appDataUpload: appDataUpload.initialState(),
  asstDomain: asstDomain.initialState(),
  asstConfig: asstConfig.initialState(),
  asstTemplates: asstTemplates.initialState(),
};

export const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT") {
    // TODO: fix refresh state
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
