import Bowser from "bowser";

const detectBrowser = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  const safari_msg =
    "Slang Console on Safari is currently unstable. We recommend using Chrome or FireFox";
  const other_msg =
    "We advise using latest versions Chrome or Firefox as the Slang Console works best in these browsers.";

  const isValidBrowser = browser.satisfies({
    chrome: ">=30",
    firefox: ">=41",
  });
  const isSafari = browser.satisfies({
    safari: ">=5",
  });

  const message = !isValidBrowser ? (isSafari ? safari_msg : other_msg) : null;

  return message;
};

export default detectBrowser;
