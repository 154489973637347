import { useEffect, useState } from "react";
import { getSDKVersions } from "../../libs/slang/asstAPIs";

import { Typography, Grid } from "@material-ui/core/";

export default function SDKVersions(props) {
  const { domainData, sdkVersionsData, big } = props;

  const [AllSDKVersions, setAllSDKVersion] = useState(sdkVersionsData);

  useEffect(() => {
    // Pull data from CF if not sent in props
    if (
      !sdkVersionsData &&
      domainData?.domain &&
      domainData?.template_version
    ) {
      getSDKVersions({
        domain: domainData.domain,
        template_version: domainData.template_version,
      }).then(data => {
        if (data) {
          setAllSDKVersion(data);
        }
      });
    }

    return () => {
      // cleanup
    };
  }, [domainData]);

  // return null if no data
  if (!AllSDKVersions) return null;

  const sdk_versions = AllSDKVersions.sdk_versions;

  const sdkList = Object.keys(sdk_versions);
  return (
    <Grid
      item
      alignContent="flex-start"
      alignItems="center"
      justify="center"
      style={{ textAlign: "center" }}
      container
    >
      {sdkList?.map(sdk => (
        <Grid
          xs={
            sdkList.length > 3 ? Math.round(12 / sdkList.length) : big ? 3 : 2
          }
          md={
            sdkList.length > 3 ? Math.round(12 / sdkList.length) : big ? 4 : 3
          }
          xl={1}
          key={sdk}
          item
        >
          <a
            href="https://docs.slanglabs.in/slang/getting-started/integrating-slang-retail-assistant/code-integration-basic-steps"
            rel="noopener noreferrer"
            target="_blank"
            title={sdk.replace(/_/g, " ")}
            style={{ color: "inherit", textTransform: "capitalize" }}
          >
            <div
              alt={`${sdk} assistant`}
              style={{
                margin: "0 auto",
                height: big ? "120px" : "40px",
                width: big ? "120px" : "40px",
                backgroundImage: `url('/images/${sdk}.png')`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
            <Typography variant="subtitle2"> {sdk_versions[sdk]} </Typography>
          </a>
        </Grid>
      ))}
    </Grid>
  );
}
