import { deleteKeyArray } from "./helpers";
import AppConfig from "../app.config";
const Langs = AppConfig.LANGUAGES;

export const updateASRHintsState = (appData, lang, name) => {
  const updateData = appData[name].filter(
    hint => hint.language && hint.language === lang
  );
  const newAsrHints = updateData.length ? updateData[0].phrases : [];
  return newAsrHints;
};
export const updateHintsState = (data, lang) => {
  const updateData = data.filter(
    hint => hint.language && hint.language === lang
  );
  const newAsrHints = updateData.length ? updateData[0].phrases : [];
  return newAsrHints;
};

export const getLangName = lang => {
  return Langs.filter(item => item.code === lang)[0].locale;
};

export const deletePhrase = (chipData, key) => {
  return deleteKeyArray(chipData, key);
};

export const addStateToSchema = (prevData, lang, name, chipData) => {
  const updateData = prevData[name].filter(
    hint => hint.language && hint.language !== lang
  );
  const newAppData = {
    ...prevData,
    [name]: [...updateData, { language: lang, phrases: chipData }],
  };
  return newAppData;
};
export const createDataForSchema = (
  prevData,
  selectedValue,
  lang,
  chipData
) => {
  let _langForHintsExists = false;

  const newUIHints = prevData;
  const oldHints = newUIHints[selectedValue].hints;

  let newHints = oldHints.map(hint => {
    if (hint?.language === lang) {
      _langForHintsExists = true;
      return { ...hint, phrases: chipData };
    } else {
      return hint;
    }
  });

  if (!_langForHintsExists) {
    newHints = [...oldHints, { language: lang, phrases: chipData }];
  }
  newUIHints[selectedValue].hints = newHints;
  return newUIHints;
};

export const sortValue = (items, by, toggle = false) => {
  if (!by) return items;

  if (by === "count") {
    // sort by value
    items.sort((a, b) => {
      if (toggle) {
        return b.count - a.count;
      }
      return a.count - b.count;
    });
    return items;
  }
  // sort by name
  items.sort((a, b) => {
    const nameA = a[by] && a[by].toUpperCase();
    const nameB = b[by] && b[by].toUpperCase();
    if (toggle) {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    }

    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  });

  return items;
};

export const EntityCell = (item, p) => {
  let entitiesObj = {};
  try {
    entitiesObj =
      !item[p] || item[p] === "null" || item[p] === "{}"
        ? {}
        : JSON.parse(item[p]);
  } catch (e) {
    entitiesObj = {};
  }

  const entitiesKeys =
    entitiesObj &&
    !Array.isArray(entitiesObj) &&
    Object.keys(entitiesObj).length
      ? Object.keys(entitiesObj)
      : [];
  return entitiesKeys.length
    ? entitiesKeys.map(itm => (
        <div key={itm}>
          &middot; {itm}: {JSON.stringify(entitiesObj[itm])}
        </div>
      ))
    : item[p] && null;
};
export const getFireldValues = (list, fieldName) => {
  const onlyFieldNameValues = list.map(item => {
    return item[fieldName];
  });
  const uniqueValues = [...new Set(onlyFieldNameValues)];
  return uniqueValues.sort();
};

export const emptyUIHintsState = enabledSubDomain => {
  const emptyHints = enabledSubDomain.map(SD => ({
    subdomain: SD.name,
    hints: Langs.map(item => ({
      language: item.code,
      phrases: [],
    })),
  }));

  return emptyHints;
};

export const createUserJourneyAddOnObject = () => {
  return {
    retail: {
      retail_search: [
        { name: "filters", displayName: "Filters", enabled: false },
        { name: "add_to_cart", displayName: "Add to Cart", enabled: false },
        { name: "promotions", displayName: "Promotions", enabled: false },
      ],
      retail_navigation: [
        {
          name: "category_switching",
          displayName: "Category Switching",
          enabled: false,
        },
      ],
      retail_order_management: [
        {
          name: "view",
          displayName: "View Order",
          enabled: false,
        },
        {
          name: "cancel",
          displayName: "Cancel Order",
          enabled: false,
        },
      ],
      voice_checkout: [
        {
          name: "confirmation",
          displayName: "Confirmation",
          enabled: false,
        },
        {
          name: "data_collection",
          displayName: "Data Collection",
          enabled: false,
        },
      ],
      voice_offer_management: [
        {
          name: "view",
          displayName: "View Offers",
          enabled: false,
        },
      ],
    },
    travel: {
      travel_navigation: [
        { name: "status_check", displayName: "Status check", enabled: false },
        { name: "ticket_view", displayName: "Ticket view", enabled: false },
      ],
    },
  };
};

export const createNewTemplateState = () => {
  const initialAppSchema = {
    subDomains: [
      {
        name: "default_subdomain",
        description: "Default Subdomain",
        displayName: "Default Subdomain",
        enabled: true,
        userUpdatableData: [],
        data: [],
        userData: [],
        asrHints: [],
      },
    ],
    userJourneys: [
      {
        name: "global",
        description: "global",
        displayName: "global",
        enabled: true,
        intents: ["dummy_intent"],
        appStatesMetadata: [
          {
            name: "global_greeting_",
            description:
              "The message that Slang will speak out whenever Slang is invoked and there no default user journey is set.",
            displayName: "Global Greeting message",
            appStateName: "greeting",
            conditionName: "",
            parameters: [],
            isTerminal: false,
            uiHints: [],
            completionContext: {
              type: "completion",
              data: "",
            },
            completion: {
              successTemplates: [
                {
                  isId: true,
                  severity: 1,
                  value: "global_greeting_sev1_var1",
                },
              ],
              failureTemplates: [],
            },
          },
          {
            name: "global_clarification_",
            description:
              "When the user speaks a command and Slang is not able to understand the intent behind it.",
            displayName: "User intent is not clear",
            appStateName: "clarification",
            conditionName: "",
            parameters: [],
            isTerminal: false,
            uiHints: [],
            completionContext: {
              type: "completion",
              data: "",
            },
            completion: {
              successTemplates: [
                {
                  isId: true,
                  severity: 1,
                  value: "global_clarification_sev1_var1",
                },
              ],
              failureTemplates: [],
            },
          },
        ],
        greeting: {
          successTemplates: [
            {
              isId: true,
              severity: 1,
              value: "global_greeting_sev1_var1",
            },
          ],
          failureTemplates: [],
        },
        clarification: {
          successTemplates: [
            {
              isId: true,
              severity: 1,
              value: "global_clarification_sev1_var1",
            },
          ],
          failureTemplates: [],
        },
      },
    ],
  };

  const initialStringTable = {
    global_greeting_sev1_var1: {
      "en-IN":
        "Welcome to the Slang Retail Assistant! What would you like to do today?",
    },
    global_clarification_sev1_var1: {
      "en-IN": "Sorry, I didn't understand. Please try again",
    },
  };

  return { appSchema: initialAppSchema, stringTable: initialStringTable };
};

export const getSaveAndTrainTimestamp = (metadata, metadataTrainStatus) => {
  let lastSavedTimestamp;
  let lastStagePublishedTimestamp;
  let lastProdPublishedTimestamp = null;

  if (metadata) {
    const lastStageSavedTimestamp = metadata.status.stage.update_timestamp
      ? metadata.status.stage.update_timestamp
      : -1;
    const lastProdSavedTimestamp = metadata.status.prod.update_timestamp
      ? metadata.status.prod.update_timestamp
      : -1;

    if (lastStageSavedTimestamp > lastProdSavedTimestamp) {
      lastSavedTimestamp = lastStageSavedTimestamp / 1000;
    } else {
      lastSavedTimestamp = lastProdSavedTimestamp / 1000;
    }
  }

  if (metadataTrainStatus) {
    const stageTrainEndTimestamp = metadataTrainStatus.status.stage
      .train_end_timestamp
      ? metadataTrainStatus.status.stage.train_end_timestamp
      : -1;
    const prodTrainEndTimestamp = metadataTrainStatus.status.prod
      .train_end_timestamp
      ? metadataTrainStatus.status.prod.train_end_timestamp
      : -1;
    lastStagePublishedTimestamp = stageTrainEndTimestamp / 1000;
    lastProdPublishedTimestamp = prodTrainEndTimestamp / 1000;
  }

  const getRecentActivity = () => {
    const recentActivityTime = Math.max(
      lastSavedTimestamp,
      lastStagePublishedTimestamp,
      lastProdPublishedTimestamp
    );
    if (recentActivityTime === lastSavedTimestamp) return PUBLISH_ACTIVITY.SAVE;
    else if (recentActivityTime === lastStagePublishedTimestamp)
      return PUBLISH_ACTIVITY.STAGE_PUBLISH;
    else if (recentActivityTime === lastProdPublishedTimestamp)
      return PUBLISH_ACTIVITY.PROD_PUBLISH;
  };

  return {
    lastSavedTimestamp: lastSavedTimestamp > 0 ? lastSavedTimestamp : null,
    lastStagePublishedTimestamp:
      lastStagePublishedTimestamp > 0 ? lastStagePublishedTimestamp : null,
    lastProdPublishedTimestamp:
      lastProdPublishedTimestamp > 0 ? lastProdPublishedTimestamp : null,
    recentActivity: getRecentActivity(),
  };
};

export const PUBLISH_ACTIVITY = {
  SAVE: "SAVE",
  STAGE_PUBLISH: "STAGE",
  PROD_PUBLISH: "PROD",
};

export const TutorialSteps = [
  "You can create a new assistant by clicking here.",
  "Configure your assistant by clicking here.",
  "You can add more sub-domain capabilities to your assistant here.",
  "You can upload your app specific data here.",
  "You can enable/disable and customize the user journeys here.",
  "You can add dynamic actions to the user journeys here.",
  "You can configure the prompts, hints and responses for specific journeys here.",
  "You can customize the Assistant language and its appearance here.",
  "You can customize the languages for your Assistant here.",
  "You can customize the UI, Onboarding and Intro experience for your Assistant here.",
];

export const actionsAppStateCondition = {
  retail: {
    retail_search: {
      appStateName: "search_results",
      conditionName: "success",
    },
    retail_order_management: {
      appStateName: "view_order",
      conditionName: "success",
    },
    retail_navigation: {
      appStateName: "navigation",
      conditionName: "success",
    },
    voice_offer_management: {
      appStateName: "view_offers",
      conditionName: "success",
    },
    voice_checkout: {
      appStateName: "checkout",
      conditionName: "success",
    },
  },
  travel: {
    travel_search: {
      appStateName: "search_results",
      conditionName: "success",
    },
    travel_navigation: {
      appStateName: "navigation",
      conditionName: "success",
    },
  },
  bfsi: {
    bfsi_manage_account: {
      appStateName: "account_details",
      conditionName: "success",
    },
    bfsi_manage_funds: {
      appStateName: "funds_reallocation",
      conditionName: "success",
    },
    bfsi_view_statement: {
      appStateName: "statement_details",
      conditionName: "success",
    },
    bfsi_manage_ipo: {
      appStateName: "view_ipo",
      conditionName: "navigation_success",
    },
    bfsi_manage_credentials: {
      appStateName: "access_code",
      conditionName: "navigation_success",
    },
    bfsi_manage_portfolio: {
      appStateName: "view_portfolio",
      conditionName: "navigation_success",
    },
    bfsi_manage_brokerage: {
      appStateName: "view_brokerage",
      conditionName: "navigation_success",
    },
  },
  fitness: {
    food_logging: {
      appStateName: "food_log",
      conditionName: "success",
    },
    fitness_navigation: {
      appStateName: "navigation",
      conditionName: "success",
    },
  },
};
