import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import InputTextField from "./InputTextField";
import ChipsDisplay from "./ChipsDisplay";

import Paper from "@material-ui/core/Paper";

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  containHeight: {
    maxHeight: theme.spacing(50),
    overflow: "auto",
  },
}));

const ChipsArray = props => {
  const { langName, handleDelete, chipType, cleanPhraseData, state } = props;

  const { asrHintsAdd, asrHintsChipData } = state;

  const { chipData, addPhrase } = {
    addPhrase: asrHintsAdd,
    chipData: asrHintsChipData,
  };

  const classes = styles();

  const InputTextFieldProps = { langName, chipType, cleanPhraseData };
  const ChipsDisplayProps = { chipType, chipData, handleDelete };
  return (
    <Paper className={classes.root} elevation={1}>
      <InputTextField {...InputTextFieldProps} />
      <br />
      <br />
      <div className={classes.containHeight}>
        <ChipsDisplay {...ChipsDisplayProps} />
      </div>
    </Paper>
  );
};

ChipsArray.propTypes = {
  langName: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  chipType: PropTypes.string.isRequired,
};

export default ChipsArray;
