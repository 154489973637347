import { isUserLoggedIn } from "../actions/login";
import * as types from "../types";
import * as helper from "./helper";
import { createToPublishAsstConfig } from "../../libs/slang/asstAPIs";

export const asstDomainActions = store => next => action => {
  if (!action.meta || action.meta.type !== "ASST_CONFIG") {
    return next(action);
  }

  const { name, template_version, template_id, userJourneys, subDomains } =
    action.payload;
  store.dispatch(isUserLoggedIn({}));
  const {
    login: { identity, isAuth },
    apikey: { api_key, api_secret },
  } = store.getState();

  if (!identity || !isAuth || !api_key || api_key === "" || !api_secret) {
    const payload = {
      error: "Error: id or api key not found",
    };
    helper.prepareAction(action, payload, store);
    return;
  }

  switch (action.type) {
    case types.ASST_CONFIG_CREATE_TO_PUBLISH:
      createToPublishAsstConfig({
        name,
        template_version,
        template_id,
        userJourneys,
        subDomains,
      })
        .then(asstID => {
          const payload = {
            asstConfigID: asstID,
            error: null,
          };
          helper.prepareActionSession(action, payload, store);
        })
        .catch(err => {
          console.log(err.toString());

          const payload = {
            asstConfigID: null,
            error: err.toString(),
          };
          helper.prepareAction(action, payload, store);
        });
      break;

    default:
      const newAction = Object.assign({}, action);
      delete newAction.meta;
      store.dispatch(newAction);
  }
};

export default asstDomainActions;
