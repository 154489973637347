import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  SlangSurfacePermaTextContainer: {
    textAlign: "center",
    fontSize: "0.85rem",
    minHeight: "3.5rem",
    overflow: "hidden",
  },
  SlangSurfacePermaTextHelpText: {
    textTransform: "capitalize",
    fontStyle: "italic",
    fontSize: "1.2rem",
    marginTop: 0,
    marginBottom: "0.5rem",
  },
  SlangSurfacePermaTextState: {
    textTransform: "uppercase",
    margin: "0.3rem 0",
  },
  SlangHintsList: {
    display: "flex",
    margin: props => (props.surfaceStyle === "curved" ? "10px 5px" : "10px 0"),
    marginLeft: props => (props.surfaceStyle === "curved" ? "1.5rem" : "0px"),
    overflow: "scroll",
    width: props => (props.surfaceStyle === "curved" ? "80%" : "100%"),
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  SlangSurfacePermaTextHelpListItem: {
    padding: "5px 10px",
    border: 0,
    margin: "0px 5px",
    borderRadius: "100px",
    width: "max-content",
    whiteSpace: "nowrap",
    backgroundColor: props =>
      props.surfaceUIMode === "dark" ? "#5a595c" : "#efefef",
    fontSize: "smaller",
  },
}));

const hintsList = [
  "Maggi Masala Noodles",
  "Peeled Sambhar Onions",
  "Cold Cream",
  "Dishwashing Liquid",
];

const SlangSurfacePermaText = props => {
  const { surfaceHintStyle } = props;
  const classes = styles(props);
  return (
    <div className={classes.SlangSurfacePermaTextContainer}>
      {" "}
      <div>
        <h4 className={classes.SlangSurfacePermaTextState}>Try Saying</h4>
        {surfaceHintStyle === "static" ? (
          <p className={classes.SlangSurfacePermaTextHelpText}>
            {"'Maggi Masala Noodles'"}
          </p>
        ) : (
          <div className={classes.SlangHintsList}>
            {hintsList.map(item => (
              <p className={classes.SlangSurfacePermaTextHelpListItem}>
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SlangSurfacePermaText;
