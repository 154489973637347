import { useRef } from "react";

import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import TutorialWrapper from "../../common/TutorialWrapper";

import { NewBuddyCardsInterface } from "./AssistantConfigsHomeTypes";

const NewBuddyCards = (props: NewBuddyCardsInterface) => {
  const { classes, handleCreateClick, state } = props;

  const { apikey, isCreatingOrDeleting } = state;

  const CardRef = useRef(null);

  return (
    <>
      <TutorialWrapper step={0} anchorEl={CardRef}>
        <Card
          className={
            !apikey || isCreatingOrDeleting
              ? classes?.cardDisabled
              : classes.cardNew
          }
          elevation={0}
          ref={CardRef}
          onClick={
            !apikey || isCreatingOrDeleting ? () => {} : handleCreateClick
          }
        >
          <CardMedia
            className={`${classes.media} ${testClassNames.HOME_CREATE_NEW_BUDDY}`}
            image="/images/new_assistant_icon.png"
            // title="Add a new assistant"
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="body2" component="h4">
              Add Assistant
            </Typography>
          </CardContent>
        </Card>
      </TutorialWrapper>
    </>
  );
};

export default NewBuddyCards;
