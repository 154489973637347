import AppConfig from "../../app.config";
import { getApps } from "../actions/appSchema";
import { isUserLoggedIn } from "../actions/login";
import * as types from "../types";
import * as helper from "./helper";
import { sortStrings } from "../../utils/helpers";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";
import SlangSchemaActions from "../../libs/slang/slang_schema_actions";
import {
  GetSetUserRoles,
  GetSetAccessTokenRaw,
  GetSetAppMetadata,
} from "../../utils/auth/handleAuthState";

const SlangStorage = require("../../libs/utils/slang_storage");

// Temp this will change once roles and premissions are in place.
const getUserRole = () => {
  const getRoles = GetSetUserRoles();
  return getRoles ? getRoles[getRoles.length - 1] : "visitor";
};

const getOrgID = () => {
  const orgID = GetSetAppMetadata().organization_id;
  return orgID || "none";
};

export const appSchemaActions = store => next => action => {
  if (!action.meta || action.meta.type !== "APPSCHEMA") {
    return next(action);
  }

  const {
    appID,
    createAppName,
    deleteAppID,
    withTemplate,
    template_version,
    assistant_version,
    template_id,
  } = action.payload;
  store.dispatch(isUserLoggedIn({}));
  const {
    login: { identity, isAuth },
    apikey: { api_key, api_secret },
  } = store.getState();

  if (!identity || !isAuth || !api_key || api_key === "" || !api_secret) {
    const payload = {
      error: "Error: id or api key not found",
    };
    helper.prepareAction(action, payload, store);
    return;
  }
  const orgID = getOrgID();
  const api_secret_role = `${api_secret}|${getUserRole()}`;
  const orgID_token = `${orgID}|${GetSetAccessTokenRaw()}`;

  switch (action.type) {
    case types.APP_LIST:
      logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_LIST_FETCH_STARTED);
      SlangSchemaActions.getSlangAppList(
        AppConfig.SLANG_HOST,
        orgID_token,
        api_secret_role
      )
        .then(json => {
          if (!json.success) {
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.APP_LIST_FETCH_FAILURE,
              {
                error: JSON.stringify(json.trace),
              }
            );
            throw Error(json.message);
          } else {
            const setItem = [
              {
                key: "A_list",
                value: JSON.stringify(json.raw_json_response),
              },
            ];
            logEvent(
              SlangSeverityLevels.INFO,
              SlangEvents.APP_LIST_FETCH_SUCCESS
            );
            const payload = {
              list: json.raw_json_response,
              error: null,
            };
            helper.prepareActionSession(action, payload, store, setItem);
          }
        })
        .catch(err => {
          console.log(err.toString());
          logEvent(
            SlangSeverityLevels.ERROR,
            SlangEvents.APP_LIST_FETCH_FAILURE,
            {
              error: "fetch error: " + err.toString(),
            }
          );
          const app_LIST = sessionStorage.getItem("A_list")
            ? JSON.parse(sessionStorage.getItem("A_list"))
            : [];
          const payload = {
            list: app_LIST,
            error: "fetch error: " + err.toString(),
          };
          helper.prepareAction(action, payload, store);
        });
      break;

    case types.APP_DATA_GET_TYPES:
      SlangSchemaActions.fetchSlangStdEntityTypes(AppConfig.SLANG_HOST)
        .then(stdTypes => {
          const stdEntityTypes = sortStrings(stdTypes);
          const setItem = [
            {
              key: "A_types",
              value: JSON.stringify(stdEntityTypes),
            },
          ];

          const payload = {
            stdTypes: stdEntityTypes,
            error: null,
          };
          helper.prepareActionSession(action, payload, store, setItem);
        })
        .catch(err => {
          console.log(err.toString());
          const stdTypes = sessionStorage.getItem("A_types")
            ? JSON.parse(sessionStorage.getItem("A_types"))
            : [];

          const payload = {
            error: "Couldn't fetch standard entity types",
            stdTypes,
          };
          helper.prepareAction(action, payload, store);
        });
      break;
    case types.APP_CREATE:
      const appCreatePayload = { createAppName, template_version, template_id };
      SlangSchemaActions.createSlangApp(
        AppConfig.SLANG_HOST,
        orgID_token,
        api_secret_role,
        appCreatePayload
      )
        .then(json => {
          if (!json.success) {
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.APP_CREATE_FAILURE,
              {
                app_name: createAppName,
                template_version,
                template_id,
                error: JSON.stringify(json.trace),
              }
            );
            throw Error(json.message);
          } else {
            json = json.raw_json_response;
            sessionStorage.removeItem("A_list");

            const payload = {
              appID: json.id,
              createAppName: json.name,
              error: null,
            };

            if (withTemplate) payload.withTemplate = withTemplate;

            if (AppConfig.demoAppName !== json.name) {
              SlangStorage.putItem("app_id", json.id, "analytics", false);
              SlangStorage.putItem("app_name", json.name, "analytics", false);
              logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_CREATED, {
                app_id: json.id,
                app_name: json.name,
              });
            }

            helper.prepareAction(action, payload, store);
            store.dispatch(getApps());
          }
        })
        .catch(err => {
          const error = err.toString();
          console.log(error);
          logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_CREATE_FAILURE, {
            error,
            app_name: createAppName,
            template_version,
            template_id,
          });
          const payload = {
            error: "fetch error: " + error,
          };
          helper.prepareAction(action, payload, store);
        });

      break;
    case types.APP_CLONE:
      const appClonePayload = { appName: createAppName, cloneID: appID };

      SlangSchemaActions.requestCloneApp(
        AppConfig.SLANG_HOST,
        orgID_token,
        api_secret_role,
        appClonePayload
      )
        .then(json => {
          if (!json.success) {
            logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_CLONE_FAILED, {
              app_name: createAppName,
              app_id: appID,
              error: JSON.stringify(json.trace),
            });
            throw Error(json.message);
          } else {
            json = json.raw_json_response;
            sessionStorage.removeItem("A_list");
            const payload = {
              appId: json.id,
              createAppName: json.name,
              error: null,
            };
            logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_CLONED, {
              app_id: json.id,
              app_name: json.name,
            });
            helper.prepareAction(action, payload, store);
            store.dispatch(getApps());
          }
        })
        .catch(err => {
          const error = err.toString();
          console.log(error);
          logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_CREATE_FAILURE, {
            app_name: createAppName,
            app_id: appID,
            error,
          });
          const payload = {
            error: "fetch error: " + error,
          };
          helper.prepareAction(action, payload, store);
        });

      break;

    case types.APP_DELETE:
      SlangSchemaActions.deleteSlangApp(
        AppConfig.SLANG_HOST,
        orgID_token,
        api_secret_role,
        {
          appId: deleteAppID,
          assistant_version,
        }
      )
        .then(json => {
          if (!json.success) {
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.APP_DELETE_FAILURE,
              {
                error: JSON.stringify(json.trace),
                app_id: deleteAppID,
                assistant_version,
              }
            );
            throw Error(json.message);
          } else {
            sessionStorage.removeItem("A_list");
            logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_DELETED, {
              app_id: deleteAppID,
              assistant_version,
            });
            const payload = {
              appID: null,
              appName: null,
              error: null,
            };
            helper.prepareAction(action, payload, store);
            store.dispatch(getApps());
            SlangStorage.removeItem("app_id", "analytics");
            SlangStorage.removeItem("app_name", "analytics");
          }
        })
        .catch(err => {
          const error = err.toString();
          logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_DELETE_FAILURE, {
            error,
            app_id: deleteAppID,
            assistant_version,
          });
          const payload = {
            error: "fetch error: " + error,
          };
          helper.prepareAction(action, payload, store);
          SlangStorage.removeItem("app_id", "analytics");
          SlangStorage.removeItem("app_name", "analytics");
        });
      break;
    case types.APP_DATA:
      if (appID) {
        logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_DATA_FETCH_STARTED, {
          app_id: appID,
          assistant_version,
        });
        SlangSchemaActions.getSlangApp(
          AppConfig.SLANG_HOST,
          orgID_token,
          api_secret_role,
          {
            appId: appID,
            assistant_version,
          }
        )
          .then(json => {
            if (!json.success) {
              logEvent(
                SlangSeverityLevels.ERROR,
                SlangEvents.APP_DATA_FETCH_FAILURE,
                {
                  app_id: appID,
                  assistant_version,
                  error: JSON.stringify(json.trace),
                }
              );
              throw Error(json.message);
            } else {
              const appDATA = json.raw_json_response;
              const setItem = [
                {
                  key: "A_data",
                  value: JSON.stringify(appDATA),
                },
              ];
              logEvent(
                SlangSeverityLevels.INFO,
                SlangEvents.APP_DATA_FETCH_SUCCESS,
                {
                  app_id: appID,
                  assistant_version,
                }
              );
              const payload = {
                appID: appDATA.id,
                appDATA,
                error: null,
              };
              helper.prepareActionSession(action, payload, store, setItem);
            }
          })
          .catch(err => {
            console.log(err.toString());
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.APP_DATA_FETCH_FAILURE,
              {
                app_id: appID,
                assistant_version,
                error: err.toString(),
              }
            );
            const payload = {
              appID: "",
              appDATA: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
      } else {
        const payload = {
          appID: "",
          error: "App ID undefined",
        };
        helper.prepareAction(action, payload, store);
      }
      break;
    case types.APP_METADATA:
      if (appID) {
        SlangSchemaActions.getSlangAppMetadata(
          AppConfig.SLANG_HOST,
          orgID_token,
          api_secret_role,
          {
            appId: appID,
            assistant_version,
          }
        )
          .then(json => {
            if (!json.success) {
              throw Error(json.message);
            } else {
              const appMetadata = json.raw_json_response;

              const payload = {
                appID,
                appMetadata,
                error: null,
              };
              helper.prepareActionSession(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              appMetadata: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
      } else {
        const payload = {
          appMetadata: null,
          error: "App ID undefined",
        };
        helper.prepareAction(action, payload, store);
      }

      break;
    case types.APP_METADATA_TRAIN_STATUS:
      if (appID) {
        SlangSchemaActions.getSlangAppMetadataTrainStatus(
          AppConfig.SLANG_HOST,
          orgID_token,
          api_secret_role,
          {
            appId: appID,
            assistant_version,
          }
        )
          .then(json => {
            if (!json.success) {
              throw Error(json.message);
            } else {
              const appMetadataTrainStatus = json.raw_json_response;

              const payload = {
                appID,
                appMetadataTrainStatus,
                error: null,
              };
              helper.prepareActionSession(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              appMetadataTrainStatus: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
      } else {
        const payload = {
          appMetadataTrainStatus: null,
          error: "App ID undefined",
        };
        helper.prepareAction(action, payload, store);
      }

      break;
    case types.APP_STRING_TABLE:
      if (appID) {
        logEvent(
          SlangSeverityLevels.INFO,
          SlangEvents.APP_STRING_TABLE_FETCH_STARTED,
          {
            app_id: appID,
            assistant_version,
          }
        );
        SlangSchemaActions.getSlangAppStringTable(
          AppConfig.SLANG_HOST,
          orgID_token,
          api_secret_role,
          {
            appId: appID,
            assistant_version,
          }
        )
          .then(json => {
            if (!json.success) {
              logEvent(
                SlangSeverityLevels.ERROR,
                SlangEvents.APP_STRING_TABLE_FETCH_FAILURE,
                {
                  error: JSON.stringify(json.trace),
                  app_id: appID,
                  assistant_version,
                }
              );
              throw Error(json.message);
            } else {
              const appStringTableNew = json.raw_json_response;
              logEvent(
                SlangSeverityLevels.INFO,
                SlangEvents.APP_STRING_TABLE_FETCH_SUCCESS,
                {
                  app_id: appID,
                  assistant_version,
                }
              );
              const setItem = [
                {
                  key: "A_stringTable",
                  value: JSON.stringify(appStringTableNew),
                },
              ];

              const payload = {
                appID,
                appStringTable: appStringTableNew,
                error: null,
              };
              helper.prepareActionSession(action, payload, store, setItem);
            }
          })
          .catch(err => {
            console.log(err.toString());
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.APP_STRING_TABLE_FETCH_FAILURE,
              {
                error: err.toString(),
                app_id: appID,
                assistant_version,
              }
            );
            const payload = {
              appID: "",
              appStringTable: {},
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
      } else {
        const payload = {
          appID: "",
          error: "NO BUDDY ID",
        };
        helper.prepareAction(action, payload, store);
      }
      break;

    case types.APP_SCHEMA_DEFINITION:
      logEvent(
        SlangSeverityLevels.INFO,
        SlangEvents.FETCH_SCHEMA_DEFINITION_STARTED
      );
      SlangSchemaActions.fetchSlangSchemaDefinition(AppConfig.SLANG_HOST)
        .then(schema => {
          if (!schema) {
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.FETCH_SCHEMA_DEFINITION_FAILURE,
              {
                error: schema.trace,
              }
            );
          } else {
            const setItem = [
              {
                key: "D_schema",
                value: JSON.stringify(schema),
              },
            ];
            logEvent(
              SlangSeverityLevels.INFO,
              SlangEvents.FETCH_SCHEMA_DEFINTIION_SUCCESS
            );
            const payload = {
              schemaDefinition: schema,
              error: null,
            };
            helper.prepareActionSession(action, payload, store, setItem);
          }
        })
        .catch(err => {
          console.log(err.toString());
          logEvent(
            SlangSeverityLevels.ERROR,
            SlangEvents.FETCH_SCHEMA_DEFINITION_FAILURE,
            {
              error: err.toString(),
            }
          );
          const payload = {
            error: "fetch error: " + err.toString(),
            schemaDefinition: null,
          };
          helper.prepareAction(action, payload, store);
        });
      break;

    default:
      const newAction = Object.assign({}, action);
      delete newAction.meta;
      store.dispatch(newAction);
  }
};

export default appSchemaActions;
