import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Grid } from "@material-ui/core";
import { TabPanel, a11yProps } from "../UserJourneysEdit/TabPanel";
import PromptsEdit from "../UserJourneysEdit/PromptsEdit";
import AddEditTriggerForm from "./AddEditTriggerForm";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs(props) {
  const {
    appStateUID,
    userJourneyID,
    userJourney,
    appStatesMetadata,
    changeUserJourneyData,
    selectedValue,
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid xs={2} item>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab label="Properties" {...a11yProps(0)} />
          <Tab label="Prompts" {...a11yProps(1)} />
        </Tabs>
      </Grid>
      <Grid xs={10} item>
        <TabPanel value={value} index={0}>
          <AddEditTriggerForm
            {...{
              selectedAppState: selectedValue,
              userJourney,
              userJourneyID,
              handleSaveClose: changeUserJourneyData,
              appStateUID,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PromptsEdit
            {...{
              appStatesMetadata,
              selectedAppState: appStateUID,
              userJourneyID,
              changeUserJourneyData,
              heading: "Prompts",
            }}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
