import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../../withTheme";
import { styles } from "../styles.js";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import AppConfig from "../../../app.config";
import { validateLoginPassword } from "../../../utils/validate";
import { getAllOrganizations } from "../../../libs/auth/slang_auth";
import { getAPI } from "../../../redux/actions/apikey";
import { changePassword } from "../../../redux/actions/login";
import { resetIsProfileUpdated } from "../../../redux/actions/user";
import UserDetailsPopUp from "./UserDetailsPopUp";
import { checkIfUserBelongsToSlangLabs } from "../../../utils/userUtils";
import MD5 from "blueimp-md5";

import {
  logPageVisited,
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs";

const mapDispatchToProps = dispatch => ({
  getAPI: () => dispatch(getAPI()),
  resetIsProfileUpdated: () => dispatch(resetIsProfileUpdated()),
  changePassword: pass => dispatch(changePassword(pass)),
});

export class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openAdmin: false,
      dialogTitle: "",
      login: false,
      isLoading: false,
      isChanging: false,
      openPopUP: false,
      password1: "",
      password2: "",
      adminUser: "",
      adminPassword: "",
      adminError: false,
      oldPassword: "",
      error: false,
      isError: false,
      orgName: "",
      cur_month_trans_count: AppConfig.maxTransactionsPerMonth,
      notify: { variant: "info", message: "" },
    };
  }

  handleCreateAPI = () => {
    this.props.createAPI();
    this.setState({
      isLoading: true,
      notify: {
        variant: "info",
        message: "Creating API key ...",
      },
    });
  };

  getOrgName = async () => {
    try {
      const data = await getAllOrganizations();
      const U_orgID = localStorage.getItem("U_orgID");
      const orgName = data.organizations.find(
        item => item.organization_id === U_orgID
      ).organization_name;
      this.setState({
        orgName,
      });
    } catch (error) {
      this.setState({
        notify: {
          variant: "error",
          message: error.toString(),
        },
      });
    }
  };

  handleResetAPI = () => {
    this.props.resetAPI();
    this.setState({ isLoading: true });
  };

  handleSwitchDARKMODE = event => {
    this.setState({
      DARKMODE: event.target.checked,
      isLoading: true,
    });
    localStorage.setItem("U_DARKMODE", event.target.checked.toString());
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  componentDidMount() {
    this.props.getAPI();
    this.getOrgName();
    logPageVisited("user_details");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (prevProps.error !== this.props.error && this.props.error !== "") {
        this.setState({
          isLoading: false,
          notify: {
            variant: "error",
            message: this.props.error,
          },
        });
      }
      if (prevProps.apikey !== this.props.apikey && this.props.apikey !== "") {
        this.setState({ isLoading: false, error: false });
      }

      if (this.props.isChanged) {
        this.setState({
          isChanging: false,
          notify: {
            variant: "success",
            message: "Password changed!",
          },
        });
      }
    } else {
      if (this.props.error && this.state.isLoading) {
        this.setState({
          isLoading: false,
          notify: {
            variant: "error",
            message: this.props.error,
          },
        });
      }
    }

    if (prevState !== this.state) {
    } else {
    }
  }

  handleClose = () => {
    this.setState({
      openAdmin: false,
      open: false,
      openPopUP: false,
    });
  };

  handleEditPassword = () => {
    this.setState({
      open: true,
      openPopUP: false,
    });
  };

  handleAdminPassword = () => {
    this.setState({
      openAdmin: true,
      open: false,
      openPopUP: false,
    });
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangePasswordClose = () => {
    this.props.changePassword({
      newPassword: this.state.password1,
      oldPassword: this.state.oldPassword,
      isChanged: false,
      error: null,
    });
    this.setState({
      open: false,
      password1: "",
      password2: "",
      oldPassword: "",
    });

    logEvent(SlangSeverityLevels.INFO, SlangEvents.PASSWORD_CHANGED);
  };

  handleCheckAdminClose = () => {
    const { adminUser, adminPassword } = this.state;
    // enables admin mode by checking the stored user & pass in the config.
    if (
      MD5(adminPassword) === AppConfig.SLANG_ADMIN_PASSWORD &&
      MD5(adminUser.toLowerCase()) === AppConfig.SLANG_ADMIN_USER
    ) {
      localStorage.setItem("U_username", adminUser.toLowerCase());
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } else {
      this.setState({
        adminError: true,
      });
    }
  };

  handleClickOpen = () => {
    this.setState({
      openPopUP: true,
    });
  };

  updateBillingInfo = payload => {
    this.props.updateBillingInfo(payload);
  };

  render() {
    const {
      classes,
      emailid,
      billingInfo,
      // errorUser,
      isProfileUpdated,
      resetIsProfileUpdated,
      // isAdminUser
    } = this.props;
    const { updateBillingInfo, handleClose } = this;
    const {
      open,
      openAdmin,
      password1,
      password2,
      oldPassword,
      openPopUP,
      adminError,
      orgName,
    } = this.state;

    const validPassword =
      oldPassword && password1 && password2
        ? validateLoginPassword(password1, password2) &&
          validateLoginPassword(oldPassword)
        : false;
    const popupProps = {
      classes,
      updateBillingInfo,
      billingInfo,
      isProfileUpdated,
      resetIsProfileUpdated,
      openPopUP,
      handleClose,
    };

    return (
      <Accordion
        classes={{
          root: classes.Accordion,
          expanded: classes.summaryExpand,
        }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<span></span>}>
          <Typography className={classes.heading}>User Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper className={classes.paper} elevation={0}>
            <Grid item container xs={12} className={classes.space}>
              <Grid item xs={4}>
                <Typography className={classes.sideHeading}>
                  LOGIN EMAIL ID
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{emailid}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.alignTextRight}>
                {/* 
                TODO: Rehaul of this component 
                {!isAdminUser && (
                  <Button
                    variant="outlined"
                    color="initial"
                    style={{ width: 150 }}
                    onClick={this.handleAdminPassword}
                  >
                    ADMIN MODE
                  </Button>
                )} */}
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.space}>
              <Grid item xs={4}>
                <Typography className={classes.sideHeading}>
                  ORGANIZATION
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{orgName}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.alignTextRight}></Grid>
            </Grid>
            {/*  <Grid item container xs={12} className={classes.space}>
              <Grid item xs={4}>
                <Typography className={classes.sideHeading}>
                  PASSWORD
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>***************</Typography>
              </Grid>
              <Grid item xs={3} className={classes.alignTextRight}>
                <Button
                  variant="outlined"
                  color="initial"
                  style={{ width: 150 }}
                  onClick={this.handleEditPassword}
                >
                  EDIT PASSWORD
                </Button>
              </Grid>
            </Grid>
            {errorUser === null && Object.keys(billingInfo).length ? (
              <React.Fragment>
                <Grid item container xs={12} className={classes.space}>
                  <Grid item xs={4}>
                    <Typography>Billing Information</Typography>
                  </Grid>
                  <Grid item xs={5} />
                  <Grid item xs={3} className={classes.alignTextRight}>
                    <Button
                      variant="outlined"
                      color="initial"
                      style={{ width: 150 }}
                      onClick={this.handleClickOpen}
                      className={testClassNames.USER_ACCOUNT_EDIT_INFO_BUTTON}
                    >
                      {"Edit Info"}
                    </Button>
                  </Grid>
                </Grid>
                {Object.keys(billingInfo).map((item, key) => (
                  <Grid
                    key={key}
                    item
                    container
                    xs={12}
                    className={classes.space}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="button"
                        className={classes.sideHeading}
                      >
                        {item.replace("_", " ")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body2">
                        {billingInfo[item]}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </React.Fragment>
            ) : (
              <Grid item container xs={12} className={classes.space}>
                <Typography component="em" color="error" variant="subtitle1">
                  Unable to fetch billing Information
                </Typography>
              </Grid>
            )} */}
          </Paper>
        </AccordionDetails>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>Change your password</DialogTitle>
          <DialogContent>
            <DialogContentText />
            <TextField
              autoFocus
              margin="dense"
              name="oldPassword"
              label="Old Password"
              type="password"
              onChange={this.handleInputChange}
              fullWidth
            />
            <TextField
              margin="dense"
              name="password1"
              label="New Password"
              type="password"
              onChange={this.handleInputChange}
              fullWidth
            />
            <TextField
              margin="dense"
              name="password2"
              label="Re-type password"
              type="password"
              onChange={this.handleInputChange}
              error={!validPassword}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.handleChangePasswordClose}
              disabled={!validPassword}
            >
              Change
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAdmin} onClose={this.handleClose}>
          <DialogTitle>Admin password</DialogTitle>
          <DialogContent>
            <DialogContentText />
            <TextField
              autoFocus
              margin="dense"
              name="adminUser"
              label="Admin Username"
              type="text"
              onChange={this.handleInputChange}
              error={adminError}
              fullWidth
            />
            <TextField
              margin="dense"
              name="adminPassword"
              label="Admin Password"
              type="password"
              onChange={this.handleInputChange}
              error={adminError}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.handleCheckAdminClose}
              // disabled={!validPassword}
            >
              Enter
            </Button>
          </DialogActions>
        </Dialog>
        <UserDetailsPopUp {...popupProps} />
      </Accordion>
    );
  }
}

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
UserDetails.defaultProps = {
  billingInfo: {
    owner_name: "",
    email_id: "",
    company_name: "",
  },
};

const mapStateToProps = state => {
  return {
    isAdminUser: checkIfUserBelongsToSlangLabs(state.login.username),
    emailid: state.login.username,
    isChanged: state.login.isChanged,
    error: state.apikey.error,
    apikey: state.apikey.api_key,
    appID: state.appSchema.appDATA.id,
    isProfileUpdated: state.user.isProfileUpdated,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(UserDetails)));
