import { SvgIcon } from "@material-ui/core";

const SlangUnmuteIcon = props => {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path
        fill={props.fill}
        d="M 11 3 L 5 9 L 3 9 C 1.895 9 1 9.895 1 11 L 1 13 C 1 14.105 1.895 15 3 15 L 5 15 L 11 21 L 11 3 z M 16.65625 6.34375 L 15.242188 7.7578125 C 17.594098 10.109723 17.593888 13.891346 15.242188 16.242188 L 16.65625 17.65625 C 19.772549 14.541091 19.772339 9.4598394 16.65625 6.34375 z M 13.828125 9.171875 L 12.414062 10.585938 C 13.204152 11.376027 13.204152 12.623974 12.414062 13.414062 L 13.828125 14.830078 C 15.382035 13.276168 15.382035 10.725785 13.828125 9.171875 z"
      />
    </SvgIcon>
  );
};

export default SlangUnmuteIcon;
