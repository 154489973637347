import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SaveTemplateDialog from "./SaveTemplateDialog";

const versionUpdateTypes = { PATCH: "patch", MINOR: "minor", MAJOR: "major" };

const styles = makeStyles(theme => ({
  textColor: {
    color: theme.palette.primary.dark,
    padding: "0",
    margin: 0,
    flexGrow: 1,
    textAlign: "left",
  },
  text: {
    color: theme.palette.primary.dark,
    padding: "0",
    margin: 0,
    textAlign: "left",
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    minWidth: 400,
    padding: "20px 40px",
  },
  Chip: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    padding: "8px",
  },
  createInput: {
    color: theme.palette.primary.dark,
    width: "100%",
    display: "grid",
    gridTemplateColumns: "2fr 9fr",
    margin: "24px 0px",
    gap: "32px",
  },
  Input: {
    color: theme.palette.primary.dark,
    width: "80%",
  },
  ChangelogItemEdit: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  select: {
    width: "25%",
  },
  addButton: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  TemplateContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  changelogTitleContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "0",
    gap: "8px",
    padding: 0,
  },
  changeItem: {
    backgroundColor: theme.palette.grey.A400,
    margin: "10px 0",
    padding: "5px 26px 5px 26px",
    textAlign: "start",
    width: "80%",
  },
  ChangesList: {
    padding: "0",
    paddingTop: "8px",
    maxHeight: "240px",
  },
  submitButton: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    height: 50,
    padding: "6px 16px",
    cursor: "pointer",
  },
  cancelButton: {
    height: 50,
    color: theme.palette.primary.dark,
  },
  buttons: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    padding: 0,
    cursor: "pointer",
  },
  CloseIcon: {
    cursor: "pointer",
  },
  changeItemContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  changeItemText: {
    overflowWrap: "break-word",
  },
  versionContainer: {
    display: "flex",
    gap: "40px",
    alignItems: "center",
  },
  nextVersionChip: {
    color: theme.palette.primary.light,
  },
}));

const SaveTemplateButton = props => {
  const {
    isTemplateUpdating,
    handleUpdateAssistantTemplate,
    templateDetails,
    templateVersion,
    isLocked,
  } = props;
  const classes = styles();

  const [open, setOpen] = useState(false);
  const [selectedVersionUpdateType, setSelectedVersionUpdateType] = useState(
    versionUpdateTypes.PATCH
  );
  const [changelogTitle, setChangelogTitle] = useState("");
  const [changelogDescription, setChangelogDescription] = useState("");
  const [changelogList, setChangelogList] = useState([]);

  const handleSubmit = e => {
    e.preventDefault();
    const changes = changelogList.join();
    handleUpdateAssistantTemplate(
      selectedVersionUpdateType,
      changelogTitle,
      changes
    );
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SaveTemplateDialog
        {...{
          open,
          handleClose,
          classes,
          handleSubmit,
          versionUpdateTypes,
          templateVersion,
          templateDetails,
          setSelectedVersionUpdateType,
          setChangelogTitle,
          setChangelogDescription,
          setChangelogList,
          changelogDescription,
          changelogTitle,
          selectedVersionUpdateType,
          changelogList,
        }}
      />
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        disabled={isTemplateUpdating || isLocked}
      >
        {"Save "}
      </Button>
    </>
  );
};

export default SaveTemplateButton;
