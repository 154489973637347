import { Component } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { testClassNames } from "../../../utils/integrationTestClassNames";

import {
  validateLoginEmail,
  validatePhoneNumber,
} from "../../../utils/validate";

export class UserDetailsPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      errorEmail: false,
      openPopUP: !!this.props.openPopUP,
      billingInfo: this.props.billingInfo,
    };
  }

  handleReset = () => {
    this.props.handleClose();
    setTimeout(() => {
      this.setState({
        isLoading: false,
        isError: false,
      });
      this.props.resetIsProfileUpdated();
    }, 300);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (
        this.props.isProfileUpdated !== prevProps.isProfileUpdated &&
        this.props.isProfileUpdated === "YES"
      ) {
        this.handleReset();
      } else if (
        this.props.isProfileUpdated !== prevProps.isProfileUpdated &&
        this.props.isProfileUpdated === "NO"
      ) {
        this.setState({
          isLoading: false,
          isError: true,
        });
      }
      if (this.props.openPopUP !== prevProps.openPopUP) {
        this.setState({
          openPopUP: this.props.openPopUP,
          billingInfo: this.props.billingInfo.owner_name
            ? this.props.billingInfo
            : {
                email_id:
                  this.props.billingInfo && this.props.billingInfo.email_id
                    ? this.props.billingInfo.email_id
                    : "",
                owner_name: "",
                company_name: "",
              },
        });
      }
    }
  }

  handleClickOpen = plan => {
    this.setState({
      openPopUP: true,
    });
  };

  handleOnChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (value.length > 64) return;

    if (name === "email_id") {
      const OK = validateLoginEmail(value) && value !== "";
      this.setState({
        errorEmail: !OK,
      });
    } else if (name === "phone_number") {
      const OK = validatePhoneNumber(value);
      this.setState({
        errorPhone: !OK,
      });
    }
    this.setState(state => {
      return { billingInfo: { ...state.billingInfo, [name]: value } };
    });
  };

  handleOnSubmit = event => {
    event.preventDefault();
    const billingInfo = this.state.billingInfo;
    const selectedPlanID = null;
    this.setState({
      isLoading: true,
    });
    Object.keys(billingInfo).forEach(key => {
      billingInfo[key] = billingInfo[key].trim();
    });
    this.props.updateBillingInfo(billingInfo, selectedPlanID);
  };

  render() {
    const { classes } = this.props;
    const { handleOnSubmit, handleOnChange } = this;

    const { openPopUP, billingInfo, isLoading, isError, errorEmail } =
      this.state;

    return (
      <Dialog
        open={openPopUP}
        onClose={isLoading ? null : this.handleReset}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"sm"}
      >
        {isLoading && (
          <div className={classes.loadingProfile}>
            <CircularProgress color="primary" size={100} thickness={1} />
          </div>
        )}
        <form onSubmit={handleOnSubmit}>
          <DialogTitle name="form-dialog-title">{"Update Profile"}</DialogTitle>
          <DialogContent>
            {isError ? (
              <Grid container>
                <Grid className={classes.spaceTextMid} item sm={12}>
                  <ErrorOutlineIcon
                    style={{
                      color: "indianred",
                      fontSize: 72,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: "indianred",
                      fontWeight: 500,
                    }}
                  >
                    There was an error, please try again
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid
                  className={classes.space2}
                  style={{ overflow: "auto", maxHeight: "50vh" }}
                  item
                  xs={12}
                >
                  <div>
                    <TextField
                      autoFocus
                      margin="dense"
                      name="owner_name"
                      value={billingInfo.owner_name}
                      label="Your Name"
                      type="text"
                      onChange={handleOnChange}
                      fullWidth
                      required
                    />
                    <TextField
                      margin="dense"
                      name="email_id"
                      value={billingInfo.email_id}
                      label="Email Address"
                      type="email"
                      onChange={handleOnChange}
                      error={errorEmail}
                      fullWidth
                      required
                    />

                    <TextField
                      margin="dense"
                      name="company_name"
                      value={billingInfo.company_name}
                      label="Company Name"
                      type="text"
                      onChange={handleOnChange}
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ padding: 20 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isError || errorEmail}
              className={testClassNames.USER_ACCOUNT_UPDATE_INFO_BUTTON}
            >
              {"Update"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

UserDetailsPopUp.defaultProps = {
  openPopUP: false,
  billingInfo: {
    email_id: "",
    owner_name: "",
    company_name: "",
  },
};

UserDetailsPopUp.propTypes = {
  classes: PropTypes.object.isRequired,
  updateBillingInfo: PropTypes.func.isRequired,
  openPopUP: PropTypes.bool,
  billingInfo: PropTypes.object,
};

export default UserDetailsPopUp;
