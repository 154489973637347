import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Mic } from "@material-ui/icons";

const styles = makeStyles(theme => ({
  clickableIcon: {
    cursor: "pointer",
  },
  pulsatingCircleParent: {
    transformStyle: "preserve-3d",
  },
  pulsatingCircle: {
    content: "",
    width: "100%",
    height: "100%",
    borderRadius: "45px",
    transform: "translateZ(-1px)",
    background: "#139a7a",
    position: "absolute",
    animation: "pulse-ring 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) infinite",
  },
  testButton: {
    marginBottom: 10,
  },
}));

const MicTrigger = props => {
  const { sdkUiState, triggerSlang, cancelSlang, classes } = props;

  return sdkUiState && sdkUiState.isReady ? (
    <Mic
      color="primary"
      onClick={triggerSlang}
      className={classes.clickableIcon}
    />
  ) : sdkUiState.isListening ? (
    <div className={classes.pulsatingCircleParent}>
      <div className={classes.pulsatingCircle} />

      <Mic
        color="error"
        onClick={cancelSlang}
        className={classes.clickableIcon}
      />
    </div>
  ) : sdkUiState.isProcessing ? (
    <CircularProgress color="primary" size={20} onClick={cancelSlang} />
  ) : (
    <Mic color="secondary" />
  );
};

const UtteranceEdit = props => {
  const {
    sdkUiState,
    utterance,
    setUtterance,
    triggerSlang,
    cancelSlang,
    inferUtterance,
  } = props;

  const classes = styles();

  const MicTriggerProps = {
    sdkUiState,
    triggerSlang,
    cancelSlang,
    classes,
  };

  return (
    <TextField
      id="utterance"
      disabled={sdkUiState ? sdkUiState.isListening : false}
      label={
        sdkUiState && sdkUiState.isListening
          ? "Listening. Please continue speaking..."
          : "Speak/Type out an utterance here"
      }
      value={utterance}
      onChange={event => setUtterance(event.target.value)}
      onKeyDown={event => {
        if (event.key === "Enter") {
          event.preventDefault;
          inferUtterance(utterance);
        }
      }}
      fullWidth
      margin="normal"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MicTrigger {...MicTriggerProps} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="contained"
              color="primary"
              disabled={
                !utterance ||
                sdkUiState === null ||
                (sdkUiState && !sdkUiState.isReady)
              }
              onClick={() => {
                inferUtterance(utterance);
              }}
              className={classes.testButton}
            >
              Test
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

UtteranceEdit.propTypes = {
  utterance: PropTypes.string.isRequired,
  setUtterance: PropTypes.func.isRequired,
  triggerSlang: PropTypes.func.isRequired,
  cancelSlang: PropTypes.func.isRequired,
  inferUtterance: PropTypes.func.isRequired,
};

export default UtteranceEdit;
