import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  Tabs,
  Tab,
  List,
  ListItem,
  TextField,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Fab,
  Paper,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import teal from "@material-ui/core/colors/teal";
import Color from "color";

import {
  emptyStateStatements,
  getCurrentSeverityLevels,
} from "./promptsEditUtil";
import { validatePromptTextLanguage } from "../../../../../utils/validate";
import AppConfig from "../../../../../app.config";
import DeleteButton from "./DeleteButton";
import LengthCounter from  "./LengthCounter";

const Langs = AppConfig.LANGUAGES;

const styles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  list: {
    maxHeight: 350,
    overflow: "scroll",
    overflowX: "hidden",
  },
  expansionPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    textAlign: "left",
  },
  expansionDetails: {
    paddingBottom: 0,
    paddingLeft: 0,
  },
  listWidth: {
    flexGrow: 1,
    width: "100%",
  },
  levels: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    fontFamily: theme.typography.button,
    padding: theme.spacing(1.3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionInputList: {
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  values: {
    marginRight: 25,
    fontWeight: 500,
    fontSize: "0.9rem",
    padding: 0,
    "& input": {
      fontSize: "0.9rem",
      padding: 2,
    },
    "& textarea": {
      fontSize: "0.9rem",
      paddingLeft: 2,
      paddingRight: 2,
    },
  },
}));

const ExpansionStatements = props => {
  const {
    lang,
    promptName,
    promptsExample,
    prompts,
    HELPTEXT,
    onChangeText,
    handleSaveAllPrompts,
    handleDelete,
    severityLevels,
    isTerminal,
    // handleAddLevel,
    // handleDeleteLevel,
  } = props;
  // States
  const [currentLevel, setCurrentLevel] = useState(1);
  const [StatementValues, setStatementValues] = useState(prompts);
  const [langStatements, setLangStatements] = useState(
    emptyStateStatements() || {}
  );
  const [error, setError] = useState(true);
  const [newPromptLength, setNewPromptLength] = useState(0);
  useEffect(() => {
    const newSuccessTemplates = prompts.successTemplates.map((item, idc) => ({
      ...item,
      idc,
    }));
    prompts.successTemplates = newSuccessTemplates;
    setStatementValues(prompts);
  }, [promptName, prompts]);

  useEffect(() => {
    setCurrentLevel(1);
  }, [promptName]);

  // Styles
  const classes = styles();

  // Component Handlers
  const handleOnChangeStatement = event => {
    const langCode = lang;
    const text = event.target.value;
    const name = event.target.name;
    const OK = validatePromptTextLanguage(event.target.value, lang);

    setNewPromptLength(text.length);

    setLangStatements({
      ...langStatements,
      [langCode]: {
        ...langStatements[langCode],
        [name]: text,
      },
    });
    setError(!OK);
  };

  const updateNew = (event, promptTemplate) => {
    const newPromptPayload = {
      promptName,
      completionTemplateType: promptTemplate,
      severity: currentLevel,
      value: langStatements[lang][promptTemplate].trim(),
      type: event.target.type,
    };
    const currentLevelPrompts =
      StatementValues[promptTemplate] &&
      StatementValues[promptTemplate].filter(
        item => item.severity === currentLevel
      );

    const nonEmptyPrompts = currentLevelPrompts.filter(
      item => item.value.trim() !== ""
    );

    let key = 0;
    if (currentLevelPrompts.length === 0 || nonEmptyPrompts.length > 0) {
      key = StatementValues[promptTemplate].length;
    } else {
      key = currentLevelPrompts[0].idc;
    }

    onChangeText(newPromptPayload, key);

    setLangStatements(emptyStateStatements());
    setError(true);
  };

  const handleOnClickNew = (event, name) => {
    updateNew(event, name);
  };

  const handleOnKeyPress = (event, name) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (name !== "" && !error) {
        updateNew(event, name);
      }
    }
  };

  // const handleLevelAdd = () => {
  //   const selectedLevel = handleAddLevel();
  //   setCurrentLevel(selectedLevel);
  // };

  // const handleLevelDelete = (currentLevel) => {
  //   const selectedLevel = handleDeleteLevel(currentLevel);
  //   setCurrentLevel(selectedLevel);
  // };
  // preProcessing Component
  const successTemplates = StatementValues.successTemplates;

  const successTemplatesStatement = langStatements[lang].successTemplates;
  const langText = Langs.filter(item => item.code === lang)[0].text;

  const equalHelpText = idc => {
    const langEqualText = promptsExample && promptsExample.successTemplates;
    const check =
      langEqualText && langEqualText[idc] && langEqualText[idc].value !== "";
    return check
      ? `"${langEqualText[idc].value}" in ${langText} `
      : `${langText}  text for this prompt`;
  };

  const successTemplatePrompts = successTemplates.filter(
    a => a.severity === currentLevel
  );
  // .filter((prompt) => prompt.value.trim() !== "" || !prompt.empty);

  const handlePromptDelete = (type, template, promptName, id) => {
    // if (successTemplatePrompts.length === 1 && successTemplates.length > 1) {
    //   const newPromptPayload = {
    //     promptName,
    //     completionTemplateType: template,
    //     severity: currentLevel,
    //     value: "",
    //     type,
    //   };
    //   onChangeText(newPromptPayload, id);
    //   return;
    // }

    handleDelete(template, promptName, id);
  };

  // const allowAddNewLevel = () => {
  //   const currentSeverityLevels = getCurrentSeverityLevels(successTemplates);
  //   if (currentSeverityLevels.length === severityLevels.length) {
  //     return false;
  //   }
  //   return true;
  // };

  const bgColorList = severity => Color(teal[300]).fade(0.6).string();

  return (
    <div className={classes.root}>
      <Paper elevation={1} className={classes.expansionPaper}>
        <div className={classes.expansionDetails}>
          <div className={classes.listWidth}>
            {/* {isTerminal && severityLevels.length == 0 ? (
              <div className={classes.levels}>
                <Button
                  variant="outlined"
                  color={"secondary"}
                  onClick={handleLevelAdd}
                  size="small"
                  className={classes.button}
                  disabled={allowAddNewLevel()}
                >
                  <Add /> Add new level
                </Button>
              </div>
            ) : null} */}
            {!isTerminal ? (
              <div className={classes.levels}>
                <Tabs
                  value={currentLevel}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event, currentLevel) =>
                    setCurrentLevel(currentLevel)
                  }
                  variant="scrollable"
                  scrollButtons={severityLevels.length ? "on" : "off"}
                >
                  {severityLevels.map(n => (
                    <Tab key={n} value={n} label={`Level ${n}`} />
                  ))}
                </Tabs>
                {/* {severityLevels.length > 0 ? (
                  <IconButton
                    aria-label="Add"
                    color="primary"
                    onClick={handleLevelAdd}
                    disabled={allowAddNewLevel()}
                  >
                    <Add />
                  </IconButton>
                ) : (
                  <Button
                    variant="outlined"
                    color={"secondary"}
                    onClick={handleLevelAdd}
                    size="small"
                    disabled={allowAddNewLevel()}
                  >
                    <Add /> Add new level
                  </Button>
                )} */}
                {/* {severityLevels.length > 0 ? (
                  <DeleteButton
                    {...{
                      handleDelete: () => handleLevelDelete(currentLevel),
                      deleteMessage: `Are you sure? Any following levels will also be deleted.`,
                      popover: true,
                    }}
                  />
                ) : null} */}
              </div>
            ) : null}
            {severityLevels.length > 0 ? (
              <List className={`${classes.list}`}>
                <ListItem className={classes.expansionInputList}>
                  <TextField
                    style={{ marginRight: 25, paddingTop: 15 }}
                    id="successTemplates-prompt"
                    name="successTemplates"
                    value={successTemplatesStatement}
                    onChange={handleOnChangeStatement}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyPress={event => {
                      handleOnKeyPress(event, "successTemplates");
                    }}
                    className={classes.values}
                    placeholder={`${HELPTEXT.PLACEHOLDER_EN} in ${langText}`}
                    helperText={HELPTEXT.EXAMPLE_EN}
                    type="text"
                    fullWidth
                    error={error}
                  />
                      <LengthCounter promptLength={newPromptLength} />
                  <ListItemSecondaryAction style={{ marginLeft: 5 }}>
                    <Fab
                      aria-label="Add"
                      color="primary"
                      style={{ height: 40, width: 40 }}
                      onClick={event => {
                          handleOnClickNew(
                            {
                              ...event,
                              target: {
                                ...event.target,
                                type: "text",
                              },
                            },
                            "successTemplates"
                          );
                          setNewPromptLength(0);
                        }
                      }
                      disabled={error}
                    >
                      <Add />
                    </Fab>
                  </ListItemSecondaryAction>
                </ListItem>
                {successTemplatePrompts.map((item, key) => {
                  return (
                    <ListItem
                      key={key}
                      style={{
                        width: "100%",
                        borderBottomLeftRadius:
                          key === successTemplatePrompts.length - 1 && 6,
                        borderBottomRightRadius:
                          key === successTemplatePrompts.length - 1 && 6,
                        backgroundColor: bgColorList(item.severity),
                      }}
                    >
                      <TextField
                        name={promptName}
                        placeholder={equalHelpText(item.idc)}
                        multiline
                        rowsMax={3}
                        value={item.value}
                        onChange={event => {
                          onChangeText(
                            {
                              promptName: event.target.name,
                              completionTemplateType: "successTemplates",
                              severity: currentLevel,
                              value: event.target.value,
                            },
                            item.idc
                          );
                        }}
                        onBlur={event => {
                          handleSaveAllPrompts(event, item.idc);
                        }}
                        onKeyPress={event => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            updateNew(event, "successTemplates");
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.values}
                        // margin="dense"
                        fullWidth
                        required
                      />
                      <LengthCounter promptLength={item.value.length} />
                      <ListItemSecondaryAction>
                        <DeleteButton
                          {...{
                            handleDelete: () => {
                              handlePromptDelete(
                                null,
                                "successTemplates",
                                promptName,
                                item.idc
                              );
                            },
                            deleteMessage:
                              "Are you sure you want to delete this prompt?",
                            popover: true,
                            color: "secondary",
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            ) : null}
          </div>
        </div>
      </Paper>
    </div>
  );
};

ExpansionStatements.propTypes = {
  lang: PropTypes.string.isRequired,
  promptName: PropTypes.string.isRequired,
  promptsExample: PropTypes.object.isRequired,
  prompts: PropTypes.object.isRequired,
  HELPTEXT: PropTypes.object.isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleSaveAllPrompts: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ExpansionStatements;
