import { Component, forwardRef } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles.js";
import withTheme from "../../withTheme";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import * as SlangTooltips from "../../common/SlangTooltips.js";

import DocHelpLink from "../../common/DocLinks";
import TablePaginationActionsWrapped from "./pagination";
import {
  validateEntityTypeName,
  validateValueTypeIdentity,
} from "../../../utils/validate";
import {
  replaceAtArray,
  addNewKeyToObject,
  deleteKeyArray,
} from "../../../utils/helpers";

import { testClassNames } from "../../../utils/integrationTestClassNames";

const Transition = forwardRef((props, ref) => {
  return (
    <Slide direction="up" {...props} timeout={{ enter: 1000, exit: 500 }} />
  );
});

const cleanSynonyms = synonyms => {
  let cleanSynonyms = synonyms
    .join(",")
    .replace(/(^[\s\uFEFF\xA0,]+)|([\s\uFEFF\xA0,]+$)/g, "");
  cleanSynonyms = cleanSynonyms.replace(/(,,)+/g, "");
  cleanSynonyms = cleanSynonyms.replace(/(\s\s)+/g, " ");

  const checkSynonyms =
    cleanSynonyms !== ""
      ? cleanSynonyms
          .split(",")
          .filter(item => item && item.trim() !== "")
          .map(item => item.trim())
      : [];
  return checkSynonyms;
};

let emptyRows = 1;
const reMapItems = listValues => {
  return listValues?.map((item, key) => ({
    ...item,
    cid: key,
  }));
};
export class AddEntityType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openModal: false,
      nameValueType: "",
      synonymValueType: "",
      page: 0,
      rowsPerPage: 5,
      rowsPerPageOptions: [5],
      show: "",
      nameType: "",
      descriptionType: "",
      valuesType: [],
      valuesTypeStatic: [],
      samplesType: false,
      allEntityTypes: [],
      errorName: false,
      errorValueIdentity: false,
      errorValue: null,
      errorIdentity: false,
      deleteName: null,
      deleteValue: null,
      editNameValueType: "",
      editSynonymValueType: "",
      editValue: null,
      searchTerm: "",
      expandCheck: false,
    };
  }

  handleExpandSelect = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleDeleteClick = ID => {
    this.setState({
      openModal: true,
      deleteValue: ID,
      deleteName: this.state.valuesTypeStatic[ID].identity,
    });
  };

  handleValueDelete = () => {
    const ID = this.state.deleteValue;
    const newTypeValues = reMapItems(
      deleteKeyArray(this.state.valuesTypeStatic, ID)
    );
    this.setState({
      valuesType: newTypeValues,
      valuesTypeStatic: newTypeValues,
      openModal: false,
      deleteValue: null,
    });
    this.reSearchItem();
  };

  handleModalClose = event => {
    this.setState({ openModal: false });
  };

  handleChangeType = event => {
    if (event.target.name === "nameType") {
      this.setState({ errorName: false });
      let validTypeName = false;
      const cleanText = event.target.value
        .replace(/([^a-zA-Z.]+)/g, "")
        .replace(/(\.\.)+/g, ".");

      if (this.props.typeData) {
        const searchTypeName = this.props.allEntityTypes.filter(
          item => item.name !== this.props.typeData.name
        );
        validTypeName = validateEntityTypeName(cleanText, searchTypeName);
      } else {
        const searchTypeName = this.props.allEntityTypes;
        validTypeName = validateEntityTypeName(cleanText, searchTypeName);
      }
      const OK = cleanText.length > 0 ? validTypeName : true;

      this.setState({
        [event.target.name]: cleanText,
        errorName: !OK,
      });
    } else if (event.target.name === "nameValueType") {
      this.setState({ errorValueIdentity: null });
      const cleanText = event.target.value.trim();
      const OK =
        cleanText.length > 0
          ? validateValueTypeIdentity(cleanText, this.state.valuesTypeStatic)
          : false;

      this.setState({
        [event.target.name]: event.target.value,
        errorValueIdentity: !OK,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleChangeValueType = event => {
    const cleanText = event.target.value;
    const ID = this.state.editValue;
    if (event.target.name === "editNameValueType") {
      this.setState({ errorIdentity: null });

      const searchTypeValues = this.state.valuesTypeStatic.filter(
        (item, key) => key !== ID
      );

      const OK =
        cleanText.length > 0
          ? validateValueTypeIdentity(cleanText, searchTypeValues)
          : null;

      this.setState(prevState => {
        const newChangeToID = addNewKeyToObject(
          prevState.valuesTypeStatic[ID],
          {
            identity: cleanText,
          }
        );
        const newValuesTypeStatic = replaceAtArray(
          prevState.valuesTypeStatic,
          ID,
          newChangeToID
        );
        const currentID = prevState.valuesType.reduce(
          (Acc, Cur, key) => (Cur.cid === ID ? key : Acc),
          null
        );
        const newValuesType = replaceAtArray(
          prevState.valuesType,
          currentID,
          newChangeToID
        );
        return {
          valuesType: newValuesType,
          valuesTypeStatic: newValuesTypeStatic,
          errorIdentity: !OK ? ID : null,
        };
      });
    } else {
      const synonymValueType = cleanText !== "" ? cleanText.split(", ") : [];
      this.setState(prevState => {
        const newChangeToID = addNewKeyToObject(
          prevState.valuesTypeStatic[ID],
          {
            synonyms: synonymValueType,
          }
        );
        const newValuesTypeStatic = replaceAtArray(
          prevState.valuesTypeStatic,
          ID,
          newChangeToID
        );
        const currentID = prevState.valuesType.reduce(
          (Acc, Cur, key) => (Cur.cid === ID ? key : Acc),
          null
        );
        const newValuesType = replaceAtArray(
          prevState.valuesType,
          currentID,
          newChangeToID
        );

        return {
          valuesType: newValuesType,
          valuesTypeStatic: newValuesTypeStatic,
        };
      });
    }
  };

  handleEditValueBlur = ID => {
    const errorIdentity = this.state.errorIdentity;
    if (errorIdentity === ID) {
      this.setState(prevState => {
        const newChangeToID = addNewKeyToObject(
          prevState.valuesTypeStatic[ID],
          {
            identity: prevState.editIdentityValueType,
          }
        );
        const newValuesTypeStatic = replaceAtArray(
          prevState.valuesTypeStatic,
          ID,
          newChangeToID
        );
        const currentID = prevState.valuesType.reduce(
          (Acc, Cur, key) => (Cur.cid === ID ? key : Acc),
          null
        );
        const newValuesType = replaceAtArray(
          prevState.valuesType,
          currentID,
          newChangeToID
        );
        return {
          valuesType: newValuesType,
          valuesTypeStatic: newValuesTypeStatic,
          errorIdentity: null,
          editIdentityValueType: null,
          editSynonymValueType: null,
          editValue: null,
        };
      });
    } else {
      this.setState(prevState => {
        const checkSynonyms = cleanSynonyms(
          prevState.valuesTypeStatic[ID].synonyms
        );
        const checkIdentity = prevState.valuesTypeStatic[ID].identity.trim();
        const newChangeToID = addNewKeyToObject(
          prevState.valuesTypeStatic[ID],
          {
            synonyms: checkSynonyms,
            identity: checkIdentity,
          }
        );
        const newValuesTypeStatic = replaceAtArray(
          prevState.valuesTypeStatic,
          ID,
          newChangeToID
        );
        const currentID = prevState.valuesType.reduce(
          (Acc, Cur, key) => (Cur.cid === ID ? key : Acc),
          null
        );
        const newValuesType = replaceAtArray(
          prevState.valuesType,
          currentID,
          newChangeToID
        );
        return {
          valuesType: newValuesType,
          valuesTypeStatic: newValuesTypeStatic,
          errorIdentity: null,
          editIdentityValueType: null,
          editSynonymValueType: null,
          editValue: null,
        };
      });
    }
    this.reSearchItem();
  };

  reSearchItem = () => {
    if (this.state.searchTerm !== "") {
      setTimeout(() => {
        this.filterValues(this.state.searchTerm);
      }, 300);
    }
  };

  handleDoneClose = event => {
    event.preventDefault();
    if (this.state.newType !== "" && this.state.valuesTypeStatic.length) {
      const expand = this.state.expandCheck ? "auto" : "none";

      const newEntityType = {
        $EntityType$: "enumEntityType",
        description: this.state.descriptionType,
        name: this.state.nameType,
        expand,
        values: this.state.valuesTypeStatic.map(item => {
          const { cid, ...rest } = item;
          return rest;
        }),
      };

      if (this.props.typeData) {
        const newEntityTypeEdit = {
          ...this.props.typeData,
          ...newEntityType,
        };
        if (this.props.typeData.name !== this.state.nameType) {
          const newName = this.state.nameType;
          const oldName = this.props.typeData.name;

          this.props.handleNewEntityType(
            newEntityTypeEdit,
            this.props.typeID,
            newName,
            oldName
          );
        } else {
          this.props.handleNewEntityType(
            newEntityTypeEdit,
            this.props.typeID,
            null,
            null
          );
        }
      } else {
        this.props.handleNewEntityType(newEntityType, null, null, null);
      }

      this.setState({
        open: false,
        nameType: "",
        descriptionType: "",
        valuesType: [],
        valuesTypeStatic: [],
        searchTerm: "",
      });
    } else {
      this.setState({
        errorValue: "Add at least one value here",
      });
    }
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
      openModal: false,
      nameValueType: "",
      synonymValueType: "",
      show: "",
      nameType: "",
      descriptionType: "",
      valuesType: [],
      valuesTypeStatic: [],
      samplesType: false,
      allEntityTypes: [],
      errorName: false,
      errorValueIdentity: false,
      errorValue: null,
      errorIdentity: null,
      deleteValue: null,
      editNameValueType: "",
      editSynonymValueType: "",
      searchTerm: "",
      editValue: null,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      if (this.props.typeOpenDialog) {
        if (this.props.typeData) {
          const expand = !!(
            this.props.typeData.expand && this.props.typeData.expand === "auto"
          );
          const allValues = reMapItems(this.props.typeData.values);
          this.setState({
            openModal: false,
            nameValueType: "",
            synonymValueType: "",
            show: "",
            nameType: this.props.typeData.name,
            descriptionType: this.props.typeData.description,
            valuesType: allValues,
            valuesTypeStatic: allValues,
            samplesType: false,
            expandCheck: expand,
            allEntityTypes: this.props.allEntityTypes,
            errorName: false,
            errorValueIdentity: false,
            errorValue: null,
            errorIdentity: null,
            deleteValue: null,
            editNameValueType: "",
            editSynonymValueType: "",
            searchTerm: "",
            editValue: null,
          });
        } else {
          this.handleClickOpen();
        }
      }
    }
  }

  handleMouseOver = ID => {
    this.setState({
      show: ID,
    });
  };

  handleMouseLeave = name => {
    this.setState({
      show: "",
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  handleAddSynonyms = () => {
    const synonymValueType = cleanSynonyms(
      this.state.synonymValueType.split(",")
    );
    const newSynonym = {
      identity: this.state.nameValueType.trim(),
      synonyms: synonymValueType,
    };
    const newvaluesType = reMapItems([
      newSynonym,
      ...this.state.valuesTypeStatic,
    ]);

    this.setState({
      valuesType: newvaluesType,
      valuesTypeStatic: newvaluesType,
      synonymValueType: "",
      nameValueType: "",
      searchTerm: "",
    });
  };

  handleChangeSearch = event => {
    if (event.target.value.length < 128) {
      this.setState({
        searchTerm: event.target.value,
      });
      this.filterValues(event.target.value);
    }
  };

  filterValues(text) {
    const typeValues = this.state.valuesTypeStatic.length
      ? this.state.valuesTypeStatic
      : [];
    if (!typeValues || typeValues.length === 0) {
      return;
    }

    if (text && text.length > 0) {
      const filteredValueList = typeValues.filter(item => {
        return (
          item.identity.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
          item.synonyms.join(" ").toLowerCase().indexOf(text.toLowerCase()) !==
            -1
        );
      });
      this.setState({ valuesType: filteredValueList || [] });
    } else {
      this.setState({ valuesType: typeValues });
    }
  }

  handleEditClick = (event = null, item, ID) => {
    this.setState({
      editNameValueType: item.identity ? item.identity : "",
      editSynonymValueType: item.synonyms ? item.synonyms.join(",") : "",
      editValue: ID,
      errorIdentity: null,
    });
  };

  onKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  onKeyPressBlur = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.target.blur();
    }
  };

  onKeyPressAdd = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (this.state.errorValueIdentity || this.state.nameValueType === "") {
      } else {
        this.handleAddSynonyms();
      }
    }
  };

  onKeyPressCancel = event => {
    if (event.key === "Enter") {
      if (this.props.withButton) {
        this.handleClose(event);
      } else {
        this.props.handleTypeDialogClose(event);
      }

      event.preventDefault();
    }
  };

  onKeyPressSave = event => {
    if (event.key === "Enter") {
      if (!this.state.errorIdentity) {
        this.handleDoneClose(event);
      }
      event.preventDefault();
    }
  };

  render() {
    const { classes, withButton, typeOpenDialog } = this.props;
    const {
      open,
      nameType,
      descriptionType,
      valuesType,
      samplesType,
      nameValueType,
      synonymValueType,
      page,
      rowsPerPage,
      rowsPerPageOptions,
      show,
      editValue,
      errorName,
      errorValueIdentity,
      errorValue,
      errorIdentity,
      expandCheck,
      searchTerm,
      deleteName,
    } = this.state;

    if (valuesType) {
      emptyRows =
        rowsPerPage -
        Math.min(
          rowsPerPage,
          valuesType.filter(Boolean).length - page * rowsPerPage
        );
    } else if (samplesType) {
      emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, samplesType.length - page * rowsPerPage);
    } else {
      emptyRows = 0;
    }

    return (
      <div className={classes.margin}>
        {withButton && (
          <Button
            variant="text"
            className={`${classes.eTypeButton} ${testClassNames.BUDDY_ADD_ENTITY_BUTTON}`}
            onClick={this.handleClickOpen}
          >
            <Icon>add</Icon>
          </Button>
        )}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openModal}
          onClose={this.handleModalClose}
          onBackdropClick={this.handleModalClose}
          onEscapeKeyDown={this.handleModalClose}
          disableAutoFocus
        >
          <div className={classes.modal}>
            <Typography variant="body2" id="modal-title">
              Delete <strong>{deleteName}</strong> from entity values ?
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description" />
            <br />
            <br />
            <Button
              color="default"
              onClick={this.handleModalClose}
              style={{ float: "right" }}
            >
              Close
            </Button>
            <Button
              className={classes.buttonDelete}
              onClick={this.handleValueDelete}
              style={{ float: "right" }}
            >
              OK
            </Button>
          </div>
        </Modal>

        <Dialog
          open={withButton ? open : typeOpenDialog}
          onClose={
            withButton ? this.handleClose : this.props.handleTypeDialogClose
          }
          aria-labelledby="form-dialog-title"
          className={classes.dialogWidth}
          TransitionComponent={Transition}
          fullWidth
          maxWidth="md"
        >
          <form onSubmit={this.handleDoneClose} onKeyPress={this.onKeyPress}>
            <DialogTitle id="form-dialog-title">
              {" Add / Edit Entity Type "} <DocHelpLink docFor="ENTITY_TYPE" />
              <Button
                style={{ float: "right" }}
                type="submit"
                variant="text"
                classes={{
                  root: classes.eTypeDone,
                  disabled: classes.disabled,
                }}
                onKeyPress={this.onKeyPressSave}
                disabled={
                  errorName ||
                  !(this.state.newType !== "" && this.state.valuesType?.length)
                }
              >
                Done
              </Button>
              <Button
                style={{ float: "right" }}
                onClick={
                  withButton
                    ? this.handleClose
                    : this.props.handleTypeDialogClose
                }
                variant="text"
                color="default"
                onKeyPress={this.onKeyPressCancel}
              >
                Cancel
              </Button>
            </DialogTitle>
            <DialogContent>
              <Grid xs={12} item>
                <Grid
                  item
                  container
                  justify="center"
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h5" />
                  </Grid>
                  <Grid
                    item
                    container
                    justify="center"
                    direction="row"
                    alignItems="center"
                  >
                    <Grid container className={classes.marginal}>
                      <Grid item container>
                        <Grid container item xs={12}>
                          <Grid item xs={4} className={classes.space3}>
                            <TextField
                              autoFocus
                              placeholder={SlangTooltips.ENTITY_TYPE_NAME}
                              label="Entity Type Name"
                              name="nameType"
                              onChange={this.handleChangeType}
                              type="text"
                              pattern="[A-Za-z\s]+"
                              value={nameType || ""}
                              required
                              fullWidth
                              error={errorName}
                            />
                          </Grid>
                          <Grid item xs={1} className={classes.space3} />
                          <Grid item xs={6} className={classes.space3}>
                            <TextField
                              id="Description"
                              placeholder={
                                SlangTooltips.ENTITY_TYPE_DESCRIPTION
                              }
                              label="Add Description"
                              type="text"
                              name="descriptionType"
                              onChange={this.handleChangeType}
                              value={descriptionType || ""}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container className={classes.marginal}>
                      <Grid container item spacing={0}>
                        <Grid item xs={2}>
                          <Typography variant="h5">Values</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            className={classes.checkboxLabel}
                            control={
                              <Checkbox
                                className={classes.checkbox}
                                color="primary"
                                checked={expandCheck}
                                onChange={this.handleExpandSelect(
                                  "expandCheck"
                                )}
                                value="expandCheck"
                              />
                            }
                            label="Expand values"
                          />
                          <Tooltip
                            title={SlangTooltips.ENTITY_TYPE_EXPAND}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Icon className={classes.iconHelp}>help</Icon>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Input
                            placeholder="Search Values"
                            className={
                              searchTerm !== "" ? classes.SearchOn : null
                            }
                            value={searchTerm}
                            inputProps={{
                              "aria-label": "Search Values",
                            }}
                            onChange={this.handleChangeSearch}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item container className={classes.group} xs={12}>
                          <Grid item xs={5}>
                            <TextField
                              id="newvalue"
                              label={SlangTooltips.ENTITY_TYPE_VALUE}
                              type="text"
                              name="nameValueType"
                              onChange={this.handleChangeType}
                              onKeyPress={this.onKeyPressAdd}
                              value={nameValueType}
                              error={errorValueIdentity && nameValueType !== ""}
                              title="Add at least one unique entity type value below by clicking the + button"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="synonym"
                              label={SlangTooltips.ENTITY_TYPE_SYNONYM}
                              name="synonymValueType"
                              onChange={this.handleChangeType}
                              onKeyPress={this.onKeyPressAdd}
                              value={synonymValueType}
                              type="text"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              className={classes.buttonAddValue}
                              aria-label="Add"
                              onClick={this.handleAddSynonyms}
                              onKeyPress={this.onKeyPressAdd}
                              disabled={
                                errorValueIdentity || nameValueType === ""
                              }
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid container item className={classes.group} xs={12}>
                          {errorValue && (
                            <Grid item xs={12}>
                              {errorValue}
                            </Grid>
                          )}
                          {valuesType && (
                            <Table className={classes.tableValues} border={0}>
                              <TableBody>
                                {valuesType
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((item, key) => {
                                    if (item) {
                                      return (
                                        <TableRow
                                          key={key}
                                          id={5 + "entityValues" + item.cid}
                                          onMouseEnter={this.handleMouseOver.bind(
                                            this,
                                            key
                                          )}
                                          onMouseLeave={this.handleMouseLeave}
                                          hover
                                        >
                                          <TableCell
                                            id={item.identity + 1}
                                            component="th"
                                            scope="row"
                                            className={classes.padding}
                                          >
                                            <TextField
                                              className={classes.values}
                                              id={1 + "entityValues" + item.cid}
                                              placeholder={item.identity}
                                              type="text"
                                              name="editNameValueType"
                                              InputProps={{
                                                disableUnderline: !(
                                                  editValue === item.cid
                                                ),
                                              }}
                                              onChange={
                                                this.handleChangeValueType
                                              }
                                              onKeyPress={this.onKeyPressBlur}
                                              onBlur={this.handleEditValueBlur.bind(
                                                this,
                                                item.cid
                                              )}
                                              onFocus={event =>
                                                this.handleEditClick(
                                                  event,
                                                  item,
                                                  item.cid
                                                )
                                              }
                                              value={item.identity}
                                              fullWidth
                                              label={
                                                errorIdentity === item.cid
                                                  ? "Unique value"
                                                  : null
                                              }
                                              error={errorIdentity === item.cid}
                                            />
                                          </TableCell>
                                          <TableCell
                                            id={item.identity + 4}
                                            className={classes.padding}
                                          >
                                            <TextField
                                              id={2 + "entityValues" + item.cid}
                                              type="text"
                                              InputProps={{
                                                disableUnderline: !(
                                                  editValue === item.cid
                                                ),
                                              }}
                                              className={classes.values}
                                              placeholder={
                                                item.synonyms.length
                                                  ? item.synonyms.join(", ")
                                                  : "Add Synonyms"
                                              }
                                              name="editSynonymValueType"
                                              onBlur={this.handleEditValueBlur.bind(
                                                this,
                                                item.cid
                                              )}
                                              onFocus={event =>
                                                this.handleEditClick(
                                                  event,
                                                  item,
                                                  item.cid
                                                )
                                              }
                                              onChange={
                                                this.handleChangeValueType
                                              }
                                              onKeyPress={this.onKeyPressBlur}
                                              value={
                                                item.synonyms.length
                                                  ? item.synonyms.join(", ")
                                                  : ""
                                              }
                                              fullWidth
                                            />
                                          </TableCell>
                                          <TableCell
                                            id={item.identity + 3}
                                            className={classes.tableColWidth}
                                          >
                                            <span
                                              style={
                                                show === key
                                                  ? {
                                                      display: "inherit",
                                                      width: 50,
                                                    }
                                                  : {
                                                      display: "none",
                                                      width: 50,
                                                    }
                                              }
                                            >
                                              <IconButton
                                                id={item.identity + 7}
                                                className={classes.buttonDelete}
                                                aria-label="Delete"
                                                onClick={this.handleDeleteClick.bind(
                                                  this,
                                                  item.cid
                                                )}
                                              >
                                                <DeleteIcon
                                                  id={item.identity + 5}
                                                />
                                              </IconButton>
                                            </span>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                    emptyRows++;
                                    return null;
                                  })}
                                {emptyRows > 0 && (
                                  <TableRow style={{ height: 48 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                  </TableRow>
                                )}
                              </TableBody>
                              <TableFooter>
                                <TableRow sytle={{ textAlign: "right" }}>
                                  <TablePagination
                                    colSpan={3}
                                    count={valuesType.filter(Boolean).length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={
                                      this.handleChangeRowsPerPage
                                    }
                                    ActionsComponent={
                                      TablePaginationActionsWrapped
                                    }
                                  />
                                </TableRow>
                              </TableFooter>
                            </Table>
                          )}
                        </Grid>
                      </Grid>

                      {samplesType &&
                        samplesType.map((item, key) => {
                          return (
                            <Grid key={key} container item>
                              <Grid item xs={3} className={classes.space3} />
                              <Grid
                                container
                                item
                                className={classes.group}
                                xs={8}
                              >
                                <Grid item xs={9} className={classes.padRight}>
                                  {item}
                                </Grid>
                                <Grid item xs={2} />
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        </Dialog>
      </div>
    );
  }
}

AddEntityType.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNewEntityType: PropTypes.func.isRequired,
};

export default withTheme(withStyles(styles)(AddEntityType));
