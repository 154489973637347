import { Component } from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  "@keyframes slang-sound": {
    from: {
      opacity: "0.35",
      height: "3px",
    },

    to: {
      opacity: 1,
      height: "28px",
    },
  },
  SlangCanvas: {
    display: "block",
  },
  SlangCanvasContainer: {
    maxWidth: "100%",
    overflow: "hidden",
    position: "absolute",
  },
});

const canvasId = "slang-canvas";
const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

const cancelAnimationFrame =
  window.cancelAnimationFrame || window.mozCancelAnimationFrame;

class SlangListeningAnimation extends Component {
  constructor(props) {
    super(props);
    this.animationRequest = { requestId: null };
    this.state = {
      waveColors: this.props.waveColors,
    };
    this.classes = styles();
  }

  componentDidMount() {
    this.visualize(this.animationRequest);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationRequest.requestId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.waveColors[3] !== this.props.waveColors[3]) {
      this.setState({
        waveColors: this.props.waveColors,
      });
      cancelAnimationFrame(this.animationRequest.requestId);
      this.visualize(this.animationRequest);
    }
  }

  visualize = animationRequest => {
    let cvs, ctx;
    const nodes = 60; // more peaks and valleys in wave
    const waves = [];
    const waveHeight = 24;
    const colors = this.state.waveColors;
    const bounceValue = 5; // LOWER TO BOUNCE MORE

    const bounce = (nodeArr, bounceValue) => {
      nodeArr[1] =
        (waveHeight / 2) * Math.sin(nodeArr[2] / bounceValue) + cvs.height / 2;
      nodeArr[2] = nodeArr[2] + nodeArr[3];
    };

    const drawWave = obj => {
      const diff = (a, b) => {
        return (b - a) / 2 + a;
      };

      ctx.fillStyle = obj.colour;
      ctx.beginPath();
      ctx.moveTo(0, cvs.height);
      ctx.lineTo(obj.nodes[0][0], obj.nodes[0][1]);

      for (let i = 0; i < obj.nodes.length; i++) {
        if (obj.nodes[i + 1]) {
          ctx.quadraticCurveTo(
            obj.nodes[i][0],
            obj.nodes[i][1],
            diff(obj.nodes[i][0], obj.nodes[i + 1][0]),
            diff(obj.nodes[i][1], obj.nodes[i + 1][1])
          );
        } else {
          ctx.lineTo(obj.nodes[i][0], obj.nodes[i][1]);
          ctx.lineTo(cvs.width, cvs.height);
        }
      }
      ctx.closePath();
      ctx.fill();
    };

    function Wave(colour, lambda, nodes) {
      this.colour = colour;
      this.lambda = lambda;
      this.nodes = [];

      for (let i = 0; i <= nodes + 2; i++) {
        const temp = [
          ((i - 1) * cvs.width) / nodes,
          0,
          Math.random() * 200,
          0.3,
        ];
        this.nodes.push(temp);
      }
    }

    const resizeCanvas = (canvas, width, height) => {
      if (width && height) {
        canvas.width = width;
        canvas.height = height;
      } else {
        if (window.innerWidth > 1920) {
          canvas.width = window.innerWidth;
        } else {
          canvas.width = 1920;
        }

        canvas.height = waveHeight;
      }
    };

    const update = animationRequest => {
      ctx.globalAlpha = 1;
      ctx.globalCompositeOperation = "source-over";
      ctx.clearRect(0, 0, cvs.width, cvs.height);
      ctx.globalCompositeOperation = "luminosity";
      // Optional other type of canvas composition for different animation type.
      // ctx.globalCompositeOperation = "screen";
      // ctx.globalCompositeOperation = "hard-light";
      ctx.globalAlpha = 0.3;

      for (let i = 0; i < waves.length; i++) {
        for (let j = 0; j < waves[i].nodes.length; j++) {
          bounce(waves[i].nodes[j], bounceValue);
        }
        drawWave(waves[i]);
      }
      animationRequest.requestId = requestAnimationFrame(() =>
        update(animationRequest)
      );
    };
    // Initiator function
    const init = animationRequest => {
      cvs = document.getElementById(canvasId);
      ctx = cvs.getContext("2d");
      resizeCanvas(cvs);

      for (let i = 0; i < colors.length; i++) {
        waves.push(new Wave(colors[i], 1, nodes));
      }

      update(animationRequest);
    };
    init(animationRequest);
  };

  render() {
    const { classes, waveColors } = this.props;
    return (
      <div id="slang-listening-animation">
        <div className={classes.SlangCanvasContainer}>
          <canvas id={canvasId} className={classes.SlangCanvas}></canvas>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SlangListeningAnimation);
