import { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import CheckLoggedInState from "../../checkLoggedInState.js";
import { RouteCreator } from "./RouteControl";

import Notifications from "../Notifications";
import CreateOrgID from "../../home/AssistantsConfigsHome/createOrgID";
import Loading from "../slangLoader";
import detectBrowser from "../../../utils/detectBrowser";
import {
  setAuthSession,
  createPayload,
} from "../../../utils/auth/handleAuthState";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs.js";

const message = detectBrowser();

const Loader = notificationProps => (
  <>
    <Loading />
    {message ? <Notifications {...notificationProps} /> : null}
  </>
);

function RoutesWrapper({ children, setRoutesList, signIn }) {
  const {
    isLoading,
    isAuthenticated,
    user,
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const [isSession, setIsSession] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    notify: {
      variant: "warning",
      open: true,
      autoHide: null,
      position: { vertical: "top", horizontal: "right" },
      message,
    },
  });

  useEffect(() => {
    return () => {
      const routesList = RouteCreator();
      setRoutesList(routesList);
    };
  }, [isSession, setRoutesList]);

  CheckLoggedInState();

  if (isLoading) {
    return <Loader {...notificationProps} />;
  }

  if (error) {
    logEvent(SlangSeverityLevels.ERROR, SlangEvents.SIGNIN_FAILED, {
      reason: error,
    });
    console.log(error);
    setNotificationProps({
      ...notificationProps,
      variant: "error",
      message: "Something went wrong with the Authenticator!!",
    });
    return <Loader {...notificationProps} />;
  }

  if (user && isAuthenticated && !isSession) {
    setAuthSession({ user, getAccessTokenSilently, getIdTokenClaims })
      .then(data => {
        const signInPayload = createPayload(user);
        signIn(signInPayload);
        setIsSession(data);
      })
      .catch(err => {
        setNotificationProps({
          ...notificationProps,
          variant: "error",
          message: "Something went wrong !!",
        });
      });

    return <Loader {...notificationProps} />;
  } else if (user && isAuthenticated && isSession) {
    const isOrgID =
      isSession["https://slanglabs.in/app_metadata"].organization_id;
    if (!isOrgID) return <CreateOrgID />;
  }

  return (
    <>
      {children}
      {message ? <Notifications {...notificationProps} /> : null}
    </>
  );
}
export default RoutesWrapper;
