import * as CognitoAuth from "./cognito_auth";
import axios from "axios";
import AuthConfig from "./config";
import AppConfig from "../../app.config";
import { GetSetAccessTokenRaw } from "../../utils/auth/handleAuthState";

export function initialize() {
  CognitoAuth.initialize();
}

export function signup(username, password, promocode) {
  return new Promise((resolve, reject) => {
    CognitoAuth.signup(username, password, promocode)
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}

export function confirmSignup(username, confirmationCode) {
  return new Promise((resolve, reject) => {
    CognitoAuth.confirmSignup(username, confirmationCode)
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}

export function login(username, password) {
  return new Promise((resolve, reject) => {
    CognitoAuth.login(username, password)
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    CognitoAuth.logout()
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}

export function changePassword(oldPassword, newPassword) {
  return new Promise((resolve, reject) => {
    CognitoAuth.changePassword(oldPassword, newPassword)
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}

export function sendConfirmCodeForforgotPassword(username) {
  return new Promise((resolve, reject) => {
    CognitoAuth.sendConfirmCodeForforgotPassword(username)
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}

export function resetPassword(username, confirmationCode, newPassword) {
  return new Promise((resolve, reject) => {
    CognitoAuth.resetPassword(username, confirmationCode, newPassword)
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}

export function isUserLoggedIn() {
  return new Promise((resolve, reject) => {
    CognitoAuth.isUserLoggedIn()
      .then(data => {
        resolve({ success: true, data });
      })
      .catch(e => {
        resolve({ success: false, error: e });
      });
  });
}
export function createOrganisation({ name }) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        AppConfig.SLANG_HOST + AuthConfig.AUTH_BASE + AuthConfig.ORGANIZATION,
        { name },
        {
          headers: {
            Authorization: `Bearer ${GetSetAccessTokenRaw()}`,
            Identity: "none",
          },
        }
      )
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        reject({ success: false, error: e });
      });
  });
}

export function getAllOrganizations() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        AppConfig.SLANG_HOST + AuthConfig.AUTH_BASE + AuthConfig.ORGANIZATIONS,
        {
          headers: {
            Authorization: `Bearer ${GetSetAccessTokenRaw()}`,
            Identity: "none",
          },
        }
      )
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        reject({ success: false, error: e });
      });
  });
}
