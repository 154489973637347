export default function userJourneyReducer(state, action) {
  switch (action.type) {
    case "reState": {
      return action.payload;
    }
    case "delete": {
      const { userJourneyID } = action.payload;
      return state.filter((_, ID) => ID !== userJourneyID);
    }
    case "toggle":
      return state.map(obj => {
        if (obj.name === action.payload.name) {
          obj.enabled = action.payload.enabled;
        }
        return obj;
      });
    case "toggleReplaceUserData": {
      const { subDomainID, userDataID, toggle } = action.payload;
      return state.map((subDomain, ID) => {
        if (ID === subDomainID) {
          const userDataInfo = subDomain.userData[userDataID];
          subDomain.userData[userDataID] = {
            ...userDataInfo,
            replace: toggle,
          };
          return subDomain;
        }
        return subDomain;
      });
    }
    case "update":
      return state.map((obj, ID) => {
        if (ID === action.payload.idx) {
          return action.payload.data;
        }
        return obj;
      });
    case "userDataAdd": {
      const { dataID, subDomainID, userDataID, csvfields, csvdata } =
        action.payload;
      return state.map((subDomain, ID) => {
        if (ID === subDomainID) {
          if (userDataID !== undefined) {
            const userDataInfo = subDomain.userData[userDataID];
            subDomain.userData[userDataID] = {
              ...userDataInfo,
              value: {
                ...userDataInfo.value,
                csvfields,
                csvdata,
              },
            };
            return subDomain;
          } else {
            const newID = subDomain.userData?.length
              ? subDomain.userData.length
              : 0;
            const userDataInfo = subDomain.data[dataID];
            subDomain.userData[newID] = {
              ...userDataInfo,
              value: {
                ...userDataInfo.value,
                csvfields,
                csvdata,
              },
            };
            return subDomain;
          }
        }
        return subDomain;
      });
    }
    case "dataUpdate": {
      const { dataID, subDomainID, csvfields, csvdata } = action.payload;
      return state.map((subDomain, ID) => {
        if (ID === subDomainID) {
          if (dataID !== undefined) {
            const dataInfo = subDomain.data[dataID];
            subDomain.data[dataID] = {
              ...dataInfo,
              value: {
                ...dataInfo.value,
                csvfields,
                csvdata,
              },
            };
            return subDomain;
          }
        }
        return subDomain;
      });
    }
    case "userDataDelete": {
      const { subDomainID, userDataID } = action.payload;
      return state.map((subDomain, ID) => {
        if (ID === subDomainID && userDataID !== undefined) {
          subDomain.userData = subDomain.userData.filter(
            (_, i) => i !== userDataID
          );
          return subDomain;
        }
        return subDomain;
      });
    }
    case "dataTypeDelete": {
      const { subDomainID, dataID } = action.payload;
      console.log("object");

      return state.map((subDomain, ID) => {
        if (ID === subDomainID && dataID !== undefined) {
          subDomain.data = subDomain.data.filter((_, i) => i !== dataID);
          return subDomain;
        }
        return subDomain;
      });
    }

    default:
      return state;
  }
}
