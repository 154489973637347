import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import * as SlangTooltips from "../../../common/SlangTooltips";
import HelpExpansion from "./HelpExpansion";

const styles = makeStyles(theme => ({
  separate: {
    marginTop: theme.spacing(3),
  },
}));

const HelpPromptTextField = props => {
  const {
    lang,
    helpLangPrompts,
    handleOnChange,
    handleDeletePrompt,
    handleBlurSave,
  } = props;

  const helpDescription = helpLangPrompts[lang].helpDescription.value
    ? helpLangPrompts[lang].helpDescription.value
    : "";

  const errorTitle = helpDescription.trim() === "";
  const errorUtterance =
    helpLangPrompts[lang].utterances &&
    helpLangPrompts[lang].utterances.length === 0;

  const classes = styles();

  const helpExpansionProps = {
    onChangeText: handleOnChange,
    handleDelete: handleDeletePrompt,
    helpLangPrompts,
    handleBlurSave,
    lang,
    HELPTEXT: SlangTooltips.INTENT_HELP,
    errorTitle,
    errorUtterance,
  };

  return (
    <>
      <div className={classes.separate}>
        <TextField
          style={{ marginTop: 0 }}
          name="helpDescription"
          placeholder={SlangTooltips.INTENT_HELP.HELP_TITLE_EN}
          helperText={SlangTooltips.INTENT_HELP.TTILE_EXAMPLE_EN}
          value={helpDescription}
          onChange={handleOnChange}
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          onBlur={handleBlurSave}
          fullWidth
          error={errorTitle}
          required
        />
      </div>
      <div className={classes.separate}>
        <HelpExpansion {...helpExpansionProps} />
      </div>
    </>
  );
};

export default HelpPromptTextField;
