const convertToCSV = objArray => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  array.forEach(item => {
    let line = "";
    const replacer = value =>
      value === null ? "" : "" + value.toString().replace(/[,"]/g, " ") + ""; // specify how you want to handle null values here
    for (const key in item) {
      if (line !== "") line += ",";
      line += replacer(item[key]);
    }
    str += line + "\r\n";
  });

  return str;
};

const dateTimestamp = () => {
  const today = new Date();
  let sToday = " " + today.getDate().toString().padStart(2, "0") + "-";
  sToday += (today.getMonth() + 1).toString().padStart(2, "0") + "-";
  sToday += today.getFullYear() + "__";
  sToday += today.getHours().toString() + "_";
  sToday += today.getMinutes().toString() + "_";
  sToday += today.getSeconds().toString();
  return sToday;
};

export const exportCSVFile = (
  headers = [],
  items = [],
  fileTitle,
  csvString
) => {
  let allRows = items;
  if (headers) {
    allRows = [headers, ...items];
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(allRows);
  const csv = csvString || convertToCSV(jsonObject);
  const dateString = dateTimestamp();
  const exportedFilenmae =
    fileTitle + dateString + ".csv" || "export" + dateString + ".csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
