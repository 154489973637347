import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import UserJourneyItems from "./UserJourneyItems";
import DocHelpLink from "../../../common/DocLinks";
import { LinearProgress } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

function UserJourneysEdit(props) {
  const {
    classes,
    userJourneys,
    handleEditAppData,
    changeUserJourneyData,
    handleChangeTab,
  } = props;
  const [selectError, setSelectError] = useState(null);

  const handleSelectError = error => {
    setSelectError(error);
  };

  const UserJourneysProps = {
    userJourneys,
    handleEditAppData,
    changeUserJourneyData,
    handleSelectError,
    selectError,
    handleChangeTab,
  };

  return (
    <div className={classes.root}>
      <List>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" noWrap>
                {"User Journeys "} <DocHelpLink docFor="USER_JOURNEYS" />
              </Typography>
            }
            secondary={
              <Typography variant="subtitle1" color="error">
                {selectError || <span>&nbsp;</span>}
              </Typography>
            }
          />
        </ListItem>
        {userJourneys?.length ? (
          <UserJourneyItems {...UserJourneysProps} />
        ) : (
          <LinearProgress />
        )}
      </List>
    </div>
  );
}

UserJourneysEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  handleEditAppData: PropTypes.func.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
  userJourneys: PropTypes.array,
};

export default withStyles(styles)(UserJourneysEdit);
