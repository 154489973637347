import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid, Typography, Chip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  promptContainer: {
    padding: theme.spacing(2.5),
  },
  body: {
    textTransform: "capitalize",
  },
  appState: {
    textTransform: "uppercase",
  },
}));

const AppStatesProperties = props => {
  const { appStatesMetadata, selectedAppState, heading } = props;

  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [appStateName, setAppStateName] = useState("");
  const [isTerminal, setIsTerminal] = useState(false);

  useEffect(() => {
    const selectedAppStateData = appStatesMetadata[selectedAppState];
    setDescription(selectedAppStateData.description || "No Description");
    setDisplayName(selectedAppStateData.displayName || "No Display Name");
    setAppStateName(selectedAppStateData.appStateName || "No App State");
    setIsTerminal(selectedAppStateData.isTerminal || false);
  }, [selectedAppState]);

  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6" className={classes.chip} noWrap>
        {heading}
      </Typography>
      <div className={classes.promptContainer}>
        <Grid spacing={3} alignContent="center" alignItems="center" container>
          <Grid xs={6} item>
            <Typography variant="button">App State</Typography>
            <Typography variant="body1" className={classes.appState}>
              {appStateName}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography variant="button">Condition</Typography>
            <Typography variant="body1" className={classes.body}>
              {displayName}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="button">Description</Typography>
            <Typography variant="body1" className={classes.body}>
              {description}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography variant="button">Condition Type</Typography>
            <br />
            {isTerminal ? (
              <Chip size="small" label="Terminal" color="primary" />
            ) : (
              <Chip size="small" label="Non-Terminal" color="secondary" />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

AppStatesProperties.propTypes = {
  appStatesMetadata: PropTypes.array.isRequired,
  selectedAppState: PropTypes.number.isRequired,
  heading: PropTypes.string,
};

export default AppStatesProperties;
