import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Paper,
  Typography,
  Divider,
  Button,
  Popover,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  Android,
  Web,
  Apple,
  Clear as CloseIcon,
  TouchApp,
} from "@material-ui/icons";
import {
  editNoCodeActionState,
  emptyNoCodeActionsState,
  readNoCodeActionState,
  writeNoCodeActionState,
} from "../../../../utils/noCodeActionUtils";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";
import { actionsAppStateCondition } from "../../../../utils/MiscUtils";

const platforms = {
  android: { label: "Deep Link URI" },
  web: { label: "Link" },
  ios: { label: "Deep Link URI" },
};

const styles = makeStyles(theme => ({
  promptPaperDark: {
    width: "100%",
    padding: theme.spacing(2.5),
    margin: "1rem 0",
    textAlign: "left",
    background: theme.palette.background.default,
  },
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    textAlign: "left",
  },
  dialogAction: {
    width: "100%",
    padding: `0 ${theme.spacing(2.5)}px`,
    textAlign: "left",
  },
  iconButton: {
    marginRight: 20,
  },
  dialogHeading: {
    textTransform: "capitalize",
  },
  content: {
    padding: `0 ${theme.spacing(1)}px`,
    width: "100%",
    overflow: "hidden",
    minHeight: "250px",
  },
  closeButton: {
    float: "right",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  platformControl: {
    display: "flex",
    justifyContent: "flex-end",
    // margin: `0 ${theme.spacing(1)}px`,
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    width: "100%",
    height: 50,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  disabled: {},
  typography: {
    padding: theme.spacing(2),
  },
  middle: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  redButton: {
    color: theme.palette.error.main,
  },
}));

let _isMounted, _stateChanged;

const NoCodeActionDialog = props => {
  const {
    appStatesMetadata,
    userJourneyName,
    userJourneyID,
    changeUserJourneyData,
    refAnchor,
  } = props;

  const [open, setOpen] = useState(false);
  const [closeConfirm, setCloseConfirm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actions, setActions] = useState(emptyNoCodeActionsState() || {});
  const [actionUri, setActionUri] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("android");

  const { domain, userJourney, noCodeActions } = useSelector(state => ({
    domain: state.appSchema.appDATA.domain,
    userJourney: state.appSchema.appDATA.userJourneys[userJourneyID],
    noCodeActions:
      state.appSchema.appDATA.userJourneys[userJourneyID].noCodeActions,
  }));

  const classes = styles();

  useEffect(() => {
    if (open) {
      _isMounted = true;
      _stateChanged = false;
    }

    return () => {
      if (!open) {
        _isMounted = false;
        _stateChanged = false;
      }
    };
  }, [open]);

  useEffect(() => {
    if (open) updateNoCodeActionState();
  }, [open, selectedPlatform]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = event => {
    if (_stateChanged) {
      setAnchorEl(event.currentTarget);
      setCloseConfirm(true);
    } else {
      setOpen(false);
    }
  };

  // On Change Handler for Input Fields
  const onChangeActionState = e => {
    const { name, value } = e.target;

    let payload = {
      noCodeActionUri: actionUri,
      ...actionsAppStateCondition[domain][userJourney.name],
    };

    // Setting values depending on the Input Control
    if (name === "noCodeActionUri") {
      setActionUri(value);
      payload = {
        ...payload,
        noCodeActionUri: value,
      };
    }

    const newNoCodeActionState = editNoCodeActionState(
      actions,
      payload,
      selectedPlatform,
      userJourneyName
    );

    setActions(newNoCodeActionState);
    // If user made any change, setting flag to true to confirm before closing.
    _stateChanged = true;
  };

  // Save new state of noCodeActions to schema
  const handleSaveActionState = (noCodeActionState = actions) => {
    const newData = writeNoCodeActionState(noCodeActionState);

    let newUserJourneyData = userJourney;
    newUserJourneyData = {
      ...newUserJourneyData,
      noCodeActions: {
        ...newData,
      },
    };

    changeUserJourneyData(newUserJourneyData, userJourneyID);
    updateNoCodeActionState(newData);
    logEvent(
      SlangSeverityLevels.INFO,
      SlangEvents.USERJOURNEY_ACTIONS_UPDATED,
      {
        userjourney_name: userJourneyName,
        selectedPlatform,
      }
    );
  };

  // Update UI state for no code action for each platform
  const updateNoCodeActionState = (noCodeConfig = noCodeActions) => {
    let newNoCodeActionsState = actions;
    // Only reading the actions config from schema when first mounted, then always from state.
    if (noCodeConfig && _isMounted) {
      newNoCodeActionsState = readNoCodeActionState(noCodeConfig);
      setActions(newNoCodeActionsState);
    }
    const selectedPlatformNoCodeConfig =
      newNoCodeActionsState[selectedPlatform];
    if (selectedPlatformNoCodeConfig) {
      setActionUri(selectedPlatformNoCodeConfig.noCodeActionUri);
    }
    _isMounted = false;
  };

  const handleChangePlatform = (event, currentTab) => {
    if (currentTab !== null) {
      setSelectedPlatform(currentTab);
    }
  };

  return (
    <>
      {!(
        actionsAppStateCondition[domain] &&
        actionsAppStateCondition[domain][userJourney.name]
      ) ? (
        <Tooltip title="Actions not supported for this User Journey yet.">
          <span>
            <IconButton
              aria-label="View"
              className={classes.iconButton}
              onClick={handleClickOpen}
              ref={refAnchor}
              disabled={true}
            >
              <TouchApp />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton
          aria-label="View"
          className={classes.iconButton}
          onClick={handleClickOpen}
          ref={refAnchor}
        >
          <TouchApp />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeading}>
          {`Create Action for ${userJourney.displayName}`}

          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Popover
            id={"confirm-close-popover"}
            open={closeConfirm}
            anchorEl={anchorEl}
            onClose={() => {
              setCloseConfirm(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className={classes.middle}>
              <Typography className={classes.typography}>
                {"You may lose unsaved changes. Continue?"}
              </Typography>
              <Button
                className={classes.redButton}
                onClick={() => {
                  setCloseConfirm(false);
                  setOpen(false);
                }}
              >
                Yes
              </Button>{" "}
              <Button
                onClick={() => {
                  setCloseConfirm(false);
                }}
              >
                No
              </Button>
            </div>
          </Popover>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.promptPaper}>
            <ToggleButtonGroup
              color="primary"
              value={selectedPlatform}
              exclusive
              onChange={handleChangePlatform}
              aria-label="text alignment"
              className={classes.platformControl}
            >
              <ToggleButton value={"android"}>
                Android &nbsp; <Android />
              </ToggleButton>
              <ToggleButton value={"web"}>
                Web &nbsp; <Web />
              </ToggleButton>
              <ToggleButton value={"ios"}>
                iOS &nbsp; <Apple />
              </ToggleButton>
            </ToggleButtonGroup>
            <Paper className={classes.promptPaperDark}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom>
                    Set Action URI
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={8}>
                  <TextField
                    id="no-code-action-uri"
                    label={platforms[selectedPlatform].label}
                    variant="outlined"
                    fullWidth
                    value={actionUri}
                    className={classes.formControl}
                    name={"noCodeActionUri"}
                    onChange={onChangeActionState}
                    helperText={
                      "Replace the search query parameter with '<query>', eg. example.com?q=<query>"
                    }
                    size="small"
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
          <div className={classes.dialogAction}>
            <Button
              variant="text"
              classes={{
                root: classes.eTypeDone,
                disabled: classes.disabled,
              }}
              onClick={() => {
                handleSaveActionState(actions);
                setOpen(false);
              }}
              type="button"
              disabled={actionUri.trim() === ""}
            >
              {"Create Action"}
            </Button>
          </div>
          <br />
          <br />
        </DialogContent>
      </Dialog>
    </>
  );
};

NoCodeActionDialog.propTypes = {
  appStatesMetadata: PropTypes.array.isRequired,
  userJourneyName: PropTypes.string.isRequired,
  userJourneyID: PropTypes.number.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
};

export default NoCodeActionDialog;
