import { makeStyles } from "@material-ui/core";
import SlangBrandingSVG from "./assets/SlangBranding.svg";
const styles = makeStyles(theme => ({
  SlangBranding: {
    position: "absolute",
    margin: "0 auto",
    bottom: 14,
    zIndex: 2147472648,
    display: "flex",
    justifyContent: "center",
    padding: "3px 0 0 0",
    fontSize: "0.8em",
    width: "100%",
    color: props => props.brandColor,
  },
  SlangBrandingSVG: {
    marginTop: "0.1em",
    height: "0.6em",
    width: "auto",
  },
  SlangLink: {
    textDecoration: "none",
    color: "inherit",
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: 700,
  },
}));

const SlangBranding = props => {
  const classes = styles(props);
  return (
    <div className={classes.SlangBranding}>
      <a
        className={classes.SlangLink}
        href="https://www.slanglabs.in/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={SlangBrandingSVG}
          className={classes.SlangBrandingSVG}
          alt="Powered By Slang Labs"
        />
      </a>
    </div>
  );
};

export default SlangBranding;
