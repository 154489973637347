import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Fab from "@material-ui/core/Fab";

import { emptyStateUtterances } from "../../../utils/promptUtils";

import { validatePromptTextLanguage } from "../../../utils/validate";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import AppConfig from "../../../app.config";

const Langs = AppConfig.LANGUAGES;

const styles = theme => ({
  root: {
    width: "100%",
  },
  listWidth: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionInputList: {
    ...theme.shape,
    backgroundColor: theme.palette.background.default,
  },
});

class ExpansionHelp extends Component {
  state = {
    expanded: "panel1",
    error: true,
    langStatements: emptyStateUtterances() || {},
    StatementValues: this.props.StatementValues,
    en: {
      utterances: "",
    },
    hi: {
      utterances: "",
    },
  };

  componentDidMount() {
    this.setState({
      StatementValues: this.props.StatementValues,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        StatementValues: this.props.StatementValues,
      });
    }
  }

  handleOnChangeStatement = event => {
    const langCode = this.props.lang;
    const text = event.target.value;
    const name = event.target.name;
    const OK = validatePromptTextLanguage(event.target.value, "en");
    this.setState(state => {
      const langStatements = state.langStatements;
      return {
        langStatements: {
          ...langStatements,
          [langCode]: {
            ...langStatements[langCode],
            [name]: text,
          },
        },
        error: !OK,
      };
    });
  };

  updateNew = prompt => {
    const event = { target: { name: "", value: "" } };
    const langCode = this.props.lang;
    const len = this.props.StatementValues[prompt]
      ? this.props.StatementValues[prompt].length
      : 0;
    event.target.name = this.props.promptName + "." + prompt;
    event.target.value = this.state.langStatements[langCode][prompt].trim();

    this.setState({
      langStatements: emptyStateUtterances(),
      error: true,
    });

    this.props.onChangeText(event, len, true);
  };

  handleOnClickNew = name => e => {
    this.updateNew(name);
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleOnKeyPress = (event, name) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (name !== "" && !this.state.error) {
        this.updateNew(name);
      }
    }
  };

  render() {
    const {
      classes,
      onChangeText,
      StatementValues,
      lang,
      handleDelete,
      promptName,
      errorUtterance,
      errorTitle,
      handleBlurSave,
      HELPTEXT,
    } = this.props;
    const { error, langStatements } = this.state;
    const utterances = StatementValues.utterances
      ? StatementValues.utterances
      : [];
    const utterance = langStatements[lang].utterances;
    const langText = Langs.filter(item => item.code === lang)[0].text;

    return (
      <div className={classes.root}>
        <Accordion expanded={true} defaultExpanded>
          <AccordionSummary>
            <Typography className={classes.secondaryHeading}>
              {"Help sentences"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.listWidth}>
              <List className={`${testClassNames.INTENT_HELP_LIST}`}>
                <ListItem className={classes.expansionInputList}>
                  <TextField
                    style={{ marginRight: 35 }}
                    id="utterances-prompt"
                    name="utterances"
                    value={utterance}
                    onChange={this.handleOnChangeStatement}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyPress={e => {
                      this.handleOnKeyPress(e, "utterances");
                    }}
                    placeholder={
                      errorTitle
                        ? "Enter a help description to enable this"
                        : langText
                    }
                    helperText={HELPTEXT.EXAMPLE_EN}
                    type="text"
                    fullWidth
                    error={errorUtterance}
                    disabled={errorTitle}
                  />
                  <ListItemSecondaryAction style={{ marginLeft: 5 }}>
                    <Fab
                      aria-label="Add"
                      color="primary"
                      style={{ height: 40, width: 40 }}
                      className={`${testClassNames.INTENT_HELP_ADD_BUTTON}`}
                      onClick={this.handleOnClickNew("utterances")}
                      disabled={error}
                    >
                      <Add />
                    </Fab>
                  </ListItemSecondaryAction>
                </ListItem>
                {utterances.map((item, key) => (
                  <ListItem key={key} style={{ width: "100%" }}>
                    <TextField
                      style={{ marginRight: 35 }}
                      name={promptName + ".utterances"}
                      value={item.value}
                      onChange={e => {
                        onChangeText(e, key);
                      }}
                      onBlur={handleBlurSave}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      fullWidth
                      error={item.value === ""}
                      required
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Delete"
                        className={`${testClassNames.INTENT_HELP_DELETE_BUTTON}`}
                        onClick={handleDelete.bind(
                          this,
                          "utterances",
                          promptName,
                          key
                        )}
                      >
                        <Remove />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

ExpansionHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  StatementValues: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpansionHelp);
