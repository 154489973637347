import { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import userJourneyReducer from "../../state/userJourneyReducer";
import { LinearProgress } from "@material-ui/core";
import DeleteConfirm from "../DeleteConfirm";
import UserJourneyList from "./UserJourneyList";

const styles = theme => ({
  iconButton: {
    marginRight: 20,
  },
  marginText: {
    marginRight: 25,
    display: "inline",
  },
});

function UserJourneyItems(props) {
  const {
    classes,
    userJourneys,
    subDomains,
    handleEditAppData,
    handleSelectError,
    selectError,
    changeSubDomainData,
  } = props;

  const [open, setOpen] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("Are you sure ?");
  const [checked, setChecked] = useState(null);
  const [userJourneysState, dispatch] = useReducer(
    userJourneyReducer,
    userJourneys
  );

  useEffect(() => {
    const newChecked = userJourneysState
      ?.filter(UJ => UJ.enabled)
      .map(UJ => UJ.name);
    setChecked(newChecked);
    return () => {};
  }, []);

  useEffect(() => {
    let didCancel = false;
    if (userJourneysState.length !== userJourneys.length) {
      dispatch({
        type: "reState",
        payload: userJourneys,
      });

      const newChecked = userJourneys
        ?.filter(SD => SD.enabled)
        .map(SD => SD.name);
      setChecked(newChecked);
    } else if (userJourneysState && !didCancel) {
      if (!didCancel) {
        if (
          userJourneysState !== userJourneys &&
          userJourneysState.length === userJourneys.length
        ) {
          dispatch({
            type: "reState",
            payload: userJourneys,
          });
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [userJourneys]);
  useEffect(() => {
    let didCancel = false;
    if (userJourneysState.length !== userJourneys.length) {
      const newChecked = userJourneysState
        ?.filter(SD => SD.enabled)
        .map(SD => SD.name);
      setChecked(newChecked);
      handleEditAppData(userJourneysState, "userJourneys");
    }
    return () => {
      didCancel = true;
    };
  }, [userJourneysState]);

  const handleToggle = name => () => {
    const currentIndex = checked.indexOf(name);
    const newChecked = [...checked];
    let enabled = false;

    if (currentIndex === -1) {
      newChecked.push(name);
      enabled = true;
    } else {
      newChecked.splice(currentIndex, 1);
      enabled = false;
    }
    // off set the global user journey which is always selected
    if (newChecked.length > 1) {
      dispatch({ type: "toggle", payload: { name, enabled } });
      handleEditAppData(userJourneysState, "userJourneys");
      setChecked(newChecked);
    } else if (!selectError) {
      handleSelectError("At least one User Journeys must be selected");
      setTimeout(() => {
        handleSelectError(null);
      }, 5000);
    }
  };
  const changeUserJourneyData = (data, idx) => {
    dispatch({ type: "update", payload: { data, idx } });
    handleEditAppData(userJourneysState, "userJourneys");
  };
  const handleDeleteUserData = payload => () => {
    const { userJourneyID } = payload;
    setDialogTitle(`Delete user journey ${userJourneys[userJourneyID].name} ?`);
    setOpen(true);
    setDeleteInfo({ userJourneyID });
  };
  const handleClose = () => {
    setOpen(false);
    setDeleteInfo(null);
  };
  const handleDeleteID = () => {
    const { userJourneyID } = deleteInfo;
    dispatch({ type: "delete", payload: { userJourneyID } });
    // handleEditAppData(userJourneysState, "userJourneys");
    handleClose();
  };

  const deleteProps = { handleClose, handleDeleteID };
  const userJourneyListProps = {
    handleDeleteUserData,
    changeUserJourneyData,
    handleToggle,
    userJourneysState,
    subDomains,
    changeSubDomainData,
  };

  return (
    <>
      <DeleteConfirm {...deleteProps} state={{ open, dialogTitle }} />
      {checked ? (
        <List>
          {" "}
          <UserJourneyList {...userJourneyListProps} />{" "}
        </List>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

UserJourneyItems.propTypes = {
  classes: PropTypes.object.isRequired,
  userJourneys: PropTypes.array.isRequired,
};

export default withStyles(styles)(UserJourneyItems);
