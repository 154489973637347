import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles.js";
import AssistantConfigEditMain from "./assistantConfigEditMain";
import TopNav from "../../common/topNav";
import SideNav from "../../common/sideNav";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { testClassNames } from "../../../utils/integrationTestClassNames.js";

class AssistantConfigEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleAppNameChange = (newAppName, appVersion) => {
    this.setState({
      isLoading: true,
    });

    const redirect = "/home/assistants/" + newAppName;

    this.props.history.replace({
      pathname: redirect,
      state: { appVersion },
    });

    setTimeout(
      () =>
        this.setState({
          isLoading: false,
        }),
      500
    );
  };

  render() {
    const { classes, match, location } = this.props;
    const appName = match.params.appname;
    const appVersion = location.state.appVersion
      ? location.state.appVersion
      : null;
    return (
      <div className={classes.appFrame}>
        <SideNav currentPage="intent" appname={appName} />
        <main className={classes.content}>
          <TopNav showPublish home />
          <Fade in={true} timeout={{ enter: 1500, exit: 500 }}>
            <div>
              {this.state.isLoading ? (
                <div className={classes.progress}>
                  <CircularProgress
                    color="primary"
                    thickness={1}
                    size={150}
                    className={`${testClassNames.CIRCULAR_PROGRESS_BAR}`}
                  />
                </div>
              ) : (
                <AssistantConfigEditMain
                  appName={appName}
                  appVersion={appVersion}
                  handleAppNameChange={this.handleAppNameChange}
                />
              )}
            </div>
          </Fade>
        </main>
      </div>
    );
  }
}

AssistantConfigEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssistantConfigEdit);
