import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CloneApp from "../../../assets/cloneApp.png";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { Link } from "react-router-dom";
import { testClassNames } from "../../../utils/integrationTestClassNames";

import DocHelpLink from "../../common/DocLinks";

const CloneBuddyDialog = props => {
  const { classes, handleClose, handleCloneApp, handleChangeAppName, state } =
    props;

  const { openClone, cloneName, createError, apikey } = state;

  return (
    <Dialog open={openClone} onClose={handleClose} maxWidth="md">
      <div className={classes.dialogCreate}>
        <Typography variant="h6" gutterBottom className={classes.textColor}>
          {"Clone Buddy "} <DocHelpLink docFor="BUDDY" />
        </Typography>
        <DialogContent>
          <img src={CloneApp} alt="Slang Labs" className={classes.logo} />
          {!apikey ? (
            <Typography
              variant="button"
              gutterBottom
              className={classes.errorColor}
            >
              Cannot create buddy :( <br /> <br /> No API key, you can create
              one
              <Link
                to="/user"
                style={{ color: "#FFFFFF", textDecoration: "underline" }}
              >
                here
              </Link>
            </Typography>
          ) : (
            <form onSubmit={handleCloneApp} style={{ width: "100%" }}>
              <FormControl
                color="light"
                className={classes.createInput}
                error={!!createError}
                aria-describedby="create-app-name-text"
              >
                <Input
                  autoFocus
                  id="create-app-name"
                  name="cloneName"
                  className={classes.createInput}
                  color="primary"
                  placeholder="App Name"
                  pattern="[A-Za-z0-9.]+"
                  inputProps={{
                    "aria-label": "App Name",
                  }}
                  type="text"
                  onChange={handleChangeAppName}
                  value={cloneName}
                  required
                />
              </FormControl>
              <br />
              <br />
              <Typography variant="caption" gutterBottom paragraph>
                {"Unique name; e.g. app.camera.com or My Camera app V1"}
              </Typography>

              <br />
              <br />
              <Button
                variant="text"
                classes={{
                  root: classes.eTypeDone,
                  disabled: classes.disabled,
                }}
                className={`${testClassNames.BUDDY_CREATE_BUTTON}`}
                type="submit"
                disabled={!!createError}
              >
                {"Clone Buddy"}
              </Button>
              <br />
              <br />
              <Button
                variant="outlined"
                className={`${classes.cancelButton} ${testClassNames.BUDDY_CANCEL_BUTTON}`}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </form>
          )}
        </DialogContent>
        <DialogActions />
      </div>
    </Dialog>
  );
};

export default CloneBuddyDialog;
