import { Component } from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { validateIntentName } from "../../../utils/validate";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs";
import { testClassNames } from "../../../utils/integrationTestClassNames.js";

let contextTrigger = false;

const SaveButton = props => {
  const { classes, handleSave, ...rest } = props;
  return (
    <IconButton
      className={`${classes.buttonEdit} ${testClassNames.INTENT_NAME_SAVE_BUTTON}`}
      aria-label="Edit"
      onClick={handleSave}
      {...rest}
    >
      <SaveIcon className={classes.iconEdit} />
    </IconButton>
  );
};
const EditButton = props => {
  const { classes, ...rest } = props;
  return (
    <IconButton
      className={`${classes.buttonEdit} ${testClassNames.INTENT_NAME_EDIT_BUTTON}`}
      aria-label="Edit"
      {...rest}
    >
      <EditIcon className={classes.iconEdit} />
    </IconButton>
  );
};
export class IntentNameDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editIntentName: this.props.intentName,
      descriptionIntent: this.props.descriptionIntent,
      editIntentNameClick: false,
      editIntentDescriptionClick: false,
      intentNameError: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.intentName !== this.props.intentName ||
      prevProps.descriptionIntent !== this.props.descriptionIntent
    ) {
      this.setState({
        intentName: this.props.intentName,
        descriptionIntent: this.props.descriptionIntent,
      });
    }
  }

  handleEditIntentNameClick = change => {
    this.setState({
      editIntentNameClick: typeof change !== "function" ? change : false,
    });
  };

  handleEditIntentDescriptionClick = change => {
    this.setState({
      editIntentDescriptionClick: typeof change !== "function" ? change : false,
    });
  };

  handleChangeIntentName = event => {
    // eslint-disable-next-line
    const text = event.target.value.replace(/([^a-zA-Z_]+)/g, "");
    const listOfIntents = this.props.intents.filter(
      item => item.name !== this.props.intentName
    );
    const valid =
      text.length > 0 ? validateIntentName(text, listOfIntents) : true;
    this.setState({
      editIntentName: text,
      intentNameError: !valid,
    });
  };

  handleChangeIntentDescription = event => {
    // eslint-disable-next-line
    const text = event.target.value.replace(/([^a-zA-Z_0-9 ]+)/g, "");
    if (text.length < 141)
      this.setState({
        descriptionIntent: text,
      });
  };

  handleSaveIntentDescription = event => {
    const newDesc = this.state.descriptionIntent
      ? this.state.descriptionIntent.trim()
      : "";
    const GO = newDesc && newDesc !== this.props.descriptionIntent;
    if (GO) {
      this.props.handleSaveIntentDescription(newDesc);
    }
    this.setState({
      descriptionIntent: newDesc,
      editIntentDescriptionClick: false,
    });
  };

  handleSaveIntentName = () => {
    const newname = this.state.editIntentName;
    const GO = !this.state.intentNameError && newname;
    if (GO) {
      logEvent(SlangSeverityLevels.INFO, SlangEvents.INTENT_NAME_UPDATED, {
        // TODO: Find out the previous value of the intent name and update.
        prev_intent_name: this.props.intentName,
        new_intent_name: newname,
      });
      this.props.handleSaveIntentName(newname);
      this.setState({
        editIntentNameClick: false,
      });
    }
  };

  onKeyPress = event => {
    if (event.key === "Enter") {
      if (event.target.name === "editIntentName") {
        this.handleSaveIntentName();
      } else if (event.target.name === "descriptionIntent") {
        this.handleSaveIntentDescription();
      }
    }
  };

  handleClickAway = event => {
    if (!contextTrigger) {
      this.setState({
        editIntentNameClick: false,
      });
    } else {
      contextTrigger = false;
    }
  };

  render() {
    const { classes, intentName } = this.props;
    const {
      handleChangeIntentDescription,
      handleEditIntentDescriptionClick,
      handleEditIntentNameClick,
      handleSaveIntentDescription,
    } = this;
    const {
      editIntentNameClick,
      editIntentDescriptionClick,
      editIntentName,
      intentNameError,
      descriptionIntent,
    } = this.state;

    return (
      <Grid
        item
        container
        alignContent="flex-start"
        alignItems="flex-start"
        justify="flex-start"
        direction="row"
        style={{ textAlign: "left", minHeight: "4em" }}
      >
        <Grid xs={5} item>
          {!editIntentNameClick ? (
            <Typography
              variant="h5"
              title={intentName}
              onClick={handleEditIntentNameClick.bind(this, true)}
              noWrap
            >
              {intentName}
              <EditButton classes={classes} />
            </Typography>
          ) : (
            <ClickAwayListener onClickAway={this.handleClickAway}>
              <Input
                placeholder={intentName}
                className={classes.editIntentName}
                name="editIntentName"
                value={editIntentName}
                inputProps={{
                  "aria-label": "Intent Name",
                }}
                onKeyPress={this.onKeyPress}
                onChange={this.handleChangeIntentName}
                error={intentNameError}
                endAdornment={
                  <InputAdornment position="end">
                    <SaveButton
                      handleSave={this.handleSaveIntentName}
                      classes={classes}
                    />
                  </InputAdornment>
                }
                autoFocus
                fullWidth
              />
            </ClickAwayListener>
          )}
        </Grid>
        <Grid style={{ textAlign: "right" }} xs={7} container item>
          {!editIntentDescriptionClick ? (
            <Grid
              container
              item
              spacing={1}
              justify="center"
              alignItems="center"
            >
              <Grid xs={11} item>
                <Typography
                  variant="body2"
                  style={{ fontSize: "0.9rem" }}
                  onClick={handleEditIntentDescriptionClick.bind(this, true)}
                >
                  {descriptionIntent && descriptionIntent !== "" ? (
                    descriptionIntent
                  ) : (
                    <em>click here to add a description</em>
                  )}
                </Typography>
              </Grid>
              <Grid xs={1} item>
                <EditButton
                  onClick={handleEditIntentDescriptionClick.bind(this, true)}
                  classes={classes}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid xs={12} item>
              <Input
                placeholder="Describe what this intent does ..."
                name="descriptionIntent"
                onChange={handleChangeIntentDescription}
                value={descriptionIntent}
                onBlur={handleSaveIntentDescription}
                onKeyPress={this.onKeyPress}
                endAdornment={
                  <InputAdornment position="end">
                    <SaveButton
                      handleSave={this.handleSaveIntentDescription}
                      classes={classes}
                    />
                  </InputAdornment>
                }
                className={classes.editIntentDescription}
                autoFocus
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

IntentNameDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  intentName: PropTypes.string.isRequired,
  descriptionIntent: PropTypes.string,
};

export default IntentNameDescription;
