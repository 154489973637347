import React from "react";
import "core-js/stable";
import "regenerator-runtime/runtime";
import * as ReactDOM from "react-dom";

import App from "./pages/App";
import configureStore from "./redux/configureStore";

export default function renderToDOM(): void {
  const store = configureStore();
  const root = document.querySelector("#root");

  if (root != null) {
    ReactDOM.render(<App store={store} />, root);
  }
}

renderToDOM();
