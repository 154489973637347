import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SelectIntent from "./SelectIntent";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import { generateIntent } from "../../../../utils/generateIntent";

const styles = makeStyles(theme => ({
  generateButton: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  IntentsDialogTitleContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "0",
    gap: "8px",
    padding: 0,
  },
  CloseIcon: {
    cursor: "pointer",
  },
  textColor: {
    color: theme.palette.primary.dark,
    padding: "0",
    margin: 0,
    textAlign: "left",
    flexGrow: 1,
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    minWidth: 400,
    padding: "20px 40px",
  },
  dialogContentBox: {
    color: theme.palette.primary.dark,
    padding: "0",
    margin: 0,
    flexGrow: 1,
    textAlign: "left",
  },
  singletonsChipContainer: {
    marginBottom: "10px",
    display: "flex",
    gap: "4px",
  },
  singletonChip: {
    padding: "5px",
  },
}));

const GenerateIntentDialog = ({ data, handleGenerateUtterances }) => {
  const [open, setOpen] = useState(false);
  const [blackListedIntents, setBlackListedIntents] = useState([]);
  const [utteranceGenerating, setUtteranceGenerating] = useState(false);

  const intents = data
    .filter(entity => !entity.type.includes("EndResolution"))
    .map(entity => entity.name);

  const singletons = data
    .filter(entity => entity.type.includes("EndResolution"))
    .map(entity => entity.name);

  const handleBlackListedIntentAdd = value => {
    setBlackListedIntents(value);
    value.forEach(element => {
      if (intents.includes(element)) {
        intents.splice(intents.indexOf(element), 1);
      }
    });
  };

  const handleGenerate = () => {
    setUtteranceGenerating(true);

    const utterances = generateIntent(intents, blackListedIntents, singletons);

    handleGenerateUtterances(utterances);
    handleClose();
    setUtteranceGenerating(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = styles();
  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        className={classes.generateButton}
      >
        Generate
      </Button>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <div className={classes.dialogCreate}>
          <DialogContent className={classes.IntentsDialogTitleContainer}>
            <Typography variant="h5" gutterBottom className={classes.textColor}>
              {"Generate Utterance"}
            </Typography>
            <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
          </DialogContent>
          <DialogContent className={classes.dialogContentBox}>
            {console.log(singletons)}
            {singletons.length !== 0 && (
              <DialogContentText className={classes.textColor}>
                {" "}
                Singletons Entities
              </DialogContentText>
            )}
            {singletons.length !== 0 &&
              singletons.map((item, id) => (
                <div className={classes.singletonsChipContainer}>
                  <Chip
                    className={classes.singletonChip}
                    key={id}
                    label={`${item}`}
                  />
                </div>
              ))}
          </DialogContent>
          <DialogContent className={classes.dialogContentBox}>
            <DialogContentText className={classes.textColor}>
              {" "}
              BlackListed Entities
            </DialogContentText>
            <SelectIntent
              {...{
                intentNames: intents,
                selectedIntents: blackListedIntents,
                setSelectedIntents: handleBlackListedIntentAdd,
              }}
            />
          </DialogContent>
          <DialogContent>
            <Button
              variant="contained"
              onClick={handleGenerate}
              className={classes.generateButton}
            >
              Generate
            </Button>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default GenerateIntentDialog;
