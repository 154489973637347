// Config for tooltips everywhere

export const INTENTS =
  "App actions that you would like to support through voice";
export const NEW_INTENT =
  "Add an new intent to begin your life's journey with Slang";
export const ENTITY_TYPE =
  "Custom entity types defined for this buddy, built-in entity types are not listed here";
export const POWER_MODE =
  "Power Mode allows you to directly edit the JSON schema";

export const SCHEMA = "The data structure of the buddy";
export const STRING_TABLE = "Localised strings to map to the buddy";
export const UTTERANCE =
  "The various ways an end user may speak out this intent";
export const ENTITY =
  "Different pieces of data required to achieve this intent";
export const ENTITY_LIST =
  "Allows the end user to speak out a list of multiple values for the entity one after the other e.g. 'Add potatoes, tomatoes and onions to the basket'.";
export const ENTITY_NAME =
  "Unique name, 3+ characters only letters & underscore";
export const ENTITY_SELECT_TYPE = "Select an entity type";

export const ENTITY_TYPE_NAME = "3+ charaters, letters and . only";
export const ENTITY_TYPE_DESCRIPTION = "A suitable description";
export const ENTITY_TYPE_VALUE = "Unique value*";
export const ENTITY_TYPE_SYNONYM = "Synonyms to value";
export const ENTITY_TYPE_EXPAND =
  "When checked, some related values that are not explicitly included in this list  would be recognised during intent resolution.";

export const APP_HOME_RIGHT =
  "<p>A buddy is a collection of all intents that correspond to the different activities or tasks that the end user can perform in the app. " +
  "<strong>For example:</strong> a travel app may consists of one or more intents related to the app features such as search destinations, book a holiday, check flight status and so on.</p>";
export const INTENT_HOME_RIGHT =
  "<p>The information you provide here helps Slang recognize, interpret, and process information when the end user talks to your app. </p>" +
  "<p><strong>Utterance</strong> indicates all the different voice requests an end user can use for this intent. For example, to check flight status the user can say one of the following: 'Is my flight on time', 'What is my flight status', 'Show status', 'Get flight status' and so on.</p>" +
  "<p><strong>Entities</strong> are additional information needed to compelete the task or activity associated with this intent. For example, to check flight status we would need more information such as the flight number & date.</p>" +
  "<p> Entities need to be highlighted and marked in the utterances & all mandatory entities need at least one prompt that can be used by Slang to request the end user to provide the missing information.</p>";

export const SAVE =
  "Changes (create or update) to intents, utterances, entities, and entity types are pushed to the server in the form of a json schema";
export const PUBLISH =
  "Choose to train this buddy for 'Staging' or 'Production' environment";
export const PUBLISH_STAGING =
  "This will push the changes made to this app to the Slang backend and use it for train the models that Slang builds/maintains for your app. The changes will be visible to clients which use the 'staging' environment";
export const PROMOTE_TO_PROD =
  "This will push the changes made to this app to the Slang backend and use it for train the models that Slang builds/maintains for your app. The changes will be visible to clients which use the 'prod' environment";
export const APP_PROMPTS =
  "Different voice responses to be spoken out to the user by slang";
export const INTENT_PROMPTS =
  "Different voice responses to be spoken out to the user by slang specific to this intent.";
export const UPGRADE = "Upgrade your assistant to the latest version.";

export const GREETING_STATEMENT = {
  HELP: "Welcome statements to greet a user on clicking the mic icon",
  PLACEHOLDER_EN: "Add a new greeting",
  PLACEHOLDER_HI: "एक नया ग्रीटिंग लिखें",
  EXAMPLE_EN: "e.g. Hello there, how may I help you",
  EXAMPLE_HI: "e.g. हैलो, मैं आपकी मदद कैसे कर सकता हूं",
};

export const CLARIFICATION_PROMPT = {
  HELP: "Voice responses when slang is unable to hear or understand what the user is saying",
  HELP_HI:
    "बोली जाने वाली प्रतिक्रिया जब स्लैंग सुनने में असमर्थ है या यह समझने में असमर्थ है कि उपयोगकर्ता क्या कह रहा है",
  PLACEHOLDER_EN: "Add a new question or statement",
  PLACEHOLDER_HI: "एक नया प्रश्न या वाक्य लिखें",
  EXAMPLE_EN:
    "e.g. Sorry, I didn't understand that, could you please say it again?",
  EXAMPLE_HI:
    "e.g. क्षमा करें, मुझे समझ में नहीं आया, क्या आप इसे फिर से कह सकते हैं?",
};

export const COMPLETION_STATEMENT = {
  HELP: "Voice confirmation from slang on successful intent completion.",
  PLACEHOLDER_EN: "Add a new voice confirmation",
  PLACEHOLDER_HI: "एक नया स्पष्टीकरण लिखें",
  EXAMPLE_EN: "e.g. Congratulations, your transaction is successful",
  EXAMPLE_HI: "e.g. बधाई हो, आपका लेनदेन सफल है",
};

export const MISSING_ENTITY_PROMPT = {
  HELP: "Voice question to collect missing information for this intent",
  PLACEHOLDER_EN: "What Slang should say if this entity is missing",
  PLACEHOLDER_HI: "इस एंटिटी में प्रांप्ट जोड़ें",
  EXAMPLE_EN: "e.g. Please select the payment mode",
  EXAMPLE_HI: "e.g. कृपया भुगतान करने का तरीक़ा चुन्ने",
};

export const INTENT_HELP = {
  HELP_TEXT:
    "Title & example utterances to be displayed when the user needs assistance related to this intent",
  HELP_TITLE_EN: "Add help title *",
  HELP_TITLE_HI: "मदद शीर्षक लिखें *",
  TTILE_EXAMPLE_EN: "e.g. View Statement, Track Order (required)",
  TTILE_EXAMPLE_HI: "e.g. खाता विवरण देखें, आर्डर ढूंढें",
  PLACEHOLDER_EN: "Add examples to display in user help *",
  PLACEHOLDER_HI: "मदद में दिखाने के लिए उद्धरण लिखें *",
  EXAMPLE_EN:
    "e.g. Show my statement for last 3 months; When is my jeans getting delivered? (required)",
  EXAMPLE_HI:
    "e.g. पिछले 3 महीने का विवरण दिखाओ; मेरी जींस कब डिलीवर हो रही है",
};

export const DOC_LINKS = {
  BUDDY: {
    text: "A buddy captures the specification of the conversational model for your application",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies",
  },
  INTENT: {
    text: "App actions that you would like to support through voice",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies/intents",
  },
  UTTERANCE: {
    text: "The various ways an end user may speak out this intent",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies/intents#examples-of-intents",
  },
  ENTITY: {
    text: "Different pieces of data required to achieve this intent",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies/entities-and-entity-types",
  },
  ENTITY_TYPE: {
    text: "Custom entity types defined for this buddy, built-in entity types are not listed here",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies/entities-and-entity-types",
  },
  APP_PROMPTS: {
    text: "Different voice responses to be spoken out to the user by slang",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies/prompts",
  },
  INTENT_PROMPTS: {
    text: "Different voice responses to be spoken out to the user by slang specific to this intent.",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies/prompts",
  },
  INTENT_HELP_TEXT: {
    text: "Title & example utterances to be displayed when the user needs assistance related to this intent",
    link: "https://docs.slanglabs.in/slang/digging-deeper/building-buddies/prompts",
  },
  ASR_HINTS: {
    text: "ASR Hints bias the ASR model to detect the said words below in their respected languages.",
    link: "https://docs.slanglabs.in/slang/digging-deeper/web-deep-dive#improve-speech-recognition",
  },
  APP_STATES: {
    text:
      "An app state is a point in the user journey path that represents a specific form and" +
      " context the app is in or wants to be in, based on the data it received from the Assistant " +
      " or directly from the end-user via the screen",
    link: "https://docs.slanglabs.in/slang/user-journey-and-app-states#app-states",
  },
  SUB_DOMAINS: {
    text: "Sub-domains are variants inside the currently selected domain. They clarify which sub-categories, the Assistant has been trained for",
    link: "https://docs.slanglabs.in/slang/user-journey-and-app-states#sub-domains",
  },
  USER_JOURNEYS: {
    text: "User journeys represent various journeys or functionalities that are supported by the Assistant",
    link: "https://docs.slanglabs.in/slang/user-journey-and-app-states#user-journey",
  },
  CREATE_ASSISTANT: {
    text: "Add a new assistant by selecting the assistant type, user journeys, app states and publish.",
    link: "https://docs.slanglabs.in/slang/getting-started/integrating-slang-retail-assistant/setting-up-the-assistant#adding-a-new-assistant",
  },
  APP_SETTINGS: {
    text: "Customize the Assistant. Change the UI Hints and prompts spoken by the Assistant",
    link: "https://docs.slanglabs.in/slang/getting-started/advanced-topics/customizing-the-assistant",
  },
};
