import * as types from "../types";

export const getTemplatesSubdomainsJourneys = (payload = {}) => ({
  type: types.TEMPLATES_SUBDOMAINS_JOURNEYS,
  payload,
  meta: {
    type: "ASST_TEMPLATES",
  },
});

export const resetError = () => ({
  type: types.RESET_ERROR,
});
