import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/core/styles";
import { main } from "../styles.js";

import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import Tooltip from "@material-ui/core/Tooltip";
import * as SlangTooltips from "../../common/SlangTooltips";

import Notifications from "../../common/Notifications";
import ExpansionStatements from "./ExpansionStatements";

import {
  writeIntentPromptState,
  emptyLangIntentState,
  readIntentPromptState,
  editPromptState,
  editPromptSeverity,
  deleteIntentPromptState,
} from "../../../utils/promptUtils";
import { changeAppStringTable } from "../../../redux/actions/appSchema";
import LangButton from "./LangButton";
import DocHelpLink from "../../common/DocLinks";

const mapDispatchToProps = dispatch => ({
  changeAppStringTable: appStrings =>
    dispatch(changeAppStringTable(appStrings)),
});

function PromptTextField(props) {
  const {
    classes,
    lang,
    handleOnChange,
    handleDeletePrompt,
    handleChangeSeverity,
    promptsExample,
    state,
  } = props;
  const { completion } = state;
  const ExpansionStatementsProps = {
    onChangeText: handleOnChange,
    handleDelete: handleDeletePrompt,
    handleChangeSeverity,
    promptsExample,
    StatementValues: completion,
    lang,
    promptName: "completion",
    HELPTEXT: SlangTooltips.COMPLETION_STATEMENT,
  };

  return (
    <form>
      <div className={classes.separate}>
        <Typography variant="body2" noWrap>
          Completion
          <Tooltip
            title={SlangTooltips.COMPLETION_STATEMENT.HELP}
            classes={{ tooltip: classes.tooltip }}
          >
            <Icon className={classes.iconHelp}>help</Icon>
          </Tooltip>
        </Typography>
        <br />
        <ExpansionStatements {...ExpansionStatementsProps} />
      </div>
    </form>
  );
}

PromptTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export class IntentHomePrompts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "en-IN",
      open: false,
      error: null,
      strData: this.props.appStringTable,
      languagePrompts: emptyLangIntentState() || {},
      notify: {
        variant: "info",
        message: "msg",
      },
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handlePromptSave = this.handlePromptSave.bind(this);
  }

  updateState = () => {
    const intentData = this.props.intentData;
    const stringData = this.props.appStringTable;

    this.setState({
      languagePrompts: readIntentPromptState(intentData, stringData),
      stringData,
    });
  };

  componentDidMount() {
    this.updateState();
  }

  handleDeletePrompt = (template, promptType, ID) => {
    const strData = this.state.strData;
    const languagePrompts = this.state.languagePrompts;
    const promptsStrings = deleteIntentPromptState(
      languagePrompts,
      strData,
      template,
      promptType,
      ID
    );

    this.setState({
      languagePrompts: promptsStrings.newLanguagePrompts,
    });
    if (promptsStrings.hasStringID) {
      this.setState({ strData: promptsStrings.newStrData });
    }
    this.handlePromptSave(
      promptsStrings.newLanguagePrompts,
      promptsStrings.newStrData
    );
  };

  handlePromptSave = (
    languagePrompts = this.state.languagePrompts,
    strData = this.state.strData
  ) => {
    const intentData = this.props.intentData;
    const newData = writeIntentPromptState(
      intentData,
      strData,
      languagePrompts
    );
    const newIntentData = newData.appData;

    if (
      newIntentData.completion &&
      (newIntentData.completion.successTemplates.length ||
        newIntentData.completion.failureTemplates.length)
    ) {
      this.props.handleSavePromptData(newIntentData);
    } else {
      delete newIntentData.completion;
      this.props.handleSavePromptData(newIntentData);
    }
    this.props.changeAppStringTable({ appStringTable: newData.strData });
    setTimeout(() => {
      this.updateState();
    }, 300);
  };

  handleOnChange = (event, key = null) => {
    const value = event.target.value;
    const name = event.target.name;
    const severity = event.target.severity;
    const lang = this.state.lang;

    this.setState(state => {
      const languagePrompts = state.languagePrompts;
      const newLanguagePrompts = editPromptState(
        languagePrompts,
        lang,
        name,
        key,
        value,
        severity
      );
      this.handlePromptSave(newLanguagePrompts);
      return { languagePrompts: newLanguagePrompts };
    });
  };

  handleChangeSeverity = (idx, severity) => {
    const lang = this.state.lang;

    this.setState(state => {
      const languagePrompts = state.languagePrompts;
      const newLanguagePrompts = editPromptSeverity(
        languagePrompts,
        lang,
        idx,
        severity
      );
      this.handlePromptSave(newLanguagePrompts);
      return { languagePrompts: newLanguagePrompts };
    });
  };

  handleLangChange = lang => {
    this.setState({
      lang,
    });
  };

  render() {
    const { classes } = this.props;
    const { error, notify, languagePrompts, lang } = this.state;

    const {
      handleOnChange,
      handleDeletePrompt,
      handleLangChange,
      handleChangeSeverity,
    } = this;

    const promptsExample = languagePrompts["en-IN"];
    const prompts = languagePrompts[lang];
    const promptTextFieldProps = {
      handleOnChange,
      handleDeletePrompt,
      handleChangeSeverity,
      promptsExample,
      error,
      lang,
      classes,
    };
    const promptTextFieldState = {
      ...prompts,
    };
    const langButtonProps = {
      lang,
      handleLangChange,
      classes,
    };

    return (
      <div>
        <Paper className={classes.promptPaper} elevation={0}>
          <Typography variant="h5" noWrap>
            {"Intent Prompts "} <DocHelpLink docFor="INTENT_PROMPTS" />
          </Typography>
          <br />
          <br />
          <LangButton {...langButtonProps} />
          <PromptTextField
            {...promptTextFieldProps}
            state={promptTextFieldState}
          />
        </Paper>
        <Notifications notify={notify} />
      </div>
    );
  }
}

IntentHomePrompts.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    appStringTable: state.appSchema.appStringTable,
    appList: state.appSchema.list,
    error: state.appSchema.error,
    appID: state.appSchema.appID,
    schemaDefinition: state.appSchema.schemaDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(main)(IntentHomePrompts));
