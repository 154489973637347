import axios from "axios";
import AppConfig from "../../app.config";

import { HEADERS } from "../slang/assistant_routes";
import { GetSetAccessTokenRaw } from "../../utils/auth/handleAuthState";

const getHeaders = () => {
  const orgID = localStorage.getItem("U_orgID");
  const newHeaders = {
    ...HEADERS,
    identity: orgID,
    Authorization: "Bearer " + GetSetAccessTokenRaw(),
  };
  return newHeaders;
};

const API = axios.create({
  baseURL: AppConfig.SLANG_HOST,
  headers: getHeaders(),
});

export const getPostPutDelete = async ({
  method,
  path,
  postPutData = null,
}) => {
  let response;
  try {
    switch (method) {
      case "GET":
        response = await API.get(path, { headers: getHeaders() });
        return response.data;

      case "POST":
        response = await API.post(path, postPutData, { headers: getHeaders() });
        return response.data;

      case "TRAIN":
        response = await API.post(path, null, { headers: getHeaders() });
        return response.data;

      case "PUT":
        response = await API.put(path, postPutData, { headers: getHeaders() });
        return response.data;

      case "DELETE":
        response = await API.delete(path, { headers: getHeaders() });
        return response.data;

      default:
        break;
    }
  } catch (error) {
    throw error;
  }
};
