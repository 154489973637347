require("es6-promise").polyfill();
require("isomorphic-fetch");

const btoa = require("btoa");
const SlangConfig = require("./config");

function _get_uri(action, host, id = "") {
  // Construct a valid URL depending on GET or POST or DELETE method
  // TODO: remove this logic once API_KEY_ROUTE also returns the secret
  switch (action) {
    case "get":
      return host + SlangConfig.API_KEY_AND_SECRET_ROUTE + id;
    default:
      return host + SlangConfig.API_KEY_ROUTE + id;
  }
}

function _validate_response(json) {
  if ("code" in json && (json.code < 200 || json.code > 300)) {
    let errorMsg = json.code + ": " + json.message;
    if (json.trace) errorMsg = errorMsg + "\n" + json.trace;
    return {
      success: false,
      message: errorMsg,
      raw_json_response: json,
    };
  } else {
    return { success: true, raw_json_response: json };
  }
}

function _slang_headers(auth_key) {
  const base64_auth = btoa(auth_key + ":TODO");
  return Object.assign(SlangConfig.HEADERS, {
    Authorization: "Basic " + base64_auth,
  });
}

const getSlangApiKey = function (host, identity) {
  return new Promise((resolve, reject) => {
    if (!identity) reject("Not Authorized");

    fetch(_get_uri("get", host, identity), {
      method: "GET",
      headers: _slang_headers(identity),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get API Key:  " + err.message);
      });
  });
};

const createSlangApiKey = function (host, identity) {
  return new Promise((resolve, reject) => {
    if (!identity) reject("Not Authorized");

    fetch(_get_uri("create", host), {
      method: "POST",
      headers: _slang_headers(identity),
      body: JSON.stringify({ identity }),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to create API Key: " + err.message);
      });
  });
};

const deleteSlangApiKey = function (host, identity) {
  return new Promise((resolve, reject) => {
    if (!identity) reject("Not Authorized");

    fetch(_get_uri("delete", host), {
      method: "DELETE",
      headers: _slang_headers(identity),
    })
      .then(response => {
        if (response.status === 204)
          resolve(
            _validate_response({
              code: response.status,
              raw_json_response: { code: response.status },
            })
          );
        else {
          response
            .json()
            .then(json => {
              resolve(_validate_response(json));
            })
            .catch(err => {
              reject("Failed to delete API Key: " + err.message);
            });
        }
      })
      .catch(err => {
        reject("Failed to delete API Key: " + err.message);
      });
  });
};

module.exports = {
  getSlangApiKey,
  createSlangApiKey,
  deleteSlangApiKey,
};
