import { useState, useEffect, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { LinearProgress } from "@material-ui/core";
import { reducer } from "../state/reducers";
import DeleteConfirm from "../../components/DeleteConfirm";
import UploadDialog from "../../components/SubDomains/UploadDialog";
import ViewSubDomainDialogWithResource from "../../components/SubDomains/ViewSubDomainDialogWithResource";
import TutorialWrapper from "../../../common/TutorialWrapper";
import { useSelector } from "react-redux";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";

const styles = theme => ({
  iconButton: {
    marginRight: 10,
  },
  marginText: {
    marginRight: 20,
    display: "inline",
    // flex: "none"
  },
  expandDetails: {
    display: "initial",
    padding: 0,
  },
  summary: {
    margin: "12px 0",
    "&$expanded": {
      margin: "10px 0",
    },
  },
  content: {
    margin: "12px 0",
    "&$expanded": {
      margin: "10px 0",
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    display: "inline-flex",
    alignItems: "center",
    marginLeft: 20,
    cursor: "initial",
  },
  labelText: { minWidth: "150px", textTransform: "capitalize" },
  expanded: {},
  subdomainDataHeading: {
    textTransform: "capitalize",
    display: "inline-flex",
    minWidth: "155px",
  },
});

function SubDomainItems(props) {
  const {
    classes,
    subdomains,
    handleEditAppData,
    handleSelectError,
    selectError,
  } = props;

  const [checked, setChecked] = useState(null);
  const [subdomainsState, dispatch] = useReducer(reducer, subdomains);
  const [expand, setExpand] = useState(null);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("Are you sure ?");
  const [open, setOpen] = useState(false);

  const uploadBtnRef = useRef(null);

  const waitTime = 3000;
  const dispatchSubdomainChange = dispatch;
  useEffect(() => {
    let didCancel = false;
    if (subdomainsState && !didCancel) {
      const newChecked = subdomainsState
        ?.filter(SD => SD.enabled)
        .map(SD => SD.name);
      if (!didCancel) {
        setChecked(newChecked);
        if (subdomainsState !== subdomains) {
          handleEditAppData(subdomainsState, "subDomains");
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [subdomainsState, handleEditAppData, subdomains]);

  const handleToggleReplace =
    ({ subDomainID, userDataID, toggle }) =>
    () => {
      dispatch({
        type: "toggleReplaceUserData",
        payload: { subDomainID, userDataID, toggle },
      });
      logEvent(
        SlangSeverityLevels.INFO,
        SlangEvents.SUBDOMAIN_USER_DATA_REPLACE,
        {
          subdomain_name: subdomainsState[subDomainID].name,
          subdomain_data_name:
            subdomainsState[subDomainID].data[userDataID].name,
          is_user_data_replaced: toggle,
        }
      );
    };

  const handleToggle = name => () => {
    const currentIndex = checked.indexOf(name);
    const newChecked = [...checked];
    let enabled = false;

    if (currentIndex === -1) {
      newChecked.push(name);
      enabled = true;
    } else {
      newChecked.splice(currentIndex, 1);
      enabled = false;
    }

    if (newChecked.length) {
      dispatch({ type: "toggle", payload: { name, enabled } });
      logEvent(SlangSeverityLevels.INFO, SlangEvents.SUBDOMAIN_ENABLED, {
        subdomain_name: name,
        subdomain_enabled: enabled,
      });
      setChecked(newChecked);
    } else if (!selectError) {
      handleSelectError({
        message: "At least one Sub Domain must be selected",
        type: "error",
      });
      setTimeout(() => {
        handleSelectError(null);
      }, waitTime);
    }
  };

  const handleDeleteUserData = payload => () => {
    const { subDomainID, userDataID, name } = payload;
    setDialogTitle(`Delete custom ${name} ?`);
    setOpen(true);
    setDeleteInfo({ subDomainID, userDataID, name });
  };

  const handleExpand = panel => (event, expanded) => {
    const newExpand = expand ? panel : false;
    setExpand(newExpand);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteInfo(null);
  };

  const handleDeleteID = () => {
    const { subDomainID, userDataID } = deleteInfo;
    dispatch({
      type: "userDataDelete",
      payload: {
        subDomainID,
        userDataID,
      },
    });
    handleSelectError({ message: "Deleted custom data", type: "initial" });
    setTimeout(() => {
      handleSelectError(null);
    }, waitTime);
    setDeleteInfo(null);
    setDialogTitle("Are you sure ?");
    setOpen(false);
    logEvent(SlangSeverityLevels.INFO, SlangEvents.SUBDOMAIN_USER_DATA_DELETE, {
      subdomain_name: subdomainsState[subDomainID].name,
      subdomain_data_name: subdomainsState[subDomainID].data[userDataID].name,
    });
  };

  const DeleteProps = { handleClose, handleDeleteID };

  const firstEnabledSubdomain = () => {
    return subdomainsState.find(item => item.enabled);
  };
  return (
    <>
      <DeleteConfirm {...DeleteProps} state={{ open, dialogTitle }} />
      {checked ? (
        subdomainsState?.map((subDomain, subDomainID) => (
          <Accordion
            key={subDomain.name}
            expanded={subDomain.enabled}
            onChange={handleExpand(subDomain.name)}
          >
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
              }}
              expandIcon={<span></span>}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={subDomain.enabled}
                    onClick={handleToggle(subDomain.name)}
                  />
                }
                className={classes.labelText}
                label={subDomain.displayName.replace(/_/g, " ")}
              />
              <Typography className={classes.secondaryHeading}>
                {subDomain.description}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expandDetails}>
              <List>
                {subDomain.data?.map((item, dataID) => {
                  const dataName = item.name;
                  const userDataID = subDomain.userData
                    ?.map((UD, key) => {
                      if (UD.name === dataName) {
                        return key;
                      }
                      return null;
                    })
                    .find(itm => itm !== null);
                  const isUserData = userDataID !== undefined;
                  const replaceToggle =
                    isUserData && subDomain.userData[userDataID].value.replace;
                  const name = item.displayName.replace(/_/g, " ");
                  const subDomainName = subDomain.name.replace(/_/g, " ");
                  return (
                    <ListItem key={item.name} className={classes.listItem}>
                      <ListItemText>
                        <Typography
                          variant="body1"
                          className={classes.subdomainDataHeading}
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.secondaryHeading}
                        >
                          {item.description}
                        </Typography>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={replaceToggle || false}
                              disabled={!isUserData}
                              onClick={handleToggleReplace({
                                subDomainID,
                                userDataID,
                                toggle: isUserData ? !replaceToggle : false,
                              })}
                            />
                          }
                          className={classes.labelText}
                          label={"Replace"}
                        />
                        <TutorialWrapper
                          step={3}
                          anchorEl={
                            subDomain.name === firstEnabledSubdomain().name &&
                            dataID === 0
                              ? uploadBtnRef
                              : null
                          }
                        >
                          <span>
                            <UploadDialog
                              refAnchor={
                                subDomain.name ===
                                  firstEnabledSubdomain().name && dataID === 0
                                  ? uploadBtnRef
                                  : null
                              }
                              {...{
                                assistantType: "config",
                                dataID,
                                subDomainID,
                                displayName: name,
                                subDomainName,
                                dataName,
                                dispatchSubdomainChange,
                                userDataID,
                              }}
                            />
                          </span>
                        </TutorialWrapper>

                        <IconButton
                          aria-label="Upload"
                          title={`Delete your ${name}  for ${subDomainName} subdomain`}
                          onClick={handleDeleteUserData({
                            subDomainID,
                            userDataID,
                            name,
                          })}
                          className={classes.iconButton}
                          disabled={!isUserData}
                        >
                          <DeleteForever />
                        </IconButton>
                        <ViewSubDomainDialogWithResource
                          aria-label="Sub domain data View"
                          className={classes.iconButton}
                          title={`${subDomainName} - ${name}`}
                          dataValues={item.value}
                          assistantType="config"
                          userValues={
                            isUserData
                              ? subDomain.userData[userDataID].value
                              : null
                          }
                          disabled={!subDomain.enabled}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

SubDomainItems.propTypes = {
  classes: PropTypes.object.isRequired,
  subdomains: PropTypes.array.isRequired,
};

export default withStyles(styles)(SubDomainItems);
