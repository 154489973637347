import AppConfig from "../app.config";

const Langs = AppConfig.LANGUAGES;

export const emptyLangsHelpPromptState = () => {
  const emptyHelpPrompts = {
    helpDescription: {},
    utterances: [],
  };

  let emptyLangsHelpPromptState = {};

  Langs.forEach(item => {
    emptyLangsHelpPromptState = {
      ...emptyLangsHelpPromptState,
      [item.code]: emptyHelpPrompts,
    };
  });

  return emptyLangsHelpPromptState;
};

const extractStringHelpPrompt = (intentHelpData, stringTable, lang) => {
  let langHelpPrompt = {};

  if (intentHelpData) {
    langHelpPrompt = {
      helpDescription: {
        ...intentHelpData.helpDescription,
        id: intentHelpData.helpDescription.value,
        value:
          stringTable[intentHelpData.helpDescription.value] &&
          stringTable[intentHelpData.helpDescription.value][lang]
            ? stringTable[intentHelpData.helpDescription.value][lang]
            : "",
      },
      utterances: intentHelpData.utterances?.map(item => ({
        ...item,
        id: item.value,
        value:
          stringTable[item.value] && stringTable[item.value][lang]
            ? stringTable[item.value][lang]
            : "",
      })),
    };
  } else {
    langHelpPrompt = {
      helpDescription: {},
      utterances: [],
    };
  }

  return langHelpPrompt;
};

export const readHelpMenuPrompts = (intentHelpData, appStringTable) => {
  const helpMenuLanguagePromptState = {};

  Langs.forEach(item => {
    helpMenuLanguagePromptState[item.code] = extractStringHelpPrompt(
      intentHelpData,
      appStringTable,
      item.code
    );
  });

  return helpMenuLanguagePromptState;
};

export const editHelpDescriptionState = (helpLangPrompts, lang, key, value) => {
  let newHelpLangPrompts = helpLangPrompts;
  const isNew = !newHelpLangPrompts[lang].helpDescription.id;
  Langs.forEach(item => {
    if (item.code === lang) {
      if (isNew) {
        newHelpLangPrompts = {
          ...newHelpLangPrompts,
          [lang]: {
            ...newHelpLangPrompts[lang],
            helpDescription: {
              ...newHelpLangPrompts[lang].helpDescription,
              value,
            },
          },
        };
      } else {
        const changedPrompt = newHelpLangPrompts[lang].helpDescription;
        changedPrompt.value = value || "";
        newHelpLangPrompts = {
          ...newHelpLangPrompts,
          [lang]: {
            ...newHelpLangPrompts[lang],
            helpDescription: changedPrompt,
          },
        };
      }
    } else {
      if (isNew) {
        newHelpLangPrompts = {
          ...newHelpLangPrompts,
          [item.code]: {
            ...newHelpLangPrompts[item.code],
            helpDescription: {
              ...newHelpLangPrompts[item.code].helpDescription,
              value: "",
            },
          },
        };
      } else {
        newHelpLangPrompts = {
          ...newHelpLangPrompts,
          [item.code]: {
            ...newHelpLangPrompts[item.code],
            helpDescription: {
              ...newHelpLangPrompts[item.code].helpDescription,
              value: newHelpLangPrompts[item.code].helpDescription.value
                ? newHelpLangPrompts[item.code].helpDescription.value
                : "",
            },
          },
        };
      }
    }
  });

  return newHelpLangPrompts;
};

export const editHelpUtteranceState = (
  helpLangPrompts,
  lang,
  key,
  value,
  isNew
) => {
  let newHelpLangPrompts = helpLangPrompts;
  console.log(newHelpLangPrompts);
  Langs.forEach(item => {
    if (item.code === lang) {
      if (isNew) {
        newHelpLangPrompts = {
          ...newHelpLangPrompts,
          [lang]: {
            ...newHelpLangPrompts[lang],
            utterances: [
              ...newHelpLangPrompts[lang].utterances,
              { value: value || "" },
            ],
          },
        };
      } else {
        const changedPrompt = newHelpLangPrompts[lang].utterances;
        changedPrompt[key].value = value || "";
        newHelpLangPrompts = {
          ...newHelpLangPrompts,
          [lang]: {
            ...newHelpLangPrompts[lang],
            utterances: changedPrompt,
          },
        };
      }
    } else {
      if (isNew) {
        newHelpLangPrompts = {
          ...newHelpLangPrompts,
          [item.code]: {
            ...newHelpLangPrompts[item.code],
            utterances: [
              ...newHelpLangPrompts[item.code].utterances,
              { value: "" },
            ],
          },
        };
      }
    }
  });
  console.log(newHelpLangPrompts);
  return newHelpLangPrompts;
};

const setHelpPromptId = (intentHelpData, intentName) => {
  let promptsWithID = intentHelpData;

  promptsWithID = {
    ...promptsWithID,
    helpDescription: {
      ...promptsWithID.helpDescription,
      id: promptsWithID.helpDescription.id?.match(/^\w+_help_description$/g)
        ? promptsWithID.helpDescription.id
        : `${intentName}_help_description`,
    },
  };

  promptsWithID.utterances = promptsWithID.utterances.map((utterance, ID) => {
    const found = utterance.id
      ? utterance.id.match(/^\w+_help_var\d$/g)
      : false;
    if (!found) {
      utterance.id = `${intentName}_help_var${ID}`;
    }
    return utterance;
  });
  return promptsWithID;
};

const updateAppData = (intentID, oldAppData, newHelpLangPrompts) => {
  const newAppData = oldAppData;
  let intentData = newAppData.intents[intentID];
  intentData = {
    ...intentData,
    help: {
      helpDescription: {
        isId: true,
        severity: 1,
        value: newHelpLangPrompts["en-IN"].helpDescription.id,
      },
      utterances: newHelpLangPrompts["en-IN"].utterances.map(item => ({
        isId: true,
        severity: 1,
        value: item.id,
      })),
    },
  };

  newAppData.intents[intentID] = intentData;

  return newAppData;
};

const updateStringTable = (oldStringTable, newHelpLangPrompts) => {
  const newStringTable = oldStringTable;

  Object.keys(newHelpLangPrompts).forEach(lang => {
    const { id, value } = newHelpLangPrompts[lang].helpDescription;
    newStringTable[id] = {
      ...newStringTable[id],
      [lang]: value,
    };

    newHelpLangPrompts[lang].utterances.forEach(item => {
      const { id, value } = item;
      newStringTable[id] = {
        ...newStringTable[id],
        [lang]: value,
      };
    });
  });

  return newStringTable;
};

export const writeHelpPromptState = (
  newHelpLangPrompts,
  appDATA,
  appStringTable,
  intentID
) => {
  const intentData = appDATA.intents[intentID];
  Langs.forEach(item => {
    newHelpLangPrompts[item.code] = setHelpPromptId(
      newHelpLangPrompts[item.code],
      intentData.name
    );
  });

  console.log(newHelpLangPrompts);

  const newAppData = updateAppData(intentID, appDATA, newHelpLangPrompts);

  const newStringTable = updateStringTable(appStringTable, newHelpLangPrompts);

  return { newAppData, newStringTable };
};

export const deleteHelpPromptState = (helpLangPrompts, appStringTable, key) => {
  const newHelpLangPrompts = helpLangPrompts;
  const newStringTable = appStringTable;

  const stringID = helpLangPrompts["en-IN"].utterances[key].id
    ? helpLangPrompts["en-IN"].utterances[key].id
    : null;

  if (key > -1) {
    Langs.forEach(item => {
      helpLangPrompts[item.code].utterances.splice(key, 1);
    });
  }

  if (stringID) {
    delete newStringTable[stringID];
  }

  return { hasStringID: stringID, newHelpLangPrompts, newStringTable };
};
