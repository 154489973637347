class Version {
  constructor(versionString) {
    const versionParts = versionString.split(".");
    this.major = parseInt(versionParts[0], 10);
    this.minor = parseInt(versionParts[1], 10);
    this.patch = parseInt(versionParts[2], 10);
    this.versionString = versionString;
  }

  isGreaterThan(versionB) {
    if (this.major > versionB.major) {
      return true;
    } else if (this.major === versionB.major && this.minor > versionB.minor) {
      return true;
    } else if (
      this.major === versionB.major &&
      this.minor === versionB.minor &&
      this.patch > versionB.patch
    ) {
      return true;
    }
    return false;
  }

  isEqualTo(versionB) {
    if (
      this.major === versionB.major &&
      this.minor === versionB.minor &&
      this.patch === versionB.patch
    ) {
      return true;
    }
    return false;
  }
}

export class EditableVersions {
  // map of major -> Version
  constructor(allVersionStrings) {
    for (const versionString of allVersionStrings) {
      const version = new Version(versionString);
      if (!this[version.major]) {
        this[version.major] = version;
        this.latest = version;
      } else if (this[version.major].minor < version.minor) {
        this[version.major] = version;
        this.latest = version;
      } else if (
        this[version.major].minor === version.minor &&
        this[version.major].patch <= version.patch
      ) {
        this[version.major] = version;
        this.latest = version;
      }
    }
  }

  includes(versionString) {
    const version = new Version(versionString);
    if (this[version.major] && this[version.major].isEqualTo(version)) {
      return true;
    }
    return false;
  }
}

export const getEqualOrGreater = (
  candidateVersionString,
  allVersionStrings
) => {
  const candidateVersion = new Version(candidateVersionString);
  const equalOrGreater = allVersionStrings.filter(versionString => {
    const versionB = new Version(versionString);
    if (
      versionB.isEqualTo(candidateVersion) ||
      versionB.isGreaterThan(candidateVersion)
    ) {
      return true;
    }
    return false;
  });
  return equalOrGreater;
};

export const isUpdatatableToNextMajor = (
  candidateVersionString,
  allVersionStrings
) => {
  const candidateVersion = new Version(candidateVersionString);
  const greaterMajorVersions = allVersionStrings.filter(versionString => {
    const versionB = new Version(versionString);
    if (versionB.major > candidateVersion.major) {
      return true;
    }
    return false;
  });
  if (greaterMajorVersions.length > 0) {
    return false;
  }
  return true;
};

export const getAllVersions = (allVersionStrings, shouldSort) => {
  const allVersions = allVersionStrings.map(versionString => {
    return new Version(versionString);
  });
  if (shouldSort) {
    const allVersionsSorted = allVersions.sort((versionA, versionB) => {
      if (versionA.isGreaterThan(versionB)) {
        return 1;
      } else if (versionA.isEqualTo(versionB)) {
        return 0;
      }
      return -1;
    });
    return allVersionsSorted;
  }
  return allVersions;
};
