import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOnboardingStatus } from "../../redux/actions/user";
import CoachmarkTooltip from "./CoachmarkTooltip";
import { TutorialSteps } from "../../utils/MiscUtils";

const TutorialWrapper = props => {
  const { step, anchorEl, children, ...others } = props;

  const dispatchRedux = useDispatch();

  const { onboardingComplete, onboardingStep, appList } = useSelector(
    state => ({
      onboardingComplete: state.user.onboardingComplete,
      onboardingStep: state.user.onboardingStep,
      appList: state.appSchema.list,
    })
  );

  useEffect(() => {
    if (!onboardingComplete) {
      localStorage.setItem("SLANG_ONBOARDING_COMPLETE", "false");
    }
    if (!onboardingStep) {
      localStorage.setItem("SLANG_ONBOARDING_STEP", "0");
    }
    if (!onboardingComplete && !onboardingStep) {
      dispatchRedux(
        updateOnboardingStatus({
          onboardingComplete: false,
          onboardingStep: 0,
        })
      );
      if (step >= 0 && step < 2) {
        if (appList.length > 1) {
          dispatchRedux(
            updateOnboardingStatus({
              onboardingComplete: false,
              onboardingStep: 2,
            })
          );
          localStorage.setItem("SLANG_ONBOARDING_COMPLETE", "false");
          localStorage.setItem("SLANG_ONBOARDING_STEP", "2");
        }
      }
    }
  }, []);

  const [currentStep, setCurrentStep] = useState(onboardingStep);

  const handleProgress = () => {
    if (!onboardingComplete) {
      const nextStep = onboardingStep + 1;
      setCurrentStep(nextStep);
      dispatchRedux(
        updateOnboardingStatus({
          onboardingComplete: nextStep === TutorialSteps.length,
          onboardingStep: nextStep,
        })
      );
      localStorage.setItem("SLANG_ONBOARDING_STEP", nextStep);
      localStorage.setItem(
        "SLANG_ONBOARDING_COMPLETE",
        nextStep === TutorialSteps.length
      );
    }
  };

  useEffect(() => {
    if (!onboardingComplete) {
      if (step >= 2) {
        if (anchorEl && anchorEl.current) {
          anchorEl.current.addEventListener("click", event => {
            handleProgress();
          });
        }
      }
    }
  }, [anchorEl]);

  useEffect(() => {
    if (!onboardingComplete) {
      if (step >= 0 && step < 2) {
        if (anchorEl && anchorEl.current) {
          anchorEl.current.addEventListener("click", event => {
            handleProgress();
          });
        }
      }
    }
  }, [anchorEl, children]);

  const getPrimaryAction = () => {
    if (onboardingComplete) {
      return null;
    } else if (currentStep >= 0 && currentStep < 2) {
      return null;
    } else if (currentStep >= 2 && currentStep < TutorialSteps.length - 1) {
      return {
        label: "Got it",
        action: () => {
          handleProgress();
        },
      };
    } else if (currentStep === TutorialSteps.length - 1) {
      return {
        label: "That's all",
        action: () => {
          handleProgress();
        },
      };
    }
  };

  const getSecondaryAction = () => {
    if (onboardingComplete) {
      return null;
    } else if (currentStep >= 0 && currentStep < 2) {
      return null;
    } else if (currentStep >= 2 && currentStep < TutorialSteps.length - 1) {
      return {
        label: "Skip",
        action: () => {
          setCurrentStep(TutorialSteps.length);
          dispatchRedux(
            updateOnboardingStatus({
              onboardingComplete: true,
              onboardingStep: TutorialSteps.length,
            })
          );
          localStorage.setItem("SLANG_ONBOARDING_STEP", TutorialSteps.length);
          localStorage.setItem("SLANG_ONBOARDING_COMPLETE", true);
        },
      };
    } else if (currentStep === TutorialSteps.length - 1) {
      return null;
    }
  };

  useEffect(() => {
    if (!onboardingComplete) {
      setCurrentStep(onboardingStep);
    }
  }, [onboardingStep]);

  const handleComponentClick = () => {
    if (step === 1) {
      handleProgress();
    }
  };

  return !onboardingComplete ? (
    <CoachmarkTooltip
      open={step === currentStep}
      placement={"right"}
      title={TutorialSteps[step]}
      showSteps={currentStep > 1}
      step={step + 1}
      maxSteps={TutorialSteps.length}
      primaryAction={getPrimaryAction()}
      secondaryAction={getSecondaryAction()}
      anchorEl={anchorEl}
      refAction={handleComponentClick}
      {...others}
    >
      {children}
    </CoachmarkTooltip>
  ) : (
    children
  );
};

TutorialWrapper.propTypes = {
  open: PropTypes.bool,
  step: PropTypes.number.isRequired,
  anchorEl: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default TutorialWrapper;
