import { useEffect, useState } from "react";
import TopNav from "../../common/topNav";
import SideNav from "../../common/sideNav";
// import Fade from "@material-ui/core/Fade";
import TemplateHomeMain from "./TemplateHomeMain";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import withTheme from "../../withTheme";
import { styles } from "../styles";
import { useParams } from "react-router-dom";
import { getAllAssistantTemplates } from "../../../libs/slang/asstAPIs";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import { getAllVersions } from "../../../utils/versionUtils";

const Template = props => {
  const {
    classes,
    // breadcrumbs,
    // match
  } = props;
  const [templateDetails, setTemplateDetails] = useState({});
  const { templateID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState({
    variant: "info",
    message: "",
  });

  const refreshTemplateDetails = async () => {
    try {
      setIsLoading(true);
      const data = await getAllAssistantTemplates();
      const singleTemplateDetails = {
        id: templateID,
        name: data.find(template => template.id === templateID).name,
        versions: getAllVersions(
          data
            .filter(template => template.id === templateID)
            .map(template => template.version),
          true
        ),
        changelog: {},
      };

      data
        .filter(template => template.id === templateID)
        .forEach(template => {
          if (template.title && template.description) {
            singleTemplateDetails.changelog[template.version] = {
              title: template.title,
              description: template.description,
            };
          } else {
            singleTemplateDetails.changelog[template.version] = {
              title: null,
              description: null,
            };
          }
        });
      console.log(singleTemplateDetails);
      setTemplateDetails(singleTemplateDetails);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setNotify({
        variant: "error",
        message: `Error while fetching template"  ${error.toString()}`,
      });
    }
  };

  useEffect(() => {
    refreshTemplateDetails();
    // https://stackoverflow.com/a/60327893
  }, []);

  return (
    <div className={classes.appFrame}>
      <SideNav currentPage="template" appname={templateDetails.name} />
      <main className={classes.content}>
        <TopNav notify={notify} />
        {!isLoading ? (
          <TemplateHomeMain
            templateDetails={templateDetails}
            refreshTemplateDetails={refreshTemplateDetails}
            setNotify={setNotify}
          />
        ) : (
          <LinearProgress className={`${testClassNames.PROGRESS_BAR}`} />
        )}
      </main>
    </div>
  );
};

export default withTheme(withStyles(styles)(Template));
