import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector } from "react-redux";

import SelectList from "./SelectList";
import ChipsArray from "./ChipsArray";

import Paper from "@material-ui/core/Paper";
import { Typography, Grid } from "@material-ui/core/";

import {
  updateHintsState,
  deletePhrase,
  getLangName,
  createDataForSchema,
  emptyUIHintsState,
} from "../../../../../utils/MiscUtils";
import { cleanPhrases } from "../../../../../utils/validate";

import LangButton from "../LangButton";
import DocHelpLink from "../../../../common/DocLinks";
import AppConfig from "../../../../../app.config";
import AddDialog from "../../SubDomains/AddDialog";

const Langs = AppConfig.LANGUAGES;

const styles = makeStyles(theme => ({
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    paddingBottom: 0,
    textAlign: "left",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "center",
  },
}));

const UIHints = props => {
  const {
    allHints,
    userJourney,
    userJourneyID,
    selectedAppState,
    changeUserJourneyData,
    subDomains,
    changeSubDomainData,
  } = props;

  const [selectedValue, setSelectedValue] = useState(0);
  const [newAllHints, setNewAllHints] = useState(
    emptyUIHintsState(subDomains) || []
  );
  const [error, setError] = useState(null);
  const [asrHintsChipData, setAsrHintsChipData] = useState([]);
  const [asrHintsAdd, setAsrHintsAdd] = useState("");
  const [lang, setLang] = useState("en-IN");
  const [langName, setLangName] = useState(getLangName("en-IN"));

  const getEnabledSubDomainHints = () => {
    const enabledSubDomain = subDomains.filter(SD => SD.enabled);

    let enabledSubDomainHints = [];

    enabledSubDomainHints = enabledSubDomain.map(SD => {
      const isHint = allHints.find(item => SD.name === item.subdomain);
      if (isHint) {
        return isHint;
      } else {
        const emptyHintsState = emptyUIHintsState(subDomains);
        const emptyHint = emptyHintsState.find(
          item => item.subdomain === SD.name
        );
        return emptyHint;
      }
    });

    return enabledSubDomainHints;
  };

  const saveAppStateData = newAppStatesMetadata => {
    userJourney.appStatesMetadata = newAppStatesMetadata;
    changeUserJourneyData(userJourney, userJourneyID);
  };
  const handleSaveToSchema = (chipData, name) => {
    const newUIHints = createDataForSchema(
      newAllHints,
      selectedValue,
      lang,
      chipData
    );

    const newAppStatesMetadata = userJourney.appStatesMetadata;

    newAppStatesMetadata[selectedAppState].uiHints = newUIHints;

    saveAppStateData(newAppStatesMetadata);
    return true;
  };
  const updateState = (_lang = lang) => {
    const updatedHints = getEnabledSubDomainHints();
    if (updatedHints.length < 1) {
      setError("NO_SUBDOMAIN_FOUND_EXCEPTION");
      return;
    } else {
      setError(null);
    }
    setNewAllHints(updatedHints);
    const _hints = updatedHints[selectedValue].hints;
    const newAsrHintsChipData = updateHintsState(_hints, _lang);
    setAsrHintsChipData(newAsrHintsChipData);
  };

  useEffect(() => {
    updateState();
    return () => {
      // cleanup
    };
  }, [selectedValue, selectedAppState, allHints, subDomains]);

  const handleLangChange = _lang => {
    const _langName = getLangName(_lang);
    setLang(_lang);
    setLangName(_langName);
    updateState(_lang);
  };

  const handleDelete = (key, name) => () => {
    const newChipData = deletePhrase(asrHintsChipData, key);
    handleSaveToSchema(newChipData, name);
  };

  const handleTextChange = event => {
    if (event.target.value.length < 65) {
      setAsrHintsAdd(event.target.value);
    }
  };

  const cleanPhraseData = (name, data) => {
    const textData = data;
    if (textData?.length > 0) {
      const chipData = asrHintsChipData;
      const cleanChipData = cleanPhrases(textData);
      const newChipData = [...new Set([...cleanChipData, ...chipData])];
      return handleSaveToSchema(newChipData, name);
    }
  };

  const handleAddNewSubdomain = (data, key) => {
    changeSubDomainData(data, key);
  };

  const classes = styles();

  const ASRHintsProps = {
    error,
    lang,
    langName,
    handleDelete,
    handleTextChange,
    cleanPhraseData,
    chipType: "asrHints",
  };
  const ASRHintsState = {
    asrHintsAdd,
    asrHintsChipData,
  };

  const langButtonProps = {
    lang,
    setLang: handleLangChange,
    classes,
  };
  return (
    <div>
      <Typography variant="h6" className={classes.chip} noWrap>
        {"UI Hints "} <DocHelpLink docFor="ASR_HINTS" />
      </Typography>
      <br />
      {error === "NO_SUBDOMAIN_FOUND_EXCEPTION" ? (
        <Paper elevation={1} className={classes.expansionPaper}>
          <div className={classes.root}>
            <AddDialog
              {...{
                title: "Add Sub Domain",
                subdomains: subDomains,
                handleEditAppData: handleAddNewSubdomain,
                label: "Add Sub Domain",
                color: "secondary",
                variant: "outlined",
              }}
            />
          </div>
        </Paper>
      ) : (
        <Grid justify="center" spacing={2} container>
          <Grid xs={5} item>
            <SelectList
              {...{
                listItems: getEnabledSubDomainHints(),
                selectedValue,
                setSelectedValue,
              }}
            />
          </Grid>
          <Grid xs={11} item>
            <br />
            <LangButton {...langButtonProps} />
          </Grid>
          <Grid xs={11} item>
            <ChipsArray {...ASRHintsProps} state={ASRHintsState} />{" "}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

UIHints.propTypes = {
  allHints: PropTypes.array.isRequired,
  userJourney: PropTypes.object.isRequired,
  userJourneyID: PropTypes.number.isRequired,
  selectedAppState: PropTypes.number.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
};

export default UIHints;
