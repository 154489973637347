import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import SlangSurface from "./SlangSurface/SlangSurface";
import MobileBackground from "./SlangSurface/assets/MobileBackground.png";
import { KeyboardArrowRight, Check } from "@material-ui/icons";

const defaultColors = ["#4E7F3D", "#f78081", "#4892e2", "#52e3bf", "#ad65ea"];

const ThemeCustomization = props => {
  const {
    classes,
    setSurfaceUIMode,
    surfaceUIMode,
    brandColor,
    setBrandColor,
    surfaceHintStyle,
    setSurfaceHintStyle,
    surfaceStyle,
    setSurfaceStyle,
    handleSaveUIConfig,
    handleChangeValue,
  } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <div className={classes.uiRoot}>
          <div className={classes.ui}>
            <img src={MobileBackground} className={classes.mobile} />
            <div className={classes.uiSurface}>
              <SlangSurface
                {...{
                  surfaceUIMode,
                  brandColor,
                  surfaceHintStyle,
                  surfaceStyle,
                }}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={3}>
        <div className={classes.promptContainer}>
          <Typography variant="h6" className={classes.chip} gutterBottom>
            {"Theme and Colors"}
          </Typography>
          <div className={classes.themeButtonGroup}>
            <Button
              variant={surfaceUIMode === "dark" ? "outlined" : "contained"}
              disableElevation
              style={{ minWidth: 80 }}
              color="default"
              onClick={() => setSurfaceUIMode("dark")}
              onBlur={() => handleSaveUIConfig()}
            >
              Dark
            </Button>

            <Button
              variant={surfaceUIMode === "light" ? "outlined" : "contained"}
              disableElevation
              style={{ marginLeft: "1rem", minWidth: 80 }}
              color="default"
              onClick={() => setSurfaceUIMode("light")}
              onBlur={() => handleSaveUIConfig()}
            >
              Light
            </Button>
          </div>
          <div className={classes.themeButtonGroup}>
            {defaultColors.map(color => (
              <Button
                variant={"contained"}
                disableElevation
                style={{
                  minWidth: 55,
                  minHeight: 30,
                  margin: "0 0.5rem 0 0 ",
                  background: color,
                }}
                onClick={() => setBrandColor(color)}
                onBlur={() => handleSaveUIConfig()}
              >
                {brandColor === color ? (
                  <Check fontSize="small" color="secondary" />
                ) : (
                  ""
                )}
              </Button>
            ))}
          </div>
          <div className={classes.platformControl}>
            <TextField
              name="brandColor"
              value={brandColor}
              onChange={e => setBrandColor(e.target.value)}
              variant="filled"
              label="Brand Color Code"
              size="small"
              className={classes.createInput}
              onBlur={() => handleSaveUIConfig()}
            />
          </div>
          <br />
          <br />
          <Typography variant="h6" className={classes.chip} gutterBottom>
            {"Surface"}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="surface-style-label">Surface Style</InputLabel>
            <Select
              labelId="surface-style-label"
              id="surface-style"
              label="Surface Style"
              value={surfaceStyle}
              onChange={e => {
                setSurfaceStyle(e.target.value);
              }}
              onBlur={() => handleSaveUIConfig()}
            >
              <MenuItem value="gradient">Gradient</MenuItem>
              <MenuItem value="curved">Curved</MenuItem>
              <MenuItem value="card">Card</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="surface-hint-label">Surface Hint Style</InputLabel>
            <Select
              labelId="surface-hint-label"
              id="surface-hint"
              label="Surface Hint Style"
              value={surfaceHintStyle}
              onChange={e => {
                setSurfaceHintStyle(e.target.value);
              }}
              onBlur={() => handleSaveUIConfig()}
            >
              <MenuItem value="static">Static</MenuItem>
              <MenuItem value="list">List</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={() => handleChangeValue(1)}>
            <KeyboardArrowRight />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ThemeCustomization;
