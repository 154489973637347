import { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";

const styles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const ChipsDisplay = memo(props => {
  const { chipType, chipData, handleDelete } = props;

  const classes = styles();

  return chipData.map((data, key) => (
    <Chip
      key={key + data}
      label={data}
      onDelete={handleDelete(key, chipType)}
      className={classes.chip}
    />
  ));
});

ChipsDisplay.propTypes = {
  chipType: PropTypes.string.isRequired,
  chipData: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ChipsDisplay;
