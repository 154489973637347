import axios from "axios";
import qs from "querystring";

import {
  SLANG_ASSISTANT_DOMAINS_BASE,
  SLANG_ASSISTANT_TEMPLATES_BASE,
  SLANG_SCHEMA_BASE,
  SLANG_ASSISTANT_CONFIGS_BASE,
  SLANG_ASSISTANT_CONFIGS_ID_TRAIN_URI,
} from "./assistant_routes";

import { getPostPutDelete } from "../utils/slangAPIs";
import {
  CREATE_PUBLISH_ASSISTANT_CONFIG_URI,
  CREATE_SAVE_ASSISTANT_CONFIG_URI,
  FULL_DOMAIN_LIST_URI,
  FULL_TEMPLATE_LIST_URI,
} from "./console_routes";

export const getNewDomainsList = async () => {
  try {
    return await getPostPutDelete({
      path: `${SLANG_ASSISTANT_DOMAINS_BASE}/new-domains`,
      method: "GET",
    });
  } catch (error) {
    throw error;
  }
};

export const getDomainFullList = async () => {
  const fullDomainList = await getPostPutDelete({
    path: `${FULL_DOMAIN_LIST_URI}`,
    method: "GET",
  });

  return fullDomainList;
};

export const getAssistantDomainData = async ({ domainID }) => {
  try {
    return await getPostPutDelete({
      method: "GET",
      path: `${SLANG_ASSISTANT_DOMAINS_BASE}/${domainID}`,
    });
  } catch (error) {
    throw error;
  }
};

export const createAssistantDomain = async payload => {
  const {
    newDomainCanonicalName,
    newDomainDisplayName,
    newDomainDescription,
    assistantTemplateData,
    assistantTemplateStringTable,
  } = payload;
  try {
    const newDomainData = {
      name: newDomainCanonicalName,
      display_name: newDomainDisplayName,
      description: newDomainDescription,
    };

    const domainData = await getPostPutDelete({
      method: "POST",
      path: `${SLANG_ASSISTANT_DOMAINS_BASE}/`,
      postPutData: newDomainData,
    });

    const newAssistantTemplateName = `${newDomainDisplayName} Template`;
    const newAssistantTemplateVersion = "1.0.0";

    const templateData = await createAssistantTemplate({
      assistantTemplateVersion: newAssistantTemplateVersion,
      assistantTemplateName: newAssistantTemplateName,
      assistantTemplateData: {
        ...assistantTemplateData,
        domain: newDomainCanonicalName,
        name: newAssistantTemplateName,
        version: newAssistantTemplateVersion,
      },
      assistantTemplateStringTable,
    });

    const updatedDomainData = await getPostPutDelete({
      method: "PUT",
      path: `${SLANG_ASSISTANT_DOMAINS_BASE}/${domainData.id}`,
      postPutData: {
        template_id: templateData.id,
        template_version: templateData.version,
      },
    });

    return updatedDomainData;
  } catch (error) {
    throw error;
  }
};

export const updateAssistantDomain = async payload => {
  const { domainID, newTemplateVersion, templateID } = payload;
  try {
    return await getPostPutDelete({
      method: "PUT",
      path: `${SLANG_ASSISTANT_DOMAINS_BASE}/${domainID}`,
      postPutData: {
        template_version: newTemplateVersion,
        template_id: templateID,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const deleteAssistantDomain = async payload => {
  const { domainID } = payload;
  try {
    return await getPostPutDelete({
      method: "DELETE",
      path: `${SLANG_ASSISTANT_DOMAINS_BASE}/${domainID}`,
    });
  } catch (error) {
    throw error;
  }
};

export const getTemplatesFullList = async () => {
  const fullDomainList = await getPostPutDelete({
    path: `${FULL_TEMPLATE_LIST_URI}`,
    method: "GET",
  });
  return fullDomainList;
};

export const createToPublishAsstConfig = async payload => {
  const { name, template_version, template_id, userJourneys, subDomains } =
    payload;

  if (
    !name ||
    !template_version ||
    !template_id ||
    !userJourneys ||
    !subDomains
  ) {
    throw new Error("Missing params createtoPublishAsstConfig ");
  }

  const createPublishAsstConfig = await getPostPutDelete({
    path: `${CREATE_PUBLISH_ASSISTANT_CONFIG_URI}`,
    method: "POST",
    postPutData: {
      name,
      template_version,
      template_id,
      userJourneys,
      subDomains,
    },
  });

  return createPublishAsstConfig.id;
};

export const createSaveAsstConfig = async payload => {
  const { name, template_version, template_id, userJourneys, subDomains } =
    payload;

  if (
    !name ||
    !template_version ||
    !template_id ||
    !userJourneys ||
    !subDomains
  ) {
    throw new Error("Missing params createtoPublishAsstConfig ");
  }

  const createPublishAsstConfig = await getPostPutDelete({
    path: `${CREATE_SAVE_ASSISTANT_CONFIG_URI}`,
    method: "POST",
    postPutData: {
      name,
      template_version,
      template_id,
      userJourneys,
      subDomains,
    },
  });

  return createPublishAsstConfig.id;
};

export const getAllAssistantTemplates = async () => {
  try {
    const data = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}`,
      method: "GET",
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createAssistantTemplate = async payload => {
  const {
    assistantTemplateVersion,
    assistantTemplateName,
    assistantTemplateData,
    assistantTemplateStringTable,
  } = payload;
  try {
    let data = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/?assistant_version=${assistantTemplateVersion}`,
      method: "POST",
      postPutData: { name: assistantTemplateName },
    });

    if (assistantTemplateData) {
      data = { ...data, ...assistantTemplateData, id: data.id };
    }

    if (assistantTemplateStringTable) {
      await getPostPutDelete({
        path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${data.id}/strings/?version=${assistantTemplateVersion}`,
        method: "PUT",
        postPutData: assistantTemplateStringTable,
      });
    }

    // we have to do a put after post during the initial creation of assistant template. This might change in the future
    return await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${data.id}/?version=${assistantTemplateVersion}`,
      method: "PUT",
      postPutData: data,
    });

    // update the string table
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateAssistantTemplate = async payload => {
  const {
    assistantTemplateVersion,
    assistantTemplateID,
    versionUpdateType,
    templateData,
    templateStrings,
    title,
    description,
  } = payload;
  try {
    await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${assistantTemplateID}/strings?version=${assistantTemplateVersion}&version_type=${versionUpdateType}`,
      method: "PUT",
      postPutData: templateStrings,
    });
    await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${assistantTemplateID}/?version=${assistantTemplateVersion}&version_type=${versionUpdateType}`,
      method: "PUT",
      postPutData: {
        title,
        description,
        schema: templateData,
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAssistantTemplate = async payload => {
  const { assistantTemplateVersion, assistantTemplateID, env } = payload;
  try {
    const data = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${assistantTemplateID}/?version=${assistantTemplateVersion}&env=${env}`,
      method: "GET",
    });
    const stringTable = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${assistantTemplateID}/strings/?version=${assistantTemplateVersion}&env=${env}`,
      method: "GET",
    });
    return { data, stringTable };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSchemaDefinition = async () => {
  try {
    const data = await getPostPutDelete({
      path: `${SLANG_SCHEMA_BASE}`,
      method: "GET",
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const publishAsstConfig = async payload => {
  const { asstConfigID, assistantVersion } = payload;
  try {
    const data = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_CONFIGS_ID_TRAIN_URI.replace(
        ":app_id",
        asstConfigID
      ).replace("ENV", "stage")}&assistant_version=${assistantVersion}`,
      method: "POST",
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const upgradeAssistantConfig = async ({
  assistantID,
  assistantVersion,
  templateVersion,
  env,
}) => {
  try {
    const data = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_CONFIGS_BASE}/${assistantID}/update?assistant_version=${assistantVersion}&template_version=${templateVersion}&env=${env}`,
      method: "POST",
      postPutData: {},
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSDKVersions = async payload => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const querystring = qs.stringify(payload);

  try {
    const response = await axios(
      `https://us-central1-slang-client-183202.cloudfunctions.net/slang-template-sdk-version-mapping?${querystring}`
    );
    return response.data;
  } catch (error) {
    // console.error(error)
    return false;
  }
};

export const getPutPostSubdomainData = async payload => {
  const {
    method = "GET",
    ID,
    assistantType,
    is_user_data,
    data_id,
    page_number = 1,
    version,
    env,
    assistant_version,
  } = payload;

  let { data } = payload;
  let assistantTypeBase;
  let queryString = "";

  if (
    !ID ||
    !method ||
    !assistantType ||
    !version ||
    !assistant_version ||
    !env
  ) {
    console.log({ ID, method, assistantType, version, assistant_version, env });
    throw new Error("Missing params putSubdomainData ");
  }

  if (method !== "GET" && !data) {
    throw new Error("Missing params 'data' for function putSubdomainData ");
  }

  if (method === "GET") {
    queryString = `?env=${env}&assistant_version=${assistant_version}&version=${version}&data_id=${data_id}&page_number=${page_number}&is_user_data=${is_user_data}`;
  } else if (method === "PUT") {
    queryString = `?env=${env}&assistant_version=${assistant_version}&version=${version}&data_id=${data_id}&page_number=${page_number}`;
    data = { data };
  } else if (method === "POST") {
    queryString = `?env=${env}&assistant_version=${assistant_version}&version=${version}`;
  } else {
    throw new Error(`Unsupported method: ${method}`);
  }

  if (assistantType === "template") {
    assistantTypeBase = SLANG_ASSISTANT_TEMPLATES_BASE;
  } else if (assistantType === "config") {
    assistantTypeBase = SLANG_ASSISTANT_CONFIGS_BASE;
  } else {
    throw new Error(
      "Missing params 'assistantType' for function putSubdomainData "
    );
  }

  try {
    const response = await getPostPutDelete({
      path: `${assistantTypeBase}/${ID}/subdomain/data${queryString}`,
      method,
      postPutData: data,
    });
    return response;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const getSubdomainMetadata = async payload => {
  const {
    method = "GET",
    ID,
    assistantType,
    is_user_data,
    data_id,
    version,
    env,
    assistant_version,
  } = payload;

  let assistantTypeBase;
  let queryString = "";

  if (
    !ID ||
    !method ||
    !assistantType ||
    !version ||
    !assistant_version ||
    !env
  ) {
    console.log({ ID, method, assistantType, version, assistant_version, env });
    throw new Error("Missing params putSubdomainData ");
  }

  if (method === "GET") {
    queryString = `?env=${env}&assistant_version=${assistant_version}&version=${version}&data_id=${data_id}&is_user_data=${is_user_data}`;
  } else {
    throw new Error(`Unsupported method: ${method}`);
  }

  if (assistantType === "template") {
    assistantTypeBase = SLANG_ASSISTANT_TEMPLATES_BASE;
  } else if (assistantType === "config") {
    assistantTypeBase = SLANG_ASSISTANT_CONFIGS_BASE;
  } else {
    throw new Error(
      "Missing params 'assistantType' for function putSubdomainDataMetadata "
    );
  }

  try {
    const response = await getPostPutDelete({
      path: `${assistantTypeBase}/${ID}/subdomain/metadata${queryString}`,
      method,
    });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
// fetch template lock status
export const getTemplateLockStatus = async payload => {
  const { templateID, templateVersion } = payload;
  try {
    const data = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${templateID}/lock?version=${templateVersion}`,
      method: "GET",
      postPutData: {},
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Update template lock
export const putTemplateLockStatus = async payload => {
  const { templateID, templateVersion, user, release = false } = payload;
  try {
    const data = await getPostPutDelete({
      path: `${SLANG_ASSISTANT_TEMPLATES_BASE}/${templateID}/lock?version=${templateVersion}`,
      method: "PUT",
      postPutData: {
        user,
        release,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
