import React from "react";
import * as PropTypes from "prop-types";
import { Provider } from "react-redux";
import Routes from "./routes";
import ErrorBoundary from "./ErrorBoundary";
import Auth0ProviderWithHistory from "./common/auth/auth0-provider-with-history";
import { BrowserRouter } from "react-router-dom";
import { Store } from "redux";

// enable dark Mode by default
const DarkModeDefault = () => {
  if (!localStorage.getItem("U_DARKMODE")) {
    localStorage.setItem("U_DARKMODE", "true");
  }
};
DarkModeDefault();

const getUserConfirmation = (
  message: string,
  callback: (ok: boolean) => void
) => {
  const allowTransition = window.confirm(message);
  callback(allowTransition);
};

interface AppProps {
  store: Store;
}

const App = (props: AppProps) => {
  const { store } = props;

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter getUserConfirmation={getUserConfirmation}>
          <Auth0ProviderWithHistory>
            <Routes />
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
