import { useState } from "react";
// import PropTypes from "prop-types";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/snippets/json";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-keybinding_menu";
import "ace-builds/src-noconflict/ext-settings_menu";

import "../../app_home/components/Editor_styles.css";
import { withStyles } from "@material-ui/core/styles";
import { main } from "../../app_home/styles";

import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Fade from "@material-ui/core/Fade";

import Tooltip from "@material-ui/core/Tooltip";
import * as SlangTooltips from "../../common/SlangTooltips";
import Notifications from "../../common/Notifications";
import SlangSchemaValidator from "../../../libs/slang/slang_schema_validator";

const NewTemplateEditor = props => {
  const [error, setError] = useState(null);
  const [notify, setNotify] = useState({
    variant: "info",
    message: "msg",
  });

  const searchIDS = (
    needle,
    haystack,
    getKeyValue,
    nested = false,
    found = []
  ) => {
    Object.keys(haystack).forEach(key => {
      if (key === needle) {
        if (haystack[key] || typeof haystack[key] === "object") {
          found.push(haystack[getKeyValue]);
        }

        return found;
      }

      if (nested) {
        if (typeof haystack[key] === "object") {
          searchIDS(needle, haystack[key], getKeyValue, true, found);
        }
      }
    });
    return found;
  };

  const handleValidate = event => {
    const schemaDefinition = props.schemaDefinition;

    let jsonObj, stringObj;
    try {
      // name and id will be implicitly created so while validating we just insert them.
      jsonObj = {
        ...JSON.parse(props.assistantTemplateDataTextWithoutNameOrID),
        name: "justForValidation",
        id: "justForValidation",
      };
      stringObj = JSON.parse(props.assistantTemplateStringDataText);
    } catch (error) {
      console.error("Error in parsing. Text is not valid JSON.");
      setNotify({
        variant: "error",
        message: "Error validating schema",
      });
      setError({ error: "Error validating" });
      return false;
    }
    const allIDS = searchIDS("isId", jsonObj, "value", true);

    let stringErrors = "";
    const validateStrings = allIDS.map((item, key) => {
      const checkId = searchIDS(item, stringObj, item.toString()).length;
      if (checkId === 0) {
        stringErrors += item + " , ";
      }
      return checkId > 0;
    });

    const stringsValid =
      validateStrings.filter(Boolean).length === allIDS.length;

    if (!stringsValid) {
      setNotify({
        variant: "error",
        message: `Missing string Ids in string table: ${stringErrors}`,
      });
      setError({ error: "Error validating strings" });
      return false;
    }
    if (schemaDefinition) {
      const result = SlangSchemaValidator.validate(schemaDefinition, jsonObj);
      console.log(result);

      if (result.success) {
        setNotify({
          variant: "info",
          message: "Your JSON is valid",
        });
        setError(null);
        props.setIsTemplateValid(true);
      } else {
        setNotify({
          variant: "error",
          message: "Error validating schema",
        });
        setError({ error: "Error validating schema" });
      }
    } else {
      console.error("Unable to fetch schema definition from server");
    }
  };
  const { classes } = props;

  return (
    <Fade in={true} timeout={{ enter: 1500, exit: 500 }}>
      <div>
        <Typography variant="h6" color="primary" noWrap>
          POWER MODE ON &nbsp;&nbsp;
          <Tooltip
            title={SlangTooltips.POWER_MODE}
            classes={{ tooltip: classes.tooltip }}
          >
            <Icon className={classes.iconHelp}>help</Icon>
          </Tooltip>
        </Typography>
        <br />
        {props.editable ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                handleValidate();
              }}
              disabled={error !== null}
            >
              Validate changes
            </Button>
            <br />
            <br />
          </>
        ) : null}

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" noWrap>
              App Schema &nbsp;
              <Tooltip
                title={SlangTooltips.SCHEMA}
                classes={{ tooltip: classes.tooltip }}
              >
                <Icon className={classes.iconHelp}>help</Icon>
              </Tooltip>
            </Typography>

            <AceEditor
              id="MonoSpaceEditor1"
              mode="json"
              theme="monokai"
              name="SlangJsonEditor"
              onChange={text => {
                props.setAssistantTemplateDataTextWithoutNameOrID(text);
                props.setIsTemplateValid(false);
                setError(null);
              }}
              readOnly={!props.editable}
              fontSize={14}
              tabSize={2}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={props.assistantTemplateDataTextWithoutNameOrID}
              height="70vh"
              width="100%"
              editorProps={{ $blockScrolling: true }}
              enableBasicAutocompletion={true}
              enableLiveAutocompletion={true}
              enableSnippets={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" noWrap>
              String Table &nbsp;
              <Tooltip
                title={SlangTooltips.STRING_TABLE}
                classes={{ tooltip: classes.tooltip }}
              >
                <Icon className={classes.iconHelp}>help</Icon>
              </Tooltip>
            </Typography>

            <AceEditor
              id="MonoSpaceEditor2"
              mode="json"
              theme="terminal"
              name="SlangStringTableEditor"
              onChange={text => {
                props.setAssistantTemplateStringDataText(text);
                props.setIsTemplateValid(false);
                setError(null);
              }}
              readOnly={!props.editable}
              fontSize={14}
              tabSize={2}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={props.assistantTemplateStringDataText}
              height="70vh"
              width="100%"
              editorProps={{ $blockScrolling: true }}
              enableBasicAutocompletion={true}
              enableLiveAutocompletion={true}
              enableSnippets={true}
            />
          </Grid>
        </Grid>

        <br />
        <br />

        <Notifications notify={notify} />
      </div>
    </Fade>
  );
};

export default withStyles(main)(NewTemplateEditor);
