import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ParametersArray from "./ParametersArray";
import {
  cleanParameters,
  validateParameters,
  deleteParameters,
} from "../../../../../utils/parametersUtil";

const styles = makeStyles(theme => ({
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    paddingBottom: 0,
    textAlign: "left",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const ParametersEdit = props => {
  const { appDATA } = useSelector(state => ({
    appDATA: state.appSchema.appDATA,
  }));

  const {
    appStatesMetadata,
    selectedAppState,
    userJourneyID,
    changeUserJourneyData,
    heading,
  } = props;

  const [parameters, setParameters] = useState([]);

  const updateState = (newAppStatesMetadata = appStatesMetadata) => {
    const newParameters = newAppStatesMetadata[selectedAppState].parameters;

    setParameters(validateParameters(newParameters));
  };

  useEffect(() => {
    updateState();
  }, [selectedAppState]);

  const saveAppStateData = newUserJourneyData => {
    changeUserJourneyData(newUserJourneyData);
  };

  const handleSaveToSchema = parametersData => {
    const newAppStatesMetadata =
      appDATA.userJourneys[userJourneyID].appStatesMetadata;

    newAppStatesMetadata[selectedAppState].parameters = parametersData;

    updateState(newAppStatesMetadata);

    const newUserJourneyData = {
      ...appDATA.userJourneys[userJourneyID],
      appStatesMetadata: newAppStatesMetadata,
    };

    saveAppStateData(newUserJourneyData);

    return true;
  };

  const handleDelete = key => {
    const newChipData = deleteParameters(parameters, key);
    handleSaveToSchema(newChipData);
  };

  const cleanParameterData = data => {
    const textData = data;
    if (textData?.length > 0) {
      const chipData = parameters;
      const cleanChipData = cleanParameters(textData);
      const newChipData = [...new Set([...cleanChipData, ...chipData])];
      return handleSaveToSchema(newChipData);
    }
  };

  const parametersArrayProp = {
    parameters,
    cleanParameterData,
    handleDelete,
  };

  const classes = styles();

  return (
    <div>
      <Typography variant="h6" className={classes.chip} noWrap>
        {heading}
        {/* <DocHelpLink docFor={"APP_PROMPTS"} /> */}
      </Typography>
      <br />
      <ParametersArray {...parametersArrayProp} />
    </div>
  );
};

ParametersEdit.propTypes = {
  appStatesMetadata: PropTypes.array.isRequired,
  selectedAppState: PropTypes.number.isRequired,
  userJourneyID: PropTypes.number.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
};

export default ParametersEdit;
