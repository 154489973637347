import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DocHelpLink from "../../../common/DocLinks";
import { LinearProgress, ListItemSecondaryAction } from "@material-ui/core";

import SubDomainItems from "./SubDomainItems";
import AddDialog from "./AddDialog";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});
function SubDomainEdit(props) {
  const { classes, subdomains, handleEditAppData } = props;
  const [selectError, setSelectError] = useState(null);

  const handleSelectError = error => {
    setSelectError(error);
  };

  const SubDomainItemsProps = {
    subdomains,
    handleEditAppData,
    handleSelectError,
    selectError,
  };

  return (
    <div className={classes.root}>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <>
                <Typography component="span" variant="h5" noWrap>
                  {"Sub Domains "} <DocHelpLink docFor="SUB_DOMAINS" />
                </Typography>
              </>
            }
            secondary={
              <Typography
                variant="subtitle1"
                color={selectError ? selectError.type : "initial"}
              >
                {selectError ? selectError.message : <span>&nbsp;</span>}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <AddDialog
              title="Add Sub Domain"
              {...{ subdomains, handleEditAppData }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      {subdomains ? (
        <SubDomainItems {...SubDomainItemsProps} />
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

SubDomainEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  handleEditAppData: PropTypes.func.isRequired,
  subdomains: PropTypes.array,
};

export default withStyles(styles)(SubDomainEdit);
