import { isUserLoggedIn } from "../actions/login";
import * as types from "../types";
import * as helper from "./helper";
import { getDomainFullList } from "../../libs/slang/asstAPIs";

export const asstDomainActions = store => next => action => {
  if (!action.meta || action.meta.type !== "ASST_DOMAIN") {
    return next(action);
  }

  store.dispatch(isUserLoggedIn({}));
  const {
    login: { identity, isAuth },
    apikey: { api_key, api_secret },
  } = store.getState();

  if (!identity || !isAuth || !api_key || api_key === "" || !api_secret) {
    const payload = {
      error: "Error: id or api key not found",
    };
    helper.prepareAction(action, payload, store);
    return;
  }

  switch (action.type) {
    case types.DOMAIN_LIST_FULL:
      getDomainFullList()
        .then(listFull => {
          const setItem = [
            {
              key: "A_domainList",
              value: JSON.stringify(listFull),
            },
          ];
          const payload = {
            listFull,
            error: null,
          };
          helper.prepareActionSession(action, payload, store, setItem);
        })
        .catch(err => {
          console.log(err.toString());
          const setItem = [
            {
              key: "A_domainList",
              value: JSON.stringify([]),
            },
          ];
          const payload = {
            list: [],
            error: err.toString(),
          };
          helper.prepareAction(action, payload, store, setItem);
        });
      break;

    default:
      const newAction = Object.assign({}, action);
      delete newAction.meta;
      store.dispatch(newAction);
  }
};

export default asstDomainActions;
