import MD5 from "blueimp-md5";
import { deleteKeyFromObject, deleteKeyArray } from "./helpers";
import AppConfig from "../app.config";

const Langs = AppConfig.LANGUAGES;

export const randID = (text = (Math.random() * Math.random()).toString()) => {
  const id = MD5(Math.floor(Math.random() * Date.now()).toString() + text);
  return id;
};

export const extractStringPrompt = (promptData, Lang, strTable) => {
  const promptName = Object.keys(promptData)[0];
  let strObj = {};
  const getStr = data => {
    const newData = data.map(item => {
      if (item.isId) {
        return {
          ...item,
          id: item.value,
          value:
            strTable[item.value] && strTable[item.value][Lang]
              ? strTable[item.value][Lang]
              : "",
        };
      }
      return { ...item, value: item.value };
    });
    return newData;
  };

  if (promptData.clarificationPrompt || promptData.missingEntityPrompt) {
    const questionTemplates = promptData[promptName].questionTemplates
      ? getStr(promptData[promptName].questionTemplates)
      : [];
    const affirmativeResponseTemplates = promptData[promptName]
      .affirmativeResponseTemplates
      ? getStr(promptData[promptName].affirmativeResponseTemplates)
      : [];
    const negativeResponseTemplates = promptData[promptName]
      .negativeResponseTemplates
      ? getStr(promptData[promptName].negativeResponseTemplates)
      : [];

    strObj = {
      questionTemplates,
      affirmativeResponseTemplates,
      negativeResponseTemplates,
    };
  } else if (
    promptData.greetingStatement ||
    promptData.hangupStatement ||
    promptData.completion
  ) {
    const successTemplates = promptData[promptName].successTemplates
      ? getStr(promptData[promptName].successTemplates)
      : [];
    const failureTemplates = promptData[promptName].failureTemplates
      ? getStr(promptData[promptName].failureTemplates)
      : [];

    strObj = {
      successTemplates,
      failureTemplates,
    };
  } else {
    const successTemplates = promptData[promptName].successTemplates
      ? getStr(promptData[promptName].successTemplates)
      : [];
    const failureTemplates = promptData[promptName].failureTemplates
      ? getStr(promptData[promptName].failureTemplates)
      : [];

    strObj = {
      successTemplates,
      failureTemplates,
    };
  }

  return strObj;
};

export const checkEmptyAppPromptsNew = (oldAppData, allPromptTypes) => {
  const appData = { ...oldAppData, ...allPromptTypes };
  Object.keys(allPromptTypes).forEach(promptName => {
    Object.keys(allPromptTypes[promptName]).forEach(template => {
      if (!Object.keys(allPromptTypes[promptName][template]).length) {
        if (appData[promptName] && appData[promptName][template])
          delete appData[promptName][template];
      }
    });
    if (!(appData[promptName] && Object.keys(appData[promptName]).length)) {
      if (appData[promptName]) delete appData[promptName];
    }
  });
  return appData;
};

export const completePromptUserJourneyState = (
  appData,
  strData,
  languagePrompts,
  allPromptTypes
) => {
  const newStrData = strData;
  Object.keys(languagePrompts).forEach(lang => {
    Object.keys(languagePrompts[lang]).forEach(type => {
      Object.keys(languagePrompts[lang][type]).forEach(template => {
        languagePrompts[lang][type][template].forEach((item, n) => {
          let tmpID;
          const isModified = "_modified";
          if (item.id) {
            tmpID = item.id.match(isModified) ? item.id : item.id + isModified;
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: item.value,
            };
          } else if (allPromptTypes[type][template][n]) {
            tmpID = allPromptTypes[type][template][n].value;
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: item.value,
            };
          } else {
            tmpID = randID(item.value) + isModified;
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: item.value,
            };
          }
          allPromptTypes[type][template][n] = {
            severity: item.severity,
            value: tmpID,
            isId: true,
          };
        });
      });
    });
  });
  const newAppData = checkEmptyAppPromptsNew(appData, allPromptTypes);

  return { appData: newAppData, strData: newStrData };
};
export const completePromptState = (
  appData,
  strData,
  languagePrompts,
  allPromptTypes
) => {
  const newStrData = strData;
  Object.keys(languagePrompts).forEach(lang => {
    Object.keys(languagePrompts[lang]).forEach(type => {
      Object.keys(languagePrompts[lang][type]).forEach(template => {
        languagePrompts[lang][type][template].forEach((item, n) => {
          let tmpID;
          if (item.id) {
            tmpID = item.id;
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: item.value,
            };
          } else if (allPromptTypes[type][template][n]) {
            tmpID = allPromptTypes[type][template][n].value;
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: item.value,
            };
          } else {
            tmpID = randID(item.value);
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: item.value,
            };
          }
          allPromptTypes[type][template][n] = {
            severity: item.severity,
            value: tmpID,
            isId: true,
          };
        });
      });
    });
  });
  const newAppData = checkEmptyAppPromptsNew(appData, allPromptTypes);

  return { appData: newAppData, strData: newStrData };
};

export const emptyLangEntityState = () => {
  const languageState = {};
  const promptTemplate = {
    missingEntityPrompt: {
      questionTemplates: [],
      affirmativeResponseTemplates: [],
      negativeResponseTemplates: [],
    },
  };
  Langs.forEach((item, key) => {
    languageState[item.code] = promptTemplate;
  });
  return languageState;
};

export const emptyLangIntentState = () => {
  const languageState = {};
  const promptTemplate = {
    completion: {
      failureTemplates: [],
      successTemplates: [],
    },
  };
  Langs.forEach((item, key) => {
    languageState[item.code] = promptTemplate;
  });
  return languageState;
};

export const emptyLangAppState = () => {
  const languageState = {};
  const promptTemplate = {
    clarificationPrompt: {
      questionTemplates: [],
      affirmativeResponseTemplates: [],
      negativeResponseTemplates: [],
    },
    greetingStatement: {
      failureTemplates: [],
      successTemplates: [],
    },
    hangupStatement: {
      failureTemplates: [],
      successTemplates: [],
    },
  };
  Langs.forEach((item, key) => {
    languageState[item.code] = promptTemplate;
  });
  return languageState;
};

export const emptyLangAppUserJourneyState = () => {
  const promptTemplateUserJourneys = {
    userClarification: {
      failureTemplates: [],
      successTemplates: [],
    },
    userGreeting: {
      failureTemplates: [],
      successTemplates: [],
    },
    userCompletion: {
      failureTemplates: [],
      successTemplates: [],
    },
  };
  const languageState = {};
  Langs.forEach((item, key) => {
    languageState[item.code] = promptTemplateUserJourneys;
  });
  return languageState;
};

export const emptyStatePrompts = () => {
  const languageState = {};
  const prompt = {
    questionTemplates: "",
    affirmativeResponseTemplates: "",
    negativeResponseTemplates: "",
  };
  Langs.forEach((item, key) => {
    languageState[item.code] = prompt;
  });
  return languageState;
};
export const emptyStateUtterances = () => {
  const languageState = {};
  const statement = {
    utterances: "",
  };
  Langs.forEach((item, key) => {
    languageState[item.code] = statement;
  });
  return languageState;
};
export const emptyStateStatements = () => {
  const languageState = {};
  const statement = {
    failureTemplates: "",
    successTemplates: "",
  };
  Langs.forEach((item, key) => {
    languageState[item.code] = statement;
  });
  return languageState;
};

export const readEntityPromptState = (intentData, stringData) => {
  const promptLanguageState = {};
  const strTable = stringData;
  const missingEntityPrompt =
    intentData.missingEntityPrompt &&
    intentData.missingEntityPrompt.questionTemplates.length
      ? intentData.missingEntityPrompt.questionTemplates[0].value
      : "";

  Langs.forEach((item, key) => {
    promptLanguageState[item.code] = {
      missingEntityPrompt:
        missingEntityPrompt && strTable[missingEntityPrompt]
          ? extractStringPrompt(
              { missingEntityPrompt: intentData.missingEntityPrompt },
              item.code,
              strTable
            )
          : {
              questionTemplates: [],
              affirmativeResponseTemplates: [],
              negativeResponseTemplates: [],
            },
    };
  });
  return promptLanguageState;
};
export const readIntentPromptState = (intentData, stringData) => {
  const promptLanguageState = {};
  const strTable = stringData;
  const completion = intentData?.completion?.successTemplates?.length
    ? intentData.completion.successTemplates[0].value
    : "";

  Langs.forEach((item, key) => {
    promptLanguageState[item.code] = {
      completion:
        completion && strTable[completion]
          ? extractStringPrompt(
              { completion: intentData.completion },
              item.code,
              strTable
            )
          : {
              failureTemplates: [],
              successTemplates: [],
            },
    };
  });
  return promptLanguageState;
};

export const readPromptStateUserJourneys = (
  appData,
  stringData,
  promptName1
) => {
  const prompts =
    appData && appData[promptName1] ? appData[promptName1] : appData.completion;
  const promptLanguageState = {};
  const strTable = stringData;
  // const statement1 =
  //   appData[promptName1] && appData[promptName1]["successTemplates"]?.length
  //     ? appData[promptName1]["successTemplates"][0]["value"]
  //     : "";
  // Langs.forEach((item, key) => {
  //   promptLanguageState[item.code] = {
  //     [promptName1]:
  //       statement1 && strTable[statement1]
  //         ? extractStringPrompt(
  //             { [promptName1]: appData[promptName1] },
  //             item.code,
  //             strTable
  //           )
  //         : {
  //             failureTemplates: [],
  //             successTemplates: [],
  //           },
  const statement1 =
    prompts && prompts.successTemplates?.length
      ? prompts.successTemplates[0].value
      : "";
  Langs.forEach((item, key) => {
    promptLanguageState[item.code] = {
      [promptName1]:
        statement1 && strTable[statement1]
          ? extractStringPrompt({ [promptName1]: prompts }, item.code, strTable)
          : {
              failureTemplates: [],
              successTemplates: [],
            },
      // [promptName2]:
      // statement2 && strTable[statement2]
      //   ? extractStringPrompt(
      //       { [promptName2]: appData[promptName2] },
      //       item.code,
      //       strTable
      //     )
      //   : {
      //       failureTemplates: [],
      //       successTemplates: []
      //     },
    };
  });
  return promptLanguageState;
};
export const readPromptState = (appData, stringData) => {
  const promptLanguageState = {};
  const strTable = stringData;
  const greetingStatement =
    appData.greetingStatement &&
    appData.greetingStatement.successTemplates.length
      ? appData.greetingStatement.successTemplates[0].value
      : "";
  const hangupStatement =
    appData.hangupStatement && appData.hangupStatement.successTemplates.length
      ? appData.hangupStatement.successTemplates[0].value
      : "";
  const clarificationPrompt =
    appData.clarificationPrompt &&
    appData.clarificationPrompt.questionTemplates.length
      ? appData.clarificationPrompt.questionTemplates[0].value
      : null;

  Langs.forEach((item, key) => {
    promptLanguageState[item.code] = {
      clarificationPrompt:
        clarificationPrompt && strTable[clarificationPrompt]
          ? extractStringPrompt(
              { clarificationPrompt: appData.clarificationPrompt },
              item.code,
              strTable
            )
          : {
              questionTemplates: [],
              affirmativeResponseTemplates: [],
              negativeResponseTemplates: [],
            },
      greetingStatement:
        greetingStatement && strTable[greetingStatement]
          ? extractStringPrompt(
              { greetingStatement: appData.greetingStatement },
              item.code,
              strTable
            )
          : {
              failureTemplates: [],
              successTemplates: [],
            },
      hangupStatement:
        hangupStatement && strTable[hangupStatement]
          ? extractStringPrompt(
              { hangupStatement: appData.hangupStatement },
              item.code,
              strTable
            )
          : {
              failureTemplates: [],
              successTemplates: [],
            },
    };
  });
  return promptLanguageState;
};

export const editPromptSeverity = (oldLanguagePrompts, lang, idx, severity) => {
  const languagePrompts = oldLanguagePrompts;
  const typePrompts = idx.split(".");
  Langs.forEach(lng => {
    const value =
      languagePrompts[lng.code][typePrompts[0]][typePrompts[1]][typePrompts[2]];
    if (value)
      languagePrompts[lng.code][typePrompts[0]][typePrompts[1]][
        typePrompts[2]
      ] = { ...value, severity };
  });
  return languagePrompts;
};
export const editPromptState = (
  oldLanguagePrompts,
  lang,
  name,
  key,
  value,
  severity = 1
) => {
  let languagePrompts = oldLanguagePrompts;
  const typePrompts = name.split(".");
  const isNew = !languagePrompts[lang][typePrompts[0]][typePrompts[1]][key];
  Langs.forEach(item => {
    if (item.code === lang) {
      if (isNew) {
        languagePrompts = {
          ...languagePrompts,
          [lang]: {
            ...languagePrompts[lang],
            [typePrompts[0]]: {
              ...languagePrompts[lang][typePrompts[0]],
              [typePrompts[1]]: [
                ...languagePrompts[lang][typePrompts[0]][typePrompts[1]],
                { value, severity },
              ],
            },
          },
        };
      } else {
        const changedPrompt =
          languagePrompts[lang][typePrompts[0]][typePrompts[1]];
        changedPrompt[key].value = value;
        languagePrompts = {
          ...languagePrompts,
          [lang]: {
            ...languagePrompts[lang],
            [typePrompts[0]]: {
              ...languagePrompts[lang][typePrompts[0]],
              [typePrompts[1]]: changedPrompt,
            },
          },
        };
      }
    } else {
      if (isNew) {
        languagePrompts = {
          ...languagePrompts,
          [item.code]: {
            ...languagePrompts[item.code],
            [typePrompts[0]]: {
              ...languagePrompts[item.code][typePrompts[0]],
              [typePrompts[1]]: [
                ...languagePrompts[item.code][typePrompts[0]][typePrompts[1]],
                { value: "", severity },
              ],
            },
          },
        };
      }
    }
  });
  return languagePrompts;
};

export const writeIntentPromptState = (appData, strData, languagePrompts) => {
  const allPromptTypes = {
    completion: {
      failureTemplates: [],
      successTemplates: [],
    },
  };

  const newData = completePromptState(
    appData,
    strData,
    languagePrompts,
    allPromptTypes
  );

  return newData;
};

export const writeEntityPromptState = (appData, strData, languagePrompts) => {
  const allPromptTypes = {
    missingEntityPrompt: {
      questionTemplates: [],
      affirmativeResponseTemplates: [],
      negativeResponseTemplates: [],
    },
  };

  const newData = completePromptState(
    appData,
    strData,
    languagePrompts,
    allPromptTypes
  );

  return newData;
};

export const writeAppPromptState = (appData, strData, languagePrompts) => {
  const allPromptTypes = {
    clarificationPrompt: {
      questionTemplates: [],
      affirmativeResponseTemplates: [],
      negativeResponseTemplates: [],
    },
    greetingStatement: {
      failureTemplates: [],
      successTemplates: [],
    },
    hangupStatement: {
      failureTemplates: [],
      successTemplates: [],
    },
  };
  const newData = completePromptState(
    appData,
    strData,
    languagePrompts,
    allPromptTypes
  );
  return newData;
};

const setPromptId = (prompts, promptName) => {
  const severityLevels = [1, 2, 3];
  const severityArray = [];
  let promptsWithIds = [];

  severityLevels.forEach(severity => {
    severityArray[severity - 1] = prompts.filter(
      prompt => prompt.severity === severity
    );
  });

  severityArray.forEach((severitySet, sevId) => {
    severitySet.forEach((prompt, variant) => {
      const found = prompt.id
        ? prompt.id.match(/^[a-zA-Z_]+_sev\d_var\d$/g)
        : false;
      let tmpId = `${promptName.replace(/\_$/, "")}_sev${sevId + 1}_var${
        variant + 1
      }`;
      if (!found || (prompt.id && prompt.id.match("_modified"))) {
        tmpId = tmpId + "_modified";
      }
      prompt.id = tmpId;
      promptsWithIds = [...promptsWithIds, prompt];
    });
  });

  return promptsWithIds;
};

export const writeUserJourneyPromptsState = (
  appData,
  strData,
  languagePrompts,
  promptName,
  key
) => {
  const languagePromptsUpdatedIds = languagePrompts;

  Object.keys(languagePromptsUpdatedIds).forEach(lang => {
    languagePromptsUpdatedIds[lang][promptName].successTemplates = setPromptId(
      languagePromptsUpdatedIds[lang][promptName].successTemplates,
      appData.name
    );
  });

  const newData = updateData(
    appData,
    strData,
    languagePromptsUpdatedIds,
    key,
    promptName
  );

  return newData;
};

const updateData = (
  appData,
  strData,
  languagePromptsUpdatedIds,
  key,
  promptName
) => {
  const newAppData = appData;
  const newStringTable = strData;
  let isModified = false;

  if (key !== null) {
    const tmpId =
      languagePromptsUpdatedIds["en-IN"][promptName].successTemplates[key].id;
    if (!tmpId.match("_modified")) {
      if (strData[tmpId]) {
        isModified = Object.keys(strData[tmpId]).reduce((acc, cur) => {
          const value =
            languagePromptsUpdatedIds[cur][promptName].successTemplates[key]
              .value;
          if (acc) return true;
          if (strData[tmpId][cur] === value) return false;
          if (strData[tmpId][cur] !== value) return true;
        }, false);
      } else {
        isModified = true;
      }
    }

    Langs.forEach(item => {
      if (isModified) {
        languagePromptsUpdatedIds[item.code][promptName].successTemplates[
          key
        ].id = tmpId + "_modified";
      }
    });
  }

  newAppData[promptName] = {
    successTemplates: languagePromptsUpdatedIds["en-IN"][
      promptName
    ].successTemplates.map(item => {
      return {
        value: item.id,
        severity: item.severity,
        isId: true,
      };
    }),
  };

  Object.keys(languagePromptsUpdatedIds).forEach(lang => {
    languagePromptsUpdatedIds[lang][promptName].successTemplates.forEach(
      prompt => {
        if (prompt.id.match("_modified")) {
          newStringTable[prompt.id] = {
            ...newStringTable[prompt.id],
            [lang]: prompt.value,
          };
        }
      }
    );
  });

  const { cleanAppData, cleanStringTable } = cleanFalseModified(
    newAppData,
    newStringTable,
    promptName
  );

  return { newAppData: cleanAppData, newStringTable: cleanStringTable };
};

const cleanFalseModified = (appData, stringTable, promptName) => {
  const cleanAppData = appData;
  const cleanStringTable = stringTable;

  const prompts = cleanAppData[promptName].successTemplates;

  prompts.forEach((item, id) => {
    if (item.value.match("_modified")) {
      const defaultId = item.value.replace("_modified", "");

      const isModified =
        cleanStringTable[defaultId] &&
        Object.keys(cleanStringTable[defaultId]).reduce((acc, cur) => {
          const value = cleanStringTable[item.value][cur];
          if (acc) return true;
          if (cleanStringTable[defaultId][cur] === value) return false;
          if (cleanStringTable[defaultId][cur] !== value) return true;
        }, false);

      if (!isModified && typeof isModified !== "undefined") {
        delete cleanStringTable[item.value];
        item.value = defaultId;
      }
    }
  });

  cleanAppData[promptName].successTemplates = prompts;

  return { cleanAppData, cleanStringTable };
};

export const deleteIntentPromptState = (
  languagePrompts,
  strData,
  template,
  promptType,
  ID
) => {
  let newStrData = strData;
  const newLanguagePrompts = languagePrompts;
  const stringID = languagePrompts["en-IN"][promptType][template][ID].id
    ? languagePrompts["en-IN"][promptType][template][ID].id
    : null;

  if (ID > -1) {
    Langs.forEach(item => {
      newLanguagePrompts[item.code][promptType][template] = deleteKeyArray(
        languagePrompts[item.code][promptType][template],
        ID
      );
    });
  }

  if (stringID) {
    newStrData = deleteKeyFromObject(strData, stringID);
    return { hasStringID: stringID, newLanguagePrompts, newStrData };
  }
  return { hasStringID: stringID, newLanguagePrompts, newStrData };
};

export const deleteAppPromptState = (
  languagePrompts,
  strData,
  template,
  promptType,
  ID
) => {
  let newStrData = strData;
  const newLanguagePrompts = languagePrompts;
  const stringID = languagePrompts["en-IN"][promptType][template][ID].id
    ? languagePrompts["en-IN"][promptType][template][ID].id
    : null;

  if (ID > -1) {
    Langs.forEach(item => {
      newLanguagePrompts[item.code][promptType][template] = deleteKeyArray(
        languagePrompts[item.code][promptType][template],
        ID
      );
    });
  }

  const isModified = "_modified";

  if (stringID && stringID.match(isModified)) {
    newStrData = deleteKeyFromObject(strData, stringID);
  }

  return { hasStringID: stringID, newLanguagePrompts, newStrData };
};
