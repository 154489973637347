import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const styles = makeStyles(theme => ({
  textField: {
    width: "90%",
  },
  fab: {
    height: 35,
    width: 35,
    marginLeft: theme.spacing(2),
    verticalAlign: "bottom",
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
}));

const InputTextField = props => {
  const { cleanParameterData } = props;

  const [addParameter, setAddParameter] = useState("");

  const handleTextChange = event => {
    setAddParameter(event.target.value);
  };

  const updateChips = () => {
    if (cleanParameterData(addParameter)) setAddParameter("");
  };

  const onKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      updateChips();
    }
  };

  const classes = styles();

  return (
    <>
      <TextField
        name={"parameters"}
        label={"Allowed Parameters"}
        placeholder="e.g. '$$app_name' for app parameters, '$retail_brand' or 'retail variant, retail size'"
        className={classes.textField}
        value={addParameter}
        onChange={handleTextChange}
        onKeyPress={onKeyPress}
        margin="normal"
      />
      <Fab
        size="small"
        aria-label="Add"
        color="default"
        className={classes.fab}
        onClick={updateChips}
        disabled={addParameter.length === 0}
      >
        <Add />
      </Fab>
    </>
  );
};

InputTextField.propTypes = {
  cleanParameterData: PropTypes.func.isRequired,
};

export default InputTextField;
