import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppHomeTabs from "../Template/TabsContainer/AppHomeTabs";
import IntentHomeTabs from "../Template/TabsContainer/IntentHomeTabs";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  if (value === index)
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        aria-labelledby={`simple-tab-${index}`}
        style={{ padding: " 1% 3% 1% 3%" }}
        {...other}
      >
        {children}
      </div>
    );
  else return null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function TabsContainer(props) {
  const {
    handleDeleteIntent,
    handleDeleteEntityType,
    handleNewEntityType,
    handleEditAppData,
    appDATA,
  } = props;

  const [page, setPage] = React.useState("App Home");
  const [intentID, setIntentID] = React.useState(null);

  const handlePageChange = intentName => {
    if (intentName) {
      setPage("Intent Home");
      const { value, key } = appDATA.intents.reduce(
        (Acc, obj, ID) =>
          obj.name === intentName ? { value: obj, key: ID } : Acc,
        { value: null, key: null }
      );

      setIntentID(key);
    } else {
      setPage("App Home");
      setIntentID(null);
    }
  };

  const AppHomeTabsProps = {
    handleDeleteIntent,
    handleDeleteEntityType,
    handleNewEntityType,
    handleEditAppData,
    appDATA,
    handlePageChange,
  };
  const IntentHomeTabsProps = {
    appDATA,
    intentID,
    handlePageChange,
  };

  switch (page) {
    case "App Home":
      return <AppHomeTabs {...AppHomeTabsProps} />;
    case "Intent Home":
      return <IntentHomeTabs {...IntentHomeTabsProps} />;

    default:
      return <AppHomeTabs {...AppHomeTabsProps} />;
  }
}
