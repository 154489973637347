// Create Empty UI state object for No Code Action Dialog
export const emptyNoCodeActionsState = () => {
  const emptyNoCodeActions = {
    android: {
      name: "",
      appStateName: "",
      noCodeActionUri: "",
      conditionNames: [
        {
          name: "",
        },
      ],
    },
    web: {
      name: "",
      appStateName: "",
      noCodeActionUri: "",
      conditionNames: [
        {
          name: "",
        },
      ],
    },
    ios: {
      name: "",
      appStateName: "",
      noCodeActionUri: "",
      conditionNames: [
        {
          name: "",
        },
      ],
    },
  };

  return emptyNoCodeActions;
};

// Read no code actions from schema if available else send the empty action state.
export const readNoCodeActionState = noCodeActions => {
  let newActions = emptyNoCodeActionsState();
  if (noCodeActions) {
    noCodeActions.actions.forEach(item => {
      newActions = {
        ...newActions,
        [item.platform]: {
          ...newActions[item.platform],
          appStateName: item.noCodeAction.appStateName,
          conditionNames: item.noCodeAction.conditionNames,
          noCodeActionUri: item.noCodeAction.noCodeActionUri,
          name: item.noCodeAction.name,
        },
      };
    });
  }

  return newActions;
};

// Editing no code action state on input changes
export const editNoCodeActionState = (
  actions,
  payload,
  platform,
  userjourneyName
) => {
  const { noCodeActionUri, appStateName, conditionName } = payload;

  let newActions = actions;
  newActions = {
    ...newActions,
    [platform]: {
      ...newActions[platform],
      appStateName,
      conditionNames: [
        {
          name: conditionName,
        },
      ],
      noCodeActionUri,
      name: `${userjourneyName}_no_code_action_${platform}`,
    },
  };
  return newActions;
};

// Parse the no code actions from UI state object to schema-acceptable object shape
export const writeNoCodeActionState = actionsState => {
  const newActionsState = {
    supported: true,
    actions: [],
  };

  Object.keys(actionsState).forEach(platform => {
    if (actionsState[platform] && actionsState[platform].noCodeActionUri) {
      if (actionsState[platform].noCodeActionUri.trim() !== "") {
        newActionsState.actions = [
          ...newActionsState.actions,
          { platform, noCodeAction: { ...actionsState[platform] } },
        ];
      }
    }
  });
  return newActionsState;
};
