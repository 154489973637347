import { getPostPutDelete } from "../utils/slangAPIs";
import AnalyticsConfig from "./config";

/* Replicated from ./slang_analytics.js to prevent export issues as 
../utils/slangAPIs is a es5 module and couldn't be imported in a commonjs file. */

export const SlangSeverityLevels = {
  DEFAULT: "DEFAULT", //	(0) The log entry has no assigned severity level.
  DEBUG: "DEBUG", //	(100) Debug or trace information.
  INFO: "INFO", //	(200) Routine information, such as ongoing status or performance.
  NOTICE: "NOTICE", //	(300) Normal but significant events, such as start up, shut down, or a configuration change.
  WARNING: "WARNING", //	(400) Warning events might cause problems.
  ERROR: "ERROR", //	(500) Error events are likely to cause problems.
  CRITICAL: "CRITICAL", //	(600) Critical events cause more severe problems or outages.
  ALERT: "ALERT", //	(700) A person must take an action immediately.
  EMERGENCY: "EMERGENCY", //	(800) One or more systems are unusable.
};

export const SlangEvents = {
  CONSOLE_VISITED: "console.home.visited",
  ANALYTICS_VISITED: "console.analytics.visited",
  ACCOUNTING_VISITED: "console.accounting.visited",
  HELP_VISITED: "console.help.visited",
  PAGE_VISITED: "console.page.visited",
  ORG_CREATED: "console.organisation.created",

  APP_CREATED: "console.app.created",
  APP_CREATE_FAILURE: "console.app.create.failure",
  APP_DELETED: "console.app.deleted",
  APP_DELETE_FAILURE: "console.app.delete.failure",
  APP_NAME_UPDATED: "console.app.name_updated",
  APP_OPENED: "console.app.opened",
  APP_PUBLISH_STARTED: "console.app.publish.started",
  APP_PUBLISH_SUCCESS: "console.app.publish.success",
  APP_PUBLISH_FAILURE: "console.app.publish.failure",
  APP_SAVE_STARTED: "console.app.save.started",
  APP_SAVE_SUCCESS: "console.app.save.success",
  APP_SAVE_FAILURE: "console.app.save.failure",

  INTENT_OPENED: "console.intent.opened",
  INTENT_CREATED: "console.intent.created",
  INTENT_DELETED: "console.intent.deleted",
  INTENT_NAME_UPDATED: "console.intent.name_updated",

  ENTITY_TYPE_OPENED: "console.entity_type.opened",
  ENTITY_TYPE_CREATED: "console.entity_type.created",
  ENTITY_TYPE_NAME_UPDATED: "console.entity_type.name_updated",
  ENTITY_TYPE_DELETED: "console.entity_type.deleted",

  ENTITY_OPENED: "console.entity.opened",
  ENTITY_CREATED: "console.entity.created",
  ENTITY_DELETED: "console.entity.deleted",
  ENTITY_NAME_UPDATED: "console.entity.name_updated",

  UTTERANCE_CREATED: "console.utterance.created",
  UTTERANCE_DELETED: "console.utterance.deleted",
  UTTERANCE_UPDATED: "console.utterance.updated",

  SIGNUP_STARTED: "console.signup.started",
  SIGNUP_FAILED: "console.signup.failure",
  SIGNUP_SUCCESS: "console.signup.success",
  CONFIRM_CODE_STARTED: "console.confirm_code.started",
  CONFIRM_CODE_SUCCESS: "console.confirm_code.success",
  CONFIRM_CODE_FAILURE: "console.confirm_code.failure",
  SIGNIN_STARTED: "console.signin.started",
  SIGNIN_FAILED: "console.signin.failure",
  SIGNIN_SUCCESS: "console.signin.success",
  FORGOT_PASSWORD_STARTED: "console.forgot_password.started",
  FORGOT_PASSWORD_SUCCESS: "console.forgot_password.success",
  FORGOT_PASSWORD_FAILURE: "console.forgot_password.failure",
  CONFIRM_EMAIL_CLICKED: "console.confirm_email.clicked",
  SIGNOUT_STARTED: "console.signout.started",
  SIGNOUT_SUCCESS: "console.signout.success",
  SIGNOUT_FAILURE: "console.signout.failure",

  CHANGE_PLAN_CLICKED: "console.change_plan.clicked",

  CONSOLE_ERROR: "console.error",
  APIKEY_CREATED: "console.apikey.created",
  APIKEY_COPIED: "console.apikey.copied",
  APP_ID_COPIED: "console.app_id.copied",
  PASSWORD_CHANGED: "console.password.changed",

  SUBDOMAIN_ENABLED: "console.subdomain.enabled",
  SUBDOMAIN_USER_DATA_REPLACE: "console.subdomain.user_data.replace",
  SUBDOMAIN_USER_DATA_UPLOAD_STARTED:
    "console.subdomain.user_data.upload.started",
  SUBDOMAIN_USER_DATA_UPLOAD_SUCCESS:
    "console.subdomain.user_data.upload.success",
  SUBDOMAIN_USER_DATA_UPLOAD_FAILURE:
    "console.subdomain.user_data.upload.failure",
  SUBDOMAIN_USER_DATA_DELETE: "console.subdomain.user_data.delete",

  USERJOURNEY_ENABLED: "console.userjourney.enabled",
  USERJOURNEY_ACTIONS_UPDATED: "console.userjourney.actions.updated",
  USERJOURNEY_PROMPTS_UPDATED: "console.userjourney.prompts.updated",
  USERJOURNEY_UIHINTS_UPDATED: "console.userjourney.uihints.updated",

  APP_LANGUAGE_SETTINGS_UPDATED: "console.app_locale.updated",

  GLOBAL_PROMPTS_UPDATED: "console.global_prompts.updated",
  GLOBAL_UIHINTS_UPDATED: "console.global_uihints.updated",

  UI_CONFIG_UPDATED: "console.ui_config.updated",

  FETCH_SCHEMA_DEFINITION_STARTED: "console.schema_definition.fetch.started",
  FETCH_SCHEMA_DEFINITION_FAILURE: "console.schema_definition.fetch.failure",
  FETCH_SCHEMA_DEFINTIION_SUCCESS: "console.schema_definition.fetch.success",
  VALIDATE_SCHEMA_STARTED: "console.schema.validation.started",
  VALIDATE_SCHEMA_FAILURE: "console.schema.validation.failure",
  VALIDATE_SCHEMA_SUCCESS: "console.schema.validation.success",
  UPLOAD_APP_STRING_TABLE_STARTED: "console.app_string.upload.started",
  UPLOAD_APP_STRING_TABLE_FAILURE: "console.app_string.upload.failure",
  UPLOAD_APP_STRING_TABLE_SUCCESS: "console.app_string.upload.success",
  UPLOAD_APP_SCHEMA_STARTED: "console.app_schema.upload.started",
  UPLOAD_APP_SCHEMA_FAILURE: "console.app_schema.upload.failure",
  UPLOAD_APP_SCHEMA_SUCCESS: "console.app_schema.upload.success",
  SUBDOMAIN_USER_DATA_FETCH_FAILURE:
    "console.subdomain.user_data.fetch.failure",
  SUBDOMAIN_USER_DATA_FETCH_SUCCESS:
    "console.subdomain.user_data.fetch.success",
  SUBDOMAIN_USER_DATA_FETCH_STARTED:
    "console.subdomain.user_data.fetch.started",
  APP_LIST_FETCH_STARTED: "console.app_list.fetch.started",
  APP_LIST_FETCH_SUCCESS: "console.app_list.fetch.success",
  APP_LIST_FETCH_FAILURE: "console.app_list.fetch.failure",
  APP_CLONED: "console.app.cloned",
  APP_CLONE_FAILED: "console.app.clone.failure",
  APP_STRING_TABLE_FETCH_SUCCESS: "console.app.string.fetch.success",
  APP_STRING_TABLE_FETCH_FAILURE: "console.app.string.fetch.failure",
  APP_STRING_TABLE_FETCH_STARTED: "console.app.string.fetch.started",
  APP_DATA_FETCH_SUCCESS: "console.app.data.fetch.success",
  APP_DATA_FETCH_FAILURE: "console.app.data.fetch.failure",
  APP_DATA_FETCH_STARTED: "console.app.data.fetch.started",
  UPDATE_TEMPLATE_FAILURE: "console.template.update.failure",
  UPDATE_TEMPLATE_SUCCESS: "console.template.update.success",
  UPDATE_TEMPLATE_STARTED: "console.template.update.started",
  TEMPLATE_CREATE_STARTED: "console.template.create.started",
  TEMPLATE_CREATE_FAILURE: "console.template.create.failure",
  TEMPLATE_CREATE_SUCCESS: "console.template.create.success",
};

let host;
let common_props = {};

export const initialiseAnalytics = (slang_host, properties) => {
  if (slang_host !== null) host = slang_host;

  if (properties != null) {
    common_props = Object.assign({}, properties);
  }
};

export const updateAnalyticsProps = properties => {
  if (properties != null) {
    common_props = Object.assign(common_props, properties);
  }
};

export const getMSUAnalyticsData = async () => {
  const response = await getPostPutDelete({
    path: AnalyticsConfig.MSU_COUNT_URI,
    method: "GET",
  });
  return response;
};

export const logEvent = async (severity_level, event_type, properties = {}) => {
  const A_Data = sessionStorage.getItem("A_data");

  let assistant_id;
  let assistant_name;
  let version = "";

  if (A_Data) {
    assistant_id = JSON.parse(A_Data).id;
    assistant_name = JSON.parse(A_Data).name;
    version = JSON.parse(A_Data).version;
  }

  const eventData = {
    event_type,
    severity_level,
    timestamp: new Date().getTime(),
    ...(assistant_id ? { assistant_id } : {}),
    ...(assistant_name ? { assistant_name } : {}),
    ...(version ? { assistant_version: version } : {}),
    ...properties,
    ...common_props,
    org_id: localStorage.getItem("U_orgID") || "0",
    org_name: localStorage.getItem("U_orgName") || "0",
  };

  const response = await getPostPutDelete({
    path: AnalyticsConfig.LOG_EVENT_URL,
    method: "POST",
    postPutData: eventData,
  });
  return response;
};

export const logPageVisited = async page_name => {
  const response = await logEvent(
    SlangSeverityLevels.INFO,
    SlangEvents.PAGE_VISITED,
    {
      page: page_name,
    }
  );

  return response;
};

export const logError = async (error, info) => {
  const response = await logEvent(
    SlangSeverityLevels.ERROR,
    SlangEvents.CONSOLE_ERROR,
    {
      info,
      error,
    }
  );

  return response;
};
