import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Popover, Typography, Button } from "@material-ui/core";
import { Remove } from "@material-ui/icons";

const styles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  middle: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  redButton: {
    color: theme.palette.error.main,
  },
}));

const DeleteLevelButton = props => {
  const { handleDelete, deleteMessage, variant, popover, color } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    if (popover) {
      setAnchorEl(event.currentTarget);
    } else {
      handleDelete();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClose = () => {
    handleDelete();
    setAnchorEl(null);
  };

  const classes = styles();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {variant === "Button" ? (
        <Button
          aria-describedby={id}
          variant="outlined"
          color={color || "secondary"}
          onClick={handleClick}
          size="small"
        >
          Delete
        </Button>
      ) : (
        <IconButton
          aria-label="Delete"
          color={color || "primary"}
          onClick={handleClick}
        >
          <Remove />
        </IconButton>
      )}
      {popover ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.middle}>
            <Typography className={classes.typography}>
              {deleteMessage}
            </Typography>
            <Button className={classes.redButton} onClick={handleDeleteClose}>
              Yes
            </Button>{" "}
            <Button onClick={handleClose}>No</Button>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export default DeleteLevelButton;
