import AppConfig from "../../../../../app.config";

const Langs = AppConfig.LANGUAGES;

export const randID = (text = (Math.random() * Math.random()).toString()) => {
  const id = MD5(Math.floor(Math.random() * Date.now()).toString() + text);
  return id;
};

export const emptyStateStatements = () => {
  const languageStateStatements = {};
  const stateStatement = {
    failureTemplates: "",
    successTemplates: "",
  };
  Langs.forEach((item, key) => {
    languageStateStatements[item.code] = stateStatement;
  });
  return languageStateStatements;
};

export const emptyLangPromptUserJourneyState = () => {
  const promptTemplateUserJourneys = {
    failureTemplates: [],
    successTemplates: [],
  };

  const languageState = {};
  Langs.forEach((item, key) => {
    languageState[item.code] = promptTemplateUserJourneys;
  });

  return languageState;
};

const extractStringPrompt = (completionStatements, lang, appStringTable) => {
  const getStr = data => {
    const newData = data
      .map(item => {
        if (item.isId) {
          return {
            ...item,
            id: item.value,
            value:
              appStringTable[item.value] && appStringTable[item.value][lang]
                ? appStringTable[item.value][lang]
                : "",
            empty: !(
              appStringTable[item.value] && appStringTable[item.value][lang]
            ),
          };
        } else {
          return {
            ...item,
            value: lang === "en-IN" ? item.value : "",
            empty: false,
          };
        }
      })
      .filter(item => item.value.trim() !== "");
    return newData;
  };

  const strObj = {
    successTemplates: completionStatements.successTemplates
      ? getStr(completionStatements.successTemplates)
      : [],
    failureTemplates: completionStatements.failureTemplates
      ? getStr(completionStatements.failureTemplates)
      : [],
  };
  return strObj;
};

export const readAppStateCompletionPrompts = (
  appStatesMetadata,
  appStringTable
) => {
  const promptLanguageState = {};
  if (appStatesMetadata) {
    if (!appStatesMetadata.userCompletion) {
      appStatesMetadata.userCompletion = JSON.parse(
        JSON.stringify(appStatesMetadata.completion)
      );
    }
  }
  const prompts = appStatesMetadata && appStatesMetadata.userCompletion;
  Langs.forEach(item => {
    promptLanguageState[item.code] = {
      ...extractStringPrompt(prompts, item.code, appStringTable),
    };
  });
  return promptLanguageState;
};

export const editPromptState = (
  languagePrompts,
  severity,
  value,
  lang,
  completionTemplateType,
  key
) => {
  const isNew = !languagePrompts[lang][completionTemplateType][key];

  const newPrompt = {
    severity,
    value,
    empty: false,
  };

  let newLanguagePrompts = languagePrompts;

  if (isNew) {
    newLanguagePrompts = {
      ...newLanguagePrompts,
      [lang]: {
        ...newLanguagePrompts[lang],
        [completionTemplateType]: [
          ...newLanguagePrompts[lang][completionTemplateType],
          newPrompt,
        ],
      },
    };
  } else {
    const changedPrompt = newLanguagePrompts[lang][completionTemplateType];
    changedPrompt[key].value = value;
    changedPrompt[key].empty = false;
    newLanguagePrompts = {
      ...newLanguagePrompts,
      [lang]: {
        ...newLanguagePrompts[lang],
        [completionTemplateType]: changedPrompt,
      },
    };
  }

  return newLanguagePrompts;
};

const updateAppState = (appState, languagePrompts) => {
  const completionTemplate = {
    failureTemplates: [],
    successTemplates: [],
  };

  const promptVisited = [];

  Object.keys(languagePrompts).forEach(lang => {
    Object.keys(languagePrompts[lang]).forEach(template => {
      languagePrompts[lang][template].forEach((prompt, idx) => {
        if (!promptVisited.includes(prompt.id)) {
          completionTemplate[template].push({
            value: prompt.id,
            isId: true,
            severity: prompt.severity,
          });
          promptVisited.push(prompt.id);
        }
      });
      completionTemplate[template].sort((a, b) => {
        // Compare the 2 dates
        if (a.severity < b.severity) return -1;
        if (a.severity > b.severity) return 1;
        return 0;
      });
    });
  });

  const newAppState = appState;
  newAppState.userCompletion = completionTemplate;
  return newAppState;
};

const updateStringTable = (appStringTable, languagePrompts) => {
  const newAppStringTable = appStringTable;
  const refreshStack = [];

  Object.keys(languagePrompts).forEach(lang => {
    Object.keys(languagePrompts[lang]).forEach(template => {
      languagePrompts[lang][template].forEach(prompt => {
        if (prompt.id.match("_modified")) {
          if (!refreshStack.includes(prompt.id)) {
            newAppStringTable[prompt.id] = {};
            refreshStack.push(prompt.id);
          }
          newAppStringTable[prompt.id] = {
            ...(newAppStringTable[prompt.id] && newAppStringTable[prompt.id]),
            [lang]: prompt.value,
          };
        }
      });
    });
  });

  return newAppStringTable;
};

const setPromptId = (prompts, promptName, severityLevels) => {
  const severityArray = [];
  let promptsWithIds = [];

  severityLevels.forEach(severity => {
    severityArray[severity - 1] = prompts.filter(
      prompt => prompt.severity === severity
    );
  });

  severityArray.forEach((severitySet, sevId) => {
    severitySet.forEach((prompt, variant) => {
      const tmpId = `${promptName.replace(/\_$/, "")}_sev${sevId + 1}_var${
        variant + 1
      }`;
      if (prompt.id && prompt.id.match("_modified")) {
        prompt.id = tmpId + "_modified";
      } else {
        prompt.id = tmpId;
      }
      promptsWithIds = [...promptsWithIds, prompt];
    });
  });

  return promptsWithIds;
};

export const writeUserJourneyPromptsState = (
  promptName,
  appState,
  appStringTable,
  languagePrompts,
  levels,
  key,
  stringID = null,
  language
) => {
  const newLanguagePrompts = languagePrompts;

  if (stringID !== null) {
    newLanguagePrompts[language].successTemplates = newLanguagePrompts[
      language
    ].successTemplates.filter(prompt => prompt.id !== stringID);
  }

  Object.keys(newLanguagePrompts).forEach(lang => {
    Object.keys(newLanguagePrompts[lang]).forEach(template => {
      newLanguagePrompts[lang][template] = setPromptId(
        newLanguagePrompts[lang][template],
        promptName,
        levels
      );
    });
  });

  if (stringID !== null && !stringID.match("_modified")) {
    Object.keys(newLanguagePrompts).forEach(lang => {
      Object.keys(newLanguagePrompts[lang]).forEach(template => {
        let modify = false;
        let severity = -1;
        newLanguagePrompts[lang][template].forEach(prompt => {
          if (prompt.id === stringID) {
            modify = true;
            severity = prompt.severity;
            prompt.id = prompt.id + "_modified";
          }
          if (
            modify &&
            !prompt.id.match("_modified") &&
            prompt.severity === severity
          ) {
            prompt.id = prompt.id + "_modified";
          }
        });
      });
    });
  }

  const newData = updateData(newLanguagePrompts, appState, appStringTable);

  return newData;
};

const updateData = (langPrompts, appData, appStringTable) => {
  const modifiedLangPrompts = langPrompts;
  const modifiedStack = [];

  Object.keys(modifiedLangPrompts).forEach(lang => {
    modifiedLangPrompts[lang].successTemplates.forEach(prompt => {
      if (!modifiedStack.includes(prompt.id) && !prompt.id.match("_modified")) {
        if (appStringTable[prompt.id]) {
          if (prompt.value !== appStringTable[prompt.id][lang]) {
            modifiedStack.push(prompt.id);
          }
        } else {
          modifiedStack.push(prompt.id);
        }
      }
    });
  });

  Object.keys(modifiedLangPrompts).forEach(lang => {
    modifiedLangPrompts[lang].successTemplates.forEach(prompt => {
      if (modifiedStack.includes(prompt.id)) {
        prompt.id = prompt.id + "_modified";
      }
    });
  });

  const newAppState = updateAppState(appData, modifiedLangPrompts);
  const newStringTable = updateStringTable(appStringTable, modifiedLangPrompts);

  return { newAppState, newStringTable };
};

export const deleteAppPromptState = (
  appStringTable,
  languagePrompts,
  templateType,
  promptId,
  lang
) => {
  const newStringTable = appStringTable;
  const stringId = languagePrompts[lang][templateType][promptId].id;

  if (stringId.match("_modified")) {
    if (Object.keys(newStringTable[stringId]).length > 1) {
      delete newStringTable[stringId][lang];
    } else {
      delete newStringTable[stringId];
    }
  }

  return { hasStringID: stringId, newStringTable };
};

export const getCurrentSeverityLevels = prompts => {
  let severityLevels = [];
  prompts.forEach((item, id) => {
    if (item.value.trim() !== "") {
      severityLevels = [...new Set([...severityLevels, item.severity])];
    }
  });
  return severityLevels;
};

export const getAllSeverityLevels = prompts => {
  let severityLevels = [];

  Langs.forEach(item => {
    prompts[item.code].successTemplates.forEach(prompt => {
      severityLevels = [...new Set([...severityLevels, prompt.severity])];
    });
  });
  return severityLevels;
};

export const removeSeverityLevelsPrompts = (
  languagePrompts,
  stringTable,
  newLevels
) => {
  const newLanguagePrompts = languagePrompts;
  const newStringTable = stringTable;
  Langs.forEach(item => {
    const newPrompts = newLanguagePrompts[item.code].successTemplates.filter(
      prompt => {
        if (newLevels.includes(prompt.severity)) {
          return true;
        } else {
          delete newStringTable[prompt.id];
          return false;
        }
      }
    );
    newLanguagePrompts[item.code].successTemplates = newPrompts;
  });

  return { newLanguagePrompts, newStringTable };
};
