// import Intent from "../../app_home/app_home.js";
import {
  AssistantsConfigsHome,
  AssistantsTemplatesHome,
  AssistantsDomainsHome,
  OrganizationsHome,
  AssistantUpgraderHome,
} from "../../home/index";
import { AssistantConfigEdit } from "../../app_home/AssistantConfigEdit";
import Help from "../../help/index";
import User from "../../user/index";
import EditIntents from "../../app_home/intent_home";
import NotFound from "../../404.js";
import asyncComponent from "../AsyncComponent";
import Template from "../../app_home/Template";

import { GetSetUserRoles } from "../../../utils/auth/handleAuthState";

const Analytics = asyncComponent(() => import("../../analytics/index"));

const defaultRoutesList = [
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "/*",
    component: NotFound,
  },
];
export const routesAdmin = [
  {
    path: "/home",
    component: AssistantsTemplatesHome,
  },
  // {
  //   path: "/home/assistant-upgrader",
  //   component: AssistantUpgraderHome
  // },
  {
    path: "/home/assistants",
    component: AssistantsConfigsHome,
  },
  {
    path: "/home/assistants/:appname",
    component: AssistantConfigEdit,
  },
  {
    path: "/home/assistant_templates",
    component: AssistantsTemplatesHome,
  },
  {
    path: "/home/assistant_domains",
    component: AssistantsDomainsHome,
  },
  {
    path: "/home/organizations",
    component: OrganizationsHome,
  },
  {
    path: "/home/assistant_templates/:appname/analytics",
    component: Analytics,
  },
  {
    path: "/home/assistant_templates/:templateID",
    component: Template,
  },
  {
    path: "/home/assistant_templates/:appname/:intent",
    component: EditIntents,
  },
  {
    path: "/home/help",
    component: Help,
  },
  {
    path: "/home/user",
    component: User,
  },
  ...defaultRoutesList,
];
export const routesUser = [
  {
    path: "/home/assistants",
    component: AssistantsConfigsHome,
  },
  {
    path: "/home",
    component: AssistantsConfigsHome,
  },
  {
    path: "/home/assistants/:appname/help",
    component: Help,
  },
  {
    path: "/home/assistants/:appname/user",
    component: User,
  },
  {
    path: "/home/assistants/:appname/analytics",
    component: Analytics,
  },
  {
    path: "/home/assistants/:appname",
    component: AssistantConfigEdit,
  },
  {
    path: "/home/assistants/:appname/:intent",
    component: EditIntents,
  },
  {
    path: "/home/help",
    component: Help,
  },
  {
    path: "/home/user",
    component: User,
  },
  ...defaultRoutesList,
];

const getUserRole = () => {
  const getRoles = GetSetUserRoles();
  return getRoles ? getRoles[getRoles.length - 1] : "visitor";
};

export const RouteCreator = () => {
  const role = getUserRole();
  switch (role) {
    case "Slang Admin":
      return routesAdmin;
    case "Customer Admin":
      return routesUser;
    case "visitor":
      return defaultRoutesList;
    default:
      return defaultRoutesList;
  }
};

export const SideNavRoutes = () => {
  const role = getUserRole();
  switch (role) {
    case "Slang Admin":
      return "/home/assistant_templates/";
    case "Customer Admin":
      return "/home/assistants/";
    case "visitor":
      return "/home/";
    default:
      return "/home/";
  }
};

export const getSideNavShortcuts = () => {
  return [
    // {
    //   link: "/home/assistant-upgrader",
    //   title: "Upgrader",
    //   icon: "upgrade"
    // },
    {
      link: "/home/organizations",
      title: "Organizations",
      icon: "corporate_fare",
    },
    { link: "/home/assistant_domains", title: "Domain", icon: "public" },
    {
      link: "/home/assistant_templates",
      title: "Template",
      icon: "assignment",
    },
  ];
};
