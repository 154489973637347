import theme from "../theme";
import { commonStyles } from "../appStyles";
import grey from "@material-ui/core/colors/grey";
import Color from "color";

const DarkMode = localStorage.getItem("U_DARKMODE")
  ? localStorage.getItem("U_DARKMODE") === "true"
  : false;
const bColor = DarkMode ? grey[700] : grey[200];

const styles = () => ({
  ...commonStyles,
  dialog: {
    paddingTop: theme.spacing(20),
  },
  logo: {
    height: "auto",
    width: 80,
  },
  paper: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0),
    textAlign: "left",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(0),
  },
  space: {
    padding: theme.spacing(3),
  },
  space2: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  spaceTextMid: {
    padding: theme.spacing(3),
    textAlign: "center",
  },
  media: {
    height: 200,
    width: "100%",
    // paddingTop: '56.25%', // 16:9
  },
  icon: {
    fontSize: 15,
  },
  increaseLimitButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    marginRight: 10,
    width: 150,
  },
  hideShowButton: {
    // color: theme.palette.primary.light,
    // backgroundColor: theme.palette.primary.medium,
    marginRight: 10,
    width: 150,
  },
  alignTextRight: {
    textAlign: "right",
  },
  usage: {
    marginBottom: "1em",
    maxWidth: "15em",
    color: theme.palette.primary.medium,
    backgroundColor: theme.palette.misc.barLight,
  },
  beta: {
    display: "inline-block",
    fontFamily: "Lato",
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "left",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    textAlign: "left",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  iconFeature: {
    color: theme.palette.primary.primary,
  },
  selectedPlan: {
    padding: theme.spacing(3),
    backgroundColor: Color(theme.palette.primary.main).fade(0.9).string(),
    borderRadius: 10,
    textAlign: "left",
  },
  unselectedPlan: {
    padding: theme.spacing(3),
    backgroundColor: Color(bColor).fade(0.3).string(),
    borderRadius: 10,
    marginBottom: 10,
  },
  featureText: {
    fontSize: 15,
  },
  featureItem: {
    padding: 3,
  },
  loadingPlan: {
    zIndex: 1000,
    top: 140,
    width: "100%",
    minHeight: "350px",
    height: "55%",
    padding: "10%",
    backgroundColor: theme.overrides.MuiBackdrop.root.backgroundColor,
    position: "absolute",
    textAlign: "center",
  },
  loadingProfile: {
    zIndex: 1000,
    top: 60,
    width: "100%",
    minHeight: "290px",
    height: "55%",
    paddingTop: "10%",
    backgroundColor: theme.overrides.MuiBackdrop.root.backgroundColor,
    position: "absolute",
    textAlign: "center",
  },
  Accordion: {
    width: "100%",
    marginBottom: 10,
  },
  summaryExpand: {
    margin: 0,
    marginBottom: 8,
  },
  expanded: {},
});

export { styles };
