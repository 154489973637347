import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  SlangDarkBackground: {
    width: 325,
    height: 500,
    margin: "0",
    padding: "0",
    position: "absolute",
    bottom: "-1rem",
    left: "0",
    background: "rgba(0,0,0,0.4)",
    zIndex: -1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  SlangOnboardingContainer: {
    width: 300,
    height: 240,
    margin: "0 1rem",
    background: "white",
    zIndex: 2147483647,
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-around",
    flexFlow: "column",
    color: "#000",
  },
  SlangOnboardingButtons: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-around",
    alignItems: "space-around",
  },
  SlangSeparator: {
    border: 0,
    height: "1px",
    width: "90%",
    background: "rgba(0,0,0,0.2)",
    filter: "invert(.6)",
    margin: "0 auto",
  },
  SlangEnableButton: {
    color: props => props.brandColor,
    cursor: "pointer",
    fontSize: "1.2rem",
    padding: "5px 0",
  },
  SlangLaterButton: {
    cursor: "pointer",
    fontSize: "1.2rem",
    color: "rgba(0,0,0,0.5)",
    padding: "5px 0",
  },
  SlangOnboardingMessage: {
    textAlign: "center",
    paddingTop: "1rem",
    paddingRight: "2rem",
    paddingBottom: "5px",
    paddingLeft: "2rem",
    fontSize: "32px",
  },
  SlangOnboardingInstruction: {
    textAlign: "center",
    fontWeight: "200",
    paddingTop: "5px",
    paddingRight: "2rem",
    paddingBottom: "0",
    paddingLeft: "2rem",
    fontSize: "18px",
    lineHeight: 1,
  },
}));

const SlangOnboardingDialog = props => {
  const { onboardingIntroTitle, onboardingIntroDescription } = props;
  const classes = styles(props);
  return (
    <div id="slang-onboarding-dialog" className={classes.SlangDarkBackground}>
      <div className={classes.SlangOnboardingContainer}>
        <div>
          <div className={classes.SlangOnboardingMessage}>
            <span>
              {onboardingIntroTitle || "A word is worth a thousand clicks."}
            </span>
          </div>
          <div className={classes.SlangOnboardingInstruction}>
            <span>
              {onboardingIntroDescription ||
                "You can now just use your voice to get things done."}{" "}
            </span>
          </div>
        </div>
        <div>
          <hr className={classes.SlangSeparator} />
          <div className={classes.SlangOnboardingButtons}>
            <div className={classes.SlangLaterButton}>
              <b>Later</b>
            </div>
            <div className={classes.SlangEnableButton}>
              <b>Try now ⟶</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlangOnboardingDialog;
