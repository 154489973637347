import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "./withTheme";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { commonStyles } from "./appStyles";
import SideNav from "./common/sideNav";
import { ReactNode } from "react";

// interface NotFoundProps {
//   classes: { [key: string]: any },
//   match?: any,
//   isLoggedin?: boolean
// }

const NotFound = ({ classes, match, isLoggedin }) => {
  const URL = match.url;
  const isHomeRoute = URL.match("/home");

  if (isHomeRoute && !isLoggedin) {
    return (
      <Redirect
        to={{
          pathname: "/logout",
          state: { from: URL },
        }}
      />
    );
  }
  return (
    <div className={classes.appFrame}>
      {isLoggedin ? (
        <SideNav
          {...{
            location: "404",
            appname: match.params.appname,
          }}
        />
      ) : (
        ""
      )}
      <main className={classes.fourOhFour}>
        {/* <TopNav /> */}
        <div className={classes.margin}>
          <Grid
            justify="flex-start"
            direction="row"
            alignItems="flex-start"
            container
          >
            <Grid xs={12} item>
              <Typography variant="h4">
                &#60; 404 NOT FOUND &#47;&#62;
              </Typography>
              <br />
              <br />
              <img src={"/images/404.png"} alt="Bad 404 Robot" width="400" />

              <br />
              <br />
              <br />
              <Typography variant="button">
                We couldn't find what you were looking for
              </Typography>

              <br />
              <br />
              <a href="/"> Go Back Home </a>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Paper className={classes.paper} />
          </Grid>
        </div>
      </main>
    </div>
  );
};

export default withTheme(withStyles(commonStyles)(NotFound));
