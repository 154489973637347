import { Component } from "react";
import PropTypes from "prop-types";
import { styles } from "./styles.js";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "../withTheme";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import Layout from "../common/layouts/defaultLayout";

import { connect } from "react-redux";
import { getAPI, createAPI, resetErrorAPI } from "../../redux/actions/apikey";
import {
  getApps,
  createApp,
  cloneApp,
  deleteApp,
  clearAppDATA,
  deleteAppList,
  appNameChange,
  resetErrorSchema,
} from "../../redux/actions/appSchema";
import { createUser } from "../../redux/actions/user";

import {
  validateAppName,
  namespace_name_regex_reverse,
} from "../../utils/validate";
import ConfirmDialog from "./components/ConfirmDialog";
import WelcomeDialog from "./components/WelcomeDialog";
import CreateBuddyDialog from "./components/CreateBuddyDialog";
import CloneBuddyDialog from "./components/CloneBuddyDialog";
import BuddyCards from "./components/BuddyCards";
import NewBuddyCard from "./components/NewBuddyCard";
import {
  logPageVisited,
  logEvent,
  SlangEvents,
  logError,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";
import { testClassNames } from "../../utils/integrationTestClassNames";
import AppConfig from "../../app.config";
import Can from "../../utils/rbac/Can";
import AllPermissions from "../../utils/rbac/rbac-rules";
const SlangStorage = require("../../libs/utils/slang_storage");

let newUser = false;
const themeType =
  localStorage.getItem("U_DARKMODE") &&
  localStorage.getItem("U_DARKMODE") === "true"
    ? "dark"
    : "light";
const openWelcomeFlag = () =>
  !(
    localStorage.getItem("I_WELCOME") &&
    localStorage.getItem("I_WELCOME") === "DONE"
  );

const mapDispatchToProps = dispatch => ({
  createApp: payload => dispatch(createApp(payload)),
  cloneApp: payload => dispatch(cloneApp(payload)),
  deleteApp: payload => dispatch(deleteApp(payload)),
  createAPI: () => dispatch(createAPI()),
  createUser: payload => dispatch(createUser(payload)),
  getAPI: () => {
    dispatch(getAPI());
  },
  getApps: () => dispatch(getApps()),
  resetErrorSchema: () => dispatch(resetErrorSchema()),
  resetErrorAPI: () => dispatch(resetErrorAPI()),
  refreshAppList: () => {
    dispatch(deleteAppList());
    setTimeout(() => {
      dispatch(getApps());
      dispatch(appNameChange({ hasNameChanged: false }));
    }, 200);
  },
  clearAppDATA: () => dispatch(clearAppDATA()),
  initLoad: () => {
    dispatch(getAPI());
    setTimeout(() => {
      dispatch(getApps());
    }, 5000);
  },
});

const sortList = List => {
  List.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });

  return List;
};

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDelete: false,
      openWelcome: false,
      openCreate: false,
      openClone: false,
      isNewUser: false,
      dialogTitle: "",
      dialogContent: "",
      createAppName: "",
      isPlayground: true,
      creatingPlayground: false,
      createError: null,
      deleteID: null,
      deleteName: null,
      cloneName: null,
      cloneID: null,
      appList: this.props.appList ? sortList(this.props.appList) : [],
      isLoading: false,
      isCreatingOrDeleting: false,
      notify: {
        variant: "info",
        message: "",
      },
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  checkError = new RegExp("404");

  handleClose = () => {
    this.setState({
      openSnackbar: false,
    });
  };

  handleDailogOpen = () => {
    this.setState({
      openWelcome: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      openWelcome: false,
    });
    localStorage.setItem("I_WELCOME", "DONE");
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleDeleteClick = (appId, appName) => {
    this.setState({
      openDelete: true,
      dialogTitle:
        "This is an irreversible action. Are you sure you want to DELETE the buddy " +
        appName +
        " ?",
      deleteName: appName,
      deleteID: appId,
    });
  };

  handleCloneClick = (appId, appName) => {
    this.setState({
      openClone: true,
      cloneName: `${appName} copy`,
      cloneID: appId,
    });
  };

  handleConfirmDelete = event => {
    event.preventDefault();
    const deleteAppID = this.state.deleteID;
    const deleteAppName = this.state.deleteName;

    if (deleteAppID !== "") {
      this.setState({
        openDelete: false,
        notify: {
          variant: "info",
          message: "Deleting Buddy: " + deleteAppName,
        },
        openCreate: false,
        isCreatingOrDeleting: true,
      });

      this.props.deleteApp({
        deleteAppID,
      });
      SlangStorage.putItem("app_id", deleteAppID, "analytics", false);
      SlangStorage.putItem("app_name", deleteAppName, "analytics", false);
    }
  };

  handleCreateClick = event => {
    this.setState({
      openCreate: true,
      createAppName: "",
    });
  };

  handleCreateAPI = () => {
    this.props.createAPI();
    this.setState({
      isLoading: true,
      isCreatingOrDeleting: true,
    });
  };

  handleCreateNewApp = event => {
    event.preventDefault();
    if (this.state.createAppName !== "") {
      const app_name = this.state.createAppName;
      this.setState({
        notify: {
          variant: "info",
          message:
            "Creating Buddy " +
            app_name +
            ". In a few seconds it will show up below.",
        },
        openDelete: false,
        openCreate: false,
        isCreatingOrDeleting: true,
      });

      this.props.createApp({
        createAppName: app_name,
      });
    }
  };

  handleCloneApp = event => {
    event.preventDefault();
    if (this.state.cloneName !== "") {
      const { cloneName, cloneID } = this.state;
      this.setState({
        notify: {
          variant: "info",
          message:
            "Cloning Buddy " +
            cloneName +
            ". In a few seconds it will show up below.",
        },
        openClone: false,
        isCreatingOrDeleting: true,
      });

      this.props.cloneApp({
        createAppName: cloneName,
        appID: cloneID,
      });
    }
  };

  handleEditClick = event => {
    this.setState({
      openDelete: true,
      dialogTitle: "Edit:",
      dialogContent: event.target.id.substring(0, event.target.id.length - 1),
    });
  };

  handleClose = () => {
    this.setState({
      openDelete: false,
      openCreate: false,
      openClone: false,
    });
  };

  handleChangeAppName = event => {
    const name = event.target.name;
    const text = event.target.value
      .replace(namespace_name_regex_reverse, "")
      .replace(/(\.\.)+/g, ".");
    const valid = validateAppName(text, this.props.appList);
    this.setState({
      [name]: text,
      createError: !valid,
    });
  };

  getNewAppList = () => {
    this.props.getApps();
    setTimeout(() => {
      this.setState({
        isLoading: true,
      });
    }, 0);
  };

  componentDidMount() {
    SlangStorage.removeItem("app_id", "analytics");
    SlangStorage.removeItem("app_name", "analytics");
    this.props.clearAppDATA();

    if (this.props.apikey !== "") {
      if (typeof this.props.appList !== "undefined") {
        if (this.props.hasNameChanged && this.props.appList.length) {
          this.props.refreshAppList();
          this.setState({
            notify: {
              variant: "info",
              message: "Loading ...",
            },
            isLoading: true,
          });
        }
      }
      this.getNewAppList();
    } else {
      this.props.initLoad();
      if (this.props.errorAPI && this.props.errorAPI !== null) {
        console.log(this.props.errorAPI);
        this.props.resetErrorAPI();
      } else {
        this.setState({
          notify: {
            variant: "info",
            message: "Getting API key and initializing console.",
          },
          isLoading: true,
          openWelcome: openWelcomeFlag(),
        });
      }
    }

    logPageVisited("console_home");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (prevProps.appList !== this.props.appList && this.props.appList) {
        if (this.props.appList.length) {
          this.setState({
            appList: sortList(this.props.appList),
            isLoading: false,
            isCreatingOrDeleting: false,
          });
          if (
            this.props.appList.filter(app => app.name === AppConfig.demoAppName)
              .length &&
            this.state.newUser
          ) {
            this.setState({
              newUser: false,
              isLoading: false,
              isCreatingOrDeleting: false,
            });
          } else if (
            this.props.appList.filter(app => app.name === AppConfig.demoAppName)
              .length === 0 &&
            this.state.newUser
          ) {
            this.setState({
              isLoading: true,
              isCreatingOrDeleting: true,
            });
            this.props.createApp({
              createAppName: AppConfig.demoAppName,
              withTemplate: AppConfig.demoAppTemplate,
            });
          }
        } else if (!this.state.newUser) {
          this.setState({
            appList: [],
            isLoading: false,
            isCreatingOrDeleting: false,
          });
        }
      }

      if (prevProps.apikey !== this.props.apikey && this.props.apikey !== "") {
        this.props.getApps();
        this.setState({
          notify: {
            variant: "info",
            message: "Loading ...",
          },
          isLoading: true,
        });
      }

      if (
        prevProps.apikey !== this.props.apikey &&
        this.props.apikey !== "" &&
        this.state.isNewUser
      ) {
        this.setState({
          notify: {
            variant: "info",
            message: `Creating a demo buddy ${AppConfig.demoAppName}...`,
          },
          isCreatingOrDeleting: true,
        });
        this.props.createUser({ email_id: this.props.emailid });
      }

      if (
        prevProps.errorAPI !== this.props.errorAPI &&
        this.props.errorAPI !== null
      ) {
        if (this.checkError.test(this.props.errorAPI)) {
          newUser = true;
          this.handleCreateAPI();
          this.setState({
            openWelcome: true,
            isLoading: true,
            isNewUser: true,
            isCreatingOrDeleting: false,
          });
          logEvent(SlangSeverityLevels.INFO, SlangEvents.APIKEY_CREATED);
        }
        this.props.resetErrorAPI();
      } else if (
        this.props.apikey === "" &&
        !this.state.isLoading &&
        !this.state.isNewUser
      ) {
        if (!this.checkError.test(this.props.errorAPI)) {
          this.setState({
            notify: {
              variant: "warning",
              message: "Couldn't get API key",
            },
            isLoading: false,
            isCreatingOrDeleting: false,
          });
        }
      }

      if (
        prevProps.errorList !== this.props.errorList &&
        this.props.errorList !== null
      ) {
        if (!newUser) {
          this.setState({
            isLoading: false,
            isCreatingOrDeleting: false,
            notify: {
              variant: "error",
              message: "There was an error handling this request",
            },
          });
        } else {
          this.setState({
            isLoading: false,
            isCreatingOrDeleting: false,
          });
          newUser = false;
        }

        logError(this.props.errorList, { page: "console_home" });
        this.props.resetErrorSchema();
      }

      if (!this.state.isLoading) {
        if (typeof this.props.appList !== "undefined") {
          if (this.props.appList.length) {
            this.setState({
              isLoading: false,
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        }
      }
    }
  }

  render() {
    const { classes, apikey } = this.props;

    const {
      openDelete,
      dialogTitle,
      openCreate,
      notify,
      createAppName,
      createError,
      openWelcome,
      openClone,
      isLoading,
      isCreatingOrDeleting,
      appList,
      cloneName,
      cloneID,
    } = this.state;

    const {
      handleClose,
      handleConfirmDelete,
      handleDialogClose,
      handleCreateNewApp,
      handleChangeAppName,
      handleDeleteClick,
      handleCreateClick,
      handleCloneClick,
      handleCloneApp,
    } = this;

    const confirmDeleteProps = {
      classes,
      handleClose,
      handleConfirmDelete,
    };
    const confirmDeleteState = {
      dialogTitle,
      openDelete,
    };
    const welcomeDialogProps = {
      classes,
      handleDialogClose,
    };
    const welcomeDialogState = {
      openWelcome,
    };
    const createBuddyDialogProps = {
      classes,
      handleClose,
      handleCreateNewApp,
      handleChangeAppName,
    };
    const createBuddyDialogState = {
      openCreate,
      createAppName,
      createError,
      apikey,
    };
    const cloneBuddyDialogProps = {
      classes,
      handleClose,
      handleCloneApp,
      handleChangeAppName,
    };
    const cloneBuddyDialogState = {
      openClone,
      cloneName,
      cloneID,
      createError,
      apikey,
    };
    const buddyCardsProps = {
      classes,
      handleDeleteClick,
      handleCloneClick,
    };
    const buddyCardsState = {
      apikey,
      isCreatingOrDeleting,
      appList,
    };
    const newBuddyCardProps = {
      classes,
      handleCreateClick,
    };
    const newBuddyCardState = {
      apikey,
      isCreatingOrDeleting,
    };

    const Image = appList.filter(item => item.name !== AppConfig.demoAppName)
      .length
      ? "basecamp"
      : "astronaut";
    const backgroundImage = `url("/images/draw_${Image}_${themeType}.png")`;

    const allowCreateBuddy = Can({
      perform: AllPermissions.ASSISTANT_TEMPLATES.CREATE,
      yes: () => true,
      no: () => null,
    });

    return (
      <Layout notify={notify} backgroundImage={backgroundImage}>
        <div>
          <div style={{ height: 8, width: "100%" }}>
            {(isLoading || isCreatingOrDeleting) && (
              <LinearProgress className={`${testClassNames.PROGRESS_BAR}`} />
            )}
          </div>
          <br />
          <Grid container spacing={1}>
            {allowCreateBuddy && (
              <Grid item xs={12} md={3} sm={4} lg={2} xl={1}>
                <NewBuddyCard
                  {...newBuddyCardProps}
                  state={newBuddyCardState}
                />
              </Grid>
            )}
            <BuddyCards {...buddyCardsProps} state={buddyCardsState} />
          </Grid>
          <ConfirmDialog {...confirmDeleteProps} state={confirmDeleteState} />
          <WelcomeDialog {...welcomeDialogProps} state={welcomeDialogState} />
          <CreateBuddyDialog
            {...createBuddyDialogProps}
            state={createBuddyDialogState}
          />
          <CloneBuddyDialog
            {...cloneBuddyDialogProps}
            state={cloneBuddyDialogState}
          />
        </div>
      </Layout>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loginId: state.login.identity,
    isAuth: state.login.isAuth,
    appList: state.appSchema.list,
    hasNameChanged: state.appSchema.hasNameChanged,
    apikey: state.apikey.api_key,
    errorAPI: state.apikey.error,
    errorList: state.appSchema.error,
    emailid: state.login.username,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(Home)));
