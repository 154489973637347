import { makeStyles } from "@material-ui/core";
import SlangListeningAnimation from "./SlangListeningAnimation";
import { useEffect, useState } from "react";

const styles = makeStyles(theme => ({
  SlangAnimationContainerListening: {
    bottom: -14,
    left: -10,
    position: "relative",
    overflow: "hidden",
    height: "30px",
    width: "110%",
  },
}));

const SlangSurfaceAnimation = props => {
  const { brandColor } = props;

  const [waveColors, setWaveColors] = useState([
    "#D0F0C0",
    "#139a7a",
    "#009150",
    "#00853E",
  ]);

  useEffect(() => {
    setWaveColors([brandColor, brandColor, brandColor, brandColor]);
  }, [brandColor]);

  const classes = styles();

  return (
    <div className={classes.SlangAnimationContainerListening}>
      {<SlangListeningAnimation waveColors={waveColors} />}
    </div>
  );
};

export default SlangSurfaceAnimation;
