import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
  updateAnalyticsProps,
} from "../../libs/analytics/slangAnalyticsAPIs";
import * as types from "../types";

export const initialState = () => ({
  username: localStorage.getItem("U_username")
    ? localStorage.getItem("U_username")
    : "",
  password: "",
  promocode: "",
  confirmationCode: "",
  identity: localStorage.getItem("U_identity")
    ? localStorage.getItem("U_identity")
    : "",
  error: null,
  isAuth: !!localStorage.getItem("U_identity"),
  isConfirm: false,
  isChanged: false,
  newPassword: "",
  oldPassword: "",
});

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.SIGNIN:
      // Setting user information in local storage from auth0 signInPayload
      localStorage.setItem("U_identity", action.payload.identity);
      localStorage.setItem("U_username", action.payload.username);
      localStorage.setItem("U_orgID", action.payload.org_id);

      // Updating the analytics event metadata with current user information
      updateAnalyticsProps({
        user_email: action.payload.username,
        user_id: action.payload.identity,
        org_id: action.payload.org_id,
      });

      logEvent(SlangSeverityLevels.INFO, SlangEvents.SIGNIN_SUCCESS, {
        user_email: action.payload.username,
        user_id: action.payload.identity,
        org_id: action.payload.org_id,
      });
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
        identity: action.payload.identity,
        error: action.payload.error,
        isAuth: action.payload.isAuth,
      };

    case types.SIGNUP:
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
        confirmationCode: action.payload.confirmationCode,
        promocode: action.payload.promocode,
        error: action.payload.error,
        isConfirm: action.payload.isConfirm,
      };

    case types.SIGNOUT:
      return {
        ...state,
        ...initialState(),
        error: action.payload.error,
      };

    case types.CONFIRMSIGNUP:
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
        confirmationCode: action.payload.confirmationCode,
        isAuth: action.payload.isAuth,
        error: action.payload.error,
      };

    case types.FORGOT:
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
        confirmationCode: action.payload.confirmationCode,
        isConfirm: action.payload.isConfirm,
        isAuth: action.payload.isAuth,
        error: action.payload.error,
      };

    case types.CHANGE_PASSWORD:
      return {
        ...state,
        newPassword: action.payload.password,
        oldPassword: action.payload.password,
        isChanged: action.payload.isChanged,
        error: action.payload.error,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case types.IS_USER_LOGGED_IN:
      return {
        ...state,
        isAuth: action.payload.isAuth,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default reducer;
