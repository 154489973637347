import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  displayName: {
    fontSize: theme.typography.pxToRem(25),
    cursor: "text",
    color: theme.palette.text.secondary,
    display: "inline-flex",
    alignItems: "center",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    cursor: "text",
    color: theme.palette.text.secondary,
    display: "inline-flex",
    alignItems: "center",
    marginLeft: 20,
  },
  grow: {
    flexGrow: 1,
  },
}));

function EditSubDomain(props) {
  const { subDomainID, subDomain, handleEditAppData, subdomains } = props;

  const classes = useStyles();
  const [name, setName] = useState(subDomain.name);
  const [displayName, setDisplayName] = useState(subDomain.displayName);
  const [description, setDescription] = useState(subDomain.description);
  const [selection, setSelection] = useState(null);
  const [selectError, setSelectError] = useState(null);

  const handleBlur = event => {
    let shouldUpdate = false;

    switch (event.target.name) {
      case "displayName":
        if (subDomain[event.target.name] !== displayName) shouldUpdate = true;
        subDomain[event.target.name] = displayName;
        subdomains[subDomainID] = subDomain;
        break;
      case "name":
        if (subDomain[event.target.name] !== name) shouldUpdate = true;
        if (selectError) {
          shouldUpdate = false;
          return;
        }
        subDomain[event.target.name] = name;
        subdomains[subDomainID] = subDomain;
        break;
      case "description":
        if (subDomain[event.target.name] !== description) shouldUpdate = true;

        subDomain[event.target.name] = description;
        subdomains[subDomainID] = subDomain;
        break;

      default:
        break;
    }
    if (shouldUpdate) handleEditAppData(subdomains, "subDomains");
    setSelection(null);
    setSelectError(null);
  };

  const handleChange = event => {
    const text = event.target.value;
    if (text.length < 140) {
      switch (event.target.name) {
        case "displayName":
          setDisplayName(text);
          break;
        case "name":
          const currentName = text.replace(/ /g, "_");
          const doesNameExist = subdomains.find(
            (SD, ID) => SD.name === currentName && ID !== subDomainID
          );

          if (doesNameExist) {
            setSelectError("Sub Domain name exists");
          } else if (text.length < 3) {
            setSelectError("Should be greater than 2 chars");
          } else {
            setSelectError(null);
          }
          setName(currentName);
          break;
        case "description":
          setDescription(event.target.value);
          break;

        default:
          break;
      }
    }
    if (text.length < 3) {
      setSelectError("Should be greater than 2 chars");
    }
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handleBlur(event);
      event.preventDefault();
    } else if (event.keyCode === 27) {
      setSelection(null);
      setSelectError(null);
      event.preventDefault();
    }
  };
  const handleKeyPressText = event => {
    if (event.key === "Enter") {
      const fieldName = event.target.attributes.name.value;
      setSelection(fieldName);
      event.preventDefault();
    }
  };
  return (
    <>
      {selection === "displayName" ? (
        <TextField
          id="info-displayName"
          label="Display Name"
          name="displayName"
          value={displayName}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          autoFocus
        />
      ) : (
        <Typography
          onDoubleClick={() => {
            setSelection("displayName");
          }}
          name="displayName"
          title="Double Click to edit."
          onKeyPress={handleKeyPressText}
          tabIndex={0}
          className={classes.displayName}
        >
          {subDomain.displayName}
        </Typography>
      )}
      &nbsp; &nbsp;
      {selection === "name" ? (
        <TextField
          id="info-name"
          label="name"
          name="name"
          value={name}
          error={selectError !== null}
          helperText={selectError}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          autoFocus
        />
      ) : (
        <Typography
          onDoubleClick={() => {
            setSelection("name");
          }}
          name="name"
          title="Double Click to edit."
          onKeyPress={handleKeyPressText}
          tabIndex={0}
          className={classes.secondaryHeading}
        >
          {subDomain.name}
        </Typography>
      )}
      &nbsp; &nbsp;
      {selection === "description" ? (
        <TextField
          id="info-description"
          label="description"
          name="description"
          value={description}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          fullWidth
          autoFocus
        />
      ) : (
        <Typography
          onDoubleClick={() => {
            setSelection("description");
          }}
          name="description"
          title="Double Click to edit."
          onKeyPress={handleKeyPressText}
          tabIndex={0}
          className={`${classes.secondaryHeading} ${classes.grow}`}
        >
          {subDomain.description}
        </Typography>
      )}
    </>
  );
}

EditSubDomain.propTypes = {
  subDomain: PropTypes.object.isRequired,
  subDomainID: PropTypes.number.isRequired,
  handleEditAppData: PropTypes.func.isRequired,
  subdomains: PropTypes.array.isRequired,
};

export default EditSubDomain;
