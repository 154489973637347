import jwt_decode from "jwt-decode";
import { getAllOrganizations } from "../../libs/auth/slang_auth";
import { checkIfUserBelongsToSlangLabs } from "../userUtils";

let Auth_accessTokenRaw;
let AccessTokenPayload;
let IDTokenClaims;
let userPermissions;
let userDetails;
let userRoles;
let appMetadata;

export const GetSetAccessTokenPayload = data => {
  if (data) {
    AccessTokenPayload = data;
    return;
  }

  return AccessTokenPayload;
};

export const GetSetAppMetadata = data => {
  if (data) {
    appMetadata = data;
    if (data.organization_id) {
      localStorage.setItem("U_orgID", data.organization_id);
      getOrgName(data.organization_id).then(orgName =>
        localStorage.setItem("U_orgName", orgName)
      );
    }
    return;
  }
  return appMetadata;
};

export const GetSetAccessTokenRaw = data => {
  if (data) {
    Auth_accessTokenRaw = data;
    return;
  }

  return Auth_accessTokenRaw;
};
const GetSetIDTokenClaims = data => {
  if (data) {
    IDTokenClaims = data;
    return;
  }

  return IDTokenClaims;
};
export const GetSetUserPermissions = data => {
  if (data) {
    userPermissions = data;
    return;
  }

  return userPermissions;
};
const GetSetUserDetails = data => {
  if (data) {
    userDetails = data;
    return;
  }
  return userDetails;
};
export const GetSetUserRoles = data => {
  if (data) {
    userRoles = data;
    return;
  }
  return userRoles;
};

const getOrgName = async orgID => {
  try {
    const data = await getAllOrganizations();
    const orgName = data.organizations.find(
      item => item.organization_id === orgID
    ).organization_name;
    return orgName;
  } catch (error) {
    console.error(error);
  }
};

export const createPayload = user => {
  const sub = user.sub.split("|");
  const identity = sub[0] === "auth0" ? sub[1] : sub[2];
  const org_id = GetSetAppMetadata().organization_id;
  const payload = {
    username: user.name,
    identity,
    isAuth: true,
    error: null,
    org_id,
  };
  return payload;
};

export const isSlangLabsUser = () => {
  try {
    if (userDetails) {
      return checkIfUserBelongsToSlangLabs(userDetails.email);
    }
    return false;
  } catch (error) {
    console.error(error);
  }
};

export const setAuthSession = async ({
  getAccessTokenSilently,
  getIdTokenClaims,
  user,
}) => {
  GetSetUserDetails(user);
  try {
    // Need this for debugging
    // let accessToken = await getAccessTokenSilently({ cache: false });
    const accessToken = await getAccessTokenSilently();
    const idToken = await getIdTokenClaims();
    const decodedToken = jwt_decode(accessToken);
    GetSetAccessTokenPayload(decodedToken);
    GetSetAccessTokenRaw(accessToken);
    GetSetIDTokenClaims(idToken);
    GetSetUserPermissions(decodedToken.permissions);
    GetSetUserRoles(decodedToken["https://slanglabs.in/roles"]);
    GetSetAppMetadata(user["https://slanglabs.in/app_metadata"]);
    // console.log(decodedToken, user)
    return decodedToken;
  } catch (error) {
    console.log(error);

    throw error;
  }
};
