import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Paper, Select, Typography } from "@material-ui/core";
import UtteranceEdit from "./UtteranceEdit";
import AppConfig from "../../../../app.config";

const Langs = AppConfig.LANGUAGES;

const styles = makeStyles(theme => ({
  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

const InputWrapper = props => {
  const {
    env,
    handleChangeEnv,
    lang,
    handleChangeLang,
    sdkUiState,
    utterance,
    setUtterance,
    triggerSlang,
    cancelSlang,
    inferUtterance,
  } = props;

  const UtteranceEditProps = {
    sdkUiState,
    utterance,
    setUtterance,
    triggerSlang,
    cancelSlang,
    inferUtterance,
  };

  const classes = styles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="button" color="inherit">
        Input
      </Typography>
      <br />
      <Typography component="span">
        {" "}
        Environment:{" "}
        <Select value={env} onChange={event => handleChangeEnv(event)}>
          <MenuItem value={"stage"}>Stage</MenuItem>
          <MenuItem value={"prod"}>Prod</MenuItem>
        </Select>{" "}
        &nbsp;&nbsp; Locale:{" "}
        <Select value={lang} onChange={handleChangeLang}>
          {Langs.map((lang, key) => (
            <MenuItem key={key} value={`${lang.code}`}>
              {`${lang.text}`}
            </MenuItem>
          ))}
        </Select>
      </Typography>
      <UtteranceEdit {...UtteranceEditProps} />
      <br />
      <br />
    </Paper>
  );
};

InputWrapper.propTypes = {
  env: PropTypes.string.isRequired,
  handleChangeEnv: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  handleChangeLang: PropTypes.func.isRequired,
  utterance: PropTypes.string.isRequired,
  setUtterance: PropTypes.func.isRequired,
  triggerSlang: PropTypes.func.isRequired,
  cancelSlang: PropTypes.func.isRequired,
  inferUtterance: PropTypes.func.isRequired,
};

export default InputWrapper;
