const checkLoggedInState = () => {
  let hidden = "hidden";
  let currentWindowState = "hidden";
  // Standards:
  if (hidden in document)
    document.addEventListener("visibilitychange", handleOnChange);
  else if ((hidden = "mozHidden") in document)
    document.addEventListener("mozvisibilitychange", handleOnChange);
  else if ((hidden = "webkitHidden") in document)
    document.addEventListener("webkitvisibilitychange", handleOnChange);
  else if ((hidden = "msHidden") in document)
    document.addEventListener("msvisibilitychange", handleOnChange);
  // IE 9 and lower:
  else if ("onfocusin" in document)
    document.onfocusin = document.onfocusout = handleOnChange;
  // All others:
  else
    window.onpageshow =
      window.onpagehide =
      window.onfocus =
      window.onblur =
        handleOnChange;

  // Check is the window current state is hidden then initiate storage change listener
  if (document[hidden]) {
    checkLocalStorageState("hidden");
  }
  // set of possible events to handle
  function handleOnChange(evt) {
    const v = "visible";
    const h = "hidden";
    const evtMap = {
      focus: v,
      focusin: v,
      pageshow: v,
      blur: h,
      focusout: h,
      pagehide: h,
    };

    evt = evt || window.event;
    if (evt.type in evtMap) currentWindowState = evtMap[evt.type];
    else currentWindowState = this[hidden] ? "hidden" : "visible";

    checkLocalStorageState(currentWindowState);
  }
  // Here we initiate the addEventListener for storage and removeEventListener when visible
  function checkLocalStorageState(check) {
    if (check === "hidden")
      window.addEventListener("storage", event => {
        if (event.key === "U_identity") {
          window.location = "/";
        }
      });
    else window.removeEventListener("storage", () => {});
  }
};

export default checkLoggedInState;
