import { Component, useState, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core/styles";
import { main } from "../styles.js";

import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import { testClassNames } from "../../../utils/integrationTestClassNames";

import {
  updateASRHintsState,
  deletePhrase,
  getLangName,
  addStateToSchema,
} from "../../../utils/MiscUtils";
import { cleanPhrases } from "../../../utils/validate";

import { changeAppDATA } from "../../../redux/actions/appSchema";
import LangButton from "./LangButton";
import DocHelpLink from "../../common/DocLinks";
import { checkIfUserBelongsToSlangLabs } from "../../../utils/userUtils";

const mapDispatchToProps = dispatch => ({
  changeAppDATA: appDATA => dispatch(changeAppDATA(appDATA)),
});

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
  containHeight: {
    maxHeight: theme.spacing(50),
    overflow: "auto",
  },
  textField: {
    width: "90%",
  },
  chip: {
    margin: theme.spacing(0.2),
  },
  fab: {
    height: 35,
    width: 35,
    marginLeft: theme.spacing(2),
    verticalAlign: "bottom",
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
});

const InputTextField = props => {
  const { langName, chipType, cleanPhraseData, classes } = props;
  const [addPhrase, setaddPhrase] = useState("");

  const handleTextChange = event => {
    setaddPhrase(event.target.value);
  };
  const updateChips = () => {
    if (cleanPhraseData(chipType, addPhrase)) setaddPhrase("");
  };
  const onKeyPress = event => {
    if (event.key === "Enter") {
      const name = event.target.name;
      updateChips();
      event.preventDefault();
    }
  };
  return (
    <>
      <TextField
        name={chipType}
        label={`Hints in ${langName}`}
        placeholder="e.g. 'chrome browser' or 'chrome, firefox, netscape'"
        className={classes.textField}
        value={addPhrase}
        onChange={handleTextChange}
        onKeyPress={onKeyPress}
        margin="normal"
      />
      <Fab
        size="small"
        aria-label="Add"
        color="default"
        className={classes.fab}
        onClick={() => updateChips()}
        disabled={addPhrase.length === 0}
      >
        <Add />
      </Fab>
    </>
  );
};
const ChipsDisplay = memo(props => {
  const { chipType, chipData, classes, handleDelete } = props;

  return chipData.map((data, key) => (
    <Chip
      key={key + data}
      label={data}
      onDelete={handleDelete(key, chipType)}
      className={classes.chip}
    />
  ));
});
const ChipsArray = props => {
  const { classes, langName, handleDelete, chipType, cleanPhraseData, state } =
    props;
  const { asrHintsAdd, stopWordsAdd, asrHintsChipData, stopWordsChipData } =
    state;
  const { chipData, addPhrase } =
    chipType === "asrHints"
      ? { addPhrase: asrHintsAdd, chipData: asrHintsChipData }
      : { addPhrase: stopWordsAdd, chipData: stopWordsChipData };

  const InputTextFieldProps = { langName, chipType, cleanPhraseData, classes };
  const ChipsDisplayProps = { chipType, chipData, classes, handleDelete };
  return (
    <Paper className={classes.root} elevation={4}>
      <InputTextField {...InputTextFieldProps} />
      <br />
      <br />
      <div
        className={`${classes.containHeight} ${testClassNames.ASR_HINTS_AND_STOP_WORDS_LENGTH}`}
      >
        <ChipsDisplay {...ChipsDisplayProps} />
      </div>
    </Paper>
  );
};

ChipsArray.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ChipsArrayWrap = withStyles(styles)(ChipsArray);

export class AppHomePrompts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "en-IN",
      langName: getLangName("en-IN"),
      addToASR: "",
      asrHintsAdd: "",
      stopWordsAdd: "",
      asrHintsChipData: [],
      stopWordsChipData: [],
      chipData: [],
      open: false,
      error: null,
    };
  }

  updateState = (lang = this.state.lang) => {
    const appData = this.props.appDATA;
    const newAsrHintsChipData = updateASRHintsState(appData, lang, "asrHints");
    const newStopWordsChipData = updateASRHintsState(
      appData,
      lang,
      "stopWords"
    );
    this.setState({
      asrHintsChipData: newAsrHintsChipData,
      stopWordsChipData: newStopWordsChipData,
    });
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appDATA.asrHints !== this.props.appDATA.asrHints) {
      const { lang } = this.state;
      this.updateState(lang);
    }
    if (prevProps.appDATA.stopWords !== this.props.appDATA.stopWords) {
      const { lang } = this.state;
      this.updateState(lang);
    }
  }

  handleLangChange = lang => {
    const langName = getLangName(lang);
    this.setState({
      lang,
      langName,
    });
    this.updateState(lang);
  };

  handleDelete = (key, name) => () => {
    const newChipData = deletePhrase(this.state[name + "ChipData"], key);
    this.handleSaveToSchema(newChipData, name);
  };

  handleTextChange = event => {
    const name = `${event.target.name}Add`;

    if (event.target.value.length < 65)
      this.setState({
        [name]: event.target.value,
      });
  };

  handleSaveToSchema = (chipData, name) => {
    const appData = this.props.appDATA;
    const { lang } = this.state;
    const newAppData = addStateToSchema(appData, lang, name, chipData);
    this.props.changeAppDATA({ appDATA: newAppData });
    return true;
  };

  cleanPhraseData = (name, data) => {
    const textData = data;
    if (textData?.length > 0) {
      const chipData = this.state[`${name}ChipData`];
      const cleanChipData = cleanPhrases(textData);
      const newChipData = [...cleanChipData, ...chipData];
      return this.handleSaveToSchema(newChipData, name);
    }
  };

  render() {
    const { classes, userBelongsToSlangLabs } = this.props;
    const {
      error,
      lang,
      asrHintsAdd,
      stopWordsAdd,
      asrHintsChipData,
      stopWordsChipData,
      langName,
    } = this.state;
    const {
      handleLangChange,
      handleDelete,
      handleTextChange,
      cleanPhraseData,
    } = this;

    const ASRHintsProps = {
      error,
      lang,
      langName,
      handleDelete,
      handleTextChange,
      cleanPhraseData,
      chipType: "asrHints",
    };
    const ASRHintsState = {
      asrHintsAdd,
      asrHintsChipData,
    };
    const StopWordsProps = {
      error,
      lang,
      langName,
      handleDelete,
      handleTextChange,
      cleanPhraseData,
      chipType: "stopWords",
    };
    const StopWordsState = {
      stopWordsAdd,
      stopWordsChipData,
    };
    const langButtonProps = {
      lang,
      handleLangChange,
      classes,
    };
    return (
      <div>
        <Paper className={classes.promptPaper} elevation={0}>
          {/* <Typography variant="h5" noWrap>
            {"ASR Hints "} <DocHelpLink docFor="ASR_HINTS" />
          </Typography> */}
          {/* <br />
          <br /> */}
          <LangButton {...langButtonProps} />
          {/* <br />
          <br />
          <ChipsArrayWrap {...ASRHintsProps} state={ASRHintsState} />
          <br />
          <br /> */}
          {userBelongsToSlangLabs ? (
            <div>
              <br />
              <Typography variant="h5"> Stop Words </Typography>
              <br />
              <ChipsArrayWrap {...StopWordsProps} state={StopWordsState} />
            </div>
          ) : null}
        </Paper>
      </div>
    );
  }
}

AppHomePrompts.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    appDATA: state.appSchema.appDATA,
    userBelongsToSlangLabs: checkIfUserBelongsToSlangLabs(state.login.username),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(main)(AppHomePrompts));
