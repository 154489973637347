import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddSubDomainForm from "./AddSubDomainForm";
import AddDataTypeForm from "./AddDataTypeForm";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    overflow: "hidden",
  },
  closeIcon: { float: "right" },
  button: {
    fontFamily: theme.typography.button,
    padding: theme.spacing(1.3),
  },
}));

function AddDialog(props) {
  const {
    title,
    subdomains,
    subDomainID,
    handleEditAppData,
    label,
    color,
    variant,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveClose = payload => {
    handleEditAppData(payload, "subDomains");
    setOpen(false);
  };

  if (subDomainID !== undefined) {
    return (
      <>
        <IconButton
          color="primary"
          variant="contained"
          edge="start"
          aria-label="Add"
          onClick={handleClickOpen}
          title="Add new data type"
        >
          <AddIcon />
        </IconButton>
        <Dialog
          open={open}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"Add Data Type"}{" "}
            <IconButton className={classes.closeIcon} onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <AddDataTypeForm
              {...{ subdomains, open, subDomainID, handleSaveClose }}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Button
        color={color || "primary"}
        variant={variant || "contained"}
        edge="start"
        aria-label="Close"
        onClick={handleClickOpen}
        title="Add new sub domain"
        className={label ? classes.button : ""}
      >
        <AddIcon /> {label}
      </Button>
      <Dialog
        open={open}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <AddSubDomainForm {...{ subdomains, open, handleSaveClose }} />
        </DialogContent>
      </Dialog>
    </>
  );
}

AddDialog.propTypes = {
  handleEditAppData: PropTypes.func.isRequired,
  subdomains: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subDomainID: PropTypes.number,
};

export default AddDialog;
