import { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Clear";

import DocHelpLink from "../../../common/DocLinks";
import CreatePublishAConfigStepper from "./CreatePublishAConfigStepper";
import { IconButton } from "@material-ui/core";

const CreateBuddyDialog = props => {
  const { classes, handleClose, appList, getNewAppList, state } = props;

  const { openCreate, apikey } = state;
  const [isLoading, setIsLoading] = useState(false);

  const CreatePublishAConfigStepperProps = {
    handleClose,
    isLoading,
    getNewAppList,
    setIsLoading,
    appList,
    apikey,
  };

  return (
    <Dialog
      open={openCreate}
      // onClose={isLoading ? null : handleClose}
      maxWidth="md"
      fullWidth
    >
      <div className={classes.dialogCreate}>
        {isLoading ? null : (
          <IconButton
            onClick={handleClose}
            style={{ top: -15, float: "right" }}
            edge="end"
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogTitle variant="h6" className={classes.textColor}>
          {"Add a New Assistant"} <DocHelpLink docFor="CREATE_ASSISTANT" />
        </DialogTitle>

        <DialogContent>
          <CreatePublishAConfigStepper {...CreatePublishAConfigStepperProps} />
        </DialogContent>
        <DialogActions />
      </div>
    </Dialog>
  );
};

export default CreateBuddyDialog;
