import PropTypes from "prop-types";

import Icon from "@material-ui/core/Icon";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import * as SlangTooltips from "./SlangTooltips";

const contentStyles = theme => ({
  paraText: {
    color: theme.palette.secondary.medium,
  },
  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
  iconDocs: {
    color: theme.palette.secondary.medium,
    fontSize: "1.1rem",
  },
});

function DocHelpLink(props) {
  const { classes, docFor, ...other } = props;
  return (
    <Tooltip
      title={SlangTooltips.DOC_LINKS[docFor].text}
      classes={{ tooltip: classes.tooltip }}
      {...other}
    >
      <a
        className={classes.paraText}
        href={SlangTooltips.DOC_LINKS[docFor].link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {" "}
        <Icon className={classes.iconDocs}>menu_book</Icon>
      </a>
    </Tooltip>
  );
}

DocHelpLink.propTypes = {
  classes: PropTypes.object.isRequired,
  docFor: PropTypes.string.isRequired,
};

export default withStyles(contentStyles)(DocHelpLink);
