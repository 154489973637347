import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Tooltip, Typography, Grid } from "@material-ui/core";

const styles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  middle: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  redButton: {
    color: theme.palette.error.main,
  },
  CoachmarkBackdrop: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.7)",
    opacity: "0.7",
    zIndex: 10,
  },
  Highlight: {
    position: "relative",
    zIndex: 99999,
  },
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    textAlign: "left",
    background: theme.palette.background.default,
  },
  button: {
    backgroundColor: "#F0D765",
    borderRadius: "20px",
    padding: "0.2rem 1rem",
    color: "#181818",
    textTransform: "capitalize",
    fontSize: "0.9rem",
    "&:hover": {
      backgroundColor: "#ba9c12",
    },
  },
  buttonSkip: {
    borderRadius: "20px",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    paddingLeft: "0rem",
    paddingRight: "1rem",
    alignSelf: "flex-end",
    justifyContent: "flex-start",
    textTransform: "capitalize",
    textDecoration: "underline",
    fontSize: "0.8rem",
    color: "white",
    "&:hover": {
      backgroundColor: "transparent",
      color: "white",
      textDecoration: "none",
    },
  },
}));

const Coachmark = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    maxWidth: 420,
    fontSize: "1.3rem",
    border: 0,
    borderRadius: "15px",
    boxShadow: "rgba(18, 18, 18, 0.2) 0px 8px 24px",
    padding: theme.spacing(2),
    zIndex: 999999,
    animationName: "$motion",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    webkitAnimationDuration: "0.5s",
    animationDuration: "0.5s",
    webkitAnimationTimingFunction: "ease-in-out",
    animationTimingFunction: "ease-in-out",
  },
  arrow: {
    fontSize: "1.5rem",
    "&::before": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  "@keyframes motion": {
    from: { transform: "translateX(0.5rem)" },
    to: { transform: "translateX(1rem)" },
  },
}))(Tooltip);

const CoachmarkTooltip = props => {
  const {
    open,
    placement,
    step,
    maxSteps,
    title,
    children,
    primaryAction,
    secondaryAction,
    anchorEl,
    showSteps,
    refAction,
  } = props;

  const [activate, setActivate] = useState(open);
  const [anchorRef, setAnchorRef] = useState(null);

  const classes = styles();

  useEffect(() => {
    setActivate(open);
    setAnchorRef(anchorEl?.current);
  }, [open, step, anchorEl, children]);

  useEffect(() => {
    if (anchorRef) {
      anchorRef.addEventListener("click", event => {
        setActivate(false);
      });
    }
  }, [anchorRef]);

  return anchorRef ? (
    <Coachmark
      open={activate}
      interactive={true}
      onClick={refAction}
      title={
        <div>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography variant="h5" style={{ fontSize: "1.1rem" }}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {!showSteps ? null : (
                <Typography
                  variant="button"
                  component="p"
                  align="right"
                  style={{ color: "#F0D765" }}
                >
                  {`${step} of ${maxSteps}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: secondaryAction
                    ? "space-between"
                    : "flex-end",
                  alignItems: "center",
                }}
              >
                {secondaryAction ? (
                  <Button
                    variant="text"
                    size="small"
                    onClick={secondaryAction?.action}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    className={classes.buttonSkip}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ fontSize: "0.9rem", lineHeight: "0.5rem" }}
                    >
                      {secondaryAction?.label}
                    </Typography>
                  </Button>
                ) : null}
                {primaryAction ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={primaryAction?.action}
                    disableElevation
                    disableRipple
                    className={classes.button}
                  >
                    {primaryAction?.label}
                  </Button>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      }
      arrow
      placement={placement}
      className={classes.Highlight}
    >
      {children}
    </Coachmark>
  ) : (
    children
  );
};

CoachmarkTooltip.propTypes = {
  open: PropTypes.bool.isRequired,
  placement: PropTypes.string.isRequired,
  step: PropTypes.number,
  maxSteps: PropTypes.number,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disableBackdrop: PropTypes.bool,
  primaryAction: PropTypes.object,
  secondaryAction: PropTypes.object,
  anchorEl: PropTypes.object,
  showSteps: PropTypes.bool,
};

export default CoachmarkTooltip;
