import * as types from "../types";

export const initialState = () => ({
  env: "stage",
  error: null,
  schemaDefinition: null,
  isValid: false,
  isSaved: false,
  isProd: false,
  isPublished: false,
  appSchema: null,
  appStringTable: null,
  appID: null,
});

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.GET_SCHEMA_DEFINITION:
      return {
        ...state,
        schemaDefinition: action.payload.schemaDefinition,
        error: action.payload.error,
      };
    case types.VALIDATE_APP_SCHEMA:
      return {
        ...state,
        schemaDefinition: action.payload.schemaDefinition,
        appSchema: action.payload.appSchema,
        appID: action.payload.appID,
        error: action.payload.error,
        isValid: action.payload.isValid,
      };
    case types.UPLOAD_APP_SCHEMA:
      return {
        ...state,
        schemaDefinition: action.payload.schemaDefinition,
        appSchema: action.payload.appSchema,
        appID: action.payload.appID,
        error: action.payload.error,
        isValid: action.payload.isValid,
        isSaved: action.payload.isSaved,
        isPublished: action.payload.isPublished,
      };
    case types.UPLOAD_APP_STRING_TABLE:
      return {
        ...state,
        schemaDefinition: action.payload.schemaDefinition,
        appSchema: action.payload.appSchema,
        appStringTable: action.payload.appStringTable,
        appID: action.payload.appID,
        error: action.payload.error,
        isValid: action.payload.isValid,
        isSaved: action.payload.isSaved,
        isPublished: action.payload.isPublished,
      };
    case types.SAVE_APP_SCHEMA:
      return {
        ...state,
        schemaDefinition: action.payload.schemaDefinition,
        appSchema: action.payload.appSchema,
        appID: action.payload.appID,
        error: action.payload.error,
        isValid: action.payload.isValid,
        isSaved: action.payload.isSaved,
        isPublished: action.payload.isPublished,
      };
    case types.PUBLISH_APP_SCHEMA:
      return {
        ...state,
        schemaDefinition: action.payload.schemaDefinition,
        appSchema: action.payload.appSchema,
        appID: action.payload.appID,
        error: action.payload.error,
        isValid: action.payload.isValid,
        isSaved: action.payload.isSaved,
        isProd: action.payload.isProd,
        isPublished: action.payload.isPublished,
      };
    case types.PUBLISH_START:
      return {
        ...state,
        isPublished: action.payload.isPublished,
      };
    case types.RESET_STATE:
      return initialState();
    default:
      return state;
  }
};

export default reducer;
