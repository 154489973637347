import { useState, useEffect, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { reducer } from "../state/reducers";
import EditUserJourneyDialog from "./EditUserJourneyDialog";
import { FormGroup, LinearProgress, Typography, Chip } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { createUserJourneyAddOnObject } from "../../../../utils/MiscUtils";
import NoCodeActionDialog from "./NoCodeActionDialog";
import TutorialWrapper from "../../../common/TutorialWrapper";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";
const styles = theme => ({
  iconButton: {
    marginRight: 20,
  },
  marginText: {
    marginRight: 25,
    display: "inline",
  },
  addOnListItem: {
    paddingLeft: "3rem",
    background: theme.palette.background.default,
  },
  formgroup: {
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    marginTop: "5px",
  },
  chip1: {
    margin: theme.spacing(1),
    width: "max-content",
  },
});

const addOns = createUserJourneyAddOnObject();

function UserJourneyItems(props) {
  const {
    classes,
    userJourneys,
    handleEditAppData,
    changeUserJourneyData,
    handleSelectError,
    selectError,
  } = props;

  const { domain } = useSelector(state => ({
    domain: state.appSchema.appDATA.domain,
  }));

  const [checked, setChecked] = useState(null);
  const [userJourneysState, dispatch] = useReducer(reducer, userJourneys);

  const noCodeActionRef = useRef(null);
  const editUJRef = useRef(null);

  useEffect(() => {
    let didCancel = false;
    if (userJourneysState && !didCancel) {
      const newChecked = userJourneysState
        ?.filter(UJ => UJ.enabled)
        .map(UJ => UJ.name);
      if (!didCancel) {
        setChecked(newChecked);
      }
    }
    return () => {
      didCancel = true;
    };
  }, [userJourneysState]);

  const handleToggle = name => () => {
    const currentIndex = checked.indexOf(name);
    const newChecked = [...checked];
    let enabled = false;

    if (currentIndex === -1) {
      newChecked.push(name);
      enabled = true;
    } else {
      newChecked.splice(currentIndex, 1);
      enabled = false;
    }
    // off set the global user journey which is always selected
    if (newChecked.length > 1) {
      dispatch({ type: "toggle", payload: { name, enabled } });
      logEvent(SlangSeverityLevels.INFO, SlangEvents.USERJOURNEY_ENABLED, {
        userjourney_name: name,
        userjourney_enabled: enabled,
      });
      handleEditAppData(userJourneysState, "userJourneys");
      setChecked(newChecked);
    } else if (!selectError) {
      handleSelectError("At least one User Journeys must be selected");
      setTimeout(() => {
        handleSelectError(null);
      }, 5000);
    }
  };

  const firstEnabledUserJourney = () =>
    userJourneys.find(item => item.enabled && item.name !== "global");

  return checked ? (
    userJourneysState.map((value, ID) =>
      value.name.toLowerCase() !== "global" ? (
        <div key={ID}>
          <ListItem
            key={value.name}
            role={undefined}
            button
            onClick={handleToggle(value.name)}
            className={classes.listItem}
          >
            <Checkbox
              color="primary"
              checked={value.enabled}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText>
              <Typography variant="body1">
                {`${value.displayName.replace(/_/g, " ")}`}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ maxWidth: 1100 }}
              >{`${value.description}`}</Typography>

              <FormGroup className={classes.formgroup} row>
                {addOns[domain] && addOns[domain][value.name] ? (
                  <>
                    <Typography variant="button" color="textSecondary">
                      Includes
                    </Typography>
                    {addOns[domain][value.name].map((addon, key) => (
                      <Chip
                        key={key}
                        label={`${addon.displayName}`}
                        color={"secondary"}
                        className={classes.chip1}
                        size="small"
                        variant={value.enabled ? "default" : "outlined"}
                        icon={value.enabled ? <Check /> : null}
                      />
                    ))}
                  </>
                ) : null}
              </FormGroup>
            </ListItemText>
            <ListItemSecondaryAction>
              {value.enabled ? (
                <>
                  <TutorialWrapper
                    step={5}
                    anchorEl={
                      firstEnabledUserJourney().name === value.name
                        ? noCodeActionRef
                        : null
                    }
                  >
                    <span>
                      <NoCodeActionDialog
                        appStatesMetadata={value.appStatesMetadata}
                        userJourneyName={value.name}
                        userJourneyID={ID}
                        changeUserJourneyData={changeUserJourneyData}
                        refAnchor={
                          firstEnabledUserJourney().name === value.name
                            ? noCodeActionRef
                            : null
                        }
                      />
                    </span>
                  </TutorialWrapper>
                  <TutorialWrapper
                    step={6}
                    anchorEl={
                      firstEnabledUserJourney().name === value.name
                        ? editUJRef
                        : null
                    }
                  >
                    <span>
                      <EditUserJourneyDialog
                        aria-label="View"
                        appStatesMetadata={value.appStatesMetadata}
                        userJourneyName={value.name}
                        userJourneyID={ID}
                        changeUserJourneyData={changeUserJourneyData}
                        refAnchor={
                          firstEnabledUserJourney().name === value.name
                            ? editUJRef
                            : null
                        }
                      />
                    </span>
                  </TutorialWrapper>
                </>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      ) : null
    )
  ) : (
    <LinearProgress />
  );
}

UserJourneyItems.propTypes = {
  classes: PropTypes.object.isRequired,
  userJourneys: PropTypes.array.isRequired,
};

export default withStyles(styles)(UserJourneyItems);
