import React from "react";
import { Typography, Grid } from "@material-ui/core/";
import QRCode from "qrcode.react";

export default function TryAssistant(props) {
  const {
    apikey,
    asstConfigID,
    createAppName,
    selectedAssistant,
    domain,
    major_version,
  } = props;

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {/* Choose a playground to try out the assistant. */}
        Try the assistant
      </Typography>

      <Grid alignContent="center" alignItems="center" container>
        <Grid xs={12} item>
          <QRCode
            style={{ margin: 10, borderRadius: 15 }}
            size={164}
            value={`slangqr:${asstConfigID}:${apikey}:${domain}:${major_version}:${process.env.REACT_APP_STAGE}`}
            includeMargin={true}
          />
          <br />
        </Grid>
        <Grid xs={12} item>
          <Typography component="span" gutterBottom>
            Scan the QR code above in the Slang CONVA Playground App &nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=in.slanglabs.pg_app"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="/images/playstore.png"
                width="100"
                alt="pub success"
                style={{ verticalAlign: "middle" }}
              />
            </a>
          </Typography>
        </Grid>
        {/* Will be used once integrated with the web playground  */}
        {/* <Grid xs={2} item>
          <Typography> OR </Typography>
        </Grid>
        <Grid xs={5} item>
          <img src="/images/web.png" width="200" alt="pub success" />
          <Typography gutterBottom>
            Click to try the Javascript playground here.
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
