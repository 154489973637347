import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { testClassNames } from "../../../utils/integrationTestClassNames";

const ConfirmDialog = props => {
  const { classes, handleClose, handleConfirmDelete, state } = props;

  const { dialogTitle, openDelete } = state;

  return (
    <Dialog open={openDelete} onClose={handleClose}>
      <DialogContent>
        <Typography variant="button">{dialogTitle}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          className={`${classes.buttonDelete} ${testClassNames.CONFIRM_BUTTON}`}
          onClick={handleConfirmDelete}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
