import { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../../withTheme";
import { IntentsTableStyles } from "../styles.js";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinearProgress from "@material-ui/core/LinearProgress";

import TablePaginationActionsWrapped from "./pagination";
import AddEntityType from "./AddEntityType.js";
import DeleteConfirm from "./DeleteConfirm";
import DocHelpLink from "../../common/DocLinks";

let emptyRows = 1;

class EditIntentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      open: false,
      typeData: false,
      typeOpenDialog: false,
      dialogTitle: "",
      dialogContent: "",
      show: "",
      etype: "",
      entity: {
        position: "absolute",
        display: "none",
      },
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: [10],
      typeID: null,
      appTypes: null,
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleTypeDialogClose = this.handleTypeDialogClose.bind(this);
  }

  handleDeleteClick = ID => {
    this.setState({
      open: true,
      dialogTitle:
        "Deleting an entity type is an irreversible action and might make your app unuseable, do you want to continue ?",
      typeID: ID,
    });
  };

  handleDeleteEntityType = event => {
    this.props.handleDeleteEntityType(this.state.typeID);
    this.setState({
      open: false,
    });
  };

  handleDeleteID = event => {
    this.props.handleDeleteEntityType(this.state.typeID);
    this.setState({
      open: false,
    });
  };

  handleEditClick = (item, ID) => {
    this.setState({
      typeOpenDialog: true,
      typeData: item,
      typeID: ID,
    });
  };

  handleTypeDialogClose = event => {
    this.setState({
      typeOpenDialog: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleMouseOver = name => {
    this.setState({
      show: name,
    });
  };

  handleMouseLeave = name => {
    this.setState({
      show: "",
    });
  };

  handleSelectChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      entity: {
        position: "absolute",
        display: "none",
      },
    });
  };

  handleChangeSearch = event => {
    this.setState({
      search: event.target.value,
      dialogTitle: "Search for:",
    });
    this.filterApps(event.target.value);
  };

  handleSearchClick = event => {
    if (this.state.search !== "")
      this.setState({
        open: true,
      });
  };

  onKeyPress = event => {
    if (event.key === "Enter") {
      if (this.state.search !== "")
        this.setState({
          open: true,
        });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  handleNewEntityType = (newEntityType, ID, newName, oldName) => {
    this.props.handleNewEntityType(newEntityType, ID, newName, oldName);
    this.setState({
      typeOpenDialog: false,
    });
  };

  filterApps(text) {
    const currentIntents = this.props.appTypes;

    if (!currentIntents || currentIntents.length === 0) {
      return;
    }

    if (text && text.length > 0) {
      const filteredAppList = currentIntents.map(appItem => {
        if (appItem.name.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          return appItem;
        }
        return null;
      });
      this.setState({ appTypes: filteredAppList || [] });
    } else {
      this.setState({ appTypes: this.props.appTypes });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        appTypes: this.props.appTypes,
      });
    }
  }

  componentDidMount() {
    this.setState({
      appTypes: this.props.appTypes,
    });
  }

  render() {
    const { classes } = this.props;

    const {
      open,
      dialogTitle,
      show,
      rowsPerPageOptions,
      rowsPerPage,
      page,
      typeData,
      typeOpenDialog,
      typeID,
      appTypes,
    } = this.state;

    emptyRows = appTypes
      ? rowsPerPage -
        Math.min(rowsPerPage, appTypes.length - page * rowsPerPage)
      : 0;

    const { handleClose, handleDeleteID } = this;

    const DeleteConfirmProps = {
      handleClose,
      handleDeleteID,
      classes,
    };
    const DeleteConfirmState = {
      open,
      dialogTitle,
    };

    return (
      <Paper className={classes.root} elevation={0}>
        <Typography
          variant="h5"
          // color="inherit"
          classes={{ h5: classes.typeFont }}
          style={{ marginLeft: 24 }}
          noWrap
        >
          {"Entity Types "} <DocHelpLink docFor="ENTITY_TYPE" />
        </Typography>
        <Table className={classes.table} border={0}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.searchHead} colSpan="2">
                <Input
                  placeholder="Search"
                  className={classes.searchInput}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  onKeyPress={this.onKeyPress}
                  onChange={this.handleChangeSearch}
                />
              </TableCell>
              <TableCell className={classes.searchHead}>
                <AddEntityType
                  handleNewEntityType={this.handleNewEntityType}
                  allEntityTypes={appTypes}
                  withButton
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.padding}>
                <Typography className={classes.tableHead}>NAME</Typography>
              </TableCell>
              <TableCell className={classes.padding}>
                <Typography className={classes.tableHead}>
                  DESCRIPTION
                </Typography>
              </TableCell>
              <TableCell className={classes.padding} />
            </TableRow>
          </TableHead>
          <TableBody>
            {appTypes ? (
              appTypes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, key) => {
                  if (item) {
                    const ID = page * rowsPerPage + key;
                    if (item.name) {
                      return (
                        <TableRow
                          key={key}
                          onMouseEnter={this.handleMouseOver.bind(
                            this,
                            item.name
                          )}
                          onMouseLeave={this.handleMouseLeave}
                          hover
                        >
                          <TableCell
                            id={item.name + 1}
                            component="th"
                            scope="row"
                            className={classes.pointer}
                            onClick={this.handleEditClick.bind(this, item, ID)}
                          >
                            <span className={classes.tableBodyText}>
                              {" "}
                              {item.name}{" "}
                            </span>
                          </TableCell>
                          <TableCell
                            id={item.name + 1}
                            scope="row"
                            className={classes.pointer}
                            onClick={this.handleEditClick.bind(this, item, ID)}
                          >
                            {item.description}
                          </TableCell>
                          <TableCell
                            id={item.name + 3}
                            className={classes.edit}
                          >
                            <span
                              style={
                                show === item.name
                                  ? { display: "inherit" }
                                  : { display: "none" }
                              }
                            >
                              <IconButton
                                id={item.name + 6}
                                className={classes.buttonEdit}
                                aria-label="Edit"
                                onClick={this.handleEditClick.bind(
                                  this,
                                  item,
                                  ID
                                )}
                              >
                                <EditIcon id={item.name + 4} />
                              </IconButton>
                              <IconButton
                                id={item.name + 7}
                                className={classes.buttonDelete}
                                aria-label="Delete"
                                onClick={this.handleDeleteClick.bind(this, ID)}
                              >
                                <DeleteIcon id={item.name + 5} />
                              </IconButton>
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  }
                  emptyRows++;
                  return null;
                })
            ) : (
              <TableRow style={{ height: 48 * rowsPerPage }}>
                <TableCell colSpan={5}>
                  {" "}
                  <LinearProgress color="primary" variant="query" />{" "}
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={6}
                count={appTypes ? appTypes.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={rowsPerPageOptions}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <AddEntityType
          typeData={typeData}
          typeOpenDialog={typeOpenDialog}
          handleTypeDialogClose={this.handleTypeDialogClose}
          handleNewEntityType={this.handleNewEntityType}
          handleDeleteEntityType={this.props.handleDeleteEntityType}
          allEntityTypes={appTypes}
          typeID={typeID}
        />
        <DeleteConfirm state={DeleteConfirmState} {...DeleteConfirmProps} />
      </Paper>
    );
  }
}

EditIntentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(IntentsTableStyles)(EditIntentsTable));
