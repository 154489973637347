import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Toolbar, Tab } from "@material-ui/core";

// import AppHomePrompts from "../../components/AppHomePrompts";
import ASRHints from "../../components/ASRHints";
import AppHomeIntentsTable from "../../components/AppHomeIntentsTable";
import AppHomeTypesTable from "../../components/AppHomeTypesTable";
import asyncComponent from "../../../common/AsyncComponent";
import { TabPanel, a11yProps } from "../../components/TabsContainer";
import SubDomains from "../../components/SubDomains";
import UserJourneys from "../../components/UserJourneys";

const JsonEditor = asyncComponent(() =>
  import("../../components/JsonEditorTemplate")
);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function AppHomeTabs(props) {
  const {
    handleDeleteIntent,
    handleDeleteEntityType,
    handleNewEntityType,
    handlePageChange,
    handleEditAppData,
    appDATA,
  } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const _handleEditAppData = (UJ, key) => {
    handleEditAppData(UJ, key);
  };

  const handleCreateNewIntent = payload => {
    const newIntents = [payload, ...appDATA.intents];
    handleEditAppData(newIntents, "intents");
  };

  return (
    <div className={classes.root}>
      {/* <NewIntent {...newIntentProps} state={newIntentState} /> */}
      <AppBar position="static">
        <Toolbar>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="App Intents" {...a11yProps(0)} />
            <Tab label="App Types" {...a11yProps(1)} />
            <Tab label="ASR Hints" {...a11yProps(2)} />
            <Tab label="Subdomains" {...a11yProps(3)} />
            <Tab label="User Journeys" {...a11yProps(4)} />
            {/* <Tab label="App Prompts" {...a11yProps(3)} /> */}
            <Tab label="Power Mode" {...a11yProps(5)} />
          </Tabs>
        </Toolbar>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AppHomeIntentsTable
          appName={"appName"}
          appIntents={appDATA.intents}
          handleDeleteIntent={handleDeleteIntent}
          handlePageChange={handlePageChange}
          handleCreateNewIntent={handleCreateNewIntent}
          handleEditAppData={handleEditAppData}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AppHomeTypesTable
          appTypes={appDATA.types}
          handleNewEntityType={handleNewEntityType}
          handleDeleteEntityType={handleDeleteEntityType}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ASRHints />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SubDomains
          subdomains={appDATA.subDomains}
          handleEditAppData={handleEditAppData}
        />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <UserJourneys
          userJourneys={appDATA.userJourneys}
          handleEditAppData={_handleEditAppData}
          subDomains={appDATA.subDomains}
          changeSubDomainData={handleEditAppData}
          handleCreateNewIntent={handleCreateNewIntent}
        />
      </TabPanel>

      {/* <TabPanel value={value} index={3}>
        <AppHomePrompts appDATA={appDATA} />
      </TabPanel> */}
      <TabPanel value={value} index={5}>
        <JsonEditor isTabLoaded={value === 5} />
      </TabPanel>
    </div>
  );
}
