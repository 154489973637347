import theme from "../theme";

const commonStyles = () => ({
  root: {
    textAlign: "center",
    overflow: "hidden",
  },
  appFrame: {
    height: "100vh",
    width: "100vw",
    zIndex: 1,
    overflow: "auto",
    position: "relative",
    display: "flex",
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginLeft: 90,
  },
});

const drawerWidth = 66;
const nav = () => ({
  root: {
    position: "fixed",
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: "hidden",
  },
  logo: {
    position: "absolute",
    top: 0,
  },
  centerItems: {
    height: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bigAvatar: {
    margin: 8,
    width: 40,
  },
  smallAvatar: {
    margin: 8,
    borderRadius: 20,
    width: 40,
  },
  menuItem: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.text.primary,
    flexGrow: 1,
    "&:focus": {},
    "&:hover": {},
  },
  menuItemSelected: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    icon: {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  menuItemSelectedIcon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    icon: {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  menuLogout: {
    marginTop: "30%",
  },
  beta: {
    color: "white",
    fontStyle: "oblique",
  },
});

const TopNavStyles = () => ({
  headLinks: {
    textAlign: "left",
    "& a": {
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 600,
      letterSpacing: "1px",
      fontSize: "0.80rem",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      color: theme.palette.secondary.medium,
    },
    color: theme.palette.primary.main,
  },
  iconPublish: {
    fontSize: 15,
    marginRight: 5,
  },
  iconLogout: {
    fontSize: 25,
    margin: 0,
  },
  publishButton: {
    border: "1px solid " + theme.palette.primary.medium,
    marginRight: 10,
    color: theme.palette.primary.medium,
  },
  iconRight: {
    fontSize: 24,
    marginTop: 0,
    color: theme.palette.secondary.medium,
    lineHeight: "1.5",
  },
  bottomMargin: {
    marginBottom: 30,
    minHeight: 56,
  },
  blueButton: {
    color: "white",
    backgroundColor: theme.palette.fourth.medium,
    marginRight: 0,
  },
  saveButtonDefault: {
    marginRight: 10,
  },
  saveButton: {
    color: "white",
    backgroundColor: theme.palette.primary.medium,
    marginRight: 10,
  },

  space: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: "1px solid " + theme.palette.primary.medium,
  },
  toUpper: {
    textTransform: "uppercase",
  },
  leftAlign: {
    justifyContent: "space-between",
  },

  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
  noconnection: {
    height: 30,
    backgroundColor: "#fc3d39",
    color: "white",
    margin: 5,
    padding: 3,

    borderRadius: 10,
  },
  notifyChange: {
    display: "inline-block",
    marginRight: 15,
    right: 0,
    textAlign: "center",
    padding: "1px 16px 4px",
    fontSize: "0.8em",
    verticalAlign: "middle",
    lineHeight: 2,
  },
  playgroundNotify: {
    ...theme.mixins.gutters(),
    padding: "1px 16px 4px",
    fontSize: "0.8em",
    verticalAlign: "middle",
    lineHeight: 2,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  unsaved: {
    borderLeft: `5px solid ${theme.palette.secondary.medium}`,
    lineHeight: 2,
    "& p": {
      color: theme.palette.secondary.medium,
    },
  },
  saved: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    lineHeight: 2,
    "& p": {
      color: theme.palette.primary.main,
    },
  },
  error: {
    borderLeft: `5px solid ${theme.palette.error.main}`,
    lineHeight: 2,
    "& p": {
      color: theme.palette.error.main,
    },
  },
  bottomBar: {
    position: "fixed",
    zIndex: 100,
    left: 0,
    bottom: 0,
    padding: 4,
    width: "100%",
    fontSize: "0.8rem",
    "& a": {
      fontSize: "0.8rem",
      fontWeight: "700",
    },
  },
});

export { commonStyles, nav, TopNavStyles };
