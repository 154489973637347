import PropTypes from "prop-types";
import { styles } from "../../home/styles.js";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "../../withTheme";

import Fade from "@material-ui/core/Fade";

import TopNav from "../topNav";
import SideNav from "../sideNav";

const DefaultLayout = props => {
  const { classes, notify, children, backgroundImage } = props;
  return (
    <div className={classes.appFrame}>
      <div
        className={classes.drawn}
        style={
          backgroundImage
            ? {
                backgroundImage,
              }
            : null
        }
      >
        <SideNav currentPage="home" shortcuts={props.sideNavShortcuts} />
        <main className={classes.content}>
          <TopNav notify={notify} />
          <Fade in={true}>{children}</Fade>
        </main>
      </div>
    </div>
  );
};

DefaultLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(DefaultLayout));
