import PropTypes from "prop-types";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  testButton: {
    marginBottom: 10,
  },
  clickableIcon: {
    cursor: "pointer",
  },
}));

const AddASRHints = props => {
  const {
    isSlangInitialised,
    speechRecognitionHints,
    speechRecognitionHintString,
    handleChangeSpeechRecognitionHintString,
    addSpeechRecognitionHint,
    removeSpeechRecognitionHint,
  } = props;

  const classes = styles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <div>
        <Typography className={classes.heading}>
          Add Speech Recognition Hints
        </Typography>
        <TextField
          id="asrHints"
          label="Speech Recognition Hints"
          value={speechRecognitionHintString}
          onChange={handleChangeSpeechRecognitionHintString}
          onKeyDown={event => {
            if (event.key === "Enter") {
              event.preventDefault;
              addSpeechRecognitionHint(speechRecognitionHintString);
            }
          }}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSlangInitialised !== 1}
                  onClick={() =>
                    addSpeechRecognitionHint(speechRecognitionHintString)
                  }
                  className={classes.testButton}
                >
                  Add
                </Button>
              </InputAdornment>
            ),
          }}
          margin="normal"
        />
        <List>
          {speechRecognitionHints.map(hint => (
            <ListItem key={hint} dense button className={classes.listItem}>
              <ListItemText primary={`${hint}`} />
              <ListItemSecondaryAction>
                <CloseRounded
                  onClick={() => removeSpeechRecognitionHint(hint)}
                  className={classes.clickableIcon}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  );
};

AddASRHints.propTypes = {
  isSlangInitialised: PropTypes.number.isRequired,
  speechRecognitionHints: PropTypes.array.isRequired,
  speechRecognitionHintString: PropTypes.string.isRequired,
  handleChangeSpeechRecognitionHintString: PropTypes.func.isRequired,
  addSpeechRecognitionHint: PropTypes.func.isRequired,
  removeSpeechRecognitionHint: PropTypes.func.isRequired,
};

export default AddASRHints;
