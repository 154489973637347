import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CloudUpload,
  Assignment,
  ArrowDropDown,
  ArrowDropUp,
  Close as ClearIcon,
  Info,
} from "@material-ui/icons";
import * as SlangTooltips from "./SlangTooltips";
import { testClassNames } from "../../utils/integrationTestClassNames";

const styles = makeStyles(theme => ({
  publishButton: {
    border: "1px solid " + theme.palette.primary.medium,
    marginRight: 10,
    color: theme.palette.primary.medium,
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menu: {
    top: 45,
  },
  menuItem: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& $primary, & $icon": {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
  closeIcon: {
    float: "right",
  },
  subheading: {
    fontSize: "1.4rem",
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    float: "right",
    width: "20%",
    marginBottom: "1rem",
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  disabled: {},
}));

const PublishButton = props => {
  const { isLoading, isPublishing, isPublishingProd, handleClick } = props;

  const classes = styles();

  const [open, setOpen] = useState(false);
  const [env, setEnv] = useState("stage");
  const [engine, setEngine] = useState("vedvyasa");
  const [task, setTask] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeEnv = event => {
    setEnv(event.target.value);
  };

  const handleChangeEngine = event => {
    setEngine(event.target.value);
  };

  const handleChangeTask = event => {
    if (event.target.checked) {
      setTask([...task, event.target.name]);
    } else {
      setTask(task.filter(item => item !== event.target.name));
    }
  };

  const handleClickPublish = event => {
    const selectedTask =
      task.length > 1 ? "all" : task.length === 1 ? task[0] : "";
    if (env === "stage") {
      handleClick("PUBLISH")(engine, selectedTask, env);
    } else {
      handleClick("PROMOTE_TO_PROD")(engine, selectedTask, env);
    }
    setOpen(false);
  };

  return (
    <span>
      <Tooltip
        title={SlangTooltips.PUBLISH}
        classes={{ tooltip: classes.tooltip }}
        disableFocusListener
        disableHoverListener
      >
        <Button
          variant="outlined"
          className={`${classes.publishButton} ${testClassNames.BUDDY_PUBLISH_UNLOCK_BUTTON}`}
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="When device is locked"
          onClick={handleOpen}
          disabled={isLoading || isPublishing || isPublishingProd}
        >
          {isPublishing || isPublishingProd ? (
            <CircularProgress size={24} style={{ marginRight: 5 }} />
          ) : null}{" "}
          &nbsp; {" Publish "}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Publish Assistant
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="h5" className={classes.subheading}>
            Select Environment
          </Typography>

          <FormControl style={{ width: "100%" }}>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              style={{ justifyContent: "center" }}
              value={env}
              onChange={handleChangeEnv}
            >
              <div style={{ marginRight: "2rem" }}>
                <FormControlLabel
                  value="stage"
                  control={<Radio size="small" />}
                  label="Staging"
                />
              </div>
              <div>
                <FormControlLabel
                  value="prod"
                  control={<Radio size="small" />}
                  label="Production"
                />
              </div>
            </RadioGroup>
          </FormControl>
          <FormHelperText>
            If you are still testing, we suggest you{" "}
            <strong>Publish to Staging</strong> instead{" "}
            <strong>Publish to Production</strong>.
          </FormHelperText>
          <br />
          <Typography variant="h5" className={classes.subheading}>
            Select Engine
          </Typography>

          <FormControl style={{ width: "100%" }}>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              style={{ justifyContent: "center" }}
              value={engine}
              onChange={handleChangeEngine}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "2rem",
                }}
              >
                <FormControlLabel
                  value="vedvyasa"
                  control={<Radio size="small" />}
                  label="Vedvyasa"
                  style={{ marginRight: "5px" }}
                />{" "}
                <Tooltip title="About Vedvyasa">
                  <Info fontSize="small" />
                </Tooltip>{" "}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  value="panini"
                  control={<Radio size="small" />}
                  label="Panini"
                  style={{ marginRight: "5px" }}
                />{" "}
                <Tooltip title="About Panini">
                  <Info fontSize="small" />
                </Tooltip>{" "}
              </div>
            </RadioGroup>
          </FormControl>
          <FormHelperText>
            Select the engine you want to set as the primary engine for training
            the assistant.
          </FormHelperText>
          <br />
          {engine === "panini" ? (
            <>
              <Typography variant="h5" className={classes.subheading}>
                Select Task
              </Typography>
              <FormControl style={{ width: "100%" }}>
                <FormGroup row style={{ justifyContent: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={task.includes("classifier")}
                        onChange={handleChangeTask}
                        name="classifier"
                      />
                    }
                    label="Classifier"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={task.includes("tagger")}
                        onChange={handleChangeTask}
                        name="tagger"
                      />
                    }
                    label="Tagger"
                  />
                </FormGroup>
              </FormControl>
              <FormHelperText>
                Select the task you want to the NLP engine to perform while
                training.
              </FormHelperText>
              <br />
            </>
          ) : null}
          <div className={classes.dialogAction}>
            <Button
              variant="text"
              classes={{
                root: classes.eTypeDone,
                disabled: classes.disabled,
              }}
              onClick={handleClickPublish}
              disabled={engine === "panini" && task.length === 0}
            >
              {"Publish"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </span>
  );
};

export default PublishButton;
