import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/core/styles";
import { main } from "../styles.js";

// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import * as SlangTooltips from "../../common/SlangTooltips";

import Notifications from "../../common/Notifications";
import ExpansionHelp from "./ExpansionHelp";
import LangButton from "./LangButton";

import {
  writeHelpPromptState,
  emptyLangIntentHelpState,
  readHelpPromptState,
  editHelpDescriptionState,
  editHelpUtteranceState,
  deleteIntentHelpState,
} from "../../../utils/helpUtils";
import { changeAppStringTable } from "../../../redux/actions/appSchema";
import DocHelpLink from "../../common/DocLinks";

// import { testClassNames } from "../../../utils/integrationTestClassNames.js";

const mapDispatchToProps = dispatch => ({
  changeAppStringTable: appStrings =>
    dispatch(changeAppStringTable(appStrings)),
});

function HelpTextField(props) {
  const {
    classes,
    lang,
    updateState,
    handleOnChange,
    handleDeletePrompt,
    handleBlurSave,
    state,
  } = props;
  const { help } = state;
  const helpDescriptionValue = help.helpDescription.value
    ? help.helpDescription.value
    : "";
  const errorTitle = helpDescriptionValue.trim() === "";
  const errorUtterance = help.utterances && help.utterances.length === 0;
  return (
    <form>
      <div className={classes.separate}>
        <TextField
          style={{ marginTop: 0 }}
          name="help.helpDescription"
          placeholder={SlangTooltips.INTENT_HELP.HELP_TITLE_EN}
          helperText={SlangTooltips.INTENT_HELP.TTILE_EXAMPLE_EN}
          value={helpDescriptionValue}
          onChange={handleOnChange}
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          onFocus={updateState}
          onBlur={handleBlurSave}
          fullWidth
          error={errorTitle}
          required
        />
      </div>
      <div className={classes.separate}>
        <ExpansionHelp
          onChangeText={handleOnChange}
          handleDelete={handleDeletePrompt}
          StatementValues={help}
          handleBlurSave={handleBlurSave}
          lang={lang}
          promptName="help"
          HELPTEXT={SlangTooltips.INTENT_HELP}
          errorUtterance={errorUtterance}
          errorTitle={errorTitle}
        />
      </div>
    </form>
  );
}

HelpTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};
let isNoUpdate = false;
export class IntentHomeHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "en-IN",
      open: false,
      error: null,
      strData: {},
      languagePrompts: emptyLangIntentHelpState() || {},
      notify: {
        variant: "info",
        message: "msg",
      },
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleBlurSave = this.handleBlurSave.bind(this);
  }

  updateState = () => {
    const stringData = JSON.parse(JSON.stringify(this.props.appStringTable));
    const intentData = this.props.intentData;
    this.setState({
      languagePrompts: readHelpPromptState(intentData, stringData),
      strData: stringData,
    });
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (isNoUpdate) {
        isNoUpdate = false;
      } else {
        this.updateState();
      }
    }
  }

  handleDeletePrompt = (template, promptType, ID) => {
    const strData = this.state.strData;
    const languagePrompts = this.state.languagePrompts;
    const promptsStrings = deleteIntentHelpState(
      languagePrompts,
      strData,
      template,
      promptType,
      ID
    );
    if (promptsStrings.hasStringID) {
      this.handleSave(
        promptsStrings.newLanguagePrompts,
        promptsStrings.strData
      );

      return;
    }
    this.handleSave(promptsStrings.newLanguagePrompts);
  };

  handleSave = (languagePrompts, strData, intentData) => {
    languagePrompts = languagePrompts || this.state.languagePrompts;
    strData = strData || this.state.strData;
    intentData = intentData || this.props.intentData;
    const newData = writeHelpPromptState(intentData, strData, languagePrompts);

    this.props.handleSavePromptData(newData.appData);
    this.props.changeAppStringTable({ appStringTable: newData.strData });
  };

  handleBlurSave = () => {
    isNoUpdate = true;
    this.handleSave();
  };

  handleOnChange = (event, newkey = null, isNew = false) => {
    const value = event.target.value;
    const name = event.target.name;
    const lang = this.state.lang;
    const key = newkey;
    if (name === "help.helpDescription") {
      const languagePrompts = this.state.languagePrompts;
      const newLanguagePrompts = editHelpDescriptionState(
        languagePrompts,
        lang,
        name,
        key,
        value
      );

      this.handleSave(newLanguagePrompts);

      return;
    }
    const languagePrompts = this.state.languagePrompts;
    const newLanguagePrompts = editHelpUtteranceState(
      languagePrompts,
      lang,
      name,
      key,
      value,
      isNew
    );
    if (isNew) {
      this.handleSave(newLanguagePrompts);
      return;
    }
    this.setState({
      languagePrompts: newLanguagePrompts,
    });
  };

  handleLangChange = lang => {
    this.setState({
      lang,
    });
  };

  render() {
    const { classes } = this.props;
    const { error, notify, lang, languagePrompts } = this.state;

    const {
      handleOnChange,
      handleDeletePrompt,
      handleLangChange,
      handleBlurSave,
      updateState,
    } = this;

    const HelpTextFieldProps = {
      handleOnChange,
      handleDeletePrompt,
      handleBlurSave,
      updateState,
      error,
      lang,
      classes,
    };
    const HelpTextFieldState = languagePrompts[lang];

    const langButtonProps = {
      lang,
      handleLangChange,
      classes,
    };
    return (
      <div>
        <Paper className={classes.promptPaper} elevation={0}>
          <Typography variant="h5" noWrap>
            {" Intent Help "} <DocHelpLink docFor="INTENT_HELP_TEXT" />
          </Typography>
          <br />
          <LangButton {...langButtonProps} />
          <br />
          <br />
          <HelpTextField {...HelpTextFieldProps} state={HelpTextFieldState} />
        </Paper>
        <Notifications notify={notify} />
      </div>
    );
  }
}

IntentHomeHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  appStringTable: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    appStringTable: state.appSchema.appStringTable,
    appList: state.appSchema.list,
    error: state.appSchema.error,
    appID: state.appSchema.appID,
    schemaDefinition: state.appSchema.schemaDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(main)(IntentHomeHelp));
