import { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Switch from "@material-ui/core/Switch";
import ExpansionPrompts from "./ExpansionPrompts";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import { testClassNames } from "../../../utils/integrationTestClassNames";
import * as SlangTooltips from "../../common/SlangTooltips.js";
import LangButton from "./LangButton";
import IntentEntityHelp from "./IntentEntityHelp";
import DocHelpLink from "../../common/DocLinks";
import { validateEntityName } from "../../../utils/validate";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs";
import { deleteKeyFromObject, replaceAtArray } from "../../../utils/helpers";
import {
  writeEntityPromptState,
  emptyLangEntityState,
  readEntityPromptState,
  editPromptState,
  editPromptSeverity,
  deleteIntentPromptState,
} from "../../../utils/promptUtils";
import AddEntityType from "./AddEntityType";

class AddEditEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      typeOpenDialog: false,
      selectOpen: false,
      strData: this.props.stringData,
      contextTrigger: true,
      entityData: {},
      entityName: "",
      entityType: "none",
      entityPriority: 1,
      isRequired: false,
      listCheck: false,
      entityHelp: {},
      canSubmit: true,
      lang: this.props.lang,
      editID: null,
      languagePrompts: emptyLangEntityState() || {},
      tabValue: 1,
    };
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleSaveHelpData = entityHelp => {
    this.setState({
      entityHelp,
    });
  };

  handleDeleteMissing = (template, promptType, ID) => {
    const strData = this.state.strData;
    const languagePrompts = this.state.languagePrompts;
    const promptsStrings = deleteIntentPromptState(
      languagePrompts,
      strData,
      template,
      promptType,
      ID
    );

    this.setState({
      languagePrompts: promptsStrings.newLanguagePrompts,
    });
    if (promptsStrings.hasStringID) {
      this.setState({ strData: promptsStrings.newStrData });
    }
    this.handlePromptSave(
      promptsStrings.newLanguagePrompts,
      promptsStrings.newStrData
    );
  };

  handlePromptSave = (
    languagePrompts = this.state.languagePrompts,
    strData = this.state.strData
  ) => {
    const entityData = this.state.entityData;
    const newIntentData = writeEntityPromptState(
      entityData,
      strData,
      languagePrompts
    );

    if (newIntentData.appData.missingEntityPrompt)
      return {
        missingEntityPrompt: newIntentData.appData.missingEntityPrompt,
        strData: newIntentData.strData,
      };

    return { missingEntityPrompt: false, strData: newIntentData.strData };
  };

  handleOnChange = (event, key = null) => {
    const value = event.target.value;
    const name = event.target.name;
    const severity = event.target.severity;
    const lang = this.state.lang;

    this.setState(state => {
      const languagePrompts = state.languagePrompts;
      const newLanguagePrompts = editPromptState(
        languagePrompts,
        lang,
        name,
        key,
        value,
        severity
      );
      return { languagePrompts: newLanguagePrompts };
    });
  };

  handleChangeSeverity = (idx, severity) => {
    const lang = this.state.lang;

    this.setState(state => {
      const languagePrompts = state.languagePrompts;
      const newLanguagePrompts = editPromptSeverity(
        languagePrompts,
        lang,
        idx,
        severity
      );
      this.handlePromptSave(newLanguagePrompts);
      return { languagePrompts: newLanguagePrompts };
    });
  };

  handleListSelect = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleNewEntityType = (newEntityType, ID, newName, oldName) => {
    this.setState({
      typeOpenDialog: false,
      typeData: null,
      typeID: null,
      entityType: newEntityType.name,
    });

    this.props.handleNewEntityType(newEntityType, ID, newName, oldName);
  };

  handleSelectClose = () => {
    this.setState({
      selectOpen: false,
    });
  };

  handleEntityClose = () => {
    this.props.setStateOfEntityDialog(false);
    this.setState({
      selectOpen: false,
      tabValue: 1,
    });
  };

  handleSelectOpen = () => {
    this.setState({
      selectOpen: true,
      contextTrigger: true,
    });
  };

  handleChangeEntity = event => {
    this.setState({
      newType: event.target.value,
    });
  };

  handleSelectChange = event => {
    const currentEntities = this.props.allEntities;

    if (event.target.name === "entityName") {
      // eslint-disable-next-line
      const name = event.target.value.replace(/([^a-zA-Z_]+)/g, "");
      let list = currentEntities;
      if (this.state.editID !== null) {
        const nameOld = currentEntities[this.state.editID].name;
        list = currentEntities.filter(item => item.name !== nameOld);
      }
      const OK = validateEntityName(name, list);

      this.setState({
        entityName: name,
        canSubmit: OK,
      });
    } else if (event.target.name === "entityPriority") {
      const priority = parseInt(event.target.value, 10);
      this.setState({
        [event.target.name]: priority,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleEditChange = event => {
    const currentEntities = this.props.allEntities;
    if (event.target.name === "editEntityPriority") {
      const priority = parseInt(event.target.value, 10);

      this.setState({
        [event.target.name]: priority,
      });
    } else if (event.target.name === "editEntityName") {
      const name = currentEntities[this.state.editID].name;
      const list = currentEntities.filter(item => item.name !== name);

      const OK = validateEntityName(event.target.value, list);

      this.setState({
        [event.target.name]: event.target.value,
        editCanSubmit: OK,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
    this.setState({ contextTrigger: true });
  };

  handleSelectClick = (event, edit = null) => {
    const typeID = edit && edit.split("_")[0];
    if (event.target.value === "newType") {
      this.setState({
        typeData: null,
        typeID: null,
        typeOpenDialog: true,
      });
    } else if (typeID) {
      event.stopPropagation();
      const typeData = this.props.appDATA.types[typeID];
      this.setState({
        typeData,
        typeID,
        typeOpenDialog: true,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleSwitchChange = event => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleNewEntity = event => {
    event.preventDefault();
    const currentEntities = this.props.allEntities;
    const ID = this.state.editID;
    const isNew = ID === null;
    let newData, oldName, newName;
    const {
      isRequired,
      listCheck,
      entityType,
      entityName,
      entityHelp,
      entityPriority,
    } = this.state;

    let newEntity = {
      name: entityName,
      required: isRequired,
      type: entityType,
      list: listCheck,
      priority: isNew ? currentEntities.length + 1 : entityPriority,
    };

    const help = entityHelp.help;
    if (
      help &&
      help.helpDescription &&
      help.helpDescription.value &&
      help.utterances &&
      help.utterances.length
    ) {
      newEntity = {
        ...newEntity,
        help: entityHelp.help,
      };
    }
    const entityData = this.handlePromptSave();
    if (entityData.missingEntityPrompt) {
      newEntity = {
        ...newEntity,
        missingEntityPrompt: entityData.missingEntityPrompt,
      };
    }

    if (isNew) {
      newData = [...currentEntities, newEntity];
    } else {
      oldName = currentEntities[ID].name;
      newName = entityName;
      newData = replaceAtArray(currentEntities, ID, {
        ...currentEntities[ID],
        ...newEntity,
      });
      if (!entityData.missingEntityPrompt) {
        newData[ID] = deleteKeyFromObject(newData[ID], "missingEntityPrompt");
      }
    }

    logEvent(SlangSeverityLevels.INFO, SlangEvents.ENTITY_CREATED, {
      entity_name: entityName,
      intent_name: this.props.intentName,
    });

    this.props.setStateOfEntityDialog(false);
    this.setState({
      data: newData.sort((a, b) => {
        return a.priority - b.priority;
      }),
      isRequired: false,
      entityPriority: null,
      entityType: "none",
      entityName: "",
      languagePrompts: emptyLangEntityState() || {},
      editID: null,
    });
    if (oldName !== newName && !isNew) {
      this.props.handleSaveEntityData(newData, oldName, newName);
    } else {
      this.props.handleSaveEntityData(newData);
    }
    this.props.changeAppStringTable({ appStringTable: entityData.strData });
    this.setState({ contextTrigger: false });
  };

  changeLocalStringTable = ({ appStringTable }) => {
    this.setState({
      strData: appStringTable,
    });
  };

  handleLangChange = lang => {
    this.setState({
      lang,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.lang !== this.props.lang ||
      prevProps.entityData !== this.props.entityData
    ) {
      if (this.props.editID !== null && this.props.editID > -1) {
        this.setState({
          open: false,
          typeOpenDialog: false,
          selectOpen: false,
          languagePrompts: readEntityPromptState(
            this.props.entityData,
            this.props.stringData
          ),
          strData: this.props.stringData,
          contextTrigger: true,
          entityData: this.props.entityData,
          entityName: this.props.entityData.name,
          entityType: this.props.entityData.type
            ? this.props.entityData.type
            : "none",
          entityPriority: this.props.entityData.priority,
          isRequired: this.props.entityData.required,
          listCheck: this.props.entityData.list,
          entityHelp: this.props.entityData,
          canSubmit: true,
          lang: this.props.lang,
          editID: this.props.editID,
          tabValue: 1,
        });
      } else if (this.props.editID === null) {
        this.setState({
          open: false,
          typeOpenDialog: false,
          selectOpen: false,
          languagePrompts: emptyLangEntityState() || {},
          strData: this.props.stringData,
          contextTrigger: true,
          canSubmit: true,
          lang: "en-IN",
          entityData: {},
          entityName: "",
          entityType: "none",
          entityPriority: 1,
          isRequired: false,
          listCheck: false,
          entityHelp: {},
          editID: null,
          tabValue: 1,
        });
      }
    }
  }

  handleTypeDialogClose = nameType => {
    this.setState({
      typeOpenDialog: false,
      typeData: null,
      typeID: null,
    });
  };

  render() {
    const { classes, stdTypes, appTypes, appDATA } = this.props;

    const { openEntity } = this.props.state;

    const {
      handleLangChange,
      handleSelectClick,
      handleListSelect,
      handleSwitchChange,
      handleNewEntity,
      handleOnChange,
      handleDeleteMissing,
      handleChangeSeverity,
      handleSaveHelpData,
      handleSelectChange,
      handleSelectOpen,
      handleEntityClose,
      handleSelectClose,
      handleNewEntityType,
      handleTypeDialogClose,
      changeLocalStringTable,
    } = this;

    const {
      languagePrompts,
      tabValue,
      entityHelp,
      isRequired,
      entityType,
      entityName,
      lang,
      listCheck,
      canSubmit,
      selectOpen,
      typeOpenDialog,
      typeData,
      typeID,
      strData,
    } = this.state;

    const prompts = languagePrompts[lang];
    const promptsExample = languagePrompts["en-IN"];
    const { missingEntityPrompt } = prompts;

    const langButtonProps = {
      lang,
      handleLangChange,
      classes,
    };
    const isDisabled = !(
      canSubmit &&
      entityType !== "none" &&
      (!isRequired ||
        (isRequired && missingEntityPrompt.questionTemplates.length))
    );

    const ExpansionPromptsProps = {
      onChangeText: handleOnChange,
      handleDelete: handleDeleteMissing,
      handleChangeSeverity,
      PromptValues: missingEntityPrompt,
      lang,
      promptName: "missingEntityPrompt",
      promptsExample,
      expand: "ON",
      isRequired: false,
      HELPTEXT: SlangTooltips.MISSING_ENTITY_PROMPT,
    };
    const AddEditEntityTypeProps = {
      allEntityTypes: appDATA.types,
      handleNewEntityType,
      typeOpenDialog,
      handleTypeDialogClose,
      typeData,
      typeID,
    };
    return (
      <Fragment>
        <Dialog open={openEntity} onClose={handleEntityClose} maxWidth="md">
          <form autoComplete="off" onSubmit={handleNewEntity}>
            <DialogTitle>
              {"Add / Edit Entity "} <DocHelpLink docFor="ENTITY" />
              <Button
                style={{ float: "right" }}
                variant="contained"
                color="inherit"
                className={`${classes.intentButton} ${testClassNames.ENTITY_SAVE_BUTTON}`}
                type="submit"
                disabled={isDisabled}
              >
                Save
              </Button>
              <Button
                style={{ float: "right", marginRight: 10 }}
                color="default"
                onClick={handleEntityClose}
                className={`${testClassNames.ENTITY_TYPE_CANCEL_BUTTON}`}
              >
                Cancel
              </Button>
            </DialogTitle>
            <DialogContent>
              <Grid container className={classes.form}>
                <Grid item xs={6} className={classes.paddingEntity}>
                  <FormControl error={!canSubmit} fullWidth>
                    <TextField
                      autoFocus
                      placeholder="Add name*"
                      label="Entity Name"
                      inputProps={{
                        "aria-label": "Entity Name",
                      }}
                      type="text"
                      name="entityName"
                      value={entityName}
                      onChange={handleSelectChange}
                      required
                      fullWidth
                    />
                    <FormHelperText>{SlangTooltips.ENTITY_NAME}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className={classes.paddingEntity}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="SelectType">
                      Entity Type*
                    </InputLabel>
                    <Select
                      id="SelectType"
                      className={`${testClassNames.ENTITY_TYPE_SELECTION}`}
                      open={selectOpen}
                      onClose={handleSelectClose}
                      onOpen={handleSelectOpen}
                      label="Entity Type*"
                      value={entityType}
                      name="entityType"
                      displayEmpty
                      onChange={event => handleSelectClick(event, null)}
                      required
                      fullWidth
                    >
                      <MenuItem value={"newType"}>
                        <Button variant="outlined" color="primary" fullWidth>
                          Create Entity Type
                        </Button>
                      </MenuItem>
                      {appTypes
                        ? appTypes.map((item, key) => (
                            <MenuItem key={item.name + key} value={item.name}>
                              {`${item.name}   `}
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={event => {
                                  handleSelectClick(
                                    event,
                                    `${key}_${item.name}`
                                  );
                                }}
                                className={classes.editButton}
                                component="span"
                              >
                                <EditIcon id={`${key}_${item.name}`} />
                              </IconButton>
                            </MenuItem>
                          ))
                        : ""}
                      <Divider style={{ margin: "10px 0" }} />
                      {stdTypes
                        ? stdTypes.map((item, key) => (
                            <MenuItem key={key} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                    <FormHelperText>
                      {SlangTooltips.ENTITY_SELECT_TYPE}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.paddingEntity}>
                  <FormControlLabel
                    // className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        color="primary"
                        checked={listCheck}
                        onChange={handleListSelect("listCheck")}
                        value="listCheck"
                      />
                    }
                    label="Mark as list"
                  />
                  <Tooltip
                    title={SlangTooltips.ENTITY_LIST}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Icon className={classes.iconHelp}>help</Icon>
                  </Tooltip>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    // className={classes.switchLabel}
                    control={
                      <Switch
                        name="isRequired"
                        checked={isRequired}
                        value="isRequired"
                        onChange={handleSwitchChange}
                        color="primary"
                      />
                    }
                    label="Required ?"
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  className={classes.paddingEntity}
                  style={{ marginTop: 30 }}
                />
                <Grid item xs={8} className={classes.paddingEntity}>
                  <LangButton {...langButtonProps} />
                </Grid> */}
                {/* <Grid item xs={4} className={classes.paddingEntity}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isRequired"
                        checked={isRequired}
                        value="isRequired"
                        onChange={handleSwitchChange}
                        color="primary"
                      />
                    }
                    label="Required ?"
                  />
                </Grid> */}
                {/* <Grid item xs={12} className={classes.paddingEntity}>
                  <Tabs
                    value={this.state.tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange}
                  >
                    <Tab value={1} label="Prompts" />
                    <Tab value={2} label="Hints" />
                  </Tabs>
                  <br />
                  <br />
                </Grid>
                <Grid item xs={12} className={classes.paddingEntity}>
                  {tabValue === 1 && (
                    <ExpansionPrompts {...ExpansionPromptsProps} />
                  )}
                  {tabValue === 2 && (
                    <IntentEntityHelp
                      appStringTable={strData}
                      entityHelp={entityHelp}
                      handleSaveHelpData={handleSaveHelpData}
                      changeLocalStringTable={changeLocalStringTable}
                      lang={lang}
                    />
                  )}
                </Grid> */}
                {/* <Grid item xs={6} className={classes.paddingEntity} /> */}
              </Grid>
            </DialogContent>
          </form>
        </Dialog>
        <AddEntityType {...AddEditEntityTypeProps} />
      </Fragment>
    );
  }
}

AddEditEntity.propTypes = {
  classes: PropTypes.object.isRequired,
  stdTypes: PropTypes.array.isRequired,
  appDATA: PropTypes.object.isRequired,
  appTypes: PropTypes.array.isRequired,
  handleSaveEntityData: PropTypes.func.isRequired,
  changeAppStringTable: PropTypes.func.isRequired,
  allEntities: PropTypes.array.isRequired,
  setStateOfEntityDialog: PropTypes.func.isRequired,
  appStringTable: PropTypes.object,
  editID: PropTypes.number,
  entityData: PropTypes.object,
};

export default AddEditEntity;
