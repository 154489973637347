import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { testClassNames } from "../../../utils/integrationTestClassNames";

const NewAssistatantDomainCard = (props: any) => {
  const { classes, handleCreateClick, state } = props;

  const { isCreatingOrDeleting } = state;

  return (
    <Card
      className={isCreatingOrDeleting ? classes.cardDisabled : classes.cardNew}
      elevation={0}
      onClick={isCreatingOrDeleting ? null : handleCreateClick}
    >
      <CardMedia
        className={`${classes.media} ${testClassNames.HOME_CREATE_NEW_BUDDY}`}
        image="/images/new_template_icon.png"
        title="Add a new assistant"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="body2" component="h4">
          Add Assistant Domain
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NewAssistatantDomainCard;
