import theme from "../theme";
import { commonStyles } from "../appStyles";
import Color from "color";

const minWidth = "1124";
const styles = {
  ...commonStyles,
  dialog: {
    paddingTop: theme.spacing(20),
  },
  logo: {
    height: "auto",
    width: 80,
  },
  dialogWidth: {
    minWidth: 600,
  },
  space: {
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    borderBottom: "1px solid " + theme.palette.primary.medium,
  },
  space2: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  outlinedGreen: {
    border: `1px solid ${theme.palette.secondary.light}`,
    marginRight: 10,
  },
  buttonEdit: {
    width: 28,
    height: 28,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  buttonDelete: {
    width: 28,
    height: 28,
    marginRight: 5,
    color: "red",
  },
  buttonAddValue: {
    width: 40,
    height: 40,
    margin: 10,
    color: theme.palette.primary.main,
  },
  group: {
    // backgroundColor: theme.palette.secondary.main,
    padding: 12,
    marginBottom: 10,
    borderRadius: 10,
    minHeight: 50,
    // borderTop: '1px solid black',
  },
  padRight: {
    paddingRight: 10,
  },
  space3: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
    marginBottom: 2,
  },
  marginal: {
    marginTop: 0,
    marginBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 0,
    minWidth: 900,
  },
  content2: {
    backgroundColor: theme.palette.primary.medium,
  },
  footerButton: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  iconHelp: {
    fontSize: 15,
    marginRight: 5,
    color: theme.palette.secondary.medium,
  },
  iconSearch: {
    fontSize: 15,
  },
  typeRow: {
    margin: 0,
  },
  greenButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.light,
    margin: 20,
  },
  basePaper: {
    minWidth: 300,
    maxWidth: 900,
  },
  table: {
    minWidth: 300,
  },
  tableValues: {
    minWidth: 300,
  },
  tableBody: {
    maxHeight: 600,
  },
  tableColWidth: {
    width: theme.spacing(12),
  },
  progress: {
    paddingTop: 200,
  },
  modal: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: "35%",
    left: "35%",
    borderRadius: 10,
  },
  values: {
    fontSize: "0.8rem",
    "& input": {
      fontSize: "0.8rem",
      padding: 0,
    },
  },
  eTypeButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    // marginRight: 10
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    marginLeft: 10,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  disabled: {},
  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
  checkboxLabel: {
    verticalAlign: "sub",
    marginRight: 8,
  },
  checkbox: {
    height: 24,
  },
  SearchOn: {
    backgroundColor: Color(theme.palette.secondary.medium).fade(0.9).string(),
  },
  versionsList: {
    marginBottom: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  changelogIcon: {
    verticalAlign: "middle",
    fontSize: "60px",
    paddingLeft: "20px",
    cursor: "pointer",
  },
  closeIcon: {
    cursor: "pointer",
  },
  templateDetailsText: {
    color: theme.palette.primary.dark,
    padding: "0",
    margin: "0",
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    minWidth: 400,
    padding: 40,
  },
  DialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    padding: "8px 0",
  },
  DialogContentLeft: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  versionTypeChip: {
    color: theme.palette.primary.light,
  },
  versionNumberChip: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
  },
  createInput: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    width: "100%",
    height: 50,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  cancelButton: {
    width: "100%",
    height: 50,
    color: theme.palette.primary.dark,
  },
  TemplateContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  changelogTextBox: {
    backgroundColor: theme.palette.action.disabledBackground,
    margin: "10px 0",
  },
  changeItem: {
    backgroundColor: theme.palette.grey.A400,
    margin: "10px 0",
    padding: "5px 26px 5px 26px",
    textAlign: "start",
  },
  ChangesList: {
    padding: "0",
  },
};

const main = () => ({
  root: {
    flexGrow: 1,
  },
  wrapText: {
    overflowWrap: "break-word",
  },
  typeFont: {
    fontFamily: "'Work Sans', sans-serif",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paper3: {
    padding: theme.spacing(2),
    textAlign: "left",
    height: "100%",
  },
  paperHelp: {
    padding: theme.spacing(2),
    textAlign: "left",
    overflow: "auto",
    maxWidth: "100%",
    height: "100%",
  },
  promptPaper: {
    minHeight: "70vh",
    width: "100%",
    padding: theme.spacing(2.5),
    paddingBottom: 0,
    textAlign: "left",
  },
  promptContainer: {
    padding: theme.spacing(2.5),
  },
  promptHeading: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  separate: {
    marginTop: theme.spacing(3),
  },
  paper4: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    textAlign: "left",
  },
  paper2: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary.light,
    backgroundColor: theme.palette.fourth.main,
  },
  chip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.light,
    fontSize: "10px",
    height: "25px",
    width: "90px",
  },
  size: {
    width: 15,
    height: 15,
    marginTop: 10,
    marginRight: 5,
  },
  input: {
    width: "100%",
  },
  searchInput: {
    width: "90%",
  },
  outlined: {
    border: "1px solid #4860f6",
    marginRight: 10,
    color: "#4860f6",
  },
  greenButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.light,
    marginRight: 10,
  },
  blueButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.fourth.medium,
    marginRight: 10,
  },
  paper0: {
    padding: theme.spacing(3),
    textAlign: "left",
    backgroundColor: theme.palette.primary.light,
  },
  textImportant: {
    color: theme.palette.primary.main,
    margin: `0 ${theme.spacing(0.5)}px`,
  },
  vertical: {
    width: 30,
  },
  fab: {
    borderRadius: "50%",
    padding: 0,
    minWidth: 20,
    fontSize: 14,
    marginLeft: 15,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  fab2: {
    borderRadius: "50%",
    padding: 0,
    minWidth: 20,
    fontSize: 24,
    marginLeft: 5,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  iconBack: {
    fontSize: 35,
  },
  iconHelp: {
    fontSize: 15,
    marginLeft: 5,
    color: theme.palette.secondary.medium,
  },
  iconSearch: {
    fontSize: 15,
  },
  buttonSearch: {
    marginRight: 15,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  dialogWidth: {
    minWidth: 900,
  },
  buttonEdit: {
    width: 28,
    height: 28,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  intentButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
  },
  buttonDelete: {
    width: 28,
    height: 28,
    marginRight: 5,
    color: "red",
  },
  greendot: {
    width: "10px",
    height: "10px",
    // backgroundColor: '#69ff63',
    color: "#69ff63",
    fontSize: "20px",
  },
  outlinedGreen: {
    border: `1px solid ${theme.palette.secondary.light}`,
    marginRight: 10,
  },
  mainIntent: {
    paddingBottom: theme.spacing(1),
  },
  mainType: {
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  appid: {
    marginBottom: 10,
  },
  icon: {
    fontSize: 15,
  },
  selectOptions: {
    textTransform: "capitalize",
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    minWidth: 400,
    padding: 20,
  },
  intentIcon: {
    fontSize: 90,
    color: theme.palette.primary.main,
  },
  logo: {
    height: "auto",
    width: 150,
    margin: 30,
  },
  createButton: {
    width: "100%",
    backgroundColor: theme.palette.primary.light,
  },
  cancelButton: {
    width: "100%",
    height: 50,
    color: theme.palette.primary.dark,
  },
  textColor: {
    color: theme.palette.primary.dark,
  },
  createInput: {
    color: theme.palette.primary.dark,
    width: 300,
  },
  errorColor: {
    color: theme.palette.primary.light,
  },
  AceEditor: {
    fontFamily:
      "'Monaco', 'Menlo', 'Ubuntu Mono', 'Droid Sans Mono', 'Consolas', monospace !important",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    letterSpacing: "0 !important",
  },
  editAppName: {
    "& input": {
      fontSize: "1.6rem",
      fontWeight: 400,
      fontFamily: "'Work Sans', sans-serif",
      padding: 0,
    },
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    width: "100%",
    height: 50,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  disabled: {},
  highlightButton: {
    marginRight: 8,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
  },
  normalButton: {
    marginRight: 8,
    borderBottom: "2px solid transparent",
    borderRadius: 0,
  },
  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
  intentView: {
    height: "440px",
    overflow: "auto",
  },
  appPlay: {
    float: "right",
  },
  metadataStyle: { fontSize: "0.9rem", color: theme.palette.text.hint },
  [`@media (max-width: ${minWidth}px)`]: {
    intentView: {
      height: "405px",
    },
  },
});

const editMain = () => ({
  root: {
    flexGrow: 1,
    borderBottom: "0px solid #FEF4E8",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paper3: {
    padding: theme.spacing(2),
    textAlign: "left",
    height: "100%",
  },
  paper2: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary.light,
    backgroundColor: theme.palette.fourth.main,
  },
  chip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.light,
    fontSize: "10px",
    height: "25px",
    width: "90px",
  },
  size: {
    width: 15,
    height: 15,
    marginTop: 10,
    marginRight: 5,
  },
  input: {
    width: "100%",
  },
  searchInput: {
    width: 200,
  },
  outlinedRed: {
    border: `1px solid ${theme.palette.secondary.main}`,
    marginRight: 10,
  },
  outlinedGreen: {
    border: `1px solid ${theme.palette.secondary.light}`,
    marginRight: 10,
  },
  greenButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.light,
    marginRight: 10,
  },
  blueButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.fourth.medium,
    marginRight: 0,
    width: 200,
  },
  paper0: {
    padding: theme.spacing(3),
    textAlign: "left",
    backgroundColor: theme.palette.primary.light,
  },
  paperUtter: {
    padding: theme.spacing(3),
    textAlign: "left",
    marginTop: theme.spacing(0),
  },
  paperEntity: {
    padding: theme.spacing(3),
    textAlign: "left",
    marginTop: theme.spacing(3),
  },
  formControl: {
    minWidth: 150,
  },
  vertical: {
    width: 30,
  },
  fab: {
    borderRadius: "50%",
    padding: 0,
    minWidth: 20,
    fontSize: 14,
    marginLeft: 15,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  fab2: {
    borderRadius: "50%",
    padding: 0,
    minWidth: 20,
    fontSize: 24,
    marginLeft: 5,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  iconBack: {
    fontSize: 35,
  },
  iconHelp: {
    fontSize: 15,
    marginRight: 5,
    color: theme.palette.secondary.medium,
  },
  iconSearch: {
    fontSize: 15,
  },
  buttonSearch: {
    marginRight: 15,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  iconEdit: {
    fontSize: 20,
  },
  buttonEdit: {
    width: 28,
    height: 28,
    fontSize: 15,
    margin: 5,
    color: theme.palette.primary.main,
  },

  editIntentDescription: {
    fontFamily: "'Work Sans', sans-serif",
    lineHeight: "1.35417em",
    "& input": {
      fontWeight: "400",
      fontSize: "0.9rem",
      textAlign: "right",
      padding: 0,
    },
  },
  editIntentName: {
    fontFamily: "'Work Sans', sans-serif",
    lineHeight: "1.35417em",
    "& input": {
      fontWeight: "400",
      fontSize: "1.3rem",
      padding: 0,
    },
  },
  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
});

const IntentsTableStyles = () => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(3),
    overflowX: "auto",
    padding: 0,
    textAlign: "left",
    marginBottom: 40,
  },
  typeFont: {
    fontFamily: "'Work Sans', sans-serif",
  },
  iconHelp: {
    fontSize: 15,
    marginLeft: 5,
    color: theme.palette.secondary.medium,
  },
  padding: {
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 600,
    fontSize: "0.9rem",
    // padding: "4px 6px 4px 24px",
  },
  paddingEntity: {
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 500,
    fontSize: "0.9rem",
    // padding: "4px 6px 4px 24px",
  },
  paddingPointer: {
    cursor: "pointer",
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 600,
    fontSize: "0.9rem",
    padding: "4px 6px 4px 24px",
  },
  table: {
    minWidth: 500,
  },
  tableBody: {
    maxHeight: 500,
  },
  tableHead: {
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  tableBodyText: {
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  tableBodyLink: {
    cursor: "pointer",
    fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  greendot: {
    width: "10px",
    height: "10px",
    color: "#69ff63",
    fontSize: "20px",
  },
  icon: {
    fontSize: 10,
    marginRight: 5,
    width: 10,
    height: 10,
  },
  buttonEdit: {
    width: 25,
    height: 25,
    marginRight: 5,
    color: theme.palette.primary.medium,
  },
  buttonDelete: {
    width: 25,
    height: 25,
    marginRight: 5,
    color: "red",
  },
  delete: {
    width: theme.spacing(12),
  },
  edit: {
    width: theme.spacing(20),
    textAlign: "right",
  },
  noborder: {
    borderBottom: "0px solid #FEF4E8",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  form: {
    borderBottom: "0px solid #FEF4E8",
    paddingBottom: theme.spacing(4),
  },
  input: {
    width: "120px",
  },
  inputNumber: {
    width: 40,
  },
  arrowsBot: {
    padding: 0,
    minWidth: 40,
    minHeight: 20,
  },
  arrows: {
    padding: 0,
    minWidth: 20,
    minHeight: 20,
  },
  editInputNumber: {
    width: 40,
    fontSize: "0.8rem",
    "& input": {
      fontSize: "0.8rem",
    },
  },
  editInput: {
    width: "120px",
    fontSize: "0.8rem",
    "& input": {
      fontSize: "0.8rem",
    },
  },
  editInputPrompts: {
    fontSize: "0.8rem",
    "& input": {
      fontSize: "0.8rem",
    },
  },
  fullWidth: {
    width: "100%",
  },
  addEntity: {
    color: theme.palette.secondary.dark,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  formControl: {
    minWidth: 150,
  },
  floatControl: {
    minWidth: 150,
    padding: 10,
    border: `1px solid ${theme.palette.primary.medium}`,
  },
  alignRight: {
    textAlign: "right",
  },
  editPadding: {
    width: theme.spacing(20),
    paddingLeft: 30,
  },
  alignButton: {
    textAlign: "right",
  },
  space2: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginTop: 10,
  },
  searchInput: {
    width: "200px",
  },
  buttonSearch: {
    marginRight: 15,
    boxShadow: theme.shadows[0],
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
  iconSearch: {
    fontSize: 15,
  },
  searchHead: {
    borderBottom: "none",
    height: 75,
  },
  group: {
    backgroundColor: theme.palette.primary.medium,
    padding: 12,
    marginBottom: 10,
    borderRadius: 10,
    height: 50,
    // borderTop: '1px solid black',
  },
  space3: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginBottom: 10,
  },
  marginal: {
    marginTop: 30,
    marginBottom: 30,
    padding: 20,
  },
  content2: {
    backgroundColor: theme.palette.primary.medium,
  },
  footerButton: {
    textAlign: "center",
  },
  typeRow: {
    margin: 20,
  },
  greenButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.light,
    margin: 20,
  },
  utterEdit: {
    display: "inherit",
    minWidth: 400,
  },
  utterHide: {
    display: "none",
  },
  entityMenuItem: {
    padding: 15,
    maxWidth: 400,
    borderBottom: "1px solid #e6e6e6",
    cursor: "pointer",
    "&:hover": {},
  },
  entityMenuItemSelected: {
    padding: 15,
    maxWidth: 400,
    borderBottom: "1px solid #e6e6e6",
    backgroundColor: theme.palette.secondary.main,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
  },
  menuItemSelected: {
    padding: 15,
    width: 245,
    borderBottom: "1px solid #e6e6e6",
  },

  entityMenuItemSearch: {
    padding: 15,
    width: 248,
    borderBottom: "1px solid #e6e6e6",
    cursor: "pointer",
    backgroundColor: "white",
  },
  utterNew: {
    display: "inherit",
    minWidth: "30em",
    padding: 5,
    borderBottom: "1px solid green",
    "&::-moz-selection": {
      backgroundColor: "lightgreen",
    },
    "& :before": {
      content: "",
      fontSize: 20,
    },
  },
  "@media only screen and (min-width: 960px) and (max-width: 1060px)": {
    utterNew: {
      minWidth: "25em",
    },
  },
  addEntityButtonInDropdown: {
    "&:hover": { backgroundColor: "transparent" },
    paddingLeft: 0,
    paddingRight: 0,
  },
  contextMenu: {
    display: "block",
    minWidth: 220,
    maxHeight: 400,
    overflow: "auto",
    zIndex: 20000,
  },
  pointerPadding: {
    padding: "4px 6px 4px 24px",
    cursor: "pointer",
  },
  pointer: {
    cursor: "pointer",
  },
  quotesMd: {
    position: "relative",
    top: "-10px",
    display: "table-cell",
    fontSize: 15,
    paddingLeft: 5,
  },
  quotesSm: {
    position: "relative",
    top: "-5px",
    display: "table-cell",
    fontSize: 10,
    paddingLeft: 5,
  },
  contextAvail: {
    display: "inherit",
    cursor: "context-menu",
    borderBottom: "1px solid green",
  },
  intentButton: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
  },
  highlightButton: {
    marginRight: 8,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
  },
  normalButton: {
    marginRight: 8,
    borderBottom: "2px solid transparent",
    borderRadius: 0,
  },
  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
  checkboxLabel: {
    verticalAlign: "sub",
    marginRight: 8,
  },
  languageOn: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: 5,
    borderRadius: 10,
    padding: 5,
  },
  languageChip: {
    marginRight: 5,
  },
  avatarRoot: {
    width: 28,
    height: 28,
    fontSize: "0.9rem",
  },
  chipLabel: {
    padding: "0 7px 0 3px",
  },
  parentRow: {
    "& .hidden": {
      display: "none",
    },
    "&:hover .hidden": {
      display: "block",
    },
  },
  editButton: {
    width: 20,
    height: 20,
    marginLeft: 10,
    "& svg": {
      width: "0.8em",
      height: "0.8em",
    },
  },
  triggerButton: {
    width: "1.5em",
    height: "1.5em",
    fontSize: "1.5em",
  },
  triggerButtonIcon: {
    fontSize: "inherit",
    // color: theme.palette.secondary.medium,
    "& svg": {
      fontSize: "inherit",
      color: "inherit",
    },
  },
  buttonSpan: {
    display: "inherit",
  },
});

export { styles, main, IntentsTableStyles, editMain };
