import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Icon } from "@material-ui/core";

const styles = makeStyles(theme => ({
  debuggerNotify: {
    ...theme.mixins.gutters(),
    padding: "1px 16px 4px",
    fontSize: "0.8em",
    verticalAlign: "middle",
    lineHeight: 2,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  unsaved: {
    borderLeft: `5px solid ${theme.palette.secondary.medium}`,
    lineHeight: 2,
    "& p": {
      color: theme.palette.secondary.medium,
    },
  },
  saved: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    lineHeight: 2,
    "& p": {
      color: theme.palette.primary.main,
    },
  },
  error: {
    borderLeft: `5px solid ${theme.palette.error.main}`,
    lineHeight: 2,
    "& p": {
      color: theme.palette.error.main,
    },
  },
}));

const MessageWrapper = props => {
  // Props
  const { initState } = props;

  const isReady = initState === 1;
  const isLoading = initState === 0;
  const hasFailed = initState < 0;

  const classes = styles();

  return (
    <Paper
      className={`${classes.debuggerNotify} ${
        isLoading
          ? classes.unsaved
          : isReady
          ? classes.saved
          : hasFailed
          ? classes.error
          : null
      }`}
      elevation={1}
    >
      {isLoading ? (
        <Typography>
          {" "}
          <Icon style={{ fontSize: "1em", paddingTop: 2 }}>error_outlined</Icon>
          {" Loading Playground..."}
        </Typography>
      ) : isReady ? (
        <Typography>
          <Icon style={{ fontSize: "1em", paddingTop: 2 }}>done</Icon>{" "}
          {" Ready "}
        </Typography>
      ) : hasFailed ? (
        <Typography className={classes.title} color="error">
          {"Error initializing"}
        </Typography>
      ) : null}
    </Paper>
  );
};

MessageWrapper.propTypes = {
  initState: PropTypes.number.isRequired,
};

export default MessageWrapper;
