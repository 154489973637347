import theme from "../theme";
import { commonStyles } from "../appStyles";

const styles = () => ({
  ...commonStyles(),
  dialog: {
    paddingTop: theme.spacing(20),
  },
  logo: {
    height: "auto",
    width: 80,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "left",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(3),
  },
  space: {
    padding: theme.spacing(3),
    borderBottom: "1px solid " + theme.palette.secondary.main,
  },
});

export { styles };
