import {
  Grid,
  Card,
  Typography,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import Can from "../../../utils/rbac/Can";
import AllPermissions from "../../../utils/rbac/rbac-rules";

import { Link } from "react-router-dom";

import { templatesInterface } from "./AssistantTemplateTypes";

interface AssistantTemplateCardsInterface {
  classes: { [key: string]: any };
  state: {
    isCreatingOrDeleting: boolean | undefined;
    templates: templatesInterface;
  };
}

const AssistanTemplateCards = (props: AssistantTemplateCardsInterface) => {
  const { classes, state } = props;
  const { templates, isCreatingOrDeleting } = state;

  const dTemplates: templatesInterface = templates.filter(
    obj => obj.belongsToDomain
  );
  const eTemplates: templatesInterface = templates.filter(
    obj => !obj.belongsToDomain
  );

  return (
    <>
      <Typography variant="h5" align="left">
        {" "}
        {"Domain Templates"}{" "}
      </Typography>
      <hr></hr>
      <Grid container spacing={1}>
        {dTemplates.map((item, key) => {
          const hover = "hover";
          const isLoading = isCreatingOrDeleting;
          const pathName = Can({
            perform: AllPermissions.ASSISTANT_CONFIG.EDIT,
            yes: () => `/home/assistant_templates/${item.id}`,
            no: () => `/home/assistant_templates/${item.id}/analytics`,
          });
          return (
            <Grid
              key={item.name + key}
              item
              xs={12}
              md={3}
              sm={4}
              lg={2}
              xl={1}
              id={key + hover}
            >
              <div className={`${classes.parentCard}`}>
                <Card
                  className={`${
                    isLoading ? classes.cardDisabled : classes.card
                  }`}
                  elevation={0}
                >
                  <Link
                    to={
                      isLoading
                        ? "/home"
                        : {
                            pathname: pathName,
                          }
                    }
                  >
                    <CardMedia
                      className={
                        isLoading ? classes.mediaDisabled : classes.media
                      }
                      image={"/images/graphic_assistant_template.png"}
                      title={item.name}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="body2"
                        component="h4"
                        noWrap
                      >
                        {item.name}
                      </Typography>
                    </CardContent>
                  </Link>
                  <span className={classes.buttonSpan}>
                    <span className={isLoading ? "hide" : "hidden"}>
                      {/* <Link to={isLoading ? "/home" : "/home/" + item.name}>
                  <IconButton
                    id={key + hover + 2}
                    className={classes.buttonEdit}
                    aria-label="Edit"
                    title="Edit Assistant"
                  >
                    <EditIcon id={key + hover + 3} />
                  </IconButton>
                </Link> */}
                      {/* <span>
                  <IconButton
                    id={key + hover + 5}
                    className={classes.buttonClone}
                    aria-label="Clone"
                    title="Clone this Assistant"
                    onClick={
                      isLoading
                        ? null
                        : handleCloneClick.bind(this, item.id, item.name)
                    }
                  >
                    <CopyIcon id={key + hover + 5} />
                  </IconButton>
                </span> */}
                      {/* <span>
                  <IconButton
                    id={key + hover + 4}
                    className={classes.buttonDelete}
                    onClick={
                      isLoading
                        ? null
                        : handleDeleteClick.bind(this, item.id, item.name)
                    }
                    aria-label="Delete"
                    title="Delete Template"
                  >
                    <DeleteIcon id={key + hover + 5} />
                  </IconButton>
                </span> */}
                    </span>
                  </span>
                </Card>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <Typography variant="h5" align="left">
        {" "}
        {"Experimental Templates"}{" "}
      </Typography>
      <hr></hr>
      <Grid container spacing={1}>
        {eTemplates.map((item, key) => {
          const hover = "hover";
          const isLoading = isCreatingOrDeleting;
          const pathName = Can({
            perform: AllPermissions.ASSISTANT_CONFIG.EDIT,
            yes: () => `/home/assistant_templates/${item.id}`,
            no: () => `/home/assistant_templates/${item.id}/analytics`,
          });
          return (
            <Grid
              key={item.name + key}
              item
              xs={12}
              md={3}
              sm={4}
              lg={2}
              xl={1}
              id={key + hover}
            >
              <div className={`${classes.parentCard}`}>
                <Card
                  className={`${
                    isLoading ? classes.cardDisabled : classes.card
                  }`}
                  elevation={0}
                >
                  <Link
                    to={
                      isLoading
                        ? "/home"
                        : {
                            pathname: pathName,
                          }
                    }
                  >
                    <CardMedia
                      className={
                        isLoading
                          ? classes.mediaDisabled
                          : item.belongsToDomain
                          ? classes.mediaBelongsToDomain
                          : classes.media
                      }
                      image={"/images/graphic_assistant_template.png"}
                      title={item.name}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="body2"
                        component="h4"
                        noWrap
                      >
                        {item.name}
                      </Typography>
                    </CardContent>
                  </Link>
                </Card>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AssistanTemplateCards;
