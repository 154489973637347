import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { emptyUIHintsState } from "../../../../utils/MiscUtils";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const initialData = {
  name: "",
  description: "",
  displayName: "",
  appStateName: "",
  conditionName: "",
  parameters: [""],
  isTerminal: false,
  uiHints: [],
  completionContext: {
    type: "completion",
    data: "",
  },
  completion: {
    successTemplates: [
      {
        isId: false,
        value: "New_AppState_Prompt_Placeholder",
        severity: 1,
      },
    ],
    failureTemplates: [],
  },
};

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: 10,
  },
}));

function AddAppStatesForm(props) {
  // When creating a new app state, make sure not to populate disabled UJ.
  const { enabledSD } = useSelector(state => ({
    enabledSD: state.appSchema.appDATA.subDomains.filter(SD => SD.enabled),
  }));

  const {
    appStateUID,
    selectedAppState,
    userJourney,
    open,
    userJourneyID,
    handleSaveClose,
  } = props;
  const classes = useStyles();

  const [selectError, setSelectError] = useState(null);
  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [name, setName] = useState("");
  const [conditionName, setConditionName] = useState("");
  const [appStateName, setAppStateName] = useState("");
  const [isTerminal, setIsTerminal] = useState(false);
  const isEditMode = selectedAppState !== undefined;

  useEffect(() => {
    if (isEditMode) {
      const selectedAppStateData =
        userJourney.appStatesMetadata[selectedAppState];
      setDescription(selectedAppStateData.description);
      setDisplayName(selectedAppStateData.displayName);
      setName(selectedAppStateData.name);
      setConditionName(selectedAppStateData.conditionName);
      setAppStateName(selectedAppStateData.appStateName);
      setIsTerminal(selectedAppStateData.isTerminal);
    }
  }, [appStateUID]);
  useEffect(() => {
    return () => {
      if (!open) {
        setDisplayName("");
        setName("");
        setDescription("");
        setSelectError(null);
      }
    };
  }, [open]);

  const handleToggle = event => {
    setIsTerminal(v => !v);
  };
  const handleChangeDisplayName = event => {
    const text = event.target.value;
    if (text.length < 140) setDisplayName(text);
  };
  const handleChangeConditionName = event => {
    const text = event.target.value;
    if (text.length < 140) {
      const currentConditionName = text.replace(/ /g, "_").toLowerCase();
      setConditionName(currentConditionName);
      setName(`${userJourney.name}_${appStateName}_${currentConditionName}`);
    }
  };
  const handleChangeAppStateName = event => {
    const text = event.target.value;
    if (text.length < 140) {
      const currentAppStateName = text.replace(/ /g, "_").toLowerCase();
      setAppStateName(currentAppStateName);
      setName(`${userJourney.name}_${currentAppStateName}_${conditionName}`);
    }
  };
  const handleChangeName = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDisplayName(text.replace(/_/g, " "));
      const currentName = text.replace(/ /g, "_");
      const doesNameExist = userJourney.appStatesMetadata.find(
        ap => ap.name === currentName
      );

      if (doesNameExist) {
        setSelectError("Sub Domain name exists");
      } else {
        setSelectError(null);
      }
      setName(currentName);
    }
  };
  const handleChangeDescription = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDescription(text);
    }
  };

  const handleSubmitData = () => {
    const newAppStatesMetadata = userJourney.appStatesMetadata;

    const payload = {
      name,
      description,
      displayName,
      appStateName,
      conditionName,
      isTerminal,
    };

    if (isEditMode) {
      newAppStatesMetadata[selectedAppState] = {
        ...newAppStatesMetadata[selectedAppState],
        ...payload,
      };
    } else {
      newAppStatesMetadata[newAppStatesMetadata.length] = {
        ...initialData,
        ...payload,
        uiHints: emptyUIHintsState(enabledSD),
      };
    }

    userJourney.appStatesMetadata = newAppStatesMetadata;
    handleSaveClose(userJourney, userJourneyID);
  };
  const isDisabled =
    name.length < 2 || displayName.length < 2 || description.length < 3;
  return (
    <div>
      <form noValidate autoComplete="off">
        <Grid spacing={3} alignContent="center" alignItems="center" container>
          <Grid xs={6} item>
            <TextField
              id="standard-name"
              label="App State Name"
              name="appStateName"
              value={appStateName}
              onChange={handleChangeAppStateName}
              error={selectError !== null}
              helperText={selectError}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="standard-name"
              label="Condition Name"
              name="conditionName"
              value={conditionName}
              onChange={handleChangeConditionName}
              error={selectError !== null}
              helperText={selectError}
              fullWidth
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="standard-name"
              label="Name"
              name="name"
              value={name}
              onChange={handleChangeName}
              error={selectError !== null}
              helperText={selectError}
              contentEditable={false}
              fullWidth
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="standard-display-name"
              label="Display Name"
              name="displayName"
              value={displayName}
              onChange={handleChangeDisplayName}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="standard-description"
              label="Description"
              name="description"
              value={description}
              onChange={handleChangeDescription}
              fullWidth
            />
          </Grid>
          <Grid xs={6} item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isTerminal}
                  onClick={handleToggle}
                  label="Hello"
                />
              }
              label={"isTerminal"}
            />
          </Grid>
          <Grid xs={12} item>
            <Button
              disabled={selectError !== null}
              onClick={handleSubmitData}
              color="primary"
              fullWidth
            >
              {isEditMode ? "Save" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

AddAppStatesForm.propTypes = {
  handleSaveClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userJourney: PropTypes.object.isRequired,
  userJourneyID: PropTypes.number.isRequired,
};

export default AddAppStatesForm;
