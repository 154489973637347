import AppConfig from "../../app.config";
import * as SlangUser from "../../libs/slang/slang_account_actions";
import * as types from "../types";
import * as helper from "./helper";

export const userActions = store => next => action => {
  if (!action.meta || action.meta.type !== "user") {
    return next(action);
  }

  const { profile, selectedPlan, currentPlan, email_id } = action.payload;

  const host = AppConfig.SLANG_HOST;
  const {
    apikey: { api_key, api_secret },
  } = store.getState();

  if (!api_secret || !api_key || !host) {
    const payload = {
      ...action.payload,
      error: "Error: No user credentials",
    };

    helper.prepareAction(action, payload, store);
  } else {
    switch (action.type) {
      case types.USER_CREATE:
        SlangUser.createAccount(host, api_key, api_secret, email_id)
          .then(result => {
            if (result && result.success) {
              const payload = {
                error: null,
              };
              helper.prepareActionLocal(action, payload, store);
            } else {
              const payload = {
                email_id,
                error: result.message
                  ? result.message
                  : "Error creating account!",
              };
              // log error in console log
              console.log(payload.error.toString());
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              email_id: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_GET_PROFILE:
        SlangUser.getAccountProfile(host, api_key, api_secret)
          .then(result => {
            if (result && result.success) {
              const setItem = [
                {
                  key: "U_profile",
                  value: JSON.stringify(result.raw_json_response),
                },
              ];
              const payload = {
                profile: result.raw_json_response,
                error: null,
              };

              helper.prepareActionLocal(action, payload, store, setItem);
            } else {
              const payload = {
                error: result.message
                  ? result.message
                  : "Error getting profile!",
              };

              console.log(payload.error.toString());
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              profile: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_GET_PLAN:
        SlangUser.getAccountPlan(host, api_key, api_secret)
          .then(result => {
            if (result && result.success) {
              const setItem = [
                {
                  key: "U_profile",
                  value: JSON.stringify(result.raw_json_response),
                },
              ];
              const payload = {
                currentPlan: result.raw_json_response,
                error: null,
              };

              helper.prepareActionLocal(action, payload, store, setItem);
            } else {
              const payload = {
                error: result.message
                  ? result.message
                  : "Error getting profile!",
              };

              console.log(payload.error.toString());
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              currentPlan: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_UPDATE_PROFILE:
        let localProfile = localStorage.getItem("U_profile");
        localProfile = localProfile
          ? JSON.parse(localProfile)
          : { email_id: null, owner_name: null, company_name: null };
        SlangUser.updateAccountProfile(host, api_key, api_secret, profile)
          .then(result => {
            if (result && result.success) {
              const setItem = [
                {
                  key: "U_profile",
                  value: JSON.stringify(profile),
                },
              ];
              const payload = {
                profile,
                isProfileUpdated: "YES",
                error: null,
              };
              helper.prepareActionLocal(action, payload, store, setItem);
            } else {
              const payload = {
                profile: localProfile,
                isProfileUpdated: "NO",
                error: result.message
                  ? result.message
                  : "Error Updating profile!",
              };
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              isProfileUpdated: "NO",
              profile: localProfile,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_UPDATE_PLAN:
        SlangUser.updateAccountPlan(host, api_key, api_secret, {
          primary_plan: selectedPlan,
          discounts: [],
        })
          .then(result => {
            if (result && result.success) {
              const payload = {
                selectedPlan,
                currentPlan: result.raw_json_response,
                isPlanUpdated: true,
                error: null,
              };
              helper.prepareActionLocal(action, payload, store);
            } else {
              const payload = {
                selectedPlan,
                currentPlan,
                isPlanUpdated: "NO",
                error: result.message ? result.message : "Error updating plan!",
              };
              console.log(payload.error);

              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            const error = err.toString();
            const payload = {
              selectedPlan,
              currentPlan,
              isPlanUpdated: "NO",
              error: error || "Error updating plan!",
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_GET_ALL_PLANS:
        SlangUser.getAllPlans(host, api_key, api_secret)
          .then(result => {
            if (result && result.success) {
              const setItem = [
                {
                  key: "U_allPlans",
                  value: JSON.stringify(result.raw_json_response),
                },
              ];
              const payload = {
                allPlans: result.raw_json_response,
                error: null,
              };
              helper.prepareActionLocal(action, payload, store, setItem);
            } else {
              const payload = {
                allPlans: null,
                error: result.message
                  ? result.message
                  : "Error getting all plans!",
              };
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              allPlans: null,
              errorPlans: err.toString(),
              // error: err.toString()
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_SESSIONS_USAGE:
        const args = AppConfig.accountSessionDate();
        SlangUser.getAccountSessionsUsage(host, api_key, api_secret, args)
          .then(result => {
            if (result && result.success) {
              const payload = {
                userSessions: result.raw_json_response,
                error: null,
              };
              helper.prepareActionLocal(action, payload, store);
            } else {
              const payload = {
                userSessions: null,
                error: result.message
                  ? result.message
                  : "Error getting transactions!",
              };
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              userSessions: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_TRANSC_USAGE:
        SlangUser.getAccountTransactionsUsage(host, api_key, api_secret)
          .then(result => {
            if (result && result.success) {
              const payload = {
                userTransactions: result.raw_json_response,
                error: null,
              };
              helper.prepareActionLocal(action, payload, store);
            } else {
              const payload = {
                userTransactions: null,
                error: result.message
                  ? result.message
                  : "Error getting transactions!",
              };
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              userTransactions: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_INTENT_USAGE:
        SlangUser.getAccountIntentsUsage(host, api_key, api_secret)
          .then(result => {
            if (result && result.success) {
              const payload = {
                userIntents: result.raw_json_response,
                error: null,
              };
              helper.prepareActionLocal(action, payload, store);
            } else {
              const payload = {
                userIntents: null,
                error: result.message
                  ? result.message
                  : "Error getting intent usage!",
              };
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              userIntents: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_GET_PRIVLG:
        SlangUser.getAccountPrivileges(host, api_key, api_secret)
          .then(result => {
            if (result && result.success) {
              const payload = {
                userPriviledge: result.raw_json_response,
                error: null,
              };
              helper.prepareActionLocal(action, payload, store);
            } else {
              const payload = {
                userPriviledge: null,
                error: result.message
                  ? result.message
                  : "Error getting intent usage!",
              };
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            console.log(err.toString());

            const payload = {
              userPriviledge: null,
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store);
          });
        break;
      case types.USER_ONBOARDING:
        const payload = action.payload;
        helper.prepareAction(action, payload, store);
        break;
      default:
        break;
    }
  }
};

export default userActions;
