import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import IconButton from "@material-ui/core/IconButton";
import RemoveForever from "@material-ui/icons/RemoveCircleOutlineOutlined";
import ViewSubDomainDialogWithResource from "./ViewSubDomainDialogWithResource";
import EditDataType from "./EditDataType";
import UploadDialog from "./UploadDialog";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  iconButton: {
    marginRight: 10,
  },
  checkbox: {
    marginLeft: 20,
  },
}));
function SubDomainDataList(props) {
  const {
    subDomain,
    subDomainID,
    dispatchSubdomainChange,
    handleDeleteDataType,
    handleEditAppData,
    subdomains,
  } = props;
  const classes = useStyles();

  const handleDataTypeCheckBox = (subDomainName, dataTypeName) => {
    subdomains.map((subDomain, ind) => {
      if (subDomain.name === subDomainName) {
        if (subDomain.userUpdatableData.includes(dataTypeName)) {
          const index = subDomain.userUpdatableData.indexOf(dataTypeName);
          subDomain.userUpdatableData.splice(index, 1);
        } else {
          subDomain.userUpdatableData.push(dataTypeName);
        }
      }
    });
    handleEditAppData(subdomains, "subDomains");
  };

  const EditDataTypeProps = {
    subDomainID,
    subDomain,
    handleEditAppData,
    subdomains,
  };
  return (
    <>
      <List>
        {subDomain?.data?.map((dataType, dataID) => {
          const userDataID = subDomain.userData
            ?.map((UD, key) => {
              if (UD.name === dataType.name) {
                return key;
              }
              return null;
            })
            .find(itm => itm !== null);
          const isUserData = userDataID !== undefined;
          const displayName = dataType.displayName;
          const dataName = dataType.name;
          const subDomainName = subDomain.name;
          return (
            <ListItem key={dataType.name}>
              <Checkbox
                color="primary"
                checked={subDomain.userUpdatableData.includes(dataType.name)}
                onClick={() => {
                  handleDataTypeCheckBox(subDomain.name, dataType.name);
                }}
                title="Enable this to allow users to edit this data type"
                className={classes.checkbox}
              />
              <EditDataType {...{ ...EditDataTypeProps, dataType, dataID }} />
              <ListItemSecondaryAction>
                <UploadDialog
                  {...{
                    assistantType: "template",
                    dataID,
                    subDomainID,
                    displayName,
                    subDomainName,
                    dataName,
                    dispatchSubdomainChange,
                  }}
                />

                <ViewSubDomainDialogWithResource
                  aria-label="View"
                  className={classes.iconButton}
                  title={`${subDomainName} - ${displayName}`}
                  dataValues={dataType.value}
                  assistantType="template"
                  userValues={
                    isUserData ? subDomain.userData[userDataID].value : null
                  }
                />

                <IconButton
                  aria-label="Delete"
                  title={`Delete ${displayName}  for ${subDomainName} subdomain`}
                  onClick={handleDeleteDataType({
                    subDomainID,
                    dataID,
                    name: dataType.name,
                  })}
                  className={classes.iconButton}
                >
                  <RemoveForever />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

SubDomainDataList.propTypes = {
  subDomain: PropTypes.object.isRequired,
  subDomainID: PropTypes.number.isRequired,
  handleToggleReplace: PropTypes.func.isRequired,
  handleDeleteUserData: PropTypes.func.isRequired,
  dispatchSubdomainChange: PropTypes.func.isRequired,
};

export default SubDomainDataList;
