import * as types from "../types";
const SlangStorage = require("../../libs/utils/slang_storage");

const app_LIST = sessionStorage.getItem("A_list")
  ? JSON.parse(sessionStorage.getItem("A_list"))
  : [];
const stdTypes = sessionStorage.getItem("A_types")
  ? JSON.parse(sessionStorage.getItem("A_types"))
  : [];

const app_DATA = {};
const app_stringTable = null;
const isDirty = false;

const hasNameChanged = sessionStorage.getItem("A_hasNameChanged")
  ? sessionStorage.getItem("A_hasNameChanged")
  : "false";
const schemaDefinition = sessionStorage.getItem("D_schema")
  ? sessionStorage.getItem("D_schema")
  : null;

export const initialState = () => ({
  list: app_LIST,
  error: null,
  stdTypes,
  isDirty,
  hasNameChanged: hasNameChanged === "true",
  createAppName: null,
  withTemplate: null,
  deleteAppID: null,
  appID: null,
  schemaDefinition,
  appDATA: app_DATA,
  appMetadata: null,
  appStringTable: app_stringTable,
});

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.APP_LIST:
      return {
        ...state,
        list: action.payload.list,
        error: action.payload.error,
      };
    case types.DELETE_APP_LIST:
      sessionStorage.removeItem("A_list");
      return {
        ...state,
        list: [],
      };
    case types.APP_DATA_GET_TYPES:
      return {
        ...state,
        stdTypes: action.payload.stdTypes,
        error: action.payload.error,
      };
    case types.APP_CREATE:
      return {
        ...state,
        appID: action.payload.appID,
        createAppName: action.payload.createAppName,
        withTemplate: action.payload.withTemplate,
        error: action.payload.error,
      };
    case types.APP_CLONE:
      return {
        ...state,
        appID: action.payload.appID,
        createAppName: action.payload.createAppName,
        error: action.payload.error,
      };
    case types.APP_DELETE:
      return {
        ...state,
        appID: action.payload.appID,
        deleteAppName: action.payload.deleteAppID,
        error: action.payload.error,
      };
    case types.APP_DATA:
      return {
        ...state,
        appID: action.payload.appID,
        appDATA: action.payload.appDATA,
        error: action.payload.error,
      };
    case types.APP_METADATA:
      return {
        ...state,
        appID: action.payload.appID,
        appMetadata: action.payload.appMetadata,
        error: action.payload.error,
      };
    case types.APP_METADATA_TRAIN_STATUS:
      return {
        ...state,
        appID: action.payload.appID,
        appMetadataTrainStatus: action.payload.appMetadataTrainStatus,
        error: action.payload.error,
      };
    case types.APP_STRING_TABLE:
      return {
        ...state,
        appID: action.payload.appID,
        appStringTable: action.payload.appStringTable,
        error: action.payload.error,
      };
    case types.APP_NAME_CHANGE:
      sessionStorage.setItem(
        "A_hasNameChanged",
        action.payload.hasNameChanged.toString()
      );

      return {
        ...state,
        hasNameChanged: action.payload.hasNameChanged,
      };
    case types.APP_DATA_CHANGE:
      sessionStorage.setItem("A_isDirty", "true");
      try {
        sessionStorage.setItem(
          "A_data",
          JSON.stringify(action.payload.appDATA)
        );
      } catch (error) {
        // needed to catch errors when schema is larger than 10MB
        console.log(error);
      }

      return {
        ...state,
        appDATA: action.payload.appDATA,
        isDirty: true,
      };
    case types.APP_STRING_TABLE_CHANGE:
      sessionStorage.setItem("A_isDirty", "true");
      try {
        sessionStorage.setItem(
          "A_stringTable",
          JSON.stringify(action.payload.appStringTable)
        );
      } catch (error) {
        // needed to catch errors when schema is larger than 10MB
        console.log(error);
      }
      return {
        ...state,
        appStringTable: action.payload.appStringTable,
        isDirty: true,
      };
    case types.APP_LIST_EDIT:
      sessionStorage.setItem("A_list", JSON.stringify(action.payload.appList));
      return {
        ...state,
        list: action.payload.appList,
      };

    case types.APP_DATA_SAVED:
      sessionStorage.setItem("A_isDirty", "false");
      return {
        ...state,
        isDirty: false,
      };
    case types.APP_DATA_CLEAR:
      sessionStorage.removeItem("A_data");
      sessionStorage.removeItem("A_stringTable");
      sessionStorage.setItem("A_isDirty", "false");
      SlangStorage.removeItem("app_id", "analytics");
      SlangStorage.removeItem("app_name", "analytics");
      return {
        ...state,
        appDATA: {},
        appStringTable: {},
        appID: null,
        isDirty: false,
      };
    case types.APP_SCHEMA_DEFINITION:
      return {
        ...state,
        schemaDefinition: action.payload.schemaDefinition,
        error: action.payload.error,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
