import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import VerticalTabsAppStates from "./VerticalTabsAppStates";
import SelectList from "./SelectList";
import { Grid, Icon } from "@material-ui/core";
import DeleteConfirmButton from "../DeleteConfirmButton";
import AddDialog from "../AddDialog";
import AddAppStatesForm from "./AddAppStatesForm";

const styles = theme => ({
  iconButton: {
    marginRight: 20,
  },
  dialogHeading: {
    textTransform: "capitalize",
  },
  content: {
    padding: `0 ${theme.spacing(1)}px`,
    minHeight: 720,
    overflow: "hidden",
  },
  closeButton: {
    float: "right",
  },
});

function EditUserJourneyDialog({
  classes,
  disabled,
  appStatesMetadata,
  userJourneyID,
  userJourney,
  subDomains,
  userJourneyName,
  changeUserJourneyData,
  changeSubDomainData,
}) {
  const [open, setOpen] = useState(false);
  const [allStates, setAllStates] = useState(appStatesMetadata);
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedAppState, setSelectedAppState] = useState(0);
  const [selectedCondition, setSelectedCondition] = useState(0);
  const [allAppStates, setAllAppStates] = useState([]);
  const [allConditions, setAllConditions] = useState([]);

  const updateStates = (appStates = allStates) => {
    const newAppStatesMetadata = appStates.map((item, ID) => ({
      ...item,
      idx: ID,
    }));

    setAllStates(newAppStatesMetadata);

    const appStateNames = [
      ...new Set(newAppStatesMetadata.map(item => item.appStateName)),
    ];
    const conditionNames = [
      ...new Set(
        newAppStatesMetadata
          .filter(item => item.appStateName === appStateNames[selectedAppState])
          .map(item => item.conditionName)
      ),
    ];

    setAllAppStates(appStateNames);
    setAllConditions(conditionNames);

    const _appState = newAppStatesMetadata
      .filter(item => item.appStateName === appStateNames[selectedAppState])
      .find(
        item =>
          item.conditionName ===
          (conditionNames[selectedCondition] || conditionNames[0])
      );

    if (!conditionNames[selectedCondition]) setSelectedCondition(0);

    setSelectedValue(_appState ? _appState.idx : 0);
  };

  useEffect(() => {
    if (open) {
      const newAppStatesMetadata = appStatesMetadata.map((item, ID) => ({
        ...item,
        uid: ID,
      }));
      setAllStates(newAppStatesMetadata);
      updateStates(newAppStatesMetadata);
    }
  }, [open, selectedAppState, selectedCondition]);

  useEffect(() => {
    setSelectedCondition(0);
  }, [selectedAppState]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const newAppStatesMetadata = allStates.filter(
      (_, id) => selectedValue !== id
    );

    newAppStatesMetadata.forEach(item => {
      delete item.idx;
      delete item.uid;
    });

    userJourney.appStatesMetadata = newAppStatesMetadata;

    changeUserJourneyData(userJourney, userJourneyID);
    setSelectedAppState(0);
    updateStates(newAppStatesMetadata);
  };

  const verticalTabsAppStatesProps = {
    appStateUID: allStates[selectedValue].uid,
    userJourneyID,
    userJourney,
    userJourneyName,
    appStatesMetadata,
    changeUserJourneyData,
    selectedValue,
    subDomains,
    changeSubDomainData,
  };

  const handleAddNewAppState = (userJourneysData, newUserJourneyID) => {
    const newAppStatesMetadata = userJourneysData.appStatesMetadata;
    changeUserJourneyData(userJourneysData, newUserJourneyID);
    updateStates(newAppStatesMetadata);
  };

  const addDialogProps = {
    iconButton: true,
    handleEditAppData: handleAddNewAppState,
    Component: AddAppStatesForm,
  };

  return (
    <>
      <IconButton
        aria-label="View"
        className={classes.iconButton}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <Icon>{"list_alt"}</Icon>
      </IconButton>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        // fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeading}>
          {`Edit: ${userJourneyName}`}
          {" App States"}
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid alignContent="center" alignItems="center" spacing={3} container>
            <Grid xs={1} item></Grid>
            <Grid xs={1} item>
              <AddDialog
                title={`${userJourneyName}: Add App State`}
                userJourneyID={userJourneyID}
                userJourney={userJourney}
                {...addDialogProps}
              />
            </Grid>
            <Grid xs={4} item>
              <SelectList
                label={"App States"}
                noValueString={"No App State"}
                listItems={allAppStates}
                selectedValue={selectedAppState}
                setSelectedValue={setSelectedAppState}
              />
            </Grid>
            <Grid xs={4} item>
              <SelectList
                label={"Condition"}
                noValueString={"No Condition"}
                listItems={allConditions}
                selectedValue={selectedCondition}
                setSelectedValue={setSelectedCondition}
              />
            </Grid>
            <Grid xs={2} item>
              <DeleteConfirmButton
                handleDelete={handleDelete}
                deleteThis={`${allStates[selectedValue].name} app state`}
              />
            </Grid>

            <Grid xs={12} item>
              <VerticalTabsAppStates {...verticalTabsAppStatesProps} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

EditUserJourneyDialog.propTypes = {
  classes: PropTypes.object,
  appStatesMetadata: PropTypes.array,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(EditUserJourneyDialog);
