import {
  StorefrontRounded,
  FlightRounded,
  AccountBalanceRounded,
  LocalGroceryStoreRounded,
  LocalHospitalRounded,
  TrainRounded,
  FlightTakeoffRounded,
  DirectionsBusRounded,
  MonetizationOnRounded,
} from "@material-ui/icons";

export const DomainIcons = {
  retail: <StorefrontRounded fontSize="small" />,
  travel: <FlightRounded fontSize="small" />,
  bfsi: <AccountBalanceRounded fontSize="small" />,
};
export const SubDomainIcons = {
  grocery: <LocalGroceryStoreRounded fontSize="small" />,
  pharmacy: <LocalHospitalRounded fontSize="small" />,
  train: <TrainRounded fontSize="small" />,
  bus: <DirectionsBusRounded fontSize="small" />,
  flight: <FlightTakeoffRounded fontSize="small" />,
  trading: <MonetizationOnRounded fontSize="small" />,
};
