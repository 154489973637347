import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemText, Collapse, List } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const NestedListItem = props => {
  // Props
  const { entityName, children, level } = props;

  // States
  const [open, setOpen] = useState(false);

  // Handlers
  const handleViewNestedValues = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleViewNestedValues}>
        <ListItemText>{entityName}:</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="span" disablePadding dense>
          <div style={{ paddingLeft: `${level}em` }}>{children}</div>
        </List>
      </Collapse>
    </>
  );
};

NestedListItem.propTypes = {
  entityName: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
};

export default NestedListItem;
