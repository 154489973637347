// constraint constants

// eslint-disable-next-line
const loginEmailRegex =
  /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;
const phoneNumberRegex = /^(\+|\d)?[\d\s.-]{10,18}$/;
const passwordLoginMin = 8;
const confirmationCodeMinChars = 6;
const confirmationCodeRegex = /[0-9]{6}/;

// eslint-disable-next-line
const namespace_name_regex = /^[a-zA-Z][0-9a-zA-Z_ .-]*$/;
export const namespace_name_regex_reverse = /([^0-9a-zA-Z_ .-]+)/g;
const simple_name_regex = /^[a-zA-Z][a-zA-Z_]+$/;
const entity_type_regex = /^(?!std\.)[a-zA-Z][a-zA-Z.]*[a-zA-Z]$/;

export const simple_text_regex_en =
  /[0-9\u{0000}-\u{00FF}]+((\s)+[0-9\u{0000}-\u{00FF}_?!'",.]+)*/giu;
export const simple_text_regex_hi =
  /[0-9\u{0900}-\u{097F}]+((\s)+[0-9\u{0900}-\u{097F}_?!'",.]+)*/giu;
export const simple_text_regex_kn =
  /[0-9\u{0C80}-\u{0CFF}]+((\s)+[0-9\u{0C80}-\u{0CFF}_?!'",.]+)*/giu;
export const simple_text_regex_ta =
  /[0-9\u{0B80}-\u{0BFF}]+((\s)+[0-9\u{0B80}-\u{0BFF}_?!'",.]+)*/giu;
export const simple_text_regex_ml =
  /[0-9\u{0D00}-\u{0DFF}]+((\s)+[0-9\u{0D00}-\u{0DFF}_?!'",.]+)*/giu;
export const simple_text_regex_vi =
  /[0-9\u{00C0}-\u{1EF9}]+((\s)+[0-9\u{00C0}-\u{1EF9}_?!'",.]+)*/giu;

const TextMaxChars = 255;
const TextMinChars = 2;

const NameMaxChars = 65;
const NameMinChars = 3;

export const validateLoginEmail = (email = "") => {
  const cleanText = email.trim();
  const OK = loginEmailRegex.test(cleanText);

  if (cleanText === "") return true;

  return OK;
};

export const validatePhoneNumber = (phone = "") => {
  const cleanText = phone.trim();
  const OK = phoneNumberRegex.test(cleanText);

  if (cleanText === "") return true;

  return OK;
};

export const validateLoginPassword = (password1 = "", password2 = "") => {
  let OK = password1.length >= passwordLoginMin;

  if (password2 !== "") {
    OK = OK && password2.length >= passwordLoginMin && password1 === password2;
  }

  if (password1 === "") return true;

  return OK;
};

export const validateLoginConfirmCode = (code = "") => {
  const cleanText = code.trim();
  // let filteredAppList = appList.filter(appItem => {
  //  return appItem.name.toLowerCase() === text.toLowerCase();
  // });

  const OK =
    cleanText.length === confirmationCodeMinChars &&
    confirmationCodeRegex.test(cleanText);

  if (cleanText === "") return true;

  return OK;
};

export const validateAppName = (text = "", List = []) => {
  const cleanText = text.trim();
  const listLenght = List.length;

  const filteredList = listLenght
    ? List.filter(appItem => {
        return appItem.name.toLowerCase() === text.toLowerCase();
      })
    : [];
  const OK =
    namespace_name_regex.test(cleanText) &&
    text.length <= NameMaxChars &&
    text.length >= NameMinChars &&
    filteredList.length === 0;

  return OK;
};

export const validateEntityTypeName = (text = "", List = []) => {
  const cleanText = text.trim();
  const listLenght = List.length;

  const filteredList = listLenght
    ? List.filter(appItem => {
        return appItem.name.toLowerCase() === text.toLowerCase();
      })
    : [];

  const OK =
    entity_type_regex.test(cleanText) &&
    text.length <= NameMaxChars &&
    text.length >= NameMinChars &&
    filteredList.length !== 1;

  return OK;
};

export const validateEntityName = (text = "", List = []) => {
  const cleanText = text.trim();
  const listLenght = List.length;

  // const get = p => o =>
  //             p.reduce((xs, x) =>
  //             (xs && xs[x]) ? xs[x] : null, o)

  const filteredList = listLenght
    ? List.filter(appItem => {
        return appItem.name.toLowerCase() === text.toLowerCase();
      })
    : [];

  const OK =
    simple_name_regex.test(cleanText) &&
    text.length <= NameMaxChars &&
    text.length >= NameMinChars &&
    filteredList.length !== 1;

  // console.log(OK);

  // if(cleanText === '') return true;
  return OK;
};

export const validateIntentName = (text = "", List = []) => {
  const cleanText = text.trim();
  // let emptyArray;
  // try {
  //   emptyArray = typeof List[0] !== "undefined" && List.length > 0;
  // } catch (error) {
  //   emptyArray = true;
  // }

  // let filteredAppList = !emptyArray

  const listLenght = List.length;

  const filteredList = listLenght
    ? List.filter(appItem => {
        return appItem.name.toLowerCase() === text.toLowerCase();
      })
    : [];

  const OK =
    simple_name_regex.test(cleanText) &&
    text.length <= NameMaxChars &&
    text.length >= NameMinChars &&
    filteredList.length !== 1;

  return OK;
};

export const validateValueTypeIdentity = (text = "", List = []) => {
  const cleanText = text.trim();
  let emptyArray;
  try {
    emptyArray = !(typeof List[0] !== "undefined" && List.length > 0);
  } catch (error) {
    emptyArray = true;
  }

  const filteredAppList = !emptyArray
    ? List.filter(value => {
        return value.identity.toLowerCase().trim() === cleanText.toLowerCase();
      })
    : [];

  const OK = filteredAppList.length !== 1;

  return OK;
};

export const validatePromptTextLanguage = (text = "", Lang = "en-IN") => {
  const cleanText = text.trim();
  const textLength =
    cleanText.length <= TextMaxChars && cleanText.length >= TextMinChars;
  let langTest;

  if (Lang === "hi-IN") {
    langTest = simple_text_regex_hi.test(cleanText);
  } else if (Lang === "ta-IN") {
    langTest = simple_text_regex_ta.test(cleanText);
  } else if (Lang === "kn-IN") {
    langTest = simple_text_regex_kn.test(cleanText);
  } else if (Lang === "ml-IN") {
    langTest = simple_text_regex_ml.test(cleanText);
  } else if (Lang === "vi-VN") {
    langTest = simple_text_regex_vi.test(cleanText);
  } else {
    langTest = simple_text_regex_en.test(cleanText);
  }
  return langTest && textLength;
};

export const cleanPhrases = addToASR => {
  // This regex checks if there are special characters in the string
  const regex = new RegExp("([\u{005B}-\u{0060}\u{007B}-\u{007F}]+)", "gi");
  const cleanData = addToASR
    .split(",")
    .map(item => item.trim() !== "" && item.replace(regex, "").trim())
    .filter(Boolean);
  return cleanData;
};
