import { GetSetUserPermissions } from "../auth/handleAuthState";

const check = (_rules, action, _data) => {
  const permissions = GetSetUserPermissions();

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions;

  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  return false;
};
// This component checks the role of the user logged in.
// It compares the check to perform against the role rule list in rbac-rules.js
// returns either `yes` or `no` component
const Can = props =>
  check(null, props.perform, props.data) ? props.yes() : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
