import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import InputTextField from "./InputTextField";
import ParametersChips from "./ParametersChips";

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  containHeight: {
    maxHeight: theme.spacing(50),
    overflow: "auto",
  },
}));

const ParametersTextField = props => {
  const { parameters, cleanParameterData, handleDelete } = props;

  const classes = styles();

  const inputTextFieldProps = {
    cleanParameterData,
  };

  const parametersChipsProps = {
    parameters,
    handleDelete,
  };

  return (
    <Paper className={classes.root} elevation={1}>
      <InputTextField {...inputTextFieldProps} />
      <br />
      <br />
      <div className={classes.containHeight}>
        <ParametersChips {...parametersChipsProps} />
      </div>
    </Paper>
  );
};

ParametersTextField.propTypes = {
  parameters: PropTypes.array.isRequired,
  cleanParameterData: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ParametersTextField;
