import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add, Close, Edit } from "@material-ui/icons";
import { useState } from "react";
import AddEditTriggerForm from "./AddEditTriggerForm";

const AddTriggerDialog = props => {
  const {
    classes,
    title,
    userJourneyID,
    userJourney,
    changeUserJourneyData,
    showButton,
    allTriggers,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveClose = (payload, key) => {
    changeUserJourneyData(payload, key);
    setOpen(false);
  };

  return (
    <>
      {!showButton ? (
        <Button variant="outlined" onClick={handleClickOpen}>
          Add New Trigger
        </Button>
      ) : (
        <IconButton
          aria-label="View"
          className={classes.iconButton}
          onClick={handleClickOpen}
        >
          <Add />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeading}>
          <Typography
            variant={"h6"}
            style={{ maxWidth: "350px" }}
            component={"span"}
          >
            {`${userJourney.displayName}: Add Trigger`}
          </Typography>

          <IconButton className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.addDialogContent}>
          <AddEditTriggerForm
            {...{
              isEditMode: false,
              userJourney,
              userJourneyID,
              handleSaveClose,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddTriggerDialog;
