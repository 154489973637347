import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Typography from "@material-ui/core/Typography";

const DeleteConfirm = props => {
  const { handleClose, handleDeleteID } = props;
  const { open, dialogTitle } = props.state;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Typography variant="button">{dialogTitle}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          style={{
            width: 28,
            height: 28,
            marginRight: 5,
            color: "red",
          }}
          onClick={handleDeleteID}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirm;
