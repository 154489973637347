import { Component } from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";

import { withStyles } from "@material-ui/core/styles";
import { IntentsTableStyles } from "../styles.js";
import withTheme from "../../withTheme";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Fab from "@material-ui/core/Fab";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import EntityDropDown from "./EntityDropDown";
import DeleteConfirm from "./DeleteConfirm";
import TablePaginationActionsWrapped from "./pagination";
import { styleEntity } from "../../../utils/helpers";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs";
import { testClassNames } from "../../../utils/integrationTestClassNames.js";
import TriggerPlayground from "../../common/TriggerPlayground";
import GenerateIntentDialog from "./UserJourneys/GenerateIntentDialog.js";

const findClass = "utteranceInput";

// Initialise variables
let entityparentElementOutter = "";
let selectedRange = "";
let range = null;
let currentEntityName = "none";
let utterKey;
let isNew = false;
let contextTrigger = false;
let selectEntityClick = false;
let emptyRows = 1;

const cleanHtml = html => {
  // eslint-disable-next-line
  const cleanHTML = html
    .replace(/(<br>)/gim, "")
    .replace(/(&nbsp;)/gim, "")
    .replace(/<[^/>][^>]+>\s*<\/[^>]+>/gim, "")
    .trim();

  return cleanHTML;
};

const clearUtteranceInput = () => {
  const el = document.getElementById("editText");
  el.innerHTML = "";
};
let returnKey = false;

class IntentHomeUtteranceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: "",
      page: 0,
      rowsPerPage: 5,
      rowsPerPageOptions: [5],
      deleteID: "",
      dialogTitle: "",
      dialogContent: "",
      entityName: "none",
      edit: null,
      sel: null,
      data: this.props.data,
      dataEntities: this.props.dataEntities,
      utterance: [],
      errorEntity: false,
      entityStyle: {
        position: "absolute",
        display: "none",
      },
      html: "",
      newhtml: "",
      enterPressed: true,
      newEnterPressed: true,
      isNew: false,
      utterError: false,
      utterEditError: false,
      anchorEl: null,
      openPopOver: false,
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditUtterance = this.handleEditUtterance.bind(this);
    this.handlePasting = this.handlePasting.bind(this);
  }

  handlePasting = event => {
    const regularExp = new RegExp(findClass, "g");
    const checkClass = event.target.className.match(regularExp);
    if (checkClass) {
      event.preventDefault();
      const currentEvent = event.originalEvent || event;
      const data = currentEvent.clipboardData.getData("text");
      document.execCommand("insertText", false, data);
      return false;
    }
    return event;
  };

  componentDidMount() {
    document.addEventListener("paste", this.handlePasting);
    this._componentIsMounted = true;
  }

  componentWillUnmount() {
    document.removeEventListener("paste", this.handlePasting);
    this._componentIsMounted = false;
  }

  writeToAppData = (html, selectChange = false) => {
    const spanHtml = html;
    const currentData = this.props.data;

    const el = document.createElement("div");
    el.innerHTML = spanHtml;
    const utteranceText = el.innerText.trim();

    const allNodes = el.childNodes;

    const utterance = [];

    for (let index = 0; index < allNodes.length; index++) {
      const element = allNodes[index];
      if (element.nodeName === "SPAN") {
        const entity = element.attributes.name.value;
        const text = element.innerText.trim();
        if (text !== "") {
          utterance[index] = {
            text,
            entity,
          };
        }
      } else if (element.nodeName === "#text") {
        const text = element.nodeValue.trim();
        if (text !== "") {
          utterance[index] = {
            text,
          };
        }
      }
    }
    if (isNew) {
      if (selectChange) {
        this.setState({
          newhtml: html,
          editIntentNameClick: false,
        });
      } else {
        this.setState({
          newhtml: "",
          editIntentNameClick: false,
        });
        const newData = [utterance.filter(Boolean), ...currentData];
        this.props.handleSaveData(newData);
      }

      logEvent(SlangSeverityLevels.INFO, SlangEvents.UTTERANCE_CREATED, {
        value: utteranceText,
        intent_name: this.props.intentName,
      });
    } else {
      const newData = currentData;
      newData[utterKey] = utterance.filter(Boolean);

      this.setState({
        data: newData,
        html,
        editIntentNameClick: false,
      });
      this.props.handleSaveData(newData);

      logEvent(SlangSeverityLevels.INFO, SlangEvents.UTTERANCE_UPDATED, {
        value: utteranceText,
        intent_name: this.props.intentName,
      });
    }
  };

  handleMouseUp = isItNew => {
    const selection = window.getSelection();
    const text = document.all
      ? document.selection.createRange().text.toString().trim()
      : document.getSelection().toString().trim();

    // Resets when the selection has a length of 0
    if (!selection || selection.anchorOffset === selection.focusOffset) {
      this.handleClosePopOver();
      return;
    }

    if (!text) {
      this.handleClosePopOver();
      return;
    }

    const getBoundingClientRect = () =>
      selection.getRangeAt(0).getBoundingClientRect();
    const textSel = selection.toString();

    contextTrigger = true;
    isNew = isItNew;
    this.setState({
      isNew: !!isItNew,
      openPopOver: true,
      selText: textSel,
      anchorEl: {
        clientWidth: getBoundingClientRect().width,
        clientHeight: getBoundingClientRect().height,
        getBoundingClientRect,
      },
      sel: selection,
      errorEntity: false,
    });

    let parentEl, range2;
    parentEl = selection.getRangeAt(0).commonAncestorContainer;
    if (parentEl.nodeType !== 1) {
      parentEl = parentEl.parentNode;
    }

    range = selection.getRangeAt(0);
    range2 = document.getSelection().toString();
    const nodeName =
      selection.getRangeAt(0).commonAncestorContainer.parentElement.nodeName;
    const nodeAttribute =
      selection.getRangeAt(0).commonAncestorContainer.parentElement.attributes
        .name;

    if (typeof nodeAttribute !== "undefined" && nodeName === "SPAN") {
      currentEntityName =
        selection.getRangeAt(0).commonAncestorContainer.parentElement.attributes
          .name.value;
      entityparentElementOutter =
        selection.getRangeAt(0).commonAncestorContainer.parentNode.outerHTML;
      selectedRange = range2;

      this.setState({
        entityName: currentEntityName,
      });
    } else {
      currentEntityName = "none";
      entityparentElementOutter =
        selection.getRangeAt(0).commonAncestorContainer.parentNode.outerHTML;
      selectedRange = range2;

      this.setState({
        entityName: currentEntityName,
      });
    }

    if (range.commonAncestorContainer.innerHTML) {
      this.setState({
        sel: null,
        errorEntity: "The selection contains an existing entity",
      });
    }
  };

  /* Specialized logic with all cases of entity selection */
  handleSelectChangeEntity = value => {
    selectEntityClick = true;
    const sel = this.state.sel;
    const selectedValue = value;
    // check if there is a selection made
    if (sel) {
      this.setState({
        entityName: selectedValue,
      });
      // prepare the variables
      const selectedText = selectedRange;
      const parentElOutter = entityparentElementOutter.trim();
      const currentUtterance = isNew ? this.state.newhtml : this.state.html;
      const previousEntity = currentEntityName;
      let finalHTML = "";
      const styleText = styleEntity(selectedValue);
      const el = document.createElement("div");
      el.innerHTML = parentElOutter;
      const spanNode = el.childNodes[0];

      // case where if a previous entity existed
      if (previousEntity !== "none") {
        // case where user has selected an entity
        if (selectedValue !== "none" && spanNode.nodeName === "SPAN") {
          spanNode.setAttribute("name", selectedValue);
          spanNode.setAttribute("title", selectedValue);
          spanNode.setAttribute("style", styleText);
          const changeEntity = document.createElement("div");
          changeEntity.innerHTML = currentUtterance;
          const parentDivNode = changeEntity.childNodes;
          // iterate through the utterance & recontruct with newly selected entity
          for (let index = 0; index < parentDivNode.length; index++) {
            const element = parentDivNode[index];
            if (element.nodeName === "SPAN") {
              const matchSpan =
                element.attributes.key.value === spanNode.attributes.key.value;
              if (matchSpan) {
                finalHTML += spanNode.parentElement.innerHTML.toString();
              } else {
                finalHTML += element.outerHTML.toString();
              }
            } else if (element.nodeName === "#text") {
              finalHTML += element.textContent;
            }
          }
        }
        // case where user has unselected the previous entity
        else {
          const changeEntity = document.createElement("div");
          changeEntity.innerHTML = currentUtterance;
          const parentDivNode = changeEntity.childNodes;
          // iterate through the utterance & recontruct removing entity
          for (let index = 0; index < parentDivNode.length; index++) {
            const element = parentDivNode[index];
            if (element.nodeName === "SPAN") {
              const matchSpan =
                element.attributes.key.value === spanNode.attributes.key.value;
              if (matchSpan) {
                finalHTML += element.innerText;
              } else {
                finalHTML += element.outerHTML.toString();
              }
            } else if (element.nodeName === "#text") {
              finalHTML += element.textContent;
            }
          }
        }
      }
      // case where no previous entity
      else {
        const rangeStart = range.startOffset;
        // The key is used as an identity is match text
        // TODO improve key value to make it unique
        const key =
          spanNode.nodeName === "SPAN"
            ? spanNode.attributes.key.value
            : selectedText + "1";
        let replaceHtml = "";
        // case where the selected entity is not 'none' prepare the HTML to replace
        if (selectedValue !== "none") {
          replaceHtml =
            " <span style='" +
            styleText +
            "'  key='" +
            key +
            "'  name='" +
            selectedValue +
            "'  title='" +
            selectedValue +
            "' >" +
            selectedText +
            "</span> ";
        } else {
          replaceHtml = selectedText;
        }
        const parentDivNode = spanNode.childNodes;

        // iterate through the utterance & recontruct replacing the selected text with the entity or remove it
        for (
          let index = 0, textLength = 0;
          index < parentDivNode.length;
          index++
        ) {
          const element = parentDivNode[index];

          if (element.nodeName === "SPAN") {
            textLength = textLength + element.innerText.length;
            finalHTML += element.outerHTML;
          } else if (element.nodeName === "#text") {
            textLength = textLength + element.length;

            if (rangeStart <= textLength - 1) {
              finalHTML += element.nodeValue.replace(selectedText, replaceHtml);
            } else {
              finalHTML += element.nodeValue;
            }
          }
        }
      }

      this.writeToAppData(finalHTML, true);
    }

    if (!this.state.openPopOver) {
      return;
    }

    this.setState({ openPopOver: false });
  };

  handleClosePopOver = () => {
    // If  entityDialog is open then don't close the popover
    if (this.props.entityDialogOpen) {
      return;
    }

    if (!this.state.openPopOver) {
      return;
    }

    this.setState({ openPopOver: false });
  };

  handleUtterChange = event => {
    if (!this.state.enterPressed) {
      // eslint-disable-next-line
      const html = event.target.value.replace(/<[^\/>][^>]+><\/[^>]+>/gim, "");
      this.setState({
        html,
      });
      if (this.state.utterEditError) {
        this.setState({
          utterEditError: false,
        });
      }
    }
  };

  handleNewUtterChange = event => {
    if (!returnKey) {
      // eslint-disable-next-line
      const html = event.target.value.replace(
        /(<br><br>|<div>|<\/div>)|<br>[a-zA-Z0-9]*<br>/gim,
        ""
      );

      this.setState({
        newhtml: html,
      });
      if (this.state.utterError) {
        this.setState({
          utterError: false,
        });
      }
    } else {
      returnKey = false;
      clearUtteranceInput();
    }
  };

  handleUtterNewClick = event => {
    this.setState({
      newEnterPressed: true,
    });
    // eslint-disable-next-line
    const html = cleanHtml(this.state.newhtml);

    if (html === "undefined" || html === "") {
      this.setState({
        utterError: true,
        newEnterPressed: true,
      });
    } else {
      isNew = true;
      this.setState({
        isNew: true,
        newhtml: html,
      });
      this.writeToAppData(html, false);
    }
  };

  handleUtterClick = event => {
    this.setState({
      enterPressed: true,
      edit: "",
    });
    // eslint-disable-next-line
    const html = cleanHtml(this.state.html);

    if (html === "undefined" || html === "") {
      this.setState({
        enterPressed: true,
        utterError: true,
      });
    } else {
      isNew = false;
      this.setState({
        isNew: false,
        html,
      });
      this.writeToAppData(html, false);
    }
  };

  handleUtterKeyPress = event => {
    if (event.key === "Enter") {
      this.setState({
        enterPressed: true,
        edit: "",
      });

      // eslint-disable-next-line
      const html = cleanHtml(this.state.html);

      if (html === "undefined" || html === "") {
        this.setState({
          enterPressed: true,
          utterEditError: true,
        });
      } else {
        isNew = false;
        this.setState({
          isNew: false,
          html,
        });
        this.writeToAppData(html, false);
      }
    }
    this.handleClosePopOver();
  };

  handleNewUtterKeyPress = event => {
    if (event.key === "Enter") {
      returnKey = true;

      const html = cleanHtml(this.state.newhtml);
      clearUtteranceInput();
      if (html === "undefined" || html === "") {
        this.setState({
          newEnterPressed: true,
          newhtml: "",
          utterError: true,
        });
      } else {
        isNew = true;
        this.setState({
          isNew: true,
          newhtml: "",
        });
        this.writeToAppData(html, false);
      }
    }
    this.setState({
      openPopOver: false,
    });
    contextTrigger = false;
  };

  handleDeleteClick = ID => {
    this.setState({
      open: true,
      dialogTitle: "Are you sure you want to delete this utterance ? ",
      deleteID: ID,
    });
  };

  handleDeleteID = () => {
    const ID = this.state.deleteID;
    const newData = this.props.data;

    logEvent(SlangSeverityLevels.INFO, SlangEvents.UTTERANCE_DELETED, {
      value: newData[ID],
      intent_name: this.props.intentName,
    });

    if (ID > -1) {
      newData.splice(ID, 1);
    }

    this.props.handleSaveData(newData);

    this.setState({
      data: newData,
      open: false,
      deleteID: null,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleCancel = () => {
    this.setState({
      edit: null,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  handleEditUtterance(utterId, utterance, key) {
    contextTrigger = false;

    let html = "";
    utterance.map((item, key) => {
      if (item.entity) {
        const styleText = styleEntity(item.entity);
        html +=
          " <span key='" +
          item.text +
          key +
          "' title='" +
          item.entity +
          "' name='" +
          item.entity +
          "'  style='" +
          styleText +
          "' >" +
          item.text +
          "</span> ";
      } else {
        html += " " + item.text;
      }
      return false;
    });

    this.setState({
      edit: utterId,
      utterance,
      html,
      enterPressed: false,
      utterEditError: false,
      utterError: false,
    });

    utterKey = key;
    contextTrigger = false;
  }

  handleId = props => {
    if (props.name === "newUtter") {
      isNew = true;
      this.setState({
        isNew: true,
      });
    } else {
      isNew = false;
      this.setState({
        isNew: false,
      });
    }

    contextTrigger = false;
  };

  handleNewUtterance = () => {
    this.setState({
      newhtml: this.state.newhtml,
      newEnterPressed: false,
      enterPressed: true,
      edit: "",
    });

    utterKey = this.props.data.length;
  };

  handleFocus = event => {
    contextTrigger = false;
    this.handleClosePopOver();
  };

  handleBlur = event => {
    contextTrigger = false;
    // this.setState({
    //   edit: "",
    //   enterPressed: true,
    // });
    // this.handleClosePopOver();
  };

  handleChangeSearch = event => {
    this.setState({
      search: event.target.value,
    });
    this.filterUtterance(event.target.value);
  };

  filterUtterance(text) {
    const currentData = this.props.data.length ? this.props.data : [];

    if (!currentData || currentData.length === 0) {
      return;
    }

    if (text && text.length > 0) {
      const filtered = currentData.map((item, key) => {
        let utterance = "";
        item.map((item, key) => {
          if (item.entity) {
            utterance += item.text;
          } else {
            utterance += item.text;
          }
          utterance += " ";

          return false;
        });
        if (utterance.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          return item;
        }

        return false;
      });

      if (typeof filtered !== "undefined") {
        this.setState({ data: filtered });
      }
    } else {
      this.setState({ data: currentData });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (prevProps.dataEntities !== this.props.dataEntities) {
        this.setState({
          dataEntities: this.props.dataEntities,
        });
      }
      if (prevProps.data !== this.props.data) {
        this.setState({
          data: this.props.data,
          dataEntities: this.props.dataEntities,
        });
      }
    }
  }

  handleClickAway = async event => {
    // If entity dialog is open skip  handling this event
    // if (this.props.entityDialogOpen) {
    //   return;
    // }
    // // essentially sleep for 1 ms. This guarantees that the entityDialogOpen has been set
    // function timeout(ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // }
    // await timeout(1);
    // // If after returning from the event loop the component has been unmounted already then just return
    // if (!this._componentIsMounted) {
    //   return;
    // }
    // if (!contextTrigger) {
    //   const regex = /(editUtterance|utter)[0-9]+/g;
    //   const found = event.target.id.match(regex);
    //   const found2 =
    //     event.target.parentElement &&
    //     event.target.parentElement.id.match(regex);
    //   if (found || found2) {
    //     this.handleClosePopOver();
    //   } else {
    //     this.setState({
    //       edit: "",
    //       enterPressed: true,
    //     });
    //     if (!this.state.openPopOver) {
    //       return;
    //     }
    //     this.handleClosePopOver();
    //   }
    // } else {
    //   if (!selectEntityClick) {
    //     contextTrigger = false;
    //     this.handleClosePopOver();
    //   }
    //   selectEntityClick = false;
    // }
  };

  handleGenerateUtterances = utterances => {
    this.setState({
      data: utterances,
    });
    this.props.handleSaveData(utterances);
  };

  render() {
    const { classes } = this.props;
    const {
      open,
      dialogTitle,
      errorEntity,
      entityName,
      rowsPerPageOptions,
      rowsPerPage,
      page,
      edit,
      data,
      html,
      dataEntities,
      utterEditError,
      newhtml,
      newEnterPressed,
      enterPressed,
      anchorEl,
      openPopOver,
    } = this.state;
    emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const {
      handleonMouseUp,
      handleSelectChange,
      handleCancel,
      handleClose,
      handleDeleteID,
      handleClosePopOver,
      handleGenerateUtterances,
    } = this;

    const EntityDropDownState = {
      errorEntity,
      dataEntities,
      entityName,
    };
    const EntityDropDownProps = {
      handleonMouseUp,
      handleSelectChange,
      handleCancel,
      handleClosePopOver,
      classes,
    };

    const DeleteConfirmProps = {
      handleClose,
      handleDeleteID,
      classes,
    };
    const DeleteConfirmState = {
      open,
      dialogTitle,
    };

    return (
      <Paper className={classes.root} elevation={0}>
        <Table className={classes.table} border={0}>
          <TableBody
            className={`${classes.tableBody} ${testClassNames.UTTERANCE_TABLE_BODY}`}
          >
            <TableRow>
              <TableCell className={classes.searchHead}>
                <Input
                  placeholder="Search"
                  className={`${classes.searchInput} ${testClassNames.UTTERANCE_SEARCH_INPUT_FIELD}`}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  onChange={this.handleChangeSearch}
                />
              </TableCell>
              {dataEntities.length !== 0 && (
                <GenerateIntentDialog
                  data={dataEntities}
                  handleGenerateUtterances={handleGenerateUtterances}
                />
              )}
            </TableRow>
            <TableRow className={classes.form}>
              <TableCell className={classes.padding}>
                <Icon
                  className={classes.quotesMd}
                  style={{ transform: "scale(-1, 1)" }}
                >
                  format_quote
                </Icon>
                <ContentEditable
                  id="editText"
                  className={`${classes.utterNew} ${findClass}`}
                  onMouseUp={this.handleMouseUp.bind(this, true)}
                  onClick={this.handleNewUtterance.bind(this)}
                  html={newhtml === "<br>" || newhtml === "" ? "" : newhtml} // innerHTML of the editable div
                  disabled={newEnterPressed} // use true to disable edition
                  onChange={this.handleNewUtterChange} // handle innerHTML change
                  onKeyPress={this.handleNewUtterKeyPress}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                />
                <Icon className={classes.quotesMd}>format_quote</Icon>
                {/* {utterError && <small>empty string</small>} */}
              </TableCell>
              <TableCell className={classes.alignRight}>
                <Fab
                  size="small"
                  color="inherit"
                  onClick={newEnterPressed ? null : this.handleUtterNewClick}
                  disabled={newEnterPressed}
                  className={`${classes.addEntity} ${testClassNames.UTTERANCE_ADD_BUTTON}`}
                >
                  <Icon>add</Icon>
                </Fab>
              </TableCell>
            </TableRow>

            {data.length !== 0 &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, key) => {
                  if (item) {
                    const cid = "utter";
                    let utterance = "";
                    // eslint-disable-next-line
                    const ID = page * rowsPerPage + key;
                    if (item[0].text) {
                      return (
                        <ClickAwayListener
                          key={key}
                          onClickAway={this.handleClickAway}
                        >
                          <TableRow
                            className={`${
                              edit === cid + ID ? classes.form : classes.padding
                            } ${classes.parentRow}`}
                            hover
                          >
                            <TableCell
                              id={ID + cid + 1}
                              scope="row"
                              className={classes.padding}
                            >
                              <span
                                id={cid + ID + 2}
                                className={classes.pointer}
                                style={
                                  edit === cid + ID
                                    ? { display: "none" }
                                    : { display: "inherit" }
                                }
                                onClick={this.handleEditUtterance.bind(
                                  this,
                                  cid + ID,
                                  item,
                                  ID
                                )}
                                tabIndex={ID}
                              >
                                {item.map((item, key) => {
                                  utterance += item.text + " ";
                                  if (item.entity) {
                                    const style = styleEntity(
                                      item.entity,
                                      true
                                    );
                                    return (
                                      <span
                                        key={key}
                                        name={item.entity}
                                        title={item.entity}
                                        style={style}
                                      >
                                        {item.text}
                                      </span>
                                    );
                                  } else {
                                    return " " + item.text;
                                  }
                                })}
                              </span>

                              <span
                                id={cid + ID + 3}
                                className={
                                  edit === cid + ID
                                    ? classes.utterEdit
                                    : classes.utterHide
                                }
                              >
                                <Icon
                                  className={classes.quotesSm}
                                  style={{ transform: "scale(-1, 1)" }}
                                >
                                  format_quote
                                </Icon>
                                <ContentEditable
                                  className={`${classes.contextAvail} ${findClass}`}
                                  id={cid + ID + 4}
                                  name={cid + ID}
                                  // onMouseUp={this.handleId}
                                  onMouseUp={this.handleMouseUp.bind(
                                    this,
                                    false
                                  )}
                                  html={
                                    html === "<br>" || html === "" ? " " : html
                                  } // innerHTML of the editable div
                                  disabled={enterPressed} // use true to disable edition
                                  onChange={this.handleUtterChange} // handle innerHTML change
                                  onKeyPress={this.handleUtterKeyPress}
                                  onFocus={this.handleFocus}
                                  // onBlur={this.handleUtterClick}
                                />
                                <Icon className={classes.quotesSm}>
                                  format_quote
                                </Icon>
                                {utterEditError && <small>empty string</small>}
                              </span>
                            </TableCell>

                            <TableCell
                              id={key + cid + 3}
                              className={classes.alignButton}
                            >
                              <span className={"hidden"}>
                                {edit === cid + ID ? (
                                  <Fab
                                    size="small"
                                    color="inherit"
                                    className={classes.addEntity}
                                    type="button"
                                    onClick={
                                      enterPressed
                                        ? null
                                        : this.handleUtterClick.bind(this, ID)
                                    }
                                    disabled={false}
                                    style={{ height: 35, width: 35 }}
                                  >
                                    <Icon
                                      id={"editUtterance" + ID}
                                      style={{ fontSize: 15 }}
                                    >
                                      done
                                    </Icon>
                                  </Fab>
                                ) : (
                                  <span id={ID + "delete"}>
                                    <TriggerPlayground
                                      classes={classes}
                                      loaderSize={20}
                                      utterance={utterance}
                                    />
                                    &nbsp;
                                    <IconButton
                                      id={key + cid + 7}
                                      className={classes.buttonDelete}
                                      aria-label="Delete"
                                      onClick={this.handleDeleteClick.bind(
                                        this,
                                        ID
                                      )}
                                    >
                                      <DeleteIcon id={key + cid + 5} />
                                    </IconButton>
                                  </span>
                                )}
                              </span>
                            </TableCell>
                          </TableRow>
                        </ClickAwayListener>
                      );
                    }
                    return null;
                  }
                  emptyRows++;
                  return null;
                })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={6}
                count={data ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={rowsPerPageOptions}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <Popper
          open={openPopOver}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          style={{ zIndex: openPopOver ? 100 : -1 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={{ enter: 550, exit: 250 }}>
              <div>
                <EntityDropDown
                  setStateOfEntityDialog={this.props.setStateOfEntityDialog}
                  state={EntityDropDownState}
                  handleSelectChangeEntity={this.handleSelectChangeEntity}
                  {...EntityDropDownProps}
                />
              </div>
            </Fade>
          )}
        </Popper>
        <DeleteConfirm state={DeleteConfirmState} {...DeleteConfirmProps} />
      </Paper>
    );
    IntentHomeUtteranceTable;
  }
}

IntentHomeUtteranceTable.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSaveData: PropTypes.func.isRequired,
  intentName: PropTypes.string.isRequired,
  dataEntities: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  setStateOfEntityDialog: PropTypes.func.isRequired,
  entityDialogOpen: PropTypes.bool.isRequired,
};

export default withTheme(
  withStyles(IntentsTableStyles)(IntentHomeUtteranceTable)
);
