import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    overflow: "hidden",
  },
  closeIcon: { float: "right" },
}));

function AddDialog(props) {
  const { title, iconButton, handleEditAppData, Component, ...others } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveClose = (payload, key) => {
    handleEditAppData(payload, key);
    setOpen(false);
  };
  return (
    <>
      {iconButton ? (
        <IconButton
          color="primary"
          variant="contained"
          edge="start"
          aria-label="Add"
          onClick={handleClickOpen}
        >
          <AddIcon />
        </IconButton>
      ) : (
        <Button
          color="primary"
          variant="contained"
          edge="start"
          aria-label="Close"
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Button>
      )}
      <Dialog
        open={open}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {title}{" "}
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Component
            handleSaveClose={handleSaveClose}
            open={open}
            {...others}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

AddDialog.propTypes = {
  handleEditAppData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subDomainID: PropTypes.number,
};

export default AddDialog;
