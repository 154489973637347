import { Component } from "react";
import AppConfig from "../app.config";

class AutoLogin extends Component {
  async componentDidMount() {
    const payload = this.props.match.params.payload;

    try {
      const res = await fetch(
        `${AppConfig.SLANG_HOST}/v1/autoLogin?q=${payload}`
      );
      const json = await res.json();
      if (json.code) {
        throw new Error("error");
      }
      localStorage.setItem("U_identity", json.id);
      localStorage.setItem("U_username", json.user);
      window.location = "/";
    } catch (error) {
      window.location = "/";
    }
  }

  render() {
    return null;
  }
}

export default AutoLogin;
