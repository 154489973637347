import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { testClassNames } from "../../../utils/integrationTestClassNames";

const NewBuddyCards = props => {
  const { classes, handleCreateClick, state } = props;

  const { apikey, isCreatingOrDeleting } = state;

  return (
    <Card
      className={
        !apikey || isCreatingOrDeleting ? classes.cardDisabled : classes.cardNew
      }
      elevation={0}
      onClick={!apikey || isCreatingOrDeleting ? null : handleCreateClick}
    >
      <CardMedia
        className={`${classes.media} ${testClassNames.HOME_CREATE_NEW_BUDDY}`}
        image="/images/new_app_icon.png"
        title="Create New Buddy"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="body2" component="h4">
          Create Buddy
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NewBuddyCards;
