import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import UsageDetailsDialog from "./UsageDetailsDialog";
import { getMSUAnalyticsData } from "../../../libs/analytics/slangAnalyticsAPIs";
import { LinearProgress } from "@material-ui/core";

const styles = theme => ({
  sideHeading: {
    fontFamily: "'Work Sans', sans-serif",
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
  },
  alignTextRight: {
    textAlign: "right",
  },
  usageData: {
    display: "inline-block",
    fontFamily: "Lato",
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "left",
  },
  space: {
    padding: theme.spacing(3),
    minHeight: 85,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    textAlign: "left",
  },
  paper: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0),
    textAlign: "left",
    width: "100%",
  },
});

function Usage(props) {
  const { classes } = props;

  const [BQData, setBQData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [monthlySlangUsers, setMsu] = useState("loading...");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getMSUAnalyticsData();
        const { BQData, totalMonthlyCount } = response;
        setBQData(BQData);
        setMsu(totalMonthlyCount);
      } catch (error) {
        console.log(error);
        setError("Error loading");
        setMsu("null");
      } finally {
        setLoading(false);
      }
    };

    getData();

    return () => {};
  }, []);
  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid item container style={{ padding: 24 }}>
        <Grid item xs={4}>
          <Typography className={classes.heading}>Usage</Typography>
        </Grid>
        <Grid item container alignItems="center" className={classes.space}>
          <Grid item xs={4}>
            <Typography className={classes.sideHeading}>
              CURRENT USAGE
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.usageData}>
              {monthlySlangUsers}
            </Typography>{" "}
            <Typography
              style={{ display: "inline-block", verticalAlign: "middle" }}
              variant="caption"
            >
              {" Monthly Active Users"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.alignTextRight}>
            {loading ? (
              <LinearProgress />
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : BQData?.length ? (
              <UsageDetailsDialog BQData={BQData} />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

Usage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Usage);
