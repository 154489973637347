import { withStyles } from "@material-ui/core/styles";
import { main } from "../styles.js";
import withTheme from "../../withTheme";
import PropTypes from "prop-types";
import * as SlangTooltips from "../../common/SlangTooltips";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const PowerModeToggler = props => {
  const { classes } = props;
  return (
    <Paper className={classes.paper4}>
      <Typography variant="button" noWrap gutterBottom>
        <Switch
          name="POWERMODE"
          checked={props.POWERMODE}
          value="POWERMODE"
          onChange={props.handleSwitchPowerMode}
          color="primary"
        />
        POWER MODE &nbsp;
        <Tooltip
          title={SlangTooltips.POWER_MODE}
          classes={{ tooltip: classes.tooltip }}
        >
          <Icon className={classes.iconHelp}>help</Icon>
        </Tooltip>
      </Typography>
    </Paper>
  );
};

PowerModeToggler.propTypes = {
  classes: PropTypes.object.isRequired,
  POWERMODE: PropTypes.bool.isRequired, // wether powermode is on or not
  handleSwitchPowerMode: PropTypes.func.isRequired, // callback to be fired on toggle
};

export default withTheme(withStyles(main)(PowerModeToggler));
