import AppConfig from "../app.config";

const Langs = AppConfig.LANGUAGES;

export const emptyOnboardingStatements = () => {
  const emptyState = {
    onboardingPrimaryText: {
      successTemplates: [],
    },
    onboardingSecondaryText: {
      successTemplates: [],
    },
    onboardingIntroTitle: {
      successTemplates: [],
    },
    onboardingIntroDescription: {
      successTemplates: [],
    },
  };

  const emptyOnboardingStatements = {};

  Langs.forEach(lang => {
    emptyOnboardingStatements[lang.code] = emptyState;
  });

  return emptyOnboardingStatements;
};

const extractStringPrompt = (uiConfig, lang, appStringTable, platform) => {
  const getStr = data => {
    const newData = data.map(item => {
      return {
        ...item,
        id: item.value,
        value:
          appStringTable[item.value] && appStringTable[item.value][lang]
            ? appStringTable[item.value][lang]
            : "",
      };
    });
    return newData;
  };

  const strObj = {
    onboardingPrimaryText: {
      successTemplates:
        uiConfig?.onboardingPrimaryText?.successTemplates &&
        uiConfig?.onboardingPrimaryText?.successTemplates.length > 0
          ? getStr(uiConfig?.onboardingPrimaryText?.successTemplates)
          : lang === "en-IN"
          ? [
              {
                severity: 1,
                id: `ui_config_${platform}_onboardingPrimaryText`,
                value: "You can now talk to your app!",
              },
            ]
          : [],
    },
    onboardingSecondaryText: {
      successTemplates:
        uiConfig?.onboardingSecondaryText?.successTemplates &&
        uiConfig?.onboardingSecondaryText?.successTemplates.length > 0
          ? getStr(uiConfig?.onboardingSecondaryText?.successTemplates)
          : lang === "en-IN"
          ? [
              {
                severity: 1,
                id: `ui_config_${platform}_onboardingSecondaryText`,
                value: "Click here to begin...",
              },
            ]
          : [],
    },
    onboardingIntroTitle: {
      successTemplates:
        uiConfig?.onboardingIntroTitle?.successTemplates &&
        uiConfig?.onboardingIntroTitle?.successTemplates.length > 0
          ? getStr(uiConfig?.onboardingIntroTitle?.successTemplates)
          : lang === "en-IN"
          ? [
              {
                severity: 1,
                id: `ui_config_${platform}_onboardingIntroTitle`,
                value: "A word is worth a thousand clicks.",
              },
            ]
          : [],
    },
    onboardingIntroDescription: {
      successTemplates:
        uiConfig?.onboardingIntroDescription?.successTemplates &&
        uiConfig?.onboardingIntroDescription?.successTemplates.length > 0
          ? getStr(uiConfig?.onboardingIntroDescription?.successTemplates)
          : lang === "en-IN"
          ? [
              {
                severity: 1,
                id: `ui_config_${platform}_onboardingIntroDescription`,
                value: "You can now just use your voice to get things done.",
              },
            ]
          : [],
    },
  };
  return strObj;
};

export const readUIConfigOnboardingStatments = (
  uiConfig,
  appStringTable,
  platform
) => {
  const uiConfigLanguageState = {};
  if (uiConfig) {
    Langs.forEach(item => {
      uiConfigLanguageState[item.code] = {
        ...extractStringPrompt(uiConfig, item.code, appStringTable, platform),
      };
    });
  }
  return uiConfigLanguageState;
};

export const editOnboardingStatements = (
  onboardingLangSentences,
  type,
  value,
  lang,
  platform
) => {
  const isNew = !onboardingLangSentences[lang][type].successTemplates[0];
  let newOnboardingLangSentences = onboardingLangSentences;

  const newOnboardingStatement = {
    severity: 1,
    value,
    id: `ui_config_${platform}_${type}`,
  };

  if (isNew) {
    newOnboardingLangSentences = {
      ...newOnboardingLangSentences,
      [lang]: {
        ...newOnboardingLangSentences[lang],
        [type]: {
          ...newOnboardingLangSentences[lang][type],
          successTemplates: [
            ...newOnboardingLangSentences[lang][type].successTemplates,
            newOnboardingStatement,
          ],
        },
      },
    };
  } else {
    const changedStatement =
      newOnboardingLangSentences[lang][type].successTemplates;
    changedStatement[0].value = value;
    newOnboardingLangSentences = {
      ...newOnboardingLangSentences,
      [lang]: {
        ...newOnboardingLangSentences[lang],
        [type]: {
          ...newOnboardingLangSentences[lang][type],
          successTemplates: changedStatement,
        },
      },
    };
  }

  return newOnboardingLangSentences;
};

const updateOnboardingConfig = onboardingLangStatements => {
  const onboardingTemplate = {
    onboardingPrimaryText: {
      successTemplates: [],
    },
    onboardingSecondaryText: {
      successTemplates: [],
    },
    onboardingIntroTitle: {
      successTemplates: [],
    },
    onboardingIntroDescription: {
      successTemplates: [],
    },
  };
  const statementVisited = [];
  Object.keys(onboardingLangStatements).forEach(lang => {
    Object.keys(onboardingLangStatements[lang]).forEach(type => {
      Object.keys(onboardingLangStatements[lang][type]).forEach(template => {
        onboardingLangStatements[lang][type][template].forEach(statement => {
          if (!statementVisited.includes(statement.id)) {
            onboardingTemplate[type][template].push({
              value: statement.id,
              isId: true,
              severity: statement.severity,
            });
            statementVisited.push(statement.id);
          }
        });
      });
    });
  });
  return onboardingTemplate;
};

const updateStringTable = (onboardingLangStatements, appStringTable) => {
  const newAppStringTable = appStringTable;

  Object.keys(onboardingLangStatements).forEach(lang => {
    Object.keys(onboardingLangStatements[lang]).forEach(type => {
      Object.keys(onboardingLangStatements[lang][type]).forEach(template => {
        onboardingLangStatements[lang][type][template].forEach(statement => {
          newAppStringTable[statement.id] = {
            ...(newAppStringTable[statement.id]
              ? newAppStringTable[statement.id]
              : {}),
            [lang]: statement.value,
          };
        });
      });
    });
  });
  return newAppStringTable;
};

export const writeOnboardingStatements = (
  onboardingLangStatements,
  appStringTable
) => {
  const newOnboardingConfig = updateOnboardingConfig(onboardingLangStatements);

  const newAppStringTable = updateStringTable(
    onboardingLangStatements,
    appStringTable
  );

  return {
    onboardingStatements: newOnboardingConfig,
    stringTable: newAppStringTable,
  };
};
