import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  listItemText: {
    flex: " 0 1 auto",
    width: "130px",
    margin: "4px  20px 4px 45px",
  },
  description: {
    maxWidth: "300px",
  },
}));

function EditData(props) {
  const {
    subDomainID,
    subDomain,
    dataID,
    dataType,
    handleEditAppData,
    subdomains,
  } = props;

  const classes = useStyles();
  const [name, setName] = useState(dataType.name);
  const [displayName, setDisplayName] = useState(dataType.displayName);
  const [description, setDescription] = useState(dataType.description);
  const [selection, setSelection] = useState(null);
  const [selectError, setSelectError] = useState(null);

  const handleBlur = event => {
    let shouldUpdate = false;

    switch (event.target.name) {
      case "displayName":
        if (dataType[event.target.name] !== displayName) shouldUpdate = true;
        dataType[event.target.name] = displayName;
        break;
      case "name":
        if (dataType[event.target.name] !== name) shouldUpdate = true;
        if (selectError) {
          shouldUpdate = false;
          setSelection(null);
          return;
        }

        dataType[event.target.name] = name;
        break;
      case "description":
        if (dataType[event.target.name] !== description) shouldUpdate = true;
        dataType[event.target.name] = description;
        break;

      default:
        break;
    }
    if (shouldUpdate) {
      subdomains[subDomainID].data[dataID] = dataType;
      handleEditAppData(subdomains, "subDomains");
    }
    setSelection(null);
    setSelectError(null);
  };

  const handleChange = event => {
    const text = event.target.value;

    if (text.length < 140) {
      switch (event.target.name) {
        case "displayName":
          setDisplayName(text);
          break;
        case "name":
          const currentName = text.replace(/ /g, "_");

          const doesNameExist = subDomain?.data.find(
            D => D.name === currentName
          );

          if (doesNameExist) {
            setSelectError("Data Type name exists");
          } else {
            setSelectError(null);
          }
          setName(currentName);
          break;
        case "description":
          setDescription(event.target.value);
          break;

        default:
          break;
      }
    }
    if (text.length < 3) {
      setSelectError("Should be greater than 2 chars");
    }
  };

  const handleNameChange = event => {
    const text = event.target.value;

    if (text.length < 140) {
      const currentName = text.replace(/ /g, "_");

      const doesNameExist = subDomain?.data.find(
        (el, ID) => el.name === currentName && dataID !== ID
      );

      if (doesNameExist) {
        setSelectError("Data Type name exists");
      } else {
        setSelectError(null);
      }
      setName(currentName);
    }
    if (text.length < 3) {
      setSelectError("Should be greater than 2 chars");
    }
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handleBlur(event);
      event.preventDefault();
    }
  };
  const handleKeyPressText = event => {
    if (event.key === "Enter") {
      const fieldName = event.target.attributes.name.value;
      setSelection(fieldName);
      event.preventDefault();
    }
  };
  return (
    <>
      {selection === "displayName" ? (
        <TextField
          id="info-displayName"
          label="Display Name"
          name="displayName"
          value={displayName}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          autoFocus
        />
      ) : (
        <ListItemText
          onDoubleClick={() => {
            setSelection("displayName");
          }}
          name="displayName"
          onKeyPress={handleKeyPressText}
          tabIndex={0}
          title="Double Click to edit."
          className={classes.listItemText}
        >
          {" "}
          {dataType.displayName}
        </ListItemText>
      )}
      &nbsp; &nbsp;
      {selection === "name" ? (
        <TextField
          id="info-name"
          label="name"
          name="name"
          value={name}
          error={selectError !== null}
          helperText={selectError}
          onChange={handleNameChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          autoFocus
        />
      ) : (
        <ListItemText
          onDoubleClick={() => {
            setSelection("name");
          }}
          tabIndex={0}
          name="name"
          title="Double Click to edit."
          onKeyPress={handleKeyPressText}
          className={classes.listItemText}
        >
          {" "}
          {dataType.name}
        </ListItemText>
      )}
      &nbsp; &nbsp;
      {selection === "description" ? (
        <TextField
          id="info-description"
          label="description"
          name="description"
          value={description}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          className={classes.description}
          multiline
          rows={2}
          fullWidth
          autoFocus
        />
      ) : (
        <ListItemText
          onDoubleClick={() => {
            setSelection("description");
          }}
          tabIndex={0}
          name="description"
          onKeyPress={handleKeyPressText}
          title="Double Click to edit."
          className={classes.description}
        >
          {" "}
          {dataType.description}
        </ListItemText>
      )}
    </>
  );
}

EditData.propTypes = {
  subDomain: PropTypes.object.isRequired,
  subDomainID: PropTypes.number.isRequired,
  dataID: PropTypes.number,
  dataType: PropTypes.object,
  handleEditAppData: PropTypes.func.isRequired,
  subdomains: PropTypes.array.isRequired,
};

export default EditData;
