import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  Typography,
  TextField,
  Chip,
  Paper,
  IconButton,
} from "@material-ui/core";
import { isUpdatatableToNextMajor } from "../../../utils/versionUtils";
import WatchLater from "@material-ui/icons/WatchLater";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Add, Delete, Remove } from "@material-ui/icons";

const SaveTemplateDialog = props => {
  const {
    open,
    handleClose,
    classes,
    handleSubmit,
    versionUpdateTypes,
    templateVersion,
    templateDetails,
    setSelectedVersionUpdateType,
    setChangelogTitle,
    setChangelogDescription,
    setChangelogList,
    changelogDescription,
    changelogTitle,
    selectedVersionUpdateType,
    changelogList,
  } = props;

  const [nextVersion, setNextVersion] = useState(`${templateVersion}`);
  const [chipBackgroundColor, setChipBackgroundColor] = useState("#139a7a");
  const [titleError, setTitleError] = useState("");
  const [editChangelogItemNumber, setEditChangelogItemNumber] = useState("");

  useEffect(() => {
    const version = templateVersion.split(".");
    version[2] = +version[2] + 1;
    setNextVersion(version.join("."));
  }, []);

  const handleSelectedVersionChange = e => {
    setSelectedVersionUpdateType(e.target.value);
    const version = templateVersion.split(".");
    if (e.target.value === "patch") {
      version[2] = +version[2] + 1;
      setChipBackgroundColor("#139a7a");
    } else if (e.target.value === "minor") {
      version[1] = +version[1] + 1;
      version[2] = 0;
      setChipBackgroundColor("#f0851a");
    } else if (e.target.value === "major") {
      version[0] = +version[0] + 1;
      version[1] = 0;
      version[2] = 0;
      setChipBackgroundColor("#FF0000");
    }
    setNextVersion(version.join("."));
  };

  const handleChangeChangelogTitle = event => {
    if (event.target.value.length > 60 || event.target.value.length <= 0) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    setChangelogTitle(event.target.value);
  };

  const handleChangelogDescription = event => {
    setChangelogDescription(event.target.value);
  };

  const handleDeleteChangelogItem = index => {
    setChangelogList([
      ...changelogList.slice(0, index),
      ...changelogList.slice(index + 1, changelogList.length),
    ]);
  };

  const handleChangelogItemChange = event => {
    const data = [...changelogList];
    data[editChangelogItemNumber] = event.target.value;
    setChangelogList(data);
  };

  const handleChangelogItemKeyPress = event => {
    if (event.key === "Enter") {
      handleBlur();
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    setEditChangelogItemNumber("");
  };

  const handleChangelogKeypress = event => {
    if (event.key === "Enter") {
      changelogDescription &&
        setChangelogList([...changelogList, `${changelogDescription}`]);
      setChangelogDescription("");
      event.preventDefault();
    }
  };

  const handleSubmitChangelogItem = e => {
    e.preventDefault();
    changelogDescription &&
      setChangelogList([...changelogList, `${changelogDescription}`]);
    setChangelogDescription("");
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <div className={classes.dialogCreate}>
        <DialogContent className={classes.changelogTitleContainer}>
          <WatchLater />
          <Typography variant="h5" className={classes.textColor}>
            {"Template changelog"}
          </Typography>
          <Close className={classes.CloseIcon} onClick={handleClose} />
        </DialogContent>

        <DialogContent className={classes.textColor}>
          <form onSubmit={handleSubmit}>
            <FormControl className={classes.createInput}>
              <Typography variant="h5" gutterBottom className={classes.text}>
                {"Update type"}
              </Typography>
              <div className={classes.versionContainer}>
                <Select
                  className={classes.select}
                  value={selectedVersionUpdateType}
                  onChange={handleSelectedVersionChange}
                >
                  <MenuItem value={versionUpdateTypes.PATCH}>PATCH</MenuItem>
                  <MenuItem value={versionUpdateTypes.MINOR}>MINOR</MenuItem>
                  {isUpdatatableToNextMajor(
                    templateVersion,
                    templateDetails.versions.map(
                      version => version.versionString
                    )
                  ) ? (
                    <MenuItem value={versionUpdateTypes.MAJOR}>MAJOR</MenuItem>
                  ) : null}
                </Select>{" "}
                <Chip
                  style={{ backgroundColor: `${chipBackgroundColor}` }}
                  variant="outlined"
                  label={`${nextVersion}`}
                  size="small"
                  className={classes.nextVersionChip}
                />
              </div>
            </FormControl>
            <FormControl className={classes.createInput}>
              <Typography variant="h5" gutterBottom className={classes.text}>
                {"Title"}
              </Typography>
              <div>
                <TextField
                  autoFocus
                  variant="outlined"
                  className={classes.Input}
                  color="primary"
                  placeholder="Changelog Title"
                  type="text"
                  onChange={handleChangeChangelogTitle}
                  name={"createChangelogTitle"}
                  value={changelogTitle}
                  required
                  error={!!titleError}
                  size={"small"}
                />
                <FormHelperText id="create-changelog-title-text">
                  {"e.g, - 'Retail Template v1.0.1', 'Template Major v2.0.0'"}
                </FormHelperText>
              </div>
            </FormControl>
            <FormControl className={classes.createInput}>
              <Typography variant="h5" gutterBottom className={classes.text}>
                {"Changes"}
              </Typography>
              <div>
                <div className={classes.changelogTitleContainer}>
                  <TextField
                    multiline
                    variant="outlined"
                    className={classes.Input}
                    color="primary"
                    placeholder="Changelog description"
                    type="text"
                    onKeyPress={handleChangelogKeypress}
                    onChange={handleChangelogDescription}
                    value={changelogDescription}
                    size="small"
                  />
                  <IconButton
                    className={classes.addButton}
                    variant="text"
                    onClick={handleSubmitChangelogItem}
                    type="submit"
                    color="secondary"
                    // disabled={createError ? true : false}
                  >
                    <Add />
                  </IconButton>
                </div>
                <FormHelperText id="create-changelog-title-text">
                  {"Description for the changelog"}
                </FormHelperText>

                {changelogList && (
                  <DialogContent className={classes.ChangesList}>
                    {changelogList.map((item, key) => (
                      <div className={classes.changeItemContainer} key={key}>
                        <Paper className={classes.changeItem}>
                          {editChangelogItemNumber === key ? (
                            <TextField
                              multiline
                              color="primary"
                              type="text"
                              className={classes.ChangelogItemEdit}
                              value={changelogList[key]}
                              onChange={handleChangelogItemChange}
                              onKeyPress={handleChangelogItemKeyPress}
                              onBlur={handleBlur}
                            />
                          ) : (
                            <li
                              onDoubleClick={() => {
                                setEditChangelogItemNumber(key);
                              }}
                              className={classes.changeItemText}
                            >
                              {item}
                            </li>
                          )}
                        </Paper>
                        <IconButton
                          id={key}
                          onClick={() => {
                            handleDeleteChangelogItem(key);
                          }}
                        >
                          <Delete id={key} />
                        </IconButton>
                      </div>
                    ))}
                  </DialogContent>
                )}
              </div>
            </FormControl>

            <DialogContent className={classes.buttons}>
              <Button
                variant="outlined"
                className={classes.cancelButton}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                classes={{
                  root: classes.submitButton,
                }}
                onClick={handleSubmit}
                type="submit"
                disabled={!!titleError}
              >
                {"Save Template"}
              </Button>
            </DialogContent>
          </form>
        </DialogContent>
        <DialogActions />
      </div>
    </Dialog>
  );
};

export default SaveTemplateDialog;
