import { makeStyles } from "@material-ui/core/styles";

const LengthCounter = props => {
  const classes = useStyles({ length: props.promptLength });

  return (
    <div className={classes.SlangLenghtCounterStyle}>
      <small>{props.promptLength}</small>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  SlangLenghtCounterStyle: props => ({
    backgroundColor: props.length > 35 ? "#814A37" : "#3A5B58",
    marginRight: "20px",
    borderRadius: "50%",
    padding: "0px 7px",
  }),
}));

export default LengthCounter;
