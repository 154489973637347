import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel, a11yProps } from "../TabsContainer";
import { Grid, Typography } from "@material-ui/core";
import UIHints from "./UIHints";
import AddAppStatesForm from "./AddAppStatesForm";
import PromptsEdit from "./PromptsEdit";
import ParametersEdit from "./ParametersEdit";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs(props) {
  const {
    appStateUID,
    userJourneyID,
    userJourney,
    userJourneyName,
    appStatesMetadata,
    changeUserJourneyData,
    selectedValue,
    subDomains,
    changeSubDomainData,
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const AddAppStatesFormProps = {
    appStateUID,
    selectedAppState: selectedValue,
    handleSaveClose: changeUserJourneyData,
    open: true,
    userJourneyID,
    userJourney,
  };
  const allHints =
    appStatesMetadata[selectedValue] &&
    appStatesMetadata[selectedValue].uiHints;

  const promptsEditProp = {
    appStatesMetadata,
    selectedAppState: selectedValue,
    userJourneyID,
    changeUserJourneyData,
    heading: "Prompts",
  };

  const uiHintsProps = {
    selectedAppState: selectedValue,
    changeUserJourneyData,
    userJourneyID,
    userJourney,
    allHints,
    subDomains,
    changeSubDomainData,
  };

  const parametersEditProp = {
    appStatesMetadata,
    selectedAppState: selectedValue,
    userJourneyID,
    changeUserJourneyData,
    heading: "Parameters",
  };

  return (
    <Grid container>
      <Grid xs={2} item>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab label="Properties" {...a11yProps(0)} />
          <Tab label="Prompts" {...a11yProps(1)} />
          <Tab label="UI Hints" {...a11yProps(2)} />
          <Tab label="Parameters" {...a11yProps(3)} />
        </Tabs>
      </Grid>
      <Grid xs={10} item>
        <TabPanel value={value} index={0}>
          <Typography variant="h6"> Properties</Typography>
          <AddAppStatesForm {...AddAppStatesFormProps} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* <Typography variant="h6">Prompts</Typography> */}
          <PromptsEdit {...promptsEditProp} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {allHints ? <UIHints {...uiHintsProps} /> : null}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ParametersEdit {...parametersEditProp} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
