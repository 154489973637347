import { useState, useEffect, useReducer, createRef } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import subDomainUserJourneyReducer from "../../state/subDomainReducer";
import DeleteConfirm from "../DeleteConfirm";
import SubDomainDataList from "./SubDomainDataList";
import SubDomainAccordion from "./SubDomainAccordion";

function SubDomainItems(props) {
  const { subdomains, handleEditAppData, handleSelectError, selectError } =
    props;

  const [checked, setChecked] = useState(null);
  const [subdomainsState, dispatch] = useReducer(
    subDomainUserJourneyReducer,
    subdomains
  );
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("Are you sure ?");
  const [open, setOpen] = useState(false);
  const waitTime = 3000;
  const elementRefs = subdomainsState.map(item => {
    return item?.data?.map(_ => createRef());
  });

  const messageUser = ({ message, type }) => {
    handleSelectError({
      message,
      type,
    });
    setTimeout(() => {
      handleSelectError(null);
    }, waitTime);
  };

  useEffect(() => {
    const newChecked = subdomainsState
      ?.filter(SD => SD.enabled)
      .map(SD => SD.name);
    setChecked(newChecked);
  }, []);

  useEffect(() => {
    let didCancel = false;
    if (subdomainsState.length !== subdomains.length) {
      dispatch({
        type: "reState",
        payload: subdomains,
      });

      const newChecked = subdomains
        ?.filter(SD => SD.enabled)
        .map(SD => SD.name);
      setChecked(newChecked);
    } else if (subdomainsState && !didCancel) {
      if (!didCancel) {
        if (
          subdomainsState !== subdomains &&
          subdomainsState.length === subdomains.length
        ) {
          dispatch({
            type: "reState",
            payload: subdomains,
          });
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [subdomains]);
  useEffect(() => {
    let didCancel = false;
    if (subdomainsState.length !== subdomains.length) {
      const newChecked = subdomainsState
        ?.filter(SD => SD.enabled)
        .map(SD => SD.name);
      setChecked(newChecked);
      handleEditAppData(subdomainsState, "subDomains");
    }
    return () => {
      didCancel = true;
    };
  }, [subdomainsState]);

  const handleToggleReplace =
    ({ subDomainID, userDataID, toggle }) =>
    () => {
      dispatch({
        type: "toggleReplaceUserData",
        payload: { subDomainID, userDataID, toggle },
      });
    };

  const handleToggle = name => () => {
    const currentIndex = checked.indexOf(name);
    const newChecked = [...checked];
    let enabled = false;

    if (currentIndex === -1) {
      newChecked.push(name);
      enabled = true;
    } else {
      newChecked.splice(currentIndex, 1);
      enabled = false;
    }

    if (newChecked.length) {
      dispatch({ type: "toggle", payload: { name, enabled } });
      setChecked(newChecked);
    } else if (!selectError) {
      messageUser({
        message: "At least one Sub Domain must be selected",
        type: "error",
      });
    }
  };

  const handleDeleteUserData = payload => () => {
    const { subDomainID, userDataID, name } = payload;
    setDialogTitle(`Delete custom ${name} ?`);
    setOpen(true);
    setDeleteInfo({ subDomainID, userDataID, name });
  };
  const handleDeleteDataType = payload => () => {
    const { subDomainID, dataID, name } = payload;
    setDialogTitle(`Delete data type ${name} ?`);
    setOpen(true);
    setDeleteInfo({ subDomainID, dataID, name });
  };

  const handleDeleteSubDomain = payload => () => {
    const { subDomainID, name } = payload;
    setDialogTitle(`Delete Sub Domain ${name} ?`);
    setOpen(true);
    setDeleteInfo({ subDomainID, name });
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteInfo(null);
  };

  const handleDeleteID = () => {
    const { subDomainID, userDataID, dataID, name } = deleteInfo;
    if (userDataID !== undefined) {
      dispatch({
        type: "userDataDelete",
        payload: {
          subDomainID,
          userDataID,
        },
      });
      messageUser({
        message: "Deleted custom data",
        type: "initial",
      });
    } else if (dataID !== undefined) {
      dispatch({
        type: "dataTypeDelete",
        payload: {
          subDomainID,
          dataID,
        },
      });
      messageUser({
        message: "Deleted custom data",
        type: "initial",
      });
    } else if (subDomainID !== undefined) {
      dispatch({
        type: "delete",
        payload: {
          subDomainID,
        },
      });
      messageUser({
        message: `Deleted Sub Domain ${name}`,
        type: "initial",
      });
    }
    setDeleteInfo(null);
    setDialogTitle("Are you sure ?");
    setOpen(false);
  };

  const DeleteProps = { handleClose, handleDeleteID };
  const SubDomainAccordionProps = {
    handleToggle,
    handleDeleteSubDomain,
    handleEditAppData,
    subdomains,
  };
  const subDomainDataListProps = {
    dispatchSubdomainChange: dispatch,
    handleDeleteUserData,
    handleDeleteDataType,
    handleToggleReplace,
    handleEditAppData,
    subdomains,
    elementRefs,
  };
  return (
    <>
      <DeleteConfirm {...DeleteProps} state={{ open, dialogTitle }} />
      {checked ? (
        subdomainsState?.map((subDomain, subDomainID) => (
          <SubDomainAccordion
            key={subDomain.name}
            {...{
              ...SubDomainAccordionProps,
              subDomainID,
              subDomain,
            }}
          >
            <SubDomainDataList
              {...{ ...subDomainDataListProps, subDomainID, subDomain }}
            />
          </SubDomainAccordion>
        ))
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

SubDomainItems.propTypes = {
  subdomains: PropTypes.array.isRequired,
};

export default SubDomainItems;
