import { useState } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "@material-ui/core/Fade";

import LoginRedirect from "./login/login_redirect";
import LogOut from "./login/logout";
import NotFound from "./404.js";
import AutoLogin from "./AutoLogin";
import { isUserLoggedIn, signIn } from "../redux/actions/login";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./app_home/styles.js";
import * as SlangAnalytics from "../libs/analytics/slangAnalyticsAPIs";

import AppConfig from "../app.config";
import RoutesWrapper from "./common/routes/RoutesWrapper";
import { v4 as uuidv4 } from "uuid";

const mapDispatchToProps = dispatch => ({
  isUserLoggedIn: payload => dispatch(isUserLoggedIn(payload)),
  signIn: payload => dispatch(signIn(payload)),
});

const InitAnalytics = () => {
  const distinct_id = localStorage.getItem("distinct_id");
  if (distinct_id == null) {
    localStorage.setItem("distinct_id", uuidv4());
  }
  SlangAnalytics.initialiseAnalytics(AppConfig.SLANG_HOST, {
    user_id: localStorage.getItem("U_identity") || "0",
    user_email: localStorage.getItem("U_username") || "0",
    distinct_id: localStorage.getItem("distinct_id") || "0",
    browser_agent: navigator.userAgent,
    browser_platform: navigator.platform,
    browser_referrer: document.referrer,
    env: "stage", // Hard coded to stage by default. Only when 'promote to prod' is used, we will overwrite this to 'prod'.
  });
};

const Routes = props => {
  const [routesList, setRouteList] = useState([]);

  const { isAuthenticated } = useAuth0();

  const { signIn } = props;
  InitAnalytics();

  return (
    <div className={props.classes.root}>
      <RoutesWrapper setRoutesList={setRouteList} signIn={signIn}>
        <Switch>
          <Route
            exact
            path="/"
            render={propsChild =>
              isAuthenticated ? (
                <Redirect
                  {...propsChild}
                  to={{
                    pathname: "/home",
                    state: { from: props.location },
                  }}
                />
              ) : (
                <LoginRedirect {...propsChild} />
              )
            }
          />
          <Route exact path="/autoLogin/:payload" component={AutoLogin} />
          <Route exact path="/logout" component={LogOut} />
          {routesList?.length
            ? routesList.map((route, i) =>
                route.path.match("/home") ? (
                  <Route
                    key={i + "route"}
                    exact
                    path={route.path}
                    render={props =>
                      isAuthenticated ? (
                        <Fade in={true} timeout={{ enter: 500, exit: 1000 }}>
                          <div>
                            <route.component {...props} />
                          </div>
                        </Fade>
                      ) : (
                        <Redirect
                          to={{
                            pathname: "/logout",
                            state: { from: route.path },
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Route
                    key={i + "route"}
                    path={route.path}
                    component={route.component}
                  />
                )
              )
            : null}
          <Route exact path="/404" component={NotFound} />
          <Route
            path="/*"
            render={props => (
              <NotFound {...props} isLoggedin={isAuthenticated} />
            )}
          />
        </Switch>
      </RoutesWrapper>
    </div>
  );
};

Routes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Routes));
