export const FETCH_NEW_TIME = "FETCH_NEW_TIME";
export const FETCH_USER_TIME = "FETCH_USER_TIME";

export const SIGNIN = "SIGNIN";
export const SIGNUP = "SIGNUP";
export const SIGNOUT = "SIGNOUT";
export const CONFIRMSIGNUP = "CONFIRMSIGNUP";
export const FORGOT = "FORGOT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const IS_USER_LOGGED_IN = "IS_USER_LOGGED_IN";
export const RESET_ERROR = "RESET_ERROR";

export const GET_API = "GET_API";
export const CREATE_API = "CREATE_API";
export const RESET_API = "RESET_API";

export const APP_LIST = "APP_LIST";
export const DELETE_APP_LIST = "DELETE_APP_LIST";
export const APP_CREATE = "APP_CREATE";
export const APP_CLONE = "APP_CLONE";
export const APP_DELETE = "APP_DELETE";
export const APP_LIST_EDIT = "APP_LIST_EDIT";
export const APP_DATA_GET_TYPES = "APP_DATA_GET_TYPES";
export const APP_SCHEMA_DEFINITION = "APP_SCHEMA_DEFINITION";

export const APP_DATA = "APP_DATA";
export const APP_METADATA = "APP_METADATA";
export const APP_METADATA_TRAIN_STATUS = "APP_METADATA_TRAIN_STATUS";
export const APP_STRING_TABLE = "APP_STRING_TABLE";
export const APP_DATA_CHANGE = "APP_DATA_CHANGE";
export const APP_NAME_CHANGE = "APP_NAME_CHANGE";
export const APP_DATA_CLEAR = "APP_DATA_CLEAR";
export const APP_DATA_SAVED = "APP_DATA_SAVED";
export const APP_STRING_TABLE_CHANGE = "APP_STRING_TABLE_CHANGE";

export const GET_SCHEMA_DEFINITION = "GET_SCHEMA_DEFINITION";
export const VALIDATE_APP_SCHEMA = "VALIDATE_APP_SCHEMA";
export const UPLOAD_APP_SCHEMA = "UPLOAD_APP_SCHEMA";
export const UPLOAD_APP_STRING_TABLE = "UPLOAD_APP_STRING_TABLE";
export const SAVE_APP_SCHEMA = "SAVE_APP_SCHEMA";
export const PUBLISH_START = "PUBLISH_START";
export const PUBLISH_APP_SCHEMA = "PUBLISH_APP_SCHEMA";
export const RESET_STATE = "RESET_STATE";

export const REPLACE_UTTERANCE = "REPLACE_UTTERANCE";

export const USER_CREATE = "USER_CREATE";
export const USER_GET_PROFILE = "USER_GET_PROFILE";
export const USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE";
export const USER_GET_ALL_PLANS = "USER_GET_ALL_PLANS";
export const USER_GET_PLAN = "USER_GET_PLAN";
export const USER_UPDATE_PLAN = "USER_UPDATE_PLAN";
export const USER_TRANSC_USAGE = "USER_TRANSC_USAGE";
export const USER_SESSIONS_USAGE = "USER_SESSIONS_USAGE";
export const USER_INTENT_USAGE = "USER_INTENT_USAGE";
export const USER_RESET_IS_PLAN_UPDATE = "USER_RESET_IS_PLAN_UPDATE";
export const USER_RESET_IS_PROFILE_UPDATE = "USER_RESET_IS_PROFILE_UPDATE";
export const USER_ONBOARDING = "USER_ONBOARDING";

export const USER_GET_PRIVLG = "USER_GET_PRIVLG";

export const DOMAIN_LIST_FULL = "DOMAIN_LIST_FULL";

export const TEMPLATES_SUBDOMAINS_JOURNEYS = "TEMPLATES_SUBDOMAINS_JOURNEYS";

export const ASST_CONFIG_LIST = "ASST_CONFIG_LIST";
export const ASST_CONFIG_CREATE = "ASST_CONFIG_CREATE";
export const ASST_CONFIG_UPDATE = "ASST_CONFIG_UPDATE";
export const ASST_CONFIG_PUBLISH = "ASST_CONFIG_PUBLISH";
export const ASST_CONFIG_CREATE_TO_PUBLISH = "ASST_CONFIG_PUBLISH";
