import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Offline } from "react-detect-offline";
import { Beforeunload } from "react-beforeunload";

import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { TopNavStyles } from "./styles.js";
import withTheme from "../withTheme";
import asyncComponent from "./AsyncComponent";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";
import Save from "@material-ui/icons/Save";
import Assignment from "@material-ui/icons/Assignment";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Can from "../../utils/rbac/Can";
import AllPermissions from "../../utils/rbac/rbac-rules";
import { Prompt, Link, withRouter } from "react-router-dom";

import * as SlangTooltips from "./SlangTooltips";
import PublishButton from "./PublishButton";
import {
  saveAppSchema,
  startPublish,
  resetState,
} from "../../redux/actions/appDataUpload";
import { changeAppStringTable } from "../../redux/actions/appSchema";

import { testClassNames } from "../../utils/integrationTestClassNames";
import { validateStrings } from "../../utils/helpers";
import { checkIfUserBelongsToSlangLabs } from "../../utils/userUtils";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";
import AppConfig from "../../app.config";
import moment from "moment";
import BottomBar from "./BottomBar.js";
import AssistantUpgrader from "./AssistantUpgrader";
import { GetSetUserRoles } from "../../utils/auth/handleAuthState";
import {
  List,
  Popover,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import PublishPopover from "./PublishPopover.js";
import {
  getSaveAndTrainTimestamp,
  PUBLISH_ACTIVITY,
} from "../../utils/MiscUtils";

const Playground = asyncComponent(() => import("./playground"));

const mapDispatchToProps = dispatch => ({
  changeAppStringTable: appStrings =>
    dispatch(changeAppStringTable(appStrings)),
  resetState: () => dispatch(resetState()),
  saveAppSchema: payload => dispatch(saveAppSchema(payload)),
  publishAppSchema: payload => {
    dispatch(startPublish({ isPublished: "yes" }));
    dispatch(saveAppSchema(payload));
  },
});

const kclose = () =>
  !!(
    localStorage.getItem("keepPublishClosed") &&
    localStorage.getItem("keepPublishClosed") === "true"
  );

export class TopNav extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      openPROD: false,
      checkedB: false,
      keepClosed: kclose(),
      isLoading: false,
      isPublishing: false,
      isPublishingProd: false,
      label: "",
      login: true,
      lastSaved: null,
      lastStagePublished: null,
      lastProdPublished: null,
      recentPublishActivity: null,
      filterAppID: this.props.appList
        ? this.props.appList.filter(
            item => item.name === this.props.match.params.appname
          )
        : [
            {
              id: "",
            },
          ],
      notify: {
        variant: "info",
        message: "msg",
      },
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleCatchLink = event => {
    event.preventDefault();

    if (
      (sessionStorage.getItem("A_data") !== null && this.props.isDirty) ||
      this.state.isPublishing ||
      this.state.isLoading
    ) {
      // eslint-disable-next-line
      const result = confirm("You may lose unsaved changes, continue?");
      if (result) {
        this.props.history.push("/home");
      }
    } else {
      this.props.history.push("/home");
    }
  };

  handleDailogOpenPROD = () => {
    this.setState({
      openPROD: true,
    });
  };

  handleDialogClosePROD = () => {
    this.setState({
      openPROD: false,
    });
  };

  handleClick = label => (engine, task, env) => {
    let appDATA = null;
    let appStringTable = null;
    console.log(engine, env, task);
    try {
      appDATA = JSON.parse(JSON.stringify(this.props.appDATA));
      appStringTable = JSON.parse(JSON.stringify(this.props.appStringTable));
      const newAppStringTable = validateStrings(appDATA, appStringTable);
      appStringTable = newAppStringTable;
      this.props.changeAppStringTable({ appStringTable: newAppStringTable });
    } catch (error) {
      this.setState({
        notify: {
          variant: "error",
          message: error.message,
          autoHide: null,
        },
      });
      return;
    }

    if (typeof appDATA === "object" && typeof appStringTable === "object") {
      if (label === "PUBLISH") {
        this.props.publishAppSchema({
          env: "stage",
          appID: this.props.appID,
          assistant_version: appDATA.version,
          appStringTable,
          appSchema: appDATA,
          error: null,
          isPublished: "yes",
          engine,
          task,
        });
        this.setState({
          isLoading: true,
          isPublishing: true,
          openPROD: false,
          notify: {
            variant: "info",
            message: "Publish to staging started ...",
          },
        });
      } else if (label === "PROMOTE_TO_PROD") {
        this.props.publishAppSchema({
          env: "stage",
          appID: this.props.appID,
          assistant_version: appDATA.version,
          appStringTable,
          appSchema: appDATA,
          error: null,
          isPublished: "yes",
          isProd: "yes",
          engine,
          task,
        });

        this.setState({
          isLoading: true,
          isPublishingProd: true,
          openPROD: false,
          notify: {
            variant: "info",
            message: "Publish to prod started ...",
          },
        });
      } else if (label === "SAVE") {
        this.props.saveAppSchema({
          env: "stage",
          appID: this.props.appID,
          assistant_version: appDATA.version,
          appSchema: appDATA,
          appStringTable,
          error: null,
          isPublished: false,
        });

        this.setState({
          isLoading: true,
          isPublishing: false,
          notify: {
            variant: "info",
            message: "Save started ...",
          },
        });
      }
    } else {
      this.setState({
        notify: {
          variant: "warning",
          message: "Publish parsing JSON issue",
        },
      });
    }
  };

  handleDialogClose = () => {
    this.setState({
      openDialog: false,
      keepClosed: this.state.checkedB,
    });
    localStorage.setItem("keepPublishClosed", this.state.checkedB);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleLogout = event => {
    this.setState({
      login: false,
    });
  };

  handleUpgrade = (upgradeState, notifyMessage) => {
    this.setState({
      notify: { ...notifyMessage },
    });
    if (upgradeState === 1) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  checkUser = () => {
    const getRole = GetSetUserRoles();
    return getRole ? getRole[getRole.length - 1] : "visitor";
  };

  componentDidMount() {
    const currentTier =
      AppConfig.SLANG_ISPROD === "TRUE" ? "" : AppConfig.SLANG_TIER;
    document.title = `${currentTier} ${document.title}`;
    if (this.props.match.params.appname) {
      if (!this.state.filterAppID[0]) {
        this.props.history.push("/home");
      } else {
        const appId = this.state.filterAppID[0].id;
        const appName = this.state.filterAppID[0].name;
        document.title = `${currentTier} ${document.title} - ${appName}`;

        const saveTrainTimestamps = getSaveAndTrainTimestamp(
          this.props.appMetadata,
          this.props.appMetadataTrainStatus
        );
        this.setState({
          lastSaved: saveTrainTimestamps.lastSavedTimestamp,
          lastStagePublished: saveTrainTimestamps.lastStagePublishedTimestamp,
          lastProdPublished: saveTrainTimestamps.lastProdPublishedTimestamp,
          recentPublishActivity: saveTrainTimestamps.recentActivity,
        });
      }
    }

    if (this.props.isPublished === "yes") {
      this.setState({
        isLoading: true,
        isPublishing: true,
      });
    }
  }

  componentWillUnmount() {
    document.title = "CONVA - Slang Labs";
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (
        prevProps.notify !== this.props.notify &&
        this.props.notify.message !== ""
      ) {
        this.setState({
          notify: this.props.notify,
        });
      }
      const saveTrainTimestamps = getSaveAndTrainTimestamp(
        this.props.appMetadata,
        this.props.appMetadataTrainStatus
      );

      if (this.props.appMetadataTrainStatus) {
        this.setState({
          lastSaved: saveTrainTimestamps.lastSavedTimestamp,
          lastStagePublished: saveTrainTimestamps.lastStagePublishedTimestamp,
          lastProdPublished: saveTrainTimestamps.lastProdPublishedTimestamp,
          recentPublishActivity: saveTrainTimestamps.recentActivity,
        });
      }

      if (this.props.appID) {
        if (this.props.isPublished === true) {
          localStorage.setItem(
            "A_" + this.props.appID.toString(),
            moment().format().toString()
          );

          this.setState({
            lastSaved: saveTrainTimestamps.lastSavedTimestamp,
            lastStagePublished: saveTrainTimestamps.lastStagePublishedTimestamp,
            lastProdPublished: saveTrainTimestamps.lastProdPublishedTimestamp,
            recentPublishActivity: saveTrainTimestamps.recentActivity,
          });

          this.setState({
            isLoading: false,
            isPublishing: false,
            isPublishingProd: false,
            keepClosed: kclose(),
            openDialog: true,
            notify: {
              variant: "success",
              message: "Your assistant has been published successfully",
            },
          });

          setTimeout(() => {
            this.props.resetState();
          }, 1000);
          return;
        } else if (this.props.isPublished === "yes") {
          this.setState({
            isLoading: true,
            isPublishing: true,
          });
        } else if (this.props.isSaved) {
          this.setState({
            isLoading: false,
            notify: {
              variant: "success",
              message: "Save successful !",
            },
          });

          this.props.resetState();
        }

        if (this.props.errorSave) {
          this.setState({
            isLoading: false,
            isPublishing: false,
            isPublishingProd: false,
            notify: {
              variant: "error",
              message: this.props.errorSave,
              autoHide: null,
            },
          });

          // TODO: Identify if the error was from save or publish properly
          // if (this.props.isSaved === true) {
          //   //Must be publish error
          //   logEvent(
          //     SlangSeverityLevels.ERROR,
          //     SlangEvents.APP_PUBLISH_FAILURE,
          //     {
          //       error: this.props.errorSave,
          //     }
          //   );
          // } else {
          //   logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
          //     error: this.props.errorSave,
          //   });
          // }

          this.props.resetState();
        }
      }
    }

    if (
      this.props.isPublished === "yes" &&
      !this.state.isLoading &&
      !this.state.isPublishing
    ) {
      this.setState({
        isLoading: true,
        isPublishing: true,
      });
    }
  }

  render() {
    const {
      classes,
      location,
      showPublish,
      match,
      isDirty,
      appID,
      appMetadata,
      appMetadataTrainStatus,
      appDATA,
    } = this.props;
    const { handleClick, handleDailogOpenPROD, handleUpgrade } = this;
    const breadcrumb = location.pathname.split("/").slice(1);
    const api_key = localStorage.getItem("S_apiKey")
      ? localStorage.getItem("S_apiKey")
      : null;
    const {
      openDialog,
      openMessage,
      anchorEl,
      openPROD,
      keepClosed,
      isLoading,
      isPublishing,
      isPublishingProd,
      notify,
      lastSaved,
      lastProdPublished,
      lastStagePublished,
      recentPublishActivity,
    } = this.state;

    const viewPublish = Can({
      perform: AllPermissions.ASSISTANT_CONFIG.PUBLISH,
      yes: () => showPublish,
      no: () => false,
    });
    const viewPlayground = Can({
      perform: AllPermissions.ASSISTANT_TEMPLATES.PLAYGOUND,
      yes: () => true,
      no: () => false,
    });
    let link = "";
    const appName = match.params.appname;
    const template = match.params.templateID;
    const message = () => (
      <Paper
        className={`${classes.notifyChange} ${
          isDirty
            ? classes.unsaved
            : lastSaved || lastProdPublished || lastStagePublished
            ? classes.saved
            : null
        }`}
        elevation={0}
      >
        {isDirty ? (
          <Typography>
            {" "}
            <Icon style={{ fontSize: "1em", paddingTop: 2 }}>
              error_outlined
            </Icon>{" "}
            {"Unsaved changes"}
          </Typography>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography aria-owns={"mouse-over-popover"}>
              {recentPublishActivity === PUBLISH_ACTIVITY.PROD_PUBLISH ? (
                <span>{`Production: Published ${moment(lastProdPublished)
                  .fromNow()
                  .toString()}`}</span>
              ) : recentPublishActivity === PUBLISH_ACTIVITY.STAGE_PUBLISH ? (
                <span>{`Stage: Published ${moment(lastStagePublished)
                  .fromNow()
                  .toString()}`}</span>
              ) : recentPublishActivity === PUBLISH_ACTIVITY.SAVE ? (
                <span>{`Saved: ${moment(lastSaved)
                  .fromNow()
                  .toString()}`}</span>
              ) : null}
            </Typography>
            &nbsp;&nbsp;
            {appMetadata && appMetadataTrainStatus ? (
              <PublishPopover
                metadata={appMetadata}
                metadataTrainStatus={appMetadataTrainStatus}
              />
            ) : null}
          </div>
        )}
      </Paper>
    );

    const PublishButtonProps = {
      isLoading,
      isPublishing,
      isPublishingProd,
      handleClick,
      handleDailogOpenPROD,
    };

    const AssistantUpgraderProps = {
      appMetadata,
      appDATA,
      appID,
      handleUpgrade,
    };

    return (
      <div>
        <Dialog
          open={keepClosed ? false : openDialog}
          onClose={this.handleClose}
        >
          <DialogTitle>Slang Assistant Integration</DialogTitle>
          <DialogContent>
            <img
              src="/images/sketch_empty.png"
              alt="Publish Success"
              style={{
                height: "120px",
                marginRight: "10px",
                width: "auto",
                float: "left",
              }}
            />
            <DialogContentText
              style={{
                paddingTop: "20px",
              }}
            >
              The assistant published successfully, now integrate the Slang's
              In-App Voice Assistant into your application.
              <br />
              <a
                href="https://docs.slanglabs.in/slang/getting-started/integrating-slang-retail-assistant/code-integration-basic-steps"
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  color: "#139a7a",
                }}
              >
                {" "}
                Here{" "}
              </a>{" "}
              is the documentation.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.leftAlign}>
            <Typography>
              <Checkbox
                checked={this.state.checkedB}
                onChange={this.handleChange("checkedB")}
                name="checkedB"
                color="primary"
                label="Already Integrated"
              />
              <>Do not show this again</>
            </Typography>
            <Button color="primary" onClick={this.handleDialogClose}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openPROD} onClose={this.handleDialogClosePROD}>
          <DialogContent>
            <DialogContentText>
              The changes will be pushed to Prod environment.
              <br />
              <br />
              Associated apps that have instantiated this Assistant and are
              currently using the production environment will see these changes.
              <br />
              <br />
              Please confirm that this is what you intent to do. If you are
              still testing, we suggest you <strong>
                Publish to Staging
              </strong>{" "}
              instead
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.leftAlign}>
            <Button color="initial" onClick={this.handleDialogClosePROD}>
              {" Cancel  "}
            </Button>
            <Button color="primary" onClick={this.handleClick("PUBLISH")}>
              {"  Publish to Staging "}
            </Button>
            <Button
              color="primary"
              onClick={this.handleClick("PROMOTE_TO_PROD")}
              className={`${testClassNames.PUBLISH_TO_PROD}`}
            >
              Publish to Prod
            </Button>
          </DialogActions>
        </Dialog>
        <Offline polling={{ interval: 10000 }}>
          <Grid xs={12} item className={classes.noconnection}>
            You're offline right now. Check your connection.
          </Grid>
        </Offline>
        <Grid container className={classes.bottomMargin}>
          <Grid
            container
            spacing={0}
            alignContent="flex-start"
            alignItems="center"
            direction="row"
            justify="space-between"
          >
            <Grid sm={12} md={6} xl={8} item>
              <Typography
                variant="subtitle1"
                color="primary"
                className={classes.headLinks}
                noWrap
              >
                {breadcrumb.map((crumb, key) => {
                  link = link + "/" + crumb;

                  if (key < 1) {
                    if (crumb === "home" || crumb === "home/assistants") {
                      return (
                        <span key={key} className={classes.toUpper}>
                          <a href={"/" + crumb} onClick={this.handleCatchLink}>
                            {crumb}
                          </a>
                        </span>
                      );
                    } else {
                      return (
                        <span key={key} className={classes.toUpper}>
                          <Link to={"/" + crumb}>{crumb}</Link>
                        </span>
                      );
                    }
                  } else if (key < 2 && crumb === "home/assistants") {
                    return (
                      <span key={key} className={classes.toUpper}>
                        <Icon className={classes.iconRight}>chevron_right</Icon>
                        <a href={"/" + crumb} onClick={this.handleCatchLink}>
                          {crumb}
                        </a>
                      </span>
                    );
                  } else {
                    return (
                      <span key={key} className={classes.toUpper}>
                        {" "}
                        <Icon className={classes.iconRight}>chevron_right</Icon>
                        <Link to={{ pathname: link }}>{crumb}</Link>
                      </span>
                    );
                  }
                })}
              </Typography>
            </Grid>
            {viewPublish && appID && (
              <Fragment>
                <Hidden only={["md", "lg", "xl"]}>
                  {!isLoading && (
                    <Grid
                      style={{ textAlign: "left" }}
                      container
                      item
                      sm={4}
                      justify="flex-end"
                      children={message()}
                    />
                  )}
                </Hidden>
                <Grid sm={8} md={6} xl={4} style={{ textAlign: "right" }} item>
                  <Hidden only={["xs", "sm", "md"]}>{message()}</Hidden>
                  <Tooltip
                    title={SlangTooltips.SAVE}
                    classes={{ tooltip: classes.tooltip }}
                    disableFocusListener
                    disableHoverListener
                  >
                    <Button
                      variant="text"
                      className={`${
                        isDirty ? classes.saveButton : classes.saveButtonDefault
                      } ${testClassNames.BUDDY_SAVE_BUTTON}`}
                      onClick={this.handleClick("SAVE")}
                      disabled={isLoading || isPublishing}
                    >
                      {" "}
                      {isLoading ? (
                        <CircularProgress
                          size={24}
                          style={{ marginRight: 5 }}
                          color="secondary"
                        >
                          <Save />
                        </CircularProgress>
                      ) : (
                        <Save style={{ marginRight: 5 }} />
                      )}
                      SAVE
                    </Button>
                  </Tooltip>
                  <PublishButton {...PublishButtonProps} />
                  {this.checkUser() === "Slang Admin" ? (
                    <AssistantUpgrader {...AssistantUpgraderProps} />
                  ) : null}
                  {viewPlayground && appID && appID.length === 32 && api_key ? (
                    <Playground appID={appID} apiKey={api_key} />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Hidden only={["xs", "sm", "lg", "xl"]}>
                  {!isLoading && (
                    <Grid
                      style={{ textAlign: "left" }}
                      item
                      lg={3}
                      md={6}
                      sm={4}
                      children={message()}
                    />
                  )}
                </Hidden>
              </Fragment>
            )}
          </Grid>
        </Grid>
        {(appName || template) && isDirty && (
          <>
            <Prompt
              when={isDirty}
              message={"Changes that you made may not be saved."}
            />
          </>
        )}
        <BottomBar classes={classes} notify={notify} />
      </div>
    );
  }
}

TopNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    userBelongsToSlangLabs: checkIfUserBelongsToSlangLabs(state.login.username),
    login: state.login.identity,
    isAuth: state.login.isAuth,
    appList: state.appSchema.list,
    appID: state.appSchema.appID,
    isValidSchema: state.appDataUpload.isValidSchema,
    isSaved: state.appDataUpload.isSaved,
    errorSave: state.appDataUpload.error,
    appDATA: state.appSchema.appDATA,
    isPublished: state.appDataUpload.isPublished,
    isDirty: state.appSchema.isDirty,
    appStringTable: state.appSchema.appStringTable,
    appMetadata: state.appSchema.appMetadata,
    appMetadataTrainStatus: state.appSchema.appMetadataTrainStatus,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTheme(withStyles(TopNavStyles)(TopNav)))
);
