import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Chip,
  Paper,
  Grid,
  Divider,
} from "@material-ui/core";
import {
  Android,
  Web,
  Apple,
  AddCircleOutline,
  Check,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import AppConfig from "../../../../app.config";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";

const Langs = AppConfig.LANGUAGES;

const styles = makeStyles(theme => ({
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    textAlign: "left",
    background: theme.palette.background.default,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chip1: {
    margin: theme.spacing(1),
  },
  promptContainer: {
    padding: theme.spacing(2.5),
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 300,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  platformControl: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

const LanguageSettings = props => {
  const { handleEditAppData } = props;

  const { localeConfigs } = useSelector(state => ({
    localeConfigs: state.appSchema.appDATA.localeConfigs,
  }));

  const [defaultLang, setDefaultLang] = useState("");
  const [selectedLangs, setSelectedLangs] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("android");

  const handleChangePlatform = (event, currentTab) => {
    if (currentTab !== null) {
      setSelectedPlatform(currentTab);
    }
  };

  const updateLocaleState = () => {
    const selectedPlatformLocaleConfig = localeConfigs?.find(
      item => item.platform === selectedPlatform
    );

    const requestedLanguages = {};

    if (selectedPlatformLocaleConfig) {
      setErrorMessage("");
      const _localeConfig = selectedPlatformLocaleConfig.localeConfig;

      Langs.forEach(lang => {
        if (_localeConfig.requested.includes(lang.code)) {
          requestedLanguages[lang.code] = true;
        } else {
          requestedLanguages[lang.code] = false;
        }
        setSelectedLangs(requestedLanguages);
        setDefaultLang(_localeConfig.default);
      });
    } else {
      setErrorMessage("Please select at least 1 locale");
      Langs.forEach(lang => {
        requestedLanguages[lang.code] = false;
      });
      setSelectedLangs(requestedLanguages);
      setDefaultLang("");
    }
  };

  useEffect(() => {
    updateLocaleState();
  }, [selectedPlatform]);

  const classes = styles();

  const handleDefaultLangChange = e => {
    const newDefaultLang = e.target.value;
    setDefaultLang(newDefaultLang);
  };

  // modify the UI state for supported locales
  const handleSupportedLangChange = e => {
    let defaultLocale = defaultLang;
    const newSelectedLangs = {
      ...selectedLangs,
      [e.target.name]: e.target.checked,
    };
    setSelectedLangs(newSelectedLangs);
    // Set a default locale if no default locale is selected on requested locale change
    if (defaultLocale === "") {
      Object.keys(newSelectedLangs).forEach(item => {
        if (newSelectedLangs[item]) {
          if (defaultLocale === "") {
            defaultLocale = item;
            setDefaultLang(defaultLocale);
          }
        }
      });
      // If default locale is removed from selected locale, select the next in queue or set default as empty
    } else if (defaultLocale === e.target.name) {
      const requestedLocales = Object.keys(newSelectedLangs).filter(
        item => newSelectedLangs[item]
      );
      defaultLocale = requestedLocales[0] ? requestedLocales[0] : "";
      setDefaultLang(defaultLocale);
    }

    handleSaveLocaleSettings(defaultLocale, newSelectedLangs);
  };
  // Save locale config to schema
  const handleSaveLocaleSettings = (
    defaultLocale = defaultLang,
    requested = selectedLangs
  ) => {
    let newLocaleConfigs = [];
    if (localeConfigs) {
      newLocaleConfigs = localeConfigs;
    }
    const isPlatformExist = newLocaleConfigs?.find(
      item => item?.platform === selectedPlatform
    );

    const requestedLocales = Object.keys(requested).filter(
      item => requested[item]
    );
    if (requestedLocales.length >= 1 && defaultLocale !== "") {
      setErrorMessage("");
      if (isPlatformExist) {
        newLocaleConfigs = newLocaleConfigs.map(config => {
          if (config.platform === selectedPlatform) {
            return {
              platform: config.platform,
              localeConfig: {
                name: `locale_config_${config.platform}`,
                requested: requestedLocales,
                default: defaultLocale,
              },
            };
          }
          return config;
        });
      } else {
        newLocaleConfigs.push({
          platform: selectedPlatform,
          localeConfig: {
            name: `locale_config_${selectedPlatform}`,
            requested: requestedLocales,
            default: defaultLocale,
          },
        });
      }
      handleEditAppData(newLocaleConfigs, "localeConfigs");
      logEvent(
        SlangSeverityLevels.INFO,
        SlangEvents.APP_LANGUAGE_SETTINGS_UPDATED,
        {
          selectedPlatform,
          defaultLocale,
          requested_locales: requestedLocales.join(", "),
        }
      );
    } else {
      setErrorMessage("Please select at least 1 locales");
    }
  };

  return (
    <div>
      <div>
        <ToggleButtonGroup
          color="primary"
          value={selectedPlatform}
          exclusive
          onChange={handleChangePlatform}
          aria-label="text alignment"
          className={classes.platformControl}
        >
          <ToggleButton value={"android"}>
            Android &nbsp; <Android />
          </ToggleButton>
          <ToggleButton value={"web"}>
            Web &nbsp; <Web />
          </ToggleButton>
          <ToggleButton value={"ios"}>
            iOS &nbsp; <Apple />
          </ToggleButton>
        </ToggleButtonGroup>
        <br />
        <Paper className={classes.promptPaper}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="h6" className={classes.chip}>
                {"Supported Locales"}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={8}>
              {errorMessage !== "" ? (
                <Typography
                  variant="caption"
                  color="error"
                  className={classes.chip1}
                >
                  {errorMessage}
                </Typography>
              ) : null}
              <br />
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <div className={classes.container}>
                    {Langs.map((lang, key) => (
                      <Chip
                        key={key + lang.text}
                        label={`${lang.text} (${lang.locale})`}
                        color={selectedLangs[lang.code] ? "primary" : "default"}
                        onClick={() =>
                          handleSupportedLangChange({
                            target: {
                              name: lang.code,
                              checked: !selectedLangs[lang.code],
                            },
                          })
                        }
                        clickable
                        className={classes.chip1}
                        variant={
                          selectedLangs[lang.code] ? "default" : "outlined"
                        }
                        onDelete={() =>
                          handleSupportedLangChange({
                            target: {
                              name: lang.code,
                              checked: !selectedLangs[lang.code],
                            },
                          })
                        }
                        deleteIcon={
                          selectedLangs[lang.code] ? (
                            <Check />
                          ) : (
                            <AddCircleOutline />
                          )
                        }
                      />
                    ))}
                  </div>
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <br />
        <Paper className={classes.promptPaper}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="h6" className={classes.chip} noWrap>
                {"Default Locale"}
                {/* <DocHelpLink docFor="ASR_HINTS" /> */}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={8}>
              <div className={classes.container}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="default-lang-label">
                    Default Locale
                  </InputLabel>
                  <Select
                    labelId="default-lang-label"
                    id="default-lang"
                    value={defaultLang}
                    onChange={handleDefaultLangChange}
                    onBlur={e => handleSaveLocaleSettings()}
                    label="Default Locale"
                    size="small"
                  >
                    {Object.keys(selectedLangs).map((lang, key) =>
                      selectedLangs[lang] ? (
                        <MenuItem key={key + lang} value={lang}>
                          {Langs.find(item => item.code === lang).text}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default LanguageSettings;
