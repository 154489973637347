import AppConfig from "../../app.config";
import SlangSchemaValidator from "../../libs/slang/slang_schema_validator";
import SlangSchemaActions from "../../libs/slang/slang_schema_actions";

import {
  getSchemaDefinition,
  validateAppSchema,
  uploadAppStrings,
  uploadAppSchema,
  publishAppSchema,
} from "../actions/appDataUpload";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";

import {
  savedAppDATA,
  getAppMetaData,
  getAppMetadataTrainStatus,
} from "../actions/appSchema";
import { isUserLoggedIn } from "../actions/login";

import * as types from "../types";
import * as helper from "./helper";

import {
  GetSetUserRoles,
  GetSetAccessTokenRaw,
  GetSetAppMetadata,
} from "../../utils/auth/handleAuthState";
import { v4 } from "uuid";

// Temp this will change once roles and premissions are in place.
const getUserRole = () => {
  const getRoles = GetSetUserRoles();
  return getRoles ? getRoles[getRoles.length - 1] : "visitor";
};

const getOrgID = () => {
  const orgID = GetSetAppMetadata().organization_id;
  return orgID || "none";
};

let appDataUploadID = null;

export const appDataUploadActions = store => next => action => {
  if (!action.meta || action.meta.type !== "APPUPLOAD") {
    return next(action);
  }

  const { payload } = action;
  const { appID, isProd, assistant_version } = action.payload;
  let { env } = action.payload;

  store.dispatch(isUserLoggedIn({}));
  const {
    login: { identity, isAuth },
    apikey: { api_key, api_secret },
  } = store.getState();

  if (!identity || !isAuth || !api_key || api_key === "" || !api_secret) {
    const newPayload = {
      ...payload,
      error: "Error: id or api key/secret not found",
    };
    helper.prepareAction(action, newPayload, store);
    return;
  }

  // Temp this will change once roles and premissions are in place.
  const orgID = getOrgID();
  const api_secret_role = `${api_secret}|${getUserRole()}`;
  const orgID_token = `${orgID}|${GetSetAccessTokenRaw()}`;

  // Adding a unique id to track a single publish cycle

  switch (action.type) {
    case types.SAVE_APP_SCHEMA:
      appDataUploadID = v4();
      logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_SAVE_STARTED, {
        env,
        app_data_upload_id: appDataUploadID,
      });
      console.log("Save started ..." + payload.appID);
      return store.dispatch(getSchemaDefinition(payload));

    case types.GET_SCHEMA_DEFINITION:
      logEvent(
        SlangSeverityLevels.INFO,
        SlangEvents.FETCH_SCHEMA_DEFINITION_STARTED,
        {
          env,
          app_data_upload_id: appDataUploadID,
        }
      );
      SlangSchemaActions.fetchSlangSchemaDefinition(AppConfig.SLANG_HOST)
        .then(schema => {
          if (!schema) {
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.FETCH_SCHEMA_DEFINITION_FAILURE,
              {
                env,
                error: schema.trace,
                app_data_upload_id: appDataUploadID,
              }
            );
            logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
              env,
              app_data_upload_id: appDataUploadID,
              error: `FETCH_SCHEMA_DEFINITION_FAILURE - Check event ${SlangEvents.FETCH_SCHEMA_DEFINITION_FAILURE}`,
            });
          }

          sessionStorage.setItem("D_schema", JSON.stringify(schema, null, 2));

          logEvent(
            SlangSeverityLevels.INFO,
            SlangEvents.FETCH_SCHEMA_DEFINTIION_SUCCESS,
            {
              env,
              app_data_upload_id: appDataUploadID,
            }
          );

          const newPayload = {
            ...payload,
            schemaDefinition: schema,
            error: null,
          };
          return store.dispatch(validateAppSchema(newPayload));
        })
        .catch(err => {
          logEvent(
            SlangSeverityLevels.ERROR,
            SlangEvents.FETCH_SCHEMA_DEFINITION_FAILURE,
            {
              env,
              error: err.toString(),
              app_data_upload_id: appDataUploadID,
            }
          );
          logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
            env,
            app_data_upload_id: appDataUploadID,
            error: `FETCH_SCHEMA_DEFINITION_FAILURE - Check event ${SlangEvents.FETCH_SCHEMA_DEFINITION_FAILURE}`,
          });
          console.log(err.toString());

          const payload = {
            error: "fetch schema error",
            schemaDefinition: null,
          };
          return helper.prepareAction(action, payload, store);
        });
      break;
    case types.VALIDATE_APP_SCHEMA:
      console.log("Validating App Schema ... " + appID);
      logEvent(SlangSeverityLevels.INFO, SlangEvents.VALIDATE_SCHEMA_STARTED, {
        env,
        app_data_upload_id: appDataUploadID,
      });
      const result = SlangSchemaValidator.validate(
        payload.schemaDefinition,
        payload.appSchema
      );

      if (result.success) {
        const newPayload = {
          ...payload,
          isValid: true,
          error: null,
        };
        logEvent(
          SlangSeverityLevels.INFO,
          SlangEvents.VALIDATE_SCHEMA_SUCCESS,
          {
            env,
            app_data_upload_id: appDataUploadID,
          }
        );
        return store.dispatch(uploadAppStrings(newPayload));
      } else {
        console.log(result.text);
        logEvent(
          SlangSeverityLevels.ERROR,
          SlangEvents.VALIDATE_SCHEMA_FAILURE,
          {
            env,
            error: result.text,
            app_data_upload_id: appDataUploadID,
          }
        );
        logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
          env,
          error: `VALIDATE_SCHEMA_FAILURE - Check event ${SlangEvents.VALIDATE_SCHEMA_FAILURE}`,
          app_data_upload_id: appDataUploadID,
        });
        const newPayload = {
          error: result.text,
        };
        return helper.prepareAction(action, newPayload, store);
      }
    case types.UPLOAD_APP_STRING_TABLE:
      const jsonAppStringTable = JSON.stringify(payload.appStringTable);
      if (jsonAppStringTable) {
        logEvent(
          SlangSeverityLevels.INFO,
          SlangEvents.UPLOAD_APP_STRING_TABLE_STARTED,
          {
            env,
            app_data_upload_id: appDataUploadID,
          }
        );
        SlangSchemaActions.saveAppStrings(
          AppConfig.SLANG_HOST,
          orgID_token,
          api_secret_role,
          {
            appId: appID,
            env,
            assistant_version,
            appStringTable: jsonAppStringTable,
          }
        )
          .then(json => {
            if (!json.success) {
              logEvent(
                SlangSeverityLevels.ERROR,
                SlangEvents.UPLOAD_APP_STRING_TABLE_FAILURE,
                {
                  env,
                  error: JSON.stringify(json.trace),
                  app_data_upload_id: appDataUploadID,
                }
              );
              logEvent(
                SlangSeverityLevels.ERROR,
                SlangEvents.APP_SAVE_FAILURE,
                {
                  env,
                  app_data_upload_id: appDataUploadID,
                  error: `UPLOAD_APP_STRING_TABLE_FAILURE - Check event ${SlangEvents.UPLOAD_APP_STRING_TABLE_FAILURE}`,
                }
              );
              throw Error(json.message);
            } else {
              console.log("Saved string table ... " + appID);
              logEvent(
                SlangSeverityLevels.INFO,
                SlangEvents.UPLOAD_APP_STRING_TABLE_SUCCESS,
                {
                  env,
                  app_data_upload_id: appDataUploadID,
                }
              );
              return store.dispatch(uploadAppSchema(payload));
            }
          })
          .catch(err => {
            console.log(err);
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.UPLOAD_APP_STRING_TABLE_FAILURE,
              {
                env,
                error: err.toString(),
                app_data_upload_id: appDataUploadID,
              }
            );
            logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
              env,
              app_data_upload_id: appDataUploadID,
              error: `UPLOAD_APP_STRING_TABLE_FAILURE - Check event ${SlangEvents.UPLOAD_APP_STRING_TABLE_FAILURE}`,
            });
            const payload = {
              error: "There was a server error while saving strings.",
              isSaved: false,
              isValid: false,
            };
            return helper.prepareAction(action, payload, store);
          });
      } else {
        const payload = {
          error: "Missing API key & JSON error",
          isSaved: false,
        };
        logEvent(
          SlangSeverityLevels.ERROR,
          SlangEvents.UPLOAD_APP_STRING_TABLE_FAILURE,
          {
            env,
            error: payload.error,
            app_data_upload_id: appDataUploadID,
          }
        );
        logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
          env,
          app_data_upload_id: appDataUploadID,
          error: `UPLOAD_APP_STRING_TABLE_FAILURE - Check event ${SlangEvents.UPLOAD_APP_STRING_TABLE_FAILURE}`,
        });
        helper.prepareAction(action, payload, store);
      }

      break;
    case types.UPLOAD_APP_SCHEMA:
      const jsonAppDATA = JSON.stringify(payload.appSchema);

      if (jsonAppDATA) {
        logEvent(
          SlangSeverityLevels.INFO,
          SlangEvents.UPLOAD_APP_SCHEMA_STARTED,
          {
            env,
            app_data_upload_id: appDataUploadID,
          }
        );
        SlangSchemaActions.saveIntents(
          AppConfig.SLANG_HOST,
          orgID_token,
          api_secret_role,
          {
            appId: appID,
            env,
            assistant_version,
            appIntentSchema: jsonAppDATA,
          }
        )
          .then(json => {
            if (!json.success) {
              logEvent(
                SlangSeverityLevels.ERROR,
                SlangEvents.UPLOAD_APP_SCHEMA_FAILURE,
                {
                  env,
                  error: JSON.stringify(json.trace),
                  app_data_upload_id: appDataUploadID,
                }
              );
              logEvent(
                SlangSeverityLevels.ERROR,
                SlangEvents.APP_SAVE_FAILURE,
                {
                  env,
                  error: `UPLOAD_APP_SCHEMA_FAILURE - Check event ${SlangEvents.UPLOAD_APP_SCHEMA_FAILURE}`,
                  app_data_upload_id: appDataUploadID,
                }
              );
              throw Error(json.message);
            } else {
              console.log("Saved App Schema ... " + appID);

              logEvent(
                SlangSeverityLevels.INFO,
                SlangEvents.UPLOAD_APP_SCHEMA_SUCCESS,
                {
                  env,
                  app_data_upload_id: appDataUploadID,
                }
              );
              store.dispatch(savedAppDATA());

              if (payload.isPublished === "yes") {
                const newPayload = {
                  ...payload,
                  appID,
                  appSchema: payload.appSchema,
                  error: null,
                  isSaved: true,
                  isValid: true,
                  isPublished: false,
                };
                return store.dispatch(publishAppSchema(newPayload));
              } else {
                const payload2 = {
                  appID,
                  appSchema: payload.appSchema,
                  error: null,
                  isSaved: true,
                  isValid: true,
                };
                // update app metadata when save success
                store.dispatch(getAppMetaData({ appID, assistant_version }));
                store.dispatch(
                  getAppMetadataTrainStatus({ appID, assistant_version })
                );
                return helper.prepareAction(action, payload2, store);
              }
            }
          })
          .catch(err => {
            const error = err.toString();
            console.log(error);

            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.UPLOAD_APP_SCHEMA_FAILURE,
              {
                env,
                error,
                app_data_upload_id: appDataUploadID,
              }
            );
            logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
              env,
              error: `UPLOAD_APP_SCHEMA_FAILURE - Check event ${SlangEvents.UPLOAD_APP_SCHEMA_FAILURE}`,
              app_data_upload_id: appDataUploadID,
            });
            // update app metadata when save failed
            store.dispatch(getAppMetaData({ appID, assistant_version }));
            store.dispatch(
              getAppMetadataTrainStatus({ appID, assistant_version })
            );
            const payload = {
              error: error || "There was a server error while saving.",
              isSaved: false,
              isValid: false,
            };
            helper.prepareAction(action, payload, store);
          });
      } else {
        const payload = {
          error: "Missing API key & JSON error",
          isSaved: false,
        };
        logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
          env,
          error: payload.error,
          app_data_upload_id: appDataUploadID,
        });
        helper.prepareAction(action, payload, store);
      }

      break;

    case types.PUBLISH_APP_SCHEMA:
      logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_PUBLISH_STARTED, {
        env,
        app_data_upload_id: appDataUploadID,
      });
      if (payload.isSaved) {
        SlangSchemaActions.publishIntents(
          AppConfig.SLANG_HOST,
          orgID_token,
          api_secret_role,
          {
            appId: appID,
            env,
            assistant_version,
            engine: action.payload.engine,
            task: action.payload.task,
          }
        )
          .then(json => {
            if (!json.success) {
              logEvent(
                SlangSeverityLevels.ERROR,
                SlangEvents.APP_PUBLISH_FAILURE,
                {
                  env,
                  error: JSON.stringify(json.trace),
                  app_data_upload_id: appDataUploadID,
                }
              );
              throw Error(json.message);
            } else {
              console.log("Publish Success!");
              let payload;

              if (isProd === "yes") {
                payload = {
                  ...action.payload,
                  env: "prod",
                  error: null,
                  isPublished: "yes",
                  isProd: false,
                  isSaved: false,
                  isValid: true,
                };

                return store.dispatch(uploadAppStrings(payload));
              } else {
                payload = {
                  error: null,
                  isPublished: true,
                  isSaved: true,
                  isValid: true,
                };
              }
              // update the app metadata when publish complete
              store.dispatch(getAppMetaData({ appID, assistant_version }));
              store.dispatch(
                getAppMetadataTrainStatus({ appID, assistant_version })
              );

              logEvent(
                SlangSeverityLevels.INFO,
                SlangEvents.APP_PUBLISH_SUCCESS,
                {
                  env,
                  app_data_upload_id: appDataUploadID,
                }
              );
              helper.prepareAction(action, payload, store);
            }
          })
          .catch(err => {
            const error = err.toString();
            logEvent(
              SlangSeverityLevels.ERROR,
              SlangEvents.APP_PUBLISH_FAILURE,
              {
                env,
                error,
                app_data_upload_id: appDataUploadID,
              }
            );
            // update app metadata when publish failed
            store.dispatch(getAppMetaData({ appID, assistant_version }));
            store.dispatch(
              getAppMetadataTrainStatus({ appID, assistant_version })
            );
            const payload = {
              error: error || "There was a server error while publishing.",
              isPublished: false,
            };

            helper.prepareAction(action, payload, store);
          });
      } else {
        const payload = {
          error: api_key
            ? "There was an error saving the schema"
            : "Missing API key",
          isSaved: false,
          isPublished: false,
        };
        logEvent(SlangSeverityLevels.ERROR, SlangEvents.APP_SAVE_FAILURE, {
          env,
          error: payload.error,
          app_data_upload_id: appDataUploadID,
        });
        return helper.prepareAction(action, payload, store);
      }
      break;
    default:
      break;
  }
};

export default appDataUploadActions;
