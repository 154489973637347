import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  if (value === index)
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        aria-labelledby={`simple-tab-${index}`}
        style={{ padding: " 1% 3% 1% 3%" }}
        {...other}
      >
        {children}
      </div>
    );
  else return null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
