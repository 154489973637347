import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import NestedListItem from "./NestedListItem";

const styles = makeStyles(theme => ({
  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  caption: {
    ...theme.typography.caption,
    marginBottom: 16,
    fontSize: 14,
  },
}));

const OutputWrapper = props => {
  const { intent, entities, inferTime } = props;

  const renderCompositeValues = (entity, index, level) => {
    return (
      <NestedListItem entityName={entity.name} level={level} key={index}>
        {Object.keys(entity.compositeValues).map((compVal, compIdx) =>
          renderEntity(
            entity.compositeValues[compVal],
            `comp_${index}_${compIdx}`,
            level + 1
          )
        )}
      </NestedListItem>
    );
  };

  const renderEntity = (entity, index, level = 0, renderList = false) => {
    if (!entity.isComposite && !entity.isList) {
      if (renderList) {
        return (
          <ListItem key={index}>
            <ListItemText>{`${entity.value}`}</ListItemText>
          </ListItem>
        );
      }
      return (
        <ListItem key={index}>
          <ListItemText>{`${entity.name}: ${entity.value}`}</ListItemText>
        </ListItem>
      );
    }
    if (entity.isComposite) {
      return renderCompositeValues(entity, index, level + 1);
    }
    if (entity.isList) {
      return (
        <NestedListItem entityName={entity.name} level={level + 1} key={index}>
          {entity.listValues.map((e, listIdx) => {
            return renderEntity(e, `list_${index}_${listIdx}`, level + 1, true);
          })}
        </NestedListItem>
      );
    }
  };

  const classes = styles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="button" color="inherit">
        Output
      </Typography>
      <br />
      {inferTime ? (
        <Typography variant="caption" color="inherit">
          Time Taken: &nbsp; {` ${inferTime}s`}
        </Typography>
      ) : null}
      <br />
      {intent && (
        <div>
          <span className={classes.caption}>{"Recognized Intent: "}</span>
          <Typography variant="body1">{intent}</Typography>
        </div>
      )}
      {entities.length > 0 && (
        <div>
          <span className={classes.caption}>Entities:</span>
          <List component="span" dense disablePadding>
            {entities.map((entity, index) => {
              if (entity.isResolved) return renderEntity(entity, index);

              return (
                <ListItem key={index}>
                  <ListItemText>
                    {entity.name}:
                    <em className={classes.caption}> unrecognised </em>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </div>
      )}
    </Paper>
  );
};

OutputWrapper.propTypes = {
  intent: PropTypes.string.isRequired,
  entities: PropTypes.array.isRequired,
};

export default OutputWrapper;
