import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Typography,
  Tooltip,
  Icon,
  Paper,
  Grid,
  Button,
  Chip,
} from "@material-ui/core";
import { AddCircleOutline, Check, InfoOutlined } from "@material-ui/icons";
import { SubDomainIcons } from "../../../../utils/createAsstUtils";
import { createUserJourneyAddOnObject } from "../../../../utils/MiscUtils";
const styles = theme => ({
  root: {},
  formgroup: {
    justifyContent: "center",
    textAlign: "left",
    marginTop: "5px",
  },
  formgroup2: {
    flexDirection: "column",
    justifyContent: "start",
    marginLeft: "2rem",
  },
  section: {
    padding: "10px 0",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
  iconDocs: {
    color: theme.palette.action.active,
    fontSize: "1rem",
    verticalAlign: "middle",
    margin: "0 4px",
  },
  domains: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(),
    maxHeight: 380,
    overflowY: "auto",
    overflowX: "hidden",
  },
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    textAlign: "left",
    background: theme.palette.background.default,
    overflowY: "scroll",
    maxHeight: 350,
  },
  subdomains: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  subdomainName: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  addons: {
    flexDirection: "column",
    minWidth: 160,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),

    textAlign: "left",
  },
  chip1: {
    margin: theme.spacing(1),
    width: "max-content",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const addOns = createUserJourneyAddOnObject();

function ChooseSubDomainUserJourney({
  classes,
  SubDomains,
  UserJourneys,
  handleChangeUJ,
  isLoading,
  selectedAssistant,
}) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography
            variant="h6"
            className={`${classes.chip} ${classes.capitalize}`}
            noWrap
          >
            {selectedAssistant.display_name}
            {/* <DocHelpLink docFor="ASR_HINTS" /> */}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <div className={classes.container}>
            {Object.keys(SubDomains)
              .filter(
                item =>
                  SubDomains[item].enabled &&
                  SubDomains[item].displayName.trim() !== "Common Datasets"
              )
              .map((item, key) => (
                <Button
                  key={item + key}
                  disableRipple
                  disableFocusRipple
                  variant="outlined"
                  color="default"
                  size="small"
                  className={classes.chip1}
                  disabled={isLoading}
                >
                  {SubDomainIcons[item]} &nbsp;&nbsp;{" "}
                  {SubDomains[item].displayName}
                </Button>
              ))}
          </div>
        </Grid>
      </Grid>
      <br />
      <Paper elevation={0} className={classes.promptPaper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.container}>
              <Typography
                variant="button"
                className={`${classes.chip}`}
                noWrap
                align="left"
              >
                {"User Journeys Available"}
              </Typography>
            </div>
            <Grid
              container
              spacing={1}
              justify="center"
              style={{ margin: "0 auto" }}
            >
              {Object.keys(UserJourneys).map((UJ, key) => (
                <Grid item xs={4} key={UJ + key}>
                  <div className={classes.addons}>
                    <Tooltip
                      key={UserJourneys[UJ].displayName}
                      title={UserJourneys[UJ].description}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          className={classes.capitalize}
                          style={{ marginRight: 0 }}
                          control={
                            <Checkbox
                              checked={UserJourneys[UJ].enabled}
                              onChange={e => handleChangeUJ(UJ, e)}
                              value={UJ}
                              color="primary"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                              disabled={isLoading}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              style={{
                                lineHeight: "1.1",
                              }}
                            >
                              {UserJourneys[UJ].displayName.replace(/_/g, " ")}{" "}
                            </Typography>
                          }
                        />

                        <Icon className={classes.iconDocs}>info</Icon>
                      </div>
                    </Tooltip>
                    <br />
                    {addOns[selectedAssistant.name] &&
                      addOns[selectedAssistant.name][UJ] && (
                        <FormGroup className={classes.formgroup2}>
                          <Typography variant="caption">Includes</Typography>
                          {addOns[selectedAssistant.name][UJ].map(
                            (addon, key) => (
                              <Chip
                                key={addon.displayName + key}
                                label={`${addon.displayName}`}
                                color={
                                  !isLoading && UserJourneys[UJ].enabled
                                    ? "primary"
                                    : "secondary"
                                }
                                className={classes.chip1}
                                size="small"
                                variant={
                                  UserJourneys[UJ].enabled
                                    ? "default"
                                    : "outlined"
                                }
                                icon={
                                  UserJourneys[UJ].enabled ? (
                                    <Check />
                                  ) : (
                                    <AddCircleOutline />
                                  )
                                }
                              />
                            )
                          )}
                        </FormGroup>
                      )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <br />
      </Paper>
    </div>
  );
}

ChooseSubDomainUserJourney.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChooseSubDomainUserJourney);
