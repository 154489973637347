import { createTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const DarkMode = localStorage.getItem("U_DARKMODE")
  ? localStorage.getItem("U_DARKMODE") === "true"
  : false;

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: DarkMode
          ? "rgba(20, 20, 20, 0.9)"
          : "rgba(250, 250, 250, 0.9)",
      },
    },
    MuiButton: {
      // TODO: Change button background on hover for all types of buttons.
      // Currently the buttons on hover show up as disabled, the change would
      // improve the UX by adding a active background rather than a disabled one.
      // Also there would be changes to standardise each button in the UI.
      // outlined: {
      //   "&:hover": {
      //     backgroundColor: "rgba(16,131,104,0.2)"
      //   }
      // }
      root: {
        "&:hover": {
          // backgroundColor: '#108368',
          color: DarkMode ? "white" : "black",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#ffffff",
      medium: "#139a7a",
      main: "#139a7a",
      dark: "#323c4",
    },
    secondary: {
      light: "#f9f9f9",
      medium: "#f0851a",
      main: "#e7e7e7",
      dark: grey[900],
    },
    ternary: {
      light: grey[100],
      medium: grey[200],
      main: grey[400],
      dark: grey[900],
    },
    fourth: {
      light: "#a6a6a6",
      medium: "#4860f6",
      main: "#EFEFFE",
      dark: "#000000",
    },
    misc: {
      barLight: "rgba(19, 154, 122,0.2)",
      error: "red",
    },

    type: DarkMode ? "dark" : "light",
  },
  // overrides: {
  //   MuiTypography: { // Name of the component ⚛️ / style sheet
  //     title: { // Name of the rule
  //       fontFamily: "'Work Sans', 'Helvetica', 'Arial', sans-serif",
  //     },
  //   },
  // },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: "'Work Sans', 'Helvetica', 'Arial', sans-serif",
    // fontFamily: [
    //   '"Work Sans"',
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   // 'Lato',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    h6: {
      fontFamily: "'Work Sans', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    },
    h5: {
      fontFamily: "'Work Sans', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    // caption: {
    //   fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
    //   fontWeight: 600,
    //   fontSize: "1.1rem",
    // },
    // button: {
    //   fontFamily: "'Work Sans', 'Helvetica', 'Arial', sans-serif"
    // },
    body2: {
      fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.0rem",
      fontWeight: 500,
      fontStyle: "none",
      lineHeight: "1.71429em",
    },
    body1: {
      fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.1rem",
      fontWeight: 500,
      fontStyle: "none",
      lineHeight: "1.83em",
    },
  },
});

export default theme;
