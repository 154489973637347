import { Fragment } from "react";
import PropTypes from "prop-types";
import Notifications from "./Notifications";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const BottomBar = props => {
  const { classes, notify } = props;

  return (
    <Fragment>
      <Paper elevation={1} className={classes.bottomBar}>
        <Typography style={{ fontSize: "0.8rem" }}>
          <a
            href="http://bit.ly/slangslack"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" Contact us on Slack "}
          </a>
          {" or "}
          <a
            href="mailto:42@slanglabs.in?subject=[Engineering Help]"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" 42@slanglabs.in "}
          </a>
          |
          <a
            href="https://docs.slanglabs.in/slang/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" Documentation "}
          </a>
          |
          <a
            href="https://slanglabs.in/terms-of-service"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" TOS "}
          </a>
          {" and "}
          <a
            href="https://slanglabs.in/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            {"privacy policy"}
          </a>
        </Typography>
      </Paper>
      <Notifications notify={notify} />
    </Fragment>
  );
};

BottomBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default BottomBar;
