import { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Icon, Tab, Tabs } from "@material-ui/core";

import BarStackedGraph from "../../analytics/components/BarStackedGraph";
import { createChartData } from "../../analytics/components/GraphHelpers";

function UsageDetailsDialog(props) {
  const { BQData } = props;
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [chartDataMonthlyUsage, setchartDataMonthlyUsage] = useState({});
  const [chartDataAssistantUsage, setchartDataAssistantUsage] = useState({});

  useEffect(() => {
    const { monthlyUsageChartData, assistantChartData } =
      createChartData(BQData);
    setchartDataMonthlyUsage(monthlyUsageChartData);
    setchartDataAssistantUsage(assistantChartData);

    return () => {};
  }, [BQData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (event, tabValue) => {
    setTab(tabValue);
  };
  return (
    <>
      <Button
        variant="outlined"
        color="initial"
        style={{ width: 150 }}
        onClick={handleClickOpen}
      >
        Usage Details
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Usage Details"}
          <IconButton
            onClick={handleClose}
            color="initial"
            style={{ float: "right" }}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" justify="center">
            <Grid xs={12} item>
              {" "}
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Monthly Usage" />
                <Tab label="Usage per Assistant" />
              </Tabs>
            </Grid>
            {tab === 0 && (
              <Grid xs={12} item>
                <br />
                <BarStackedGraph data={chartDataMonthlyUsage} isStacked />
              </Grid>
            )}
            {tab === 1 && (
              <Grid xs={12} item>
                <br />
                <BarStackedGraph data={chartDataAssistantUsage} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

UsageDetailsDialog.propTypes = {};

export default UsageDetailsDialog;
