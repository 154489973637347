const AppConfig = {};
let tierOverride = "-dev";

AppConfig.REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN_LOCAL;
AppConfig.REACT_APP_AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID_LOCAL;
AppConfig.REACT_APP_AUDIENCE = process.env.REACT_APP_AUDIENCE_LOCAL;

AppConfig.SLANG_ISPROD = "FALSE";
AppConfig.SLANG_ADMIN_USER = "91ba9ac6fb2da9bd89f55cf0227ce9be";
AppConfig.SLANG_ADMIN_PASSWORD = "73c1f9dfc2be4f50a291a3f8b7da1dbc";
AppConfig.maxTransactionsPerMonth = 10000;
AppConfig.SLANG_TIER = "DEV";
AppConfig.SLANG_HOST = process.env.REACT_APP_CG_HOST || "http://localhost:5000";
// Analytics playback host for live view
AppConfig.SLANG_ANALYTICS_HOST = "https://analytics-dev.slanglabs.in";
AppConfig.HUBSPOT_CDN =
  "https://f.hubspotusercontent00.net/hubfs/9064954/Console_Marketing/stage/";
if (process.env.REACT_APP_STAGE === "prod") {
  AppConfig.SLANG_HOST = "https://console-v2.slanglabs.in";
  AppConfig.SLANG_ISPROD = "TRUE";
  AppConfig.SLANG_TIER = "PROD";
  tierOverride = "";
  AppConfig.REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN_PROD;
  AppConfig.REACT_APP_AUTH0_CLIENT_ID =
    process.env.REACT_APP_AUTH0_CLIENT_ID_PROD;
  AppConfig.REACT_APP_AUDIENCE = process.env.REACT_APP_AUDIENCE_PROD;
  AppConfig.HUBSPOT_CDN =
    "https://f.hubspotusercontent00.net/hubfs/9064954/Console_Marketing/prod/";
} else if (process.env.REACT_APP_STAGE === "dev") {
  AppConfig.SLANG_HOST = "https://console-v2-dev.slanglabs.in";
} else if (process.env.REACT_APP_STAGE === "stage") {
  AppConfig.SLANG_HOST =
    process.env.REACT_APP_CG_HOST || "https://console-v2-stage.slanglabs.in";
  AppConfig.SLANG_ANALYTICS_HOST = "https://analytics-stage.slanglabs.in";
  AppConfig.SLANG_TIER = "STAGE";
  tierOverride = "-stage";
  AppConfig.REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN_STAGE;
  AppConfig.REACT_APP_AUTH0_CLIENT_ID =
    process.env.REACT_APP_AUTH0_CLIENT_ID_STAGE;
  AppConfig.REACT_APP_AUDIENCE = process.env.REACT_APP_AUDIENCE_STAGE;
} else if (process.env.REACT_APP_STAGE === "test") {
  AppConfig.SLANG_ANALYTICS_HOST = "http://ganesha:8082";
  AppConfig.SLANG_TIER = "Local";
}

// Local Tier config to override <service_name> + <tier> pattern.
if (process.env.REACT_APP_STAGE === "test") {
  AppConfig.configOverrides = {
    SLANG_INFER_SERVER_HOST: "localhost:8083",
    SLANG_ASR_SERVER_HOST: "localhost:9090",
    SLANG_TTS_SERVER_HOST: "localhost:8089",
    SLANG_ANALYTICS_SERVER_HOST: "localhost:8082",
    SLANG_SERVER_PROTOCOL: "http",
  };
} else {
  AppConfig.configOverrides = {
    SLANG_INFER_SERVER_HOST: "infer" + tierOverride,
    SLANG_ASR_SERVER_HOST: "speech" + tierOverride,
    SLANG_TTS_SERVER_HOST: "tts" + tierOverride,
    SLANG_ANALYTICS_SERVER_HOST: "analytics" + tierOverride,
  };
}
AppConfig.demoAppName = "travel.sample";
AppConfig.demoAppTemplate = ["std.template.travel"];
AppConfig.defaultPlan = {
  plan_code: "TRIAL",
  plan_name: "Trial Plan",
  max_session: 0,
  currency: "INR",
  fixed_cost: 0,
  slabs: [
    {
      from: 1,
      cost: 0,
    },
  ],
  org_id: "0",
};

AppConfig.accountSessionDate = () => {
  const today = new Date();
  let cur_month = today.getMonth() + 1; // normalizing 0 index start.
  let cur_date = today.getDate();
  cur_month = cur_month < 10 ? "0" + cur_month : cur_month;
  cur_date = cur_date < 10 ? "0" + cur_date : cur_date;
  const end_date = today.getFullYear() + "-" + cur_month + "-" + cur_date;
  const start_date = today.getFullYear() + "-" + cur_month + "-01"; // Current month's start date.
  return { start_date, end_date };
};

AppConfig.LANGUAGES = [
  { code: "en-IN", text: "English (IN)", locale: "En", script: "" },
  { code: "hi-IN", text: "Hindi", locale: "हिंदी", script: "" },
  { code: "kn-IN", text: "Kannada", locale: "ಕನ್ನಡ", script: "" },
  { code: "ta-IN", text: "Tamil", locale: "தமிழ்", script: "" },
  { code: "ml-IN", text: "Malayalam", locale: "മലയാളം", script: "" },
  { code: "vi-VN", text: "Vietnamese", locale: "Tiếng Việt", script: "" },
  { code: "es-US", text: "Spanish (US)", locale: "español", script: "" },
  { code: "en-US", text: "English (US)", locale: "En (US)", script: "" },
];

AppConfig.CHART_TYPES = [
  { chart: "Bar", type: "category" },
  { chart: "HorizontalBar", type: "category" },
  { chart: "WordCloud", type: "wordList" },
];

AppConfig.AGGREGATIONS = ["SUM", "PERCENTAGE"];
AppConfig.PAGESIZE = 30000;

export default AppConfig;
