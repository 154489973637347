import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { styles } from "../styles.js";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "../../withTheme";
import LinearProgress from "@material-ui/core/LinearProgress";

import Layout from "../../common/layouts/defaultLayout";
import { GetSetAppMetadata } from "../../../utils/auth/handleAuthState";
import { createOrganisation } from "../../../libs/auth/slang_auth";
import { getAPI, createAPI } from "../../../redux/actions/apikey";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs";

import { createUser } from "../../../redux/actions/user";
import WelcomeDialog from "./WelcomeDialog";

const themeType = "light";

const mapDispatchToProps = dispatch => ({
  createAPI: () => dispatch(createAPI()),
  createUser: payload => dispatch(createUser(payload)),
  getAPI: () => {
    dispatch(getAPI());
  },
});

export class AssistantsConfigHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openWelcome: false,
      hasOrgID: false,
      orgName: "",
      isLoading: false,
      isCreatingOrDeleting: false,
      notify: {
        variant: "info",
        message: "",
      },
    };
  }

  handleDailogOpen = () => {
    this.setState({
      openWelcome: true,
    });
  };

  handleDialogClose = name => {
    createOrganisation({ name })
      .then(data => {
        const organization_id = data.organization_id;
        localStorage.setItem("U_orgID", organization_id);
        localStorage.setItem("U_orgName", name);
        logEvent(SlangSeverityLevels.INFO, SlangEvents.ORG_CREATED, {
          company: name,
        });
        this.setState({ hasOrgID: true });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          notify: {
            variant: "error",
            message:
              "Hello, there was an error creating an organisation. Please retry in sometime!",
            autoHide: 0,
          },
          openWelcome: false,
        });
      });
  };

  componentDidMount() {
    const user_app_metadata = GetSetAppMetadata();
    const organization_id = user_app_metadata.organization_id;
    if (!organization_id) {
      this.handleDailogOpen();
    } else {
      this.setState({
        hasOrgID: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      if (this.state.openWelcome && this.state.hasOrgID) {
        this.props.createUser({ email_id: this.props.emailid });
        this.props.createAPI();
        setTimeout(() => {
          this.setState({ openWelcome: false });
          window.location = "/";
        }, 3000);
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { notify, openWelcome, isLoading, isCreatingOrDeleting, orgName } =
      this.state;

    const { handleDialogClose, handleChangeOrgName } = this;

    const welcomeDialogProps = {
      classes,
      handleDialogClose,
      handleChangeOrgName,
    };
    const welcomeDialogState = {
      openWelcome,
      orgName,
    };

    const Image = "astronaut";
    const backgroundImage = `url("/images/draw_${Image}_${themeType}.png")`;

    return (
      <Layout notify={notify} backgroundImage={backgroundImage}>
        <div>
          <div style={{ height: 8, width: "100%" }}>
            {(isLoading || isCreatingOrDeleting) && <LinearProgress />}
          </div>
          <br />
          <WelcomeDialog {...welcomeDialogProps} state={welcomeDialogState} />
        </div>
      </Layout>
    );
  }
}

AssistantsConfigHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    apikey: state.apikey.api_key,
    emailid: state.login.username,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(AssistantsConfigHome)));
