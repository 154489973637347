import { rootReducer, initialState } from "./rootReducer";
import loginActions from "./thunk/login";
import userActions from "./thunk/user";
import apiKeyActions from "./thunk/apikey";
import appSchemaActions from "./thunk/appSchema";
import appDataUpload from "./thunk/appDataUpload";
import asstDomain from "./thunk/asstDomain";
import asstTemplates from "./thunk/asstTemplates";
import asstConfig from "./thunk/asstConfig";

import { createStore, applyMiddleware, compose } from "redux";

// Below code for debugging Redux middleware
// const loggingMiddleware = (store) => (next) => (action) => {
//   // Our middleware
//   console.log(`Redux Log:`, action)
//   // call the next function
//   next(action);
// }

export const configureStore = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer, // root reducer
    initialState, // our initialState
    composeEnhancers(
      applyMiddleware(
        // loggingMiddleware,
        loginActions,
        userActions,
        apiKeyActions,
        appSchemaActions,
        appDataUpload,
        asstDomain,
        asstTemplates,
        asstConfig
      )
    )
  );

  return store;
};

export default configureStore;
