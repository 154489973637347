import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import LangButton from "../LangButton";
import ExpansionStatements from "./ExpansionStatements";

const styles = makeStyles(theme => ({
  separate: {
    marginTop: theme.spacing(3),
    marginRight: 25,
  },
}));

const PromptTextField = props => {
  const {
    lang,
    setLang,
    promptName,
    promptsExample,
    prompts,
    HELPTEXT,
    onChangeText,
    handleSaveAllPrompts,
    handleDelete,
    severityLevels,
    isTerminal,
    // handleAddLevel,
    // handleDeleteLevel,
  } = props;

  const classes = styles();

  // Preparing Payload
  const langButtonProps = {
    lang,
    setLang,
  };

  const expansionStatementsProps = {
    lang,
    promptName,
    promptsExample,
    prompts,
    HELPTEXT,
    onChangeText,
    handleSaveAllPrompts,
    handleDelete,
    severityLevels,
    isTerminal,
    // handleAddLevel,
    // handleDeleteLevel,
  };

  return (
    <form>
      <div className={classes.separate}>
        <LangButton {...langButtonProps} />
        <br />
        <br />
        <ExpansionStatements {...expansionStatementsProps} />
      </div>
    </form>
  );
};

PromptTextField.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
  promptName: PropTypes.string.isRequired,
  promptsExample: PropTypes.object.isRequired,
  prompts: PropTypes.object.isRequired,
  HELPTEXT: PropTypes.object.isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleSaveAllPrompts: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default PromptTextField;
