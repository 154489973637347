import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import Checkbox from "@material-ui/core/Checkbox";
import EditAppStatesDialog from "./EditAppStatesDialog";
import EditUserJourneyDialog from "./EditUserJourneyDialog";
import EditField from "./EditField";
import { Paper, Grid } from "@material-ui/core";

const styles = makeStyles(theme => ({
  iconButton: {
    marginRight: 0,
  },
  marginText: {
    marginRight: 25,
    display: "inline",
  },
  listItemText: {
    flex: " 0 1 auto",
    margin: "4px  20px 4px 0px",
  },
  paper: {
    textAlign: "left",
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

function UserJourneyList(props) {
  const {
    handleDeleteUserData,
    changeUserJourneyData,
    handleToggle,
    userJourneysState,
    subDomains,
    changeSubDomainData,
  } = props;

  const classes = styles();

  return userJourneysState.map((UJ, userJourneyID) => (
    <Paper key={UJ.name + userJourneyID} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid xs={1} item>
          <Checkbox
            color="primary"
            checked={UJ.enabled}
            onClick={handleToggle(UJ.name)}
            disableRipple
          />
        </Grid>
        <Grid xs={3} item>
          <EditField
            fieldName="displayName"
            currentValue={UJ.displayName}
            userJourneyID={userJourneyID}
            {...props}
          />
        </Grid>
        <Grid xs={3} item>
          <EditField
            fieldName="name"
            currentValue={UJ.name}
            userJourneyID={userJourneyID}
            {...props}
          />
        </Grid>
        <Grid xs={2} item></Grid>
        <Grid xs={1} item>
          <EditUserJourneyDialog
            disabled={!UJ.enabled}
            userJourneyName={UJ.name}
            userJourneyID={userJourneyID}
            userJourney={UJ}
            changeUserJourneyData={changeUserJourneyData}
          />
        </Grid>
        <Grid xs={1} item>
          {UJ.appStatesMetadata.length && (
            <EditAppStatesDialog
              aria-label="View"
              appStatesMetadata={UJ.appStatesMetadata}
              userJourneyName={UJ.name}
              userJourneyID={userJourneyID}
              userJourney={UJ}
              changeUserJourneyData={changeUserJourneyData}
              subDomains={subDomains}
              changeSubDomainData={changeSubDomainData}
            />
          )}
        </Grid>
        <Grid xs={1} item>
          <IconButton
            aria-label="Delete"
            title={`Delete ${UJ.displayName}`}
            onClick={handleDeleteUserData({ userJourneyID })}
            className={classes.iconButton}
          >
            <RemoveIcon />
          </IconButton>{" "}
        </Grid>
        <Grid sm={1} item></Grid>
        <Grid xs={9} item>
          <EditField
            fieldName="description"
            currentValue={UJ.description}
            userJourneyID={userJourneyID}
            {...props}
          />
        </Grid>
        <Grid sm={2} item></Grid>
      </Grid>
    </Paper>
  ));
}

UserJourneyList.propTypes = {
  userJourneysState: PropTypes.array.isRequired,
  handleDeleteUserData: PropTypes.func.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default UserJourneyList;
