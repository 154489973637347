import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import People from "@material-ui/icons/People";
import ArrowForward from "@material-ui/icons/ArrowForward";

const tutorialSteps = [
  {
    label: "Welcome to Slang CONVA",
    text: "Let’s start by creating your Organization",
    img: "/images/intro_1.png",
  },
  {
    label: "Welcome to Slang",
    text: "Its awesome to have you on board. Here you will be able to create and configure your Slang apps. \n Our integration docs can be viewed on the below link.",
    img: "/images/intro_2.png",
  },
  {
    label: "Welcome to Slang",
    text: "Its awesome to have you on board. Here you will be able to create and configure your Slang apps. \n Our integration docs can be viewed on the below link.",
    img: "/images/intro_4.png",
  },
];

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    marginBottom: 20,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    // height: 450,
    maxHeight: 500,
    overflow: "hidden",
    width: "100%",
  },
  text: {
    position: "absolute",
    // top: 200,
    // left: 150,
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(4),
    color: "white",
    marginBottom: 20,
    overflow: "hidden",
    width: "100%",
  },
  inputDiv: {
    position: "absolute",
    top: 130,
    left: 170,
  },
  textInfo: {
    color: "white",
    fontWeight: 800,
    marginBottom: 10,
  },
  textInput: {
    color: "white",
    "& label": {
      color: "white",
    },
  },
  textInputIcon: {
    height: "1.2em",
    color: "white",
  },
});

class WelcomePop extends Component {
  state = {
    activeStep: 0,
    orgName: "",
    loading: false,
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleChangeOrgName = event => {
    const name = event.target.name;
    const text = event.target.value;
    if (text.length < 33)
      this.setState({
        [name]: text,
      });
  };

  handleSendOrgName = e => {
    e.preventDefault();
    const { orgName } = this.state;

    if (orgName?.length > 3) {
      this.props.handleDialogClose(orgName);
      this.setState({ loading: true });
    } else {
      this.setState({ error: "Should be more than 3 characters!" });
    }
  };

  render() {
    const { handleSendOrgName, handleChangeOrgName } = this;
    const { classes } = this.props;
    const { activeStep, orgName, loading, error } = this.state;

    // const maxSteps = tutorialSteps.length;

    return (
      <div className={classes.root}>
        {!activeStep ? (
          <div className={classes.inputDiv}>
            <Typography
              variant="body2"
              className={classes.textInfo}
              gutterBottom
            >
              {tutorialSteps[activeStep].text}
            </Typography>
            <form onSubmit={handleSendOrgName}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <People className={classes.textInputIcon} />
                </Grid>
                <Grid item>
                  <TextField
                    id="input-with-icon-textfield"
                    placeholder="Organization Name"
                    name="orgName"
                    value={orgName}
                    onChange={handleChangeOrgName}
                    className={classes.textInput}
                    InputProps={{
                      className: classes.textInput,
                    }}
                    disabled={loading}
                    helperText={error}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    className={classes.textInputIcon}
                    aria-label="Add to shopping cart"
                    onClick={handleSendOrgName}
                  >
                    {loading ? (
                      <CircularProgress color={"secondary"} size={20} />
                    ) : (
                      <ArrowForward className={classes.textInputIcon} />
                    )}
                  </IconButton>{" "}
                </Grid>
              </Grid>
            </form>
          </div>
        ) : null}
        <img
          className={classes.img}
          src={tutorialSteps[activeStep].img}
          alt={tutorialSteps[activeStep].label}
        />
        {/* <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        /> */}
      </div>
    );
  }
}

WelcomePop.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WelcomePop);
