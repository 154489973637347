import { useAuth0 } from "@auth0/auth0-react";
import SlangLoader from "../common/slangLoader";

const LoginRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  setTimeout(() => {
    loginWithRedirect();
  }, 100);
  return <SlangLoader />;
};

export default LoginRedirect;
