import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Slang from "@slanglabs/slang-web-sdk";
import {
  Button,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";

import { CloseRounded } from "@material-ui/icons";

import DebuggerIcon from "./DebuggerIcon";
import MessageWrapper from "./MessageWrapper";
import InputWrapper from "./InputWrapper";
import OutputWrapper from "./OutputWrapper";
import AddASRHints from "./AddASRHints";
import AppConfig from "../../../../app.config";

const configOverrides = AppConfig.configOverrides;

const styles = makeStyles(theme => ({
  debuggerOpenButton: {
    position: "fixed",
    transform: "rotate(-90deg)",
    zIndex: 10000,
    right: "-37px",
    top: "34vh",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  clickableIcon: {
    cursor: "pointer",
  },
  slide: {
    width: 500,
    overflow: "auto",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.background.default,
  },
  spacing: {
    marginTop: theme.spacing(2),
  },
  topBar: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawer: {
    zIndex: 10000,
  },
}));

let _componentIsMounted;

const Debugger = props => {
  // Props
  const { apikey, assistantID, assistantVersion } = props;

  // State
  const [openDebugger, setOpenDebugger] = useState(false);
  const [isSlangInitialised, setSlangInitialised] = useState(-1);
  const [env, setEnv] = useState("stage");
  const [lang, setLang] = useState("en-IN");
  const [intent, setIntent] = useState("");
  const [entities, setEntities] = useState([]);
  const [inferTime, setInferTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [utterance, setUtterance] = useState("");
  const [sdkUiState, setSdkUiState] = useState(null);
  const [speechRecognitionHints, setSpeechRecognitionHints] = useState([]);
  const [speechRecognitionHintString, setSpeechRecognitionHintString] =
    useState("");

  // Initialising Slang
  useEffect(() => {
    _componentIsMounted = true;
    initialiseSlang(env, lang, assistantVersion);

    return () => {
      _componentIsMounted = false;
    };
  }, []);

  // Handlers
  const initialiseSlang = (env, lang, version) => {
    setSlangInitialised(0);
    Slang.setUiInitializer(() => {});
    Slang.registerUIObserver(sdkUiState => {
      if (_componentIsMounted) {
        if (sdkUiState.isListening || sdkUiState.isProcessing) {
          setUtterance(sdkUiState.userText.reduce((acc, cur) => acc + cur, ""));
        }
        setSdkUiState(sdkUiState);
      }
    });
    Slang.initialize({
      buddyId: assistantID,
      apiKey: apikey,
      env, // stage
      locale: lang, // en-IN
      assistantVersion: version, // 4.1.5
      configOverrides,

      onSuccess: () => {
        Slang.getBuiltInUI().getTrigger().hideTrigger();
        if (_componentIsMounted) {
          setSlangInitialised(1);
          setSpeechRecognitionHints([]);
        }
        Slang.setASRHints({});
        Slang.toggleMute();
        Slang.setOnUtteranceUnresolved(utterance => {
          Slang.cancel();
          if (_componentIsMounted) {
            setOpenDebugger(true);
            setUtterance(utterance);
            setIntent("Intent was not recognized");
            setEntities([]);
          }
        });
        if (_componentIsMounted) {
          Slang.setIntentActionHandler(intent => {
            setOpenDebugger(true);
            setIntent(intent.name);
            setUtterance(intent.userUtterance);
            setEntities(intent.entities);

            intent.completionStatement.overrideAffirmative(null);
            return true;
          });
        }
      },
      onFailure: () => {
        console.log("Failed");
        if (_componentIsMounted) {
          setSlangInitialised(-1);
          setSpeechRecognitionHints([]);
        }
        Slang.setASRHints({});
      },
    });
  };

  useEffect(() => {
    if (sdkUiState && sdkUiState.isProcessing) {
      console.log("Processing...");
      setStartTime(new Date().getTime());
    }
    if (startTime !== "" && !sdkUiState.isProcessing) {
      const endTime = new Date().getTime();
      setInferTime((endTime - startTime) / 1000);
      setStartTime("");
    }
  }, [sdkUiState && sdkUiState.isProcessing]);

  const handleChangeEnv = event => {
    setEnv(event.target.value);
    initialiseSlang(event.target.value, lang, assistantVersion);
  };

  const handleChangeLang = event => {
    setLang(event.target.value);
    initialiseSlang(env, event.target.value, assistantVersion);
  };

  const inferUtterance = utterance => {
    if (utterance) {
      Slang.startWithUtterance(utterance);
    }
  };

  const handleChangeSpeechRecognitionHintString = event => {
    setSpeechRecognitionHintString(event.target.value);
  };

  const addSpeechRecognitionHint = newHint => {
    if (!newHint) return;

    const hints = [...new Set([...speechRecognitionHints, newHint])];
    if (hints.length > 0) {
      Slang.setASRHints({
        [lang]: hints,
      });
    }
    setSpeechRecognitionHints(hints);
    setSpeechRecognitionHintString("");
  };

  const removeSpeechRecognitionHint = hintToRemove => {
    if (!hintToRemove) return;

    const hints = speechRecognitionHints.filter(hint => hint !== hintToRemove);
    if (hints.length > 0) {
      Slang.setASRHints({
        [lang]: hints,
      });
    }

    setSpeechRecognitionHints(hints);
  };

  const clearDebugger = () => {
    setIntent("");
    setEntities([]);
    setUtterance("");
  };

  const classes = styles();

  return (
    <>
      <Button
        onClick={() => setOpenDebugger(true)}
        variant="contained"
        color="secondary"
        className={`${classes.debuggerOpenButton}`}
      >
        <DebuggerIcon />
        &nbsp;
        {" Debugger "}
      </Button>
      <Drawer
        open={openDebugger}
        onClose={() => setOpenDebugger(false)}
        anchor={"right"}
        className={classes.drawer}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              onClick={() => setOpenDebugger(false)}
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
            >
              <CloseRounded className={classes.clickableIcon} />
            </IconButton>
            <Typography component="span" variant="h6" noWrap>
              {"Debugger "}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.slide}>
          <div>
            <MessageWrapper
              {...{
                initState: isSlangInitialised,
              }}
            />
          </div>
          <InputWrapper
            {...{
              env,
              handleChangeEnv,
              lang,
              handleChangeLang,
              sdkUiState,
              utterance,
              setUtterance,
              triggerSlang: Slang.triggerSlang,
              cancelSlang: Slang.cancel,
              inferUtterance,
            }}
          />
          <OutputWrapper
            {...{
              intent,
              entities,
              inferTime,
            }}
          />
          <AddASRHints
            {...{
              isSlangInitialised,
              speechRecognitionHints,
              speechRecognitionHintString,
              handleChangeSpeechRecognitionHintString,
              addSpeechRecognitionHint,
              removeSpeechRecognitionHint,
            }}
          />
        </div>

        <div className={classes.topBar}>
          <Button
            color="primary"
            onClick={clearDebugger}
            variant="outlined"
            disabled={!utterance && !intent && !(entities.length > 0)}
          >
            Clear Debugger
          </Button>
        </div>
      </Drawer>
    </>
  );
};

Debugger.propTypes = {
  apikey: PropTypes.string.isRequired,
  assistantID: PropTypes.string.isRequired,
  assistantVersion: PropTypes.string.isRequired,
};

export default Debugger;
