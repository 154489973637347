export const generateIntent = (intents, blackListedIntents, singletons) => {
  const utterances = [];
  const seen = new Set();

  for (let i = 0; i < intents.length; i++) {
    for (let j = 0; j < intents.length; j++) {
      const utterance = [];
      const parts_string_list = [];

      utterance.push({
        entity: intents[i],
        text: intents[i],
      });
      parts_string_list.push(intents[i]);

      utterance.push({
        entity: intents[j],
        text: intents[j],
      });
      parts_string_list.push(intents[j]);

      const parts_string = parts_string_list.join();
      let use = true;

      if (seen.has(parts_string)) {
        use = false;
      }
      seen.add(parts_string);

      for (let m = 0; m < blackListedIntents.length; m++) {
        if (parts_string.includes(blackListedIntents[m])) {
          use = false;
        }
      }
      use ? utterances.push(utterance) : null;
    }
  }

  for (let i = 0; i < singletons.length; i++) {
    const utterance = [
      {
        entity: singletons[i],
        text: singletons[i],
      },
    ];
    utterances.push(utterance);
  }

  return utterances;
};
