import React, { Component, ErrorInfo, ReactNode } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import withTheme from "./withTheme";
import { commonStyles } from "./appStyles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { logError } from "../libs/analytics/slangAnalyticsAPIs";
import { Styles } from "@material-ui/core/styles/withStyles";
import { mapStackTrace } from "sourcemapped-stacktrace";

interface Props {
  classes: any;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State;
  classes: any;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    mapStackTrace(info.componentStack, mappedStack => {
      logError(error.message, JSON.stringify(`${mappedStack.join("\n")}`));
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <main className={classes.fourOhFour}>
              <div className={classes.margin}>
                <Grid
                  justify="flex-start"
                  direction="row"
                  alignItems="flex-start"
                  container
                >
                  <Grid xs={12} item>
                    <Typography variant="h4">
                      &#60; Something went wrong &#47;&#62;
                    </Typography>
                    <br />
                    <br />
                    <img
                      src={"/images/404.png"}
                      alt="Bad 404 Robot"
                      width="400"
                    />
                    <br />
                    <br />
                    <br />
                    <Typography variant="button">
                      <a href="/"> Reload </a> or <a href="/logout">logout</a>{" "}
                      in case the error repeats
                    </Typography>

                    <br />
                    <br />
                    <a href="/"> Go Back Home </a>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Paper className={classes.paper} />
                </Grid>
              </div>
            </main>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTheme(withStyles(commonStyles)(ErrorBoundary));
