import AppConfig from "../../app.config";
import SlangApiKeyActions from "../../libs/slang/slang_apikey_actions";
import * as types from "../types";
import { createAPI } from "../actions/apikey";
import { isUserLoggedIn } from "../actions/login";
import * as helper from "./helper";

export const apiKeyActions = store => next => action => {
  if (!action.meta || action.meta.type !== "APIACTION") {
    return next(action);
  }
  store.dispatch(isUserLoggedIn({}));
  const { isAuth } = store.getState().login;
  const orgID = localStorage.getItem("U_orgID");
  if (!orgID || !isAuth) {
    const payload = {
      api_key: "",
      error: "User ID not found",
      isAPI: false,
    };
    helper.prepareActionLocal(action, payload, store);
    return;
  }
  const reportError = error => {
    console.log(error.toString());
    const payload = {
      error: error.toString(),
      api_key: null,
      api_secret: null,
      isAPI: false,
    };
    helper.prepareActionLocal(action, payload, store);
  };

  const apiKeySuccess = json => {
    const setItem = [
      {
        key: "S_apiKey",
        value: json.raw_json_response.api_key,
      },
      {
        key: "S_apiSecret",
        value: json.raw_json_response.api_secret,
      },
    ];
    const payload = {
      api_key: json.raw_json_response.api_key,
      api_secret: json.raw_json_response.api_secret,
      error: null,
      isAPI: true,
    };
    helper.prepareActionLocal(action, payload, store, setItem);
  };

  switch (action.type) {
    case types.GET_API:
      SlangApiKeyActions.getSlangApiKey(AppConfig.SLANG_HOST, orgID)
        .then(json => {
          if (!json.success) {
            throw Error(json.message);
          } else {
            return apiKeySuccess(json);
          }
        })
        .catch(err => {
          return reportError(err);
        });
      break;
    case types.CREATE_API:
      SlangApiKeyActions.createSlangApiKey(AppConfig.SLANG_HOST, orgID)
        .then(json => {
          if (!json.success) {
            throw Error(json.message);
          } else {
            return apiKeySuccess(json);
          }
        })
        .catch(err => {
          return reportError(err);
        });

      break;

    case types.RESET_API:
      SlangApiKeyActions.deleteSlangApiKey(AppConfig.SLANG_HOST, orgID)
        .then(json => {
          if (!json.success) {
            throw Error(json.message);
          } else {
            store.dispatch(createAPI());
          }
        })
        .catch(err => {
          return reportError(err);
        });

      break;

    default:
      break;
  }
};

export default apiKeyActions;
