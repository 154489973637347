import theme from "../theme";
import { commonStyles } from "../appStyles";

const cardHeight = 225;
const cardWidth = 240;

const styles = () => ({
  ...commonStyles,
  drawn: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundPosition: "bottom 7% right 2%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "30%",
    transitionProperty: "background",
    transitionDuration: "1.2s",
    transitionTimingFunction: "ease-in",
    "-webkit-transition-property": "background",
    "-moz-transition-property": "background",
    "-o-transition-property": "background",
    "-webkit-transition-duration": "1.2s",
    "-moz-transition-duration": "1.2s",
    "-o-transition-duration": "1.2s",
    "-webkit-transition-timing-function": "ease-in",
    "-moz-transition-timing-function": "ease-in",
    "-o-transition-timing-function": "ease-in",
  },
  dialog: {
    paddingTop: theme.spacing(20),
  },
  cardNew: {
    maxWidth: cardWidth,
    height: cardHeight,
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  cardDisabled: {
    maxWidth: cardWidth,
    height: cardHeight,
    opacity: "0.2",
    cursor: "not-allowed",
    backgroundColor: "transparent",
  },
  card: {
    maxWidth: cardWidth,
    height: cardHeight,
    backgroundColor: "transparent",
  },
  cardContent: {
    padding: "0 !important",
    color: theme.palette.text.primary,
  },
  media: {
    height: 150,
    width: "100%",
  },
  mediaBelongsToDomain: {
    height: 150,
    width: "100%",
    borderRadius: "50%",
    border: `2px solid ${theme.palette.secondary.medium}`,
  },
  mediaDisabled: {
    height: 150,
    width: "100%",
    cursor: "not-allowed",
  },
  buttonEdit: {
    width: 28,
    height: 28,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  buttonClone: {
    width: 28,
    height: 28,
    marginRight: 5,
    "& svg": {
      width: 20,
      height: 20,
    },
  },
  buttonDelete: {
    width: 28,
    height: 28,
    marginRight: 5,
    color: theme.palette.misc.error,
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    minWidth: 400,
    padding: 40,
  },
  logo: {
    height: "auto",
    width: 90,
    margin: 30,
  },
  createButton: {
    width: "100%",
    backgroundColor: theme.palette.primary.light,
  },
  cancelButton: {
    width: "100%",
    height: 50,
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  createInput: {
    width: "300px",
  },
  errorColor: {
    color: theme.palette.primary.light,
  },
  firstLoad: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.medium,
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    height: 50,
    width: "100%",
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  disabled: {},
  parentCard: {
    "& .hidden": {
      visibility: "hidden",
      opacity: 0,
      transition: "visibility 0s, opacity 0.3s linear",
      "-webkit-transition": "visibility 0s, opacity 0.3s linear",
      "-moz-transition": "visibility 0s, opacity 0.3s linear",
    },
    "&:hover .hidden": {
      visibility: "visible",
      opacity: 1,
    },
    "& .hide": {
      display: "none",
    },
    ".chip": {
      display: "block",
      opacity: 1,
    },
    "&:hover .chip": {
      display: "none",
      opacity: 0,
      transition: "display 0s, opacity 0.3s linear",
      "-webkit-transition": "visibility 0s, opacity 0.3s linear",
      "-moz-transition": "visibility 0s, opacity 0.3s linear",
    },
  },
  buttonSpan: {
    display: "inherit",
    height: 20,
    width: "auto",
  },
});

export { styles };
