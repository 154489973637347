import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/core/styles";
import { main } from "../styles.js";

import Typography from "@material-ui/core/Typography";
import * as SlangTooltips from "../../common/SlangTooltips";

import {
  writeAppPromptState,
  emptyLangAppState,
  readPromptState,
  editPromptState,
  deleteAppPromptState,
} from "../../../utils/promptUtils";

import Notifications from "../../common/Notifications";
import ExpansionPrompts from "./ExpansionPrompts";
import ExpansionStatements from "./ExpansionStatements";

import {
  getAppDATA,
  changeAppDATA,
  changeAppStringTable,
} from "../../../redux/actions/appSchema";
import LangButton from "./LangButton";
import DocHelpLink from "../../common/DocLinks";
import { Tabs, Tab } from "@material-ui/core";

const mapDispatchToProps = dispatch => ({
  getAppDATA: appid => dispatch(getAppDATA(appid)),
  changeAppDATA: appDATA => dispatch(changeAppDATA(appDATA)),
  changeAppStringTable: appStrings =>
    dispatch(changeAppStringTable(appStrings)),
});

const tabsList = [
  {
    name: "Greeting",
    value: "greetingStatement",
  },
  {
    name: "Clarification",
    value: "clarificationPrompt",
  },
];

function PromptTextField(props) {
  const {
    classes,
    lang,
    handleOnChange,
    handleSaveAllPrompts,
    handleDeletePrompt,
    promptsExample,
    state,
    langButtonProps,
  } = props;
  const { clarificationPrompt, greetingStatement, currentTab } = state;

  const expansionPromptsProps = {
    onChangeText: handleOnChange,
    handleDelete: handleDeletePrompt,
    PromptValues: clarificationPrompt,
    handleSaveAllPrompts,
    promptsExample,
    lang,
  };
  const expansionStatementsProps = {
    onChangeText: handleOnChange,
    handleDelete: handleDeletePrompt,
    StatementValues: greetingStatement,
    handleSaveAllPrompts,
    promptsExample,
    lang,
    promptName: "greetingStatement",
    HELPTEXT: SlangTooltips.GREETING_STATEMENT,
  };

  return (
    <form>
      {currentTab === "greetingStatement" && (
        <div className={classes.separate}>
          <LangButton {...langButtonProps} />
          <br />
          <ExpansionStatements {...expansionStatementsProps} />
        </div>
      )}
      {currentTab === "clarificationPrompt" && (
        <div className={classes.separate}>
          <LangButton {...langButtonProps} />
          <br />
          <ExpansionPrompts
            {...expansionPromptsProps}
            promptName="clarificationPrompt"
            HELPTEXT={SlangTooltips.CLARIFICATION_PROMPT}
          />
        </div>
      )}
    </form>
  );
}

PromptTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export class PromptsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "en-IN",
      open: false,
      error: null,
      stringData: this.props.appStringTable,
      currentTab: "greetingStatement",
      languagePrompts: emptyLangAppState() || {},
      notify: {
        variant: "info",
        message: "msg",
      },
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handlePromptSave = this.handlePromptSave.bind(this);
  }

  updateState = () => {
    const appDATA = this.props.appDATA ? this.props.appDATA : {};
    const stringData = this.props.appStringTable;
    this.setState({
      languagePrompts: readPromptState(appDATA, stringData),
      strData: stringData,
    });
  };

  componentDidMount() {
    this.updateState();
  }

  handleDeletePrompt = (template, promptType, ID) => {
    const strData = this.state.strData;
    const languagePrompts = this.state.languagePrompts;
    const promptsStrings = deleteAppPromptState(
      languagePrompts,
      strData,
      template,
      promptType,
      ID
    );

    this.setState({
      languagePrompts: promptsStrings.newLanguagePrompts,
    });
    if (promptsStrings.hasStringID) {
      this.setState({ strData: promptsStrings.newStrData });
    }
    this.handlePromptSave(
      promptsStrings.newLanguagePrompts,
      promptsStrings.newStrData
    );
  };

  handlePromptSave = (
    languagePrompts = this.state.languagePrompts,
    strData = this.state.strData
  ) => {
    const appData = this.props.appDATA;
    const newData = writeAppPromptState(appData, strData, languagePrompts);
    this.props.changeAppDATA({ appDATA: newData.appData });
    this.props.changeAppStringTable({ appStringTable: newData.strData });
  };

  handleSaveAllPrompts = e => {
    const { languagePrompts, stringData } = this.state;
    this.handlePromptSave(languagePrompts, stringData);
  };

  handleOnChange = (event, key = null) => {
    const { value, name, severity, type } = event.target;
    const lang = this.state.lang;
    this.setState(state => {
      const languagePrompts = state.languagePrompts;
      const newLanguagePrompts = editPromptState(
        languagePrompts,
        lang,
        name,
        key,
        value,
        severity
      );
      if (!type) {
        this.handlePromptSave(newLanguagePrompts);
      }
      return { languagePrompts: newLanguagePrompts };
    });
  };

  handleLangChange = lang => {
    this.setState({
      lang,
    });
  };

  handleSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeTab = (event, currentTab) => {
    this.setState({
      currentTab,
    });
  };

  render() {
    const { classes } = this.props;
    const { error, notify, lang, languagePrompts, currentTab } = this.state;
    const {
      handleOnChange,
      handleDeletePrompt,
      handleLangChange,
      handleSaveAllPrompts,
    } = this;

    const promptsExample = languagePrompts["en-IN"];
    const prompts = languagePrompts[lang];
    const promptTextFieldProps = {
      handleOnChange,
      handleDeletePrompt,
      handleSaveAllPrompts,
      promptsExample,
      error,
      lang,
      classes,
    };
    const promptTextFieldState = {
      ...prompts,
      currentTab,
    };
    const langButtonProps = {
      lang,
      handleLangChange,
      classes,
    };
    return (
      <div>
        <Paper className={classes.promptPaper} elevation={0}>
          <Typography variant="h5" noWrap>
            {this.props.heading || "App Settings "}{" "}
            <DocHelpLink docFor="APP_SETTINGS" />
          </Typography>
          <br />
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChangeTab}
          >
            {tabsList.map(n => (
              <Tab key={n.value} value={n.value} label={n.name} />
            ))}
          </Tabs>
          <br />
          <PromptTextField
            langButtonProps={langButtonProps}
            {...promptTextFieldProps}
            state={promptTextFieldState}
          />
        </Paper>
        <Notifications notify={notify} />
      </div>
    );
  }
}

PromptsEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    appStringTable: state.appSchema.appStringTable,
    appList: state.appSchema.list,
    error: state.appSchema.error,
    appID: state.appSchema.appID,
    appDATA: state.appSchema.appDATA,
    schemaDefinition: state.appSchema.schemaDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(main)(PromptsEdit));
