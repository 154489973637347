import {
  Dialog,
  DialogContent,
  Typography,
  Chip,
  Button,
  Paper,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { WatchLater, Close } from "@material-ui/icons";

const ChangelogDialog = props => {
  const {
    changelogOpen,
    handleChangelogClosed,
    classes,
    templateDetails,
    selectedVersion,
    changeLogDetails,
  } = props;

  return (
    <Dialog
      open={changelogOpen}
      onClose={handleChangelogClosed}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "80%",
              alignItems: "center",
            }}
          >
            <WatchLater style={{ width: "18px", marginRight: "1rem" }} />
            <Typography
              variant="subheading"
              className={classes.templateDetailsText}
              style={{ marginRight: "1rem" }}
            >
              {`${
                templateDetails.changelog[selectedVersion?.versionString].title
              }`}
            </Typography>
            <Chip
              variant="outlined"
              label={`${selectedVersion?.versionString}`}
              color="secondary"
              size="small"
            />
          </div>
          <IconButton
            className={classes.closeIcon}
            style={{ float: "right" }}
            onClick={handleChangelogClosed}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <div className={classes.dialogCreate}>
        <DialogContent style={{ padding: "0" }} className={classes.ChangesList}>
          {changeLogDetails &&
            changeLogDetails.map((item, key) => (
              <Paper className={classes.changeItem} key={key}>
                <li>{item}</li>
              </Paper>
            ))}
        </DialogContent>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          onClick={handleChangelogClosed}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default ChangelogDialog;
