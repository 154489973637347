import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector } from "react-redux";

import DocHelpLink from "../../../../common/DocLinks";
import * as SlangTooltips from "../../../../common/SlangTooltips";

import PromptTextField from "./PromptTextField";
import Parameters from "./Parameters";

import {
  emptyLangPromptUserJourneyState,
  readAppStateCompletionPrompts,
  editPromptState,
  writeUserJourneyPromptsState,
  deleteAppPromptState,
  getAllSeverityLevels,
  removeSeverityLevelsPrompts,
} from "./promptsEditUtil";

import { changeAppStringTable } from "../../../../../redux/actions/appSchema";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../../libs/analytics/slangAnalyticsAPIs";

const styles = makeStyles(theme => ({
  root: {
    maxHeight: 600,
    overflow: "scroll",
  },
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    paddingBottom: 0,
    textAlign: "left",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  promptContainer: {
    padding: theme.spacing(2.5),
  },
}));

const appStatesKey = "appStatesMetadata";

const PromptsEdit = props => {
  // Redux State
  const { appStringTable, appDATA } = useSelector(state => ({
    appStringTable: state.appSchema.appStringTable,
    appDATA: state.appSchema.appDATA,
  }));

  // Props
  const {
    appStatesMetadata,
    selectedAppState,
    userJourneyID,
    changeUserJourneyData,
    heading,
  } = props;
  // States
  const [lang, setLang] = useState("en-IN");
  const [appState, setAppState] = useState(appStatesMetadata[selectedAppState]);
  const [stringTable, setStringTable] = useState(appStringTable);
  const [languagePrompts, setLanguagePrompts] = useState(
    emptyLangPromptUserJourneyState() || {}
  );
  const [severityLevels, setSeverityLevels] = useState([1, 2, 3]);

  const updateState = (
    appStateMetadata = appState,
    stringData = stringTable
  ) => {
    setLanguagePrompts(
      readAppStateCompletionPrompts(appStateMetadata, stringData)
    );
    setAppState(appStateMetadata);
    setStringTable(stringData);
  };

  useEffect(() => {
    updateState(appStatesMetadata[selectedAppState], appStringTable);
  }, [appState, stringTable, selectedAppState]);

  // useEffect(() => {
  //   const langPrompts = readAppStateCompletionPrompts(appStatesMetadata[selectedAppState], appStringTable);
  //   setSeverityLevels(getAllSeverityLevels(langPrompts).sort((a,b) => a-b));
  // }, [languagePrompts]);

  // Handlers
  const onChangeText = (promptPayload, key = null) => {
    const { promptName, completionTemplateType, severity, value, type } =
      promptPayload;

    const newLanguagePrompts = editPromptState(
      languagePrompts,
      severity,
      value,
      lang,
      completionTemplateType,
      key
    );

    if (type) {
      handleSavePrompt(
        promptName,
        appState,
        stringTable,
        newLanguagePrompts,
        true,
        key
      );
    }
    setLanguagePrompts(newLanguagePrompts);
  };

  const handleSavePrompt = (
    promptName,
    appState,
    stringTable,
    newLanguagePrompts = languagePrompts,
    update,
    key = null,
    stringID = null
  ) => {
    const newData = writeUserJourneyPromptsState(
      promptName,
      appState,
      stringTable,
      newLanguagePrompts,
      severityLevels,
      key,
      stringID,
      lang
    );

    const newAppStates = appDATA.userJourneys[userJourneyID][appStatesKey];
    newAppStates[selectedAppState] = newData.newAppState;

    const newUserJourneyData = {
      ...appDATA.userJourneys[userJourneyID],
      [appStatesKey]: newAppStates,
    };

    if (update) {
      updateState(newData.newAppState, newData.newStringTable);
    }

    changeUserJourneyData(newUserJourneyData, userJourneyID);
    changeAppStringTable({ appStringTable: newData.newStringTable });
    logEvent(
      SlangSeverityLevels.INFO,
      SlangEvents.USERJOURNEY_PROMPTS_UPDATED,
      {
        userjourney_name: newUserJourneyData.name,
        selected_app_state: appStatesMetadata[selectedAppState].name,
        language: lang,
      }
    );
  };

  const handleSaveAllPrompts = (event, key) => {
    handleSavePrompt(
      event.target.name,
      appState,
      stringTable,
      languagePrompts,
      true,
      key
    );
  };

  const handleDelete = (templateType, promptName, promptId) => {
    const updatedPrompts = deleteAppPromptState(
      stringTable,
      languagePrompts,
      templateType,
      promptId,
      lang
    );

    if (updatedPrompts.hasStringID) {
      setStringTable(updatedPrompts.newStringTable);
    }

    handleSavePrompt(
      promptName,
      appState,
      updatedPrompts.newStringTable,
      languagePrompts,
      true,
      promptId,
      updatedPrompts.hasStringID
    );
  };

  // const handleDeleteSeverityLevel = (promptName, newLevels) => {
  //   setSeverityLevels(newLevels);
  //   const { newLanguagePrompts, newStringTable } = removeSeverityLevelsPrompts(
  //     languagePrompts,
  //     stringTable,
  //     newLevels
  //   );
  //   handleSavePrompt(
  //     promptName,
  //     appState,
  //     newStringTable,
  //     newLanguagePrompts,
  //     true
  //   );
  // };

  // const handleAddLevel = () => {
  //   setSeverityLevels((oldLevels) => [...oldLevels, oldLevels.length + 1]);
  //   return severityLevels.length + 1;
  // };

  // const handleDeleteLevel = (currentLevel) => {
  //   const newLevel = severityLevels.filter((item) => item < currentLevel);
  //   setSeverityLevels(newLevel);
  //   handleDeleteSeverityLevel(appState.name, newLevel);
  //   return newLevel.length;
  // };

  // Component Payload
  const promptsExample = languagePrompts["en-IN"];
  const prompts = languagePrompts[lang];
  const promptTextFieldProps = {
    lang,
    setLang,
    promptName: appState.name,
    promptsExample,
    prompts,
    HELPTEXT: SlangTooltips.GREETING_STATEMENT,
    onChangeText,
    handleSaveAllPrompts,
    handleDelete,
    severityLevels,
    isTerminal: appState.isTerminal,
    // handleAddLevel,
    // handleDeleteLevel,
  };

  const classes = styles();

  return (
    <div className={classes.root}>
      {heading && (
        <Typography variant="h6" className={classes.chip} noWrap>
          {heading} <DocHelpLink docFor={"APP_PROMPTS"} />
        </Typography>
      )}
      <div className={classes.promptContainer}>
        <PromptTextField {...promptTextFieldProps} />
      </div>
      <Parameters
        appStatesMetadata={appStatesMetadata}
        selectedAppState={selectedAppState}
      />
    </div>
  );
};

PromptsEdit.propTypes = {
  appStatesMetadata: PropTypes.array.isRequired,
  selectedAppState: PropTypes.number.isRequired,
  userJourneyID: PropTypes.number.isRequired,
  changeUserJourneyData: PropTypes.func.isRequired,
  heading: PropTypes.string,
};

export default PromptsEdit;
