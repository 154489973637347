import { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../withTheme";

import { styles } from "./styles";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

import IntentHomeMain from "./IntentHomeMain";
import TopNav from "../common/topNav";
import SideNav from "../common/sideNav";
import { testClassNames } from "../../utils/integrationTestClassNames";

class EditIntents extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleIntentNameChange = newIntentName => {
    this.setState({
      isLoading: true,
    });

    const redirect =
      "/home/" + this.props.match.params.appname + "/" + newIntentName;

    this.props.history.replace(redirect);

    setTimeout(
      () =>
        this.setState({
          isLoading: false,
        }),
      500
    );
  };

  componentWillUnmount() {
    this.setState({
      isLoading: false,
    });
  }

  goHome() {
    this.props.history.replace("/home");
  }

  render() {
    const { classes, breadcrumbs, match } = this.props;

    const appName = match.params.appname;
    const intentName = match.params.intent;

    return (
      <div className={classes.appFrame}>
        <SideNav currentPage="intent" appname={appName} />
        <main className={classes.content}>
          <TopNav
            login={this.handleLogout}
            breadcrumbs={breadcrumbs}
            showPublish
          />
          <Fade in={true} timeout={{ enter: 500, exit: 200 }}>
            <div>
              {!this.state.isLoading ? (
                <div className={classes.progress}>
                  <CircularProgress
                    color="primary"
                    thickness={1}
                    size={150}
                    className={`${testClassNames.CIRCULAR_PROGRESS_BAR}`}
                  />
                </div>
              ) : (
                <IntentHomeMain
                  appName={appName}
                  intentName={intentName}
                  goHome={this.goHome}
                  handleIntentNameChange={this.handleIntentNameChange}
                />
              )}
            </div>
          </Fade>
        </main>
      </div>
    );
  }
}

EditIntents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(EditIntents));
