import { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CreateApp from "../../../assets/createApp.png";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NewTemplateEditor from "../components/NewTemplateEditor";
import { createNewTemplateState } from "../../../utils/MiscUtils";
import {
  validateAppName,
  namespace_name_regex_reverse,
} from "../../../utils/validate";

import DocHelpLink from "../../common/DocLinks";

interface CreateAssistantTemplateDialogProps {
  classes: any;
  handleClose: () => void;
  handleCreateNewAssistantTemplate: (
    newTemplateName: string,
    selectedCanonicalDomainName: string,
    assistantTemplateDataTextWithoutNameOrID: any,
    assistantTemplateStringDataText: { [key: string]: any }
  ) => Promise<void>;
  state: any;
}

const CreateAssistantTemplateDialog = (
  props: CreateAssistantTemplateDialogProps
) => {
  const [newTemplateName, setNewTemplateName] = useState<string>("");
  const [selectedCanonicalDomainName, setSelectedCanonicalDomainName] =
    useState<string>("");
  const [newTemplateNameError, setNewTemplateNameError] =
    useState<boolean>(true);

  const { classes, handleClose, handleCreateNewAssistantTemplate, state } =
    props;

  const { isOpen, canonicalDomainsList, schemaDefinition, allTemplates } =
    state;

  const PAGES = {
    TEMPLATE_DETAILS: "TEMPLATE_DETAILS",
    TEMPLATE_DATA: "TEMPLATE_DATA",
  };

  const [currentPage, setCurrentPage] = useState<string>(
    PAGES.TEMPLATE_DETAILS
  );
  const [isTemplateValid, setIsTemplateValid] = useState<boolean>(false);
  const [
    assistantTemplateDataTextWithoutNameOrID,
    setAssistantTemplateDataTextWithoutNameOrID,
  ] = useState(JSON.stringify({}));
  const [assistantTemplateStringDataText, setAssistantTemplateStringDataText] =
    useState(JSON.stringify({}));

  const clearState = () => {
    setAssistantTemplateDataTextWithoutNameOrID(JSON.stringify({}));
    setAssistantTemplateStringDataText(JSON.stringify({}));
    setNewTemplateName("");
    setNewTemplateNameError(true);
    setSelectedCanonicalDomainName("");
    setCurrentPage(PAGES.TEMPLATE_DETAILS);
  };

  useEffect(() => {
    if (isOpen && currentPage === PAGES.TEMPLATE_DATA) {
      const { appSchema, stringTable } = createNewTemplateState();
      setAssistantTemplateDataTextWithoutNameOrID(
        JSON.stringify(appSchema, null, "\t")
      );
      setAssistantTemplateStringDataText(
        JSON.stringify(stringTable, null, "\t")
      );
    }
  }, [isOpen, currentPage]);

  const handleChangeTemplateName = (name: any) => {
    console.log();
    const text = name
      .replace(namespace_name_regex_reverse, "")
      .replace(/(\.\.)+/g, ".");
    const valid = validateAppName(
      text,
      allTemplates.map((item: any) => {
        return { ...item, name: item.name };
      })
    );
    setNewTemplateName(text);
    setNewTemplateNameError(!valid);
  };

  const handleCancel = () => {
    clearState();
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={currentPage === PAGES.TEMPLATE_DATA}
    >
      <div className={classes.dialogCreate}>
        <Typography variant="h6" gutterBottom className={classes.textColor}>
          {"New Assitant Template"} <DocHelpLink docFor="BUDDY" />
        </Typography>
        <DialogContent>
          <img src={CreateApp} alt="Slang Labs" className={classes.logo} />
          {currentPage === PAGES.TEMPLATE_DETAILS ? (
            <form
              onSubmit={() => {
                handleCreateNewAssistantTemplate(
                  newTemplateName,
                  selectedCanonicalDomainName,
                  JSON.parse(assistantTemplateDataTextWithoutNameOrID),
                  JSON.parse(assistantTemplateStringDataText)
                );
              }}
              style={{ width: "100%" }}
            >
              <FormControl
                color="primary"
                className={classes.createInput}
                error={newTemplateNameError || !selectedCanonicalDomainName}
                aria-describedby="create-app-name-text"
              >
                <InputLabel
                  htmlFor="canonical-domain"
                  error={!selectedCanonicalDomainName}
                >
                  Canonical Domain
                </InputLabel>

                <Select
                  inputProps={{
                    name: "canonical-domain",
                    id: "canonical-domain",
                  }}
                  value={selectedCanonicalDomainName}
                  onChange={event => {
                    setSelectedCanonicalDomainName(
                      JSON.stringify(event.target.value)
                    );
                  }}
                  error={!selectedCanonicalDomainName}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {canonicalDomainsList.map((canonicalDomain: any) => (
                    <MenuItem
                      value={canonicalDomain.name}
                      key={canonicalDomain.name}
                    >
                      <em>{canonicalDomain.name}</em>
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  autoFocus
                  id="new-template-name"
                  name="newTemplateName"
                  className={classes.createInput}
                  color="primary"
                  label="Template Name"
                  inputProps={{
                    "aria-label": "App Name",
                  }}
                  type="text"
                  onChange={event => {
                    handleChangeTemplateName(event.target.value);
                  }}
                  error={newTemplateNameError}
                  value={newTemplateName}
                  helperText="Unique name; e.g. app.camera.com or My Camera app V1"
                  required
                />
              </FormControl>

              <br />
              <br />
              <Button
                variant="text"
                classes={{
                  root: classes.eTypeDone,
                  disabled: classes.disabled,
                }}
                className={`${testClassNames.BUDDY_CREATE_BUTTON}`}
                disabled={
                  !selectedCanonicalDomainName ||
                  !newTemplateName ||
                  newTemplateNameError
                }
                onClick={() => {
                  setCurrentPage(PAGES.TEMPLATE_DATA);
                }}
              >
                {"Next"}
              </Button>
              <br />
              <br />
              <Button
                variant="outlined"
                className={`${classes.cancelButton} ${testClassNames.BUDDY_CANCEL_BUTTON}`}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </form>
          ) : (
            <div>
              <NewTemplateEditor
                editable
                schemaDefinition={schemaDefinition}
                setIsTemplateValid={setIsTemplateValid}
                assistantTemplateDataTextWithoutNameOrID={
                  assistantTemplateDataTextWithoutNameOrID
                }
                setAssistantTemplateDataTextWithoutNameOrID={
                  setAssistantTemplateDataTextWithoutNameOrID
                }
                assistantTemplateStringDataText={
                  assistantTemplateStringDataText
                }
                setAssistantTemplateStringDataText={
                  setAssistantTemplateStringDataText
                }
              />
              <Button
                variant="text"
                classes={{
                  root: classes.eTypeDone,
                  disabled: classes.disabled,
                }}
                className={`${testClassNames.BUDDY_CREATE_BUTTON}`}
                disabled={
                  newTemplateNameError ||
                  !selectedCanonicalDomainName ||
                  !isTemplateValid
                }
                onClick={() => {
                  handleCreateNewAssistantTemplate(
                    newTemplateName,
                    selectedCanonicalDomainName,
                    JSON.parse(assistantTemplateDataTextWithoutNameOrID),
                    JSON.parse(assistantTemplateStringDataText)
                  );
                  clearState();
                }}
              >
                {"Create new assistant Template"}
              </Button>
              <br />
              <br />
              <Button
                variant="outlined"
                className={`${classes.cancelButton} ${testClassNames.BUDDY_CANCEL_BUTTON}`}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions />
      </div>
    </Dialog>
  );
};

export default CreateAssistantTemplateDialog;
