import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import { colourEntity } from "../../../utils/helpers";

// Styling styling applied to the name of the entity
const colorName = name => {
  const Color = colourEntity(name);
  const colorStyle = {
    backgroundColor: Color.bColor,
    color: Color.fColor,
    borderRadius: 5,
    fontSize: "0.8rem",
    padding: "2px 10px",
    marginLeft: 2,
    marginRight: 2,
  };
  return <span style={colorStyle}>{name}</span>;
};
const EntityDropDown = props => {
  const { classes, handleSelectChangeEntity, handleClosePopOver } = props;
  const { errorEntity, dataEntities, entityName } = props.state;

  return (
    <Paper className={classes.contextMenu} elevation={7}>
      {errorEntity ? (
        <h4>{errorEntity}</h4>
      ) : (
        <div>
          <MenuItem
            className={classes.entityMenuItem}
            name="entityName"
            value="none"
            data={{ name: "entityName", value: "none" }}
            onClick={handleSelectChangeEntity.bind(this, "none")}
          >
            <em>Remove Tag</em>
          </MenuItem>
          {dataEntities
            ? dataEntities.map((item, key) => (
                <MenuItem
                  key={key}
                  name="entityName"
                  className={
                    entityName === item.name
                      ? classes.entityMenuItemSelected
                      : classes.entityMenuItem
                  }
                  value={item.name}
                  data={{ name: "entityName", value: item.name }}
                  onClick={handleSelectChangeEntity.bind(this, item.name)}
                >
                  {colorName(item.name)}
                </MenuItem>
              ))
            : ""}
          <MenuItem
            onClick={() => props.setStateOfEntityDialog(true)}
            className={classes.addEntityButtonInDropdown}
          >
            <Button variant="outlined" color="primary" fullWidth>
              Create New Entity
            </Button>
          </MenuItem>
          <MenuItem
            className={classes.entityMenuItem}
            name="close"
            value="close"
            // data={{ name: "entityName", value: "none" }}
            onClick={handleClosePopOver}
          >
            <em>Close</em>
          </MenuItem>
        </div>
      )}
    </Paper>
  );
};

EntityDropDown.propTypes = {
  setStateOfEntityDialog: PropTypes.func.isRequired,
  handleSelectChangeEntity: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default EntityDropDown;
