import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import LinearProgressWithLabel from "../LinearProgressWithLabel";
import AppConfig from "../../../../app.config";
import FileUploader from "./FileUploader";

import { Button, Typography, Grid } from "@material-ui/core";

import { getPutPostSubdomainData } from "../../../../libs/slang/asstAPIs";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";
import { v4 } from "uuid";

const UploadForm = ({
  assistantType,
  ShowNames,
  subDomainName,
  dataName,
  dataID,
  subDomainID,
  dispatchSubdomainChange,
  setIsProcessing,
  handleClose,
  userDataID,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);

  const { version, ID, subDomain } = useSelector(state => ({
    version: state.appSchema.appDATA.version,
    ID: state.appSchema.appDATA.id,
    subDomain: state.appSchema.appDATA.subDomains[subDomainID],
  }));

  useEffect(() => {
    setIsProcessing(isUploadingFile || loading);
    if (isUploadingFile || loading) setMessage(null);
    return () => {
      setIsProcessing(false);
    };
  }, [isUploadingFile, loading]);

  const recursiveUpload = async ({ chunks, data_id, rest, page = 0 }) => {
    try {
      const { data, page_number } = chunks[page];
      const unitProgress = 100 / chunks.length;
      const diff2 = Math.random() * 10;
      setProgress(unitProgress * page);
      setBuffer(unitProgress * page + unitProgress + diff2);
      await getPutPostSubdomainData({
        ...rest,
        method: "PUT",
        data_id,
        page_number,
        data,
      });
      const nextPage = page + 1;
      if (chunks.length !== nextPage) {
        return await recursiveUpload({
          chunks,
          data_id,
          rest,
          page: nextPage,
        });
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const uploadToStore = async payload => {
    const { chunks, totalRows, ...rest } = payload;
    const uploadID = v4();
    logEvent(
      SlangSeverityLevels.INFO,
      SlangEvents.SUBDOMAIN_USER_DATA_UPLOAD_STARTED,
      {
        subdomain_name: subDomainName,
        subdomain_data_name: dataName,
        upload_id: uploadID,
      }
    );
    try {
      const { data_id } = await getPutPostSubdomainData({
        ...rest,
        method: "POST",
        data: {
          data_name: dataName,
          subdomain: subDomainName,
          num_rows: totalRows,
          page_size: AppConfig.PAGESIZE,
        },
      });

      const isUploaded = await recursiveUpload({
        chunks,
        data_id,
        rest,
      });
      if (isUploaded) {
        logEvent(
          SlangSeverityLevels.INFO,
          SlangEvents.SUBDOMAIN_USER_DATA_UPLOAD_SUCCESS,
          {
            subdomain_name: subDomainName,
            subdomain_data_name: dataName,
            upload_id: uploadID,
          }
        );
        return data_id;
      }
    } catch (error) {
      logEvent(
        SlangSeverityLevels.ERROR,
        SlangEvents.SUBDOMAIN_USER_DATA_UPLOAD_FAILURE,
        {
          subdomain_name: subDomainName,
          subdomain_data_name: dataName,
          error,
          upload_id: uploadID,
        }
      );
      console.log(error);
    }
    return false;
  };

  const submitForm = async () => {
    const { chunks, headers, totalRows } = selectedFile;
    const newPayload = {
      ID,
      assistantType,
      totalRows,
      version,
      env: "stage",
      assistant_version: version,
      chunks,
    };
    const data_id = await uploadToStore(newPayload);

    if (data_id) {
      dispatchSubdomainChange({
        type: "dataUpdate",
        payload: {
          dataID,
          subDomainID,
          csvfields: headers,
          data_id,
          csvdata: [],
          userDataID,
        },
      });
      setMessage({
        text: "Updated custom data!",
        type: "primary",
      });
    } else {
      setMessage({
        text: "Error uploading the data :(",
        type: "error",
      });
    }
    setIsUploadingFile(false);
  };
  return (
    <form>
      <Grid alignItems="center" container>
        <Grid sm={12} item></Grid>
        <Grid sm={6} item>
          {isUploadingFile || loading ? (
            <Typography> processing... </Typography>
          ) : message ? (
            <Typography color={message.type}>{message.text}</Typography>
          ) : selectedFile ? (
            <Typography color="primary">Click the upload button </Typography>
          ) : (
            <Typography>Select a CSV file to upload.</Typography>
          )}
          <ShowNames />
        </Grid>

        <Grid sm={6} item>
          {message?.type === "primary" ? (
            <Button variant="outlined" fullWidth onClick={handleClose}>
              Close
            </Button>
          ) : (
            <>
              <FileUploader
                {...{
                  assistantType,
                  loading,
                  setloading,
                  isUploadingFile,
                  dataName,
                  subDomain,
                }}
                onFileSelectSuccess={file => {
                  setSelectedFile(file);
                  setError(false);
                }}
                onFileSelectError={({ error }) => {
                  setMessage({
                    text: error,
                    type: "error",
                  });
                  setError(true);
                }}
              />
              <br />
              <br />
              {isUploadingFile && (
                <LinearProgressWithLabel
                  variant="buffer"
                  value={progress}
                  valueBuffer={buffer}
                />
              )}
              <Button
                variant={isUploadingFile ? "text" : "outlined"}
                onClick={() => {
                  setIsUploadingFile(true);
                  submitForm();
                }}
                disabled={error || !selectedFile || isUploadingFile}
              >
                {isUploadingFile ? "uploading the file" : "upload"}
              </Button>{" "}
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

UploadForm.propTypes = {
  assistantType: PropTypes.string.isRequired,
  dataID: PropTypes.number.isRequired,
  subDomainID: PropTypes.number.isRequired,
  displayName: PropTypes.string,
  subDomainName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  dispatchSubdomainChange: PropTypes.func.isRequired,
  ShowNames: PropTypes.func,
  setIsProcessing: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UploadForm;
