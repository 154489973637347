import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

import { testClassNames } from "../../../utils/integrationTestClassNames.js";
import AppConfig from "../../../app.config";
const Langs = AppConfig.LANGUAGES;

const PromptCount = props => {
  const { count, classes } = props;
  return (
    <Avatar
      classes={{
        root: classes.avatarRoot,
      }}
    >
      {count}
    </Avatar>
  );
};

const EntityPromptsChip = props => {
  const { classes, entity, appStringTable, handleEntityEdit, ID } = props;
  const checkPrompt =
    entity &&
    entity.missingEntityPrompt &&
    entity.missingEntityPrompt.questionTemplates;

  const counters = {};
  Langs.forEach(lang => {
    counters[lang.code] = 0;
  });
  if (checkPrompt) {
    entity.missingEntityPrompt.questionTemplates.forEach(prompt => {
      if (prompt.isId && appStringTable[prompt.value]) {
        Langs.forEach(lang => {
          if (appStringTable[prompt.value][lang.code]) counters[lang.code]++;
        });
      } else {
        counters["en-IN"]++;
      }
    });
  } else if (entity.prompts) {
    counters["en-IN"] = entity.prompts.length;
  }

  const newCount = Object.keys(counters).reduce((previous, key) => {
    return Number(counters[key]) ? previous + counters[key] : previous;
  }, 0);

  if (newCount !== 0)
    return Langs.map(lang => {
      return (
        <Chip
          key={lang.code}
          label={lang.code}
          className={`${classes.languageChip} ${testClassNames.ENTITY_PROMPT_LANGUAGE_ENGLISH}`}
          avatar={<PromptCount count={counters[lang.code]} classes={classes} />}
          classes={{
            label: classes.chipLabel,
          }}
          onClick={handleEntityEdit.bind(this, entity, ID, lang.code)}
          clickable
        />
      );
    });

  return null;
};

export default EntityPromptsChip;
