import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { main } from "../styles.js";
import withTheme from "../../withTheme";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";

import { CopyToClipboard } from "react-copy-to-clipboard";

import Notifications from "../../common/Notifications";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs";
import { testClassNames } from "../../../utils/integrationTestClassNames.js";
import moment from "moment";

class HelpBox extends Component {
  state = {
    notify: {
      variant: "info",
      message: "msg",
    },
  };

  copytoClip = event => {
    event.preventDefault();
    this.setState({
      notify: {
        variant: "info",
        message: "BUDDY ID copied to clipboard",
      },
    });
    logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_ID_COPIED);
  };

  render() {
    const { classes, appID, appMetadata } = this.props;
    const { notify } = this.state;
    const {
      name,
      create_timestamp,
      status: { stage, prod },
    } = appMetadata || {
      name: null,
      create_timestamp: null,
      status: {
        stage: { update_timestamp: null, train_end_timestamp: null },
        prod: { update_timestamp: null, train_end_timestamp: null },
      },
    };
    const BuddyInfo = () => (
      <div>
        <Typography variant="button">ID:</Typography>
        <Tooltip title="Copy to clipboard">
          <Typography nowrap="true">
            <CopyToClipboard text={appID}>
              <a
                href="/copyme"
                onClick={this.copytoClip}
                className={`${classes.wrapText} ${testClassNames.BUDDY_ID_CLIPBOARD}`}
              >
                {appID}
              </a>
            </CopyToClipboard>
          </Typography>
        </Tooltip>
        <br />
        {name ? (
          <div>
            <Typography variant="body2" gutterBottom>
              <span className={classes.metadataStyle}>{"Name: "}</span>
              {name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span className={classes.metadataStyle}>{"Created: "}</span>
              {moment(create_timestamp / 1000)
                .fromNow()
                .toString()}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span className={classes.metadataStyle}>{"Saved: "}</span>
              {stage.update_timestamp ? (
                moment(stage.update_timestamp / 1000)
                  .fromNow()
                  .toString()
              ) : (
                <em>unsaved</em>
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span className={classes.metadataStyle}>
                {"Published - Stage: "}
              </span>

              {stage.train_end_timestamp ? (
                moment(stage.train_end_timestamp / 1000)
                  .fromNow()
                  .toString()
              ) : (
                <em>unpublished</em>
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span className={classes.metadataStyle}>
                {"Published - Prod: "}
              </span>

              {prod.train_end_timestamp ? (
                moment(prod.train_end_timestamp / 1000)
                  .fromNow()
                  .toString()
              ) : (
                <em>unpublished</em>
              )}
            </Typography>
          </div>
        ) : null}
      </div>
    );
    return (
      <Paper className={classes.paperHelp} elevation={0}>
        <div className={classes.appid}>
          <Typography
            variant="h5"
            style={{ fontSize: "1.2rem" }}
            noWrap
            gutterBottom
          >
            Buddy details
          </Typography>

          {appID ? (
            BuddyInfo()
          ) : (
            <LinearProgress color="primary" variant="query" />
          )}
        </div>
        <Divider />
        <br />
        <Notifications notify={notify} />
      </Paper>
    );
  }
}

HelpBox.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    appMetadata: state.appSchema.appMetadata,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTheme(withStyles(main)(HelpBox)));
