import * as types from "../types";

export const createUser = payload => ({
  type: types.USER_CREATE,
  payload,
  meta: {
    type: "user",
  },
});

export const getAccountProfile = payload => ({
  type: types.USER_GET_PROFILE,
  payload,
  meta: {
    type: "user",
  },
});

export const updateAccountProfile = payload => ({
  type: types.USER_UPDATE_PROFILE,
  payload,
  meta: {
    type: "user",
  },
});

export const getAllPlans = payload => ({
  type: types.USER_GET_ALL_PLANS,
  payload,
  meta: {
    type: "user",
  },
});

export const getAccountPlan = payload => ({
  type: types.USER_GET_PLAN,
  payload,
  meta: {
    type: "user",
  },
});

export const updateAccountPlan = payload => ({
  type: types.USER_UPDATE_PLAN,
  payload,
  meta: {
    type: "user",
  },
});

export const getAccountPrivileges = payload => ({
  type: types.USER_GET_PRIVLG,
  payload,
  meta: {
    type: "user",
  },
});

export const getAccountSessionsUsage = payload => ({
  type: types.USER_SESSIONS_USAGE,
  payload,
  meta: {
    type: "user",
  },
});

export const getAccountTransactionsUsage = payload => ({
  type: types.USER_TRANSC_USAGE,
  payload,
  meta: {
    type: "user",
  },
});
export const getAccountIntentsUsage = payload => ({
  type: types.USER_INTENT_USAGE,
  payload,
  meta: {
    type: "user",
  },
});
export const resetIsPlanUpdated = () => ({
  type: types.USER_RESET_IS_PLAN_UPDATE,
});
export const resetIsProfileUpdated = () => ({
  type: types.USER_RESET_IS_PROFILE_UPDATE,
});
export const updateOnboardingStatus = (payload = {}) => {
  return {
    type: types.USER_ONBOARDING,
    payload,
    meta: {
      type: "user",
    },
  };
};
