const Ajv = require("ajv");
const ajv = new Ajv({ schemaId: "auto", jsonPointers: true });
const FormatErrors = require("../utils/format_errors");
// ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-07.json"));

const validate = function (schema, jsonData) {
  if (schema === null)
    return {
      success: false,
      text: "Internal error: Cannot validate intent JSON at this time.",
    };

  if (typeof jsonData === "string") {
    try {
      jsonData = JSON.parse(jsonData);
    } catch (e) {
      return { success: false, text: e.message };
    }
  }

  if (ajv.validate(schema, jsonData)) {
    return { success: true, text: "Successfully validated JSON." };
  } else {
    const error = ajv.errorsText();
    const errorFormatted = FormatErrors.catchSchemaValidationError(
      error,
      jsonData
    );
    return { success: false, text: errorFormatted };
  }
};

module.exports = { validate };
