import * as types from "../types";

const templatesSubDomainsJourneys = sessionStorage.getItem(
  "A_templatesSubDomainsJourneys"
)
  ? JSON.parse(sessionStorage.getItem("A_templatesSubDomainsJourneys"))
  : [];

export const initialState = () => ({
  templatesList: templatesSubDomainsJourneys,
  error: null,
});

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.TEMPLATES_SUBDOMAINS_JOURNEYS:
      return {
        ...state,
        templatesList: action.payload.templatesList,
        error: action.payload.error,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
