import * as types from "../types";

export const signIn = payload => ({
  type: types.SIGNIN,
  payload,
});

export const signUp = payload => ({
  type: types.SIGNUP,
  payload,
  meta: {
    type: "login",
  },
});

export const forgotPassword = payload => ({
  type: types.FORGOT,
  payload,
  meta: {
    type: "login",
  },
});

export const confirmCode = payload => ({
  type: types.CONFIRMSIGNUP,
  payload,
  meta: {
    type: "login",
  },
});

export const signout = payload => ({
  type: types.SIGNOUT,
  payload,
  meta: {
    type: "login",
  },
});

export const changePassword = payload => ({
  type: types.CHANGE_PASSWORD,
  payload,
  meta: {
    type: "login",
  },
});
export const isUserLoggedIn = payload => ({
  type: types.IS_USER_LOGGED_IN,
  payload,
  meta: {
    type: "login",
  },
});

export const resetError = () => ({
  type: types.RESET_ERROR,
});
