const SlangConfig = {};
const ENV_PARAM = "";

SlangConfig.SLANG_CONSOLE_BASE = "/v1/console";
SlangConfig.SLANG_CONSOLE_BASE_URI =
  SlangConfig.SSLANG_CONSOLE_BASE + ENV_PARAM;
SlangConfig.CREATE_PUBLISH_ASSISTANT_CONFIG =
  "/create-publish-assistant-config";
SlangConfig.CREATE_SAVE_ASSISTANT_CONFIG = "/create-save-assistant-config";
SlangConfig.CREATE_PUBLISH_ASSISTANT_CONFIG_URI =
  SlangConfig.SLANG_CONSOLE_BASE +
  SlangConfig.CREATE_PUBLISH_ASSISTANT_CONFIG +
  ENV_PARAM;
SlangConfig.CREATE_SAVE_ASSISTANT_CONFIG_URI =
  SlangConfig.SLANG_CONSOLE_BASE +
  SlangConfig.CREATE_SAVE_ASSISTANT_CONFIG +
  ENV_PARAM;

SlangConfig.FULL_DOMAIN_LIST = "/full-domain-list";
SlangConfig.FULL_DOMAIN_LIST_URI =
  SlangConfig.SLANG_CONSOLE_BASE + SlangConfig.FULL_DOMAIN_LIST + ENV_PARAM;

SlangConfig.FULL_TEMPLATE_LIST = "/full-template-list";
SlangConfig.FULL_TEMPLATE_LIST_URI =
  SlangConfig.SLANG_CONSOLE_BASE + SlangConfig.FULL_TEMPLATE_LIST + ENV_PARAM;

SlangConfig.HEADERS = { "Content-Type": "application/json; charset=UTF-8" };

module.exports = SlangConfig;
