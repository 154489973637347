import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SelectIntent from "./SelectIntent";
import { emptyUIHintsState } from "../../../../utils/MiscUtils";
import ViewNewIntent from "../ViewNewIntent";

const initialUserJourney = {
  name: "user_journey_name_placeholder",
  description: "",
  displayName: "",
  enabled: true,
  intents: [],
  appStatesMetadata: [
    {
      name: "_greeting_",
      description:
        "The default message that Slang will speak out whenever Slang is invoked for this user journey",
      displayName: ": Greeting message",
      appStateName: "greeting",
      conditionName: "",
      parameters: [""],
      isTerminal: false,
      uiHints: [],
      completionContext: {
        type: "completion",
        data: "",
      },
      completion: {
        successTemplates: [
          {
            severity: 1,
            isId: false,
            value: "Welcome to the assistant. What would you like to do?",
          },
        ],
        failureTemplates: [],
      },
    },
    {
      name: "_clarification_",
      description:
        "When the user speaks a command and Slang is not able to understand the intent behind it for this user journey.",
      displayName: ": User intent is not clear",
      appStateName: "clarification",
      conditionName: "",
      parameters: [""],
      isTerminal: false,
      uiHints: [],
      completionContext: {
        type: "completion",
        data: "",
      },
      completion: {
        successTemplates: [
          {
            severity: 1,
            isId: false,
            value: "Sorry, can you repeat that?",
          },
        ],
        failureTemplates: [],
      },
    },
    {
      name: "_unsupported_",
      description: "",
      displayName: "",
      appStateName: "unsupported",
      conditionName: "",
      parameters: [""],
      isTerminal: true,
      uiHints: [],
      completionContext: {
        type: "completion",
        data: "",
      },
      completion: {
        successTemplates: [
          {
            isId: false,
            severity: 1,
            value: "Sorry, feature is not currently supported",
          },
        ],
        failureTemplates: [],
      },
    },
  ],
  greeting: {
    successTemplates: [
      {
        severity: 1,
        isId: false,
        value: "Welcome to the assistant. What would you like to do?",
      },
    ],
    failureTemplates: [],
  },
  clarification: {
    successTemplates: [
      {
        severity: 1,
        isId: false,
        value: "Sorry, can you repeat that?",
      },
    ],
    failureTemplates: [],
  },
};

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: 10,
  },
}));

function AddUserJourneyForm(props) {
  const { userJourneys, open, handleSaveClose, handleCreateNewIntent } = props;
  const classes = useStyles();

  const { intentNames, enabledSD } = useSelector(state => ({
    intentNames: state.appSchema.appDATA.intents.map(item => item.name),
    // Filter only the enabled sub domains.
    enabledSD: state.appSchema.appDATA.subDomains.filter(item => item.enabled),
  }));

  const [selectError, setSelectError] = useState(null);
  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [name, setName] = useState("");
  const [selectedIntents, setSelectedIntents] = useState([]);

  useEffect(() => {
    return () => {
      if (!open) {
        setDisplayName("");
        setName("");
        setDescription("");
        setSelectError(null);
        setSelectedIntents([]);
      }
    };
  }, [open]);

  const handleChangeDisplayName = event => {
    const text = event.target.value;
    if (text.length < 140) setDisplayName(text);
  };
  const handleChangeName = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDisplayName(text.replace(/_/g, " "));
      const currentName = text.replace(/ /g, "_");
      const doesNameExist = userJourneys.find(UJ => UJ.name === currentName);

      if (doesNameExist) {
        setSelectError("User Journey name exists");
      } else {
        setSelectError(null);
      }
      setName(currentName);
    }
  };
  const handleChangeDescription = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDescription(text);
    }
  };
  const handleSaveUserJourney = () => {
    const appStatesMetadata = initialUserJourney.appStatesMetadata.map(
      item => ({
        ...item,
        name: name + item.name,
        // displayName: name + item.displayName,
        uiHints: emptyUIHintsState(enabledSD),
      })
    );

    handleSaveClose(
      [
        ...userJourneys,
        {
          ...initialUserJourney,
          name: name.toLowerCase(),
          displayName,
          description,
          intents: selectedIntents,
          appStatesMetadata,
        },
      ],
      "userJourneys"
    );
  };
  const isDisabled =
    name.length < 2 ||
    displayName.length < 2 ||
    description.length < 3 ||
    selectedIntents.length < 1;
  return (
    <>
      <form noValidate autoComplete="off">
        <Grid spacing={3} alignContent="center" alignItems="center" container>
          <Grid xs={6} item>
            <TextField
              id="standard-name"
              label="Name"
              name="name"
              value={name}
              onChange={handleChangeName}
              error={selectError !== null}
              helperText={selectError}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="standard-display-name"
              label="Display Name"
              name="displayName"
              value={displayName}
              onChange={handleChangeDisplayName}
              fullWidth
            />
          </Grid>
          <Grid xs={12} className={classes.marginBottom} item>
            <TextField
              id="standard-description"
              label="Description"
              name="description"
              value={description}
              onChange={handleChangeDescription}
              fullWidth
            />
          </Grid>
          <Grid xs={10} item>
            <SelectIntent
              {...{ intentNames, selectedIntents, setSelectedIntents }}
            />
          </Grid>
          <Grid xs={2} item>
            <ViewNewIntent
              {...{
                handleCreateNewIntent,
                allIntentNames: intentNames,
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <Button
              disabled={selectError !== null}
              onClick={() => handleSaveUserJourney()}
              color="primary"
              fullWidth
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

AddUserJourneyForm.propTypes = {
  handleSaveClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userJourneys: PropTypes.array.isRequired,
};

export default AddUserJourneyForm;
