import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Tooltip,
  CircularProgress,
  Icon,
  Badge,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import * as SlangTooltips from "../SlangTooltips";
import CreateApp from "../../../assets/createApp.png";
import {
  upgradeAssistantConfig,
  getAllAssistantTemplates,
} from "../../../libs/slang/asstAPIs";
import { getAllVersions } from "../../../utils/versionUtils";

const styles = makeStyles(theme => ({
  createInput: {
    color: theme.palette.primary.dark,
    width: "100%",
    margin: theme.spacing(0.5),
    textAlign: "left",
  },
  eTypeDone: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  eTypeDone2: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.medium,
    width: "100%",
    height: 50,
    "&$disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  disabled: {},
  tooltip: {
    boxShadow: theme.shadows[5],
    fontSize: 15,
    width: 200,
  },
  dialogCreate: {
    height: "100%",
    textAlign: "center",
    width: 600,
    padding: 40,
  },
  upgradeIcon: {
    width: 90,
  },
  textColor: {
    color: theme.palette.primary.dark,
  },
  cancelButton: {
    width: "100%",
    height: 50,
    color: theme.palette.primary.dark,
  },
}));

const AssistantUpgraderDialog = props => {
  const { appID, appDATA, appMetadata, handleUpgrade } = props;

  const [assistantID, setAssistantID] = useState("");
  const [assistantVersion, setAssistantVersion] = useState("");
  const [templateVersion, setTemplateVersion] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounting, setIsMounting] = useState(true);
  const [versionList, setVersionList] = useState([]);
  const [updateRequired, setUpdateRequired] = useState(false);
  const [openUpgrader, setOpenUpgrader] = useState(false);

  const getAllTemplateVersions = templateID => {
    getAllAssistantTemplates().then(response =>
      setVersionList(
        getAllVersions(
          response
            .filter(template => template.id === templateID)
            .map(template => template.version),
          true
        )
      )
    );
  };

  const updateState = () => {
    setAssistantID(appID);
    setAssistantVersion(appDATA.version);
    getAllTemplateVersions(appMetadata.template_id);
  };

  useEffect(() => {
    if (appID && appMetadata && appDATA) {
      updateState();
    }
  }, [appID, appMetadata, appDATA]);

  useEffect(() => {
    if (versionList.length) {
      const latestVersion = versionList[versionList.length - 1];
      setUpdateRequired(latestVersion.versionString > assistantVersion);
      setIsMounting(false);
    }
  }, [assistantVersion, versionList.length]);

  const handleUpdateConfig = async () => {
    setIsLoading(true);
    setOpenUpgrader(false);
    handleUpgrade(-1, {
      variant: "info",
      message: "Upgrade Started...",
    });
    try {
      await upgradeAssistantConfig({
        assistantID,
        assistantVersion,
        templateVersion,
        env:  "stage",
      });
      handleUpgrade(1, {
        variant: "success",
        message: "Assistant config upgraded.",
      });
    } catch (error) {
      console.error(error);
      handleUpgrade(0, {
        variant: "error",
        message: "Sorry something went wrong, check console.",
      });
    }
    setIsLoading(false);
  };

  const classes = styles();

  return (
    <span>
      <Tooltip
        title={SlangTooltips.UPGRADE}
        classes={{ tooltip: classes.tooltip }}
        disableFocusListener
      >
        <Badge color="error" variant="dot" invisible={!updateRequired}>
          <Button
            classes={{
              root: classes.eTypeDone,
              disabled: classes.disabled,
            }}
            aria-haspopup="true"
            aria-label="Upgrade your assistant"
            onClick={() => {
              setOpenUpgrader(true);
            }}
            disabled={isLoading || isMounting}
          >
            {isLoading || isMounting ? (
              <CircularProgress
                size={24}
                color="secondary"
                style={{ marginRight: 5 }}
              />
            ) : (
              <Icon>{"upgrade"}</Icon>
            )}
          </Button>
        </Badge>
      </Tooltip>
      <Dialog
        open={openUpgrader}
        onClose={() => setOpenUpgrader(false)}
        maxWidth="md"
      >
        <div className={classes.dialogCreate}>
          <Typography variant="h5" gutterBottom className={classes.textColor}>
            {"Assistant Upgrader "}
          </Typography>
          <br />
          <br />
          <DialogContent className={classes.textColor}>
            <img
              src={CreateApp}
              alt="Slang Labs"
              className={classes.upgradeIcon}
            />
            <br />
            <br />
            <div>
              <FormControl className={classes.createInput}>
                <TextField
                  autoFocus
                  id="assistantID"
                  name="assistantID"
                  color="primary"
                  className={classes.createInput}
                  label="Assistant ID"
                  pattern="[A-Za-z0-9.]+"
                  inputProps={{
                    "aria-label": "assistantID",
                  }}
                  type="text"
                  onChange={event => {
                    setAssistantID(event.target.value);
                  }}
                  value={assistantID}
                  fullWidth
                />
              </FormControl>

              <FormControl className={classes.createInput}>
                <TextField
                  disabled
                  id="assistantVersion"
                  name="assistantVersion"
                  color="primary"
                  className={classes.createInput}
                  label="Assistant Version"
                  pattern="[A-Za-z0-9.]+"
                  inputProps={{
                    "aria-label": "assistantVersion",
                  }}
                  type="text"
                  onChange={event => {
                    setAssistantVersion(event.target.value);
                  }}
                  value={assistantVersion}
                />
              </FormControl>

              <FormControl className={classes.createInput}>
                <InputLabel id="select-version-label">Versions</InputLabel>
                <Select
                  labelId="select-version-label"
                  id="select-version"
                  value={templateVersion}
                  onChange={event => setTemplateVersion(event.target.value)}
                  className={classes.createInput}
                >
                  <MenuItem value={0} disabled selected>
                    {"Select Template Version"}
                  </MenuItem>
                  {versionList.map(version => {
                    return (
                      <MenuItem
                        key={version.versionString}
                        value={version.versionString}
                      >
                        {version.versionString}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              <br />
              <br />
              <Button
                variant="text"
                classes={{
                  root: classes.eTypeDone2,
                  disabled: classes.disabled,
                }}
                disabled={
                  !(assistantID && assistantVersion && templateVersion)
                }
                onClick={handleUpdateConfig}
                // disabled={createError ? true : false}
              >
                {"Upgrade"}
              </Button>
              <br />
              <br />
              <Button
                variant="outlined"
                className={classes.cancelButton}
                onClick={() => setOpenUpgrader(false)}
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </span>
  );
};

export default AssistantUpgraderDialog;
