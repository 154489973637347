const SlangConfig = require("./config");
const btoa = require("btoa");

function _get_uri(action, host, args = {}) {
  const start_date = args.start_date;
  const end_date = args.end_date;
  switch (action) {
    case "create_account":
      return host + SlangConfig.ACCOUNT_USER_URI;
    case "get_account":
      return host + SlangConfig.ACCOUNT_USER_URI;
    case "get_account_profile":
      return host + SlangConfig.ACCOUNT_PROFILE_URI;
    case "create_account_profile":
      return host + SlangConfig.ACCOUNT_PROFILE_URI;
    case "update_account_profile":
      return host + SlangConfig.ACCOUNT_PROFILE_URI;
    case "get_all_plans":
      return host + SlangConfig.ALL_PLANS_URI;
    case "get_account_plan":
    case "update_account_plan":
      return host + SlangConfig.ACCOUNT_PLAN_URI;
    case "get_account_privileges":
      return host + SlangConfig.ACCOUNT_PRIVILEGES_URI;
    case "get_transaction_usage":
      return host + SlangConfig.TRANSACTION_USAGE_URI;
    case "get_session_usage":
      return (
        host +
        SlangConfig.SESSIONS_USAGE_URI_QUERY.replace(
          "START_DATE",
          start_date
        ).replace("END_DATE", end_date)
      );
    case "get_intents_usage":
      return host + SlangConfig.INTENTS_USAGE_URI;
    case "send_demo_user_data":
      return host + SlangConfig.SEND_DEMO_DATA;
    default:
      break;
  }
}

function _slang_headers(auth_key, api_secret) {
  const base64_auth = btoa(auth_key + ":" + api_secret);
  return Object.assign(SlangConfig.HEADERS, {
    Authorization: "Basic " + base64_auth,
  });
}

function _validate_response(json) {
  if ("code" in json && (json.code < 200 || json.code > 299)) {
    let errorMsg = json.message || "Internal error";
    if (json.trace) errorMsg = errorMsg + "\n" + json.trace;
    return {
      success: false,
      message: errorMsg,
      raw_json_response: json,
    };
  } else {
    return { success: true, raw_json_response: json };
  }
}

const createAccount = function (host, api_key, api_secret, email_id) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");
    if (!email_id) reject("Missing 'email_id'");

    fetch(_get_uri("create_account", host), {
      method: "POST",
      headers: _slang_headers(api_key, api_secret),
      body: JSON.stringify({ email_id }),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to create account: " + err.message);
      });
  });
};

const getAccount = function (host, api_key, api_secret) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("get_account", host), {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to create account: " + err.message);
      });
  });
};

const getAccountProfile = function (host, api_key, api_secret) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("get_account_profile", host), {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get current plan: " + err.message);
      });
  });
};

const createAccountProfile = function (
  host,
  api_key,
  api_secret,
  profile_info
) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    const testKeys = ["company_name", "email_id", "owner_name"];
    if (
      Object.keys(profile_info).sort().toString() !== testKeys.sort().toString()
    )
      reject("Missing 'profile_info'");

    fetch(_get_uri("create_account_profile", host), {
      method: "PUT",
      headers: _slang_headers(api_key, api_secret),
      body: JSON.stringify(profile_info),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to create profile: " + err.message);
      });
  });
};

const updateAccountProfile = function (
  host,
  api_key,
  api_secret,
  profile_info
) {
  return createAccountProfile(host, api_key, api_secret, profile_info);
};

const getAllPlans = function (host, api_key, api_secret) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    const uri = _get_uri("get_all_plans", host);
    fetch(uri, {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to fetch Plans: " + err.message);
      });
  });
};

const getAccountPlan = function (host, api_key, api_secret) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("get_account_plan", host), {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get current plan: " + err.message);
      });
  });
};

const updateAccountPlan = function (host, api_key, api_secret, plan_info) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("update_account_plan", host), {
      method: "PUT",
      headers: _slang_headers(api_key, api_secret),
      body: JSON.stringify(plan_info),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to update the plan: " + err.message);
      });
  });
};

const getAccountPrivileges = function (host, api_key, api_secret) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("get_account_privileges", host), {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get privileges: " + err.message);
      });
  });
};

const getAccountTransactionsUsage = function (host, api_key, api_secret) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("get_transaction_usage", host), {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get transaction usage: " + err.message);
      });
  });
};

const getAccountSessionsUsage = function (
  host,
  api_key,
  api_secret,
  args = {}
) {
  const start_date = args.start_date;
  const end_date = args.end_date;

  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");
    if (!start_date) reject("Missing key 'start_date'");
    if (!end_date) reject("Missing key 'end_date'");

    fetch(_get_uri("get_session_usage", host, args), {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get sessions usage: " + err.message);
      });
  });
};

const getAccountIntentsUsage = function (host, api_key, api_secret) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("get_intents_usage", host), {
      method: "GET",
      headers: _slang_headers(api_key, api_secret),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to get intents usage: " + err.message);
      });
  });
};

const sendDemoUserData = function (host, api_key, api_secret, demo_user_data) {
  return new Promise((resolve, reject) => {
    if (!api_key) reject("Not Authorized");

    fetch(_get_uri("send_demo_user_data", host), {
      method: "POST",
      headers: _slang_headers(api_key, api_secret),
      body: JSON.stringify({ form_data: demo_user_data }),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        resolve(_validate_response(json));
      })
      .catch(err => {
        reject("Failed to create profile: " + err.message);
      });
  });
};

module.exports = {
  createAccount,
  getAccount,
  createAccountProfile,
  getAccountProfile,
  updateAccountProfile,
  getAllPlans,
  getAccountPlan,
  updateAccountPlan,
  getAccountPrivileges,
  getAccountTransactionsUsage,
  getAccountIntentsUsage,
  getAccountSessionsUsage,
  sendDemoUserData,
};
