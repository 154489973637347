import { Component, forwardRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import WarningIcon from "@material-ui/icons/Warning";
import TrainingIcon from "@material-ui/icons/DirectionsRun";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  train: TrainingIcon,
};

const contentStyles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  train: {
    backgroundColor: theme.palette.primary.dark,
    // This commented code is needed when adding async publish in the future
    // "& svg:first-child":{
    //   animationName: "glow",
    //   borderRadius: '18px',
    //   backgroundColor: green[500],
    //   // animationDuration: 'infinite',
    //   animation: '5s infinite alternate'
    // }
  },
  icon: {
    fontSize: 25,
    marginRight: 10,
  },
  close: {
    width: 30,
    height: 30,
  },
  closeIcon: {
    fontSize: 15,
  },
  iconVariant: {
    opacity: 0.7,
    marginRight: theme.spacing(1),
  },
  "@keyframes glow": {
    from: {
      "box-shadow": `0 0 5px -5px ${green[500]}`,
    },
    to: {
      backgroundColor: "none",
      "box-shadow": `0 0 5px 5px ${green[500]}`,
    },
  },
  message: {
    display: "flex",
    maxWidth: 470,
    alignItems: "center",
    textTransform: "none",
  },
});

const MySnackbarContent = forwardRef((props, ref) => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {variant === "train" ? (
            <CircularProgress
              className={classes.icon}
              size={24}
              thickness={3}
            />
          ) : (
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
          )}
          {message}
        </span>
      }
      action={[
        variant === "train" ? (
          <span key="none"></span>
        ) : (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        ),
      ]}
      ref={ref}
      {...other}
    />
  );
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info", "train"])
    .isRequired,
};

const SnackbarContentWrapper = withStyles(contentStyles)(MySnackbarContent);

const TransitionUp = forwardRef((props, ref) => {
  return <Slide {...props} ref={ref} />;
});

const snackBarStyles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
});

export class Notifications extends Component {
  state = {
    open: this.props.notify.open || false,
    variant: this.props.notify.variant ? this.props.notify.variant : "info",
    message: this.props.notify.message ? this.props.notify.message : "msg",
    autoHide:
      this.props.notify.autoHide !== undefined
        ? this.props.notify.autoHide
        : 3500,
    position: this.props.notify.position
      ? this.props.notify.position
      : { vertical: "top", horizontal: "center" },
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (
        prevProps.notify !== this.props.notify &&
        this.props.notify.message &&
        this.props.notify.message !== ""
      ) {
        this.setState({
          open: this.props.notify.open || true,
          variant: this.props.notify.variant || "info",
          message: this.props.notify.message || "msg",
          autoHide:
            this.props.notify.autoHide !== undefined
              ? this.props.notify.autoHide
              : 3500,
          position: this.props.notify.position || {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    }
  }

  render() {
    const { open, variant, message, autoHide, position } = this.state;

    return (
      <Snackbar
        anchorOrigin={position}
        open={open}
        autoHideDuration={autoHide}
        onClose={this.handleClose}
        TransitionComponent={TransitionUp}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <SnackbarContentWrapper
          onClose={this.handleClose}
          variant={variant}
          message={<span id="message-id"> {message} </span>}
        />
      </Snackbar>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
};

Notifications.defaultProps = {
  notify: {
    variant: "info",
    message: "msg",
    autoHide: 3500,
    open: false,
    position: { vertical: "top", horizontal: "center" },
  },
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(
  withStyles(snackBarStyles)(Notifications)
);
