import { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import CreateApp from "../../../assets/createApp.png";
import { testClassNames } from "../../../utils/integrationTestClassNames";

import DocHelpLink from "../../common/DocLinks";

const UpdateAssistantDomainDialog = (props: any) => {
  const { classes, handleClose, handleUpdateNewAssistantDomain, state } = props;

  const { isOpen, domainData } = state;

  const [selectedDomainTemplateVersion, setSelectedDomainTemplateVersion] =
    useState("");

  useEffect(() => {
    props.state.domainData &&
      setSelectedDomainTemplateVersion(props.state.domainData.template_version);
  }, [props.state.domainData]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <div className={classes.dialogCreate}>
        <Typography variant="h6" gutterBottom className={classes.textColor}>
          {"New Assitant Domain"} <DocHelpLink docFor="BUDDY" />
        </Typography>
        <DialogContent>
          <img src={CreateApp} alt="Slang Labs" className={classes.logo} />
          <div style={{ width: "100%" }}>
            <FormControl
              color="primary"
              className={classes.createInput}
              aria-describedby="create-app-name-text"
            >
              <TextField
                id="update-domain-name"
                name="updateDomainName"
                className={classes.createInput}
                color="primary"
                label="Domain Name"
                inputProps={{
                  "aria-label": "New Domain Name",
                }}
                type="text"
                disabled
                value={domainData ? domainData.display_name : ""}
                required
              />
              <TextField
                autoFocus
                id="update-app-description"
                name="updateAppDescription"
                className={classes.createInput}
                color="primary"
                label="Domain Description"
                inputProps={{
                  "aria-label": "Domain Description",
                }}
                type="text"
                disabled
                value={domainData ? domainData.description : ""}
              />
              <TextField
                autoFocus
                id="template-id"
                name="templateid"
                className={classes.createInput}
                color="primary"
                label="Domain Template ID"
                inputProps={{
                  "aria-label": "Domain template id",
                }}
                type="text"
                disabled
                value={domainData ? domainData.template_id : ""}
              />

              <InputLabel htmlFor="template-version">
                Template Version
              </InputLabel>

              <Select
                inputProps={{
                  name: "template-version",
                  id: "template-version",
                }}
                value={selectedDomainTemplateVersion}
                onChange={event => {
                  setSelectedDomainTemplateVersion(
                    event.target.value as string
                  );
                }}
              >
                {domainData?.upgradableVersions.map((version: any) => (
                  <MenuItem value={version} key={version}>
                    <em>{version}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />

            <br />
            <br />
            <Button
              variant="text"
              classes={{
                root: classes.eTypeDone,
                disabled: classes.disabled,
              }}
              className={`${testClassNames.BUDDY_CREATE_BUTTON}`}
              type="submit"
              disabled={
                !(
                  domainData &&
                  domainData.template_version !== selectedDomainTemplateVersion
                )
              }
              onClick={() => {
                if (selectedDomainTemplateVersion && domainData) {
                  handleUpdateNewAssistantDomain({
                    newTemplateVersion: selectedDomainTemplateVersion,
                    templateID: domainData.template_id,
                    domainID: domainData.id,
                  });
                }
              }}
            >
              {"Update Assistant Domain"}
            </Button>
            <br />
            <br />
            <Button
              variant="outlined"
              className={`${classes.cancelButton} ${testClassNames.BUDDY_CANCEL_BUTTON}`}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
        <DialogActions />
      </div>
    </Dialog>
  );
};

export default UpdateAssistantDomainDialog;
