import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../withTheme";

import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";
import AppConfig from "../../app.config";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { styles } from "./styles.js";
import TopNav from "../common/topNav";
import SideNav from "../common/sideNav";
// [TmpChange] D2019 remove pricing temporarily update business update
// import PlanDetails from "./components/PlanDetails";
// import AccountPlan from "./components/AccountPlan";
import Usage from "./components/Usage";
import UserDetails from "./components/UserDetails";
import { GetSetAppMetadata } from "../../utils/auth/handleAuthState";

import {
  getAccountProfile,
  createUser,
  getAccountPlan,
  getAllPlans,
  getAccountSessionsUsage,
  updateAccountProfile,
  updateAccountPlan,
} from "../../redux/actions/user";
import { getAPI } from "../../redux/actions/apikey";
import { initialState } from "../../redux/reducers/user";
import { changePassword } from "../../redux/actions/login";
import {
  logPageVisited,
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";

const defaultPlan = AppConfig.defaultPlan;

const mapDispatchToProps = dispatch => ({
  createUser: payload => dispatch(createUser(payload)),
  getAccountProfile: payload => dispatch(getAccountProfile(payload)),
  initUser: () => {
    const payload = initialState();
    dispatch(getAccountPlan({}));
    dispatch(getAccountSessionsUsage({}));
    dispatch(getAccountProfile(payload));
    dispatch(getAPI());
  },
  updateAccountProfile: payload => dispatch(updateAccountProfile(payload)),
  updateAccountPlan: payload => dispatch(updateAccountPlan(payload)),
  getAllPlans: payload => dispatch(getAllPlans(payload)),
  changePassword: pass => dispatch(changePassword(pass)),
});

const DarkMode = localStorage.getItem("U_DARKMODE")
  ? localStorage.getItem("U_DARKMODE") === "true"
  : false;

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dialogTitle: "",
      DARKMODE: DarkMode,
      login: false,
      isLoading: false,
      isChanging: false,
      error: false,
      isError: false,
      allPlans: this.props.allPlans
        ? this.props.allPlans
        : {
            TRIAL: defaultPlan,
          },
      currentPlan: defaultPlan,
      isProfileUpdated: false,
      userIntents: {
        current_free_intents: 0,
        current_intents: 0,
      },
      userTransactions: {
        current_plan_limit: 0,
        end_date: "31-10-2018",
        inferences: 0,
        start_date: "20-10-2018",
      },
      cur_month_trans_count: AppConfig.maxTransactionsPerMonth,
      notify: { variant: "info", message: "" },
      billingInfo: {
        email_id: this.props.emailid,
        owner_name: "",
        company_name: "",
      },
    };
  }

  handleSwitchDARKMODE = event => {
    this.setState({
      DARKMODE: event.target.checked,
      isLoading: true,
    });
    localStorage.setItem("U_DARKMODE", event.target.checked.toString());
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  componentDidMount() {
    const orgID = GetSetAppMetadata().organization_id;
    localStorage.setItem("U_orgID", orgID);
    this.props.initUser();
    logPageVisited("user_details");
  }

  checkError = new RegExp("this org_id does not exist");

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (
        prevProps.errorUser !== this.props.errorUser &&
        this.props.errorUser
      ) {
        if (this.checkError.test(this.props.errorUser)) {
          this.props.createUser({ email_id: this.props.emailid });
        }
      }

      if (prevProps.profile !== this.props.profile && this.props.profile) {
        this.setState({
          billingInfo: this.props.profile,
        });
      }
      if (prevProps.allPlans !== this.props.allPlans && this.props.allPlans) {
        this.setState({
          allPlans: this.props.allPlans,
        });
      } else if (!this.props.allPlans) {
        this.setState({
          allPlans: {
            TRIAL: defaultPlan,
          },
        });
      }
      if (
        prevProps.userIntents !== this.props.userIntents &&
        this.props.userIntents
      ) {
        this.setState({
          userIntents: this.props.userIntents,
        });
      } else if (!this.props.userIntents) {
        this.setState({
          userIntents: {
            current_free_intents: 0,
            current_intents: 0,
          },
        });
      }
      if (
        prevProps.userTransactions !== this.props.userTransactions &&
        this.props.userTransactions
      ) {
        this.setState({
          userTransactions: this.props.userTransactions,
        });
      } else if (!this.props.userTransactions) {
        this.setState({
          userTransactions: {
            current_plan_limit: 0,
            end_date: "31-10-2018",
            inferences: 0,
            start_date: "20-10-2018",
          },
        });
      }
      if (
        prevProps.currentPlan !== this.props.currentPlan &&
        this.props.currentPlan
      ) {
        this.setState({
          currentPlan: this.props.currentPlan,
          // currentPlan: "TRIAL"
        });
      } else if (!this.props.currentPlan) {
        this.setState({
          // currentPlan: "free"
          currentPlan: defaultPlan,
        });
      }

      if (prevProps.error !== this.props.error && this.props.error !== "") {
        this.setState({
          isLoading: false,
          notify: {
            variant: "error",
            message: this.props.error,
          },
        });
      }
      if (prevProps.apikey !== this.props.apikey && this.props.apikey !== "") {
        this.setState({ isLoading: false, error: false });
      }

      if (this.props.isChanged) {
        this.setState({
          isChanging: false,
          notify: {
            variant: "success",
            message: "Password changed!",
          },
        });
      }
    } else {
      if (this.props.error && this.state.isLoading) {
        this.setState({
          isLoading: false,
          notify: {
            variant: "error",
            message: this.props.error,
          },
        });
      }
    }

    if (prevState !== this.state) {
    } else {
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleEditPassword = () => {
    this.setState({
      open: true,
    });
  };

  copytoClip = event => {
    event.preventDefault();
    this.setState({
      notify: {
        variant: "info",
        message: "API key copied to clipboard",
      },
    });
    logEvent(SlangSeverityLevels.INFO, SlangEvents.APIKEY_COPIED);
  };

  updateBillingInfo = (newValues, newPlan = null) => {
    if (newPlan) {
      // const sendChangedPlan = {
      //   new_plan: this.props.allPlans[newPlan],
      //   billing_email: newValues.email,
      //   primary_email: this.props.emailid,
      //   phone_number: newValues.phone_number
      // };
      // this.props.updateAccountProfile({ profile: sendChangedPlan });
      // this.props.updateAccountPlan({ selectedPlan: sendChangedPlan });
    } else {
      this.props.updateAccountProfile({ profile: newValues });
    }
    // this.setState({
    //   billingInfo: newValues
    // });
  };

  render() {
    const {
      classes,
      match,
      emailid,
      apikey,
      errorUser,
      // userSessions
      // errorPlans,
      // isPlanUpdated
    } = this.props;
    const { updateBillingInfo } = this;
    const {
      isLoading,
      notify,
      DARKMODE,
      billingInfo,
      // allPlans,
      // currentPlan,
      isProfileUpdated,
      // userIntents,
      // userTransactions
    } = this.state;

    const userDetailsProps = {
      billingInfo,
      updateBillingInfo,
      errorUser,
      isProfileUpdated,
    };
    // These are temp changes to show UI.
    // TODO: create new component to handle this view
    // const planDetailsProps = {
    //   isPlanUpdated,
    //   billingInfo,
    //   updateBillingInfo,
    //   allPlans,
    //   currentPlan,
    //   isProfileUpdated,
    //   userIntents,
    //   userTransactions,
    //   errorUser,
    //   errorPlans,
    //   isLoading: allPlans.free && !errorPlans ? true : false
    // };
    // const accountPlanProps = {
    //   classes,
    //   userSessions,
    //   currentPlan
    // };

    return (
      <div className={classes.appFrame}>
        <SideNav currentPage="user" appname={match.params.appname} />
        <main className={classes.content}>
          <TopNav notify={notify} />
          <Fade in={true}>
            <div className={classes.margin}>
              <Grid
                justify="flex-start"
                direction="row"
                alignItems="flex-start"
                container
              >
                <Grid xs={6} item>
                  <Typography
                    variant="h5"
                    classes={{ h5: classes.typeFont }}
                    style={{ textAlign: "left", marginBottom: 20 }}
                    noWrap
                  >
                    ACCOUNT
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography
                    variant={"button"}
                    style={{ display: "inline-flex" }}
                  >
                    API KEY: &nbsp;&nbsp;
                  </Typography>
                  {apikey ? (
                    <Tooltip title="Copy to clipboard">
                      <CopyToClipboard text={apikey}>
                        <span>
                          <a href="/copyme" onClick={this.copytoClip}>
                            {apikey}
                          </a>
                        </span>
                      </CopyToClipboard>
                    </Tooltip>
                  ) : (
                    <Typography>
                      Not found contact
                      <a
                        href={
                          "mailto:42@slanglabs.in?subject=Unable to find my API KEY&body=My Slang Account: " +
                          emailid +
                          "Please send me an API KEY "
                        }
                      >
                        42@slanglabs.in
                      </a>
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Grid container className={classes.margin}>
                  <Grid
                    justify="flex-start"
                    direction="row"
                    alignItems="center"
                    container
                    className={classes.margin}
                  >
                    {/* <PlanDetails {...planDetailsProps} /> */}
                    {/* <AccountPlan {...accountPlanProps} /> */}
                    <Usage />
                    <UserDetails {...userDetailsProps} />
                    <Paper className={classes.paper} elevation={0}>
                      <Grid item container xs={12} className={classes.space}>
                        <Grid item xs={4}>
                          <Typography
                            style={{ paddingTop: 15 }}
                            className={classes.sideHeading}
                          >
                            DARK MODE
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Switch
                            name="DARKMODE"
                            checked={DARKMODE}
                            value="DARKMODE"
                            onChange={this.handleSwitchDARKMODE}
                            color="primary"
                          />
                          {isLoading && <CircularProgress size={24} />}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </main>
      </div>
    );
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    emailid: state.login.username,
    isChanged: state.login.isChanged,
    profile: state.user.profile,
    allPlans: state.user.allPlans,
    currentPlan: state.user.currentPlan,
    isProfileUpdated: state.user.isProfileUpdated,
    isPlanUpdated: state.user.isPlanUpdated,
    userIntents: state.user.userIntents,
    userTransactions: state.user.userTransactions,
    userSessions: state.user.userSessions,
    errorUser: state.user.error,
    errorPlans: state.user.errorPlans,
    error: state.apikey.error,
    apikey: state.apikey.api_key,
    isAuth: state.login.isAuth,
    identity: state.login.identity,
    appID: state.appSchema.appDATA.id,
    allUserState: state.user,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(User)));
