import { Component } from "react";
import PropTypes from "prop-types";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
  grayText: {
    color: theme.palette.grey[500],
  },
  root: {
    width: "inherit",
    marginRight: theme.spacing(3),
  },
  badge: {
    top: 5,
    right: 10,
    width: "auto",
    minWidth: "22px",
    cursor: "pointer",
    borderRadius: theme.spacing(0.95),
    paddingLeft: theme.spacing(0.55),
    paddingRight: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
});
const severityValues = [
  { value: 1, text: "Level 1" },
  { value: 2, text: "Level 2" },
  { value: 3, text: "Level 3" },
];

class CustomizedBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = event => {
    // special logic to check if the clicked element is the badge itself or an enclosed element.
    if (
      event.target.nodeName === "SPAN" &&
      event.target.childNodes.length === 1
    ) {
      this.setState({ anchorEl: event.target });
    }
  };

  handleClose = event => {
    const severity = event.target.value;
    if (severity) {
      this.props.handleChangeSeverity(this.props.idx, severity);
    }
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, children, badgeContent } = this.props;
    const mappedSeverity = severityValues.filter(
      item => item.value === badgeContent
    );
    const badgeContentMap = mappedSeverity.length
      ? mappedSeverity[0].text
      : badgeContent;
    return (
      <>
        <Badge
          badgeContent={badgeContentMap}
          color="secondary"
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          classes={{ badge: classes.badge, root: classes.root }}
          children={children}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem className={classes.grayText}>Escalation: </MenuItem>
          {severityValues.map((item, key) => (
            <MenuItem onClick={this.handleClose} key={key} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

CustomizedBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  badgeContent: PropTypes.node.isRequired,
};

CustomizedBadge.defaultProps = {
  children: <span>Some text</span>,
  badgeContent: 10,
};

export default withStyles(styles)(CustomizedBadge);
