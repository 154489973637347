import * as types from "../types";

const domainList = sessionStorage.getItem("A_domainList")
  ? JSON.parse(sessionStorage.getItem("A_domainList"))
  : [];

export const initialState = () => ({
  listFull: domainList,
  error: null,
});

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.DOMAIN_LIST_FULL:
      return {
        ...state,
        listFull: action.payload.listFull,
        error: action.payload.error,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
