import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Grid,
  Divider,
  TextField,
  Button,
  Chip,
} from "@material-ui/core/";
import { Check, AddCircleOutline } from "@material-ui/icons";
import { DomainIcons, SubDomainIcons } from "../../../../utils/createAsstUtils";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    margin: "0 auto",
    justifyContent: "center",
  },
  image: {
    position: "relative",
    maxWidth: 200,
    height: 180,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
        border: "4px solid currentColor",
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        // border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {
    marginRight: 10,
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "81%",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.black,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center 0%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    // backgroundColor: theme.palette.common.black,
    borderRadius: 10,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    color: theme.palette.text.primary,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  createInput: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  hintText: {
    margin: theme.spacing(1),
    minWidth: 500,
  },
  promptPaper: {
    width: "100%",
    margin: theme.spacing(2),
    padding: theme.spacing(1.5),
    textAlign: "left",
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1.5),
  },
  section: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  domainButtonGroup: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(1),
  },
  domainButton: {
    margin: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chip1: {
    margin: theme.spacing(1),
  },
});

function ChooseAssistant(props) {
  const {
    classes,
    selectedAssistant,
    onSelectedAssistantType,
    domains,
    createError,
    handleChangeAppName,
    createAppName,
    SubDomains,
    handleChangeSD,
    isChosenSubDomain,
  } = props;

  return (
    <>
      <div>
        <Paper elevation={0} className={classes.promptPaper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h5" gutterBottom className={classes.chip}>
                Select your app domain
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={7}>
              <div className={classes.section}>
                <Typography variant="button">Select Domain</Typography>
                <div className={classes.container}>
                  {domains.map((domain, ID) => (
                    <Button
                      key={ID + domain.name}
                      onClick={e => onSelectedAssistantType(ID)}
                      variant={
                        selectedAssistant === ID ? "contained" : "outlined"
                      }
                      color={selectedAssistant === ID ? "primary" : "default"}
                      size="small"
                      className={classes.chip1}
                    >
                      {DomainIcons[domain.name]} &nbsp; &nbsp;
                      {domain.display_name}
                    </Button>
                  ))}
                </div>
              </div>
              <div className={classes.section}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.chip}
                >
                  Select SubDomains
                </Typography>
                <div className={classes.container}>
                  {Object.keys(SubDomains).map((SD, key) => {
                    if (SubDomains[SD].displayName.trim() !== "Common Datasets")
                      return (
                        <Chip
                          key={SD + key}
                          label={`${SubDomains[SD].displayName}`}
                          color={SubDomains[SD].enabled ? "primary" : "default"}
                          onClick={() =>
                            handleChangeSD(SD, {
                              target: {
                                checked: !SubDomains[SD].enabled,
                              },
                            })
                          }
                          clickable
                          className={classes.chip1}
                          variant={
                            SubDomains[SD].enabled ? "default" : "outlined"
                          }
                          onDelete={() =>
                            handleChangeSD(SD, {
                              target: {
                                checked: !SubDomains[SD].enabled,
                              },
                            })
                          }
                          deleteIcon={
                            SubDomains[SD].enabled ? (
                              <Check />
                            ) : (
                              <AddCircleOutline />
                            )
                          }
                          icon={SubDomainIcons[SD]}
                        />
                      );
                  })}
                  {domains[selectedAssistant] &&
                  domains[selectedAssistant].name === "retail" ? (
                    <div className={classes.container}>
                      <Chip
                        label={"Fashion (Coming Soon)"}
                        className={classes.chip1}
                        variant={"outlined"}
                        icon={SubDomainIcons.fashion}
                      />
                      <Chip
                        label={"FMCG (Coming Soon)"}
                        className={classes.chip1}
                        variant={"outlined"}
                        icon={SubDomainIcons.fmcg}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={0} className={classes.promptPaper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h5" gutterBottom className={classes.chip}>
                What is your app name?
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={7}>
              <div className={classes.container}>
                <TextField
                  autoFocus
                  variant="outlined"
                  id="create-app-name"
                  name="createAppName"
                  label="App Name"
                  pattern="[A-Za-z0-9.]+"
                  size="small"
                  className={classes.createInput}
                  onChange={handleChangeAppName}
                  value={createAppName}
                  required
                  error={createError}
                />
                <Typography
                  variant="caption"
                  color="textPrimary"
                  paragraph
                  className={classes.hintText}
                >
                  {
                    "Unique name; e.g. app.travel.com or My Grocery app V1 (miminum 3 characters)"
                  }
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}

ChooseAssistant.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChooseAssistant);
