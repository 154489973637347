import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

import BadgeWrapper from "../../common/BadgeWrapper";
import { emptyStatePrompts } from "../../../utils/promptUtils";
import { validatePromptTextLanguage } from "../../../utils/validate";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import theme from "../../theme";

import AppConfig from "../../../app.config";
import teal from "@material-ui/core/colors/teal";
import Color from "color";

const Langs = AppConfig.LANGUAGES;

const styles = theme => ({
  root: {
    width: "100%",
  },
  listWidth: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionInputList: {
    ...theme.shape,
    backgroundColor: theme.palette.background.default,
  },
  values: {
    marginRight: 75,
    fontSize: "0.9rem",
    fontWeight: 500,
    "& input": {
      fontSize: "0.9rem",
      padding: 2,
    },
  },
});

class ExpansionPrompts extends Component {
  state = {
    expanded: null,
    PromptValues: this.props.PromptValues,
    editLevel: 1,
    error: true,
    langPrompts: emptyStatePrompts() || {},
  };

  handleSeverityChange = (val, severity) => {
    this.setState({ editLevel: severity });
  };

  componentDidMount() {
    this.setState({
      PromptValues: this.props.PromptValues,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        expanded: this.props.expand
          ? this.props.expand === "ON"
            ? "panel1"
            : null
          : "panel1",
        PromptValues: this.props.PromptValues,
      });
    }
  }

  handleOnChangePrompt = event => {
    const langCode = this.props.lang;
    const text = event.target.value;
    const name = event.target.name;
    const OK = validatePromptTextLanguage(event.target.value, "en");
    this.setState(state => {
      const langPrompts = state.langPrompts;
      return {
        langPrompts: {
          ...langPrompts,
          [langCode]: {
            ...langPrompts[langCode],
            [name]: text,
          },
        },
        error: !OK,
      };
    });
  };

  updateNew = prompt => {
    const event = {
      target: { name: "", value: "", severity: this.state.editLevel },
    };
    const langCode = this.props.lang;
    const len = this.props.PromptValues[prompt]
      ? this.props.PromptValues[prompt].length
      : 0;
    event.target.name = this.props.promptName + "." + prompt;
    event.target.value = this.state.langPrompts[langCode][prompt].trim();
    this.props.onChangeText(event, len);

    this.setState({
      langPrompts: emptyStatePrompts(),
      editLevel: 1,
      error: true,
    });
  };

  handleOnClickNew = name => e => {
    this.updateNew(name);
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleOnKeyPress = (event, name) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (name !== "" && !this.state.error) {
        this.updateNew(name);
      }
    }
  };

  render() {
    const {
      classes,
      onChangeText,
      PromptValues,
      lang,
      handleDelete,
      promptName,
      HELPTEXT,
      handleChangeSeverity,
      promptsExample,
    } = this.props;
    const { error, langPrompts, editLevel } = this.state;
    const questionTemplates = PromptValues.questionTemplates
      ? PromptValues.questionTemplates.map((item, key) => ({
          ...item,
          idc: key,
        }))
      : [];
    // const affirmativeResponseTemplates = PromptValues.affirmativeResponseTemplates
    //   ? PromptValues.affirmativeResponseTemplates
    //   : []
    // const negativeResponseTemplates = PromptValues.negativeResponseTemplates
    //   ? PromptValues.negativeResponseTemplates
    //   : []
    // const enEqual = EN ? EN.questionTemplates : [];
    const langText = Langs.filter(item => item.code === lang)[0].text;

    const equalHelpText = idc => {
      const langEqualText =
        promptsExample && promptsExample[promptName].questionTemplates;
      const check =
        langEqualText && langEqualText[idc] && langEqualText[idc].value !== "";
      return check
        ? `"${langEqualText[idc].value}" in ${langText} `
        : `${langText}  text for this prompt`;
    };
    const questionTemplatesPrompt = langPrompts[lang].questionTemplates;
    const bgColorList = severity =>
      theme.palette.type === "light"
        ? Color(teal[severity * 100])
            .fade(0.8)
            .string()
        : Color(teal[(9 - severity * 2) * 100])
            .fade(0.6)
            .string();
    return (
      <div className={classes.root}>
        <Accordion expanded={true} defaultExpanded>
          <AccordionSummary>
            <Typography variant="h5" className={classes.heading} gutterBottom>
              Question Prompts
            </Typography>
            <Typography className={classes.secondaryHeading} />
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.listWidth}>
              <List>
                <ListItem className={classes.expansionInputList}>
                  <BadgeWrapper
                    handleChangeSeverity={this.handleSeverityChange}
                    idx={
                      promptName +
                      ".questionTemplates." +
                      questionTemplates.length
                    }
                    badgeContent={editLevel}
                  >
                    <TextField
                      name="questionTemplates"
                      value={questionTemplatesPrompt}
                      onChange={this.handleOnChangePrompt}
                      onKeyPress={e => {
                        this.handleOnKeyPress(e, "questionTemplates");
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={`${HELPTEXT.PLACEHOLDER_EN} in: ${langText}`}
                      helperText={HELPTEXT.EXAMPLE_EN}
                      className={classes.values}
                      type="text"
                      fullWidth
                      disabled={this.props.isRequired}
                      margin="dense"
                    />
                  </BadgeWrapper>
                  <ListItemSecondaryAction style={{ marginLeft: 5 }}>
                    <Fab
                      aria-label="Add"
                      color="primary"
                      style={{ height: 40, width: 40 }}
                      className={`${testClassNames.APP_PROMPT_ADD_BUTTON}`}
                      onClick={this.handleOnClickNew("questionTemplates")}
                      disabled={this.props.isRequired || error}
                    >
                      <Add />
                    </Fab>
                  </ListItemSecondaryAction>
                </ListItem>
                {questionTemplates
                  .sort((a, b) => {
                    return a.severity - b.severity;
                  })
                  .map((item, key) => (
                    <ListItem
                      key={key}
                      style={{
                        width: "100%",
                        backgroundColor: bgColorList(item.severity),
                      }}
                    >
                      <BadgeWrapper
                        handleChangeSeverity={handleChangeSeverity}
                        idx={promptName + ".questionTemplates." + item.idc}
                        badgeContent={item.severity}
                      >
                        <TextField
                          name={promptName + ".questionTemplates"}
                          placeholder={equalHelpText(item.idc)}
                          value={item.value}
                          onChange={e => {
                            onChangeText(e, item.idc);
                          }}
                          onKeyPress={e => {
                            this.handleOnKeyPress(e, "");
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.values}
                          type="text"
                          margin="dense"
                          fullWidth
                          required
                        />
                      </BadgeWrapper>
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete"
                          className={`${testClassNames.APP_PROMPT_CLARIFICATION_DELETE_BUTTON}`}
                          onClick={handleDelete.bind(
                            this,
                            "questionTemplates",
                            promptName,
                            item.idc
                          )}
                        >
                          <Remove />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

ExpansionPrompts.propTypes = {
  classes: PropTypes.object.isRequired,
  PromptValues: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpansionPrompts);
