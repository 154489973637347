import MD5 from "blueimp-md5";
import { deleteKeyFromObject, deleteKeyArray } from "./helpers";
import AppConfig from "../app.config";

const Langs = AppConfig.LANGUAGES;

export const randID = (text = (Math.random() * Math.random()).toString()) => {
  const id = MD5(Math.floor(Math.random() * Date.now()).toString() + text);
  return id;
};

export const extractStringHelp = (sentenceData, Lang, strTable) => {
  let strObj = {};

  const sentence = item => {
    if (item.isId) {
      return {
        id: item.value,
        value: strTable[item.value][Lang] ? strTable[item.value][Lang] : "",
      };
    }
    return { value: item.value ? item.value : "" };
  };

  const getStr = data => {
    const newData = data.map(item => {
      return sentence(item);
    });
    return newData;
  };

  if (sentenceData.utterances) {
    const sentences =
      sentenceData.utterances && getStr(sentenceData.utterances);

    strObj = sentences;
  } else if (sentenceData.helpDescription) {
    const item = sentenceData.helpDescription;
    strObj = sentence(item);
  }
  return strObj;
};

export const checkEmptyAppPromptsNew = (oldAppData, allPromptTypes) => {
  const appData = { ...oldAppData, ...allPromptTypes };
  Object.keys(allPromptTypes).forEach(promptName => {
    Object.keys(allPromptTypes[promptName]).forEach(template => {
      if (!Object.keys(allPromptTypes[promptName][template]).length) {
        if (appData[promptName] && appData[promptName][template])
          delete appData[promptName][template];
      }
    });
    if (!(appData[promptName] && Object.keys(appData[promptName]).length)) {
      if (appData[promptName]) delete appData[promptName];
    }
  });
  return appData;
};

export const prepareHelpDescription = (appData, allPromptTypes, strData) => {
  const helpDescription = appData.help && appData.help.helpDescription;
  const newAppData = checkEmptyAppPromptsNew(appData, allPromptTypes);
  let editAppData = {};

  if (helpDescription && appData.help.helpDescription.isId) {
    const ID = helpDescription.value;
    let checkEmpty = true;
    Langs.forEach(lang => {
      if (
        checkEmpty &&
        strData[ID] &&
        strData[ID][lang.code] &&
        strData[ID][lang.code] !== ""
      ) {
        checkEmpty = false;
      }
    });
    if (checkEmpty) {
      const newStrData = deleteKeyFromObject(strData, ID);
      const newHelp = deleteKeyFromObject(appData.help, "helpDescription");
      editAppData = { ...newAppData, help: newHelp };
      editAppData =
        editAppData.help.utterances && editAppData.help.utterances.length
          ? editAppData
          : deleteKeyFromObject(appData, "help");

      return { appData: editAppData, strData: newStrData };
    }
  }

  return { appData: newAppData, strData };
};

export const completeHelpState = (
  appData,
  strData,
  languagePrompts,
  allPromptTypes
) => {
  const newStrData = strData;

  Object.keys(languagePrompts).forEach(lang => {
    Object.keys(languagePrompts[lang]).forEach(type => {
      Object.keys(languagePrompts[lang][type]).forEach(template => {
        if (template === "utterances") {
          languagePrompts[lang][type][template].forEach((item, n) => {
            let tmpID;
            if (item.id) {
              tmpID = item.id;
              newStrData[tmpID] = {
                ...newStrData[tmpID],
                [lang]: item.value ? item.value : "",
              };
            } else if (allPromptTypes[type][template][n]) {
              tmpID = allPromptTypes[type][template][n].value;
              newStrData[tmpID] = {
                ...newStrData[tmpID],
                [lang]: item.value ? item.value : "",
              };
            } else {
              tmpID = randID(item.value);
              newStrData[tmpID] = {
                ...newStrData[tmpID],
                [lang]: item.value ? item.value : "",
              };
            }
            allPromptTypes[type][template][n] = {
              value: tmpID,
              isId: true,
            };
          });
        } else if (
          template === "helpDescription" &&
          allPromptTypes[type][template]
        ) {
          let tmpID;
          const newItem = languagePrompts[lang][type][template];
          if (newItem.id) {
            tmpID = newItem.id;
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: newItem.value,
            };
            allPromptTypes[type][template] = {
              value: tmpID,
              isId: true,
            };
          } else if (allPromptTypes[type][template].value) {
            tmpID = allPromptTypes[type][template].value;
            newStrData[tmpID] = {
              ...newStrData[tmpID],
              [lang]: newItem.value,
            };
            allPromptTypes[type][template] = {
              value: tmpID,
              isId: true,
            };
          } else {
            if (newItem.value) {
              tmpID = randID(newItem.value);
              newStrData[tmpID] = {
                ...newStrData[tmpID],
                [lang]: newItem.value,
              };
              allPromptTypes[type][template] = {
                value: tmpID,
                isId: true,
              };
            }
          }
        }
      });
    });
  });
  const preparedHelp = prepareHelpDescription(
    appData,
    allPromptTypes,
    newStrData
  );

  return { appData: preparedHelp.appData, strData: preparedHelp.strData };
};

export const emptyLangIntentHelpState = () => {
  const languageState = {};
  const promptTemplate = {
    help: {
      helpDescription: {},
      utterances: [],
    },
  };
  Langs.forEach((item, key) => {
    languageState[item.code] = promptTemplate;
  });
  return languageState;
};

export const readHelpPromptState = (intentData, stringData) => {
  const promptLanguageState = {};
  const help = intentData?.help
    ? intentData.help
    : {
        helpDescription: {},
        utterances: [],
      };
  const strTable = stringData;

  const helpDescription =
    help.helpDescription && help.helpDescription.value
      ? help.helpDescription.value
      : { value: "" };
  const helpUtterances =
    help.utterances && help.utterances.length ? help.utterances[0].value : null;

  Langs.forEach((lang, key) => {
    promptLanguageState[lang.code] = {
      help: {
        helpDescription:
          helpDescription && strTable[helpDescription]
            ? extractStringHelp(
                { helpDescription: help.helpDescription },
                lang.code,
                strTable
              )
            : {},
        utterances:
          helpUtterances && strTable[helpUtterances]
            ? extractStringHelp(
                { utterances: help.utterances },
                lang.code,
                strTable
              )
            : [],
      },
    };
  });
  return promptLanguageState;
};

export const editHelpDescriptionState = (
  oldLanguagePrompts,
  lang,
  name,
  key,
  value
) => {
  let languagePrompts = oldLanguagePrompts;
  const typePrompts = name.split(".");
  const isNew = !languagePrompts[lang][typePrompts[0]][typePrompts[1]].id;
  Langs.forEach(item => {
    if (item.code === lang) {
      if (isNew) {
        languagePrompts = {
          ...languagePrompts,
          [lang]: {
            ...languagePrompts[lang],
            [typePrompts[0]]: {
              ...languagePrompts[lang][typePrompts[0]],
              [typePrompts[1]]: {
                ...languagePrompts[lang][typePrompts[0]][typePrompts[1]],
                value,
              },
            },
          },
        };
      } else {
        const changedPrompt =
          languagePrompts[lang][typePrompts[0]][typePrompts[1]];
        changedPrompt.value = value || "";
        languagePrompts = {
          ...languagePrompts,
          [lang]: {
            ...languagePrompts[lang],
            [typePrompts[0]]: {
              ...languagePrompts[lang][typePrompts[0]],
              [typePrompts[1]]: changedPrompt,
            },
          },
        };
      }
    } else {
      if (isNew) {
        languagePrompts = {
          ...languagePrompts,
          [item.code]: {
            ...languagePrompts[item.code],
            [typePrompts[0]]: {
              ...languagePrompts[item.code][typePrompts[0]],
              [typePrompts[1]]: {
                ...languagePrompts[item.code][typePrompts[0]][typePrompts[1]],
                value: "",
              },
            },
          },
        };
      } else {
        languagePrompts = {
          ...languagePrompts,
          [item.code]: {
            ...languagePrompts[item.code],
            [typePrompts[0]]: {
              ...languagePrompts[item.code][typePrompts[0]],
              [typePrompts[1]]: {
                ...languagePrompts[item.code][typePrompts[0]][typePrompts[1]],
                value: languagePrompts[item.code][typePrompts[0]][
                  typePrompts[1]
                ].value
                  ? languagePrompts[item.code][typePrompts[0]][typePrompts[1]]
                      .value
                  : "",
              },
            },
          },
        };
      }
    }
  });

  return languagePrompts;
};

export const editHelpUtteranceState = (
  oldLanguagePrompts,
  lang,
  name,
  key,
  value,
  isNew = false
) => {
  let languagePrompts = oldLanguagePrompts;
  const typePrompts = name.split(".");
  Langs.forEach(item => {
    if (item.code === lang) {
      if (isNew) {
        languagePrompts = {
          ...languagePrompts,
          [lang]: {
            ...languagePrompts[lang],
            [typePrompts[0]]: {
              ...languagePrompts[lang][typePrompts[0]],
              [typePrompts[1]]: [
                ...languagePrompts[lang][typePrompts[0]][typePrompts[1]],
                { value: value || "" },
              ],
            },
          },
        };
      } else {
        const changedPrompt =
          languagePrompts[lang][typePrompts[0]][typePrompts[1]];
        changedPrompt[key].value = value || "";
        languagePrompts = {
          ...languagePrompts,
          [lang]: {
            ...languagePrompts[lang],
            [typePrompts[0]]: {
              ...languagePrompts[lang][typePrompts[0]],
              [typePrompts[1]]: changedPrompt,
            },
          },
        };
      }
    } else {
      if (isNew) {
        languagePrompts = {
          ...languagePrompts,
          [item.code]: {
            ...languagePrompts[item.code],
            [typePrompts[0]]: {
              ...languagePrompts[item.code][typePrompts[0]],
              [typePrompts[1]]: [
                ...languagePrompts[item.code][typePrompts[0]][typePrompts[1]],
                { value: "" },
              ],
            },
          },
        };
      }
    }
  });
  return languagePrompts;
};

export const writeHelpPromptState = (appData, strData, languagePrompts) => {
  const allPromptTypes = {
    help: {
      helpDescription: {},
      utterances: [],
    },
  };

  const newData = completeHelpState(
    appData,
    strData,
    languagePrompts,
    allPromptTypes
  );

  return newData;
};

export const deleteIntentHelpState = (
  languagePrompts,
  strData,
  template,
  promptType,
  key
) => {
  let newStrData;
  const newLanguagePrompts = languagePrompts;
  const stringID = languagePrompts["en-IN"][promptType][template][key].id
    ? languagePrompts["en-IN"][promptType][template][key].id
    : null;
  Langs.forEach(lang => {
    if (key > -1) {
      newLanguagePrompts[lang.code][promptType][template] = deleteKeyArray(
        languagePrompts[lang.code][promptType][template],
        key
      );
    }
  });
  if (stringID) {
    newStrData = deleteKeyFromObject(strData, stringID);
    return { hasStringID: stringID, newLanguagePrompts, strData: newStrData };
  }
  return { hasStringID: null, newLanguagePrompts, strData };
};
