import theme from "../../theme";
import Color from "color";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import green from "@material-ui/core/colors/green";

import { colourEntity } from "../../../utils/helpers";
const colorSuccess = Color(theme.palette.primary.medium);
const colorFailure = Color(theme.palette.secondary.medium);

export const sortValue = (
  items,
  type = "count",
  toggle = true,
  sortField = "text",
  all = true
) => {
  const allToTop = items => {
    const newItems = items.filter(
      item => item.text.indexOf("<All>") > -1 && item.text.length === 5
    );
    const newItems2 = items.filter(
      item => item.text.indexOf("<All>") < 0 && item.text.length !== 5
    );
    return [...newItems, ...newItems2];
  };

  if (type === "count") {
    // sort by value
    items.sort((a, b) => {
      if (toggle) {
        return b.count - a.count;
      }
      return a.count - b.count;
    });

    if (all) return allToTop(items);

    return items;
  }
  // sort by name
  items.sort((a, b) => {
    const nameA = a[sortField].toUpperCase();
    const nameB = b[sortField].toUpperCase();
    if (toggle) {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }

    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    // names must be equal
    return 0;
  });

  if (all) return allToTop(items);

  return items;
};

export const createChartData = BQData => {
  const monthlyUsageChartData = { labels: [], datasets: [] };

  const allAppIds = BQData.reduce((accum, perMonth, i) => {
    monthlyUsageChartData.labels.push(perMonth.month);
    return sortValue(perMonth.apps, null, false, "name", false).reduce(
      (Acc, Cur) => {
        if (Acc[Cur.name] !== undefined) {
          return { ...Acc, [Cur.name]: Cur.msu + Acc[Cur.name] };
        }
        return { ...Acc, [Cur.name]: Cur.msu };
      },
      accum
    );
  }, {});

  const allAppIdsData = Object.keys(allAppIds).reduce((Acc, key, I) => {
    Acc[key] = BQData.map((perMonth, i) => {
      const isId = sortValue(perMonth.apps, null, false, "name", false).find(
        value => value.name === key
      );
      if (isId) return isId.msu;

      return 0;
    });
    return Acc;
  }, {});

  const arrayOfAppIDs = Object.keys(allAppIdsData);
  monthlyUsageChartData.datasets = arrayOfAppIDs
    .map((key, i) => {
      const totalMSUs = allAppIds[key];
      if (totalMSUs) {
        if (arrayOfAppIDs.length > 5) {
          const fillColor = Color(colourEntity(key).bColor);
          const borderColor = Color(colourEntity(key).fColor);
          return {
            label: key,
            data: allAppIdsData[key],
            backgroundColor: fillColor.fade(0.3).string(),
            borderColor: borderColor.fade(0.3).string(),
            borderWidth: 1,
            order: i,
            hoverBackgroundColor: fillColor.fade(0.1).string(),
            hoverBorderColor: borderColor.string(),
          };
        } else {
          const barColor = chartColorSchema(i + 1, true);
          return {
            label: key,
            data: allAppIdsData[key],
            backgroundColor: barColor.fade(0.3).string(),
            borderColor: barColor.fade(0.1).string(),
            borderWidth: 2,
            hoverBackgroundColor: barColor.fade(0.1).string(),
            hoverBorderColor: barColor.string(),
            order: i,
          };
        }
      }

      return null;
    })
    .filter(Boolean);

  const assistantChartData = Object.keys(allAppIds).reduce(
    (Acc, key, i) => {
      const barColor = chartColorSchema(i + 1, true);

      Acc.datasets[0].data[i] = allAppIds[key];
      Acc.datasets[0].backgroundColor[i] = barColor.fade(0.3).string();
      Acc.datasets[0].borderColor[i] = barColor.fade(0.1).string();
      Acc.labels[i] = key.length > 14 ? key.substr(0, 6) + "..." : key;

      return Acc;
    },
    {
      datasets: [
        {
          label: "",
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          barThickness: "flex",
        },
      ],
      labels: [],
    }
  );
  return {
    monthlyUsageChartData,
    assistantChartData,
  };
};

export const prepareBarChart = (labels, values) => {
  const newLabels = labels.length ? labels : [];
  const recognised = values.length ? values[0] : [];
  const unrecognised = values.length > 1 ? values[1] : [];
  return {
    labels: newLabels,
    datasets: [
      {
        label: "Unrecognised",
        backgroundColor: colorFailure.fade(0.9).string(),
        borderColor: colorFailure.fade(0.1).string(),
        borderWidth: 1,
        hoverBackgroundColor: colorFailure.fade(0.4).string(),
        hoverBorderColor: colorFailure.string(),
        data: unrecognised,
      },
      {
        label: "Successful",
        backgroundColor: colorSuccess.fade(0.9).string(),
        borderColor: colorSuccess.fade(0.1).string(),
        borderWidth: 1,
        hoverBackgroundColor: colorSuccess.fade(0.4).string(),
        hoverBorderColor: colorSuccess.string(),
        data: recognised,
      },
    ],
  };
};

export const prepareLineChart = (labels, values) => {
  const newLabels = labels.length ? labels : [];
  const recognised = values.length ? values[0] : [];
  const unrecognised = values.length > 1 ? values[1] : [];
  return {
    labels: newLabels,
    datasets: [
      {
        label: "Recognised",
        fill: true,
        lineTension: 0,
        backgroundColor: colorSuccess.fade(0.9).string(),
        borderColor: colorSuccess.fade(0.1).string(),
        borderWidth: 1,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: colorSuccess.fade(0.1).string(),
        pointBackgroundColor: "#fff",
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colorSuccess.fade(0.1).string(),
        pointHoverBorderColor: colorSuccess.string(),
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: recognised,
      },
      {
        label: "Unrecognised",
        fill: true,
        lineTension: 0,
        backgroundColor: colorFailure.fade(0.9).string(),
        borderColor: colorFailure.fade(0.1).string(),
        borderWidth: 1,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: colorFailure.fade(0.1).string(),
        pointBackgroundColor: "#fff",
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colorFailure.fade(0.1).string(),
        pointHoverBorderColor: colorFailure.fade(0.1).string(),
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: unrecognised,
      },
    ],
  };
};

export const chartColorSchema = (value, onlyColor) => {
  if (onlyColor) {
    switch (value % 3) {
      case 1:
        return theme.palette.type === "light"
          ? Color(green[value * 100])
          : Color(green[(9 - value) * 100]);
      case 2:
        return theme.palette.type === "light"
          ? Color(lightGreen[value * 100])
          : Color(lightGreen[(9 - value) * 100]);

      default:
        return theme.palette.type === "light"
          ? Color(teal[value * 100])
          : Color(teal[(9 - value) * 100]);
    }
  }

  return theme.palette.type === "light"
    ? Color(teal[value * 100])
        .fade(0.8)
        .string()
    : Color(teal[(9 - value * 2) * 100])
        .fade(0.6)
        .string();
};
