import { deleteKeyArray } from "./helpers";

export const validateParameters = parameters => {
  const validateRegExp = /[ ,]/g;

  let validatedParameters = [];
  parameters.forEach(item => {
    validatedParameters = [
      ...new Set([...validatedParameters, ...cleanParameters(item)]),
    ];
  });
  return validatedParameters;
};

export const cleanParameters = addToParameters => {
  // This regex checks if there are special characters in the string
  const regex = new RegExp(
    "([\u{005B}-\u{005E}\u{0060}\u{007B}-\u{007F}]+)",
    "gi"
  );
  const cleanData = addToParameters
    .split(",")
    .map(item => item.trim() !== "" && item.replace(regex, "").trim())
    .filter(Boolean)
    .map(item => {
      const newParam = item.trim().replace(/\s/gi, "_");
      return newParam.substr(0, 1) === "$" ? newParam : "$" + newParam;
    });
  return cleanData;
};

export const deleteParameters = (chipData, key) => {
  return deleteKeyArray(chipData, key);
};
