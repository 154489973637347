import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";

import { useSelector } from "react-redux";

import {
  emptyLangsHelpPromptState,
  readHelpMenuPrompts,
  editHelpDescriptionState,
  editHelpUtteranceState,
  writeHelpPromptState,
  deleteHelpPromptState,
} from "../../../../utils/helpPromptsUtil.js";
import DocHelpLink from "../../../common/DocLinks";
import HelpPromptTextField from "./HelpPromptTextField";
import LangButton from "./LangButton";
import {
  changeAppDATA,
  changeAppStringTable,
} from "../../../../redux/actions/appSchema.js";

const styles = makeStyles(theme => ({
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2.5),
    paddingBottom: 0,
    textAlign: "left",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  promptContainer: {
    padding: theme.spacing(2.5),
  },
}));

const HelpPromptsEdit = props => {
  const { appDATA, appStringTable } = useSelector(state => ({
    appDATA: state.appSchema.appDATA,
    appStringTable: state.appSchema.appStringTable,
  }));

  const { intentID } = props;

  const [lang, setLang] = useState("en-IN");
  const [helpLangPrompts, setHelpLangPrompts] = useState(
    emptyLangsHelpPromptState() || {}
  );

  const updateState = (appData = appDATA, stringTable = appStringTable) => {
    const intentHelpData = appData.intents[intentID].help;
    setHelpLangPrompts(readHelpMenuPrompts(intentHelpData, stringTable));
  };

  useEffect(() => {
    updateState();
  }, [intentID]);

  const classes = styles();

  const handleDeletePrompt = key => {
    const { hasStringID, newHelpLangPrompts, newAppStringTable } =
      deleteHelpPromptState(helpLangPrompts, appStringTable, key);

    if (hasStringID) {
      handleSave(newHelpLangPrompts, newAppStringTable);
      return;
    }

    handleSave(newHelpLangPrompts);
  };

  const handleSave = (
    newHelpLangPrompts = helpLangPrompts,
    strTable = appStringTable
  ) => {
    const { newAppData, newStringTable } = writeHelpPromptState(
      newHelpLangPrompts,
      appDATA,
      strTable,
      intentID
    );

    updateState(newAppData, newStringTable);

    changeAppStringTable({ appStringTable: newStringTable });
    changeAppDATA({ appDATA: newAppData });
  };

  const handleBlurSave = () => {
    handleSave();
  };

  const handleOnChange = (event, newKey = null, isNew = false) => {
    const { value, name } = event.target;
    let newHelpLanguagePrompts = {};
    newHelpLanguagePrompts =
      name === "helpDescription"
        ? editHelpDescriptionState(helpLangPrompts, lang, newKey, value)
        : editHelpUtteranceState(helpLangPrompts, lang, newKey, value, isNew);

    handleSave(newHelpLanguagePrompts);

    setHelpLangPrompts(newHelpLanguagePrompts);
  };

  const langButtonProps = {
    lang,
    setLang,
  };

  const helpPromptTextFieldProps = {
    lang,
    helpLangPrompts,
    handleOnChange,
    handleDeletePrompt,
    handleBlurSave,
    updateState,
  };

  return (
    <div>
      <Paper className={classes.promptPaper} elevation={0}>
        <Typography variant="h5" noWrap>
          {" Intent Help "} <DocHelpLink docFor="INTENT_HELP_TEXT" />
        </Typography>
        <br />
        <LangButton {...langButtonProps} />
        <br />
        <br />
        <div className={classes.promptContainer}>
          <HelpPromptTextField {...helpPromptTextFieldProps} />
        </div>
      </Paper>
    </div>
  );
};

export default HelpPromptsEdit;
