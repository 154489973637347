import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
// import { AssistantDomainCardPropsType } from "./AssistantDomainHomeTypes"

const AssistantDomainCards = (props: any) => {
  const { classes, handleDeleteClick, state, handleSelect } = props;

  const { domainList, isCreatingOrDeleting } = state;

  return domainList.map((item: any, key: number) => {
    const hover = "hover";
    const isLoading = isCreatingOrDeleting;
    // const pathName = Can({
    //   perform: AllPermissions.ASSISTANT_CONFIG.EDIT,
    //   yes: () => `/home/assistant_templates/${item.name}`,
    //   no: () => `/home/assistant_templates/${item.name}/analytics`,
    // });
    return (
      <Grid
        key={item.display_name + key}
        item
        xs={12}
        md={3}
        sm={4}
        lg={2}
        xl={1}
        id={key + hover}
      >
        <div className={`${classes.parentCard}`}>
          <Card
            className={`${isLoading ? classes.cardDisabled : classes.card}`}
            elevation={0}
          >
            <div onClick={() => handleSelect(item.id)}>
              <CardMedia
                className={isLoading ? classes.mediaDisabled : classes.media}
                image={"/images/graphic_assistant_template.png"}
                title={item.display_name}
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="body2" component="h4" noWrap>
                  {item.display_name}
                </Typography>
              </CardContent>
            </div>
            <span className={classes.buttonSpan}>
              <span className={isLoading ? "hide" : "hidden"}>
                {/* <Link to={isLoading ? "/home" : "/home/" + item.name}>
                  <IconButton
                    id={key + hover + 2}
                    className={classes.buttonEdit}
                    aria-label="Edit"
                    title="Edit Assistant"
                  >
                    <EditIcon id={key + hover + 3} />
                  </IconButton>
                </Link> */}
                {/* <span>
                  <IconButton
                    id={key + hover + 5}
                    className={classes.buttonClone}
                    aria-label="Clone"
                    title="Clone this Assistant"
                    onClick={
                      isLoading
                        ? null
                        : handleCloneClick.bind(this, item.id, item.name)
                    }
                  >
                    <CopyIcon id={key + hover + 5} />
                  </IconButton>
                </span> */}
                <span>
                  <IconButton
                    id={key + hover + 4}
                    className={classes.buttonDelete}
                    onClick={
                      isLoading
                        ? null
                        : handleDeleteClick.bind(
                            this,
                            item.id,
                            item.display_name
                          )
                    }
                    aria-label="Delete"
                    title="Delete Template"
                  >
                    <DeleteIcon id={key + hover + 5} />
                  </IconButton>
                </span>
              </span>
            </span>
          </Card>
        </div>
      </Grid>
    );
  });
};

export default AssistantDomainCards;
