import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  TextField,
  ListItemSecondaryAction,
  IconButton,
  Fab,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { emptyLangsHelpPromptState } from "../../../../utils/helpPromptsUtil";
import { validatePromptTextLanguage } from "../../../../utils/validate";

const styles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  listWidth: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionInputList: {
    ...theme.shape,
    backgroundColor: theme.palette.background.default,
  },
}));

const HelpExpansion = props => {
  const {
    onChangeText,
    handleDelete,
    helpLangPrompts,
    handleBlurSave,
    lang,
    HELPTEXT,
    errorTitle,
    errorUtterance,
  } = props;

  const utterances = helpLangPrompts[lang]?.utterances
    ? helpLangPrompts[lang]?.utterances
    : [];

  const [utterance, setUtterance] = useState("");
  const [helpPrompts, setHelpPrompts] = useState(helpLangPrompts);
  const [error, setError] = useState(true);

  const handleOnChangeStatement = event => {
    const text = event.target.value;
    const name = event.target.name;
    const OK = validatePromptTextLanguage(event.target.value, "en");
    setUtterance(text);
    setHelpPrompts({
      [lang]: {
        ...helpPrompts[lang],
        [name]: text,
      },
    });
    setError(!OK);
  };

  const updateNew = event => {
    const { name, value } = event.target;
    const len = helpLangPrompts[lang].utterances
      ? helpLangPrompts[lang].utterances.length
      : 0;
    event.target.name = name !== "" ? name : "utterances";
    event.target.value = value !== "" ? value.trim() : utterance.trim();

    setHelpPrompts(emptyLangsHelpPromptState());
    setError(true);

    onChangeText(event, len, true);
    setUtterance("");
  };

  const handleOnKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.name !== "") {
        updateNew(event);
      }
    }
  };

  const handleOnClickNew = event => {
    updateNew(event);
  };

  const classes = styles();

  return (
    <div className={classes.root}>
      <Accordion expanded={true} defaultExpanded>
        <AccordionSummary>
          <Typography className={classes.secondaryHeading}>
            {"Help sentences"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.listWidth}>
            <List>
              <ListItem className={classes.expansionInputList}>
                <TextField
                  style={{ marginRight: 35 }}
                  id="utterances-prompt"
                  name="utteranceInput"
                  value={utterance}
                  onChange={handleOnChangeStatement}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyPress={event => {
                    handleOnKeyPress(event);
                  }}
                  placeholder={""}
                  helperText={HELPTEXT.EXAMPLE_EN}
                  type="text"
                  fullWidth
                  disabled={errorTitle}
                  error={errorUtterance}
                />
                <ListItemSecondaryAction style={{ marginLeft: 5 }}>
                  <Fab
                    aria-label="Add"
                    color="primary"
                    style={{ height: 40, width: 40 }}
                    onClick={handleOnClickNew}
                    disabled={error}
                  >
                    <Add />
                  </Fab>
                </ListItemSecondaryAction>
              </ListItem>
              {utterances.map((item, key) => (
                <ListItem key={key} style={{ width: "100%" }}>
                  <TextField
                    style={{ marginRight: 35 }}
                    name={"utterancesEdit" + key}
                    value={item.value}
                    onChange={e => {
                      onChangeText(e, key);
                    }}
                    onBlur={handleBlurSave}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    fullWidth
                    error={item.value === ""}
                    required
                  />
                  {lang === "en-IN" ? (
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleDelete(key)}
                      >
                        <Remove />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              ))}
            </List>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default HelpExpansion;
