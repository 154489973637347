import * as types from "../types";

const asstConfigList = sessionStorage.getItem("A_asstConfigList")
  ? JSON.parse(sessionStorage.getItem("A_asstConfigList"))
  : [];

export const initialState = () => ({
  list: asstConfigList,
  error: null,
  name: null,
  template_id: null,
  template_version: null,
  isDirty: false,
  asstConfigID: null,

  asstConfigData: {},
  asstConfigStringTable: {},
});

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.ASST_CONFIG_LIST:
      return {
        ...state,
        list: action.payload.list,
        error: action.payload.error,
      };
    case types.ASST_CONFIG_CREATE:
      return {
        ...state,
        name: action.payload.name,
        template_id: action.payload.templateID,
        template_version: action.payload.templateVersion,
      };
    case types.ASST_CONFIG_UPDATE:
      return {
        ...state,
        asstConfigData: action.payload.asstConfigData,
        asstConfigID: action.payload.asstConfigID,
        asstConfigStringTable: action.payload.asstConfigStringTable,
        error: action.payload.error,
      };
    case types.ASST_CONFIG_PUBLISH:
      return {
        ...state,
        asstConfigData: action.payload.asstConfigData,
        asstConfigID: action.payload.asstConfigID,
        asstConfigStringTable: action.payload.asstConfigStringTable,
        error: action.payload.error,
      };
    case types.ASST_CONFIG_CREATE_TO_PUBLISH:
      return {
        ...state,
        asstConfigData: action.payload.asstConfigData,
        asstConfigID: action.payload.asstConfigID,
        error: action.payload.error,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
