// list of available checks to perform

const AllPermissions = {
  ANALYTICS: {
    CREATE: "console:analytics:create",
    EDIT: "console:analytics:edit",
    VIEW: "console:analytics:view",
    DELETE: "console:analytics:delete",
  },
  ASSISTANT_CONFIG: {
    CREATE: "console:assistantConfig:create",
    EDIT: "console:assistantConfig:edit",
    VIEW: "console:assistantConfig:view",
    DELETE: "console:assistantConfig:delete",
    PUBLISH: "console:assistantConfig:publish",
  },
  ASSISTANT_TEMPLATES: {
    CREATE: "console:assistantTemplate:create",
    EDIT: "console:assistantTemplate:edit",
    VIEW: "console:assistantTemplate:view",
    DELETE: "console:assistantTemplate:delete",
    PUBLISH: "console:assistantTemplate:publish",
    PLAYGOUND: "console:assistantTemplate:playground",
  },
};

export default AllPermissions;
