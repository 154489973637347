import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  APP_STATE_SUFFIX,
  VOICE_ASSIST_SUFFIX,
} from "../../../../utils/voiceAssistUtils";
import SelectList from "../UserJourneysEdit/SelectList";
import AddTriggerDialog from "./AddTriggerDialog";
import VoiceAssistVerticalTabs from "./VoiceAssistVerticalTabs";

const VoiceAssistTriggerEdit = props => {
  const {
    open,
    classes,
    appStatesMetadata,
    userJourneyID,
    userJourneyName,
    changeUserJourneyData,
  } = props;

  const [selectedValue, setSelectedValue] = useState(0);
  const [allTriggers, setAllTriggers] = useState([]);
  const [allStates, setAllStates] = useState(appStatesMetadata);
  const [triggerList, setTriggerList] = useState([]);

  const { userJourney } = useSelector(state => ({
    userJourney: state.appSchema.appDATA.userJourneys[userJourneyID],
  }));

  useEffect(() => {
    if (!open) {
      setSelectedValue(0);
    }
  }, [open]);

  const updateAllTriggers = () => {
    const triggers = appStatesMetadata
      .filter(item => {
        return item.name.search(VOICE_ASSIST_SUFFIX) !== -1;
      })
      .map(trigger =>
        trigger.name
          .replace(`${userJourney.name}_`, "")
          .replace(VOICE_ASSIST_SUFFIX, "")
      );
    setAllTriggers(triggers);

    const newAppStatesMetadata = appStatesMetadata.map((item, ID) => ({
      ...item,
      uid: ID,
    }));
    setAllStates(newAppStatesMetadata);
  };

  useEffect(() => {
    if (open) {
      updateAllTriggers();
    }
  }, [open, selectedValue]);

  const handleUpdateData = (data, key) => {
    changeUserJourneyData(data, key);
    setSelectedValue(0);
    updateAllTriggers();
  };

  const selectedAppState = allStates.find(
    item =>
      item.name ===
      `${userJourney.name}_${allTriggers[selectedValue]}${VOICE_ASSIST_SUFFIX}`
  );

  const verticalTabsTriggersProps = {
    appStateUID: selectedAppState?.uid,
    userJourneyID,
    userJourney,
    userJourneyName,
    appStatesMetadata,
    changeUserJourneyData: handleUpdateData,
    selectedValue,
    isTerminal: selectedAppState?.isTerminal,
  };

  useEffect(() => {
    const trimmedTriggers = [];
    allStates.forEach(item => {
      allTriggers.forEach(trigger => {
        if (
          item.name === `${userJourney.name}_${trigger}${VOICE_ASSIST_SUFFIX}`
        ) {
          trimmedTriggers.push(item.appStateName.replace(APP_STATE_SUFFIX, ""));
        }
      });
    });
    setTriggerList(trimmedTriggers);
  }, [allTriggers]);

  return (
    <Grid alignContent="center" alignItems="center" spacing={3} container>
      <Grid xs={2} item></Grid>
      <Grid xs={allTriggers.length === 0 ? 8 : 1} item>
        <AddTriggerDialog
          classes={classes}
          title={`${userJourneyName}: Add App State`}
          userJourneyID={userJourneyID}
          userJourney={userJourney}
          changeUserJourneyData={handleUpdateData}
          selectedAppState={selectedValue}
          showButton={allTriggers.length > 0}
        />
      </Grid>
      <Grid xs={allTriggers.length === 0 ? 0 : 7} item>
        {allTriggers.length > 0 ? (
          <SelectList
            label={"Triggers"}
            noValueString={"No App State"}
            listItems={triggerList}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            listItemsType={"noCategory"}
          />
        ) : null}
      </Grid>
      <Grid xs={2} item />
      {allTriggers.length > 0 ? (
        <Grid item xs={12}>
          <VoiceAssistVerticalTabs {...verticalTabsTriggersProps} />
        </Grid>
      ) : null}
    </Grid>
  );
};

PropTypes.VoiceAssistTriggerEdit = {
  userJourney: PropTypes.object.isRequired,
  userJourneyID: PropTypes.string.isRequired,
  userJourneyName: PropTypes.string.isRequired,
};

export default VoiceAssistTriggerEdit;
