export const prepareAction = (action, payload, store) => {
  const newAction = {
    ...action,
    payload,
  };
  delete newAction.meta;
  store.dispatch(newAction);
};

export const prepareActionLocal = (action, payload, store, setItem = []) => {
  if (setItem.length) {
    try {
      setItem.forEach(item => {
        localStorage.setItem(item.key, item.value);
      });
    } catch (error) {
      console.log(error);
    }
  }
  const newAction = {
    ...action,
    payload,
  };
  delete newAction.meta;
  store.dispatch(newAction);
};

export const prepareActionSession = (action, payload, store, setItem = []) => {
  if (setItem.length) {
    try {
      setItem.forEach(item => {
        sessionStorage.setItem(item.key, item.value);
      });
    } catch (error) {
      console.log(error);
    }
  }
  const newAction = {
    ...action,
    payload,
  };
  delete newAction.meta;
  store.dispatch(newAction);
};
