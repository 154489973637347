import * as types from "../types";

export const initialState = () => {
  return {
    identity: localStorage.getItem("U_identity")
      ? localStorage.getItem("U_identity")
      : "",
    api_key: localStorage.getItem("S_apiKey")
      ? localStorage.getItem("S_apiKey")
      : "",
    isAPI: !!localStorage.getItem("S_apiKey"),
    api_secret: localStorage.getItem("S_apiSecret")
      ? localStorage.getItem("S_apiSecret")
      : null,
    error: null,
  };
};

export const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.GET_API:
      return {
        ...state,
        api_key: action.payload.api_key,
        api_secret: action.payload.api_secret,
        error: action.payload.error,
      };
    case types.CREATE_API:
      return {
        ...state,
        api_key: action.payload.api_key,
        api_secret: action.payload.api_secret,
        identity: action.payload.identity,
        error: action.payload.error,
      };
    case types.RESET_API:
      return {
        ...state,
        api_key: action.payload.api_key,
        api_secret: action.payload.api_secret,
        identity: action.payload.identity,
        error: action.payload.error,
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
