import { isUserLoggedIn } from "../actions/login";
import * as types from "../types";
import * as helper from "./helper";
import { getTemplatesFullList } from "../../libs/slang/asstAPIs";

export const appSchemaActions = store => next => action => {
  if (!action.meta || action.meta.type !== "ASST_TEMPLATES") {
    return next(action);
  }

  store.dispatch(isUserLoggedIn({}));
  const {
    login: { identity, isAuth },
    apikey: { api_key, api_secret },
    asstTemplates: { templatesList },
  } = store.getState();

  if (!identity || !isAuth || !api_key || api_key === "" || !api_secret) {
    const payload = {
      error: "Error: id or api key not found",
    };
    helper.prepareAction(action, payload, store);
    return;
  }

  switch (action.type) {
    case types.TEMPLATES_SUBDOMAINS_JOURNEYS:
      if (templatesList?.length) {
        return next(action);
      } else {
        getTemplatesFullList()
          .then(templatesList => {
            const setItem = [
              {
                key: "A_templatesList",
                value: JSON.stringify(templatesList),
              },
            ];
            const payload = {
              templatesList,
              error: null,
            };
            helper.prepareActionSession(action, payload, store, setItem);
          })
          .catch(err => {
            console.log(err.toString());
            const theList = sessionStorage.getItem("A_templatesList")
              ? sessionStorage.getItem("A_templatesList")
              : [];
            const setItem = [
              {
                key: "A_templatesList",
                value: theList,
              },
            ];
            const payload = {
              templatesList: JSON.parse(theList),
              error: err.toString(),
            };
            helper.prepareAction(action, payload, store, setItem);
          });
      }

      break;
    default:
      const newAction = Object.assign({}, action);
      delete newAction.meta;
      store.dispatch(newAction);
  }
};

export default appSchemaActions;
