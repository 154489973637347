import { useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { InfoOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";

import Can from "../../../utils/rbac/Can";
import AllPermissions from "../../../utils/rbac/rbac-rules";

import { Link } from "react-router-dom";
import TutorialWrapper from "../../common/TutorialWrapper";

import {
  BuddyCardInterface,
  appListItemType,
} from "./AssistantConfigsHomeTypes";

const BuddyCards = (props: BuddyCardInterface) => {
  const { classes, handleDeleteClick, state } = props;

  const { apikey, appList, isCreatingOrDeleting, newApp } = state;

  const NewAppCardRef = useRef(null);

  return (
    <>
      {appList?.map((item: appListItemType, key: number) => {
        const hover = "hover";
        const isLoading: boolean = !apikey || isCreatingOrDeleting;
        const pathName = Can({
          perform: AllPermissions.ASSISTANT_CONFIG.EDIT,
          yes: () => `/home/assistants/${item.name}`,
          no: () => `/home/assistants/${item.name}/analytics`,
        });

        const selectMedia = (domain: string | null = null) => {
          let media;

          switch (domain) {
            case "retail":
              media = "/images/graphic_assistant_config_cart.png";
              break;
            case "travel":
              media = "/images/graphic_assistant_config_travel.png";
              break;
            default:
              media = "/images/graphic_assistant_config_null.png";
              break;
          }

          return media;
        };

        const assistantConfigCard = (
          <Card
            className={`${isLoading ? classes.cardDisabled : classes.card}`}
            elevation={0}
            ref={NewAppCardRef}
            component="div"
          >
            <Link
              to={
                isLoading
                  ? "/home/assistants/"
                  : {
                      pathname: pathName,
                      state: {
                        nameApp: item.name,
                        appVersion: item.major_version,
                      },
                    }
              }
            >
              <CardMedia
                className={isLoading ? classes.mediaDisabled : classes.media}
                image={selectMedia(item?.domain && item.domain.toLowerCase())}
                title={item.name}
              />
              <CardContent className={classes.cardContent}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography noWrap>{item.name} </Typography>
                  &nbsp;
                  <Tooltip title={`Version ${item.major_version}`}>
                    <InfoOutlined fontSize="small" color="disabled" />
                  </Tooltip>
                </div>
              </CardContent>
            </Link>
            <span className={classes.buttonSpan}>
              <span className={isLoading ? "hide" : "hidden"}>
                <Link
                  to={
                    isLoading
                      ? "/home"
                      : {
                          pathname: "/home/assistants/" + item.name,
                          state: {
                            nameApp: item.name,
                            appVersion: item.major_version,
                          },
                        }
                  }
                >
                  <IconButton
                    id={key + hover + 2}
                    className={classes.buttonEdit}
                    aria-label="Edit"
                    title="Edit Assistant"
                  >
                    <EditIcon id={key + hover + 3} />
                  </IconButton>
                </Link>
                <IconButton
                  id={key + hover + 4}
                  className={classes.buttonDelete}
                  onClick={
                    isLoading
                      ? () => {}
                      : () => {
                          handleDeleteClick.bind(
                            this,
                            item.id,
                            item.name,
                            item.major_version
                          );
                        }
                  }
                  aria-label="Delete"
                  title="Delete Assistant"
                >
                  <DeleteIcon id={key + hover + 5} />
                </IconButton>
              </span>
            </span>
          </Card>
        );

        return (
          <Grid
            key={key}
            item
            xs={12}
            md={3}
            sm={4}
            lg={2}
            xl={1}
            id={key + hover}
          >
            <div className={`${classes.parentCard}`}>
              {newApp && newApp.length > 0 && item.id === newApp[0].id ? (
                <TutorialWrapper
                  key={item.name + key}
                  step={1}
                  anchorEl={NewAppCardRef}
                >
                  {assistantConfigCard}
                </TutorialWrapper>
              ) : (
                assistantConfigCard
              )}
            </div>
          </Grid>
        );
      })}
    </>
  );
};

export default BuddyCards;
