import theme from "../pages/theme";

export const replaceAtArray = (array, index, value) => {
  const editArray = array.map((item, key) => {
    if (key === index) {
      return value;
    }
    return item;
  });
  return editArray;
};

export const addNewKeyToObject = (obj, addProperty = {}) => {
  const editObj = { ...obj, ...addProperty };
  return editObj;
};

export const deleteKeyFromObject = (obj, delProperty = "") => {
  const { [delProperty]: omit, ...editObj } = obj;
  return editObj;
};

export const deleteKeyArray = (array, ID) => {
  const filteredArray = array.filter((item, key) => key !== ID);
  return filteredArray;
};

export const sortStrings = (array, property = null) => {
  const sortedArray = array.sort((a, b) => {
    const nameA = property ? a[property].toUpperCase() : a.toUpperCase();
    const nameB = property ? a[property].toUpperCase() : b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

export const getAllKeyValues = (
  needle,
  haystack,
  getKeyValue,
  nested = false,
  found = []
) => {
  Object.keys(haystack).forEach(key => {
    if (key === needle) {
      if (haystack[key] || typeof haystack[key] === "object") {
        found.push(haystack[getKeyValue]);
      }

      return found;
    }

    if (nested) {
      if (typeof haystack[key] === "object") {
        getAllKeyValues(needle, haystack[key], getKeyValue, true, found);
      }
    }
  });
  return found;
};

/* 
Calculate the background and foreground colours based on any string,
by hashcode and getContrastText which is a material UI theme function 
*/
export const colourEntity = str => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  const bColor = `rgba(${r},${g},${b},0.87)`;
  const fColor = theme.palette.getContrastText(bColor);

  return { bColor, fColor };
};

// create the style to inject into the content editable div based on the entity name
export const styleEntity = (entity, jss = false) => {
  const Color = colourEntity(entity);
  if (jss)
    return {
      borderRadius: 4,
      fontWeight: "bold",
      boxShadow: theme.shadows[1],
      backgroundColor: Color.bColor,
      color: Color.fColor,
      padding: "1px 3px",
      margin: "0px 3px",
    };

  return (
    "border-radius:4px;font-weight:bold; box-shadow: " +
    theme.shadows[1] +
    ";" +
    "background-color:" +
    Color.bColor +
    ";padding: 1px 3px; color: " +
    Color.fColor +
    ";"
  );
};
export const validateStrings = (appData = null, appStringTable = null) => {
  if (typeof appData !== "object" && typeof appStringTable !== "object") {
    throw new Error("Error validating schema and string table");
  }
  let stringErrors = "";
  const newAppStringTable = {};
  // Get all the string ids in app schema
  const allIDS = getAllKeyValues("isId", appData, "value", true);

  allIDS.forEach(stingId => {
    // create a new string table with only ids in the app schema
    newAppStringTable[stingId] = appStringTable[stingId];
    // check if the string id is present in the string table.
    const checkId = getAllKeyValues(
      stingId,
      appStringTable,
      stingId.toString()
    ).length;
    if (checkId === 0) {
      stringErrors += stingId + "   ";
    }
  });
  if (stringErrors !== "") {
    throw new Error(`Missing string IDs: ${stringErrors}`);
  }

  return newAppStringTable;
};

export const AreEqual = (prevProps, nextProps) => {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  if (nextProps.data !== prevProps.data) {
    return false;
  }
  if (nextProps.item.text === nextProps.selectUtter) {
    return false;
  } else if (prevProps.item.text === prevProps.selectUtter) {
    return false;
  } else if (nextProps.checkedUtternaces.indexOf(nextProps.item.text) !== -1) {
    return false;
  } else if (prevProps.checkedUtternaces.indexOf(prevProps.item.text) !== -1) {
    return false;
  } else if (
    nextProps.allUtterances.indexOf(nextProps.item.text.toLowerCase()) !== -1
  ) {
    return false;
  } else if (
    prevProps.allUtterances.indexOf(prevProps.item.text.toLowerCase()) !== -1
  ) {
    return false;
  }

  return true;
};

export const getAllUtterances = appData => {
  const intents = appData.intents;
  let allUtterances = [];
  intents.forEach(intent => {
    const examples = intent.examples;
    examples.forEach(example => {
      const utterance = example
        .reduce(
          (accumulator, currentValue) => accumulator + " " + currentValue.text,
          ""
        )
        .trim()
        .toLowerCase();
      allUtterances = [...allUtterances, { intent: intent.name, utterance }];
    });
  });

  return allUtterances;
};

export const checkValidASCIIUtterance = str => {
  return str && str !== "<All>" && str !== "" && /^[\030-\177]*$/.test(str);
};

/*
 * Checks if either string_1 is not null or string_2 is ASCII
 * if its neither check is satisfied it returns null
 */
export const checkWhichStringIsValid = (string_1 = null, string_2 = null) => {
  let str1 = null;
  const str2 = string_2;

  if (checkValidASCIIUtterance(string_1)) {
    str1 = string_1;
  }

  return str2 || str1;
};
