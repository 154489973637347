const AnalyticsConfig = {};

AnalyticsConfig.BASE_URL_ASSISTANT = "/v1/assistants/analytics";
AnalyticsConfig.MSU_COUNT = "/msu/count";
AnalyticsConfig.MSU_COUNT_URI =
  AnalyticsConfig.BASE_URL_ASSISTANT + "/msu/count";

AnalyticsConfig.BASE_URL = "/v1/analytics/";
AnalyticsConfig.BASE_URL_NEXT = "/v1/applications/:app_id/analytics/";
AnalyticsConfig.USER_USAGE = AnalyticsConfig.BASE_URL_NEXT + "engagement/usage";
AnalyticsConfig.INTENT_USAGE = AnalyticsConfig.BASE_URL_NEXT + "intent/usage";
AnalyticsConfig.DAILY_EVENT_COUNT =
  AnalyticsConfig.BASE_URL_NEXT + "events/count";
AnalyticsConfig.DAILY_SUCCESS_FAIL_UTTERANCES =
  AnalyticsConfig.BASE_URL_NEXT + "utterance/popular";
AnalyticsConfig.DAILY_INTENT_COUNT =
  AnalyticsConfig.BASE_URL_NEXT + "intent/count";
AnalyticsConfig.COUNT_URL = AnalyticsConfig.BASE_URL + "count/";
AnalyticsConfig.TOP_UTTERANCE_URL =
  AnalyticsConfig.BASE_URL + "top/utterances/";
AnalyticsConfig.VOICE_USAGE_URL = AnalyticsConfig.BASE_URL + "usage/voice";
AnalyticsConfig.USER_ENGAGEMENT_URL =
  AnalyticsConfig.BASE_URL + "engagement/user";
AnalyticsConfig.ACCOUNTING_URL = AnalyticsConfig.BASE_URL + "accounting";
AnalyticsConfig.HEADERS = { "Content-Type": "application/json; charset=UTF-8" };

AnalyticsConfig.LOG_EVENT_URL = "/v1/assistants/audit-logs";
module.exports = AnalyticsConfig;
