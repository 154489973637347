import { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { editMain } from "./styles.js";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import LinearProgress from "@material-ui/core/LinearProgress";

import * as SlangTooltips from "../common/SlangTooltips";
import IntentHomeEntityTable from "./components/IntentHomeEntityTable";
import IntentHomeUtteranceTable from "./components/IntentHomeUtteranceTable";
import IntentHomePrompts from "./components/IntentHomePrompts";
import PowerModeToggler from "./components/PowerModeToggler";
import asyncComponent from "../common/AsyncComponent";
import IntentHomeHelp from "./components/IntentHomeHelp";
import IntentNameDescription from "./components/IntentNameDescription";

import Notifications from "../common/Notifications";

import { connect } from "react-redux";
import {
  getApps,
  getAppDATA,
  getAppStringTable,
  changeAppDATA,
  getStdEntityTypes,
  getAppMetaData,
} from "../../redux/actions/appSchema.js";

import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";
import { checkIfUserBelongsToSlangLabs } from "../../utils/userUtils";

import DocHelpLink from "../common/DocLinks";
import Helpbox from "./components/Helpbox";
const JsonEditor = asyncComponent(() =>
  import("./components/JsonEditorSchema")
);

const mapDispatchToProps = dispatch => ({
  getAppDATA: appid => {
    dispatch(getApps());
    dispatch(getAppDATA(appid));
    dispatch(getAppStringTable(appid));
    dispatch(getAppMetaData(appid));
  },
  getStdTypes: () => dispatch(getStdEntityTypes()),
  changeAppDATA: appid => dispatch(changeAppDATA(appid)),
});

let intentIndex = null;

export class IntentHomeMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      appIntents: [],
      intentData: null,
      search: "",
      open: false,
      dialogTitle: "",
      editIntentNameClick: false,
      editIntentName: "",
      intentNameError: false,
      entityDialogOpen: false,
      POWERMODE: false,
      examples: [],
      entities: [],
      stdTypes: [],
      notify: {
        variant: "info",
        message: "msg",
      },
    };

    this.handleSaveData = this.handleSaveData.bind(this);
  }

  setStateOfEntityDialog = state => {
    this.setState({
      entityDialogOpen: state,
    });
  };

  handleSearchClick = event => {
    this.setState({
      open: true,
    });
  };

  handleSaveIntentName = (newname = "") => {
    if (newname !== "") {
      const newAppData = this.props.appDATA;
      let index = null;
      let newAppIntentName = newAppData.intents.filter((item, key) => {
        if (item.name === this.props.intentName) {
          index = key;
        }
        return item.name === this.props.intentName;
      });

      newAppIntentName = { ...newAppIntentName[0], name: newname };
      newAppData.intents[index] = newAppIntentName;

      this.props.changeAppDATA({
        appDATA: newAppData,
      });
      this.props.handleIntentNameChange(newname);
      return true;
    }
    return false;
  };

  handleSaveIntentDescription = (newDesc = "") => {
    if (newDesc !== "") {
      const newAppData = this.props.appDATA;
      let index = null;
      let newAppIntentName = newAppData.intents.filter((item, key) => {
        if (item.name === this.props.intentName) {
          index = key;
        }
        return item.name === this.props.intentName;
      });

      newAppIntentName = { ...newAppIntentName[0], description: newDesc };
      newAppData.intents[index] = newAppIntentName;

      this.props.changeAppDATA({
        appDATA: newAppData,
      });
      return true;
    }
    return false;
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  checkApp = () => {
    const { appDATA, intentName, history, appName, appList, appStringTable } =
      this.props;
    if (appDATA && appDATA.id && appStringTable) {
      const newAppIntentName = appDATA.intents.filter((item, key) => {
        if (item.name === intentName) {
          intentIndex = key;
        }
        return item.name === intentName;
      });
      if (newAppIntentName.length) {
        const intentData = newAppIntentName[0];
        this.setState({
          appIntents: appDATA.intents,
          appTypes: appDATA.types,
          intentData,
          examples: intentData.examples,
          entities: intentData.entities,
        });
      } else {
        history.push(`/home/${appDATA.name}`);
      }
    } else {
      const theApp = appList.filter(item => item.name === appName);
      if (!theApp[0]) {
        history.push("/home");
      } else {
        this.props.getAppDATA({
          appID: theApp[0].id,
        });
      }
    }
  };

  componentDidMount() {
    this.checkApp();
    logEvent(SlangSeverityLevels.INFO, SlangEvents.INTENT_OPENED, {
      intent_name: this.props.intentName,
    });
    this.props.getStdTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.appDATA !== this.props.appDATA) {
      this.checkApp();
    }

    if (prevProps.stdTypes !== this.props.stdTypes) {
      this.setState({
        stdTypes: this.props.stdTypes,
      });
    }

    if (prevProps.error !== this.props.error && this.props.error) {
      this.setState({
        notify: {
          variant: "warning",
          message: this.props.error,
        },
      });
    }
  }

  handleSwitchPowerMode = event => {
    this.setState({
      POWERMODE: event.target.checked,
    });
  };

  handleSaveData = examples => {
    const newAppData = this.props.appDATA;
    const index = intentIndex;

    newAppData.intents[index] = {
      ...newAppData.intents[index],
      examples,
    };

    this.setState({
      appIntents: newAppData.intents,
      examples,
    });

    this.props.changeAppDATA({
      appDATA: newAppData,
    });
  };

  // TODO: write only prompts not the entire intent
  handleSavePromptData = prompts => {
    const newAppData = this.props.appDATA;
    const index = intentIndex;

    newAppData.intents[index] = prompts;

    this.setState({
      appIntents: newAppData.intents,
    });

    this.props.changeAppDATA({
      appDATA: newAppData,
    });
  };

  handleEntityNameChange = (oldName, newName) => {
    const utterances = this.state.examples;

    const newUtterances = utterances.map(utterance => {
      const newUtterance = utterance.map(item => {
        if (item.entity === oldName) {
          return { text: item.text, entity: newName, value: item.value };
        } else {
          return { text: item.text, entity: item.entity, value: item.value };
        }
      });
      return newUtterance;
    });

    this.handleSaveData(newUtterances);
  };

  handleSaveEntityData = (entities, oldName = null, newName = null) => {
    const newAppData = this.props.appDATA;
    const index = intentIndex;
    const utterances = this.state.examples;

    if (oldName && newName) {
      const newUtterances = utterances.map(utterance => {
        const newUtterance = utterance.map(item => {
          if (item.entity === oldName) {
            return { text: item.text, entity: newName, value: item.value };
          } else {
            return { text: item.text, entity: item.entity, value: item.value };
          }
        });
        return newUtterance;
      });

      newAppData.intents[index] = {
        ...newAppData.intents[index],
        entities,
        examples: newUtterances,
      };

      this.setState({
        appIntents: newAppData.intents,
        examples: newUtterances,
        entities,
      });
      logEvent(SlangSeverityLevels.INFO, SlangEvents.ENTITY_NAME_UPDATED, {
        prev_entity_name: oldName,
        new_entity_name: newName,
        intent_name: this.props.intentName,
      });
    } else if (oldName) {
      const newUtterances = utterances.map(utterance => {
        const newUtterance = utterance.map(item => {
          if (item.entity === oldName) {
            return { text: item.text };
          } else {
            return item;
          }
        });
        return newUtterance;
      });

      newAppData.intents[index] = {
        ...newAppData.intents[index],
        entities,
        examples: newUtterances,
      };

      this.setState({
        appIntents: newAppData.intents,
        examples: newUtterances,
        entities,
      });

      logEvent(SlangSeverityLevels.INFO, SlangEvents.ENTITY_DELETED, {
        entity_name: oldName,
        intent_name: this.props.intentName,
      });
    } else {
      newAppData.intents[index] = {
        ...newAppData.intents[index],
        entities,
      };

      this.setState({
        appIntents: newAppData.intents,
        entities,
      });
    }

    this.props.changeAppDATA({
      appDATA: newAppData,
    });
  };

  handleNewEntityType = (
    newEntityType,
    ID = null,
    newName = null,
    oldName = null
  ) => {
    let newTypes = this.props.appDATA.types;
    const DATA = this.props.appDATA;
    if (ID !== null || ID === 0) {
      newTypes[ID] = newEntityType;
    } else {
      newTypes = [newEntityType, ...newTypes];
    }

    if (newName && oldName) {
      logEvent(SlangSeverityLevels.INFO, SlangEvents.ENTITY_TYPE_NAME_UPDATED, {
        prev_entity_type_name: oldName,
        new_entity_type_name: newName,
      });

      const newIntents = DATA.intents.map(intent => {
        const newEntities = intent.entities.map(entity => {
          if (entity.type === oldName) {
            return { ...entity, type: newName };
          } else {
            return entity;
          }
        });

        return { ...intent, entities: newEntities };
      });

      this.props.changeAppDATA({ appDATA: { ...DATA, intents: newIntents } });
    } else if (oldName) {
      logEvent(SlangSeverityLevels.INFO, SlangEvents.ENTITY_TYPE_DELETED, {
        entity_type_name: oldName,
      });

      const newIntents = DATA.intents.map(intent => {
        const newIntent = intent.entities.map(entity => {
          if (entity.type === oldName) {
            return this.handleDeleteEntityData(intent, entity.name);
          } else {
            return intent;
          }
        });

        return newIntent;
      });

      this.props.changeAppDATA({ appDATA: { ...DATA, intents: newIntents } });
    } else {
      logEvent(SlangSeverityLevels.INFO, SlangEvents.ENTITY_TYPE_CREATED, {
        entity_type_name: newEntityType.name,
      });

      this.props.changeAppDATA({
        appDATA: { ...this.props.appDATA, types: newTypes },
      });
    }
  };

  handleChangeTab = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleChangeIndex = index => {
    this.setState({ tabValue: index });
  };

  render() {
    const { classes, intentName, appDATA, appID, userBelongsToSlangLabs } =
      this.props;
    const { handleSaveIntentName, handleSaveIntentDescription } = this;
    const {
      open,
      search,
      dialogTitle,
      entities,
      examples,
      notify,
      stdTypes,
      tabValue,
      appIntents,
      POWERMODE,
    } = this.state;

    const filterIntent = appIntents
      ? appIntents.filter(item => item.name === intentName)
      : [];
    const intentData = filterIntent[0];

    const IntentNameDescriptionProps = {
      classes,
      handleSaveIntentDescription,
      handleSaveIntentName,
      intents: appDATA.intents,
      intentName,
      descriptionIntent: intentData ? intentData.description : "",
    };

    return (
      <div>
        <Grid container spacing={3}>
          {POWERMODE ? (
            <Grid item lg={8} md={8} sm={12}>
              <JsonEditor />
            </Grid>
          ) : (
            <Grid item lg={8} md={8} sm={12}>
              <IntentNameDescription {...IntentNameDescriptionProps} />
              <Grid
                container
                className={classes.mainIntent}
                spacing={2}
                alignContent="flex-start"
                alignItems="flex-start"
                direction="row"
                justify="space-between"
              >
                <Grid xs={12} item>
                  <Tabs
                    value={tabValue}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off"
                  >
                    <Tab label="Utterances / Entities" />
                    <Tab label="Intent Prompts" />
                    <Tab label="Intent Help" />
                  </Tabs>
                </Grid>
                {tabValue === 0 && (
                  <Grid container xs={12} item>
                    <Grid item xs={12}>
                      <Paper className={classes.paperUtter} elevation={0}>
                        <Grid item sm={12}>
                          <Typography variant="h5" noWrap>
                            {"Utterances "} <DocHelpLink docFor="UTTERANCE" />
                          </Typography>
                        </Grid>
                        <Grid container>
                          {intentData ? (
                            <IntentHomeUtteranceTable
                              data={examples}
                              dataEntities={entities}
                              handleSaveData={this.handleSaveData}
                              intentName={this.props.intentName}
                              setStateOfEntityDialog={
                                this.setStateOfEntityDialog
                              }
                              entityDialogOpen={this.state.entityDialogOpen}
                            />
                          ) : (
                            <Grid item sm={12} style={{ padding: "190px 0" }}>
                              <LinearProgress color="primary" variant="query" />
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.paperEntity} elevation={0}>
                        <Grid item sm={12}>
                          <Typography variant="h5" noWrap>
                            {" Entities "} <DocHelpLink docFor="ENTITY" />
                          </Typography>
                        </Grid>
                        <Grid container>
                          {intentData ? (
                            <IntentHomeEntityTable
                              appTypes={appDATA.types}
                              handleEntityNameChange={
                                this.handleEntityNameChange
                              }
                              handleSaveEntityData={this.handleSaveEntityData}
                              handleNewEntityType={this.handleNewEntityType}
                              data2={entities}
                              stdTypes={stdTypes}
                              intentName={this.props.intentName}
                              setStateOfEntityDialog={
                                this.setStateOfEntityDialog
                              }
                              entityDialogOpen={this.state.entityDialogOpen}
                            />
                          ) : (
                            <Grid item sm={12} style={{ padding: "190px 0" }}>
                              <LinearProgress color="primary" variant="query" />
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                )}

                {tabValue === 1 && (
                  <Grid xs={12} item>
                    <IntentHomePrompts
                      intentData={intentData}
                      handleSavePromptData={this.handleSavePromptData}
                    />
                  </Grid>
                )}
                {tabValue === 2 && (
                  <Grid xs={12} item>
                    <IntentHomeHelp
                      intentData={intentData}
                      handleSavePromptData={this.handleSavePromptData}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid container item lg={4} md={4} sm={12}>
            <Grid
              container
              className={classes.mainIntent}
              spacing={2}
              alignContent="flex-start"
              alignItems="center"
              direction="row"
              justify="space-evenly"
            >
              {/* Dummy padding to hide the power mode switch when user is not a slanglabs.in user */}
              <Hidden smDown>
                <Grid
                  xs={12}
                  style={
                    userBelongsToSlangLabs ? { padding: 5 } : { padding: 52 }
                  }
                  item
                >
                  {userBelongsToSlangLabs ? (
                    <PowerModeToggler
                      POWERMODE={POWERMODE}
                      handleSwitchPowerMode={this.handleSwitchPowerMode}
                    />
                  ) : null}
                </Grid>
              </Hidden>
              <Grid xs={12} item>
                <Helpbox
                  appID={appID}
                  helpText={SlangTooltips.INTENT_HOME_RIGHT}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{search}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Notifications notify={notify} />
      </div>
    );
  }
}

IntentHomeMain.propTypes = {
  classes: PropTypes.object.isRequired,
  appDATA: PropTypes.object.isRequired,
  appList: PropTypes.array.isRequired,
  appName: PropTypes.string.isRequired,
  userBelongsToSlangLabs: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    appDATA: state.appSchema.appDATA,
    appStringTable: state.appSchema.appStringTable,
    appList: state.appSchema.list,
    error: state.appSchema.error,
    appID: state.appSchema.appID,
    stdTypes: state.appSchema.stdTypes,
    userBelongsToSlangLabs: checkIfUserBelongsToSlangLabs(state.login.username),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(editMain)(IntentHomeMain)));
