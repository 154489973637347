import { makeStyles } from "@material-ui/core";
import SlangCoachmark from "./SlangCoachmark";
import slangTrigger from "./assets/slangTriggerSVG.svg";
const styles = makeStyles(theme => ({
  SlangTriggerButton: {
    position: "absolute",
    alignItems: "center",
    zIndex: 2147482649,
    width: "3.5rem",
    height: "3.5rem",
    bottom: "1rem",
    left: "8.5rem",
  },
  SlangTriggerButtonBlock: {
    position: "relative",
    width: "3.5rem",
    height: "3.5rem",
  },
  SlangMicButton: {
    cursor: "pointer",
    width: "100%",
    height: "100%",
  },
}));

const SlangButton = props => {
  const { onboardingPrimaryText, onboardingSecondaryText } = props;
  const classes = styles();
  return (
    <div className={classes.SlangTriggerButton}>
      <span className={classes.SlangTriggerButtonBlock}>
        <SlangCoachmark
          {...{ onboardingPrimaryText, onboardingSecondaryText }}
        />
        <img
          src={slangTrigger}
          alt="Trigger Voice Assistant"
          className={classes.SlangMicButton}
        />
      </span>
    </div>
  );
};

export default SlangButton;
