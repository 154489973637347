import * as types from "../types";

export const getApps = (payload = {}) => ({
  type: types.APP_LIST,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});

export const deleteAppList = () => ({
  type: types.DELETE_APP_LIST,
});

export const getStdEntityTypes = (payload = {}) => ({
  type: types.APP_DATA_GET_TYPES,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});

export const createApp = (payload = {}) => ({
  type: types.APP_CREATE,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});
export const cloneApp = (payload = {}) => ({
  type: types.APP_CLONE,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});

export const deleteApp = (payload = {}) => ({
  type: types.APP_DELETE,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});

export const editAppList = (payload = {}) => ({
  type: types.APP_LIST_EDIT,
  payload,
});

export const getAppDATA = (payload = {}) => ({
  type: types.APP_DATA,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});
export const getAppMetaData = (payload = {}) => ({
  type: types.APP_METADATA,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});
export const getAppMetadataTrainStatus = (payload = {}) => ({
  type: types.APP_METADATA_TRAIN_STATUS,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});
export const appNameChange = (payload = {}) => ({
  type: types.APP_NAME_CHANGE,
  payload,
});

export const getAppSchemaDef = (payload = {}) => ({
  type: types.APP_SCHEMA_DEFINITION,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});

export const getAppStringTable = (payload = {}) => ({
  type: types.APP_STRING_TABLE,
  payload,
  meta: {
    type: "APPSCHEMA",
  },
});

export const changeAppDATA = (payload = {}) => ({
  type: types.APP_DATA_CHANGE,
  payload,
});

export const changeAppStringTable = (payload = {}) => ({
  type: types.APP_STRING_TABLE_CHANGE,
  payload,
});

export const clearAppDATA = () => ({
  type: types.APP_DATA_CLEAR,
});

export const savedAppDATA = () => ({
  type: types.APP_DATA_SAVED,
});

export const validateAppData = appdata => {
  return false;
};

export const resetErrorSchema = () => ({
  type: types.RESET_ERROR,
});
