import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Layout from "../../common/layouts/defaultLayout";
import { Grid, LinearProgress } from "@material-ui/core";
import AppConfig from "../../../app.config";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import { clearAppDATA } from "../../../redux/actions/appSchema";
import { getAPI } from "../../../redux/actions/apikey";
import {
  getDomainFullList,
  getAllAssistantTemplates,
  getNewDomainsList,
  getSchemaDefinition,
  createAssistantTemplate,
} from "../../../libs/slang/asstAPIs";
import { styles } from "../styles";
import { makeStyles } from "@material-ui/core/styles";

import AssistantTemplateCards from "./AssistantTemplateCards";
import CreateAssistantTemplateDialog from "./CreateAssistantTemplateDialog";
import NewAssistatantTemplateCard from "./NewAssistantTemplateCard";

import WelcomeDialog from "../components/WelcomeDialog";
import { GetSetAppMetadata } from "../../../utils/auth/handleAuthState";
import { getSideNavShortcuts } from "../../common/routes/RouteControl";
import { getAllOrganizations } from "../../../libs/auth/slang_auth";

import { templatesInterface, notifyInterface } from "./AssistantTemplateTypes";

// @ts-expect-error
const useStyles = makeStyles(theme => styles());

const AssistantTemplatesHome = (props: any) => {
  const [notify, setNotify] = useState<notifyInterface>({
    variant: "info",
    message: "msg",
  });
  const [templates, setTemplates] = useState<templatesInterface>([]);
  const [canonicalDomainsList, setCanonicalDomainsList] = useState<string[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [schemaDefinition, setSchemaDefinition] = useState<any | null>(null);
  const dispatchRedux = useDispatch();

  const classes = useStyles();

  const refreshTemplatesList = async () => {
    try {
      setIsLoading(true);
      const allTemplatesWithVersions = await getAllAssistantTemplates();
      const domainsData = await getDomainFullList();
      const newDomainsData = await getNewDomainsList();
      setSchemaDefinition(await getSchemaDefinition());
      const uniqueTemplateIds = [
        ...new Set<any>(
          allTemplatesWithVersions.map((template: { id: any }) => template.id)
        ),
      ];
      const templates = uniqueTemplateIds.map(id => {
        return {
          id,
          name: allTemplatesWithVersions.find(
            (template: { id: unknown }) => template.id === id
          ).name,
          belongsToDomain: domainsData.some(
            (domain: { template_id: unknown }) => domain.template_id === id
          ),
        };
      });
      setCanonicalDomainsList([
        ...domainsData.map((item: { name: any }) => {
          return { name: item.name };
        }),
        ...newDomainsData.map((item: { name: any }) => {
          return { name: item.name };
        }),
      ]);
      setTemplates(templates);
      setIsLoading(false);
    } catch (error: any) {
      setNotify({
        variant: "error",
        message: error.toString(),
      });
      setTemplates([]);
      setCanonicalDomainsList([]);
      setIsLoading(false);
    }
  };

  // fetch the templatesList
  useEffect(() => {
    dispatchRedux(clearAppDATA());

    (async () => {
      const orgID = GetSetAppMetadata().organization_id;
      localStorage.setItem("U_orgID", orgID);
      const { organizations } = await getAllOrganizations();
      const orgName = organizations.filter(
        (org: { organization_id: any }) => org.organization_id === orgID
      ).organization_name;
      localStorage.setItem("U_orgName", orgName);
      await refreshTemplatesList();
      dispatchRedux(getAPI());
    })();
  }, []);

  // ui stuff
  const themeType =
    localStorage.getItem("U_DARKMODE") &&
    localStorage.getItem("U_DARKMODE") === "true"
      ? "dark"
      : "light";
  const Image =
    templates.filter(item => item.name !== AppConfig.demoAppName).length > 0
      ? "basecamp"
      : "astronaut";
  const backgroundImage = `url("/images/draw_${Image}_${themeType}.png")`;

  const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false);

  const [isCreatingOrDeleting, setIsCreatingOrDeleting] = useState(false);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const welcomeDialogProps = {
    classes,
    handleDialogClose: () => {
      setIsWelcomeDialogOpen(false);
      localStorage.setItem("I_WELCOME", "DONE");
    },
  };

  const welcomeDialogState = {
    openWelcome: isWelcomeDialogOpen,
  };

  const newTemplateCardProps = {
    classes,
    handleCreateClick: () => {
      setIsCreateDialogOpen(true);
    },
  };
  const newTemplateCardState = {
    isCreatingOrDeleting,
  };

  const templateCardsProps = {
    classes,
  };

  const templateCardsState = {
    isCreatingOrDeleting,
    templates,
  };

  const createTemplateDialogProps = {
    classes,
    handleClose: () => {
      setIsCreateDialogOpen(false);
    },
    handleCreateNewAssistantTemplate: async (
      assistantTemplateName: any,
      assistantTemplateDomain: any,
      assistantTemplateData: any,
      assistantTemplateStringTable: any
    ) => {
      setIsCreatingOrDeleting(true);
      setIsCreateDialogOpen(false);
      try {
        await createAssistantTemplate({
          assistantTemplateVersion: "1.0.0",
          assistantTemplateName,
          assistantTemplateData: {
            ...assistantTemplateData,
            domain: assistantTemplateDomain,
          },
          assistantTemplateStringTable,
        });
        setNotify({
          variant: "success",
          message: "New Template has been created",
        });
      } catch (error: any) {
        setNotify({
          variant: "error",
          message: error.toString(),
        });
      }
      await refreshTemplatesList();
      setIsCreatingOrDeleting(false);
    },
  };

  const createTemplateDialogState = {
    isOpen: isCreateDialogOpen,
    canonicalDomainsList,
    schemaDefinition,
    allTemplates: templates,
  };

  return (
    <Layout
      notify={notify}
      backgroundImage={backgroundImage}
      sideNavShortcuts={getSideNavShortcuts()}
      currentPage="Template"
    >
      <div>
        <div style={{ height: 8, width: "100%" }}>
          {(isLoading || isCreatingOrDeleting) && (
            <LinearProgress className={`${testClassNames.PROGRESS_BAR}`} />
          )}
        </div>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} sm={4} lg={2} xl={1}>
            <NewAssistatantTemplateCard
              {...newTemplateCardProps}
              state={newTemplateCardState}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <AssistantTemplateCards
          {...templateCardsProps}
          state={templateCardsState}
        />
        <WelcomeDialog {...welcomeDialogProps} state={welcomeDialogState} />
        <CreateAssistantTemplateDialog
          {...createTemplateDialogProps}
          state={createTemplateDialogState}
        />
      </div>
    </Layout>
  );
};

export default AssistantTemplatesHome;
