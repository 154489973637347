const SlangConfig = {};
const ENV_PARAM = "?env=ENV";
const VER_PARAM = "version=VER";

// Schema
SlangConfig.SLANG_SCHEMA_BASE = "/v1/schema";

SlangConfig.SLANG_ASSISTANTS_BASE = "/v1/assistants";
SlangConfig.SLANG_ASSISTANTS_BASE_URI =
  SlangConfig.SLANG_ASSISTANTS_BASE + ENV_PARAM;
SlangConfig.SLANG_ASSISTANTS_ID = "/:app_id";
SlangConfig.SLANG_ASSISTANTS_ID_URI =
  SlangConfig.SLANG_ASSISTANTS_BASE +
  SlangConfig.SLANG_ASSISTANTS_ID +
  ENV_PARAM;

SlangConfig.SLANG_ASSISTANTS_ID_STRINGS =
  SlangConfig.SLANG_ASSISTANTS_ID + "/strings";
SlangConfig.SLANG_ASSISTANTS_ID_TRAIN =
  SlangConfig.SLANG_ASSISTANTS_ID + "/train";
SlangConfig.SLANG_ASSISTANTS_ID_METADATA =
  SlangConfig.SLANG_ASSISTANTS_ID + "/metadata";

SlangConfig.SLANG_ASSISTANT_DOMAINS_BASE = "/v1/assistant-domains";
SlangConfig.SLANG_ASSISTANT_DOMAINS_BASE_URI =
  SlangConfig.SLANG_ASSISTANT_DOMAINS_BASE + ENV_PARAM;
SlangConfig.SLANG_ASSISTANT_DOMAINS_ID = "/:app_id";
SlangConfig.SLANG_ASSISTANT_DOMAINS_ID_URI =
  SlangConfig.SLANG_ASSISTANTS_BASE +
  SlangConfig.SLANG_ASSISTANT_DOMAINS_ID +
  ENV_PARAM;

SlangConfig.SLANG_ASSISTANT_TEMPLATES_BASE = "/v1/assistant-templates";
SlangConfig.SLANG_ASSISTANT_TEMPLATES_BASE_URI =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_BASE + ENV_PARAM;
SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID = "/:app_id";
SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID_URI =
  SlangConfig.SLANG_ASSISTANTS_BASE +
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID +
  ENV_PARAM;
SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID_SUBDOMAIN =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID + "/subdomain/data";
SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID_SUBDOMAIN_METADATA =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID + "/subdomain/metadata";
SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID_STRINGS =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID + "/strings";
SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID_TRAIN =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID + "/train";
SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID_METADATA =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID + "/metadata";

SlangConfig.SLANG_ASSISTANT_CONFIGS_BASE = "/v1/assistant-configs";
SlangConfig.SLANG_ASSISTANT_CONFIGS_BASE_URI =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_BASE + ENV_PARAM;
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID = "/:app_id";
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_URI =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_BASE +
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID +
  ENV_PARAM;
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_SUBDOMAIN =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID + "/subdomain/data";
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_SUBDOMAIN_METADATA =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID + "/subdomain/metadata";
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_STRINGS =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID + "/strings";
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_TRAIN =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID + "/train";

SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_TRAIN_URI =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_BASE +
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_TRAIN +
  ENV_PARAM;
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_UPDATE =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID + "/update";
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_UPDATE_URI =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_UPDATE + ENV_PARAM;

SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_METADATA =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID + "/metadata";
SlangConfig.SLANG_ASSISTANT_CONFIGS_ID_METADATA_TRAIN_STATUS =
  SlangConfig.SLANG_ASSISTANT_CONFIGS_ID + "/metadata/status";

SlangConfig.SLANG_ASSISTANT_TEMPLATES_LOCK =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_BASE +
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_ID +
  "/lock";
SlangConfig.SLANG_ASSISTANT_TEMPLATE_LOCK_URI =
  SlangConfig.SLANG_ASSISTANT_TEMPLATES_LOCK + `?${VER_PARAM}`;

SlangConfig.HEADERS = { "Content-Type": "application/json; charset=UTF-8" };

module.exports = SlangConfig;
