import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/ListAltRounded";
import Close from "@material-ui/icons/Close";
import { v4 } from "uuid";

import TableView from "./TableView";
import { Tabs, Tab, Typography } from "@material-ui/core";

import {
  getPutPostSubdomainData,
  getSubdomainMetadata,
} from "../../../../libs/slang/asstAPIs";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";

const styles = theme => ({
  iconButton: {
    marginRight: 20,
  },
  closeButton: {
    float: "right",
  },
  content: {
    minHeight: "60vh",
  },
});
const SubDomainDataTable = ({
  title,
  subDomainDataValues,
  assistantType,
  isUserData,
  subDomainName,
}) => {
  const dataId = subDomainDataValues?.data_id;
  const { id, version } = useSelector(state => state.appSchema.appDATA);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [resourceData, setResourceData] = useState(subDomainDataValues);

  const getAllSubdomainData = async data_id => {
    const fetchID = v4();
    logEvent(
      SlangSeverityLevels.INFO,
      SlangEvents.SUBDOMAIN_USER_DATA_FETCH_STARTED,
      {
        subDomainName,
        dataName: title,
        fetchType: "all_pages",
        fetch_id: fetchID,
      }
    );
    const payload = {
      ID: id,
      data_id,
      is_user_data: isUserData,
      assistantType,
      env: "stage",
      version,
      assistant_version: version,
    };
    const metadata = await getSubdomainMetadata(payload);
    const { num_pages } = metadata;
    try {
      let csvdata = [];
      for (let page = 1; page <= num_pages; page++) {
        const response = await getPutPostSubdomainData({
          ...payload,
          page_number: page,
        });
        csvdata = [...csvdata, ...response];
        logEvent(
          SlangSeverityLevels.INFO,
          SlangEvents.SUBDOMAIN_USER_DATA_FETCH_SUCCESS,
          {
            subDomainName,
            dataName: title,
            fetchType: "all_pages",
            fetch_id: fetchID,
          }
        );
      }
      return csvdata;
    } catch (e) {
      logEvent(
        SlangSeverityLevels.ERROR,
        SlangEvents.SUBDOMAIN_USER_DATA_FETCH_FAILURE,
        {
          subDomainName,
          dataName: title,
          error: e.toString(),
          fetchType: "all_pages",
          fetch_id: fetchID,
        }
      );
      console.error(e);
    }
  };

  const getSubDomainData = data_id => {
    const fetchID = v4();
    logEvent(
      SlangSeverityLevels.INFO,
      SlangEvents.SUBDOMAIN_USER_DATA_FETCH_STARTED,
      {
        subDomainName,
        dataName: title,
        fetchType: "current_page",
        fetch_id: fetchID,
      }
    );
    getPutPostSubdomainData({
      ID: id,
      data_id,
      is_user_data: isUserData,
      assistantType,
      env: "stage",
      version,
      assistant_version: version,
    })
      .then(data => {
        setResourceData(D => ({ ...D, csvdata: data }));
        logEvent(
          SlangSeverityLevels.INFO,
          SlangEvents.SUBDOMAIN_USER_DATA_FETCH_SUCCESS,
          {
            subDomainName,
            dataName: title,
            fetchType: "current_page",
            fetch_id: fetchID,
          }
        );
      })
      .catch(error => {
        logEvent(
          SlangSeverityLevels.ERROR,
          SlangEvents.SUBDOMAIN_USER_DATA_FETCH_FAILURE,
          {
            subDomainName,
            dataName: title,
            error: error.toString(),
            fetchType: "current_page",
            fetch_id: fetchID,
          }
        );
        console.log(error);
        setErrorMessage("There was an error loading this data!");
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (loading) {
      setErrorMessage(null);
      if (dataId) {
        getSubDomainData(dataId);
      } else {
        setLoading(false);
      }
    }
  }, [dataId, loading]);

  return loading ? (
    <LinearProgress style={{ marginTop: "20vh" }} />
  ) : errorMessage ? (
    <Typography variant="h6" align="center" color="error">
      {errorMessage}
    </Typography>
  ) : (
    <>
      <TableView
        userValues={resourceData}
        fileName={`${title}-user-values`}
        getAllSubdomainData={() => getAllSubdomainData(dataId)}
        isServerDownloadAvailable={Boolean(dataId)}
      />
      {dataId ? (
        <Typography variant="caption" align="center">
          (only first {resourceData.csvdata.length} rows)
        </Typography>
      ) : null}
    </>
  );
};

function ViewSubDomainDialog({
  classes,
  title,
  disabled,
  userValues,
  dataValues,
  assistantType,
  subDomainName,
}) {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!open) {
      setTabValue(0);
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (event, tabValue) => {
    setTabValue(tabValue);
  };

  return (
    <>
      <IconButton
        aria-label="View"
        className={classes.iconButton}
        onClick={handleClickOpen}
        disabled={disabled}
        title={`View ${title}`}
      >
        <ListIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton
            aria-label="Close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="off"
          >
            <Tab
              label={dataValues?.csvfields ? " Values" : " Values (N/A) "}
              disabled={!dataValues}
            />
            <Tab
              label={
                userValues?.csvfields ? "User Values" : "User Values (N/A) "
              }
              disabled={!userValues}
            />
          </Tabs>
          {tabValue === 0 && dataValues?.csvdata && (
            <SubDomainDataTable
              {...{
                title,
                subDomainDataValues: dataValues,
                assistantType,
                isUserData: false,
                subDomainName,
              }}
            />
          )}
          {tabValue === 1 && userValues?.csvfields && (
            <SubDomainDataTable
              {...{
                title,
                subDomainDataValues: userValues,
                assistantType,
                isUserData: true,
              }}
            />
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}

ViewSubDomainDialog.propTypes = {
  classes: PropTypes.object,
  dataValues: PropTypes.object,
  userValues: PropTypes.object,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  subDomainName: PropTypes.string,
  dataName: PropTypes.string,
};

export default withStyles(styles)(ViewSubDomainDialog);
