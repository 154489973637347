import { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAppDATA,
  getAppStringTable,
  getStdEntityTypes,
  changeAppDATA,
  clearAppDATA,
} from "../../../redux/actions/appSchema";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import { styles } from "../styles";
import SaveTemplateButton from "./SaveTemplateButton";
import TabsContainer from "../components/TabsContainer";
import {
  getSchemaDefinition,
  getTemplateLockStatus,
  putTemplateLockStatus,
  updateAssistantTemplate,
} from "../../../libs/slang/asstAPIs";
import { EditableVersions } from "../../../utils/versionUtils";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  Paper,
  Tooltip,
  Avatar,
  TextField,
  Fab,
} from "@material-ui/core";
import { HistoryOutlined, Lock, LockOpen } from "@material-ui/icons";
import ChangelogDialog from "./ChangelogDialog";

const useStyles = makeStyles(theme => ({
  ...styles,
  promptPaper: {
    width: "100%",
    padding: theme.spacing(2),
    background: theme.palette.background.default,
  },
  promptPaper2: {
    width: "100%",
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
  },
  button: {
    padding: theme.spacing(1),
    background: "#505050",
    borderRadius: "6px",
    margin: "0 0.5rem",
    boxShadow: "0px 2px 8px rgba(18, 18, 18, 0.3)",
  },
  switchButton: {
    padding: theme.spacing(1),
    background: "#505050",
    borderRadius: "6px",
    border: "1px solid gray",
  },
  avatar: {
    padding: theme.spacing(0.5),
    background: theme.palette.primary.main,
    height: "22px",
    width: "22px",
    fontSize: "14px",
    marginLeft: "0.2rem",
  },
}));

const TemplateHomeMain = props => {
  const { templateDetails, refreshTemplateDetails, setNotify } = props;
  const classes = useStyles();
  const { appDATA, appStringTable, errorRedux, user } = useSelector(state => ({
    appDATA: state.appSchema.appDATA,
    appStringTable: state.appSchema.appStringTable,
    appMetadata: state.appSchema.appMetadata,
    appList: state.appSchema.list,
    errorRedux: state.appSchema.error,
    user: state.login.username,
  }));
  const dispatchRedux = useDispatch();

  const editableVersions = new EditableVersions(
    templateDetails.versions?.map(version => version.versionString)
  );

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVersion, setSelectedVersion] = useState({
    versionString: "1.0.0",
    isFetching: true,
    assistantTemplateData: null,
  });
  const [schemaDefinition, setSchemaDefinition] = useState({
    isFetching: true,
  });
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [lockStatus, setLockStatus] = useState(false);
  const [lockedBy, setLockedBy] = useState("");

  useEffect(async () => {
    if (!isLoading) {
      const { id, name, version } = appDATA;
      getLockStatus(id, version).then(response => {
        const { lock_status, user } = response;
        setLockedBy(user);
        setLockStatus(lock_status);
      });
    }
  }, [isLoading]);

  const handleChangelogOpen = () => {
    setChangelogOpen(true);
  };
  const handleChangelogClosed = () => {
    setChangelogOpen(false);
  };

  const changeLogDetails =
    templateDetails.changelog[
      selectedVersion?.versionString
    ].description?.split(",");

  const handleEditAppData = (data, key) => {
    const newDATA = {
      ...appDATA,
      [key]: data,
    };
    dispatchRedux(changeAppDATA({ appDATA: newDATA }));
  };

  const handleSelectVersion = async versionString => {
    setSelectedVersion({
      versionString,
      isFetching: true,
      assistantTemplateData: null,
    });
    try {
      setIsLoading(true);
      dispatchRedux(getStdEntityTypes());
      dispatchRedux(
        getAppDATA({
          appID: templateDetails.id,
          assistant_version: versionString,
        })
      );
      dispatchRedux(
        getAppStringTable({
          appID: templateDetails.id,
          assistant_version: versionString,
        })
      );
      setSelectedVersion({
        versionString,
        isFetching: false,
        assistantTemplateData: { data: appDATA, stringTable: appStringTable },
      });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  const handleUpdateAssistantTemplate = async (
    versionUpdateType,
    title,
    description
  ) => {
    if (versionUpdateType) {
      try {
        setIsSaving(true);
        await updateAssistantTemplate({
          assistantTemplateVersion: selectedVersion?.versionString,
          assistantTemplateID: templateDetails.id,
          versionUpdateType,
          templateData: appDATA,
          templateStrings: appStringTable,
          title,
          description,
        });
        setNotify({
          variant: "success",
          message: "Successfully updated template",
        });
        refreshTemplateDetails();
        releaseLockOnSave();
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        setNotify({
          variant: "error",
          message: `Error while updating template"  ${error.toString()}`,
        });
        console.error(error);
      }
    }
  };

  const handleDeleteIntent = ID => {
    const newIntents = appDATA.intents;

    if (ID > -1) {
      newIntents.splice(ID, 1);
    }

    const newDATA = { ...appDATA, intents: newIntents };
    dispatchRedux(changeAppDATA({ appDATA: newDATA }));
  };

  const handleDeleteEntityData = (intent, entityType) => {
    let newUtterances = intent.examples;
    const entities = intent.entities;
    if (entityType) {
      const newEntities = entities.filter((entity, key) => {
        if (entity.type === entityType) {
          const name = entity.name;
          newUtterances = newUtterances.map(utterance => {
            const newUtterance = utterance.map(item => {
              if (item.entity === name) {
                return { text: item.text };
              } else {
                return item;
              }
            });
            return newUtterance;
          });
        }
        return entity.type !== entityType;
      });
      const newIntent = {
        ...intent,
        entities: newEntities,
        examples: newUtterances,
      };
      return newIntent;
    }
    return intent;
  };

  const handleDeleteEntityType = ID => {
    const newTypes = appDATA.types;
    const oldName = newTypes[ID].name;
    let newDATA = appDATA;
    const intents = newDATA.intents;

    const newIntents = intents.map(intent => {
      const newIntent = handleDeleteEntityData(intent, oldName);
      return newIntent;
    });

    if (ID > -1) {
      newTypes.splice(ID, 1);
    }

    newDATA = { ...newDATA, types: newTypes, intents: newIntents };
    dispatchRedux(changeAppDATA({ appDATA: newDATA }));
  };

  const handleNewEntityType = (
    newEntityType,
    ID = null,
    newName = null,
    oldName = null
  ) => {
    let newTypes = appDATA.types;
    const DATA = appDATA;

    if (ID !== null || ID === 0) {
      newTypes[ID] = newEntityType;
    } else if (ID === 0) {
      // do nothing
    } else {
      newTypes = [newEntityType, ...newTypes];
    }

    if (newName && oldName) {
      const newIntents = DATA.intents.map(intent => {
        const newEntities = intent.entities.map(entity => {
          if (entity.type === oldName) {
            return { ...entity, type: newName };
          } else {
            return entity;
          }
        });

        return { ...intent, entities: newEntities };
      });

      dispatchRedux(
        changeAppDATA({ appDATA: { ...DATA, intents: newIntents } })
      );
    } else if (oldName) {
      const newIntents = DATA.intents.map(intent => {
        const newIntent = intent.entities.map(entity => {
          if (entity.type === oldName) {
            return handleDeleteEntityData(intent, entity.name);
          } else {
            return intent;
          }
        });

        return newIntent;
      });

      dispatchRedux(
        changeAppDATA({ appDATA: { ...DATA, intents: newIntents } })
      );
    } else {
      dispatchRedux(
        changeAppDATA({
          appDATA: { ...appDATA, types: newTypes },
        })
      );
    }
  };

  const handleSelectChange = event => {
    handleSelectVersion(event.target.value);
  };
  useEffect(() => {
    if (errorRedux) {
      setNotify({
        variant: "error",
        message: `Error while getting template"  ${errorRedux.toString()}`,
      });
    }
    if (appDATA?.id && appStringTable) {
      setIsLoading(false);
    }

    return () => {};
  }, [errorRedux, appDATA, appStringTable]);
  useEffect(() => {
    (async () => {
      try {
        const result = await getSchemaDefinition();
        await handleSelectVersion(editableVersions.latest.versionString);
        setSchemaDefinition({ isFetching: false, data: result });
      } catch (error) {
        console.error(error);
      }
    })();
    return () => {
      dispatchRedux(clearAppDATA());
    };
  }, []);
  // get lock status
  const getLockStatus = async (templateID, templateVersion) => {
    try {
      if (!templateID || !templateVersion) return;
      const lockStatusResponse = await getTemplateLockStatus({
        templateID,
        templateVersion,
      });
      if (!lockStatusResponse)
        setNotify({
          variant: "error",
          message: "Couldn't get lock status",
        });
      return lockStatusResponse;
    } catch (error) {
      setNotify({
        variant: "error",
        message: error.toString(),
      });
      throw new Error(error);
    }
  };
  // enable or release the lock
  const enableReleaseLock = async (
    templateID,
    templateVersion,
    user,
    release
  ) => {
    try {
      if (!templateID || !templateVersion) return;
      const response = await putTemplateLockStatus({
        templateID,
        templateVersion,
        user,
        release,
      });
      if (!response)
        setNotify({
          variant: "error",
          message: "Not able to set the lock at the moment",
        });
      return response;
    } catch (error) {
      setNotify({
        variant: "error",
        message: error.toString(),
      });
      throw new Error(error);
    }
  };
  // Handle lock toggle
  const handleLock = () => {
    const { id, name, version } = appDATA;
    enableReleaseLock(id, version, user, lockStatus).then(response => {
      const { lock_status, user } = response;
      setLockStatus(lock_status);
      setLockedBy(user);
      if (lock_status) {
        setNotify({
          variant: "info",
          message: `Lock acquired for ${name}`,
        });
      } else {
        setNotify({
          variant: "info",
          message: `Lock released for ${name}`,
        });
      }
    });
  };

  // Release lock on template save
  const releaseLockOnSave = () => {
    const { id, name, version } = appDATA;
    if (lockStatus) {
      enableReleaseLock(id, version, user, true).then(response => {
        const { lock_status, user } = response;
        // TBD: To perform some task on release after save
      });
    }
  };

  const TabProps = {
    handleDeleteIntent,
    selectedVersion,
    editableVersions,
    schemaDefinition,
    templateDetails,
    handleUpdateAssistantTemplate,
    handleDeleteEntityType,
    handleNewEntityType,
    handleEditAppData,
    appDATA,
    appStringTable,
  };
  return (
    <>
      <Paper
        className={classes.promptPaper2}
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            id="left"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {selectedVersion && (
              <Typography variant="h6" component="span">
                {templateDetails.name}
              </Typography>
            )}
          </div>
          <div
            id="center"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl size={"small"} style={{ margin: "0 0.5em" }}>
              <TextField
                select
                labelId="select-version-label"
                id="select-version"
                value={selectedVersion?.versionString}
                onChange={handleSelectChange}
                variant="outlined"
                label="Version"
                size={"small"}
              >
                {templateDetails.versions.map(version => {
                  return (
                    <MenuItem
                      key={version.versionString}
                      value={version.versionString}
                    >
                      {editableVersions.includes(version.versionString) ? (
                        <EditIcon fontSize="inherit" />
                      ) : (
                        " "
                      )}{" "}
                      {version.versionString}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
            {changeLogDetails && (
              <Fab size="small">
                <HistoryOutlined
                  style={{ width: "18px" }}
                  onClick={handleChangelogOpen}
                />
              </Fab>
            )}
          </div>
          <div
            id="right"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {appDATA?.id &&
            appStringTable &&
            editableVersions?.includes(selectedVersion?.versionString) ? (
              <div
                id="right"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {lockStatus ? (
                  <Tooltip title={lockedBy} arrow>
                    <Avatar className={classes.avatar} component="span">
                      {lockedBy.split("")[0]}
                    </Avatar>
                  </Tooltip>
                ) : null}
                <Tooltip title={`${lockStatus ? "Unlock" : "Lock"}`}>
                  <Button
                    onClick={handleLock}
                    disabled={user !== lockedBy ? lockStatus : false}
                    variant="contained"
                    style={{ margin: "0 0.5em" }}
                  >
                    {!lockStatus ? (
                      <Lock style={{ width: "16px" }} />
                    ) : (
                      <LockOpen style={{ width: "16px" }} />
                    )}
                  </Button>
                </Tooltip>
                <SaveTemplateButton
                  handleUpdateAssistantTemplate={handleUpdateAssistantTemplate}
                  templateDetails={templateDetails}
                  templateVersion={selectedVersion?.versionString}
                  isLocked={user !== lockedBy ? lockStatus : false}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Paper>
      <ChangelogDialog
        {...{
          changelogOpen,
          handleChangelogClosed,
          classes,
          templateDetails,
          selectedVersion,
          changeLogDetails,
        }}
      />
      <Backdrop className={classes.backdrop} open={isSaving}>
        <Typography variant="h6" color="textPrimary">
          Hold tight we are saving your template &nbsp;
        </Typography>
        <CircularProgress />
      </Backdrop>
      <Grid container>
        <Grid xs={12} item>
          {appDATA?.id && appStringTable ? (
            <>
              <TabsContainer {...TabProps} />
            </>
          ) : isLoading ? (
            <LinearProgress />
          ) : (
            !selectedVersion?.isFetching && (
              <Typography variant="body2">
                Select a version from the adjoining list
              </Typography>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TemplateHomeMain;
