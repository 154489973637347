import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Fab from "@material-ui/core/Fab";

import { emptyStateStatements } from "../../../utils/promptUtils";
import { validatePromptTextLanguage } from "../../../utils/validate";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import AppConfig from "../../../app.config";

import theme from "../../theme";
import teal from "@material-ui/core/colors/teal";
import Color from "color";

const Langs = AppConfig.LANGUAGES;

const Levels = [1, 2, 3];

const styles = theme => ({
  root: {
    width: "100%",
  },
  list: {
    overflow: "auto",
  },
  expansionDetails: {
    paddingBottom: 0,
  },
  listWidth: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionInputList: {
    ...theme.shape,
    backgroundColor: theme.palette.background.default,
  },
  values: {
    marginRight: 25,
    fontWeight: 500,
    fontSize: "0.9rem",
    "& input": {
      fontSize: "0.9rem",
      padding: 2,
    },
  },
});

class ExpansionStatements extends Component {
  state = {
    expanded: "panel1",
    error: true,
    currentLevel: 1,
    StatementValues: this.props.StatementValues,
    langStatements: emptyStateStatements() || {},
  };

  componentDidMount() {
    this.setState({
      StatementValues: this.props.StatementValues,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        StatementValues: this.props.StatementValues,
      });
    }
  }

  handleOnChangeStatement = event => {
    const langCode = this.props.lang;
    const text = event.target.value;
    const name = event.target.name;
    const OK = validatePromptTextLanguage(event.target.value, "en");
    this.setState(state => {
      const langStatements = state.langStatements;
      return {
        langStatements: {
          ...langStatements,
          [langCode]: {
            ...langStatements[langCode],
            [name]: text,
          },
        },
        error: !OK,
      };
    });
  };

  updateNew = prompt => {
    const event = {
      target: { name: "", value: "", severity: this.state.currentLevel },
    };
    const langCode = this.props.lang;
    const len = this.props.StatementValues[prompt]
      ? this.props.StatementValues[prompt].length
      : 0;
    event.target.name = this.props.promptName + "." + prompt;
    event.target.value = this.state.langStatements[langCode][prompt].trim();
    this.props.onChangeText(event, len);

    this.setState({
      langStatements: emptyStateStatements(),
      error: true,
    });
  };

  handleOnClickNew = name => e => {
    this.updateNew(name);
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleOnKeyPress = (event, name) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (name !== "" && !this.state.error) {
        this.updateNew(name);
      }
    }
  };

  handleChangeLevel = (event, currentLevel) => {
    this.setState({ currentLevel });
  };

  render() {
    const {
      classes,
      onChangeText,
      StatementValues,
      lang,
      handleDelete,
      promptName,
      promptsExample,
      HELPTEXT,
      handleSaveAllPrompts,
    } = this.props;
    const { error, langStatements, currentLevel } = this.state;
    const successTemplates = StatementValues.successTemplates
      ? StatementValues.successTemplates.map((item, key) => ({
          ...item,
          idc: key,
        }))
      : [];
    const successTemplatesStatement = langStatements[lang].successTemplates;
    const langText = Langs.filter(item => item.code === lang)[0].text;
    const equalHelpText = idc => {
      const langEqualText =
        promptsExample && promptsExample[promptName].successTemplates;
      const check =
        langEqualText && langEqualText[idc] && langEqualText[idc].value !== "";
      return check
        ? `"${langEqualText[idc].value}" in ${langText} `
        : `${langText}  text for this prompt`;
    };
    const bgColorList = severity =>
      theme.palette.type === "light"
        ? Color(teal[severity * 100])
            .fade(0.8)
            .string()
        : Color(teal[(9 - severity * 2) * 100])
            .fade(0.6)
            .string();

    return (
      <div className={classes.root}>
        <Accordion expanded={true} defaultExpanded elevation={0}>
          <AccordionDetails className={classes.expansionDetails}>
            <div className={classes.listWidth}>
              <Tabs
                value={currentLevel}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChangeLevel}
              >
                {Levels.map(n => (
                  <Tab key={n} value={n} label={`Level ${n}`} />
                ))}
              </Tabs>
              <List
                className={`${testClassNames.INTENT_PROMPT_LIST} ${classes.list}`}
              >
                <ListItem className={classes.expansionInputList}>
                  <TextField
                    style={{ marginRight: 25, paddingTop: 15 }}
                    id="successTemplates-prompt"
                    name="successTemplates"
                    value={successTemplatesStatement}
                    onChange={this.handleOnChangeStatement}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyPress={e => {
                      this.handleOnKeyPress(e, "successTemplates");
                    }}
                    className={classes.values}
                    placeholder={`${HELPTEXT.PLACEHOLDER_EN} in ${langText}`}
                    helperText={HELPTEXT.EXAMPLE_EN}
                    type="text"
                    fullWidth
                  />
                  <ListItemSecondaryAction style={{ marginLeft: 5 }}>
                    <Fab
                      aria-label="Add"
                      color="primary"
                      style={{ height: 40, width: 40 }}
                      className={`${testClassNames.INTENT_PROMPT_ADD_BUTTON}`}
                      onClick={this.handleOnClickNew("successTemplates")}
                      disabled={error}
                    >
                      <Add />
                    </Fab>
                  </ListItemSecondaryAction>
                </ListItem>
                {successTemplates
                  .filter(a => a.severity === currentLevel)
                  .map((item, key) => {
                    return (
                      <ListItem
                        key={key}
                        style={{
                          width: "100%",
                          backgroundColor: bgColorList(item.severity),
                        }}
                      >
                        <TextField
                          name={promptName + ".successTemplates"}
                          placeholder={equalHelpText(item.idc)}
                          value={item.value}
                          onChange={e => {
                            onChangeText(e, item.idc);
                          }}
                          onBlur={handleSaveAllPrompts}
                          onKeyPress={e => {
                            this.handleOnKeyPress(e, "");
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.values}
                          type="text"
                          margin="dense"
                          fullWidth
                          required
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label="Delete"
                            className={`${testClassNames.INTENT_PROMPT_DELETE_BUTTON}`}
                            onClick={handleDelete.bind(
                              this,
                              "successTemplates",
                              promptName,
                              item.idc
                            )}
                          >
                            <Remove />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

ExpansionStatements.propTypes = {
  classes: PropTypes.object.isRequired,
  StatementValues: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpansionStatements);
