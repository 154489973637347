import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/RemoveCircle";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";

import Checkbox from "@material-ui/core/Checkbox";
import AddDialog from "./AddDialog";
import EditSubDomain from "./EditSubDomain";

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "default",
  },

  expandDetails: {
    display: "initial",
    padding: 0,
  },

  content: {
    cursor: "default",
    margin: "12px 0",
    "&$expanded": {
      margin: "10px 0",
    },
  },
  expanded: {},
}));

function OrderList(props) {
  const { subDomainID, handleEditAppData, subdomains } = props;

  const moveSubDomain = (from, to) => () => {
    const newData = [];

    newData[to] = subdomains[from];
    newData[from] = subdomains[to];

    subdomains.forEach((item, i) => {
      if (i === to || i === from) {
      } else {
        newData[i] = item;
      }
    });
    handleEditAppData(newData, "subDomains");
  };
  return (
    <>
      <IconButton
        onClick={moveSubDomain(subDomainID, subDomainID - 1)}
        disabled={subDomainID === 0}
        title={"Move this sub domain up."}
      >
        <UpIcon style={{ fontSize: 18 }} />
      </IconButton>
      <IconButton
        onClick={moveSubDomain(subDomainID, subDomainID + 1)}
        disabled={subDomainID === subdomains.length - 1}
        title={"Move this sub domain down."}
      >
        <DownIcon style={{ fontSize: 18 }} />
      </IconButton>
    </>
  );
}
function SubDomainAccordion(props) {
  const {
    children,
    handleToggle,
    handleDeleteSubDomain,
    subDomainID,
    subDomain,
    handleEditAppData,
    subdomains,
  } = props;

  const classes = useStyles();
  const [expand, setExpand] = useState(null);

  const handleExpand = panel => (event, expanded) => {
    const newExpand = expand ? panel : false;
    setExpand(newExpand);
  };

  const EditSubDomainDataProps = {
    subDomainID,
    subDomain,
    handleEditAppData,
    subdomains,
  };

  return (
    <Accordion
      key={subDomain.name}
      // expanded={subDomain.enabled}
      expanded={true}
      onChange={handleExpand(subDomain.name)}
      style={{ cursor: "default" }}
    >
      <AccordionSummary
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
        }}
        IconButtonProps={{
          onClick: handleDeleteSubDomain({ subDomainID, name: subDomain.name }),
        }}
        expandIcon={<DeleteIcon title="Delete sub domain" />}
      >
        <Checkbox
          color="primary"
          checked={subDomain.enabled}
          onClick={handleToggle(subDomain.name)}
          title="Enable or disable this sub domain"
        />
        <EditSubDomain {...EditSubDomainDataProps} />
        <OrderList {...props} />
        &nbsp; &nbsp;
        <AddDialog
          title="Add data type"
          {...{ handleEditAppData, subdomains, subDomainID }}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.expandDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

SubDomainAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleDeleteSubDomain: PropTypes.func.isRequired,
  handleEditAppData: PropTypes.func.isRequired,
  subDomainID: PropTypes.number.isRequired,
  subDomain: PropTypes.object.isRequired,
  subdomains: PropTypes.array.isRequired,
};

export default SubDomainAccordion;
