import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { emptyUIHintsState } from "../../../../utils/MiscUtils";
import {
  APP_STATE_SUFFIX,
  CONDITION_SUFFIX,
  VOICE_ASSIST_SUFFIX,
} from "../../../../utils/voiceAssistUtils";

const initialData = {
  name: "",
  description: "",
  displayName: "",
  appStateName: "",
  conditionName: "",
  parameters: [""],
  isTerminal: false,
  uiHints: [],
  completionContext: {
    type: "completion",
    data: "",
  },
  completion: {
    successTemplates: [
      {
        isId: false,
        value: "New_AppState_Prompt_Placeholder",
        severity: 1,
      },
    ],
    failureTemplates: [],
  },
};

const AddEditTriggerForm = props => {
  const {
    selectedAppState,
    userJourney,
    userJourneyID,
    handleSaveClose,
    appStateUID,
  } = props;

  const { enabledSD } = useSelector(state => ({
    enabledSD: state.appSchema.appDATA.subDomains.filter(SD => SD.enabled),
  }));

  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [name, setName] = useState("");
  const [conditionName, setConditionName] = useState("");
  const [appStateName, setAppStateName] = useState("");
  const [selectError, setSelectError] = useState(false);
  const isEditMode = selectedAppState !== undefined;

  const handleChangeName = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDisplayName(text.replace(/_/g, " "));
      const currentName = text.replace(/ /g, "_");
      const doesNameExist = userJourney.appStatesMetadata.find(
        ap =>
          ap.name ===
          `${userJourney.name}_${currentName}${APP_STATE_SUFFIX}${currentName}${CONDITION_SUFFIX}${VOICE_ASSIST_SUFFIX}`
      );

      if (doesNameExist) {
        setSelectError("Trigger name exists");
      } else {
        setSelectError(false);
      }
      setName(currentName);
      handleChangeDisplayName(currentName);
      handleChangeAppStateName(currentName);
      handleChangeConditionName(currentName);
    }
  };

  const handleChangeAppStateName = currentName => {
    setAppStateName(`${currentName}${APP_STATE_SUFFIX}`);
  };
  const handleChangeConditionName = currentName => {
    setConditionName(`${currentName}${CONDITION_SUFFIX}`);
  };
  const handleChangeDisplayName = currentName => {
    const displayName = currentName
      .replace(/_/g, " ")
      .replace(/\w/g, x => x.toUpperCase());
    setDisplayName(displayName);
  };
  const handleSubmitData = () => {
    const newAppStatesMetadata = userJourney.appStatesMetadata;

    const payload = {
      name: `${userJourney.name}_${appStateName}_${conditionName}${VOICE_ASSIST_SUFFIX}`,
      description,
      displayName,
      appStateName,
      conditionName,
      isTerminal: false,
    };

    if (isEditMode) {
      newAppStatesMetadata[selectedAppState] = {
        ...newAppStatesMetadata[selectedAppState],
        ...payload,
      };
    } else {
      newAppStatesMetadata[newAppStatesMetadata.length] = {
        ...initialData,
        ...payload,
        uiHints: emptyUIHintsState(enabledSD),
      };
    }

    userJourney.appStatesMetadata = newAppStatesMetadata;
    handleSaveClose(userJourney, userJourneyID);
  };

  const handleChangeDescription = event => {
    const text = event.target.value;
    if (text.length < 140) {
      setDescription(text);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      const selectedAppStateData = userJourney.appStatesMetadata[appStateUID];
      setName(selectedAppStateData.appStateName.replace("_app_state", ""));
      setDescription(selectedAppStateData.description);
    }
  }, [appStateUID]);

  return (
    <div>
      <form noValidate autoComplete="off">
        <Grid spacing={3} alignContent="center" alignItems="center" container>
          <Grid xs={12} item>
            <TextField
              id="standard-name"
              label="Trigger Name"
              name="name"
              value={name}
              onChange={handleChangeName}
              error={selectError !== false}
              helperText={
                selectError
                  ? `${selectError}`
                  : "This will be used as the Trigger identifier"
              }
              contentEditable={false}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="standard-description"
              label="Description"
              name="description"
              value={description}
              onChange={handleChangeDescription}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <Button
              disabled={selectError}
              onClick={handleSubmitData}
              color="primary"
              fullWidth
            >
              {isEditMode ? "Save" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddEditTriggerForm;
