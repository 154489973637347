import { makeStyles } from "@material-ui/core";
import SlangSurfaceHeader from "./SlangSurfaceHeader";
import SlangSurfacePermaText from "./SlangSurfacePermaText";
import SlangSurfaceFooter from "./SlangSurfaceFooter";
import SlangSurfaceAnimation from "./SlangSurfaceAnimation";
import SlangBranding from "./SlangBranding";

const styles = makeStyles(theme => ({
  SlangSurfaceGradient: {
    fontWeight: 400,
    left: 0,
    right: 0,
    bottom: "0em",
    borderRadius: "0em",
    margin: "auto 0",
    lineHeight: 1.2,
    boxShadow: "none",
    position: "absolute",
    fontSize: "1em",
    backgroundImage: props =>
      props.surfaceUIMode === "dark"
        ? "linear-gradient(rgba(18,18,18,0) 0%, rgba(18,18,18,0.75) 50%, rgba(18,18,18,1) 65%)"
        : "linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,1) 65%)",
    border: "none",
    zIndex: -1,
    width: 330,
    minWidth: 300,
    maxWidth: 325,
    color: props => (props.surfaceUIMode === "dark" ? "#ffffff" : "#181818"),
    padding: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
  },
  SlangSurfaceCard: {
    fontWeight: 400,
    left: "0.37em",
    right: 0,
    bottom: "0.05em",
    borderRadius: "10px",
    margin: "auto 0",
    lineHeight: 1.2,
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.32)",
    position: "absolute",
    fontSize: "1em",
    backgroundColor: props =>
      props.surfaceUIMode === "dark" ? "#181818" : "#ffffff",
    border: "none",
    zIndex: -1,
    width: 315,
    minWidth: 300,
    maxWidth: 325,
    color: props => (props.surfaceUIMode === "dark" ? "#ffffff" : "#181818"),
    padding: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
  },
  SlangSurfaceCurved: {
    fontWeight: 400,
    left: 0,
    right: 0,
    bottom: "0.1em",
    borderRadius: "100% 100% 0px 0px",
    margin: "auto 0",
    lineHeight: 1.2,
    boxShadow: "0em",
    position: "absolute",
    fontSize: "1em",
    backgroundColor: props =>
      props.surfaceUIMode === "dark" ? "#181818" : "#ffffff",
    border: "none",
    zIndex: -1,
    width: 325,
    minWidth: 300,
    maxWidth: 325,
    color: props => (props.surfaceUIMode === "dark" ? "#ffffff" : "#181818"),
    padding: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
  },
  SlangSeparator: {
    border: 0,
    height: "1px",
    margin: 0,
    backgroundImage:
      "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))",
    filter: "invert(.6)",
  },
  UserTextContainer: {
    textAlign: "center",
    fontStyle: "italic",
    minHeight: 36,
    padding: "0 0.5rem",
  },
  SlangTextContainer: {
    textAlign: "center",
    minHeight: 36,
    padding: "0 0.5rem",
  },
  SlangSurfaceFooterContainer: {
    position: "relative",
  },
}));

const SlangSurface = props => {
  const { surfaceHintStyle, brandColor, surfaceUIMode, surfaceStyle } = props;
  const classes = styles(props);
  return (
    <div
      className={
        surfaceStyle === "gradient"
          ? classes.SlangSurfaceGradient
          : surfaceStyle === "card"
          ? classes.SlangSurfaceCard
          : surfaceStyle === "curved"
          ? classes.SlangSurfaceCurved
          : classes.SlangSurfaceGradient
      }
    >
      <SlangSurfaceHeader {...{ surfaceUIMode, surfaceStyle }} />
      <SlangSurfacePermaText
        {...{ surfaceHintStyle, surfaceUIMode, surfaceStyle }}
      />
      <hr className={classes.SlangSeparator} />
      <div className={classes.SlangTextContainer}>
        <p>Show me organic onions</p>
      </div>
      <div className={classes.SlangSurfaceFooterContainer}>
        <SlangSurfaceFooter {...{ brandColor, surfaceUIMode }} />
      </div>
      <SlangSurfaceAnimation type={"speaking"} {...{ brandColor }} />
      <SlangBranding {...{ brandColor }} />
    </div>
  );
};

export default SlangSurface;
