import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core/";

const styles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const SelectList = props => {
  const { listItems, selectedValue, setSelectedValue } = props;

  const classes = styles();

  const handleSelectChangeChange = event => {
    setSelectedValue(event.target.value);
  };

  if (!listItems?.length) return null;

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id="simple-select-subdomain-outlined-label">
          Subdomain
        </InputLabel>
        <Select
          labelId="simple-select-subdomain-outlined-label"
          value={selectedValue}
          onChange={handleSelectChangeChange}
          label="Select Subdomain"
        >
          {listItems.map((item, ID) => (
            <MenuItem key={item.subdomain + ID} value={ID}>
              {item.subdomain}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

SelectList.propType = {
  listItems: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
};

export default SelectList;
