import { makeStyles } from "@material-ui/core";
import SlangMuteIcon from "./assets/SlangMuteIcon";
const styles = makeStyles(theme => ({
  SlangFlexContainer: {
    position: "absolute",
    width: "100%",
    bottom: -26,
    zIndex: 2147474648,
  },
  SlangSurfaceFooterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  SlangMuteButton: {
    marginTop: 2,
    width: "32px",
    height: "18px",
    border: props =>
      `1px solid ${props.surfaceUIMode === "dark" ? "#fff" : props.brandColor}`,
    borderRadius: 10,
    cursor: "pointer",
    backgroundColor: props =>
      props.surfaceUIMode === "dark" ? "#fff" : props.brandColor,
    padding: "2px 6px",
    filter: props => (props.surfaceUIMode === "dark" ? "invert(.1)" : ""),
  },
  SlangSelectLang: {
    minWidth: "32px",
    height: "18px",
    borderRadius: 10,
    backgroundColor: props =>
      props.surfaceUIMode === "dark" ? "#fff" : props.brandColor,
    color: props =>
      props.surfaceUIMode === "dark" ? props.brandColor : "#fff",
    fontSize: 9,
    fontWeight: 400,
    padding: "3px 6px",
    border: props =>
      `1px solid ${props.surfaceUIMode === "dark" ? "#fff" : props.brandColor}`,
    appearance: "none",
    cursor: "pointer",
    textAlign: "center",
  },
}));

const SlangSurfaceFooter = props => {
  const { surfaceUIMode } = props;
  const classes = styles(props);
  return (
    <div className={classes.SlangFlexContainer}>
      <div className={classes.SlangSurfaceFooterContainer}>
        <SlangMuteIcon
          fill={surfaceUIMode === "dark" ? "#181818" : "#ffffff"}
          className={classes.SlangMuteButton}
        />

        <div className={classes.SlangSelectLang}>{"English (IN)"}</div>
      </div>
    </div>
  );
};

export default SlangSurfaceFooter;
