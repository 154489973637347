import { useState } from "react";
import CreateApp from "../../../assets/createApp.png";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { testClassNames } from "../../../utils/integrationTestClassNames";
import NewTemplateEditor from "../components/NewTemplateEditor";

import {
  validateAppName,
  namespace_name_regex_reverse,
} from "../../../utils/validate";

import DocHelpLink from "../../common/DocLinks";

const CreateAssistantDomainDialog = (props: any) => {
  const { classes, handleClose, handleCreateNewAssistantDomain, state } = props;

  const { isOpen, existingDomains, newCanonicalDomainsList, schemaDefinition } =
    state;

  const [selectedCanonicalDomainName, setSelectedCanonicalDomainName] =
    useState("");
  const [newDomainName, setNewDomainName] = useState<string>("");
  const [newDomainDescription, setNewDomainDescription] = useState<string>("");
  const [newDomainNameError, setNewDomainNameError] = useState<boolean>(true);
  const [isTemplateValid, setIsTemplateValid] = useState<boolean>(false);
  const [
    assistantTemplateDataTextWithoutNameOrID,
    setAssistantTemplateDataTextWithoutNameOrID,
  ] = useState(JSON.stringify({}));
  const [assistantTemplateStringDataText, setAssistantTemplateStringDataText] =
    useState(JSON.stringify({}));
  const PAGES = {
    DOMAIN_DETAILS: "DOMAIN_DETAILS",
    TEMPLATE_DETAILS: "TEMPLATE_DETAILS",
  };
  const [currentPage, setCurrentPage] = useState(PAGES.DOMAIN_DETAILS);

  const handleChangeDomainName = (name: string) => {
    const text = name
      .replace(namespace_name_regex_reverse, "")
      .replace(/(\.\.)+/g, ".");
    const valid = validateAppName(
      text,
      existingDomains.map((item: any) => {
        return { ...item, name: item.display_name };
      })
    );
    setNewDomainName(text);
    setNewDomainNameError(!valid);
  };

  const clearState = () => {
    setAssistantTemplateDataTextWithoutNameOrID(JSON.stringify({}));
    setAssistantTemplateStringDataText(JSON.stringify({}));
    setNewDomainDescription("");
    setNewDomainName("");
    setNewDomainNameError(true);
    setSelectedCanonicalDomainName("");
    setCurrentPage(PAGES.DOMAIN_DETAILS);
  };

  const handleCancel = () => {
    clearState();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <div className={classes.dialogCreate}>
        <Typography variant="h6" gutterBottom className={classes.textColor}>
          {"New Assitant Domain"} <DocHelpLink docFor="BUDDY" />
        </Typography>
        <DialogContent>
          <img src={CreateApp} alt="Slang Labs" className={classes.logo} />
          <div>
            {currentPage === PAGES.DOMAIN_DETAILS ? (
              <div style={{ width: "100%" }}>
                <FormControl
                  color="primary"
                  className={classes.createInput}
                  error={newDomainNameError || !selectedCanonicalDomainName}
                  aria-describedby="create-app-name-text"
                >
                  <InputLabel
                    htmlFor="canonical-domain"
                    error={!selectedCanonicalDomainName}
                  >
                    Canonical Domain
                  </InputLabel>

                  <Select
                    inputProps={{
                      name: "canonical-domain",
                      id: "canonical-domain",
                    }}
                    value={selectedCanonicalDomainName}
                    onChange={event => {
                      setSelectedCanonicalDomainName(
                        event.target.value as string
                      );
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {newCanonicalDomainsList.map((canonicalDomain: any) => (
                      <MenuItem
                        value={canonicalDomain.name}
                        key={canonicalDomain.name}
                      >
                        <em>{canonicalDomain.name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    id="create-domain-name"
                    name="newDomainName"
                    className={classes.createInput}
                    color="primary"
                    label="Domain Name"
                    inputProps={{
                      "aria-label": "New Domain Name",
                    }}
                    type="text"
                    onChange={event => {
                      handleChangeDomainName(event.target.value);
                    }}
                    value={newDomainName}
                    error={newDomainNameError}
                    required
                  />
                  <TextField
                    autoFocus
                    id="create-app-description"
                    name="createAppDescription"
                    className={classes.createInput}
                    color="primary"
                    label="Domain Description"
                    inputProps={{
                      "aria-label": "New Domain Description",
                    }}
                    type="text"
                    onChange={event => {
                      setNewDomainDescription(event.target.value);
                    }}
                    value={newDomainDescription}
                  />
                </FormControl>
                <br />
                <br />
                <Typography variant="caption" gutterBottom paragraph>
                  {"Unique name; e.g. app.camera.com or My Camera app V1"}
                </Typography>

                <br />
                <br />
                <Button
                  variant="text"
                  classes={{
                    root: classes.eTypeDone,
                    disabled: classes.disabled,
                  }}
                  className={`${testClassNames.BUDDY_CREATE_BUTTON}`}
                  type="submit"
                  disabled={newDomainNameError || !selectedCanonicalDomainName}
                  onClick={() => {
                    setCurrentPage(PAGES.TEMPLATE_DETAILS);
                  }}
                >
                  {"Next"}
                </Button>
                <br />
                <br />
                <Button
                  variant="outlined"
                  className={`${classes.cancelButton} ${testClassNames.BUDDY_CANCEL_BUTTON}`}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div>
                <NewTemplateEditor
                  editable
                  schemaDefinition={schemaDefinition}
                  setIsTemplateValid={setIsTemplateValid}
                  assistantTemplateDataTextWithoutNameOrID={
                    assistantTemplateDataTextWithoutNameOrID
                  }
                  setAssistantTemplateDataTextWithoutNameOrID={
                    setAssistantTemplateDataTextWithoutNameOrID
                  }
                  assistantTemplateStringDataText={
                    assistantTemplateStringDataText
                  }
                  setAssistantTemplateStringDataText={
                    setAssistantTemplateStringDataText
                  }
                />
                <Button
                  variant="text"
                  classes={{
                    root: classes.eTypeDone,
                    disabled: classes.disabled,
                  }}
                  className={`${testClassNames.BUDDY_CREATE_BUTTON}`}
                  type="submit"
                  disabled={
                    newDomainNameError ||
                    !selectedCanonicalDomainName ||
                    !isTemplateValid
                  }
                  onClick={() => {
                    handleCreateNewAssistantDomain({
                      newDomainCanonicalName: selectedCanonicalDomainName,
                      newDomainDisplayName: newDomainName,
                      newDomainDescription,
                      assistantTemplateData: JSON.parse(
                        assistantTemplateDataTextWithoutNameOrID
                      ),
                      assistantTemplateStringTable: JSON.parse(
                        assistantTemplateStringDataText
                      ),
                    });
                    clearState();
                  }}
                >
                  {"Create new assistant Domain"}
                </Button>
                <br />
                <br />
                <Button
                  variant="outlined"
                  className={`${classes.cancelButton} ${testClassNames.BUDDY_CANCEL_BUTTON}`}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions />
      </div>
    </Dialog>
  );
};

export default CreateAssistantDomainDialog;
