import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "../withTheme";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";

import { styles } from "./styles.js";

import TopNav from "../common/topNav";
import SideNav from "../common/sideNav";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../libs/analytics/slangAnalyticsAPIs";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    logEvent(SlangSeverityLevels.INFO, SlangEvents.HELP_VISITED);
  }

  render() {
    const { classes, match } = this.props;

    return (
      <div className={classes.appFrame}>
        <SideNav currentPage="HELP" appname={match.params.appname} />
        <main className={classes.content}>
          <TopNav />
          <Fade in={true}>
            <div className={classes.margin}>
              <Grid
                justify="flex-start"
                direction="row"
                alignItems="flex-start"
                container
              >
                <Grid xs={12} sm={8} md={6} lg={5} item>
                  <Typography variant="h5">How To Integrate Slang</Typography>
                </Grid>
              </Grid>
              <Grid item sm={12} md={10}>
                <Paper className={classes.paper} elevation={0}>
                  <Grid container className={classes.margin}>
                    <Grid
                      justify="flex-start"
                      direction="row"
                      alignItems="center"
                      container
                      className={classes.margin}
                    >
                      <Grid container xs={11} item className={classes.space}>
                        <Typography variant="subtitle1">
                          Integrating Slang into your app involves the following
                          steps.
                        </Typography>
                      </Grid>
                      <Grid container xs={11} item className={classes.space}>
                        <Grid item xs={12} sm={8} md={4}>
                          <Typography className={classes.sideHeading}>
                            STEP 1:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                          <Typography>
                            Integrate the Slang SDK into your app and register
                            for events to be fired when those use-cases are
                            detected by Slang
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container xs={11} item className={classes.space}>
                        <Grid item xs={12} sm={8} md={4}>
                          <Typography className={classes.sideHeading}>
                            STEP 2:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                          <Typography>
                            Handle the events to do app-specific actions.
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container xs={11} item className={classes.space}>
                        <Grid item xs={12} sm={8} md={4}>
                          <Typography className={classes.sideHeading}>
                            {" "}
                            DEVELOPER RESOURCES
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                          <Typography component="ul">
                            <li>
                              <a
                                href="https://docs.slanglabs.in/slang/getting-started/building-your-first-buddy"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Building Your First Buddy
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://docs.slanglabs.in/slang/getting-started/for-android-apps"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Android Quick Start
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://docs.slanglabs.in/slang/getting-started/for-web-apps"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                WebSDK Quick Start
                              </a>
                            </li>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container xs={11} item className={classes.space}>
                        <Typography>
                          Please write to{" "}
                          <a href="mailto:42@slanglabs.in">42@slanglabs.in</a>{" "}
                          for questions, feedback, support or anything else.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Fade>
        </main>
      </div>
    );
  }
}

Help.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(Help));
