import { createRef, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import withTheme from "../../withTheme";
import { main } from "../styles.js";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { CopyToClipboard } from "react-copy-to-clipboard";

import Notifications from "../../common/Notifications";
import SDKVersions from "../../common/SDKVersions";
import AppNameEdit from "../components/AppNameEdit";
/* Might need this in future  */
// import NewIntent from "../components/NewIntent";
import PromptsEdit from "./PromptsEdit";
import AppSettingsTabs from "./AppSettingsTabs";
// import AppStatesPromptsEdit from "./UserJourneysEdit/AppStatesPromptsEdit";
import asyncComponent from "../../common/AsyncComponent";
import { checkIfUserBelongsToSlangLabs } from "../../../utils/userUtils";
import Debugger from "./Debugger";
import TryAssistant from "./TryAssistant";
import { isSlangLabsUser } from "../../../utils/auth/handleAuthState";

import {
  validateIntentName,
  validateAppName,
  namespace_name_regex_reverse,
} from "../../../utils/validate";

import {
  getApps,
  getAppDATA,
  getAppStringTable,
  getAppMetaData,
  changeAppDATA,
  editAppList,
  getAppSchemaDef,
  appNameChange,
  getAppMetadataTrainStatus,
} from "../../../redux/actions/appSchema";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../libs/analytics/slangAnalyticsAPIs";
import SubDomainEdit from "./SubDomainEdit/SubDomainEdit";
import UserJourneysEdit from "./UserJourneysEdit/UserJourneysEdit";
import { LinearProgress, Typography } from "@material-ui/core";
import TutorialWrapper from "../../common/TutorialWrapper";

const JsonEditor = asyncComponent(() =>
  import("../components/JsonEditorSchema")
);

const mapDispatchToProps = dispatch => ({
  getAppDATA: (appid, assistant_version) => {
    dispatch(getApps());
    dispatch(getAppDATA(appid, assistant_version));
    dispatch(getAppStringTable(appid, assistant_version));
    dispatch(getAppMetaData(appid, assistant_version));
    dispatch(getAppMetadataTrainStatus(appid, assistant_version));
  },
  changeAppDATA: appid => dispatch(changeAppDATA(appid)),
  editAppName: name => {
    dispatch(appNameChange({ hasNameChanged: true }));
    dispatch(changeAppDATA(name));
  },
  editAppList: name => dispatch(editAppList(name)),
  getAppSchemaDef: () => dispatch(getAppSchemaDef()),
});

export class AssistantConfigEditMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAppID: this.props.appList
        ? this.props.appList.filter(
            item =>
              item.name === this.props.appName &&
              item.major_version === this.props.appVersion
          )
        : [
            {
              id: "",
            },
          ],
      appIntents: null,
      appTypes: null,
      appID: "",
      createIntentName: "",
      createIntentDescription: "",
      openCreate: false,
      errorList: null,
      createError: null,
      editAppNameClick: false,
      editAppName: "",
      appNameError: false,
      appName: this.props.appName,
      tabValue: 0,
      isQRCodeDialogOpen: false,
      notify: {
        open: false,
        message: "",
      },
    };
    this.subdomainTabRef = createRef();
    this.userJourneyTabRef = createRef();
    this.appSettingsTabRef = createRef();
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    const redirect = "/home";
    this.props.history.replace(redirect);
  };

  handleCloseNewIntent = () => {
    this.setState({
      openCreate: false,
    });
  };

  handleOpenNewIntent = () => {
    this.setState({
      openCreate: true,
    });
  };

  handleChangeIntentName = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "createIntentName") {
      // eslint-disable-next-line
      const text = value.replace(/([^a-zA-Z_]+)/g, "");
      const valid =
        text.length > 0
          ? validateIntentName(text, this.props.appDATA.intents)
          : true;

      this.setState({
        createIntentName: text,
        createError: !valid,
      });
    } else if (name === "createIntentDescription") {
      const text = value.replace(/([^[0-9]!\?a-zA-Z_ ]+)/g, "");
      if (text.length < 141)
        this.setState({
          createIntentDescription: text,
        });
    }
  };

  handleEditAppData = (data, key) => {
    const newDATA = {
      ...this.props.appDATA,
      [key]: data,
    };
    this.props.changeAppDATA({ appDATA: newDATA });
  };

  handleCreateNewIntent = event => {
    event.preventDefault();

    const newIntent = {
      name: this.state.createIntentName,
      description: this.state.createIntentDescription.trim(),
      examples: [],
      entities: [],
    };

    const newDATA = {
      ...this.props.appDATA,
      intents: [newIntent, ...this.props.appDATA.intents],
    };

    this.props.changeAppDATA({ appDATA: newDATA });

    logEvent(SlangSeverityLevels.INFO, SlangEvents.INTENT_CREATED, {
      intent_name: this.state.createIntentName,
    });

    this.setState({
      createIntentName: "",
      openCreate: false,
    });
    this.props.history.push(
      "/home/assistants/" +
        this.props.appDATA.name +
        "/" +
        this.state.createIntentName
    );
  };

  handleGetApps = _ => {
    this.props.getAppDATA({
      appID: this.state.filterAppID[0].id,
      assistant_version: this.state.filterAppID[0].major_version + ".0.0",
    });
  };

  handleDeleteIntent = ID => {
    const newIntents = this.state.appIntents;

    logEvent(SlangSeverityLevels.INFO, SlangEvents.INTENT_DELETED, {
      intent_name: newIntents[ID].name,
    });

    if (ID > -1) {
      newIntents.splice(ID, 1);
    }

    this.setState({
      appIntents: newIntents,
    });
    const newDATA = { ...this.props.appDATA, intents: newIntents };
    this.props.changeAppDATA({ appDATA: newDATA });
  };

  checkApp = () => {
    const filterAppID = this.props.appList.length
      ? this.props.appList.filter(
          item =>
            item.name === this.props.appName &&
            item.major_version === this.props.appVersion
        )
      : [];
    const { appDATA, appStringTable } = this.props;
    if (appDATA && appDATA.id && appStringTable) {
      this.setState({
        appIntents: this.props.appDATA.intents,
        appTypes: this.props.appDATA.types,
      });
    } else if (this.props.appName && filterAppID.length) {
      this.props.getAppDATA({
        appID: this.state.filterAppID[0].id,
        assistant_version: this.state.filterAppID[0].major_version + ".0.0",
      });
    } else {
      this.props.history.push("/home");
    }
  };

  componentDidMount() {
    logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_OPENED, {
      assistant_id:
        this.state.filterAppID && this.state.filterAppID.length
          ? this.state.filterAppID[0].id
          : null,
      assistant_name: this.state.appName,
      version:
        this.state.filterAppID && this.state.filterAppID.length
          ? this.state.filterAppID[0].major_version
          : null,
    });

    this.props.getAppSchemaDef();
    this.checkApp();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.appDATA !== this.props.appDATA) {
      this.checkApp();
    }

    if (prevProps.error !== this.props.error && this.props.error) {
      this.setState({
        open: true,
        dialogTitle: "There was an error getting the buddy data!",
        appIntents: [],
        appTypes: [],
      });
    }
  }

  handleEditAppNameClick = name => {
    if (!this.state.editAppNameClick) {
      this.setState({
        editAppNameClick: true,
        editAppName: name,
      });
    }
  };

  handleChangeAppName = event => {
    // eslint-disable-next-line
    const text = event.target.value
      // eslint-disable-next-line
      .replace(namespace_name_regex_reverse, "")
      .replace(/(\.\.)+/g, ".");
    const listOfApps = this.props.appList.filter(
      item =>
        item.name !== this.props.appName &&
        item.major_version === this.props.appVersion
    );
    const valid = text.length > 0 ? validateAppName(text, listOfApps) : true;

    this.setState({
      editAppName: text,
      appNameError: !valid,
    });
  };

  handleSaveAppName = () => {
    const newname = this.state.editAppName;
    const GO = !this.state.appNameError && newname;
    if (GO) {
      const newAppData = this.props.appDATA;
      newAppData.name = newname;

      let newAppList = this.props.appList;

      newAppList = newAppList.map(item => {
        if (item.id === this.props.appDATA.id) {
          return { ...item, name: newname };
        }

        return item;
      });

      this.props.editAppList({
        appList: newAppList,
      });

      this.props.editAppName({
        appDATA: newAppData,
      });

      logEvent(SlangSeverityLevels.INFO, SlangEvents.APP_NAME_UPDATED, {
        old_app_name: this.state.appName, // TODO: verify.
        new_app_name: newname,
      });

      this.setState({
        editAppNameClick: false,
        editAppName: "",
        appName: newname,
      });

      setTimeout(() => {
        this.props.handleAppNameChange(newname, this.props.appVersion);
      }, 300);
    }
  };

  onKeyPress = event => {
    if (event.key === "Enter") {
      this.handleSaveAppName();
    }
  };

  handleClickAway = _event => {
    this.setState({
      editAppNameClick: false,
      appNameError: false,
    });
  };

  handleChangeTab = (_event, tabValue) => {
    this.setState({ tabValue });
  };

  changeUserJourneyData = (data, idx) => {
    const userJourneysState = this.props.appDATA.userJourneys.map((obj, ID) => {
      if (ID === idx) {
        return data;
      }
      return obj;
    });
    this.handleEditAppData(userJourneysState, "userJourneys");
  };

  copytoClip = message => event => {
    event.preventDefault();
    this.setState({
      notify: {
        message,
        autoHide: 1300,
      },
    });
    logEvent(
      event.target.name === "APIkey"
        ? SlangEvents.APIKEY_COPIED
        : SlangEvents.APP_ID_COPIED,
      {}
    );
  };

  render() {
    const { classes, appName, appDATA, apiKey, userBelongsToSlangLabs } =
      this.props;
    const subDomains = appDATA?.subDomains;
    const userJourney = appDATA?.userJourneys;
    const {
      tabValue,
      editAppName,
      appNameError,
      editAppNameClick,
      // createIntentName,
      // createIntentDescription,
      // openCreate,
      // errorList,
      filterAppID,
      // createError,
      notify,
    } = this.state;

    const {
      // handleChangeIntentName,
      // handleCreateNewIntent,
      // handleCloseNewIntent,
      onKeyPress,
      handleSaveAppName,
      handleClickAway,
      handleChangeAppName,
      handleEditAppNameClick,
      handleEditAppData,
      changeUserJourneyData,
      handleChangeTab,
    } = this;

    /* Might need this in future  */
    // const newIntentProps = {
    //   handleCloseNewIntent,
    //   handleChangeIntentName,
    //   handleCreateNewIntent,
    //   classes
    // };
    // const newIntentState = {
    //   createIntentName,
    //   createIntentDescription,
    //   openCreate,
    //   errorList,
    //   createError
    // };

    const appNameEditState = { appNameError, editAppName, editAppNameClick };
    const appNameEditProps = {
      classes,
      appName,
      onKeyPress,
      handleSaveAppName,
      handleClickAway,
      handleChangeAppName,
      handleEditAppNameClick,
      state: appNameEditState,
    };
    const globalUserJourney = userJourney?.find(
      UJ => UJ.name.toLowerCase() === "global"
    );
    const globalUserJourneyID = userJourney?.findIndex(
      UJ => UJ.name.toLowerCase() === "global"
    );

    return (
      <>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Grid
              item
              container
              alignItems="center"
              alignContent="space-between"
              justify="space-between"
              direction="row"
            >
              <Grid
                style={{ textAlign: "left" }}
                sm={4}
                md={3}
                lg={4}
                xl={2}
                item
              >
                <AppNameEdit {...appNameEditProps} />
              </Grid>
              <Grid sm={4} md={3} lg={3} xl={7} item>
                <SDKVersions
                  domainData={
                    appDATA
                      ? {
                          domain: appDATA.domain,
                          template_version: appDATA.version,
                        }
                      : null
                  }
                />
              </Grid>
              <Grid
                sm={4}
                md={6}
                lg={5}
                xl={3}
                style={{ textAlign: "left" }}
                container
                alignItems="center"
                alignContent="flex-start"
                justify="space-evenly"
                item
              >
                <div style={{ verticalAlign: "middle", textAlign: "left" }}>
                  <Typography variant="body2">
                    Assistant ID:{" "}
                    <CopyToClipboard text={filterAppID && filterAppID[0].id}>
                      <a
                        href="#AssistantID"
                        name="AssistantID"
                        onClick={this.copytoClip(
                          "Assistant ID copied to clipboard"
                        )}
                        className={`${classes.wrapText}`}
                        title="click to copy"
                      >
                        {filterAppID && filterAppID[0].id}
                      </a>
                    </CopyToClipboard>
                  </Typography>
                  <Typography variant="body2">
                    API Key:{" "}
                    <CopyToClipboard text={apiKey}>
                      <a
                        href="#APIkey"
                        name="APIkey"
                        onClick={this.copytoClip("API Key copied to clipboard")}
                        className={`${classes.wrapText}`}
                        title="click to copy"
                      >
                        {apiKey}
                      </a>
                    </CopyToClipboard>
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.mainIntent}
              spacing={2}
              alignContent="flex-start"
              alignItems="flex-start"
              direction="row"
              justify="space-between"
            >
              {appDATA ? (
                <>
                  <Grid xs={12} item>
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="off"
                    >
                      <Tab
                        label={
                          <TutorialWrapper
                            step={2}
                            anchorEl={this.subdomainTabRef}
                          >
                            <span ref={this.subdomainTabRef}>Sub-Domains</span>
                          </TutorialWrapper>
                        }
                        value={0}
                      />
                      <Tab
                        label={
                          <TutorialWrapper
                            step={4}
                            anchorEl={this.userJourneyTabRef}
                          >
                            <span ref={this.userJourneyTabRef}>
                              User Journeys
                            </span>
                          </TutorialWrapper>
                        }
                        value={1}
                      />
                      <Tab
                        label={
                          <TutorialWrapper
                            step={7}
                            anchorEl={this.appSettingsTabRef}
                          >
                            <span ref={this.appSettingsTabRef}>
                              App Settings
                            </span>
                          </TutorialWrapper>
                        }
                        value={2}
                      />

                      {userBelongsToSlangLabs && <Tab label="Power Mode" />}
                    </Tabs>
                  </Grid>

                  {tabValue === 0 && (
                    <Grid xs={12} item>
                      <Grid xs={12} item>
                        <Paper
                          style={{
                            padding: 20,
                            height: "70vh",
                            overflow: "auto",
                          }}
                          elevation={0}
                        >
                          <SubDomainEdit
                            subdomains={subDomains}
                            handleEditAppData={handleEditAppData}
                            handleChangeTab={handleChangeTab}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  )}

                  {tabValue === 1 && (
                    <Grid xs={12} item>
                      <Paper
                        style={{
                          padding: 20,
                          height: "70vh",
                          overflow: "auto",
                        }}
                        elevation={0}
                      >
                        <UserJourneysEdit
                          userJourneys={userJourney}
                          handleEditAppData={handleEditAppData}
                          changeUserJourneyData={changeUserJourneyData}
                          handleChangeTab={handleChangeTab}
                        />
                      </Paper>
                    </Grid>
                  )}

                  {tabValue === 2 && (
                    <Grid xs={12} item>
                      {globalUserJourney ? (
                        <Paper
                          style={{
                            padding: 20,
                            height: "70vh",
                            overflow: "auto",
                          }}
                          elevation={0}
                        >
                          <AppSettingsTabs
                            appStatesMetadata={
                              globalUserJourney.appStatesMetadata
                            }
                            userJourney={globalUserJourney}
                            userJourneyID={globalUserJourneyID}
                            changeUserJourneyData={changeUserJourneyData}
                            heading={"App Settings"}
                            docFor="APP_SETTINGS"
                            global={true}
                            handleEditAppData={handleEditAppData}
                          />
                        </Paper>
                      ) : (
                        <PromptsEdit />
                      )}
                    </Grid>
                  )}
                  {userBelongsToSlangLabs && tabValue === 3 && (
                    <Grid xs={12} item>
                      <JsonEditor />
                    </Grid>
                  )}
                </>
              ) : (
                <LinearProgress />
              )}
            </Grid>
          </Grid>
        </Grid>
        {/*  Hide the playground on prod */}
        {/* process.env.REACT_APP_STAGE !== "prod" ? (
          filterAppID &&
          filterAppID[0]["domain"]?.toLowerCase() === "retail" ? (
            <RetailPlayground
              apikey={apiKey}
              assistantID={filterAppID && filterAppID[0].id}
            />
          ) : null
              //        ) : null */}

        {isSlangLabsUser() ? (
          <Debugger
            apikey={apiKey}
            assistantID={filterAppID && filterAppID[0].id}
            assistantVersion={
              filterAppID && filterAppID[0].major_version + ".0.0"
            }
          />
        ) : null}
        {filterAppID ? (
          <TryAssistant
            {...{
              filterAppID,
              apiKey,
            }}
          />
        ) : null}

        <Notifications notify={notify} />
      </>
    );
  }
}

AssistantConfigEditMain.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired,
  userBelongsToSlangLabs: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    userBelongsToSlangLabs: checkIfUserBelongsToSlangLabs(state.login.username),
    apiKey: state.apikey.api_key,
    appStringTable: state.appSchema.appStringTable,
    appDATA: state.appSchema.appDATA,
    appMetadata: state.appSchema.appMetadata,
    appMetadataTrainStatus: state.appSchema.appMetadataTrainStatus,
    appList: state.appSchema.list,
    error: state.appSchema.error,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(withStyles(main)(AssistantConfigEditMain))));
