import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import TablePaginationActionsWrapped from "../../../common/pagination";
import Papa from "papaparse";
import { exportCSVFile } from "../../../../utils/JsonToCSV";
import {
  Button,
  Input,
  Icon,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    maxHeight: "60vh",
  },
  table: {
    minWidth: 700,
  },
});

function SimpleTable(props) {
  const {
    classes,
    userValues,
    fileName,
    getAllSubdomainData,
    isServerDownloadAvailable,
  } = props;
  const { csvfields, csvdata } = userValues;
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setpage] = useState(0);
  const [currentList, setCurrentList] = useState(csvdata);
  const [serverLoading, setServerLoading] = useState(false);

  const handleSaveCurrentListState = event => {
    const queryText = event.target.value;

    if (queryText.length === 0) {
      setCurrentList(csvdata);
    }
  };
  const handleChangeSearch = event => {
    const queryText = event.target.value;
    const items = csvdata?.length ? csvdata : [];

    if (!items || items.length === 0) {
      return;
    }

    if (queryText.length > 0) {
      const filtered = items.filter(item => {
        return (
          item.join(" ").toLowerCase().indexOf(queryText.toLowerCase()) > -1
        );
      });

      if (typeof filtered !== "undefined") {
        setCurrentList(filtered);
      }
    } else {
      setCurrentList(csvdata);
    }
  };
  const handleChangePage = (event, page) => {
    setpage(page);
  };

  const handleChangeRowsPerPage = event => {
    setrowsPerPage(event.target.value);
  };

  const handleDownLoad = () => {
    const csvString = Papa.unparse([csvfields, ...csvdata]);
    exportCSVFile([], [], fileName.replace(/ /g, "-"), csvString);
  };

  const handleDownloadFromServer = async () => {
    setServerLoading(true);
    try {
      const csvDataServer = await getAllSubdomainData();
      const csvString = Papa.unparse([csvfields, ...csvDataServer]);
      exportCSVFile(
        [],
        [],
        `${fileName.replace(/ /g, "-")}_${csvDataServer?.length}`,
        csvString
      );
    } catch (e) {
      console.error(e);
    } finally {
      setServerLoading(false);
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, currentList?.length - page * rowsPerPage);
  const rowsPerPageOptions = [10];

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={csvfields?.length - 2}>
              {" "}
              <Input
                placeholder="Search"
                onChange={handleChangeSearch}
                onClick={handleSaveCurrentListState}
                inputProps={{
                  "aria-label": "Description",
                }}
                fullWidth
              />
            </TableCell>
            <TableCell colSpan={2}>
              {" "}
              <Tooltip title={`Download ${csvdata?.length} rows`}>
                <Button
                  disabled={csvdata?.length < 1 || serverLoading}
                  onClick={handleDownLoad}
                >
                  <Icon>download_rounded</Icon>
                </Button>
              </Tooltip>
              {isServerDownloadAvailable && (
                <Tooltip title="Download All">
                  <Button
                    disabled={csvdata?.length < 1 || serverLoading}
                    onClick={handleDownloadFromServer}
                  >
                    <Icon>cloud_download</Icon>
                    {serverLoading ? (
                      <>
                        &nbsp;&nbsp;
                        <CircularProgress size={"1.5rem"} color="secondary" />
                      </>
                    ) : null}
                  </Button>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            {csvfields?.map((field, i) => (
              <TableCell key={field + i}>{field}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentList
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, i) => {
              return (
                <TableRow key={row[0] + i}>
                  {row?.map((dataValue, k) => (
                    <TableCell key={dataValue + k}>{dataValue}</TableCell>
                  ))}
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 48 * emptyRows }}>
              <TableCell colSpan={csvfields?.length} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow sytle={{ textAlign: "right" }}>
            <TablePagination
              colSpan={csvfields?.length}
              count={currentList ? currentList.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActionsWrapped}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
  userValues: PropTypes.object,
  getAllSubdomainData: PropTypes.func,
  isServerDownloadAvailable: PropTypes.bool,
};

export default withStyles(styles)(SimpleTable);
