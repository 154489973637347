import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core/";
import { useHistory } from "react-router-dom";
import { getAPI } from "../../../redux/actions/apikey";
import { useDispatch } from "react-redux";

import {
  OrganizationsHomePropTypes,
  organizationsType,
} from "./OrganizationsHomeTypes";

const OrganizationCards = (props: OrganizationsHomePropTypes) => {
  const { classes, state } = props;
  const dispatchRedux = useDispatch();

  const { organizationsList } = state;
  const history = useHistory();

  const switchToDifferentOrg = (newOrgID: string, newOrgName: string) => {
    localStorage.setItem("U_orgID", newOrgID);
    localStorage.setItem("U_orgName", newOrgName);
    dispatchRedux(getAPI());
    // additional timeout to get api key before entering the asst config view
    setTimeout(() => {
      history.push("/home/assistants");
    }, 300);
  };

  return (
    <>
      {organizationsList.map((item: organizationsType, key: number) => {
        const hover = "hover";

        return (
          <Grid
            key={item.organization_id + key}
            item
            xs={12}
            md={3}
            sm={4}
            lg={2}
            xl={1}
            id={key + hover}
          >
            <div
              className={`${classes.parentCard}`}
              style={{ cursor: "pointer" }}
            >
              <Card className={`${classes.card}`} elevation={0}>
                <div
                  onClick={(): void => {
                    switchToDifferentOrg(
                      item.organization_id,
                      item.organization_name
                    );
                  }}
                >
                  <CardMedia
                    className={classes.media}
                    image={"/images/graphic_assistant_template.png"}
                    title={item.organization_name}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      variant="body2"
                      component="h4"
                      noWrap
                    >
                      {item.organization_name}
                    </Typography>
                  </CardContent>
                </div>
                <span className={classes.buttonSpan}>
                  <span className={"hidden"}>
                    <span></span>
                  </span>
                </span>
              </Card>
            </div>
          </Grid>
        );
      })}
    </>
  );
};

export default OrganizationCards;
