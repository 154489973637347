import { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

const styles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const ParametersChips = memo(props => {
  const { parameters, handleDelete } = props;

  const classes = styles();

  return parameters.map((data, key) => (
    <Chip
      key={key + data}
      label={data}
      onDelete={() => {
        handleDelete(key);
      }}
      className={classes.chip}
    />
  ));
});

ParametersChips.propTypes = {
  parameters: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ParametersChips;
