import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel, a11yProps } from "./TabPanel";
import { Grid } from "@material-ui/core";
import UIHints from "./UIHints";
import PromptsEdit from "./PromptsEdit";
import AppStatesProperties from "./AppStateProperties";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs(props) {
  const {
    appStateUID,
    userJourneyID,
    userJourney,
    userJourneyName,
    appStatesMetadata,
    changeUserJourneyData,
    selectedValue,
    isTerminal,
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const allHints = appStatesMetadata[selectedValue].userUIHints
    ? appStatesMetadata[selectedValue].userUIHints
    : appStatesMetadata[selectedValue].uiHints;

  const uiHintsProps = {
    selectedAppState: selectedValue,
    changeUserJourneyData,
    userJourneyID,
    userJourney,
    allHints,
    heading: "UI Hints ",
  };

  return (
    <Grid container>
      <Grid xs={2} item>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab label="Properties" {...a11yProps(0)} />
          <Tab label="Prompts" {...a11yProps(1)} />
          {!isTerminal ? <Tab label="UI Hints" {...a11yProps(2)} /> : null}
        </Tabs>
      </Grid>
      <Grid xs={10} item>
        <TabPanel value={value} index={0}>
          <AppStatesProperties
            {...{
              appStatesMetadata,
              selectedAppState: selectedValue,
              heading: "Properties",
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PromptsEdit
            {...{
              appStatesMetadata,
              selectedAppState: selectedValue,
              userJourneyID,
              changeUserJourneyData,
              heading: "Prompts",
            }}
          />
        </TabPanel>
        {!isTerminal ? (
          <TabPanel value={value} index={2}>
            <UIHints {...uiHintsProps} />
          </TabPanel>
        ) : null}
      </Grid>
    </Grid>
  );
}
