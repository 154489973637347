const SlangConfig = {};

const ENV_PARAM = "?env=ENV&assistant_version=VER";
SlangConfig.SLANG_SCHEMA_ROUTE = "/v1/schema/";
SlangConfig.SLANG_SCHEMA_URI = SlangConfig.SLANG_SCHEMA_ROUTE + ENV_PARAM;

SlangConfig.SLANG_DOMAINS_ROUTE = "/v1/domains/";
SlangConfig.SLANG_DOMAINS_URI = SlangConfig.SLANG_DOMAINS_ROUTE + ENV_PARAM;

SlangConfig.SLANG_TEMPLATES_ROUTE = "/v1/templates/";
SlangConfig.SLANG_TEMPLATES_URI = SlangConfig.SLANG_TEMPLATES_ROUTE + ENV_PARAM;

SlangConfig.APPS_ROUTE = "/v1/applications/";
SlangConfig.APPS_URI = SlangConfig.APPS_ROUTE + ENV_PARAM;
SlangConfig.APP_CREATE_URI = SlangConfig.APPS_URI;
SlangConfig.APP_CLONE_URI = SlangConfig.APPS_ROUTE + "clone/";
SlangConfig.APP_BASE_URI = SlangConfig.APPS_ROUTE + "APP_ID/";
SlangConfig.APP_URI = SlangConfig.APP_BASE_URI + ENV_PARAM;
SlangConfig.APP_DELETE_URI = SlangConfig.APP_URI;
SlangConfig.APP_GET_URI = SlangConfig.APP_URI;
SlangConfig.APP_SAVE_URI = SlangConfig.APP_URI;
SlangConfig.APP_TRAIN_URI = SlangConfig.APP_BASE_URI + "train/" + ENV_PARAM;
SlangConfig.APP_STRINGS_URI = SlangConfig.APP_BASE_URI + "strings/" + ENV_PARAM;
SlangConfig.APP_METADATA_URI =
  SlangConfig.APP_BASE_URI + "metadata/" + ENV_PARAM;
SlangConfig.APP_METADATA_TRAIN_STATUS_URI =
  SlangConfig.APP_BASE_URI + "metadata/status" + ENV_PARAM;
SlangConfig.APP_ANDROID_CODEGEN_URI =
  SlangConfig.APP_BASE_URI + "codegen/android/?pkg=PKG_NAME&env=ENV";
SlangConfig.APP_ENTITY_TYPE_URI =
  SlangConfig.APP_BASE_URI + "types/ENTITY_TYPE/values" + ENV_PARAM;

SlangConfig.STD_ENTITY_TYPES_ROUTE = "/v1/stdtypes/";
SlangConfig.STD_ENTITY_TYPES_URI =
  SlangConfig.STD_ENTITY_TYPES_ROUTE + ENV_PARAM;

SlangConfig.HEADERS = { "Content-Type": "application/json; charset=UTF-8" };

SlangConfig.API_KEY_ROUTE = "/v1/auth/apikey/";
SlangConfig.API_KEY_AND_SECRET_ROUTE = "/v1/auth/apikey_and_secret/";
SlangConfig.API_KEY_URI = SlangConfig.API_KEY_ROUTE;

SlangConfig.ACCOUNTS_ROUTE = "/v1/accounts";
SlangConfig.ACCOUNT_URI = SlangConfig.ACCOUNTS_ROUTE;
SlangConfig.ACCOUNT_USER_URI = SlangConfig.ACCOUNTS_ROUTE + "/user";
SlangConfig.ACCOUNT_PROFILE_URI = SlangConfig.ACCOUNT_USER_URI + "/profile";
SlangConfig.SEND_DEMO_DATA = SlangConfig.ACCOUNT_USER_URI + "/demo/data";
SlangConfig.ALL_PLANS_URI = SlangConfig.ACCOUNTS_ROUTE + "/plans";
SlangConfig.ACCOUNT_PLAN_URI = SlangConfig.ACCOUNT_USER_URI + "/plan";
SlangConfig.ACCOUNT_PRIVILEGES_URI =
  SlangConfig.ACCOUNT_USER_URI + "/privileges";
SlangConfig.TRANSACTION_USAGE_URI =
  SlangConfig.ACCOUNT_USER_URI + "/usage/inferences"; // This should give transactions for the duration of current plan.
SlangConfig.INTENTS_USAGE_URI = SlangConfig.ACCOUNT_USER_URI + "/usage/intents"; // This should give intents for the duration of current plan.
SlangConfig.SESSIONS_USAGE_URI =
  SlangConfig.ACCOUNT_USER_URI + "/usage/sessions/"; // Add seesion usage
SlangConfig.SESSIONS_USAGE_URI_QUERY =
  SlangConfig.SESSIONS_USAGE_URI + "?start_date=START_DATE&end_date=END_DATE"; // Add seesion usage

module.exports = SlangConfig;
