import React from "react";
import {
  logEvent,
  SlangEvents,
  SlangSeverityLevels,
} from "../../../../libs/analytics/slangAnalyticsAPIs";
import { Typography, Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  configBox: {
    textAlign: "left",
  },
}));

export default function PublishSuccess(props) {
  const { asstConfigID, createAppName, selectedAssistant } = props;
  const classes = useStyles();

  return (
    <>
      <Grid alignContent="center" alignItems="center" container>
        <Grid xs={7} item>
          <img
            src="/images/publish_success_new.png"
            style={{ width: "60%" }}
            alt="pub success"
          />
        </Grid>
        <Grid xs={5} className={classes.configBox} item container>
          <Grid xs={12} item>
            <Typography variant="h5" gutterBottom>
              Publish Success
            </Typography>
            <Typography gutterBottom>Try out the assistant</Typography>
            <Typography gutterBottom>OR </Typography>
            <Typography gutterBottom>
              Integrate with your app / website
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
