import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import { testClassNames } from "../../../utils/integrationTestClassNames";

import { NewAssistantTemplateCardPropsInterface } from "./AssistantTemplateTypes";

const NewAssistantTemplateCard = (
  props: NewAssistantTemplateCardPropsInterface
) => {
  const { classes, handleCreateClick, state } = props;
  const { isCreatingOrDeleting } = state;

  return (
    <Card
      className={isCreatingOrDeleting ? classes.cardDisabled : classes.cardNew}
      elevation={0}
      onClick={isCreatingOrDeleting ? undefined : handleCreateClick}
    >
      <CardMedia
        className={`${classes.media} ${testClassNames.HOME_CREATE_NEW_BUDDY}`}
        image="/images/new_template_icon.png"
        title="Add a new assistant"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="body2" component="h4">
          Add Assistant Template
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NewAssistantTemplateCard;
