import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { main } from "../styles.js";

import TextField from "@material-ui/core/TextField";

import * as SlangTooltips from "../../common/SlangTooltips";

import ExpansionHelp from "./ExpansionHelp";
import {
  writeHelpPromptState,
  emptyLangIntentHelpState,
  readHelpPromptState,
  editHelpDescriptionState,
  editHelpUtteranceState,
  deleteIntentHelpState,
} from "../../../utils/helpUtils";
import { changeAppStringTable } from "../../../redux/actions/appSchema";
import AppConfig from "../../../app.config";

const Langs = AppConfig.LANGUAGES;

const mapDispatchToProps = dispatch => ({
  changeAppStringTable: appStrings =>
    dispatch(changeAppStringTable(appStrings)),
});
// Only update the state from props in the case when onBlur
let isNoUpdate = false;
export class IntentHomeHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "en-IN",
      open: false,
      error: null,
      languagePrompts: emptyLangIntentHelpState() || {},
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleBlurSave = this.handleBlurSave.bind(this);
  }

  updateState = () => {
    const stringData = JSON.parse(JSON.stringify(this.props.appStringTable));
    const entityHelp = this.props.entityHelp;
    this.setState({
      languagePrompts: readHelpPromptState(entityHelp, stringData),
    });
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.entityHelp !== this.props.entityHelp) {
      if (isNoUpdate) {
        isNoUpdate = false;
      } else {
        this.updateState();
      }
    }
  }

  handleDeletePrompt = (template, promptType, ID) => {
    const strData = this.props.appStringTable;
    const languagePrompts = this.state.languagePrompts;
    const promptsStrings = deleteIntentHelpState(
      languagePrompts,
      strData,
      template,
      promptType,
      ID
    );
    if (promptsStrings.hasStringID) {
      this.handleSave(
        promptsStrings.newLanguagePrompts,
        promptsStrings.strData
      );

      return;
    }
    this.handleSave(promptsStrings.newLanguagePrompts);
  };

  handleSave = (languagePrompts, strData, entityHelp) => {
    languagePrompts = languagePrompts || this.state.languagePrompts;
    strData = strData || this.props.appStringTable;
    entityHelp = entityHelp || this.props.entityHelp;
    const newData = writeHelpPromptState(entityHelp, strData, languagePrompts);

    this.props.handleSaveHelpData(newData.appData);
    this.props.changeLocalStringTable({ appStringTable: newData.strData });
  };

  handleBlurSave = () => {
    isNoUpdate = true;
    this.handleSave();
  };

  handleOnChange = (event, newkey = null, isNew = false) => {
    const value = event.target.value;
    const name = event.target.name;
    const lang = this.props.lang;
    const key = newkey;
    if (name === "help.helpDescription") {
      const languagePrompts = this.state.languagePrompts;
      const newLanguagePrompts = editHelpDescriptionState(
        languagePrompts,
        lang,
        name,
        key,
        value
      );

      this.handleSave(newLanguagePrompts);

      return;
    }
    const languagePrompts = this.state.languagePrompts;
    const newLanguagePrompts = editHelpUtteranceState(
      languagePrompts,
      lang,
      name,
      key,
      value,
      isNew
    );
    if (isNew) {
      this.handleSave(newLanguagePrompts);
      return;
    }
    this.setState({
      languagePrompts: newLanguagePrompts,
    });
  };

  render() {
    const { classes, lang } = this.props;
    const { languagePrompts } = this.state;
    const { handleOnChange, handleDeletePrompt, handleBlurSave, updateState } =
      this;

    const { help } = languagePrompts[lang];

    const helpDescriptionValue = help.helpDescription.value
      ? help.helpDescription.value
      : "";
    const errorTitle = helpDescriptionValue.trim() === "";
    const errorUtterance = help.utterances && help.utterances.length === 0;
    const langText = Langs.filter(item => item.code === lang)[0].text;

    return (
      <div>
        <div className={classes.separate}>
          <TextField
            style={{ marginTop: 0 }}
            name="help.helpDescription"
            placeholder={`${SlangTooltips.INTENT_HELP.HELP_TITLE_EN} in ${langText}`}
            helperText={SlangTooltips.INTENT_HELP.TTILE_EXAMPLE_EN}
            value={helpDescriptionValue}
            onChange={handleOnChange}
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            onFocus={updateState}
            onBlur={handleBlurSave}
            fullWidth
            error={errorTitle}
            required
          />
        </div>
        <div className={classes.separate}>
          <ExpansionHelp
            onChangeText={handleOnChange}
            handleDelete={handleDeletePrompt}
            StatementValues={help}
            handleBlurSave={handleBlurSave}
            lang={lang}
            promptName="help"
            HELPTEXT={SlangTooltips.INTENT_HELP}
            errorUtterance={errorUtterance}
            errorTitle={errorTitle}
          />
        </div>
      </div>
    );
  }
}

IntentHomeHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  appStringTable: PropTypes.object.isRequired,
  entityHelp: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  handleSaveHelpData: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    appStringTable: state.appSchema.appStringTable,
    appList: state.appSchema.list,
    error: state.appSchema.error,
    appID: state.appSchema.appID,
    schemaDefinition: state.appSchema.schemaDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(main)(IntentHomeHelp));
