import { makeStyles } from "@material-ui/core";
import HelpIcon from "./assets/SlangHelpIcon";
import CancelIcon from "./assets/SlangCancelIcon";

const styles = makeStyles(theme => ({
  SlangFlexContainer: {
    top: 60,
    left: -1,
    width: "100%",
    padding: 8,
    paddingBottom: 8,
    display: "flex",
    justifyContent: "center",
    position: props => (props.surfaceStyle === "curved" ? "relative" : ""),
    marginTop: props =>
      props.surfaceStyle === "card"
        ? "0.5rem"
        : props.surfaceStyle === "curved"
        ? "-0.5rem"
        : "1.5rem",
  },
  SlangSurfaceHeaderContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  SlangIcon: {
    height: "18px",
    width: "18px",
    cursor: "pointer",
    borderRadius: 20,
    filter: props =>
      props.surfaceUIMode === "dark" ? "invert(0.1)" : "invert(0.9)",
  },
}));

const SlangSurfaceHeader = props => {
  const { surfaceUIMode, surfaceStyle } = props;
  const classes = styles(props);
  return (
    <div className={classes.SlangFlexContainer}>
      <div className={classes.SlangSurfaceHeaderContainer}>
        <HelpIcon
          className={`${classes.SlangIcon} ${
            surfaceUIMode === "dark" ? classes.SlangIconDark : ""
          }`}
        />
        <CancelIcon
          className={`${classes.SlangIcon} ${
            surfaceUIMode === "dark" ? classes.SlangIconDark : ""
          }`}
        />
      </div>
    </div>
  );
};

export default SlangSurfaceHeader;
