import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";

import { testClassNames } from "../../../utils/integrationTestClassNames";

const AppNameEdit = props => {
  const {
    classes,
    appName,
    onKeyPress,
    handleSaveAppName,
    handleClickAway,
    handleChangeAppName,
    handleEditAppNameClick,
    state,
  } = props;
  const { appNameError, editAppName, editAppNameClick } = state;

  return !editAppNameClick ? (
    <Typography
      onClick={handleEditAppNameClick.bind(this, appName)}
      style={{ minHeight: "1.75em" }}
      variant="h5"
      classes={{ h5: classes.typeFont }}
      noWrap
      gutterBottom
    >
      {appName}
      <IconButton
        className={`${classes.buttonEdit} ${testClassNames.BUDDY_NAME_EDIT_BUTTON}`}
        aria-label="Edit"
      >
        <EditIcon className={classes.iconEdit} />
      </IconButton>
    </Typography>
  ) : (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <TextField
          helperText={"Unique name; e.g. app.mtcamera.com or My Camera app V1"}
          placeholder={appName}
          className={classes.editAppName}
          name="editAppName"
          value={editAppName}
          inputProps={{
            "aria-label": "Description",
          }}
          onKeyPress={onKeyPress}
          onChange={handleChangeAppName}
          error={appNameError}
        />
        <IconButton
          className={`${classes.buttonEdit} ${testClassNames.BUDDY_NAME_SAVE_BUTTON}`}
          aria-label="Edit"
          onClick={handleSaveAppName}
          disabled={appNameError}
        >
          <SaveIcon
            className={classes.iconEdit}
            color={appNameError ? "error" : "primary"}
          />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
};

AppNameEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  handleSaveAppName: PropTypes.func.isRequired,
  handleClickAway: PropTypes.func.isRequired,
  handleChangeAppName: PropTypes.func.isRequired,
  handleEditAppNameClick: PropTypes.func.isRequired,
};

export default AppNameEdit;
